import React from 'react'; 
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateNeoVentes, getNeoVentes, getClientSearch, getB2BClientSearch, saveB2BClient, checkClientEligibility, getB2BContractListDetails, getClientAddress, getCodePromo, getNeoVentesPromos, getProductInfo, getPartialInvoicePayments, getAllProducts, getChargeDeskStatus, getClientTransactionsFullInfo } from '../../actions/NeoVentesAction'; 
import { getTicketSummary, sendTicketLead } from '../../actions/NdiFieldsAction';
import { Validations } from '../../utils/validations'; 
import Select from 'react-select';
import NeoSimpleValue from "../../ReactSelectSimpleValue"; //'react-select-simple-value';
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import TextField from '@material-ui/core/TextField';
//import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import StripePayment from '../../StripePayment';
import moment from 'moment';

import InputMask from "react-input-mask";
import Switch from '@material-ui/core/Switch';
import Toastr from "../../messages";
import  Loader from "../../loader";
import productList from "../../appProductList";
import  getProvinceTaxInfo from "../../appTaxes"; 
 


//import {Elements} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';
//import CheckoutForm from '../../payments/stripe/CheckoutForm';
import  GoogleAddressSearch from "../GoogleAddressSearch";
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import FolderIcon from '@material-ui/icons/Folder';
import DraftsIcon from '@material-ui/icons/Drafts'; 
import Tooltip from '@material-ui/core/Tooltip';


//import PropTypes from 'prop-types';
import { darken, makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
//import AutocompleteNeo from '../libautocomplete/AutocompleteNeo';
import ViewListIcon from '@material-ui/icons/ViewList';

import ZAFClient from 'zendesk_app_framework_sdk';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import HelpIcon from '@material-ui/icons/Help';
import { AddAPhoto, BorderBottom, DesktopWindows } from '@material-ui/icons';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails'; 
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


//import somestyle from "../../stylesheets/css/font-awesome.min.css"; 

//const filter = createFilterOptions();

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['name']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['description']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['amount']={check:false, chkType:'BigDecimal', message:'incorrect value'};

FIELD_VALIDATION['idClient']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['firstname']={check:true, chkType:'String', message:'Ce champ est obligatoire'};
FIELD_VALIDATION['lastname']={check:true, chkType:'String', message:'Ce champ est obligatoire'};
FIELD_VALIDATION['cell']={check:true, chkType:'String', message:'Ce champ est obligatoire'};
//FIELD_VALIDATION['terms']={check:false, chkType:'int', message:'incorrect value'};
//FIELD_VALIDATION['active']={check:false, chkType:'int', message:'incorrect value'};
//FIELD_VALIDATION['created']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['email']={check:true, chkType:'email', message:'Vous n\'avez pas saisi une adresse e-mail valide'};
FIELD_VALIDATION['id_users']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['language']={check:true, chkType:'String', message:'incorrect value'};
//FIELD_VALIDATION['push']={check:false, chkType:'int', message:'incorrect value'};
//FIELD_VALIDATION['id_zendesk']={check:false, chkType:'long', message:'incorrect value'};
FIELD_VALIDATION['sms']={check:false, chkType:'int', message:'incorrect value'};
//FIELD_VALIDATION['stripe_id']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['occupation']={check:true, chkType:'String', message:'Ce champ est obligatoire'};



FIELD_VALIDATION['idAddress']={check:false, chkType:'String', message:'incorrect value'};
//FIELD_VALIDATION['id_clients']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['address']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['city']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['province']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['cp']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['country']={check:false, chkType:'String', message:'incorrect value'}; 
FIELD_VALIDATION['company']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['unitsuite']={check:false, chkType:'String', message:'incorrect value'};


let FIELD_VALIDATION2={};
FIELD_VALIDATION2['id']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['id_b2b_list']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION2['id_external']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['name_id_external']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['firstname']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['lastname']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['email']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['tel']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['start_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION2['expiry_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION2['address']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['city']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['province']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['postal_code']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['raw_data']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['date_created']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION2['date_updated']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION2['status']={check:false, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION2['dob']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION2['id_clients']={check:false, chkType:'int', message:'incorrect value'};

const TAXk_TVQ=0.09975; //0.9975
const TAXk_TPS=0.05;
const TAXk_TOTAL=0.14975; //0.15; 

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const SelectNeo = ({ options, value, ...props }) => (
  <NeoSimpleValue options={options} value={value}>
    {simpleProps => <Select {...props} {...simpleProps} />}
    {/* {simpleProps => <Select ref={ref} {...props} {...simpleProps} />} */}
  </NeoSimpleValue>
);

let zafClient = ZAFClient.init(); 


class NeoVentesForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  requestTimer = null;
  backInvoiceId=0;
  stripeAvoidManyTokens=0;
  stripeTryTimes=0;
  stripeNeoTokenTrack=0;
  paymentBtnTryTimes2=0;
  paymentBtnTryTimes3=0;
  clientOnZendeskSCR=0;
  // TAX1=0.09975; //tax1 0.9975
  // TAX2=0.05; //tax2 
  // TAX_TOTAL=0.14975; 
  // TAX1_NAME="TVQ"; //tax1_name
  // TAX2_NAME="TPS"; //tax2_name
 
  
  constructor(props) {
    super(props);
    
    this.stateInit = {
      id:0,
      name:'', e_name: false,
      description:'', e_description: false,
      amount:'', e_amount: false,
      
      productCart:  [], 
      productId:0, productTitre:'', product:{}, productPrice:'', productPriceTax:'', productPriceTaxOnly:0, productExtraOptions:[], 
      productExtraOptionCtrl:null, productExtraOptionCtrl2:[], showProductOption:false, 
      prodTagPrice:  [], prodTagPriceTax:  [], prodTagPriceTaxOnly:  [], prodTagTotOptions:  [], grandTotal: 0, 
  
      idClient:'0',
      firstname:'', e_firstname: false,
      lastname:'', e_lastname: false,
      cell:'', e_cell: false,
      terms:'', e_terms: false,
      active:'', e_active: false,
      created:'', e_created: false,
      email:'', e_email: false,
      id_users:'0', e_id_users: false,
      language:'fr', e_language: false,
      push:'', e_push: false,
      id_zendesk:'', e_id_zendesk: false,
      sms:1, e_sms: false,
      stripe_id:'', e_stripe_id: false,
      occupation:'', e_occupation: false,
  
  
      idAddress:0, 
      address:'', e_address: false,
      unitsuite:'', e_unitsuite: false,
      city:'', e_city: false,
      province:'QC', e_province: false, province2:'', 
      cp:'', e_cp: false,
      country:'Canada', e_country: false,
      company:'', e_company: false, 
      existedClientRO: false, openPossibleZipCode:false, listAddressOnCode: null, listZipCodeAddresses:[], countryTuner:'',  
      listPromotionCodes:[], codepromoOptions:[], openInfoStepsPopup:false, infoStepsPopupno:0,
      infoProduct:{data:[]}, infoProduct_title:"", infoProduct_excerpt:"", partialCanClose:0,
      openTransactionPopup: false, twoStepSale:"",
  
       
      listOfSuggestion:[],
      valueClientSearch:'', 
      inputValueClientSearch:'',
      selectedIdSuggestion:'',

      listOfSuggestion2:[],
      valueClientSearch2:'', 
      inputValueClientSearch2:'',
      selectedIdSuggestion2:'',

      clientInfo:{ }, 
      clientAddress:{ },
      globalStepWorkflow: 1, // 1 init 2:start with product 3:start with customerInfo 4:payment 5: case close 
      workflowType: 1, //1 begin, 2 with clientInfo, 3 with Prod

      stripePaymentOpen: false, isLoaderOpen:false, 
      toastrMessage: '', toastrType: '', toastrDuration: 6000,
      cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
      orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
  
      stripeToken:'',
      dossier:'', //421
      isRecurrentPayment: 0,  nowPayment: 0, qtyPayment: 1, laterpayment:4, fromDatePayment:'', 
      paymentRound:0, paymentWay: 'full', nowPaymentRO: true, recurPaymentAmount: 0,
      paymentsReceived: [0], totalPaymentReceived: 0, paymentWayRO: false, beforePaymentTotal:0, 
      id_invoice: 0, pMethod:1, status_invoice:'', 
      showProdMulti:0, productsList:{"products": []}, openPListAsOnTheSite: false,
      errorTransacMessage: "", emailDoesNotExist: 0, openPossibleListOfClient: false,
      listClientOnCode:[], possibleListOfClient:[], possibleListOfClientmini:[],
      statusChargeDesk: 0, chargeDeskIdStr:"",
      openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}, possibleListOfCDStatusInfo:[],  
      taxInfo:{}, isupsale:false, upsaleTicket:"", isAmerindian:false, no_amerindien:"", 
      listOfTickets:[], goProd:0, 
      b2b: {
          id:0,
          id_b2b_list:'', e_id_b2b_list: false,
          id_external:'', e_id_external: false,
          name_id_external:'', e_name_id_external: false,
          firstname:'', e_firstname: false,
          lastname:'', e_lastname: false,
          email:'', e_email: false,
          tel:'', e_tel: false,
          start_date:'', e_start_date: false,
          expiry_date:'', e_expiry_date: false,
          address:'', e_address: false,
          city:'', e_city: false,
          province:'QC', e_province: false,
          postal_code:'', e_postal_code: false,
          raw_data:'', e_raw_data: false,
          date_created:'', e_date_created: false,
          date_updated:'', e_date_updated: false,
          status:'', e_status: false,
          dob:'', e_dob: false,
          id_clients:'', e_id_clients: false,
          company_name:''
      }, 
        elibilityObj:{}, eligibilityChecked:0, listOfContracts:[], clientMannual:0, 
        showCreateBtnManual:false,
      }

      this.state = JSON.parse(JSON.stringify(this.stateInit)); // Object.assign(this.stateInit);

    let workflowIni=true; let workflowClientInfo=false;   let workflowProductList=false;
	const locurl=window.location.href;
  const patt=/([0-9]+)(\/edit)$/;
  const patt2=/([0-9]+)(\/invoice)$/;
  // backInvoiceId=0;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else if(patt2.test(locurl)){ 
    this.actionMode='partialInvoice';  
    const regex = /([0-9]+)(\/invoice)$/g; ///[A-Z]/g;
    const foundText = locurl.match(regex);
    const cleanId=(""+foundText[0]).replace("/invoice", "");
    this.backInvoiceId=cleanId;
    //console.log(foundText, cleanId, this.backInvoiceId);
	}else{ 
		this.actionMode='new'; 
	}  
  //console.log(' action mode '+this.actionMode);
  this.setExtraServiceForProduct=this.setExtraServiceForProduct.bind(this);  
  //this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
  this.prodPrice=[];
  this.prodPriceTVQ=[];
  this.prodPriceTPS=[];
  this.prodTaxOnly=[];
  this.prodPriceAndTax=[];
  this.prodPriceAndTax2=[];
  this.prodPriceServices=[];
  this.prodPriceSubtotals=[];
  this.paymentWayRef=React.createRef();
  this.searchOptions = {};
  
  this.navigationTopRefOnTop=React.createRef();
   
	
  }

  baseB2B= {
    id:0,
    id_b2b_list:'', e_id_b2b_list: false,
    id_external:'', e_id_external: false,
    name_id_external:'', e_name_id_external: false,
    firstname:'', e_firstname: false,
    lastname:'', e_lastname: false,
    email:'', e_email: false,
    tel:'', e_tel: false,
    start_date:'', e_start_date: false,
    expiry_date:'', e_expiry_date: false,
    address:'', e_address: false,
    city:'', e_city: false,
    province:'QC', e_province: false,
    postal_code:'', e_postal_code: false,
    raw_data:'', e_raw_data: false,
    date_created:'', e_date_created: false,
    date_updated:'', e_date_updated: false,
    status:'', e_status: false,
    dob:'', e_dob: false,
    id_clients:'', e_id_clients: false,
    company_name:''
};

   
//productsList=productList();
productsList={"products": []}; // this.state.productsList; // productList();
 
componentDidMount() { 
  const taxInfo=getProvinceTaxInfo("QC");
  this.setState({ ["taxInfo"]: taxInfo });
  console.log("VENTES B2B weeeeeeeeeeeeeeeeeeee TAXiNFO ", taxInfo);
  if (!window.google) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBNT3gyx565iSZCCAjtIiwuWOjQmZVcjt4&libraries=places`;
    script.async = true;
    document.body.appendChild(script);
    //document.body.removeChild(script); // remove it so in each time you visit this route it won't create new script and append it to </body> this should work just fine.
  }

  //clientOnZendeskSCR=0;
  if(zafClient!==undefined && zafClient!==false){ 
    const this00=this;  let neoId=0;
    //let stepRunned=0;
    this.clientOnZendeskSCR=10; 
    
    console.log("######NEOVENTESFORM yeeeeeeeeeees ");
     
    
     

    zafClient.on('app.registered', function(e) {
      // do stuff
      this.clientOnZendeskSCR=11; 
      console.log("NEOVENTESFORM yeeeeeeeeeees registered the ticket");
      
    });

    zafClient.on('app.activated', function(e) {
      // do stuff
      this.clientOnZendeskSCR=12; 
      console.log("NEOVENTESFORM activated oooooooooooooooh an agent navigates back to a ticket");
       
    });
     
     
 
  }   

}

UNSAFE_componentWillMount () {
  // if (!window.google) {
  //   const script = document.createElement("script");
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBNT3gyx565iSZCCAjtIiwuWOjQmZVcjt4&libraries=places`;
  //   script.async = true;
  //   document.body.appendChild(script);
  //   document.body.removeChild(script); // remove it so in each time you visit this route it won't create new script and append it to </body> this should work just fine.
  // }
}

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    const globalStepWorkflow=this.state.globalStepWorkflow;
    if(this.state.existedClientRO){
      const fulname=this.state.firstname+" "+this.state.lastname;
      this.showToast("Attention!!! Vous êtes entrain de changer des données pour un client existant "+fulname, "warning");
    }
  };
  handleChangeb2b2 = (event) => {
    //this.setState({ [event.target.name]: event.target.value });
    const  val=event.target.value;
    const listOfContracts=this.state.listOfContracts;
    const clientMannual=this.state.clientMannual;
    const b2bData=this.state.b2b;
    //<option value="1">Yes</option> ClientB2B
    const listOfContractsList = listOfContracts.length > 0
        && listOfContracts.map((item, i) => {
          //console.log(item);
          if(val===item.id){
            b2bData.name_id_external=item.name_id_external;
            //b2bData.start_date=item.contract_start;
            //b2bData.expiry_date=item.contract_end;
            b2bData.raw_data='mannually';
            b2bData.status=item.status;
            b2bData.id_b2b_list=item.id;
             
 
            this.setState({ ["b2b"]: b2bData});  
            //console.log("b2b b2b b2b ", b2bData, item); 
             
          }
           
        return (
          null
        );
      }, this); 

  };
  handleCheckBoxCheck = (event) => {
    let isChecked=false; 
    if(event.target.checked){ isChecked=true; }
    this.setState({ [event.target.name]: isChecked }); 
     
    if(event.target.name==="isupsale" && isChecked){ 
      this.getClientTransactionList(-7);
    }
  };
  handleChangeProvince = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    const globalStepWorkflow=this.state.globalStepWorkflow;
    if(this.state.existedClientRO){
      const fulname=this.state.firstname+" "+this.state.lastname;
      //this.showToast("Attention!!! Vous êtes entrain de changer des données pour un client existant "+fulname, "warning");
    }
    // console.log("PROVINCE TAX CHANGE "+event.target.value);
    // const taxInfo=getProvinceTaxInfo(event.target.value);
    // this.setState({ ["taxInfo"]: taxInfo });

    this.sethandleChangeProvince(event.target.value);
 
  };
  
  //this.handleChangeb2b   this.handleFocusoutb2b 
  handleChangeb2b = (event) => {
    //this.setState({ [event.target.name]: event.target.value }); 
    const b2b=this.state.b2b;
    b2b[event.target.name]=event.target.value; 
    this.setState({ ["b2b"]: b2b }); 
    //console.log(b2b);
    //event.target.focus();
  };
  handleFocusoutb2bOld = (event) => {
    //this.setState({ [event.target.name]: event.target.value }); 
    const b2b=this.state.b2b;
    b2b[event.target.name]=event.target.value;
    //this.setState({ b2b..., [event.target.name]: event.target.value }); 
    this.setState({ ["b2b"]: b2b }); 
  };

  handleFocusoutb2b = (event) => {
    const b2b=this.state.b2b;  //ClientB2B 
	  if(FIELD_VALIDATION2[event.target.name]!==undefined && FIELD_VALIDATION2[event.target.name].check===true){
      b2b[event.target.name]=event.target.value;
      b2b["e_"+event.target.name]=false;
      this.setState({ ["b2b"]: b2b }); 
      //this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION2[event.target.name].chkType, event.target.value)){
        b2b["e_"+event.target.name]=true;
        this.setState({ ["b2b"]: b2b }); 
        //this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  }   	
  };


  handleChangeEmailFocus = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    //this.setState({ ["emailDoesNotExist"]: 0 });
    const emailneo=event.target.value;
      
    if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
        this.setState({ ["e_"+event.target.name]: true }); 	
        //console.log(`ooooooooooooooooooue error validation ${event.target.name}: ${event.target.value}`);
		  }
	  } 	
    //setTimeout(() => {
    //   this.calculateGrandTotal(null) ;
    // }, 1000);
    const globalStepWorkflow=this.state.globalStepWorkflow;
    if(this.state.existedClientRO){
      const fulname=this.state.firstname+" "+this.state.lastname;
      //this.showToast("Attention!!! Vous êtes entrain de changer des données pour un client existant "+fulname, "warning");
      return false;
    }
    this.setState({ ["emailDoesNotExist"]: 0 });
    //const emailneo=event.target.value;
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(emailneo)){
      //this.getClientSearchSuggestion(emailneo); getB2BClientSearch
      const serachObj={search:"", searchEmail:emailneo};
      const this2=this;
      Promise.resolve(this.props.getClientSearch(serachObj))
      .then((response)=>{  
        //this.setState({ ["listOfSuggestion"]: response.NeoClientsOne }); 
        const myList=response.NeoClientsOne;
        console.log("weeeeeeeeeeeeee neo client exist list "+emailneo);
        console.log(response.NeoClientsOne);
        let clientListx=[]; let clientListx2=[];

        if (myList.length > 0) { 
          let listAddressOnCode = myList.length > 0
          && myList.map((item, i) => { 
              const firstname=item.firstname;
              const lastname=item.lastname;
              const email=item.email;
              //const addObj=item.addFormatted;

              const zx=(
                <tr key={ i }>
                    <td>{ firstname }</td> 
                    <td>{ lastname }</td>
                    <td>{ email }</td>
                    <td> <a href="#" data-client={i} onClick={this2.clientCustomerSetter}><FolderIcon /></a> </td>
                  </tr>
              );
              clientListx.push(zx);

              const zx2=(
                <p key={ i }>
                    { firstname } | 
                    { lastname } | 
                    { email } | 
                    <a href="#" data-client={i} onClick={this2.clientCustomerSetter}><FolderIcon /></a> 
                  </p>
              );
              clientListx2.push(zx2);
              
            return (
              <tr key={ i }>
                  <td>{ firstname }</td> 
                  <td>{ lastname }</td>
                  <td>{ email }</td>
                  <td> <a href="#" data-client={i} onClick={this2.clientCustomerSetter}><FolderIcon /></a> </td>
                </tr>
            );
          }, this);
          this.setState({["openPossibleListOfClient"]: true, ["possibleListOfClient"]: clientListx, 
          ["possibleListOfClientmini"]: clientListx2, "listClientOnCode": myList }); 
           
        }else{
          this.setState({ ["emailDoesNotExist"]: 1 });
          this.showToast("L'email "+emailneo+" de ce client n'existe pas tu peux continuer.", "success");
        }

        return response;
      });
      /*Promise.resolve(this.getClientSearchSuggestion(emailneo))
        .then((response)=>{ 
          const cxz=response.NeoSalesPlanningDailyOne;
          //setSaleGoalDaily(cxz.qtyToday+"/"+cxz.expectation.expected_quantity);
           
          //console.log("weeeeeeeeeee checkIfUserIsAdmin "+cxz.isadmin);
          //console.log(response);  

          return response;
        });*/
    }else{
      this.setState({ ["emailDoesNotExist"]: 0 });
    }

  //}, 3000);
  };
  handleChangeCountrypays = (event) => {
    const cval=event.target.value;
    this.setState({ ["country"]: event.target.value });
    if(cval==="Canada"){ 
      this.setState({ ["province"]: "QC", ["province2"]: "", ["cp"]: ""});
    }else{ 
      this.setState({ ["province"]: "", ["province2"]: "", ["cp"]: ""});
    }
  };
  handleCountryTuner = (event) => {
    const cval=event.target.value;
    this.setState({ [event.target.name]: event.target.value });
    if(cval===""){  
      this.searchOptions={ };
    }else{  
      // and limit the results to addresses only
    let searchOptions = {
      //location: new google.maps.LatLng(-34, 151),
      radius: 2000,
      types: ['address']
      };

      var optionsqqqq = {
        types: ['(cities)'],
        componentRestrictions: {country: 'fr'}
      };  

      searchOptions = { 
        types: ['street_address']
        };

        this.searchOptions={
          types: ['address'],
          componentRestrictions: {country: cval},
        };
    }
  };
  handleChangeChkBox = (event) => {
    let valx=0;
    if(event.target.checked){ valx=1;  }  
    this.setState({ [event.target.name]: valx });
  };
  handleChangePaymentWay = (event) => {
    const valuex=event.target.value;
    this.setState({ [event.target.name]: event.target.value });
    const productPriceTotal=this.state.grandTotal;
    if(valuex==='full'){ 
      this.setState({ ['isRecurrentPayment']: 0, ['nowPaymentRO']: true, ["nowPayment"]: productPriceTotal, });
    }else if(valuex==='partial'){ 
      this.setState({ ['isRecurrentPayment']: 0, ['nowPaymentRO']: false, });
    }else if(valuex==='recurrent'){ 
      this.setState({ ['isRecurrentPayment']: 1, ['nowPaymentRO']: false, });

    const downPayment=this.state.nowPayment;
    const paymentRound=this.state.paymentRound;
    const id_invoice=this.state.id_invoice;
    
    const productPriceTotalx=this.state.orderTotal;
    const totalPaymentReceived=this.state.totalPaymentReceived;
    const productPriceTotal= (productPriceTotalx-totalPaymentReceived); //.toFixed(2) ; 

    //this.setState({ ["grandTotal"]:  productPriceTotal }); ["orderTotal"]: productPriceTotal, 
    //this.setState({["orderBruteSubTotal"]: tarif, ["orderNetSubTotal"]: tarif, ["orderTVQ"]: productPriceTVQ,["orderTPS"]: productPriceTPS,["orderTotal"]: productPriceTotal,});
    //this.setState({ ["nowPayment"]:  productPriceTotal });

    const nowPayment=(productPriceTotal/2).toFixed(2); 
    const recurPaymentAmount=(productPriceTotal/2).toFixed(2); 
    const recPaymentDate=moment().add(1, 'M').format('YYYY-MM-DD'); //""; //set one month from today
     
    
    this.setState({["nowPayment"]: nowPayment, ["recurPaymentAmount"]: recurPaymentAmount,
     ["fromDatePayment"]: recPaymentDate,});

    }else{ 

    }
  };

  handleChangeRecurrent = (event) => {
    event.preventDefault();
    const productPriceTotal=this.state.grandTotal;
    const neoVal=(event.target.value*1);
    if(neoVal >productPriceTotal){ 
      this.showToast("Erreur!!! On ne peut pas avoir un montant supérieur a "+productPriceTotal, "error");
      return false; 
    }
    //if(event.target.name==='nowPayment'){  }
    this.setState({ [event.target.name]: event.target.value });
    let this2=this;
    setTimeout(function(){
      //const this=that;
      const paymentWay=this2.state.paymentWay;
      const qtyPayment=this2.state.qtyPayment;
      const downPayment=this2.state.nowPayment;
      const paymentRound=this2.state.paymentRound;
      const id_invoice=this2.state.id_invoice;

      const productPriceTotalx=this2.state.orderTotal;
      const totalPaymentReceived=this2.state.totalPaymentReceived;
      const productPriceTotal= (productPriceTotalx-totalPaymentReceived); //.toFixed(2) ; 

      //const nowPayment=(productPriceTotal/2).toFixed(2); 
      //const recurPaymentAmount=(productPriceTotal/2).toFixed(2); 
      //console.log('wello uuuuuuupdate amount '+paymentWay); 
      if(paymentWay==='full'){ 
        //this.setState({ ['isRecurrentPayment']: 0, ['nowPaymentRO']: true, });
      }else if(paymentWay==='partial'){ 
        //this.setState({ ['isRecurrentPayment']: 0, ['nowPaymentRO']: false, });
      }else if(paymentWay==='recurrent'){ 
        //this.setState({ ['isRecurrentPayment']: 1, ['nowPaymentRO']: false, });
        //console.log('wello uuuuuuupdate amount'); 
        //const nowPayment=(productPriceTotal/2).toFixed(2); 
        const recurPaymentAmount=(Math.ceil((productPriceTotal-downPayment)/qtyPayment)).toFixed(2); 
        //console.log('wello uuuuuuupdate amount '+nowPayment+' vs '+recurPaymentAmount+' qty '+qtyPayment); 
        //this.setState({["nowPayment"]: nowPayment, ["recurPaymentAmount"]: recurPaymentAmount,});
        this2.setState({["recurPaymentAmount"]: recurPaymentAmount,});
      }
    }, 300);

  };
  
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
        this.setState({ ["e_"+event.target.name]: true }); 	
        //console.log(`ooooooooooooooooooue error validation ${event.target.name}: ${event.target.value}`);
		  }
	  } 	
  };

  openStripePayment = (event) => { 
    this.setState({ ['stripePaymentOpen']: true });
    //window.alert('heloo stripe .................');
  };
   



  handleChangeAmerindian = (event) => {
    const cval=event.target.value;
    this.setState({ [event.target.name]: cval });
    const taxInfo=this.state.taxInfo;
    const taxISO= taxInfo["iso"]; //this or 
    // const isAmerindian=this.state.isAmerindian;
    // if(isAmerindian && cval!==null && cval!==""){
         
    // }
    setTimeout(() => {
      this.sethandleChangeProvince(taxISO) ;
    }, 500); 
  };
  sethandleChangeProvince = (taxISO) => {
    // this.setState({ [event.target.name]: event.target.value });
    // const globalStepWorkflow=this.state.globalStepWorkflow;
    // if(this.state.existedClientRO){
    //   const fulname=this.state.firstname+" "+this.state.lastname;
    //   this.showToast("Attention!!! Vous êtes entrain de changer des données pour un client existant "+fulname, "warning");
    // }
    console.log("PROVINCE TAX CHANGE "+taxISO);
    const taxInfo=getProvinceTaxInfo(taxISO);

    const isAmerindian=this.state.isAmerindian;
    const no_amerindien=this.state.no_amerindien;
    if(isAmerindian  && no_amerindien!==null && no_amerindien!==""){
      taxInfo["tax1"]=0;  
      taxInfo["tax2"]=0;  
      taxInfo["tax1x"]=0;  
      taxInfo["tax2x"]=0;   
    }

    this.setState({ ["taxInfo"]: taxInfo });



    // const selVal= event.target.value;  //productId
    // const selVal2= event.target.dataset.value;  
    // const arVal=selVal2.split('-');
    // const index=arVal[0];  const prodId=arVal[1];  //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
   
    let productCart=this.state.productCart;

    //const taxInfo=this.state.taxInfo;
    /*const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"];*/

    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
     
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    } 

    const TAX_TOTAL=TAX1+TAX2; 
    
    let subTotal=0;  let totTagTotOptions=0; let totProd=0;
    productCart.length > 0
    	&& productCart.map((item, i) => { 
        const isDelete=item.isdelete;
        const myItemtxt=""+item.id;
        const index=i; 
        if(myItemtxt.indexOf("98989") <0 && isDelete===undefined){ 
          totProd++;
          subTotal=0;  totTagTotOptions=0;
        const productPrice= item.productPrice2===0?item.productPrice:item.productPrice2;
        const tarifBase=(parseFloat(productPrice)*1); //(parseFloat(item.productPrice)*1);
        const tarifAmount=(tarifBase+parseFloat(item.totOptions));
        subTotal +=parseFloat(productPrice);  //parseFloat(item.productPrice);
        totTagTotOptions +=parseFloat(item.totOptions);
        const tax1= (((tarifAmount*1)*TAX1)).toFixed(2);
        const tax2= (((tarifAmount*1)*TAX2)).toFixed(2);
        const tax_total= (tarifAmount*TAX_TOTAL).toFixed(2);
        const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2);
        const options=item.options;
        const cticket=item.cticket;
        const pages=item.pages;  const perPage=item.perPage;


        ////////////////////////////////////////////////////
        // productCart[index].productPrice2=selVal;
        // this.prodPrice[index].current.value=selVal; 
 
        //let totTagTotOptions=(productCart[index].totOptions*1); // 0;
        let optionsProd=[]; //productCart[index].options; 
        
        // totTagTotOptions=((selPage-1)*perPage); 
        // optionsProd.push({id: 'perPage', mnt:totTagTotOptions}); 
        
        // productCart[index].totOptions=totTagTotOptions;
        //const productPrice= productCart[index].productPrice2===0?productCart[index].productPrice:productCart[index].productPrice2;

        let tarif =((productPrice*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  
        const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
        const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ; 
        //productCart[index].options=optionsProd;
        const orderTVQ=((tarif*1)*TAX1).toFixed(2);
        const orderTPS=((tarif*1)*TAX2).toFixed(2);  
        const orderServices=totTagTotOptions; 
        const orderSubtotals=((tarif*1)).toFixed(2);
      

        productCart[index].productPriceTax=productPriceTotal;
        productCart[index].productPriceTaxOnly=productPriceTaxOnly;
        console.log("UPDATING PROD TAX ", index, productPrice, productPriceTotal, productPriceTaxOnly);

         
        }

    }, this); 

    // if(totProd === 0){ 
    //   this.showToast("Erreur! Pour continuer il faut avoir au moins un produit valide !", "error");
    //   return false;
    // }
    
 
 
    this.setState({ ["productCart"]:  productCart });
    //this.calculateGrandTotal(null) ;

    setTimeout(() => {
      this.calculateGrandTotal(null) ;
    }, 1000);




    //this.showToast("Attention!!! en changeant la province on applique les taxes de cette province ou territoire.", "warning");
  };

  callbackFunction = (childProp) => {
    //this.setState({message: childProp});
    
    console.log("weeeeeeeeeeel state address data");
    console.log(childProp);
    const country=childProp.country==="États-Unis"? "Etats-Unis":childProp.country;
    this.setState({ ["country"]: country });  //États-Unis
      
    let this2=this;
    setTimeout(function(){ //(function(){   = (childProp) => {
      //address city province cp
      this2.setState({ ["address"]: childProp.street }); //childProp.formatedAddress childProp.street
      this2.setState({ ["unitsuite"]: childProp.suite });
      //this.setState({ ["formatedAddress"]: childProp.formatedAddress });
      this2.setState({ ["city"]: childProp.city });
      this2.setState({ ["province"]: childProp.state2 });
      this2.setState({ ["province2"]: childProp.state });
      //this.setState({ ["country"]: childProp.country });
      this2.setState({ ["cp"]: childProp.zipCode });
      this2.setState({ ["listZipCodeAddresses"]: childProp.whenzipCode });

      // //console.log("YOOOOOOO WEEEEEEEEELL THEY CALLED ME  ");
      // //console.log(childProp.whenzipCode);
      if (childProp.whenzipCode.length > 0) {
        //const listAddressOnCode=[ ]
        let listAddressOnCode = childProp.whenzipCode.length > 0
        && childProp.whenzipCode.map((item, i) => { 
            const addcomp=item.obj;
            const addform=item.address;
            const addObj=item.addFormatted;
            
          return (
            <tr key={ i }>
                <td>{ addform }</td> 
                <td> <a href="#" data-address={i} onClick={this2.addressSetter}><FolderIcon /></a> </td>
              </tr>
          );
        }, this);
        this2.setState({["openPossibleZipCode"]: true, "listAddressOnCode": listAddressOnCode }); 
         
      }

      this2.clientFullInfoValidate();
     
    }, 10);
 
  }

  addressSetter = (event) => {
    const index=event.currentTarget.dataset.address;
    //const selVal= event.target.dataset.value; 
    const addressRaw=event.target.dataset.address;
    const streetNbTest = /^\d+-\d+\s?/;
    //342-276 Rue Notre-Dame Ouest, Montréal, QC H2Y 1T7, Canada
    //const addr=addressRaw.split(",");
    let childProp={};
    const listZipCodeAddresses=this.state.listZipCodeAddresses; 
    childProp=listZipCodeAddresses[index].addFormatted;
    //console.log("QQweeeeeeeeeeelZZZ addFormatted addFormatted "+index); console.log(childProp); 
     
    const country=childProp.country==="États-Unis"? "Etats-Unis":childProp.country;
    this.setState({ ["country"]: country });  //États-Unis
      
    let this2=this;
    setTimeout(function(){  
      //address city province cp
      this2.setState({ ["address"]: childProp.address }); //childProp.street  childProp.formatedAddress }
      this2.setState({ ["unitsuite"]: childProp.suite }); 
      this2.setState({ ["city"]: childProp.city });
      this2.setState({ ["province"]: childProp.state2 }); 
      this2.setState({ ["province2"]: childProp.state }); 
      this2.setState({ ["cp"]: childProp.zipCode }); 
      this2.setState({["openPossibleZipCode"]: false}); 
      this2.clientFullInfoValidate();
    }, 10);
 
  };

  openZipCodeAddressSetter = (event) => {
    this.setState({["openPossibleZipCode"]: true});
  };

  clientCustomerSetter = (event) => {
    const index=event.currentTarget.dataset.client;
    //const selVal= event.target.dataset.value; 
    let newValue={};
    const listZipCodeClient=this.state.listClientOnCode; 
    newValue=listZipCodeClient[index];
    //console.log("QQweeeeeeeeeeelZZZ clientCustomerSetter "+index); console.log(newValue);
    
    this.setState({ ["clientInfo"]: newValue}); 
            const clientInfo={  
              id_clients:newValue.id, 
              idClient:newValue.id, 
              firstname:newValue.firstname, 
              lastname:newValue.lastname, 
              cell:newValue.cell, 
              terms:newValue.terms, 
              active:newValue.active,  
              email:newValue.email, 
              id_users:parseInt(newValue.id_users), 
              language:newValue.language, 
              push:newValue.push, 
              id_zendesk:newValue.id_zendesk, 
              sms:newValue.sms,
              occupation:newValue.occupation,  
    
            }; 
    this.setState(clientInfo ); //xaza  this.setState({ clientInfo });
    //if(propstep==1){ this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]:3 }); }
    //else{ this.setState({ ["globalStepWorkflow"]: 2 }); }

    this.setState({ ["existedClientRO"]: true, ["emailDoesNotExist"]: 1, ["openPossibleListOfClient"]: false});

    this.clientFullInfoValidate();
      

    //get client Address
    const clientId=newValue.id;
    setTimeout(() => {
      this.getClientFullAddress(clientId); 
    }, 0); 
     
 
  };

  showToast = (arg, toastType) => {
    //toastType: success or error or warning or info	  
    //console.log("showToast showToast "); //console.log(arg);
    //alert("hello it is an eventDrop here "+arg.date);
    //Toastr("Hello I am a toast message right here, for testing purpose...");
    //toastrMessage: '', toastrType: '', toastrDuration: 6000, 
    this.setState({ // update a property
        toastrMessage: arg, 
      toastrType: toastType
      });
    setTimeout(() => { 
      this.setState({ 
        toastrMessage: "", 
        toastrType: 'success'
      }); 
    }, 5500);
  }

  getClientSearchSuggestion(search) {   
        const valueClientSearch=this.state.valueClientSearch;
        const inputValueClientSearch=this.state.inputValueClientSearch;
        if(valueClientSearch===inputValueClientSearch){ 
          //console.log('WE DONOT GO TO SERVER FOR  '+inputValueClientSearch);  
          return false; 
        }
        //this.props.getClientSearch(serachObj)) getB2BClientSearch 
        const clientId= this.state.idClient;
        const serachObj={search:search, searchEmail:"", clientId:clientId};
        Promise.resolve(this.props.getClientSearch(serachObj))
        .then((response)=>{  
          this.setState({ ["listOfSuggestion"]: response.NeoClientsOne }); 
          return response;
        });
        
  }

  getClientSearchSuggestion2(search) {   
    const valueClientSearch=this.state.valueClientSearch2;
    const inputValueClientSearch=this.state.inputValueClientSearch2;
    if(valueClientSearch===inputValueClientSearch){ 
      //console.log('WE DONOT GO TO SERVER FOR  '+inputValueClientSearch);  
      return false; 
    }
    //this.props.getClientSearch(serachObj)) getB2BClientSearch  
    const serachObj={search:search, searchEmail:""};
    Promise.resolve(this.props.getB2BClientSearch(serachObj))
    .then((response)=>{
      let showCreateBtn=false;
      if(response.NeoClientsOne.length===0){ showCreateBtn=true; }  
      this.setState({ ["listOfSuggestion2"]: response.NeoClientsOne, ["showCreateBtnManual"]:showCreateBtn }); 
      return response;
    });
    
}

  getClientSearchSuggestionOld(search) {   
    const valueClientSearch=this.state.valueClientSearch;
    const inputValueClientSearch=this.state.inputValueClientSearch;
    if(valueClientSearch===inputValueClientSearch){ 
      //console.log('WE DONOT GO TO SERVER FOR  '+inputValueClientSearch);  
      return false; 
    }
    const serachObj={search:search, searchEmail:""};
    Promise.resolve(this.props.getClientSearch(serachObj))
    .then((response)=>{  
      this.setState({ ["listOfSuggestion"]: response.NeoClientsOne }); 
      return response;
    });
    
  }

  getClientFullAddress(clientId){   
      const valueClientSearch=this.state.valueClientSearch;
      const inputValueClientSearch=this.state.inputValueClientSearch;
      const this00=this;
      this.setState({ ["isLoaderOpen"]: true});
      const clientAddress0={
        idAddress:0, 
        address:"", 
        city:"", 
        province:"QC", 
        cp:"", 
        country:"Canada", 
        company:"",  
      };

      this.setState( clientAddress0 ); //resetting address before change

      Promise.resolve(this.props.getClientAddress(clientId))
      .then((response)=>{ 
        const address=response.NeoAddressOne[0];
        if(response.NeoAddressOne.length >0 && address!==null && address!==undefined){
          this.setState({ ["clientAddress"]: address }); 
  
      
          const clientAddress={
            idAddress:address.id, 
            address:address.address, 
            city:address.city, 
            province:address.province, 
            cp:address.cp, 
            country:address.country, 
            company:address.company,  
          };

          this.setState( clientAddress ); //this.setState({ clientAddress }); 

          this.clientFullInfoValidate();
          this.sethandleChangeProvince(address.province);
        }else{
          this.clientFullInfoValidate();
        }
        this.setState({ ["isLoaderOpen"]: false});  
        return response;
      }).catch(function(error){ 
        this00.setState({ ["isLoaderOpen"]: false});  
        alert(error);
      });
      
  }

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    const NeoVentes = {id: id, name: name, description: description, amount: amount, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoVentes[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateNeoVentes(NeoVentes, 1); 
		//this.props.addNeoVentes(NeoVentes); 
	}else{ 
		this.props.addOrUpdateNeoVentes(NeoVentes, 2);
		//this.props.updateNeoVentes(NeoVentes);
		
	}
  };

  handleSubmitClientInfo = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = this.state.terms;
    const active = this.state.active;
    const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation=this.state.occupation;
    const NeoClients = {id: id, firstname: firstname, lastname: lastname, cell: cell, terms: terms, active: active, created: created, email: email, id_users: id_users, language: language, push: push, id_zendesk: id_zendesk, sms: sms, stripe_id: stripe_id, occupation:occupation, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoClients[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateNeoClients(NeoClients, 1); 
		//this.props.addNeoClients(NeoClients); 
	}else{ 
		this.props.addOrUpdateNeoClients(NeoClients, 2);
		//this.props.updateNeoClients(NeoClients);
		
	}
  };

  handleGetCustomerInfoPayment2= (event) => {                                                             
    //event.preventDefault();
    const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation=this.state.occupation;
    const neoClients = {idClient: idClient, firstname: firstname, lastname: lastname, cell: cell, terms: terms, active: active, email: email, id_users: id_users, language: language, push: push, id_zendesk: id_zendesk, sms: sms, stripe_id: stripe_id, occupation:occupation, }
  
    
    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province0 = this.state.province;
    const province2 = this.state.province2;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created; 
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if(unitsuite!==""){ address = unitsuite +"-"+ address; }
    let province ="";
    if(province0.length >2){ 
      province = province0.substring(0, 2); 
      address += " ("+province0+")"; 
    }else{ province = province0; }
    const neoAddress = {idAddress: idAddress, id_clients: id_clients, address: address, city: city, province: province, cp: cp, country: country, active: active2, tel: tel, ext: ext, company: company, name: nameNx2,}
    

    //productId:0, productTitre:'', product:{}, productPrice:'', productPriceTax:'', productPriceTaxOnly:0, productExtraOptions:[], productExtraOptionCtrl:null, showProductOption:false, 

    const productId=this.state.productCart.length; //const productId=this.state.productId;
    if(productId <1){
      //errorFound++;
      alert(`Erreur! Vous devez choisir un produit au moins`); return false; 
    }

    const productPriceTotal= this.state.grandTotal; 
    // if(productPriceTotal==0){
    //   this.showToast("Erreur! Pour continuer avec la transaction, le montant doit être superieur à 0 !", "error");
    //   return false;
    // }


    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    //const NeoVentes = {id: id, name: name, description: description, amount: amount, };
    const neoVentes = [];
    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 



    const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;  // 98989 isdelete 1
    let subTotal=0;  let totTagTotOptions=0; let totProd=0;
    productCart.length > 0
    	&& productCart.map((item, i) => { 
        const isDelete=item.isdelete;
        const myItemtxt=""+item.id; 
        if(myItemtxt.indexOf("98989") <0 && isDelete===undefined){ 
          totProd++;
        const productPrice= item.productPrice2===0?item.productPrice:item.productPrice2;
        const tarifBase=(parseFloat(productPrice)*1); //(parseFloat(item.productPrice)*1);
        const tarifAmount=(tarifBase+parseFloat(item.totOptions));
        subTotal +=parseFloat(productPrice);  //parseFloat(item.productPrice);
        totTagTotOptions +=parseFloat(item.totOptions);
        const tax1= (((tarifAmount*1)*TAX1)).toFixed(2);
        const tax2= (((tarifAmount*1)*TAX2)).toFixed(2);
        const tax_total= (tarifAmount*TAX_TOTAL).toFixed(2);
        const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2);
        const options=item.options;
        const cticket=item.cticket;
        const pages=item.pages;  const perPage=item.perPage;
        const subNeoVentes = {isSub: true, id: item.id, tax1: tax1, tax2: tax2, sub_total: tarifAmount, 
          tax_total: tax_total, total: totalForItem, amount: tarifAmount, options: options, 
          tarifBase: tarifBase, createTicket: cticket, pages:pages, perPage:perPage,
          productPrice:item.productPrice, productPrice2:item.productPrice2 };
        neoVentes.push(subNeoVentes);   
        }

    }, this); 

    if(totProd === 0){ 
      this.showToast("Erreur! Pour continuer il faut avoir au moins un produit valide !", "error");
      return false;
    }

    const tarifAmount=subTotal+totTagTotOptions;
    const tax1= (((tarifAmount*1)*TAX1)).toFixed(2);
    const tax2= (((tarifAmount*1)*TAX2)).toFixed(2);
    const tax_total= (tarifAmount*TAX_TOTAL).toFixed(2);
    const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);
    const mainNeoVentes = {isSub: false,  tax1: tax1, tax2: tax2, sub_total: tarifAmount, tax_total: tax_total, total: totalForItem };
    neoVentes.push(mainNeoVentes);

    let neoPromocode= {id: 0, percentage: 0, name: name, amount: 0, tax1: 0, tax2: 0 };
    //cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
    const cuponcodeId=this.state.cuponcodeId;
    const cuponcodeName=this.state.cuponcodeName;
    const promoAmount=this.state.promoAmount;
    const promoPercentage=this.state.promoPercentage;
    const promoTax1=this.state.promoTax1;
    const promoTax2=this.state.promoTax2;
    if(cuponcodeId  > 0){ 
      neoPromocode= {id: cuponcodeId, percentage: promoPercentage, name: cuponcodeName, amount: promoAmount, tax1: promoTax1, tax2: promoTax2 };
    }

    const stripeToken=this.state.stripeToken;
    let pMethod=1;
    // if(stripeToken  !==''){ 
    //   pMethod=1; 
    // }

    const dossier=this.state.dossier;
    pMethod=this.state.pMethod;  
    
    const downPayment=this.state.nowPayment;
    const paymentRound=this.state.paymentRound;
    const isRecurrent=this.state.isRecurrentPayment;
    const qtyPayment=this.state.qtyPayment;
    const laterpayment=this.state.laterpayment; 
    const fromDatePayment=this.state.fromDatePayment;
    const paymentWay=this.state.paymentWay; //full, partial, recurrent
    const neoPayments= {id: id,  
    downPayment:downPayment, paymentRound: paymentRound, paymentWay: paymentWay,
    isRecurrent: isRecurrent, qtyPayment: qtyPayment, recPaymentDay: laterpayment,
    recPaymentDate: fromDatePayment, };

    const id_invoice=this.state.id_invoice;
    
  

	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`@@@@@@@@@@@ ${fieldName}: ${obj.check}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoClients[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
        errorFound++;
        //console.log(`eroooooooooor in ${fieldName}: ${fieldValue}`);
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ 
    //alert(`Vous avez des erreurs dans le formulaire, veuillez de vérifier avant d'envoyer.`); 
    this.showToast("Vous avez des erreurs dans le formulaire, veuillez de vérifier avant d'envoyer.", "error"); 
    return false; 
  }
  
  //ClientB2B 
  //isupsale:false, upsaleTicket:"", isAmerindian:false, no_amerindien:"", 
  const isupsale=this.state.isupsale;
  const upsaleTicket=this.state.upsaleTicket;
  const isAmerindian=this.state.isAmerindian;
  const no_amerindien=this.state.no_amerindien;
  // if(isupsale===true && (upsaleTicket===null || upsaleTicket==="")){
  //   this.showToast("Erreur! Pour upsell tu dois choisir un ticket éxistant pour l'attacher!", "error");
  //    return false;
  // }
  // if(isAmerindian===true && (no_amerindien===null || no_amerindien==="")){
  //   this.showToast("Erreur! Pour le statut d'amérindien tu dois insérer le numéro de la carte ameridienne!", "error");
  //    return false;
  // }
  const b2b_client_id=this.state.b2b.id;
  const neoVentesBlocks={"client":neoClients, "address":neoAddress, "sales":neoVentes, 
  "promocode":neoPromocode, "payment":neoPayments, "dossier":dossier, "pMethod":pMethod, 
  "stripeToken":stripeToken, "id_invoice": id_invoice, "b2b_client_id": b2b_client_id,
  "isupsale":isupsale, "upsaleTicket":upsaleTicket, "isAmerindian":isAmerindian, "no_amerindien":no_amerindien }; 
  const this00=this;  //b2b_client_id
  let rawError={};

  //console.log("CHEEEEEEEEEEEEECKING ON", neoVentesBlocks); return false;
  //addOrUpdateNeoVentesBUG
  
  this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""}); 
    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived=this.state.totalPaymentReceived;
    let paymentsReceived=this.state.paymentsReceived;
    Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
    .then((response)=>{ 
      //const resp=response.NeoVentes.transaction;
      const resp=response.NeoVentes;
      rawError=response;
      //console.log('RESPONSE PAAAAAAAAAAAAAYMENT OJOJO ');  
      //console.log(resp); 
      /*if(resp.paymentAmount  > 0){ 
        const txtv=((totalPaymentReceived*1)+(downPayment*1));
        paymentsReceived.push(downPayment);
        const pround=paymentRound+1;
        //console.log('///\\\RESPONSE PAAAAAAAAAAAAAYMENT OJOJO pround '+pround);  
        this.setState({ ["idClient"]: resp.id_clients, ["id_clients"]: resp.id_clients,
        ["id_users"]: resp.id_users, ["idAddress"]: resp.id_address, 
        ["id_invoice"]: resp.id_invoice, ["totalPaymentReceived"]: txtv,
        ["paymentsReceived"]: paymentsReceived, ["paymentRound"]: pround, ["paymentWayRO"]: true,}); 
        //console.log('///\\\ 3 RESPONSE PAAAAAAAAAAAAAYMENT OJOJO pround '+pround);  
        this.calculateGrandTotal(null);
        //console.log('///\\\ 4 RESPONSE PAAAAAAAAAAAAAYMENT OJOJO pround '+pround);  
        this.showToast("Félicitations!!! On a finit votre transaction avec succès!", "success"); 

      }*/
      //this.setState({ ["listOfSuggestion"]: response.NeoClientsOne }); // ClientB2B 
      //this.showToast("Félicitations/Congratulations!!! On a finit votre transaction avec succès!", "success"); 
      this.showToast("Félicitations/Congratulations!!!", "success"); 
      this.setState({ ["isLoaderOpen"]: false,  ["openTransactionPopup"]: true}); 
      setTimeout(() => {
        //this.calculateGrandTotal(null) ;
        this.handleCloseTransactionPopup(null) ;
         
      }, 30000); 
      return response;
    }).catch(function(error){ 
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n There was some error from the expected data from the server. Please try again later."
      }
      this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      console.log(rawError);
       
      //localStorage.setItem("transerror", JSON.stringify(error));
      //localStorage.setItem("transerrorrawresp", JSON.stringify(rawError));
      //alert(errMessage); //error
    });


  };

  handleMakePartialPayment= (event) => {                                                             
    //event.preventDefault();
    const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation=this.state.occupation;
    const neoClients = {idClient: idClient, firstname: firstname, lastname: lastname, cell: cell, terms: terms, active: active, email: email, id_users: id_users, language: language, push: push, id_zendesk: id_zendesk, sms: sms, stripe_id: stripe_id, occupation:occupation, }
  
    
    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created; 
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if(unitsuite!==""){ address = unitsuite +"-"+ address; }
    const neoAddress = {idAddress: idAddress, id_clients: id_clients, address: address, city: city, province: province, cp: cp, country: country, active: active2, tel: tel, ext: ext, company: company, name: nameNx2,}
    

    //productId:0, productTitre:'', product:{}, productPrice:'', productPriceTax:'', productPriceTaxOnly:0, productExtraOptions:[], productExtraOptionCtrl:null, showProductOption:false, 

    const productId=this.state.productCart.length; //const productId=this.state.productId;
    const productPriceTotal= this.state.grandTotal; 
    
    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    //const NeoVentes = {id: id, name: name, description: description, amount: amount, };
    const neoVentes = [];

    const downPayment=this.state.nowPayment;

    const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;
    let subTotal=0;  let totTagTotOptions=0;
    
    const subNeoVentes = {isSub: true, id: 0, tax1: 0, tax2: 0, sub_total: 0, 
      tax_total: 0, total: downPayment, amount: downPayment, options: [], 
      tarifBase: downPayment, createTicket: true, pages:0, perPage:0,
      productPrice:0, productPrice2:downPayment };
    neoVentes.push(subNeoVentes);

    const tarifAmount=downPayment; //subTotal+totTagTotOptions;
    const tax1= 0;
    const tax2= 0;
    const tax_total= 0;
    const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);
    const mainNeoVentes = {isSub: false,  tax1: tax1, tax2: tax2, sub_total: tarifAmount, tax_total: tax_total, total: totalForItem };
    neoVentes.push(mainNeoVentes);

    let neoPromocode= {id: 0, percentage: 0, name: name, amount: 0, tax1: 0, tax2: 0 };
    //cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
    const cuponcodeId=this.state.cuponcodeId;
    const cuponcodeName=this.state.cuponcodeName;
    const promoAmount=this.state.promoAmount;
    const promoPercentage=this.state.promoPercentage;
    const promoTax1=this.state.promoTax1;
    const promoTax2=this.state.promoTax2;
 
    const stripeToken=this.state.stripeToken;
    let pMethod=1;
    
    const dossier=this.state.dossier;
    pMethod=this.state.pMethod;  
     
    //const downPayment=this.state.nowPayment;
    const paymentRound=this.state.paymentRound;
    const isRecurrent=this.state.isRecurrentPayment;
    const qtyPayment=this.state.qtyPayment;
    const laterpayment=this.state.laterpayment; 
    const fromDatePayment=this.state.fromDatePayment;
    const paymentWay=this.state.paymentWay; //full, partial, recurrent
    const neoPayments= {id: id,  
    downPayment:downPayment, paymentRound: paymentRound, paymentWay: paymentWay,
    isRecurrent: isRecurrent, qtyPayment: qtyPayment, recPaymentDay: laterpayment,
    recPaymentDate: fromDatePayment, };

    const id_invoice=this.state.id_invoice;
    
  

	let errorFound=0;
  const neoVentesBlocks={"client":neoClients, "address":neoAddress, "sales":neoVentes, 
  "promocode":neoPromocode, "payment":neoPayments, "dossier":dossier, "pMethod":pMethod, 
  "stripeToken":stripeToken, "id_invoice": id_invoice };

  if(this.actionMode==='partialInvoice'){
    neoVentesBlocks["partialInvoice"]=100;
  }


  let rawError={};  
  const this00=this;
  
  this.setState({ ["isLoaderOpen"]: true}); 
    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived=this.state.totalPaymentReceived;
    let paymentsReceived=this.state.paymentsReceived;
    Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
    .then((response)=>{ 
      const resp=response.NeoVentes.transaction;
      rawError=response;
      //console.log('RESPONSE PAAAAAAAAAAAAAYMENT OJOJO ');  
      //console.log(resp); 
      if(resp.paymentAmount  > 0){ 
        const txtv=((totalPaymentReceived*1)+(downPayment*1));
        paymentsReceived.push(downPayment);
        const pround=paymentRound+1;
        this.setState({ ["idClient"]: resp.id_clients, ["id_clients"]: resp.id_clients,
        ["id_users"]: resp.id_users, ["idAddress"]: resp.id_address, 
        ["id_invoice"]: resp.id_invoice, ["totalPaymentReceived"]: txtv,
        ["paymentsReceived"]: paymentsReceived, ["paymentRound"]: pround, ["paymentWayRO"]: true,}); 
        
        //this.calculateGrandTotal(null);

        this.showToast("Félicitations!!! On a finit votre transaction avec succès!", "success");


      }
      //this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
      this.setState({ ["isLoaderOpen"]: false});  
      return response;
    }).catch(function(error){ 
      this00.setState({ ["isLoaderOpen"]: false}); 
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n There was some error from the expected data from the server. Please try again later."
      }
      this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      console.log(rawError);

      //alert(error);
    });


  };

  payWithtChargeDesk= (event) => {
    event.preventDefault();
    this.setState({ ["pMethod"]: 4 });
    const productPriceTotal= this.state.grandTotal; //orderTotal
    const nowPayment= this.state.nowPayment;
    const paymentWay= this.state.paymentWay; 
    if(paymentWay!=="full"){
      this.paymentWayRef.current.focus();
      this.showToast("Erreur! Pour utiliser Charge Desk, la Façon de Payer doit être 'Payer Tout le Montant' !", "error");
      return false;
    }

    //const result=stripobject;  
    const paytryNow= Date.now(); 
    const timesbetween=((paytryNow*1)-(this.paymentBtnTryTimes3*1)); 
    if(timesbetween <60000){
      //this.stripeTryTimes=paytryNow;
      const timerest=(60-(timesbetween/1000));
      console.log("ooooooh wont go ---   "+timesbetween+" Svp essayez encore dans "+timerest+" secondes"); 
      this.showToast("Désolé, on ne processe pas plus qu'une fois en moins d'une minute. Svp essayez encore dans "+timerest+" secondes");
      return false;
    }else{ this.paymentBtnTryTimes3=paytryNow;  }

    //console.log("//SUCCESS bueno paso los filtros, NADA MAL"); //return false;

    //this.handleGetCustomerInfoPayment2(event);

    setTimeout(() => { 
      if(this.actionMode==='partialInvoice') {
        this.handleMakePartialPayment(event);
      }else{ this.handleGetCustomerInfoPayment2(event); }
      //this.handleGetCustomerInfoPayment2(event);
    }, 100);

  };

  payWithtInterac= (event) => {
    event.preventDefault();
    //this.setState({ ["isLoaderOpen"]: true}); return false;	
    this.setState({ ["pMethod"]: 3 }); 	
    const productPriceTotal= this.state.grandTotal; //orderTotal
    const nowPayment= this.state.nowPayment;
    const paymentWay= this.state.paymentWay; 
    if(paymentWay!=="full"){
      this.paymentWayRef.current.focus();
      this.showToast("Erreur! Pour utiliser Interac, la Façon de Payer doit être 'Payer Tout le Montant' !", "error");
      return false;
    }

    //const result=stripobject;  
    const paytryNow= Date.now(); 
    const timesbetween=((paytryNow*1)-(this.paymentBtnTryTimes2*1)); 
    if(timesbetween <60000){
      //this.stripeTryTimes=paytryNow;
      const timerest=(60-(timesbetween/1000));
      console.log("ooooooh wont go ---   "+timesbetween+" Svp essayez encore dans "+timerest+" secondes"); 
      this.showToast("Désolé, on ne processe pas plus qu'une fois en moins d'une minute. Svp essayez encore dans "+timerest+" secondes");
      return false;
    }else{ this.paymentBtnTryTimes2=paytryNow;  }

    //console.log("//SUCCESS bueno paso los filtros, NADA MAL"); //return false;

    
    //this.handleGetCustomerInfoPayment2(event);
    setTimeout(() => { 
      if(this.actionMode==='partialInvoice') {
        this.handleMakePartialPayment(event);
      }else{ this.handleGetCustomerInfoPayment2(event); }
      //this.handleGetCustomerInfoPayment2(event);
    }, 100);   


  };

  
  payWithCardOnFile= (event) => {
    event.preventDefault();
    //this.setState({ ["isLoaderOpen"]: true}); return false;	
    if(this.state.stripe_id===null || this.state.stripe_id===""){
      this.showToast("Erreur! Désolé ce client a aucune une carte éxistante!", "error");
      return false;
    }

    //this.setState({ ["pMethod"]: 3, ["stripeToken"]: "CARD_ON_FILE" }); 	
    const productPriceTotal= this.state.grandTotal; //orderTotal
    const nowPayment= this.state.nowPayment;
    const paymentWay= this.state.paymentWay; 
    if(paymentWay!=="full"){
      this.paymentWayRef.current.focus();
      this.showToast("Erreur! Pour utiliser carte éxistante, la Façon de Payer doit être 'Payer Tout le Montant' !", "error");
      return false;
    }

    //const result=stripobject;  
    const paytryNow= Date.now(); 
    const timesbetween=((paytryNow*1)-(this.paymentBtnTryTimes2*1)); 
    if(timesbetween <60000){
      //this.stripeTryTimes=paytryNow;
      const timerest=(60-(timesbetween/1000));
      console.log("ooooooh wont go ---   "+timesbetween+" Svp essayez encore dans "+timerest+" secondes"); 
      this.showToast("Désolé, on ne processe pas plus qu'une fois en moins d'une minute. Svp essayez encore dans "+timerest+" secondes");
      return false;
    }else{ this.paymentBtnTryTimes2=paytryNow;  }

    //console.log("//SUCCESS bueno paso los filtros, NADA MAL"); //return false;

    this.setState({ ["pMethod"]: 1, ["stripeToken"]: "CARD_ON_FILE" }); 	
    //this.handleGetCustomerInfoPayment2(event);
    setTimeout(() => { 
      if(this.actionMode==='partialInvoice') {
        this.handleMakePartialPayment(event);
      }else{ this.handleGetCustomerInfoPayment2(event); }
      //this.handleGetCustomerInfoPayment2(event);
    }, 100);   


  };

  payWithB2BOnFile= (event) => {
    event.preventDefault();
    //this.setState({ ["isLoaderOpen"]: true}); return false;	
    /*if(this.state.stripe_id===null || this.state.stripe_id===""){
      this.showToast("Erreur! Désolé ce client a aucune une carte éxistante!", "error");
      return false;
    }

    //this.setState({ ["pMethod"]: 3, ["stripeToken"]: "CARD_ON_FILE" }); 	
    const productPriceTotal= this.state.grandTotal; //orderTotal
    const nowPayment= this.state.nowPayment;
    const paymentWay= this.state.paymentWay; 
    if(paymentWay!=="full"){
      this.paymentWayRef.current.focus();
      this.showToast("Erreur! Pour utiliser carte éxistante, la Façon de Payer doit être 'Payer Tout le Montant' !", "error");
      return false;
    }

    //const result=stripobject;  
    const paytryNow= Date.now(); 
    const timesbetween=((paytryNow*1)-(this.paymentBtnTryTimes2*1)); 
    if(timesbetween <60000){
      //this.stripeTryTimes=paytryNow;
      const timerest=(60-(timesbetween/1000));
      console.log("ooooooh wont go ---   "+timesbetween+" Svp essayez encore dans "+timerest+" secondes"); 
      this.showToast("Désolé, on ne processe pas plus qu'une fois en moins d'une minute. Svp essayez encore dans "+timerest+" secondes");
      return false;
    }else{ this.paymentBtnTryTimes2=paytryNow;  }*/

    const paytryNow= Date.now(); 
    const timesbetween=((paytryNow*1)-(this.paymentBtnTryTimes2*1)); 
    if(timesbetween <60000){
      //this.stripeTryTimes=paytryNow;
      const timerest=(60-(timesbetween/1000));
      console.log("ooooooh wont go ---   "+timesbetween+" Svp essayez encore dans "+timerest+" secondes"); 
      this.showToast("Désolé, on ne processe pas plus qu'une fois en moins d'une minute. Svp essayez encore dans "+timerest+" secondes");
      return false;
    }else{ this.paymentBtnTryTimes2=paytryNow;  }

    //console.log("//SUCCESS bueno paso los filtros, NADA MAL"); //return false;

    this.setState({ ["pMethod"]: 5, ["stripeToken"]: "B2B_ON_FILE" }); 	
    //this.handleGetCustomerInfoPayment2(event);
    setTimeout(() => { 
      // if(this.actionMode==='partialInvoice') {
      //   this.handleMakePartialPayment(event);
      // }else{ this.handleGetCustomerInfoPayment2(event); }
      this.handleGetCustomerInfoPayment2(event);
    }, 100);   


  };

  handleProductNextStep = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const globalStepWorkflow=this.state.globalStepWorkflow;

    const productId=this.state.productCart.length; //const productId=this.state.productId;
    if(productId <1){ 
      alert(`Vous devez choisir au moins un produit.`); return false; 
    }


    const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;
    let subTotal=0;  let totTagTotOptions=0; let foundProduct=0;
    productCart.length > 0
    	&& productCart.map((item, i) => { 
        if(item.productTitre!==''){
          foundProduct++;
          //const tarifBase=(parseFloat(item.productPrice)*1);
          //const tarifAmount=(tarifBase+parseFloat(item.totOptions));
          //subTotal +=parseFloat(item.productPrice);
          //totTagTotOptions +=parseFloat(item.totOptions);
        } 

    }, this); 

    if(foundProduct <1){ 
      alert(`Vous devez choisir au moins un produit, il n'y a aucun produit dans votre panier.`); return false; 
    }

    this.workflowProductList=true;
    const workflowType=this.state.workflowType;

    /*if(globalStepWorkflow===2 && workflowType===2){
      //go to 3 
      //this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    }else if(globalStepWorkflow===2 && workflowType===3){
      //go to 2 
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }else if(globalStepWorkflow===3 && workflowType===2){
      //go to 4 
      //this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 4 }); 
    }else if(globalStepWorkflow===3 && workflowType===3){
      //stay in 2 in fact
      this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    } 
    else if(globalStepWorkflow===2){
      //go to 3
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    }
    else if(globalStepWorkflow===3){
      //go to 4 
      this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 4 });
    }*/




    if(!this.workflowClientInfo || this.state.firstname===""){ 
      this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    }else{
      this.setState({ ["globalStepWorkflow"]: 4 });
    }

    //this.showToast(`Next toast globalStepWorkflow ${globalStepWorkflow} workflowType ${workflowType}.`, "warning");

    //globalStepWorkflow  let workflowClientInfo=false;   let workflowProductList=false; 
    //this.setState({ ["globalStepWorkflow"]: 2 });
    this.navigationTopRefOnTop.current.focus();

  };

  handleClientInfoNextStep = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const globalStepWorkflow=this.state.globalStepWorkflow;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation=this.state.occupation;
    const NeoClients = {idClient: idClient, firstname: firstname, lastname: lastname, cell: cell, terms: terms, active: active, email: email, id_users: id_users, language: language, push: push, id_zendesk: id_zendesk, sms: sms, stripe_id: stripe_id, occupation:occupation, }
  
    
    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address = this.state.address;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created; 
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    const NeoAddress = {idAddress: idAddress, id_clients: id_clients, address: address, city: city, province: province, cp: cp, country: country, active: active2, tel: tel, ext: ext, company: company, name: nameNx2,}
  
 
    
  

	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`@@@@@@@@@@@ ${fieldName}: ${obj.check}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoClients[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
        errorFound++;
        //console.log(`eroooooooooor in ${fieldName}: ${fieldValue}`);
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
  //if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')} you have not passed the test on clientInfo`); return false; }
  if(errorFound > 0){ alert(`Quelques champs sont obligatoires, veuillez les remplir`); return false; }
	

    this.workflowClientInfo=true;
    const workflowType=this.state.workflowType;

    if(globalStepWorkflow===2 && workflowType===2){
      //go to 3 
      //this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    }else if(globalStepWorkflow===2 && workflowType===3){
      //go to 2 
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }else if(globalStepWorkflow===3 && workflowType===2){
      //go to 4 
      //this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 4 }); 
    }else if(globalStepWorkflow===3 && workflowType===3){
      //stay in 2 in fact
      this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }    
    else if(globalStepWorkflow===2){
      //go to 3
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    }
    else if(globalStepWorkflow===3){
      //go to 4 
      this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 4 });
    }

    /*if(!this.workflowProductList){ 
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }else{
      this.setState({ ["globalStepWorkflow"]: 4 });
    }*/

  //globalStepWorkflow  let workflowClientInfo=false;   let workflowProductList=false; 
  //this.setState({ ["globalStepWorkflow"]: 2 });
  this.navigationTopRefOnTop.current.focus();


  };

  handleClientInfoNextStepQ = (event) => {  
    
    const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;  // 98989 isdelete 1
    let subTotal=0;  let totTagTotOptions=0; let totProd=0;
    productCart.length > 0
    	&& productCart.map((item, i) => { 
        const isDelete=item.isdelete;
        const myItemtxt=""+item.id; 
        if(myItemtxt.indexOf("98989") <0 && isDelete===undefined){ 
          totProd++;
        // const productPrice= item.productPrice2===0?item.productPrice:item.productPrice2;
        // const tarifBase=(parseFloat(productPrice)*1); //(parseFloat(item.productPrice)*1);
        // const tarifAmount=(tarifBase+parseFloat(item.totOptions));
        // subTotal +=parseFloat(productPrice);  //parseFloat(item.productPrice);
        // totTagTotOptions +=parseFloat(item.totOptions);
        // const tax1= (((tarifAmount*1)*TAX1)).toFixed(2);
        // const tax2= (((tarifAmount*1)*TAX2)).toFixed(2);
        // const tax_total= (tarifAmount*TAX_TOTAL).toFixed(2);
        // const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2);
        // const options=item.options;
        // const cticket=item.cticket;
        // const pages=item.pages;  const perPage=item.perPage;
        // const subNeoVentes = {isSub: true, id: item.id, tax1: tax1, tax2: tax2, sub_total: tarifAmount, 
        //   tax_total: tax_total, total: totalForItem, amount: tarifAmount, options: options, 
        //   tarifBase: tarifBase, createTicket: cticket, pages:pages, perPage:perPage,
        //   productPrice:item.productPrice, productPrice2:item.productPrice2 };
        // neoVentes.push(subNeoVentes);   
        }

    }, this); 

    if(totProd === 0){ 
      this.showToast("Erreur! Pour continuer il faut avoir au moins un produit éligible pour le client !", "error");
      return false;
    }

    //go to 3
    this.workflowClientInfo=true;
    this.setState({ ["globalStepWorkflow"]: 3 });
    this.navigationTopRefOnTop.current.focus();
   };

  handleClientEligibility = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const globalStepWorkflow=this.state.globalStepWorkflow;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const NeoClients = {idClient: idClient, firstname: firstname, lastname: lastname, cell: cell, terms: terms, active: active, email: email, id_users: id_users, language: language, push: push, id_zendesk: id_zendesk, sms: sms, stripe_id: stripe_id, }
  
    
    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address = this.state.address;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created; 
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    const NeoAddress = {idAddress: idAddress, id_clients: id_clients, address: address, city: city, province: province, cp: cp, country: country, active: active2, tel: tel, ext: ext, company: company, name: nameNx2,}
   
	  let errorFound=0;
 

    /*if(!this.workflowProductList){ 
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }else{
      this.setState({ ["globalStepWorkflow"]: 4 });
    }*/

  //globalStepWorkflow  let workflowClientInfo=false;   let workflowProductList=false; 
  //this.setState({ ["globalStepWorkflow"]: 2 });
  
  //this.navigationTopRefOnTop.current.focus();

  const b2b = this.state.b2b;
  let rawError={};  
  const this00=this;
  
  this.setState({ ["isLoaderOpen"]: true, ["eligibilityChecked"]: 0,}); 
    //paymentsReceived: [0], totalPaymentReceived: 0,
    // const totalPaymentReceived=this.state.totalPaymentReceived;
    // let paymentsReceived=this.state.paymentsReceived;
    const elig={id:b2b.id, id_clients:b2b.id_clients, }
    Promise.resolve(this.props.checkClientEligibility(elig))
    .then((response)=>{ 
      const resp=response.NeoClientsOne; //NeoClientsOne
      rawError=response;
      //console.log('RESPONSE PAAAAAAAAAAAAAYMENT OJOJO ');  
      //console.log(response); 

      let productsList=[];
      const productListx=resp.serviceList;
       let productList = productListx.length > 0
       && productListx.map((item, i) => {  
         if(item!==null){
          productsList.push(item); 
         }
       return (
         null
       );
     }, this);
 
      //this.setState({ ["productsList"]: {"products": productsList}, });   
      this.productsList.products=productsList; 
 
      //this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
      this.setState({ ["isLoaderOpen"]: false, ["productsList"]: {"products": productsList},
      ["elibilityObj"]: resp, ["eligibilityChecked"]: 1, });   
      return response;
    }).catch(function(error){ 
      this00.setState({ ["isLoaderOpen"]: false}); 
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n There was some error from the expected data from the server. Please try again later."
      }
      this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      console.log(rawError);

      //alert(error);
    });


  };


  handleClientInfoNextStepLead = (event) => {  
    const { t } = this.props;
    const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;  // 98989 isdelete 1
    let subTotal=0;  let totTagTotOptions=0; let totProd=0;
     

    if(totProd === 0){ 
      //this.showToast("Erreur! Pour continuer il faut avoir au moins un produit éligible pour le client !", "error");
      //return false;
    }

    //go to 3
    // this.workflowClientInfo=true;
    // this.setState({ ["globalStepWorkflow"]: 3 });
    // this.navigationTopRefOnTop.current.focus();

    //sendTicketLead 
    const b2b = this.state.b2b;
    let rawError={};  
    const this00=this;
    //const pattPhone=/([\(]*\+?[0-9]*[\)]*[\s]*[\(]*\+?[0-9]+[\)]*[\s]*[\-]?[0-9]+[\-][0-9]+[\s]*)/;
    const pattPhone=/([\(]*\+?[0-9]*[\)]*[\s]*[\(]*\+?[0-9]{3}[\)]*[\s]*[\-]?[0-9]{3}[\-]?[0-9]{4}[\s]*)/ig;

    if(b2b.tel==="" || !pattPhone.test(b2b.tel)){ 
      this.showToast(t("zqwNVFrmEl22_x"), "error");
      return false;
    }

    //this.showToast("Teeeeeeeeest, just that"+b2b.tel, "warning"); return false;  
  
    this.setState({ ["isLoaderOpen"]: true, ["eligibilityChecked"]: 0,}); 
    //paymentsReceived: [0], totalPaymentReceived: 0,
    // const totalPaymentReceived=this.state.totalPaymentReceived;
    // let paymentsReceived=this.state.paymentsReceived;
    //const elig={id:b2b.id, id_clients:b2b.id_clients, };
    //const elig={id:0, ticketId:"", idb2bclient:b2b.id,id_clients:b2b.id_clients, };
    const elig={id:0, ticketId:"", idb2bclient:b2b.id,id_clients:b2b.id_clients, 
    email:b2b.email, tel:b2b.tel, };
    Promise.resolve(this.props.sendTicketLead(elig))
    .then((response)=>{ 
      const resp=response.NeoClientsOne; //NeoClientsOne
      rawError=response;
      console.log('RESPONSE-sendTicketLead PAAAAAAAAAAAAAYMENT OJOJO ');  
      console.log(response); 
      this.showToast("Felicitation on a crée le lead pour le client B2B!", "success");
 
      //this.setState({ ["productsList"]: {"products": productsList}, });    
      //this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
      this.setState({ ["isLoaderOpen"]: false,  });   
      return response;
    }).catch(function(error){ 
      this00.setState({ ["isLoaderOpen"]: false}); 
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n There was some error from the expected data from the server. Please try again later."
      }
      this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      console.log(rawError);

      //alert(error);
    });
   };


  handlePreviousStep = (event) => {
    const globalStepWorkflow=this.state.globalStepWorkflow;
    const workflowType=this.state.workflowType;
    this.workflowClientInfo=false;   this.workflowProductList=false;
    if(this.state.paymentRound >0){
      this.showToast("Attention! Vous avez déjà appliqué un paiement, donc il n'y a pas de raison d'aller en arrière.", "error");
      return false;
    }
    //console.log(" StepWorkflow="+globalStepWorkflow+" type flow="+workflowType); 
    if(globalStepWorkflow===4 && workflowType===2){
      //go to 3 
      this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    }else if(globalStepWorkflow===4 && workflowType===3){
      //go to 2 
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }else if(globalStepWorkflow===3 && workflowType===2){
      //go to 2 
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 2 }); 
    }else if(globalStepWorkflow===3 && workflowType===3){
      //stay in 3 in fact
      this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    }else if(globalStepWorkflow===3){
      //go to 2
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }
    else if(globalStepWorkflow===4){
      //go to 3 
      this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    }else if(globalStepWorkflow===3){
      //go to 2
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }

    this.navigationTopRefOnTop.current.focus();

    /*if(globalStepWorkflow===4){
      //go to 3 
      this.workflowProductList=true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    }else if(globalStepWorkflow===3){
      //go to 2
      this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }*/
  };

  clientFullInfoValidate = (event) => {
    setTimeout(() => {
      let errorFound=0;
      for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) { 
        if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
          this.setState({ ["e_"+fieldName]: false });
          let fieldValue= this.state[fieldName];  
          if(!this.validation.validate(obj.chkType, fieldValue)){
            errorFound++;
            this.setState({ ["e_"+fieldName]: true }); 	
          }
        } 
      }
      //if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
    });
  }

  handleFinalize= (event) => {
    const productPriceTotal= this.state.grandTotal; //orderTotal
    const nowPayment= this.state.nowPayment;
    const paymentWay= this.state.paymentWay; 
    const orderTotal=this.state.orderTotal;
    if(paymentWay==="partial" && orderTotal >0){ 
      this.showToast("Erreur! Pour finaliser la transaction vous devez payer tout le montant!", "error");
      return false;
    }

    //const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation=this.state.occupation;
    const NeoClients = {idClient: idClient, firstname: firstname, lastname: lastname, cell: cell, terms: terms, active: active, email: email, id_users: id_users, language: language, push: push, id_zendesk: id_zendesk, sms: sms, stripe_id: stripe_id, occupation:occupation, }
  
    
    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created; 
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if(unitsuite!==""){ address = unitsuite +"-"+ address; }
    const NeoAddress = {idAddress: idAddress, id_clients: id_clients, address: address, city: city, province: province, cp: cp, country: country, active: active2, tel: tel, ext: ext, company: company, name: nameNx2,}
     
    const productId=this.state.productCart.length; //const productId=this.state.productId;
   
    //const productPriceTotal= this.state.grandTotal; 
   

    const id = this.state.id;
    const name = this.state.name; 
    //const NeoVentes = {id: id, name: name, description: description, amount: amount, };
    const NeoVentes = [];

    const downPayment=this.state.nowPayment;

    const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;
    let subTotal=0;  let totTagTotOptions=0;
     

    const subNeoVentes = {isSub: true, id: 0, tax1: 0, tax2: 0, sub_total: 0, 
      tax_total: 0, total: downPayment, amount: downPayment, options: [], 
      tarifBase: downPayment, createTicket: true, pages:0, perPage:0,
      productPrice:0, productPrice2:downPayment };
    NeoVentes.push(subNeoVentes);

    const tarifAmount=downPayment; //subTotal+totTagTotOptions;
    const tax1= 0;
    const tax2= 0;
    const tax_total= 0;
    const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);
    const mainNeoVentes = {isSub: false,  tax1: tax1, tax2: tax2, sub_total: tarifAmount, tax_total: tax_total, total: totalForItem };
    NeoVentes.push(mainNeoVentes);

    let NeoPromocode= {id: 0, percentage: 0, name: name, amount: 0, tax1: 0, tax2: 0 }; 
    const cuponcodeId=this.state.cuponcodeId;
    const cuponcodeName=this.state.cuponcodeName;
    const promoAmount=this.state.promoAmount;
    const promoPercentage=this.state.promoPercentage;
    const promoTax1=this.state.promoTax1;
    const promoTax2=this.state.promoTax2;
    const stripeToken=this.state.stripeToken;
    let pMethod=1;
    const dossier=this.state.dossier;
    pMethod=this.state.pMethod;  
     
    //const downPayment=this.state.nowPayment;
    const paymentRound=this.state.paymentRound;
    const isRecurrent=this.state.isRecurrentPayment;
    const qtyPayment=this.state.qtyPayment;
    const laterpayment=this.state.laterpayment; 
    const fromDatePayment=this.state.fromDatePayment;
    //const paymentWay=this.state.paymentWay; //full, partial, recurrent
    const NeoPayments= {id: id,  
    downPayment:downPayment, paymentRound: paymentRound, paymentWay: paymentWay,
    isRecurrent: isRecurrent, qtyPayment: qtyPayment, recPaymentDay: laterpayment,
    recPaymentDate: fromDatePayment, };

    const id_invoice=this.state.id_invoice;
    if(id_invoice < 1) {  
      this.showToast("Erreur! Pour finaliser la transaction vous devez avoir un facture éxistante au préalable!", "error");
      return false;
    }
  

	let errorFound=0;
  const neoVentesBlocks={"client":NeoClients, "address":NeoAddress, "sales":NeoVentes, 
  "promocode":NeoPromocode, "payment":NeoPayments, "dossier":dossier, "pMethod":pMethod, 
  "stripeToken":stripeToken, "id_invoice": id_invoice };

  // if(this.actionMode==='partialInvoice'){
  //   neoVentesBlocks["partialInvoice"]=100;
  // }

  neoVentesBlocks["makeCloseOfSale"]=100;
  let rawError={};
  const this00=this;
  
  this.setState({ ["isLoaderOpen"]: true});  
    Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
    .then((response)=>{ 
      rawError=response;
      const resp=response.NeoVentes;
      //console.log('RESPONSE CLOSE PAAAAAAAAAAAAAYMENT OJOJO ');  
      //console.log(resp); 
      if(resp.closeId  > 0){ 
        
        this.setState({ ["openTransactionPopup"]: true});  
        this.showToast("Félicitations!!! On a fermé votre transaction avec succès!", "success");
        //this.setState(this.stateInit);
    
      }else{
        this.showToast("Désolé!!! On n'a pu fermer votre transaction malheureusement!", "error");
      }
      //this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
      this.setState({ ["isLoaderOpen"]: false});  
      return response;
    }).catch(function(error){ 
      //this00.setState({ ["isLoaderOpen"]: false}); 
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n There was some error from the expected data from the server. Please try again later."
      }
      this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      console.log(rawError); 
      //alert(error);
    });

    //this.showToast("test close! we are testing the close process!", "warning");

  };

  handleFinalizeCD= (event) => {
    if(window.confirm("Avant de fermer assurez-vous que le client à déjà payé et que c'est juste une erruer de chage Desk. \nÊtes-vous sur de continuer?")){
      this.handleFinalize(event);
    }
  }


  handleProductChange = (event) => {                                                             
    //event.preventDefault();  
    //const stepTrack=event.target.dataset.prodcontrol;
    const selVal= event.value;  //productId
    this.setState({ ["productId"]: selVal }); 
    //if(stepTrack==1){ this.setState({ ["globalStepWorkflow"]: 2 });  }
    this.setState({ ["globalStepWorkflow"]: 2, ["workflowType"]:2, ["goProd"]:parseInt(selVal) }); 
    this.workflowProductList=true;
    const product=this.getProductChange(selVal);
    this.applyProductChange(selVal);	
    this.showToast("Produit ajouté #"+product.titre, "success");	
    //console.log('well hellooooooooooooo '+selVal);
    //console.log(event);
  };

  handleProductChange2 = (event) => {                                                             
    //event.preventDefault();  
    //const stepTrack=event.target.dataset.prodcontrol;
    const selVal= event.value;  //productId
    this.setState({ ["productId"]: selVal }); 
    //if(stepTrack==1){ this.setState({ ["globalStepWorkflow"]: 2 });  }
    const product=this.getProductChange(selVal);
    this.applyProductChange(selVal);
    this.showToast("Produit ajouté #"+product.titre, "success"); 
  };

  getProductChange =(inputValue) => {   
    let productItem = {};
    this.productsList.products.length > 0
    	&& this.productsList.products.map((item, i) => { 
        if(item.type.indexOf('hidden') <0 && item.titre!=='' && item.id==inputValue){
          //productOptions.push({ value: item.id, label: item.titre }); 
          productItem=item;
          return item;
        } 
      });

      return productItem; //return false;
  }       

  resetToNewCustomer = (event) => {                                                             
    //event.preventDefault();  
    //const stepTrack=event.target.dataset.prodcontrol; 
    this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]:3  });
    this.setState({ ["valueClientSearch"]: '', ["inputValueClientSearch"]: '', ["selectedIdSuggestion"]: '', ["clientInfo"]: {}, ["clientAddress"]: {} });

    const stateInitVar = {
      id:0,
      name:'', e_name: false,
      description:'', e_description: false,
      amount:'', e_amount: false,
      
      idClient:'0',
      firstname:'', e_firstname: false,
      lastname:'', e_lastname: false,
      cell:'', e_cell: false,
      terms:'', e_terms: false,
      active:'', e_active: false,
      created:'', e_created: false,
      email:'', e_email: false,
      id_users:'0', e_id_users: false,
      language:'fr', e_language: false,
      push:'', e_push: false,
      id_zendesk:'', e_id_zendesk: false,
      sms:1, e_sms: false,
      stripe_id:'', e_stripe_id: false,
  
  
      idAddress:0, 
      address:'', e_address: false,
      city:'', e_city: false,
      province:'QC', e_province: false,
      cp:'', e_cp: false,
      country:'Canada', e_country: false,
      company:'', e_company: false,
      existedClientRO: false, 
       
      listOfSuggestion:[],
      valueClientSearch:'', 
      inputValueClientSearch:'',
      selectedIdSuggestion:'',
      clientInfo:{ }, 
      clientAddress:{ },
      globalStepWorkflow: 3,  
    };

    this.setState(stateInitVar);
    this.navigationTopRefOnTop.current.focus();
     
  };

  restartOver = (event) => {                                                             
    //event.preventDefault();  
    //const stepTrack=event.target.dataset.prodcontrol; 
    this.handleCloseTransactionPopup(event);
  };

  

  setExtraServiceForProduct = (event) => {     
    //event.preventDefault(); 
    //const inputValue = newValue.replace(/\W/g, '');
    //this.setState({ inputValue });

    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 

    const selVal= event.target.value;  //productId
    const arVal=selVal.split('-');
    const index=arVal[0];  const prodId=arVal[1];  const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
   
    let productCart=this.state.productCart;
    //console.log("setExtraServiceForProduct ", selVal, "prodOptId", prodOptId, "mnt", amountOpt); 
     
    //console.log(productCart[index]);
    let totTagTotOptions=(productCart[index].totOptions*1);
    let optionsProd=productCart[index].options;
    
    if(event.target.checked){ 
      //totTagTotOptions +=amountOpt; optionsProd.push(amountOpt); 
      totTagTotOptions +=amountOpt; optionsProd.push({id: prodOptId, mnt:amountOpt}); 
    }else { 
      totTagTotOptions -=amountOpt;  
      //const indz=optionsProd.indexOf(amountOpt);
      //const indz=optionsProd.indexOf({id: prodOptId, mnt:amountOpt});
      const indz=this.indexOfElement(optionsProd, prodOptId, amountOpt);
      //console.log('REACT ] indzindzindzindzindzindz  '+indz);
      if(indz >-1){ 
        optionsProd.splice(indz, 1); 
      }
    }

    productCart[index].totOptions=totTagTotOptions;
    let tarif =((productCart[index].productPrice*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  
    const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
    const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ; 
    productCart[index].options=optionsProd;
    const orderServices=totTagTotOptions; 
    const orderSubtotals=((tarif*1)).toFixed(2);
   
    productCart[index].productPriceTax=productPriceTotal;
    productCart[index].productPriceTaxOnly=productPriceTaxOnly;
    this.setState({ ["productCart"]:  productCart });
 
    /*this.prodTaxOnly[index].current.innerHTML=productPriceTaxOnly;
    this.prodPriceAndTax[index].current.innerHTML=productPriceTotal;
    this.prodPriceAndTax2[index].current.innerHTML=productPriceTotal;
    this.prodPriceServices[index].current.innerHTML=orderServices;
    this.prodPriceSubtotals[index].current.innerHTML=orderSubtotals;*/
    this.calculateGrandTotal(null) ;

  }

  showInfoForProduct = (event) => {  
    const prodId=event.currentTarget.dataset.id;
    //console.log("looooog prod id "+prodId);
    const this00=this;
    const lang=this.state.language;
    const arrProd=[6,8,9]; 
    let showProdMulti=0;
    if(prodId > 0){
      if(arrProd.includes(parseInt(prodId))){  
        if(lang==="en"){ showProdMulti=2; }else{ showProdMulti=1; }
      } 
      this.setState({ ["isLoaderOpen"]: true, ["showProdMulti"]: showProdMulti,}); 
      //const lang=this.state.language;
      Promise.resolve(this.props.getProductInfo(prodId, lang))
        .then((response)=>{ 
          //console.log("000PREEEEEEEEEEEEXX response from server .... ");  console.log(response.NeoCodePromoOne);  
          this.setState({["infoProduct"]: response.NeoCodePromoOne,  });
          this.setState({ ["isLoaderOpen"]: false});  
          return response;
        }).catch(function(error){ 
          this00.setState({ ["isLoaderOpen"]: false});  
          alert(error);
        });
    } 
  }

  setTicketForProduct  = (event) => {  //(event) {        
    //event.preventDefault(); 
    const selVal= event.target.value;  //productId
    const arVal=selVal.split('-');
    const index=arVal[0];  const prodId=arVal[1];  //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
    
    let productCart=this.state.productCart;
   
    if(event.target.checked){ 
      productCart[index].cticket=true;
    }else { 
      productCart[index].cticket=false;
    }
    //this.state.productCart[index].cticket
  
    //productCart[index].options=optionsProd;
 
    this.setState({ ["productCart"]:  productCart });

  }

  setPageDocForProduct  = (event) => {   
    event.preventDefault(); 
    const selVal= event.target.dataset.value; //event.target.value;   
    let selPage= event.target.value; 
    const arVal=selVal.split('-');
    const index=arVal[0];  const prodId=arVal[1];  const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]); 
    const maximum=(event.target.dataset.maximum*1);  
    //const perPage=(event.target.dataset.perpage); 
    const perPage=amountOpt;  
    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
     
    //console.log('dynamic change doc page= '+selPage+' ind='+index);

    if(selPage >maximum){ 
      event.target.value=1; //maximum;
      this.showToast("Erreur désolé la quantide de page maximum est "+maximum+" pas "+selPage, "error");	
      //return false;
    }
 
    let productCart=this.state.productCart;
     
    let totTagTotOptions=0; //(productCart[index].totOptions*1);
    let optionsProd=[]; //productCart[index].options;

    //if(selPage==1){  selPage=0; } //to avoid $25$ if 1 page only
    
    totTagTotOptions=((selPage-1)*perPage); 
    //optionsProd.push({id: 'perPage', mnt:totTagTotOptions}); 
    optionsProd.push({id: 'perPage', mnt:totTagTotOptions }); 
    //, pg:selPage, perPage:perPage
    
    productCart[index].totOptions=totTagTotOptions;
    const productPrice= productCart[index].productPrice2===0?productCart[index].productPrice:productCart[index].productPrice2;
    console.log("totOptions totOptions ", totTagTotOptions, selPage, perPage, "selVal", selVal); 

    let tarif =((productPrice*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  
    const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
    const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ; 
    productCart[index].options=optionsProd;
    const orderServices=totTagTotOptions; 
    const orderSubtotals=((tarif*1)).toFixed(2);
  

    //const productPriceTotal= ((((tarif*1)*TAX1)+((tarif*1)*TAX2))+(tarif*1)).toFixed(2); 
    const orderTVQ=((tarif*1)*TAX1).toFixed(2);
    const orderTPS=((tarif*1)*TAX2).toFixed(2);  

    productCart[index].productPriceTax=productPriceTotal;
    productCart[index].productPriceTaxOnly=productPriceTaxOnly;
    this.setState({ ["productCart"]:  productCart });

    /*this.prodTaxOnly[index].current.innerHTML=productPriceTaxOnly;
    this.prodPriceAndTax[index].current.innerHTML=productPriceTotal;
    this.prodPriceAndTax2[index].current.innerHTML=productPriceTotal;
    this.prodPriceTVQ[index].current.innerHTML=orderTVQ; 
    this.prodPriceTPS[index].current.innerHTML=orderTPS; 
    this.prodPriceServices[index].current.innerHTML=orderServices;
    this.prodPriceSubtotals[index].current.innerHTML=orderSubtotals;*/
    // this.calculateGrandTotal(null) ;

    productCart[index].options=optionsProd; 
    productCart[index].pages= selPage >0? selPage : 1;
    productCart[index].perPage=perPage;
     
 
    this.setState({ ["productCart"]:  productCart });
    this.calculateGrandTotal(null) ;
  }

  setNewPriceForProduct  = (event) => {    
    //event.preventDefault(); 
    const selVal= event.target.value;  //productId
    const selVal2= event.target.dataset.value;  
    const arVal=selVal2.split('-');
    const index=arVal[0];  const prodId=arVal[1];  //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
   
    let productCart=this.state.productCart;

    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
   
    //productCart[index].options=optionsProd;
    productCart[index].productPrice2=selVal;
    //this.prodPrice[index].current.value=selVal;
 
    //this.setState({ ["productCart"]:  productCart });


    let totTagTotOptions=(productCart[index].totOptions*1); // 0;
    let optionsProd=[]; //productCart[index].options; 
    
    // totTagTotOptions=((selPage-1)*perPage); 
    // optionsProd.push({id: 'perPage', mnt:totTagTotOptions}); 
    
    // productCart[index].totOptions=totTagTotOptions;
    const productPrice= productCart[index].productPrice2===0?productCart[index].productPrice:productCart[index].productPrice2;

    let tarif =((productPrice*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  
    const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
    const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ; 
    //productCart[index].options=optionsProd;
    const orderTVQ=((tarif*1)*TAX1).toFixed(2);
    const orderTPS=((tarif*1)*TAX2).toFixed(2);  
    const orderServices=totTagTotOptions; 
    const orderSubtotals=((tarif*1)).toFixed(2);
  

    productCart[index].productPriceTax=productPriceTotal;
    productCart[index].productPriceTaxOnly=productPriceTaxOnly;
    this.setState({ ["productCart"]:  productCart });

    /*this.prodTaxOnly[index].current.innerHTML=productPriceTaxOnly;
    this.prodPriceAndTax[index].current.innerHTML=productPriceTotal;
    this.prodPriceAndTax2[index].current.innerHTML=productPriceTotal;
    // this.calculateGrandTotal(null) ;
 
    this.prodPriceTVQ[index].current.innerHTML=orderTVQ; 
    this.prodPriceTPS[index].current.innerHTML=orderTPS; 
    this.prodPriceServices[index].current.innerHTML=orderServices;
    this.prodPriceSubtotals[index].current.innerHTML=orderSubtotals;*/

    //productCart[index].options=optionsProd; 
    //productCart[index].pages= selPage >0? selPage : 1;
 
    //this.setState({ ["productCart"]:  productCart });
    this.calculateGrandTotal(null) ;
 

  }

  handleRemoveProductItem = (event) => {   
    //event.target.getAttribute('') or event.target.dataset.tzzzzz
    const index= event.target.dataset.prodindex;  //const selVal= event.value;  	
    //console.log('click remooooooooooooooooove well  '+index);
    //console.log(event);
    //console.log(event);
    let productCart=this.state.productCart;
    let productExtraOptionCtrl2x=this.state.productExtraOptionCtrl2;
    const productTitre=productCart[index].productTitre;
    productExtraOptionCtrl2x[index]= <span key={index} />; //  productExtraOptionCtrl2x.splice(index, 1); ////productExtraOptionCtrl2x.push(divBlock);
    this.setState({ ["productExtraOptionCtrl2"]: productExtraOptionCtrl2x });
 
    const myCart={ "index": index, "id": index+'98989', "productTitre": '', "productPrice": 0, "productPrice2": 0, "productPriceTax": 0, "productPriceTaxOnly": 0, "totOptions":0, "options": [], "isdelete":1 }
    productCart[index]= myCart; //productCart.splice(index, 1); //productCart.push(myCart);
    this.setState({ ["productCart"]:  productCart });
    //this.calculateGrandTotal(null) ;
    this.showToast("Produit retiré "+productTitre, "warning");
    this.calculateGrandTotal(null) ;	

  };

  indexOfElement = (optionList, id, amount) => {  
    let index=-1;
    optionList.length > 0
    	&& optionList.map((item, i) => {  
        if(item.id===id && item.mnt===amount){ 
          index=i;
          return i;
        }
    }, this); 
     

    return index;
  }

  calculateGrandTotal = (event) => { 
    const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    let subTotal=0;  let totTagTotOptions=0;
    const taxInfo=this.state.taxInfo;
    // const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    // const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    // const TAX_TOTAL=TAX1+TAX2; //0.14975; 


    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    //const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    }  
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
     
    productCart.length > 0
    	&& productCart.map((item, i) => { 
        if(item.productTitre!==""){  
          const productPrice= item.productPrice2===0?item.productPrice:item.productPrice2;
          subTotal +=parseFloat(productPrice); //item.productPrice
          totTagTotOptions +=parseFloat(item.totOptions);
        }
    }, this); 
    
    let tarif =((subTotal*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  TAX1 TAX2 TAX_TOTAL
    
    const percentage= this.state.promoPercentage;
    let tarifPromo=(((percentage*1)/100)*tarif); 
    tarif=(tarif-tarifPromo);
    
    
    const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
    const productPriceTVQ= (((tarif*1)*TAX1)).toFixed(2) ;
    const productPriceTPS= (((tarif*1)*TAX2)).toFixed(2) ;
    const productPriceTotalx= (((tarif*1)*TAX_TOTAL)+(tarif*1)); //.toFixed(2) ; 

    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived=this.state.totalPaymentReceived;

    let productPriceTotal= (productPriceTotalx-totalPaymentReceived).toFixed(2) ; 
    if(productPriceTotal=="-0.00"){ productPriceTotal="0.00" }

    this.setState({ ["grandTotal"]:  productPriceTotal,["beforePaymentTotal"]:  productPriceTotalx.toFixed(2) });

    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0,  

    this.setState({["orderBruteSubTotal"]: tarif, ["orderNetSubTotal"]: tarif, ["orderTVQ"]: productPriceTVQ,["orderTPS"]: productPriceTPS,["orderTotal"]: productPriceTotal,});
    this.setState({ ["nowPayment"]:  productPriceTotal });

    //console.log("===calculateGrandTotal ", TAX1, TAX2, tarif, productPriceTVQ, productPriceTPS);

    //this.setState({ ["productCart"]:  productCart });
    // this.setState({["orderBruteSubTotal"]: orderBruteSubTotal, ["orderNetSubTotal"]: tarif, 
    //       ["orderTVQ"]: productPriceTVQ,["orderTPS"]: productPriceTPS,
    //       ["orderTotal"]: productPriceTotal, ["grandTotal"]:  productPriceTotal,});
    //       this.setState({ ["nowPayment"]:  productPriceTotal });
  };


  applyProductChange  = (inputValue) => { 
    //event.preventDefault();  
    //const inputValue = newValue.replace(/\W/g, '');
    //const this00=this;
    const taxInfo=this.state.taxInfo;

    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    //const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    } 

    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
     
    const arrProd=[6,8,9]; 
    this.productsList.products.length > 0
    	&& this.productsList.products.map((item, i) => { 
        if(item.type.indexOf('hidden') <0 && item.titre!=='' && item.id==inputValue){
          //productOptions.push({ value: item.id, label: item.titre });
          //productId:0, productTitre:'', product:{}, productPrice:'', productPriceTax:'',
          let tarif = (''+item.tarif).replace(/\W\$/g, ''); 
          tarif=tarif.replace('$', '');
          const productPriceTaxOnly= (((tarif*1)*(TAX_TOTAL))).toFixed(2);
          //const productPriceTotal= (((tarif*1)*0.15)+(tarif*1)).toFixed(2); //(((tarif*1)+(productPriceTaxOnly)));
          const productPriceTotal= ((((tarif*1)*TAX1)+((tarif*1)*TAX2))+(tarif*1)).toFixed(2); 
          const orderTVQ=((tarif*1)*TAX1).toFixed(2);
          const orderTPS=((tarif*1)*TAX2).toFixed(2); 
          const orderServices=0; 
          const orderSubtotals=((tarif*1)+orderServices).toFixed(2);
          
          this.setState({ ["productTitre"]: item.titre }); 	
          this.setState({ ["product"]: item }); 	
          this.setState({ ["productPrice"]: tarif }); 	
          this.setState({ ["productPriceTax"]: productPriceTotal });
          this.setState({ ["productPriceTaxOnly"]: productPriceTaxOnly });

          //const myCart={ "index": item, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "options": [] }
          //this.setState({ ["productCart"]:  [] }); 	



          const optionsEntries = (item.options!=undefined && !arrProd.includes(item.id)) ? Object.entries(item.options): [];
          const cartEntries = Object.entries(item.cart);

          const this00=this;

          let productCart=this.state.productCart;
          const indexProd=productCart.length;
          const apartir= item.apartir; let apartirUsed=false;
          const optionsProd=item.options;

          const productExtraOptionCtrl=optionsEntries.map(function(elem, index) { 
            const [txtLabel, amount] =elem;
            //const this0=this;
            const groupVal=''+indexProd+'-'+item.id+'-'+amount;
            //console.log(`##arraymap There are ${elem} ---- ${index} +++++++++++++ ${txtLabel} -- ${amount} `);
            let titre= '';  
            for (const [txtLabel2, labelValue] of cartEntries) { 
              if(txtLabel===txtLabel2){ 
                titre= labelValue;
                //console.log(`##zazThere are ${txtLabel2} ${labelValue}  `);
                break;
              } 
            }
            if(titre===''){
              return (<span key={index} />);
            }else{
              return (<p key={index} ><input   type="checkbox" value={amount}  onChange={(eventx) => this00.setExtraServiceForProduct(eventx)}  /> {titre} (${amount})</p>); 
            }
          });


          const productExtraOptionCtrl2=optionsEntries.map(function(elem, index) { 
            const [txtLabel, amount] =elem;
            //const this0=this;
            //const groupVal=''+indexProd+'-'+item.id+'-'+amount;
            const groupVal=''+indexProd+'-'+item.id+'-'+txtLabel+'-'+amount;
            //console.log(`##arraymap There are ${elem} ---- ${index} +++++++++++++ ${txtLabel} -- ${amount} `);
            let titre= '';  
            for (const [txtLabel2, labelValue] of cartEntries) { 
              if(txtLabel===txtLabel2){ 
                titre= labelValue;
                //console.log(`##zazThere are ${txtLabel2} //////VS ${labelValue}  `);
                break;
              } 
            }
            if(titre==='' && apartir===false){
              return (<span key={index} />);
            }if(apartir===true){
              //apartirUsed=true;
              if(apartirUsed===false){
                apartirUsed=true;
                return (<p key={index}>Veuillez indiquer le nombre de pages à relire:<br/>
                  <input type="number" min="1" step="1" defaultValue={1} 
                data-value={groupVal} data-perpage={optionsProd.perPage} data-maximum={optionsProd.maximum} 
                onChange={(eventx) => this.setPageDocForProduct(eventx)}  /> Pages (n x ${amount})</p>);
              }else{ return (<span key={index} />); }
            }else{
              //console.log(`##INDEEEEEEEEEEEEEEEEEX SET IS ${groupVal}  `);
              return (<p key={index} ><input   type="checkbox" value={groupVal}  onChange={(eventx) => this00.setExtraServiceForProduct(eventx)}  /> {titre} (${amount})</p>);  
            }
          });

          //this.prodTaxOnly=[]; this.prodPriceAndTax=[]; this.prodPriceAndTax2=[];  
          this.prodPrice[indexProd]=React.createRef();
          this.prodPriceTVQ[indexProd]=React.createRef();
          this.prodPriceTPS[indexProd]=React.createRef(); 
          this.prodTaxOnly[indexProd]=React.createRef();
          this.prodPriceAndTax[indexProd]=React.createRef();
          this.prodPriceAndTax2[indexProd]=React.createRef();
          this.prodPriceServices[indexProd]=React.createRef();
          this.prodPriceSubtotals[indexProd]=React.createRef();
           
          const rId=(Math.floor(Math.random()*16));
 
           


          this.setState({ ["productExtraOptionCtrl"]: productExtraOptionCtrl });
          //if(optionsEntries.length > 0){ this.setState({ ["showProductOption"]: true }); } 
          this.setState({ ["showProductOption"]: true });



          let productExtraOptionCtrl2x=this.state.productExtraOptionCtrl2;
          //productExtraOptionCtrl2x.push(divBlock);
          this.setState({ ["productExtraOptionCtrl2"]: productExtraOptionCtrl2x });

          //const myCart={ "index": item, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "options": [] }
          //let productCart=this.state.productCart;
          //const indexProd=productCart.length;
          const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, 
          "productPrice": tarif, "productPrice2":0, "productPriceTax": productPriceTotal, 
          "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [], 
          "cticket": true, pages:0, perPage:0, randcolor:rId }
          //productCart.push(myCart);
          if(indexProd==0){ productCart.push(myCart); }else{productCart[0]=(myCart);}
          this.setState({ ["productCart"]:  productCart });

          

          //<input type="checkbox" checked={checkbox.checked} onChange={toggleCheckbox.bind(this, index)}  />

          // for (const [txtLabel, amount] of cartEntries) { 
          //   //console.log(`zazThere are ${txtLabel} ${amount}  `);
            
          // }

           
          this.calculateGrandTotal(null) ;  
          //console.log('weeeeeeeeeeeeeeeeeeeeeee aaaaaaaaaply one '+inputValue);
          
          return false;

        }
      return (
        null
      );
    }, this);

    //this.setState({ inputValue });
    //console.log('aaaaaaaaaply hellooooooooooooo '+inputValue);
    return inputValue;
  };

 

  //setExtraServiceForProduct
  // setExtraServiceForProduct = (event) => {       //setExtraServiceForProduct = (event) => {                                                             
  //   //event.preventDefault(); 
  //   //const inputValue = newValue.replace(/\W/g, '');
  //   //this.setState({ inputValue });

  //   const selVal= event.value;  //productId
  //   //this.setState({ ["productId"]: selVal }); 
  //   //this.applyProductChange(selVal);	
  //   //console.log('dynamic check well hellooooooooooooo '+selVal+'   is '+event.checked);
  //   //console.log(event);
  // };


  handleCancel = () => { 
    if(this.actionMode==='new'){ 
      this.props.history.push(`/NeoVentes`);
    }else{ 
      const idx = this.props.match.params.id; 
      this.props.history.push(`/NeoVentes/${idx}`);
      //this.props.history.push(`./NeoVentes/${this.props.NeoVentes.id}`);
    }
  };

  handleInputChange  = (newValue) => { 
    //event.preventDefault();
    //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
    const inputValue = newValue.replace(/\W/g, '');
    //this.setState({ inputValue });
    //console.log('hellooooooooooooo '+inputValue);
    return inputValue;
  };

  couponValidate  = (event) => { 
    event.preventDefault();
    //console.log(`${fieldName}: ${obj}`);
    //event.target[0].value/name
    const inputValue= this.state.cuponcode; //event.target.value; 
    //const inputValue = newValue.replace(/\W/g, '');
    //this.setState({ inputValue });
    if(inputValue===''){ this.showToast("Veuillez d'inserer le Code Promo si vou voulez appliquer un rabais.", "error");	return false; }
    //console.log('couponValidate hellooooooooooooo '+inputValue);

    Promise.resolve(this.props.getCodePromo(inputValue))
    .then((response)=>{
      //this.setState(response.NeoVentesOne);
      const respObject=response.NeoCodePromoOne[0];
      if(respObject!==null || respObject!==undefined){   
        const percentage=respObject.percentage;
        //this.applyCouponValidationAmount(percentage);
        const cuponId=respObject.id;
        const cuponName=respObject.name;
        this.applyCouponValidationAmount(percentage, cuponId, cuponName);
        
      }
     
      return response;
    });


    return inputValue;
  };

  handleCouponValidateChange  = (event) => { 
    //event.preventDefault();
    //const stepTrack=event.target.dataset.prodcontrol; 
    const inputValue= (""+event.value).split('-');
    const percentage= ((inputValue[1]*1));
    //const inputValue = newValue.replace(/\W/g, '');
    //this.setState({ inputValue });
    const cuponId=inputValue[0];
    const cuponName=inputValue[2]; 
    this.setState({ ["cuponcode"]: cuponName}); 
    this.applyCouponValidationAmount(percentage, cuponId, cuponName);
    //return inputValue;
  };

  applyCouponValidationAmount  = (percentage, cuponId, cuponName) => { 
    //event.preventDefault();
    //console.log(`${fieldName}: ${obj}`);
    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    
      
    if(percentage > 0){ 
   
      const productCart=this.state.productCart;
      const indexProd=productCart.length+10000;
      let subTotal=0;  let totTagTotOptions=0;
      productCart.length > 0
        && productCart
        .filter(product => product.productTitre !== '')
        .map((item, i) => { 
          const productPrice= item.productPrice2===0?item.productPrice:item.productPrice2;
          subTotal +=parseFloat(productPrice); //parseFloat(item.productPrice);
          totTagTotOptions +=parseFloat(item.totOptions);
      }, this); 
      
      let tarif =((subTotal*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  TAX1 TAX2 TAX_TOTAL
      let tarifPromo=(((percentage*1)/100)*tarif); 
      const orderBruteSubTotal=tarif;
      //console.log('traif='+tarif+' prom='+tarifPromo);
      tarif=(tarif-tarifPromo);
      //console.log('neo traif='+tarif+' prom='+tarifPromo);
      const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
      const productPriceTVQ= (((tarif*1)*TAX1)).toFixed(2) ;
      const productPriceTPS= (((tarif*1)*TAX2)).toFixed(2) ;
      const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ; 
      //this.setState({ ["grandTotal"]:  productPriceTotal });

      //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
      //const myCart={ "index": indexProd, "id": respObject.id, "productTitre": respObject.name, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
      

      this.setState({["orderBruteSubTotal"]: orderBruteSubTotal, ["orderNetSubTotal"]: tarif, 
      ["orderTVQ"]: productPriceTVQ,["orderTPS"]: productPriceTPS,
      ["orderTotal"]: productPriceTotal, ["grandTotal"]:  productPriceTotal,});
      this.setState({ ["nowPayment"]:  productPriceTotal, });

      // this.setState({ ["grandTotal"]:  productPriceTotal });
      // this.setState({["orderBruteSubTotal"]: tarif, ["orderNetSubTotal"]: tarif, ["orderTVQ"]: productPriceTVQ,["orderTPS"]: productPriceTPS,["orderTotal"]: productPriceTotal,});
      // this.setState({ ["nowPayment"]:  productPriceTotal });

      let tarifPromox=tarifPromo.toFixed(2) ;
      const promoTax1= (((tarifPromo*1)*TAX1)).toFixed(2);
      const promoTax2= (((tarifPromo*1)*TAX2)).toFixed(2);
      this.setState({["cuponcodeId"]: cuponId, ["cuponcodeName"]: cuponName, ["showPromoLine"]: true,["promoAmount"]: tarifPromox,["promoPercentage"]: percentage, ["promoTax1"]: promoTax1,["promoTax2"]: promoTax2,});
      //event.target.disa
    }

  };

   
  handleCouponRemove  = (event) => { 
    //event.preventDefault();
    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
      
    const percentage=0;
    const productCart=this.state.productCart;
      const indexProd=productCart.length+10000;
      let subTotal=0;  let totTagTotOptions=0;
      productCart.length > 0
        && productCart
        .filter(product => product.productTitre !== '')
        .map((item, i) => { 
          const productPrice= item.productPrice2===0?item.productPrice:item.productPrice2;
          subTotal +=parseFloat(productPrice); //parseFloat(item.productPrice);
          totTagTotOptions +=parseFloat(item.totOptions);
      }, this); 
      
      let tarif =((subTotal*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  TAX1 TAX2 TAX_TOTAL
      let tarifPromo=(((percentage*1)/100)*tarif); 
      const orderBruteSubTotal=tarif;
      //console.log('traif='+tarif+' prom='+tarifPromo);
      tarif=(tarif-tarifPromo);
      //console.log('neo traif='+tarif+' prom='+tarifPromo);
      const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
      const productPriceTVQ= (((tarif*1)*TAX1)).toFixed(2) ;
      const productPriceTPS= (((tarif*1)*TAX2)).toFixed(2) ;
      const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ;  

      this.setState({["orderBruteSubTotal"]: orderBruteSubTotal, ["orderNetSubTotal"]: tarif, 
      ["orderTVQ"]: productPriceTVQ,["orderTPS"]: productPriceTPS,
      ["orderTotal"]: productPriceTotal, ["grandTotal"]:  productPriceTotal,});
      this.setState({ ["nowPayment"]:  productPriceTotal, });
 
      let tarifPromox=tarifPromo.toFixed(2) ;
      const promoTax1= (((tarifPromo*1)*TAX1)).toFixed(2);
      const promoTax2= (((tarifPromo*1)*TAX2)).toFixed(2);
      //cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
 
      this.setState({["cuponcodeId"]: 0, ["cuponcodeName"]: "", ["showPromoLine"]: false,["promoAmount"]: tarifPromox,["promoPercentage"]: percentage, ["promoTax1"]: promoTax1,["promoTax2"]: promoTax2,});
       
  };


  getStripStatus  = (stripobject) => {  
    //stripePaymentOpen to close   
    //result.token.id  result.error.message
    const result=stripobject;  
    const stripeNow= Date.now(); 
    const timesbetween=((stripeNow*1)-(this.stripeTryTimes*1));
    const stripeNeoToken=result.stripeNeoToken;
    // console.log("OLD STYLE SUCCESS ");
    // console.log(result.token);
    // console.log("stripeToken= "+result.token.id+" ");
    // console.log("//SUCCESS stripeToken="+result.token.id); //return false;
    // console.log("//SUCCESS times="+timesbetween+" track="+stripeNeoToken);

    //this.stripeTryTimes=0; && this.stripeNeoTokenTrack===stripeNeoToken
    if(timesbetween <60000){
      //this.stripeTryTimes=stripeNow;
      console.log("ooooooh wont go --- stripeToken= "+result.token.id+" "); 
      window.alert("Désolé, on ne processe pas une carte plus qu'une fois en moins d'une minute. Svp essayez encore.");
      return false;
    }else{ this.stripeTryTimes=stripeNow; this.stripeNeoTokenTrack=stripeNeoToken; }

    //window.alert("FINAAAAAAAAAAAAAAAAL SUCCESS stripeToken= "+result.token.id+" "); return false;
    //if(this.stripeAvoidManyTokens >0){ console.log("wont go --- stripeToken= "+result.token.id+" "); return false;}
    
    this.setState({["stripePaymentOpen"]: false, ["stripeToken"]: result.token.id, ["isLoaderOpen"]: true});
    this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.handleGetCustomerInfoPayment2(null);
    if(this.actionMode==='partialInvoice') {
      this.stripeAvoidManyTokens=1;
      this.handleMakePartialPayment(null);
    }else{ 
      this.stripeAvoidManyTokens=1; 
      this.handleGetCustomerInfoPayment2(null); 
    }

    //console.log("//#2SUCCESS stripeToken="+result.token.id+" track="+stripeNeoToken); return false;

  }

  handleClosePostalCode  = (anyx) => {  
    //console.log("OLD STYLE SUCCESS handleClosePostalCode"); 
    //window.alert("FINAAAAAAAAAAAAAAAAL SUCCESS stripeToken= "+result.token.id+" ");
    this.setState({["openPossibleZipCode"]: false }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
     
  }

  handleCloseListOfClient  = (anyx) => {   
    this.setState({["openPossibleListOfClient"]: false }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");   
  }
  handleCloseListOfCDStatusInfo  = (anyx) => {   
    this.setState({["openPossibleCDStatusInfo"]: false }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");   
  }
   

  handleCloseTransactionPopup  = (anyx) => {  
    //console.log("OLD STYLE SUCCESS handleClosePostalCode"); 
    this.actionMode='new';
    this.requestTimer = null;
    this.backInvoiceId=0; 
    this.setState({["openTransactionPopup"]: false }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    let codepromoOptions=[];
    
    //this.setState(this.stateInit);
    //this.props.history.push(`./NeoVentes`);
    //this.state = JSON.parse(JSON.stringify(this.stateInit)); 
    //const taxInfo=this.state.taxInfo;  
    const productsList=JSON.parse(JSON.stringify(this.state.productsList.products));
    const listPromotionCodes=JSON.parse(JSON.stringify(this.state.listPromotionCodes)); //this.state.listPromotionCodes;
    this.setState(JSON.parse(JSON.stringify(this.stateInit)));
    this.setState({["listPromotionCodes"]: listPromotionCodes, ["productsList"]: {products:productsList}});
    const taxInfo=getProvinceTaxInfo("QC");
    this.setState({ ["taxInfo"]: taxInfo });
    this.actionMode='new';
    this.requestTimer = null;
    this.backInvoiceId=0;
    this.stripeAvoidManyTokens=0;
    this.stripeTryTimes=0;
    this.stripeNeoTokenTrack=0;
    this.paymentBtnTryTimes2=0;
    this.paymentBtnTryTimes3=0; 
    let promosList = listPromotionCodes.length > 0
    	&& listPromotionCodes.map((item, i) => { 
        if(item.percentage >0 && item.active==1){ 
          codepromoOptions.push({ value: item.id + '-'+item.percentage+ '-'+item.name, label: item.description + ' ' + item.percentage + '% ('+item.name+')' });
        } 
      return (
        null
      );
    }, this);

    this.setState({["codepromoOptions"]: codepromoOptions });

    //this.props.history.push(`/NeoVentes`);
  }
  handleCloseProdInfoPopup  = (anyx) => {   
    this.setState({["openInfoStepsPopup"]: false,  });  
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
     
  }
  handleOpenProdInfoPopup  = (event) => {  
    const stepx= event.target.dataset.step;
    const stepcx= event.currentTarget.dataset.step;
    const step= stepcx;
    //console.log("OLD STYLE SUCCESS handleOpenProdInfoPopup "+stepx+" --- "+stepcx);  
    this.setState({["openInfoStepsPopup"]: true, ["infoStepsPopupno"]: step, });  
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
     
  }

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  handleClosePListAsOnTheSite  = (anyEvent) => {  
    //console.log("OLD STYLE SUCCESS handleClosePListAsOnTheSite");  
    this.setState({["openPListAsOnTheSite"]: false }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);

  }

  handleOpenPListAsOnTheSite  = (anyEvent) => {     
    this.setState({["openPListAsOnTheSite"]: true }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);

  }

  checkChargeDeskTransactionStatus  = (event) => {     
    //this.setState({["openPListAsOnTheSite"]: true }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);
    //const invoiceId=this.state.id_invoice;
    const chargeId=this.state.chargeDeskIdStr;
    if(chargeId===""){ window.alert("We do not find the charge id of Charge Desk"); return false;}
    if(chargeId===this.state.possibleCDStatusInfo.charge_id){ this.setState({["openPossibleCDStatusInfo"]: true }); }
    Promise.resolve(this.props.getChargeDeskStatus(chargeId))
    .then((response)=>{
      const info=response.NeoVentes; //JSON.parse(response); // response.NeoVentesOne; 
      let pstatusInfo=[];
      pstatusInfo.push(<tr key={10}><td>customer email</td><td>{info.customer_email}</td></tr>);
      pstatusInfo.push(<tr key={19}><td>Montant</td><td>{info.amount_formatted}</td></tr>);
      pstatusInfo.push(<tr key={199}><td>Description</td><td>{info.description}</td></tr>);
      pstatusInfo.push(<tr key={190}><td><b>Déjà Payé</b></td><td><b>{(info.is_paid==="true")? 'Oui':'Non'}</b></td></tr>);
      pstatusInfo.push(<tr key={191}><td>Statuts</td><td>{info.status}/{info.status_text}</td></tr>);
      pstatusInfo.push(<tr key={192}><td>Montant Remboursé</td><td>{info.amount_refunded}</td></tr>);
      pstatusInfo.push(<tr key={193}><td>Gateway</td><td>{info.gateway_id}</td></tr>);
      this.setState({["openPossibleCDStatusInfo"]: true, ["possibleListOfCDStatusInfo"]: pstatusInfo, ["possibleCDStatusInfo"]: info });
      //console.log(info);
      return response;
    });
  }





  render() {
    const { t } = this.props;
    let productOptions = [];
    const productsList=this.state.productsList.products;

    /*let productList = this.productsList.products.length > 0
    	&& this.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        if(item.type.indexOf('hidden') <0 && item.titre!==''){
          productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
          //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
        }
      return (
        null
      );
    }, this);*/

    /*let productList = productsList.length > 0
    	&& productsList.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        //console.log(item);
        if(item!==null){
          const isUpsell=item.upsell;
          if(isUpsell!==null && isUpsell!==undefined){
            productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
          }else{
            if(item.type.indexOf('hidden') <0 && item.titre!==''){
              productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
              //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
            }
          }
        }
      return (
        null
      );
    }, this);*/


 

  //value={valueClientSearch}  inputValue={inputValueClientSearch}
  const clientInfo=this.state.clientInfo;
  const clientAddress=this.state.clientAddress;

  if(clientInfo!=null){ 

  }

  const globalStepWorkflow=this.state.globalStepWorkflow;
  const myToastMessage=this.state.toastrMessage;
  const toastrType=this.state.toastrType;
  
    //cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0,
 
    


    let days=[];
    for(let p=1; p< 29; p++){
      days.push(p);
    }

    const paymentDayList = days.map((item, i) => { 
      return (
        <option key={item} value={item}>{item}</option>
      );
    }, this);


    const productPriceTotal= this.state.grandTotal; //orderTotal
    const nowPayment= this.state.nowPayment;
    const paymentWay= this.state.paymentWay; 
    const paymentValid={  total:productPriceTotal, paymentWay:paymentWay, nowPayment:nowPayment,};
    const paymentRound=this.state.paymentRound;

    const ProductsOnInvoice =this.ProductsOnInvoice;   
    const CustomerInfoAddress = this.CustomerInfoAddress;    
    const PaymentSection = this.PaymentSection;

    const ZipCodePossibleAddress =this.ZipCodePossibleAddress;
    //const ProductInfoStepsPopup =this.ProductInfoStepsPopup;
    const FinishTransactionPopup =this.FinishTransactionPopup;
    //const ShowProductExtraInfo=this.ShowProductExtraInfo;
    const AutocompleteClient=this.AutocompleteClient;
    const AutocompleteClientB2B=this.AutocompleteClientB2B;
    //const ProductListAsOnTheSite=this.ProductListAsOnTheSite;
    const Alert=this.Alert;
    const ListOfExistedPossibleClient=this.ListOfExistedPossibleClient;
    //const CheckStatusOfChargeDeskCharge=this.CheckStatusOfChargeDeskCharge;
    const EligibilityClientInfo=this.EligibilityClientInfo;

    const listOfTickets=this.state.listOfTickets;

    const b2bData=this.state.b2b;

    return (
      <div>
        <h3>{this.actionMode==='new'? t('zqwacAddNeoVentes_x'): t('zqwacEditNeoVentes_x')}</h3>
        {/* <form onSubmit={this.handleSubmit}> */}
         
    <div className="row">      		  
    <div className="neox50">
      <span > </span>
      {/* <button type="button" className="btn btn-warning" onClick={this.handleCloseTransactionPopup}>FERMER SANS FINALISER LA TRANSACTION</button> */}
      <input type="text" ref={this.navigationTopRefOnTop} style={{position:'absolute', zIndex:'-99', border:'none'}} />
		  <div className="form-group" style={{display:'none'}} >
			  <label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			  {this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
      </div>
      {globalStepWorkflow ===1 ? 
        (<div className="form-group">
			  
          <div className="form-group">
            <div className="md-8">
              <AutocompleteClientB2B key={91000} propstep={1} /> 
            </div> 
            {this.state.showCreateBtnManual===true?
            <div className="md-3">
            <button type="button" className="btn btn-primary" onClick={this.gowithNewCustomerMannual}>{t('zqwTNVFtxt34_x')}</button>
              &nbsp;  
            </div>:""  
            }
          </div>        
      </div>):<span />     
      }
      {/* {globalStepWorkflow === 2 ? 
        <ProductsOnInvoice />:<span />    
      } */}

      {globalStepWorkflow === 2 ? 
         <div className="form-group">
           <div className="md-9">
              {/* <EligibilityClientInfo b2b={b2bData} />  */}
              <EligibilityClientInfo /> 
           </div>     
         </div>: <span />    
      }
      {globalStepWorkflow === 3 ? 
        <CustomerInfoAddress /> : <span />    
      }
      {globalStepWorkflow === 4 ? 
        <div className="md-12">
        {/* Methode de Paiement du Client viendra ici! */}
        <div className="form-group">  
        <Alert severity="info">Nom du Client: <b>{this.state.firstname+" "+this.state.lastname}</b></Alert>
        </div>
         
        {/* {((paymentRound >0 && parseFloat(this.state.nowPayment)<=0 && this.state.pMethod===1) || (this.state.partialCanClose>0 && this.state.status_invoice!=="payed"))?
          <button type="button" className="btn btn-danger" onClick={this.handleFinalize}>FINALISER LA TRANSACTION</button>:''
          this.state.pMethod===4  && this.state.status_invoice!=="payed"
          } */}
        {(( this.state.statusChargeDesk===4))?
          <div className="form-group">  
          <button type="button" className="btn btn-warning" onClick={this.checkChargeDeskTransactionStatus}>Vérifier Statut de Paiement ChargeDesk</button>
          </div>:'' 
          }
        <PaymentSection listOfTickets={listOfTickets} />  
      </div>  : <span />    
      }


      {globalStepWorkflow !==1 ? 
        <div className="form-group" style={{marginTop:'150px' } }>
          <a href="#" className="btn btn-default"  onClick={this.restartOver}>{t("zqwTNVFtxt35_x")}</a>
        </div>:''
        }
       
       
    </div>
     {/* <div className="neox50">  
        <div className="text-center b-t  b-grey p-t-20 p-b-20 p-l-40 p-r-40">
          <img src="/static/assets/img/sceau-garantie-neo-fr.png" height="100" width="100" className="m-b-10" />
          <div className="text-black fs-16 sm-fs-15 m-b-5">Garantie Zéro Stress de Neolegal</div> 
        </div>
        <div style={{margin:'20px' } }> 
           <ShowProductExtraInfo proptest={"neo"} />   
        </div> 
    </div>  */}
    </div>  

      <Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
      <Loader  openx={this.state.isLoaderOpen}  />
      <ZipCodePossibleAddress  openx={this.state.isLoaderOpen}  />
      {/* <ProductInfoStepsPopup  /> */}
      <FinishTransactionPopup  />
      {/* <ProductListAsOnTheSite  /> */}
      <ListOfExistedPossibleClient  />
      {/* <CheckStatusOfChargeDeskCharge  /> */}
       
       
       
       
       
      
      
		   
  
		  
		  <div className="form-group">
			  <br/> &nbsp; <br/> &nbsp; <br/> &nbsp; <br/> &nbsp; <br/> &nbsp; <br/> &nbsp; <br/> &nbsp; <br/> &nbsp; 
      </div>
 
      </div>  

    );
  }

  PaymentSection= (props) => {
    const { t } = this.props;
    const taxInfo=this.state.taxInfo;
    /*const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; */

    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    } 
  
    //cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0,

    const promoAmount='-'+this.state.promoAmount;
    const orderBruteSubTotal=this.state.orderBruteSubTotal;
    const orderNetSubTotal=this.state.orderNetSubTotal;
    const orderTVQ=this.state.orderTVQ;
    const orderTPS=this.state.orderTPS;
    const orderTotal=this.state.orderTotal;
    const promoPercentage=this.state.promoPercentage;

    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived=this.state.totalPaymentReceived;
    const beforePaymentTotal=this.state.beforePaymentTotal;

    const listOfTickets=props.listOfTickets; //this.state.listOfTickets;
      


    let days=[];
    for(let p=1; p< 29; p++){
      days.push(p);
    }

    const paymentDayList = days.map((item, i) => { 
      return (
        <option key={item} value={item}>{item}</option>
      );
    }, this);

    const listOfTicketsList = listOfTickets.map((item, i) => { 
      if(item.ticket_id!=="" && item.ticket_id!=="0"){
      return (
        <option key={"tckt"+i} value={item.ticket_id}>{item.ticket_id+" ("+item.description+")"}</option>
      );
      }
      return null; 
    }, this);


    const productPriceTotal= this.state.grandTotal; //orderTotal
    const nowPayment= this.state.nowPayment;
    const paymentWay= this.state.paymentWay; 
    const paymentValid={  total:productPriceTotal, paymentWay:paymentWay, nowPayment:nowPayment,};
    const paymentRound=this.state.paymentRound;

    const Alert=this.Alert;

    let productOptions = [];
    const productsList=this.state.productsList.products;

    let productList = this.productsList.products.length > 0
    	&& this.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        if(item.type.indexOf('hidden') <0 && item.titre!==''){
          productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
          //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
        }
      return (
        null
      );
    }, this);

    

    return(
      <div className="card card-primary card-outline">
      <div className="card-header">
      {/* <h5 className="m-0">Methode de Paiement du Client B2B</h5> */}
      <h5 className="m-0">{t("zqwTNVFtxt39_x")}</h5>
      </div>
      <div className="card-body">

         <br/> &nbsp; <br/>
        <div className="row">
          <div className="marginr md-6">
            <div className="card card-default card-outlinex">
              <div className="card-header">
                <h5 className="m-0">{t("zqwTNVFtxt40_x")} &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; </h5>
              </div>
              <div className="card-body">
                {/* <div id="coupon_div_form" className="row">
                      <div className="col-xs-12 text-right neopad">
                      <label htmlFor="name">Produits Éligibles</label>
                      <Select options={productOptions} onChange={this.handleProductChange}   />
                      </div>                    
                </div> */}
              <div className="form-group"  >
                  <label htmlFor="company_name">{t("zqwTNVFtxt2_x")}</label> 
                <input type="text" name="company_name" id="company_name" defaultValue={this.state.b2b.company_name} 
                  className='form-control' readOnly={true}  />  
              </div>  

                <div className="row"><div className="neoinline100">
                    <label>
                      {/* #Prod Id {this.state.productId}   */}
                    {this.state.productTitre}</label>   
                 </div></div>

                {/* <div className="row"><div className="neoinline100">
                    <label htmlFor="name">Produits Éligibles</label>
                    <Select options={productOptions} onChange={this.handleProductChange}   />
                 </div></div> */}
                 

{/*                  
                <div id="coupon_div_form" className="row">
                    <div className="col-xs-4 text-right neopad">
                      <label>Code Promo <small>(facultatif)</small></label>
                    </div>
                    <span id="coupon_div_success" className="alert-success"></span>
                    <div id="coupon_div_field" className="col-xs-4 neopad">
                      <input type="text" name="cuponcode" className="form-control" onChange={this.handleChange} />
                    </div>
                    <div id="coupon_div_field1" className="col-xs-4">
                      <button type="button" name="coupon_validate" className="btn btn-info" onClick={this.couponValidate}>Valider Code</button>
                    </div>
                     
                </div>
                <div id="coupon_div_formqqzqr" className="row">
                    &nbsp;  
                </div>
                <div id="coupon_div_formqq" className="row"> 
                    <div className="neoinline20">
                    <label>Promos <small>(facultatif)</small></label>
                    </div>
                    <div className="neoinline70">
                     <Select options={this.state.codepromoOptions} placeholder="Choisir une promotion..." onChange={this.handleCouponValidateChange}   />
                    </div>
                </div>




                <div id="coupon_div_form4" className="row rowmbtm">
                    <div className="col-xs-4 text-right neopad">
                      <label><small>Statut Amérindien?</small></label>
                    </div> 
                    <div id="coupon_div_field4" className="col-xs-4 neopad"> &nbsp; 
                      <input type="checkbox" name="isAmerindian" className="form-controlx" 
                      onClick={this.handleCheckBoxCheck} placeholder={"numéro amérindien"} 
                      defaultChecked={this.state.isAmerindian} 
                      disabled={this.state.paymentWayRO} /> Oui  
                    </div>
                    {this.state.isAmerindian===true?
                      <div id="coupon_div_field14" className="col-xs-4">
                      <input type="text" name="no_amerindien" className="form-control" 
                      onChange={this.handleChange} disabled={this.state.paymentWayRO}
                      defaultValue={this.state.no_amerindien} 
                      onBlur={this.handleChangeAmerindian} />
                    </div>:''} 
                </div> 
                <div id="coupon_div_form5" className="row">
                    <div className="col-xs-4 text-right neopad">
                      <label><small>Ajouter à un ticket Existant</small></label>
                    </div> 
                    <div id="coupon_div_field5" className="col-xs-4 neopad"> &nbsp; 
                      <input type="checkbox" name="isupsale" className="form-controlx" 
                      defaultChecked={this.state.isupsale} 
                      onClick={this.handleCheckBoxCheck} disabled={this.state.paymentWayRO} /> Oui  
                    </div>
                    {this.state.isupsale===true?
                      <div id="coupon_div_field15" className="col-xs-4"> 
                        <select name="upsaleTicket"  className={'form-control'} 
                        defaultValue={this.state.upsaleTicket}  onChange={this.handleChangePaymentWay} 
                        disabled={this.state.paymentWayRO}> 
                          <option value=""> Select/Choisir</option>
                          {listOfTicketsList}
                        </select>    
                      </div>:'' }
                </div> */}
 
                 
                {/* <hr/> */}
                <div className="row"><div className="neoinline100"><hr/></div></div>

                {/* <div className="row"> 
                      <div className="col-md-3"></div>
                      <div className="col-md-8">
                        <div className="row">
                            <div className="neoinline50">Sous-total</div>
                            <div className="neoinline50"> <span id="subtotal">{orderBruteSubTotal}</span>$</div>
                        </div>
                        
                        {this.state.showPromoLine===true? 
                          <div className="row">
                            <div className="neoinline50">Rabais (<span id="tax1_amount">{promoPercentage}</span>%)
                              <a href="#" onClick={this.handleCouponRemove}><DeleteForeverIcon /></a> 
                            </div>
                            <div className="neoinline50"> <span id="subtotal">{promoAmount}</span>$</div>
                          </div>:''
                        }
                        {this.state.showPromoLine===true? 
                        <div className="row">
                            <div className="neoinline50">Sous-total</div>
                            <div className="neoinline50"> <span id="subtotal">{orderNetSubTotal}</span>$</div>
                        </div>:''
                        }

                        {TAX1_NAME!=="0"?
                          <div className="row" id="tax1_block" style={{display:"block"}}> 
                              <div className="neoinline50 text-right"><span id="tax1_name">{TAX1_NAME!=="0"?TAX1_NAME:''}</span> (<span id="tax1_amount">{TAX1lx}</span>%)</div>
                              <div className="neoinline50 text-right text-black"> <span id="tax1">{orderTVQ}</span>$</div> 
                          </div>:''}

                          {TAX2_NAME!=="0"?
                          <div className="row" id="tax2_block" style={{display:"block"}}>
                              <div className="neoinline50"><span id="tax2_name">{TAX2_NAME!=="0"?TAX2_NAME:''}</span> (<span id="tax2_amount">{TAX2lx}</span>%)</div>
                              <div className="neoinline50"> <span id="tax2">{orderTPS}</span>$</div>
                          </div>:''}

                          <div className="row"><div className="neoinline100"><hr/></div></div>
                          {totalPaymentReceived >0?
                          <>
                          <div className="row">
                              <div className="neoinline50">Total avant Paiement</div>
                              <div className="neoinline50"> <span id="totalap">{beforePaymentTotal}</span>$</div>
                          </div>
                          <div className="row" id="tax2_block" style={{display:"block"}}>
                              <div className="neoinline50"><span id="tax2_name">Paiements Réalisés</span></div>
                              <div className="neoinline50"> <span id="tax2">{totalPaymentReceived}</span>$</div>
                          </div>
                          <div className="row"><div className="neoinline100"><hr/></div></div>
                          </>:''
                          } 
                          <div className="row bold">
                              <div className="neoinline50">Total</div>
                              <div className="neoinline50"> <span id="total">{orderTotal}</span>$</div>
                          </div>
                      </div>
                  </div> */}

                  <br/> &nbsp; <br/>

              </div>
            </div>
          </div>
        </div>

     <br/> &nbsp; <br/>


    <br/> &nbsp; <br/>

       
        
        {paymentRound >0?
        <div className="form-group">  
        <Alert severity="success">Félicitations/Congratulations!! #{paymentRound}</Alert>
        <br/> &nbsp; <br/></div>:''
        }

        {(this.state.errorTransacMessage!=="")?
          <div className="form-group">  
            <Alert severity="error">
              {this.state.errorTransacMessage}
            </Alert>
            <br/> &nbsp; <br/>
          </div>:'' 
        }

      {(this.state.partialCanClose===0)?
      <>


<button type="button" name="coupon_validate" className="btn btn-info" onClick={this.payWithB2BOnFile}>{t('zqwTNVFtxt12_x')}</button>
                     
        </>:''
      }

        {/* <this.AppStripeFormPayment /> */}

        <br/> &nbsp; <br/> 
        <div className="form-group"> 
        
        {/* {this.actionMode!=='partialInvoice'?
         <button type="button" className="btn btn-default" onClick={this.handlePreviousStep}>Etape Antérieure</button>:''} &nbsp;  */}

           
          {/* {(this.state.twoStepSale==="interac")?
            <div className="form-group">  
              <Alert severity="warning">
                C'est une vente interac, assurez-vous que le client a bien payé (fonds déposés) avant de clicker 
                sur finaliser. Ceci va créer le ticket d'achat et l'envoie du courriel au client.
              </Alert>
              <br/> &nbsp; <br/>
            </div>:'' 
           } */}

          {/* {((paymentRound >0 && parseFloat(this.state.nowPayment)<=0 && this.state.pMethod===1) || (this.state.partialCanClose>0 && this.state.status_invoice!=="payed"  && this.state.statusChargeDesk===0))?
          <button type="button" className="btn btn-danger" onClick={this.handleFinalize}>FINALISER LA TRANSACTION</button>:''
          
          }
          {((paymentRound >0  && this.state.pMethod !=1) || (this.state.partialCanClose>0))?
            <> &nbsp; <button type="button" className="btn btn-warning" onClick={this.handleCloseTransactionPopup}>FERMER SANS FINALISER LA TRANSACTION</button></>
            :''
            } */}

          
          
        </div>

      </div>
    </div>
    );
  }

  getClientTransactionList = (clientIdNone) => {  
    //const serachObj={search:search, searchEmail:""};
    const clientId=this.state.idClient;
    const listOfTickets=this.state.listOfTickets;
    if(listOfTickets.length==0 && clientId > 0){
      const serachObj={clientId:clientId};
      const this00=this;  
      this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""}); 
      Promise.resolve(this.props.getClientTransactionsFullInfo(serachObj))
      .then((response)=>{ 
        ////console.log('getClientTransactionList  '); //console.log(response); 
      this.setState({ ["listOfTickets"]: response.NeoVentesOne, ["isLoaderOpen"]: false }); 
      //this.setState({ ["isLoaderOpen"]: false});  
      return response;
      }).catch(function(error){ 
        const errObj=error;
        //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
        let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
        if(errObj.status===undefined){
          errMessage +="\n There was some error from the expected data from the server. Please try again later."
        }
        this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
         
        console.log("ERROR WITH TRANSACTION"); 
        console.log(error);
        console.log("SHORT ERROR MESSAGE: "+errMessage);
        console.log("RWA RESPONSE");  
      });
    }
    
  };


  AutocompleteClientB2B = (props) => {    
    //AutocompleteClient (props){
      const { t } = this.props;
    const listofSuggestion=this.state.listOfSuggestion2;
    const valueClientSearch=this.state.valueClientSearch2;
    const inputValueClientSearch=this.state.inputValueClientSearch2;
    //const selectedIdSuggestion=this.state.selectedIdSuggestion; 
    const propstep=props.propstep;
    const this2=this;
    let localSearch="";

    return (
      <Autocomplete

        value={valueClientSearch} 
        inputValue={inputValueClientSearch}
        onInputChange={(event, newInputValue) => {
          //setInputValue(newInputValue);
          localSearch=newInputValue;
          this.setState({ ["inputValueClientSearch2"]: newInputValue }); 
          //console.log('onInputChange search for autocomplete ='+newInputValue);
          if(newInputValue.length >2){ 
              //goto the server 
              //this.getClientSearchSuggestion(newInputValue); 
                setTimeout(() => {
                  this.getClientSearchSuggestion2(newInputValue); 
                }, 0); 
          }
        }}

        onChange={(event, newValue) => { 

          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              this.setState({ ["valueClientSearch2"]: newValue });  
            });
          } else if (newValue && newValue.inputValue) {
             
            this.setState({ ["valueClientSearch2"]: newValue.inputValue });  
          } else {
            console.log("SETTIIING FOOOOOOR  "+newValue.firstname+' '+newValue.lastname)
            console.log(newValue);
            //this.setState({ ["b2b"]: JSON.parse(JSON.stringify(this.baseB2B))}); 
            ////this.setState({ ["b2b"]: newValue});  //xaza  this.setState({ clientInfo });
            //this.setState({ ["b2b"]: JSON.parse(JSON.stringify(newValue))});
            const b2b=this.state.b2b; 
            const b2b2=this.myB2Bsetter(newValue);  
            //this.setState({...b2b,  ["b2b"]: JSON.parse(JSON.stringify(newValue))});          
            this.setState({...b2b,  ["b2b"]: b2b2});          
            //this.setState({ ["valueClientSearch2"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)}); 
            this.setState({ ["valueClientSearch2"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.id_external+' '+newValue.postal_code+' '+newValue.email)}); 
            if(newValue.id_clients===null || newValue.id_clients===""){
              const clientInfo={  
                id_clients:newValue.id_clients, 
                idClient:newValue.id_clients, 
                firstname:newValue.firstname, 
                lastname:newValue.lastname, 
                cell:newValue.tel, 
                terms:newValue.terms, 
                active:'1',  
                email:newValue.email, 
                //id_users:parseInt(newValue.id_users), 
                //language:newValue.language, 
                push:1, 
                //id_zendesk:newValue.id_zendesk, 
                sms:1,
                //stripe_id:newValue.stripe_id,  
                //occupation:newValue.occupation, 
      
              }; 
              this.setState(clientInfo ); //xaza  this.setState({ clientInfo });

              const clientAddress={
                //idAddress:address.id, 
                address:newValue.address, 
                city:newValue.city, 
                province:newValue.province, 
                cp:newValue.postal_code, 
                country:"Canada", 
                //company:address.company,  
              };
    
              this.setState( clientAddress ); 
            }else{
              //existed client get data
              //getClientSearchSuggestion(search)
              const clientId= newValue.id_clients; //this.state.idClient;
              const serachObj={search:"", searchEmail:"", clientId:clientId};
              Promise.resolve(this.props.getClientSearch(serachObj))
              .then((response)=>{ 
                const newValueq=response.NeoClientsOne;
                this.setState({ ["clientInfo"]: newValueq}); 
                const clientInfo={  
                  id_clients:newValueq.id, 
                  idClient:newValueq.id, 
                  firstname:newValueq.firstname, 
                  lastname:newValueq.lastname, 
                  cell:newValueq.cell, 
                  terms:newValueq.terms, 
                  active:newValueq.active,  
                  email:newValueq.email, 
                  id_users:parseInt(newValueq.id_users), 
                  language:newValueq.language, 
                  push:newValueq.push, 
                  id_zendesk:newValueq.id_zendesk, 
                  sms:newValueq.sms,
                  stripe_id:newValueq.stripe_id,  
                  occupation:newValueq.occupation, 
        
                }; 
                this.setState(clientInfo ); 
                this.setState({ ["listOfSuggestion2"]: [response.NeoClientsOne] }); 
                this.setState({ ["existedClientRO"]: true, ["emailDoesNotExist"]: 1});
                this.getClientFullAddress(clientId); 
                return response;
              });
            }

            // if(propstep==1){ this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]:3 }); }
            // else{ this.setState({ ["globalStepWorkflow"]: 2 }); }

            this.setState({ ["globalStepWorkflow"]: 2 });

            //window.alert("CHOSEN WEEEEEEEEEELL, "+(newValue.firstname+' '+newValue.lastname+' '+newValue.email));
          }

          //this.setState({ ["valueClientSearch"]: newValue }); 
          //console.log('onChange search for autocomplete '+newValue);
        }}

        // filterOptions={(options, params) => {
        //   const filtered = filter(options, params);

        //   if (params.inputValue !== '') {
        //     filtered.push({
        //       inputValue: params.inputValue,
        //       title: `Add "${params.inputValue}"`,
        //     });
        //   }

        //   return filtered;
        // }}

        freeSolo
        id="free-solo-22x-demo"
        disableClearable
        // options={listofSuggestion.map((option) => option.firstname+' '+option.lastname+' '+option.email)}
        options={listofSuggestion}


          getOptionLabel={(option) => { 

            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            } 
            localSearch=this.state.inputValueClientSearch2; 
            //console.log("EEEEEEEEEXeePERIMENT ", localSearch)
            //return (option.firstname+' '+option.lastname+' '+option.email);
            //return (option.firstname+' '+option.lastname+' '+option.id_external+' '+option.postal_code+' '+option.email);
            return (option.firstname+' '+option.lastname+' '+option.id_external+' '+option.postal_code+' '+option.email+' '+option.tel+' '+option.subcontractName+' '+localSearch);
             
            
          }}

          //renderOption={(option) => (option.firstname+' '+option.lastname+' '+option.email)}
          renderOption={(option) => (option.firstname+' '+option.lastname+' '+option.id_external+' '+option.postal_code+' '+option.email+' '+option.subcontractName)}

        renderInput={(params) => ( 
          <TextField
            {...params}
            label={t('zqwTNVFtxt33_x')}
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    );
  }

  myB2Bsetter = (newObj) => {    
    const  baseB2B0= {
        id:0,
        id_b2b_list:'', e_id_b2b_list: false,
        id_external:'', e_id_external: false,
        name_id_external:'', e_name_id_external: false,
        firstname:'', e_firstname: false,
        lastname:'', e_lastname: false,
        email:'', e_email: false,
        tel:'', e_tel: false,
        start_date:'', e_start_date: false,
        expiry_date:'', e_expiry_date: false,
        address:'', e_address: false,
        city:'', e_city: false,
        province:'QC', e_province: false,
        postal_code:'', e_postal_code: false,
        raw_data:'', e_raw_data: false,
        date_created:'', e_date_created: false,
        date_updated:'', e_date_updated: false,
        status:'', e_status: false,
        dob:'', e_dob: false,
        id_clients:'', e_id_clients: false,
        company_name:''
    };
    const baseB2B = {...baseB2B0, ...newObj }
    return baseB2B;
  }


  AutocompleteClient = (props) => {    
    //AutocompleteClient (props){
      const { t } = this.props;
    const listofSuggestion=this.state.listOfSuggestion;
    const valueClientSearch=this.state.valueClientSearch;
    const inputValueClientSearch=this.state.inputValueClientSearch;
    //const selectedIdSuggestion=this.state.selectedIdSuggestion; 
    const propstep=props.propstep;
    const this2=this;

    return (
      <Autocomplete

        value={valueClientSearch} 
        inputValue={inputValueClientSearch}
        onInputChange={(event, newInputValue) => {
          //setInputValue(newInputValue);
          this.setState({ ["inputValueClientSearch"]: newInputValue }); 
          //console.log('onInputChange search for autocomplete ='+newInputValue);
          if(newInputValue.length >2){ 
              //goto the server 
              //this.getClientSearchSuggestion(newInputValue); 
                setTimeout(() => {
                  this.getClientSearchSuggestion(newInputValue); 
                }, 0); 
          }
        }}

        onChange={(event, newValue) => { 

          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              this.setState({ ["valueClientSearch"]: newValue });  
            });
          } else if (newValue && newValue.inputValue) {
             
            this.setState({ ["valueClientSearch"]: newValue.inputValue });  
          } else {
            //setValue(newValue);
            this.setState({ ["valueClientSearch"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)}); 
             
            this.setState({ ["clientInfo"]: newValue}); 
            const clientInfo={  
              id_clients:newValue.id, 
              idClient:newValue.id, 
              firstname:newValue.firstname, 
              lastname:newValue.lastname, 
              cell:newValue.cell, 
              terms:newValue.terms, 
              active:newValue.active,  
              email:newValue.email, 
              id_users:parseInt(newValue.id_users), 
              language:newValue.language, 
              push:newValue.push, 
              id_zendesk:newValue.id_zendesk, 
              sms:newValue.sms,
              stripe_id:newValue.stripe_id,  
              occupation:newValue.occupation, 
    
            }; 
            this.setState(clientInfo ); //xaza  this.setState({ clientInfo });
            if(propstep==1){ this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]:3 }); }
            //else{ this.setState({ ["globalStepWorkflow"]: 2 }); }

            this.setState({ ["existedClientRO"]: true, ["emailDoesNotExist"]: 1});
             
            this.clientFullInfoValidate();
             

            //get client Address
            const clientId=newValue.id;
            setTimeout(() => {
              this.getClientFullAddress(clientId); 
            }, 0); 
          }

          //this.setState({ ["valueClientSearch"]: newValue }); 
          //console.log('onChange search for autocomplete '+newValue);
        }}

        // filterOptions={(options, params) => {
        //   const filtered = filter(options, params);

        //   if (params.inputValue !== '') {
        //     filtered.push({
        //       inputValue: params.inputValue,
        //       title: `Add "${params.inputValue}"`,
        //     });
        //   }

        //   return filtered;
        // }}

        freeSolo
        id="free-solo-2-demo"
        disableClearable
        // options={listofSuggestion.map((option) => option.firstname+' '+option.lastname+' '+option.email)}
        options={listofSuggestion}


          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            //return option.title;
            //this.setState({ ["selectedIdSuggestion"]: option.id}); 
            //console.log(';;;;; sel id is '+option.id); 

            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            //return option.title;
            //this.setState({ ["selectedIdSuggestion"]: option.id}); 
          //console.log('sel id is '+option.id); 
          return (option.firstname+' '+option.lastname+' '+option.email);
            
          }}

          renderOption={(option) => (option.firstname+' '+option.lastname+' '+option.email)}

        renderInput={(params) => ( 
          <TextField
            {...params}
            label={t('zqwTNVFtxt33_x')}
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    );
  }

  gowithNewCustomerMannual = (clientIdNone) => {  
    //const serachObj={search:search, searchEmail:""};
    const clientId=this.state.idClient;
    const listOfTickets=this.state.listOfTickets;
    //if(listOfTickets.length==0 && clientId > 0){
      const serachObj={search:"", clientId:clientId};
      const this00=this;  
      this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""}); 
      Promise.resolve(this.props.getB2BContractListDetails(serachObj))
      .then((response)=>{ 
        console.log('gowithNewCustomerMannual  '); console.log(response); 
        this.setState({ ["listOfContracts"]: response.NeoClientsOne, ["isLoaderOpen"]: false, ["clientMannual"]: 1,  }); 
        //this.setState({ ["isLoaderOpen"]: false});  
        this.setState({ ["globalStepWorkflow"]: 2 });
      return response;
      }).catch(function(error){ 
        const errObj=error;
        //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
        let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
        if(errObj.status===undefined){
          errMessage +="\n There was some error from the expected data from the server. Please try again later."
        }
        this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
         
        console.log("ERROR WITH TRANSACTION"); 
        console.log(error);
        console.log("SHORT ERROR MESSAGE: "+errMessage);
        console.log("RWA RESPONSE");  
      });
    //}
    
  };

  //handleAddToClientB2B
  handleAddToClientB2B = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    
    const id = this.state.b2b.id;
    const id_b2b_list = this.state.b2b.id_b2b_list;
    const id_external = this.state.b2b.id_external;
    const name_id_external = this.state.b2b.name_id_external;
    const firstname = this.state.b2b.firstname;
    const lastname = this.state.b2b.lastname;
    const email = this.state.b2b.email;
    const tel = this.state.b2b.tel;
    const start_date = this.state.b2b.start_date;
    const expiry_date = this.state.b2b.expiry_date;
    const address = this.state.b2b.address;
    const city = this.state.b2b.city;
    const province = this.state.b2b.province;
    const postal_code = this.state.b2b.postal_code;
    const raw_data = this.state.b2b.raw_data;
    const date_created = this.state.b2b.date_created;
    const date_updated = this.state.b2b.date_updated;
    const status = this.state.b2b.status;
    const dob = this.state.b2b.dob;
    const id_clients = this.state.b2b.id_clients;
    const NeoB2bClients = {id: id, id_b2b_list: id_b2b_list, id_external: id_external, name_id_external: name_id_external, firstname: firstname, lastname: lastname, email: email, tel: tel, start_date: start_date, expiry_date: expiry_date, address: address, city: city, province: province, postal_code: postal_code, raw_data: raw_data, date_created: date_created, date_updated: date_updated, status: status, dob: dob, id_clients: id_clients, }
    
  let errorFound=0;
  const b2b=this.state.b2b;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION2)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  /*if(FIELD_VALIDATION2[fieldName]!==undefined && obj.check===true){
      //this.setState({ ["e_"+fieldName]: false });
      //this.setState({ ["e_"+fieldName]: false });
      this.setState(prevState => ({
          b2b: {
              ...prevState.b2b,
              [prevState.b2b["e_"+fieldName]]: false,
          },
      }));   
		  let fieldValue= this.state.b2b[fieldName]; // ? this.state[fieldName] : this.props.NeoB2bClients[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
        //this.setState({ ["e_"+fieldName]: true }); 	
        //this.setState({ ["e_"+fieldName]: true });     
        this.setState(prevState => ({
            b2b: {
                ...prevState.b2b,
                [prevState.b2b["e_"+fieldName]]: true,
            },
        })); 
        console.log(`eeeeeeeeeeeeeerr ${fieldName}: ${obj}`, obj);	
		  }
    }*/

    const b2b=this.state.b2b;  //ClientB2B 
    let fieldValue= b2b[fieldName]; //this.state.b2b[fieldName]; 
	  if(FIELD_VALIDATION2[fieldName]!==undefined && FIELD_VALIDATION2[fieldName].check===true){
      //b2b[event.target.name]=fieldValue;
      b2b["e_"+fieldName]=false;
      //this.setState({ ["b2b"]: b2b });  
		  if(!this.validation.validate(FIELD_VALIDATION2[fieldName].chkType, fieldValue)){
        errorFound++; 
        b2b["e_"+fieldName]=true;
        //this.setState({ ["b2b"]: b2b }); 
        //this.setState({ ["e_"+fieldName]: true }); 	
        console.log(`eeeeeeeeeeeeeerr ${fieldName}: ${obj}`, obj);	
      }
      this.setState({ ["b2b"]: b2b }); 
	  } 	
    
    
	}
  if(errorFound > 0){ 
    //alert(t('zqwerrorFoundonform_x')); 
    this.showToast(t('zqwerrorFoundonform_x'), "error");  
    return false; 
  }
  
  //alert("Hello here is at the end...."); return false;  
  
  /*if(this.actionMode==='new'){ 
		this.props.addOrUpdateNeoB2bClients(NeoB2bClients, 1); 
		//this.props.addNeoB2bClients(NeoB2bClients); 
	}else{ 
		this.props.addOrUpdateNeoB2bClients(NeoB2bClients, 2);
		//this.props.updateNeoB2bClients(NeoB2bClients);
		
  }*/

    const this00=this;  
    this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""}); 
    Promise.resolve(this.props.saveB2BClient(NeoB2bClients))
    .then((response)=>{ 
      console.log('handleAddToClientB2B  '); console.log(response); 
      this.setState({ ["isLoaderOpen"]: false, ["clientMannual"]: 0,  }); 
      //this.setState({ ["isLoaderOpen"]: false});  
      //this.setState({ ["globalStepWorkflow"]: 2 });
      const newValue=response.NeoClientsOne;
      this.setState({ ["b2b"]: newValue});  //xaza  this.setState({ clientInfo });
            this.setState({ ["valueClientSearch2"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)}); 
             
              const clientInfo={  
                id_clients:newValue.id_clients, 
                idClient:newValue.id_clients, 
                firstname:newValue.firstname, 
                lastname:newValue.lastname, 
                cell:newValue.tel, 
                terms:newValue.terms, 
                active:'1',  
                email:newValue.email, 
                //id_users:parseInt(newValue.id_users), 
                //language:newValue.language, 
                push:1, 
                //id_zendesk:newValue.id_zendesk, 
                sms:1,
                //stripe_id:newValue.stripe_id,  
      
              }; 
              this.setState(clientInfo ); //xaza  this.setState({ clientInfo });

              const clientAddress={
                //idAddress:address.id, 
                address:newValue.address, 
                city:newValue.city, 
                province:newValue.province, 
                cp:newValue.postal_code, 
                country:"Canada", 
                //company:address.company,  
              };
    
              this.setState( clientAddress ); 
              this.setState({ ["globalStepWorkflow"]: 2 });
              this.showToast("On a sauvegardé les données du client B2B avec succès!", "success");  
    return response;
    }).catch(function(error){ 
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur   \n"+errObj.data : "Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n There was some error from the expected data from the server. Please try again later."
      }
      this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
        
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE");  
      this00.showToast(errMessage, "error");  
    });
  
  };


  ProductsOnInvoice = (event) => {
    const { t } = this.props;
    const taxInfo=this.state.taxInfo;
    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    //const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    }  
    const TAX_TOTAL=TAX1+TAX2; //0.14975;         
    const arrProd=[6,8,9]; 
    let productOptions = [];
    let productList = this.productsList.products.length > 0
    	&& this.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        if(item.type.indexOf('hidden') <0 && item.titre!==''){
          productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
        }
      return (
        null
      );
    }, this);
 
    //const ProductExtraOptionCtrl=this.state.productExtraOptionCtrl;  
    const ProductExtraOptionCtrl2=this.state.productExtraOptionCtrl2;
    const grandTotal=this.state.grandTotal;
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0,
    const orderBruteSubTotal=this.state.orderBruteSubTotal; 
    const orderTVQ=this.state.orderTVQ; //shows at the end
    const orderTPS=this.state.orderTPS;  //shows at the end





    ///////////////////////////////////////////////////////////////////////////////////////
    let productCart=this.state.productCart;
    //const taxInfo=this.state.taxInfo;
        
    let subTotal=0;  let totTagTotOptions=0; let totProd=0;
    const listOfCurrentProducts=productCart.length > 0
    	&& productCart.map((itemState, i) => { 
        const isDelete=itemState.isdelete;
        const myItemtxt=""+itemState.id;
        const index=i; 
        if(myItemtxt.indexOf("98989") <0 && isDelete===undefined){ 
         
        const item= this.getProductChange(itemState.id); 
        const optionsEntries = (item.options!=undefined && !arrProd.includes(item.id)) ? Object.entries(item.options): [];
        const cartEntries = Object.entries(item.cart);

        const this00=this;

        let optionsProd=productCart[index].options; 
        let selPage=productCart[index].pages;
        let perPage=productCart[index].perPage;
        if(selPage<=1){  selPage=1; } 

        //totTagTotOptions=((selPage-1)*perPage);  
        totTagTotOptions=productCart[index].totOptions;

        // let tarif = (''+item.tarif).replace(/\W\$/g, ''); 
        // tarif=tarif.replace('$', '');
        const productPrice= itemState.productPrice2===0?itemState.productPrice:itemState.productPrice2;
        //let tarif = productPrice;
        //console.log("weeeeeeeeeeeeeeeeeeooooy ", productPrice, totTagTotOptions, "optionsProd", optionsProd); 
        let tarif =((productPrice*1)+(totTagTotOptions));
        let tarifPrix =productPrice; 
        //let selPage= event.target.value; 
        const productPriceTaxOnly= (((tarif*1)*(TAX_TOTAL))).toFixed(2);
        //const productPriceTotal= (((tarif*1)*0.15)+(tarif*1)).toFixed(2); //(((tarif*1)+(productPriceTaxOnly)));
        const productPriceTotal= ((((tarif*1)*TAX1)+((tarif*1)*TAX2))+(tarif*1)).toFixed(2); 
        const orderTVQ=((tarif*1)*TAX1).toFixed(2);
        const orderTPS=((tarif*1)*TAX2).toFixed(2); 
        //const orderServices=0;
        const orderServices=totTagTotOptions;  
        //const orderSubtotals=((tarif*1)+orderServices).toFixed(2);
        const orderSubtotals=((tarif*1)).toFixed(2);

        // let productCart=this.state.productCart;
         const indexProd=index; //productCart.length;
        const apartir= item.apartir; let apartirUsed=false;
        //const optionsProd=item.options;

        //optionsProd.perPage} data-maximum={optionsProd.maximum
        //optionsProd.push({id: prodOptId, mnt:amountOpt}); 
        //const groupVal=''+indexProd+'-'+item.id+'-'+txtLabel+'-'+amount;
        //const index=arVal[0];  const prodId=arVal[1];  const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
       //console.log("MMMMMMMMMMMMMM", itemState.options, " vs ", optionsProd);   

        const productExtraOptionCtrl2=optionsEntries.map(function(elem, index) { 
          const [txtLabel, amount] =elem;
          //const this0=this;
          //const groupVal=''+indexProd+'-'+item.id+'-'+amount;
          const groupVal=''+indexProd+'-'+item.id+'-'+txtLabel+'-'+amount;
          //console.log(`##arraymap There are ${elem} ---- ${index} +++++++++++++ ${txtLabel} -- ${amount} `);
          let titre= '';  
          for (const [txtLabel2, labelValue] of cartEntries) { 
            if(txtLabel===txtLabel2){ 
              titre= labelValue;
              //console.log(`##zazThere are ${txtLabel2} //////VS ${labelValue}  `);
              break;
            } 
          }
          if(titre==='' && apartir===false){
            return (<span key={index} />);
          }if(apartir===true){
            //apartirUsed=true;
            if(apartirUsed===false){
              apartirUsed=true;
              return (<p key={index}>Veuillez indiquer le nombre de pages à relire:<br/>
                <input type="number" min="1" step="1" defaultValue={selPage} 
              data-value={groupVal} data-perpage={optionsProd.perPage} data-maximum={optionsProd.maximum} 
              onChange={(eventx) => this00.setPageDocForProduct(eventx)}  /> Pages (n x ${amount})</p>);
            }else{ return (<span key={index} />); }
          }else{
            //console.log(`##INDEEEEEEEEEEEEEEEEEX SET IS ${groupVal} VS ${txtLabel} - ${optionsProd.id} `);
            let isSelected=false; const txtLabelx=txtLabel; const txtLabelzx=optionsProd.id;
            //let indz=-9;
            const indz=this00.indexOfElement(optionsProd, txtLabel, amount);
            //console.log('REACT ] indzindzindzindzindzindz  '+indz);
            if(indz >-1){ 
              isSelected=true; 
            } 

            return (<p key={index} ><input   type="checkbox" value={groupVal} defaultChecked={isSelected}  onChange={(eventx) => this00.setExtraServiceForProduct(eventx)}  /> {titre} (${amount})</p>);  
          }
        });
            
        //const rId=(Math.floor(Math.random()*16));
        const rId=itemState.randcolor;

        
        //<div className="card card-primary card-outline">
        const divBlock=(
          <div key={indexProd} className="marginr md-4">
          <div className="card card-default neocardx">
            <div className={`card-header neocard${rId}`}>
              <h5 className="m-0">{item.titre}</h5>
            </div>
            <div className="card-body">
{/* 
              <h6 className="card-title"><b>{tarif}</b>$ plus taxes</h6><br/>
              <h6 className="card-title">Taxes <b><span ref={this.prodTaxOnly[indexProd]}>{productPriceTaxOnly}</span></b>$</h6><br/>
              <h6 className="card-title"><b><span ref={this.prodPriceAndTax[indexProd]}>{productPriceTotal}</span></b>$ avec taxes</h6> */}

              <div className="row">
                <div className="col-md-10">
                  <div className="row" style={{display:"block"}}>
                    <div className="neoinline50"><span>Prix du Produit</span></div>
                    <div className="neoinline50l">    
                <input type="number" step="0.1" min="1" name="neoPrice" defaultValue={tarifPrix}  
                data-value={indexProd+'-'+item.id}    
              onChange={(eventx) => this00.setNewPriceForProduct(eventx)} className="form-control col-md-8x" size="30" style={{display:'inline', width:'100px'}} /> 
                    <span></span>
                    <span style={{display:'inline'}}>
                    <a href="#" data-id={item.id} onClick={(eventx) => this00.showInfoForProduct(eventx)}><InfoIcon /></a>
                    </span>
                    </div>
                  </div>
                  <div className="row" style={{display:"block"}}> 
                    <div className="neoinline50 text-right"><span>Service Aditionnel</span></div>
                    <div className="neoinline50l"> <span>{orderServices}</span>$</div> 
                  </div>
                  <div className="row" style={{display:"block"}}> 
                    <div className="neoinline50"><span>Sous-total</span></div>
                    <div className="neoinline50l"> <span>{orderSubtotals}</span>$</div>
                  </div>
                  
                  {TAX1_NAME!=="0"?
                    <div className="row" id="tax1_block" style={{display:"block"}}> 
                    <div className="neoinline50 text-right"><span>{TAX1_NAME!=="0"?TAX1_NAME:''}</span> 
                    (<span>{TAX1lx}</span>%)</div>
                    <div className="neoinline50l"> <span>{orderTVQ}</span>$</div> 
                  </div>:'' }

                   {TAX2_NAME!=="0"?
                    <div className="row" id="tax2_block" style={{display:"block"}}> 
                    <div className="neoinline50"><span>{TAX2_NAME!=="0"?TAX2_NAME:''}</span> (<span>{TAX2lx}</span>%)</div>
                    <div className="neoinline50l"> <span>{orderTPS}</span>$</div>
                  </div>:'' }

                  <div className="row" id="tax2_block" style={{display:"block"}}> 
                    <div className="neoinline50"><span>Totales Taxes</span></div>
                    <div className="neoinline50l"> <b><span>{productPriceTaxOnly}</span></b>$</div>
                  </div>
                  <div className="row"><div className="neoinline30"> </div><div className="neoinline50"><hr/></div></div>
                  <div className="row" id="tax2_block" style={{display:"block"}}> 
                    <div className="neoinline50"><span>Total</span></div>
                    <div className="neoinline50l"> <span>{productPriceTotal}</span>$</div>
                  </div>
                </div>
              </div>


              <br/>
              {optionsEntries.length > 0 && cartEntries.length > 0? <>Les Options de Service Aditionnel: <hr/></>:''}
              
              {productExtraOptionCtrl2}
              <br/>
              <div className="ovalItemPrice">  
              <span>{productPriceTotal}</span>$  
              </div> 
              <p style={{display:"none"}}>
                <input type="checkbox" value={indexProd+'-'+item.id} defaultChecked  
              onChange={(eventx) => this00.setTicketForProduct(eventx)}  /> Créer un dossier et un ticket zendesk</p>
             
              <a href="#" className="btn btn-danger" data-prodindex={indexProd} onClick={this.handleRemoveProductItem} >remove</a>
            </div>
          </div>
        </div> 
        ); 
 
         return divBlock;
        }

        return null;

    }, this);

    
    return (
      <>
      <div className="form-group">
          <label htmlFor="name">{t('zqwname_x')} du Produit</label>
          <Select options={productOptions} onChange={this.handleProductChange2} />            
      </div>
      <div className="card card-primary card-outline">
        <div className="card-header">
          <h5 className="m-0">LA LISTE DES PRODUITS DANDS LA FACTURE</h5>
        </div>
        <div className="card-body">
          {/* <div className="form-group">
              <label htmlFor="name">{t('zqwname_x')} du Produit</label>
              <Select options={productOptions} onChange={this.handleProductChange} />            
          </div>  */}
          <div className="row"> 
              <div className="form-group"><div className="input-group">
              {/* <label htmlFor="province">Taxe de {t('zqwprovince_x')} *</label>  */}
              <label htmlFor="province">Taxe de {t('zqwprovince_x')} *</label> &nbsp;  
                    <select name="province" id="province" value={this.state.province} onChange={this.handleChangeProvince} onBlur={this.handleFocusout}
                      className={this.state.e_province ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwprovince_x')} >
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                      </select> 
                      </div></div></div>


        {/* <div className="row">
        {ProductExtraOptionCtrl2}
            +++++++++++++++++++++++++++++++++++++
        </div> */}
        <div className="row">
          {listOfCurrentProducts}
        </div>

         
        <div className="card-text"> 
         {/* <h5 className="card-title">Total <b>{grandTotal}</b></h5><br/> */}
         {/* <div class="row" style="display: block;"> */}
         <div className="neoinline40">
          <div className="row" id="tax2_blockqqqqq2">
            <div className="neoinline50"><span>Sous-total</span></div>
            <div className="neoinline50l"> <span>{orderBruteSubTotal}</span>$</div>
          </div>
           
        {TAX1_NAME!=="0"?
          <div className="row" id="tax1_blockqqqq">
            <div className="neoinline50"><span>{TAX1_NAME!=="0"?TAX1_NAME:''}</span> (<span>{TAX1lx}</span>%)</div>
            <div className="neoinline50l"> <span>{orderTVQ}</span>$</div>
          </div>:'' } 

 
          {TAX2_NAME!=="0"?
            <div className="row" id="tax2_blockqqqqq">
            <div className="neoinline50"><span>{TAX2_NAME!=="0"?TAX2_NAME:''}</span> (<span>{TAX2lx}</span>%)</div>
            <div className="neoinline50l"> <span>{orderTPS}</span>$</div>
          </div>:''} 

 
          <div className="row" id="tax2_blockqqqqq3">
            <div className="neoinline50"><span>Total</span></div>
            <div className="neoinline50l"> <span><b>{grandTotal}</b></span>$</div>
            <div className="ovalTotalPrice"> &nbsp; <span>Total à payer</span> <span><b>{grandTotal}</b></span>$ &nbsp; </div>
            
          </div>
 

         </div>
          
        </div>
         <button type="button" className="btn btn-default" onClick={this.handlePreviousStep}>Étape Antérieure</button> &nbsp; 
        <a href="#" className="btn btn-primary"  onClick={this.handleProductNextStep} >Étape Suivante</a>
        <a href="#" className="btn btn-warning float-right"  onClick={this.restartOver}>Recommencer Complètement</a>
        </div>
      </div></>
    );
  }

  CustomerInfoAddress = (event) => {
    const { t } = this.props; 
  
      const ProductExtraOptionCtrl2=this.state.productExtraOptionCtrl2;
       
  
    //value={valueClientSearch}  inputValue={inputValueClientSearch}
    const clientInfo=this.state.clientInfo;
    //const clientAddress=this.state.clientAddress;
  
    if(clientInfo!=null){  
    }

    // const mask2=/[0-9A-Z][0-9A-Z][0-9A-Z] [0-9A-Z][0-9A-Z][0-9A-Z]{3}/i;  //const mask2=/\w\w\w \w\w\w/i;  const mask2=/\w{3} \w{3}/i;
    // const mask=[mask2];

    const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
    const letter = /(?!.*[DFIOQU])[A-Z]/i;
    const digit = /[0-9]/;
    const mask = [firstLetter, digit, letter, " ", digit, letter, digit];
    const AutocompleteClient=this.AutocompleteClient;
     

    return (
      <div className="card card-primary card-outline">
      <div className="card-header">
      <h5 className="m-0">{t('zqwTNVFtxt25_x')}</h5>
      </div>
      <div className="card-body">
      {/* <h6 className="card-title"><b>{productPrice}</b>$ plus taxes</h6> 

      <br/><br/>Les Options de Service Aditionnel: */}
      {/* <hr/> */}

        <AutocompleteClient key={91090}  propstep={2} />   

      {/* <a href="#" className="link" onClick={this.resetToNewCustomer}  >Reset Form/Nettoyer</a> */}
   
    <br/>
    {/* Seleted option ID: {selectedIdSuggestion}
    <hr/>
  
      <br/><br/> */}
      <div className="card-text"> 
       {/* <h5 className="card-title">Total <b>{productPriceTax}</b></h5><br/> */}
 

       <form onSubmit={this.handleSubmitClientInfo} autoComplete="off">
       <div className="form-group"  >
              <label htmlFor="company_name">{t('zqwTNVFtxt26_x')}</label> 
            <input type="text" name="company_name" id="company_name" defaultValue={this.state.b2b.company_name} 
              className='form-control' readOnly={true}  />  
          </div>

              <div className="form-group" style={{display:'none'}} >
              <label htmlFor="id">{t('zqwid_x')}</label>
                    <input type="text" name="idClient" id="idClient" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
              {this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
                  </div>
        
            <div className="form-row">
              <div className="form-group col-md-6">
              <label htmlFor="firstname">{t('zqwfirstname_x')} *</label>
              <input type="text" name="firstname" id="firstname" defaultValue={this.state.firstname} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_firstname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwfirstname_x')} readOnly={this.state.existedClientRO} /> 
              {this.state.e_firstname && (<span className="text-danger">{FIELD_VALIDATION['firstname'].message}</span>)} 
                  </div>
        
              <div className="form-group col-md-6">
              <label htmlFor="lastname">{t('zqwlastname_x')} *</label>
                    <input type="text" name="lastname" id="lastname" defaultValue={this.state.lastname} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_lastname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlastname_x')} readOnly={this.state.existedClientRO} /> 
              {this.state.e_lastname && (<span className="text-danger">{FIELD_VALIDATION['lastname'].message}</span>)} 
                  </div>
            </div>

              <div className="form-group">
                <label htmlFor="company">{t('zqwcompany_x')}  (Facultatif)</label>
                <input type="text" name="company" id="company" defaultValue={this.state.company} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_company ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcompany_x')} /> 
              {this.state.e_company && (<span className="text-danger">{FIELD_VALIDATION['company'].message}</span>)} 
                  </div> 

            <div className="form-row">
              <div className="form-group col-md-6">
              <label htmlFor="email">{t('zqwemail_x')} *</label>
                    <input type="text" name="email" id="email" defaultValue={this.state.email} onChange={this.handleChange} onBlur={this.handleChangeEmailFocus}
                      className={this.state.e_email ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwemail_x')} readOnly={this.state.existedClientRO} /> 
              {this.state.e_email && (<span className="text-danger">{FIELD_VALIDATION['email'].message}</span>)} 
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="cell">{t('zqwcell_x')} *</label>
                    {/* <input type="text" name="cell" id="cell" defaultValue={this.state.cell} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_cell ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcell_x')} />  */}
                <InputMask name="cell" mask="(999) 999-9999" onChange={this.handleChange} value={this.state.cell}  onBlur={this.handleFocusout} 
                      className={this.state.e_cell ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcell_x')} />
              {this.state.e_cell && (<span className="text-danger">{FIELD_VALIDATION['cell'].message}</span>)} 
                  </div>
            </div>

        
              {/* <div className="form-group">
              <label htmlFor="id_users">{t('zqwid_users_x')}</label>
                    <input type="text" name="id_users" id="id_users" defaultValue={this.state.id_users} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_id_users ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_users_x')} /> 
              {this.state.e_id_users && (<span className="text-danger">{FIELD_VALIDATION['id_users'].message}</span>)} 
                  </div> */}
        
              <div className="form-group">
              <label htmlFor="language">{t('zqwlanguage_x')} *</label>
                    {/* <input type="text" name="language" id="language" defaultValue={this.state.language} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_language ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlanguage_x')} /> */}
                    <select  name="language" id="language" defaultValue={this.state.language} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_language ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlanguage_x')} >
                        <option value="">{t('zqwTNVFtxt27_x')}</option> 
                        <option value="fr">{t('zqwTNVFtxt28_x')}</option>
                        <option value="en">{t('zqwTNVFtxt29_x')}</option> 
                    </select>   
              {this.state.e_language && (<span className="text-danger">{FIELD_VALIDATION['language'].message}</span>)} 
                  </div>
         
              <div className="form-group">
              {/* <label htmlFor="sms">{t('zqwsms_x')}</label>
                    <input type="text" name="sms" id="sms" defaultValue={this.state.sms} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_sms ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwsms_x')} /> */}
                    <div className="checkbox-inline">
                      {/* <label>  <input type="checkbox" id="sms" name="sms" defaultValue={this.state.sms} checked={this.state.sms} onChange={this.handleChangeChkBox} /> 
                      &nbsp; J'accepte de recevoir des notifications par SMS concernant mon dossier (recommandé)</label> */}
                      <label>  <Switch checked={this.state.sms===1?true:false} onChange={this.handleChangeChkBox} name="sms" color="primary"  /> 
                      &nbsp; {t('zqwTNVFtxt30_x')}</label>
                    </div>  
              {this.state.e_sms && (<span className="text-danger">{FIELD_VALIDATION['sms'].message}</span>)} 
                  </div>


{/* aaaaaaaaaaaaaaaddress */}


          <div className="form-row neobgGA">
            <div className="form-group col-md-6"> 
              <GoogleAddressSearch parentCallback = {this.callbackFunction} 
              placeholder={t('zqwTNVFtxt32_x')}
              searchOptions={this.searchOptions} /> 
 
            </div> 
            {/* <div className="form-group col-md-4">  
              <select name="countryTuner" defaultValue={'ca'} onChange={this.handleCountryTuner} 
                      className={'form-control'} placeholder={'pays ou zone pour sintoniser'} >
                        <option value="ca">Filtré Map pour Canada</option>
                        <option value="us">Filtré Map pour Etats-Unis</option>
                        <option value="fr">Filtré Map pour France</option>
                        <option value="">Filtré Map pour Le monde entier</option> 
                      </select>                
            </div> */}
            <div className="form-group col-md-2">   
              {/* <a href="#" onClick={this.openZipCodeAddressSetter} title="click pour voir dernière recher avec code postal"><DraftsIcon /></a> */}
              <Tooltip title="click pour voir dernière recher avec code postal" aria-label="add">
                 <a href="#" onClick={this.openZipCodeAddressSetter} ><DraftsIcon /></a> 
              </Tooltip>
            </div> 
          </div>

            <div className="form-group" style={{display:'none'}} >
              <label htmlFor="id">{t('zqwid_x')}</label>
                    <input type="text" name="idAddress" id="idAddress" defaultValue={this.state.idAddress} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_idAddress ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
              {this.state.e_idAddress && (<span className="text-danger">{FIELD_VALIDATION['idAddress'].message}</span>)} 
                  </div>

              <div className="form-group">
              <label htmlFor="address">{t('zqwaddress_x')} *</label>
                    <input type="text" name="address" id="address" defaultValue={this.state.address} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_address ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwaddress_x')} /> 
              {this.state.e_address && (<span className="text-danger">{FIELD_VALIDATION['address'].message}</span>)} 
                  </div>


            <div className="form-row">  
              <div className="form-group col-md-6"> 
              <label htmlFor="unitsuite">#{t('zqwTNVFtxt31_x')}</label>
                <input type="text" name="unitsuite" defaultValue={this.state.unitsuite} onChange={this.handleChange} onBlur={this.handleFocusout}
                    className={this.state.e_unitsuite ? 'form-control fieldErr' : 'form-control'} placeholder={'#appartement ou suite ou unité'} /> 
                {this.state.e_unitsuite && (<span className="text-danger">{FIELD_VALIDATION['unitsuite'].message}</span>)} 
              </div>
              <div className="form-group col-md-6">
              <label htmlFor="city">{t('zqwcity_x')} *</label>
                    <input type="text" name="city" id="city" defaultValue={this.state.city} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_city ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcity_x')} /> 
              {this.state.e_city && (<span className="text-danger">{FIELD_VALIDATION['city'].message}</span>)} 
                  </div>
            </div>


              <div className="form-group" >
                <label htmlFor="country">{t('zqwcountry_x')} *</label>
                <div className="input-group">
                <select name="countrypays" className={'form-control'} value={this.state.country} onChange={this.handleChangeCountrypays}>
<option value="Canada">Canada</option>
{/* <option value="Etats-Unis">États-Unis</option>
<option value="USA">Etats-Unis</option> 
<option value="France">France</option> */}
</select>
                <input type="text" name="country" id="country" value={this.state.country} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_country ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcountry_x')} /> 
                  {this.state.e_country && (<span className="text-danger">{FIELD_VALIDATION['country'].message}</span>)} 
              </div></div>  
            {(this.state.country==="Canada" || this.state.country==="Etats-Unis")?        
            <div className="form-row"> 
              <div className="form-group col-md-6">
              <label htmlFor="province">{t('zqwprovince_x')} *</label>
                {this.state.country==="Canada"?
                    <select name="province" id="province" value={this.state.province} onChange={this.handleChangeProvince} onBlur={this.handleFocusout}
                      className={this.state.e_province ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwprovince_x')} >
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                      </select>:''  }  
              {this.state.country==="Etats-Unis"?
                      <select name="province" id="province" value={this.state.province} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_province ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwprovince_x')} >
                        <option value="">Choisir État</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                        <option value="AS">American Samoa</option>
                        <option value="GU">Guam</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="UM">United States Minor Outlying Islands</option>
                        <option value="VI">Virgin Islands</option> 
                      </select>:''  }    
              {this.state.e_province && (<span className="text-danger">{FIELD_VALIDATION['province'].message}</span>)} 
                  </div>

              <div className="form-group neoCap col-md-6">
              <label htmlFor="cp">{t('zqwcp_x')} *</label>
                    {/* <input type="text" name="cp" id="cp" defaultValue={this.state.cp} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_cp ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcp_x')} /> */}
                    {this.state.country==="Canada"?  
                    <InputMask name="cp" mask={mask} onChange={this.handleChange} value={this.state.cp}  onBlur={this.handleFocusout} 
                      className={this.state.e_cell ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcp_x')} />:''  } 
                    {this.state.country==="Etats-Unis"? 
                    <input type="text" name="cp" id="cp" defaultValue={this.state.cp} onChange={this.handleChange} onBlur={this.handleFocusout}
                    className={this.state.e_cp ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcp_x')} />:''  }      
              {this.state.e_cp && (<span className="text-danger">{FIELD_VALIDATION['cp'].message}</span>)} 
                  </div>
            </div>:''  
            } 

            {(this.state.country!=="Canada" && this.state.country!=="Etats-Unis")?        
            <div className="form-row"> 
              <div className="form-group col-md-6">
              <label htmlFor="province">{t('zqwprovince_x')} *</label>
              <input type="text" name="province" id="province" value={this.state.province} onChange={this.handleChange} onBlur={this.handleFocusout} 
                      className={this.state.e_province ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwprovince_x')} maxLength={2} />  
              {this.state.e_province && (<span className="text-danger">{FIELD_VALIDATION['province'].message}</span>)} 
                  </div>

              <div className="form-group col-md-6">
              <label htmlFor="cp">{t('zqwcp_x')} *</label>
                    <input type="text" name="cp" id="cp" defaultValue={this.state.cp} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_cp ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcp_x')} /> 
              {this.state.e_cp && (<span className="text-danger">{FIELD_VALIDATION['cp'].message}</span>)} 
                  </div>
            </div>:''  
            }   
  
            <div className="form-group">
              <label htmlFor="occupation">{t('zqwoccupation_x')} *</label>
              <input type="text" name="occupation" id="occupation" defaultValue={this.state.occupation} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_occupation ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwoccupation_x')} />     
              {this.state.e_occupation && (<span className="text-danger">{FIELD_VALIDATION['occupation'].message}</span>)} 
            </div>       

              {/* <div className="form-group" >
                <label htmlFor="country">{t('zqwcountry_x')}</label>
                <input type="text" name="country" id="country" defaultValue={this.state.country} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_country ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcountry_x')} /> 
              {this.state.e_country && (<span className="text-danger">{FIELD_VALIDATION['country'].message}</span>)} 
              </div> */}

               
                {this.state.emailDoesNotExist===1?
                  <div className="form-group">
                    {/* <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')} Suivant -- Paiement</button> &nbsp;  */}
                     
                    <button type="button" className="btn btn-default" onClick={this.handlePreviousStep}>{t('zqwTNVFtxt21_x')}</button> &nbsp; 
                    <button type="button" className="btn btn-primary" onClick={this.handleClientInfoNextStep}>{t('zqwTNVFtxt22_x')}</button> &nbsp; 
                    <a href="#" className="btn btn-warning float-right"  onClick={this.restartOver}>{t('zqwTNVFtxt23_x')}</a>
                  </div>:
                  <div className="form-group red">
                  <p>{t('zqwTNVFtxt24_x')}</p>
                  {/* <p>Please click on the email field then  click out to validate the email.</p>  */}
                  </div> 
                }        
      </form>        


       </div>
      {/* <a href="#" className="btn btn-primary"  >Suivant -- Paiement</a> */}
      </div>
    </div>
    );
  }


  ZipCodePossibleAddress = (event) => { //() {
    const open = this.state.openPossibleZipCode; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");
    const listAddress=this.state.listAddressOnCode;
  
    return (
      <div>
        <Dialog maxWidth={"sm"} open={open} onClose={this.handleClosePostalCode} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
          <DialogContent id="form-dialog-confirm">
          <div className="text-center">
           
          </div>   
          <div className="text-center"><h2>{t('zqwTNVFtxt17_x')}</h2></div> 
            <p>{t('zqwTNVFtxt18_x')}</p> 
            {/* <p> &nbsp; </p> */}
            <div className="form-row neoxtbl">
            <table  style={{minWidth:'300px'}}>
            <thead><tr>
                <th colSpan="2">{t('zqwTNVFtxt19_x')}</th> 
                {/* <th> &nbsp; &nbsp;  </th>  */}
              </tr></thead>
              <tbody>
              {listAddress}
               
              </tbody>
            </table>

              <div className="form-group col-md-9">
              <br/>
              <input type="button" data-confirmyesno="1" onClick={this.handleClosePostalCode} className="btn btn-default" value={t('zqwTNVFtxt20_x')} /> &nbsp; &nbsp;
               
              </div> 
            </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }

  
  EligibilityClientInfo = (props) => {
    const { t } = this.props; 
    const ProductExtraOptionCtrl2=this.state.productExtraOptionCtrl2;
       
  
    //value={valueClientSearch}  inputValue={inputValueClientSearch}
    const clientInfo=this.state.clientInfo;
    //const clientAddress=this.state.clientAddress;
    const clientInfoB2B=this.state.b2b;
   

    const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
    const letter = /(?!.*[DFIOQU])[A-Z]/i;
    const digit = /[0-9]/;
    const mask = [firstLetter, digit, letter, " ", digit, letter, digit];
    const AutocompleteClient=this.AutocompleteClientB2B;
    const Alert=this.Alert;
    //const b2b=props.b2b; //this.state.b2b;
    const b2b=this.state.b2b;

    let productOptions = [];
    const productsList=this.state.productsList.products;
    let productList = productsList.length > 0
    	&& productsList.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        //console.log(item);
        if(item!==null){
          const isUpsell=item.upsell;
          if(isUpsell!==null && isUpsell!==undefined){
            productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
          }else{
            if(item.type.indexOf('hidden') <0 && item.titre!==''){
              //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
              productOptions.push({ value: item.id, label: item.titre });
              //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
            }
          }
        }
      return (
        null
      );
    }, this);

    const elibilityObj=this.state.elibilityObj;
    let consomptionUsedList = null;   let showBtnLead=false;    
    let isEligible=false;
    if(elibilityObj!=null && elibilityObj.isEligible!==undefined){
      isEligible=elibilityObj.isEligible;
      if(elibilityObj.isEligible===false && elibilityObj.b2bclientList!==undefined &&
        elibilityObj.b2bclientList.id>0){ showBtnLead=true;    }
    }
    if(elibilityObj!=null && elibilityObj.consumptionlist!==undefined){
      const consumptionlist=elibilityObj.consumptionlist;
      //let productOptions = []; 
       consomptionUsedList = consumptionlist.length > 0
        && consumptionlist.map((item, i) => {
          //console.log(item);
          if(item!==null){
            return (
              <tr key={"rweli"+i}>
                    <th scope="row">{item.id_invoices}</th>
                    <td>{item.created}</td> 
                    <td>{item.description}</td>
                  </tr>
            );
          }
        return (
          null
        );
      }, this);
    }

    const listOfContracts=this.state.listOfContracts;
    const clientMannual=this.state.clientMannual;
    //<option value="1">Yes</option> ClientB2B
    const listOfContractsList = listOfContracts.length > 0
        && listOfContracts.map((item, i) => {
          //console.log(item);
          if(item!==null){
            return (
              <option  key={"opli"+i} value={item.id}>{item.name}</option> 
            );
          }
        return (
          null
        );
      }, this); 

       
      // const idClient=this.state.idClient;
      // const id_users=this.state.id_users;
      // const idAddress=this.state.idAddress;

      let isAlreadyNeoClient=false;
      if(this.state.idClient >0 && this.state.id_users >0 && this.state.idAddress >0){
        isAlreadyNeoClient=true;
      }
      const selectedProd=this.state.goProd;
      const dfe= Date.now();
      //const compoKey="kx"+b2b.id+dfe;
      //const compoKey=clientMannual===1?"kxRdxGoooo":"kx"+b2b.id+dfe;
      const compoKey=clientMannual===1?"kxRdxGoooo":"kx"+b2b.id;
       
      let extraInfo=[]; //let extraInfo="";

      if(b2b.raw_data!==undefined && b2b.raw_data!==null && b2b.raw_data!=="" && b2b.raw_data!=="manually" && b2b.raw_data!=="mannually"
      && b2b.raw_data!=="{\"source\":\"manual\"}"){
        //extraInfo=b2b.raw_data;
        //console.log("ERROR SOURCE ", b2b.raw_data);
        const extraInfoEntry=JSON.parse(b2b.raw_data);
        //const dfeKey= Date.now();
        let ktyCnt=0;
        for(let okey in extraInfoEntry){
          //extraInfo +=okey+":"+extraInfoEntry[okey]+"<br/>\n";
          //const dfeKey= Date.now();
          ktyCnt++;
          const rndKey=Math.floor(Math.random()*100); 
          //extraInfo.push(<><span className="lstp" key={"px"+rndKey}>{okey}: {extraInfoEntry[okey]}</span><br/></>);
          //extraInfo.push(<span className="lstp" key={"px"+rndKey}>{okey}: {extraInfoEntry[okey]}<br/></span>);
          extraInfo.push(<p className="lstp" key={"px"+rndKey+ktyCnt}>{okey}: {extraInfoEntry[okey]}</p>);
        }

      }

    const ProductsOnInvoice =this.ProductsOnInvoice;     
  
       
    return (
      <div className="card card-primary card-outline">
      <div className="card-header">
      <h5 className="m-0">{t('zqwTNVFtxt1_x')}</h5>
      </div>
      <div className="card-body">
      {/* <h6 className="card-title"><b>{productPrice}</b>$ plus taxes</h6> 

      <br/><br/>Les Options de Service Aditionnel: */}
      {/* <hr/> */}

       {clientMannual===0? 
       <AutocompleteClient key={91090}  propstep={2} />:''  } 

      {/* <a href="#" className="link" onClick={this.resetToNewCustomer}  >Reset Form/Nettoyer</a> */}
   
    <br/>
    {/* Seleted option ID: {selectedIdSuggestion}
    <hr/>
  
      <br/><br/> */}
      <div className="card-text" key={compoKey}> 
       {/* <h5 className="card-title">Total <b>{productPriceTax}</b></h5><br/> */}
 

       <form onSubmit={this.handleSubmitClientInfo} autoComplete="off">
               
{/* ////////////////////////////////////////////////////////////////////////////////
            b2b. */}

      {clientMannual!==1? 
      <div className="form-group"  >
              <label htmlFor="company_name">{t('zqwTNVFtxt2_x')}</label> 
            <input type="text" name="company_name" id="company_name" defaultValue={b2b.company_name} 
              className='form-control' readOnly={true}  />  
          </div>:'' }

        <div className="form-group" style={{display:'none'}} >
              <label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={b2b.id} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{b2b.e_id && (<span className="text-danger">{FIELD_VALIDATION2['id'].message}</span>)} 
          </div>

      {clientMannual===1? 
      <div className="form-group">
        <label htmlFor="id_b2b_list">{t('zqwid_b2b_list_x')} <span className="red">*</span></label>
        <select name="id_b2b_list" id="id_b2b_list" defaultValue={b2b.id_b2b_list}  onChange={this.handleChangeb2b2} onBlur={this.handleFocusoutb2b}
              className={b2b.e_id_b2b_list ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstatus_x')}> 
                        <option value="">{t('zqwTNVFtxt3_x')}</option>
                        {listOfContractsList}
                    </select>
          {b2b.e_id_b2b_list && (<span className="text-danger">{FIELD_VALIDATION2['id_b2b_list'].message}</span>)}          
      </div>:<div className="form-group" style={{display:'none'}} >
			    <label htmlFor="id_b2b_list">{t('zqwid_b2b_list_x')}</label>
            <input type="text" name="id_b2b_list" id="id_b2b_list" defaultValue={b2b.id_b2b_list} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_id_b2b_list ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_b2b_list_x')} /> 
			{b2b.e_id_b2b_list && (<span className="text-danger">{FIELD_VALIDATION2['id_b2b_list'].message}</span>)} 
          </div>}

     {/* {clientMannual===0? 
     <> */}

      <div className="form-group"  style={{display:'none'}} >
			    <label htmlFor="name_id_external">{t('zqwname_id_external_x')}</label>
            <input type="text" name="name_id_external" id="name_id_external" defaultValue={b2b.name_id_external} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_name_id_external ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwname_id_external_x')} /> 
			    {b2b.e_name_id_external && (<span className="text-danger">{FIELD_VALIDATION2['name_id_external'].message}</span>)} 
      </div>

		  <div className="form-group">
			    {/* <label htmlFor="id_external">{t('zqwid_external_x')}</label> */}
          <label htmlFor="id_external">{b2b.name_id_external} <span className="red">*</span></label>
            <input type="text" name="id_external" id="id_external" defaultValue={b2b.id_external} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_id_external ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_external_x')} /> 
			    {b2b.e_id_external && (<span className="text-danger">{FIELD_VALIDATION2['id_external'].message}</span>)} 
      </div>
      {/* </>:''} */}   


		  {/* <div className="form-group">
			    <label htmlFor="name_id_external">{t('zqwname_id_external_x')}</label>
            <input type="text" name="name_id_external" id="name_id_external" defaultValue={b2b.name_id_external} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_name_id_external ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwname_id_external_x')} /> 
			    {b2b.e_name_id_external && (<span className="text-danger">{FIELD_VALIDATION2['name_id_external'].message}</span>)} 
      </div> */}

		  <div className="form-group">
			<label htmlFor="firstname">{t('zqwfirstname_x')} <span className="red">*</span></label>
            <input type="text" name="firstname" id="firstname" defaultValue={b2b.firstname} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_firstname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwfirstname_x')} /> 
			{b2b.e_firstname && (<span className="text-danger">{FIELD_VALIDATION2['firstname'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="lastname">{t('zqwlastname_x')} <span className="red">*</span></label>
            {/* <input type="text" name="lastname" id="lastname" defaultValue={b2b.lastname} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_lastname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlastname_x')} />  */}
            {/* <input type="text" name="lastname" id="lastname" defaultValue={b2b.lastname}  onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}    
              className={b2b.e_lastname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlastname_x')} />   */}
           <input type="text" name="lastname" id="lastname" defaultValue={this.state.b2b.lastname} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={this.state.b2b.e_lastname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlastname_x')} />     
			{b2b.e_lastname && (<span className="text-danger">{FIELD_VALIDATION2['lastname'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="email">{t('zqwemail_x')} <span className="red">*</span></label>
            <input type="text" name="email" id="email" defaultValue={b2b.email} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_email ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwemail_x')} /> 
			{b2b.e_email && (<span className="text-danger">{FIELD_VALIDATION2['email'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="tel">{t('zqwtel_x')} <span className="red">*</span></label>
            {/* <input type="text" name="tel" id="tel" defaultValue={b2b.tel} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_tel ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtel_x')} />  */}
              <InputMask name="tel" mask="(999) 999-9999" onChange={this.handleChangeb2b} value={b2b.tel}  onBlur={this.handleFocusoutb2b} 
                      className={b2b.e_tel ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtel_x')} />
			{b2b.e_tel && (<span className="text-danger">{FIELD_VALIDATION2['tel'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="start_date">{t('zqwstart_date_x')}</label>
            <input type="date" name="start_date" id="start_date" defaultValue={b2b.start_date} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_start_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstart_date_x')} /> 
			{b2b.e_start_date && (<span className="text-danger">{FIELD_VALIDATION2['start_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="expiry_date">{t('zqwexpiry_date_x')}</label>
            <input type="date" name="expiry_date" id="expiry_date" defaultValue={b2b.expiry_date} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_expiry_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwexpiry_date_x')} /> 
			{b2b.e_expiry_date && (<span className="text-danger">{FIELD_VALIDATION2['expiry_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="address">{t('zqwaddress_x')} <span className="red">*</span></label>
            <input type="text" name="address" id="address" defaultValue={b2b.address} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_address ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwaddress_x')} /> 
			{b2b.e_address && (<span className="text-danger">{FIELD_VALIDATION2['address'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="city">{t('zqwcity_x')} <span className="red">*</span></label>
            <input type="text" name="city" id="city" defaultValue={b2b.city} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_city ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcity_x')} /> 
			{b2b.e_city && (<span className="text-danger">{FIELD_VALIDATION2['city'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="province">{t('zqwprovince_x')} <span className="red">*</span></label>
      <select name="province" id="province" defaultValue={b2b.province} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b} 
                      className={b2b.e_province ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwprovince_x')} >
          <option value="AB">Alberta</option>
          <option value="BC">British Columbia</option>
          <option value="MB">Manitoba</option>
          <option value="NB">New Brunswick</option>
          <option value="NL">Newfoundland and Labrador</option>
          <option value="NT">Northwest Territories</option>
          <option value="NS">Nova Scotia</option>
          <option value="NU">Nunavut</option>
          <option value="ON">Ontario</option>
          <option value="PE">Prince Edward Island</option>
          <option value="QC">Quebec</option>
          <option value="SK">Saskatchewan</option>
          <option value="YT">Yukon</option>
        </select>
            {/* <input type="text" name="province" id="province" defaultValue={b2b.province} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_province ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwprovince_x')} />  */}
			{b2b.e_province && (<span className="text-danger">{FIELD_VALIDATION2['province'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="postal_code">{t('zqwpostal_code_x')} <span className="red">*</span></label>
      <InputMask name="postal_code" mask={mask} onChange={this.handleChangeb2b} value={b2b.postal_code}  onBlur={this.handleFocusoutb2b} 
                      className={this.state.e_cell ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwpostal_code_x')} />
            {/* <input type="text" name="postal_code" id="postal_code" defaultValue={b2b.postal_code} onChange={this.handleChangeb2b} onBlur={this.handleFocusoutb2b}
              className={b2b.e_postal_code ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwpostal_code_x')} />  */}
			{b2b.e_postal_code && (<span className="text-danger">{FIELD_VALIDATION2['postal_code'].message}</span>)} 
          </div>

		   

 {/* ////////////////////////////////////////////////////////////////////////////
        b2b            */}

{/* <div className="row monoAcc">
<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t('zqwTNVFtxt4_x')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/ <Typography> 
          </Typography>  /}
          <div className="containerx">{extraInfo}</div>
        </AccordionDetails>
      </Accordion>
      
</div>  */}

<div className="form-group">
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography>{t('zqwTNVFtxt4_x')}</Typography>
    </AccordionSummary>
    <AccordionDetails> 
      <div className="form-control-idem container containerx2">{extraInfo}</div>
    </AccordionDetails>
  </Accordion>   
</div>
 

  {clientMannual===0? 
      <div className="form-group">
        <button type="button" className="btn btn-primary" onClick={this.handleClientEligibility}>{t('zqwCEligibility_x')}</button> &nbsp; 
        
      </div>:''
     }

 

      <div className="row"><div className="neoinline100"><hr/></div></div>
      {this.state.eligibilityChecked===1?
      <div className="neoinline100"> 
        <div className="row">
          <div className="neoinline100"> 
            <label htmlFor="name">{t('zqwTNVFtxt5_x')} <span className="red">*</span></label>
            {/* <Select options={productOptions} onChange={this.handleProductChange}   />  */} 
            <SelectNeo options={productOptions} onChange={this.handleProductChange} value={this.state.productId}  /> 
          </div> 
          {/* <div className="row minP"> 
            <ProductsOnInvoice />
          </div> */}
          <div className="row" style={{margin:'0 auto',}}>
            <div className="minP" style={{marginTop:'15px',}}> 
              <ProductsOnInvoice />
            </div>
          </div>    
        </div>
        {(isEligible!==true)?
          <div className="row">
            <div className="neoinline100"> &nbsp; <br/>  
            <Alert severity="error">
            {t('zqwTNVFtxt6_x')}
              </Alert>
            </div>
          </div>:'' 
        }
        <div className="row"> 
          <div className="neoinline100"> 
          <br/> &nbsp; <br/> 
            <label htmlFor="tbleserv">{t('zqwTNVFtxt7_x')}</label>
            <table name="tbleserv" className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#{t('zqwTNVFtxt8_x')}</th> 
                  <th scope="col">{t('zqwTNVFtxt9_x')}</th>
                  <th scope="col">{t('zqwTNVFtxt10_x')}</th>
                </tr>
              </thead>
              <tbody> 
                {consomptionUsedList}
              </tbody>
          </table>
          </div>
        </div>
      </div>:''
      }


      {(this.state.eligibilityChecked===1 && isEligible===true && selectedProd>0 && isAlreadyNeoClient)?
        <div className="form-group"> 
          <button type="button" name="coupon_validate" className="btn btn-info" onClick={this.payWithB2BOnFile}>{t('zqwTNVFtxt12_x')}</button>
          <br/><span className="orange">{t('zqwTNVFtxt11_x')}</span>              
          <p> &nbsp; </p>
          <p> &nbsp; </p>
        </div>:''
      }    
 
 {this.state.emailDoesNotExist!==100 && clientMannual===0 && this.state.eligibilityChecked===1?
    <div className="row"><div className="neoinline100"><hr/></div></div>:'' }

                {this.state.emailDoesNotExist!==100 && clientMannual===0?
                  <div className="form-group">
                    {/* <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')} Suivant -- Paiement</button> &nbsp;  */}
                     
                    {/* <button type="button" className="btn btn-default" onClick={this.handlePreviousStep}>Étape Antérieure</button> &nbsp;  */}

                    {(this.state.eligibilityChecked===1 && isEligible===true && selectedProd>0)?
                      <button type="button" className="btn btn-primary" onClick={this.handleClientInfoNextStepQ}>{t('zqwTNVFtxt13_x')}</button>:''} 
                      &nbsp; 
                    
                    {/* <a href="#" className="btn btn-warning float-right"  onClick={this.restartOver}>Recommencer Complètement</a>  */}
                    <button type="button" name="leadvente" className="btn btn-primary float-right" onClick={this.handleClientInfoNextStepLead}>{t('zqwTNVFtxt14_x')}</button> &nbsp;

                    {/* {showBtnLead===true?
                    <button type="button" className="btn btn-primary float-right" onClick={this.handleClientInfoNextStepLead}>Céer Lead de Vente</button>:' '  } &nbsp; */}
                  </div>:<div className="form-group">
                       
                    <button type="button" className="btn btn-primary" onClick={this.handleAddToClientB2B}>{t('zqwTNVFtxt15_x')}</button> &nbsp; 
                  </div> 
                }
         
         <div className="form-group">
            <span className="red">*</span> {t('zqwTNVFtxt16_x')}           
         </div>                
      </form>        


       </div>
      {/* <a href="#" className="btn btn-primary"  >Suivant -- Paiement</a> */}
      </div>
    </div>
    );
  }
 

  TabPanel = (props) => {  
    //return (<div className="text-center mt-20 mt-sm-20">Bonjour</div>);
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}> 
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
 

  a11yProps= (index) => {  
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
 
 

  FinishTransactionPopup = (props) => { //() {
    const open = this.state.openTransactionPopup; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common"); 
  
    return (
      <div>
        <Dialog maxWidth={"sm"} open={open} onClose={this.handleCloseTransactionPopup} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
          <DialogContent id="form-dialog-transactionPopup">
          <div className="text-center"> 
            {/* <p>{t("zqwTNVFtxt36_x")}!!!</p>  */}
            <p>{t("zqwTNVFtxt37_x")}</p>  
            {/* <p>{t("zqwTNVFtxt38_x")}</p>   */}
          </div>   
            
          <div className="form-row neoxtbl">
            <div className="form-group col-md-9">
            <br/>
            <input type="button" onClick={this.handleCloseTransactionPopup} className="btn btn-success" value={t("zqwTNVFtxt20_x")} /> &nbsp; &nbsp; 
            </div> 
          </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }


  ListOfExistedPossibleClient = (event) => {  
    const open = this.state.openPossibleListOfClient; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");
    const listClient=this.state.possibleListOfClient;
    const listClient2=this.state.possibleListOfClientmini;
    const zendesk=this.clientOnZendeskSCR;
  
    return (
      <div>
        <Dialog maxWidth={"sm"} open={open} onClose={this.handleCloseListOfClient} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirmclient">
          <DialogContent id="form-dialog-confirmclient">
          <div className="text-center">
           
          </div>   
          <div className="text-center"><h2>{t("zqwTNVFtxt41_x")}</h2></div> 
            <p>{t("zqwTNVFtxt42_x")} {this.state.email} </p> 
            {/* <p> &nbsp; </p> */}
            <div className="form-row neoxtbl">
              {zendesk===0?
            <table  style={{minWidth:'300px'}}>
            <thead><tr>
                {/* <th colSpan="4">Prénom</th>  */}
                <th>{t("zqwTSumtxt15_x")}</th> 
                <th>{t("zqwTSumtxt14_x")}</th> 
                <th>{t("zqwTSumtxt16_x")}</th> 
                <th>&nbsp; &nbsp;</th> 
                {/* <th> &nbsp; &nbsp;  </th>  */}
              </tr></thead>
              <tbody>
              {listClient}
               
              </tbody>
            </table>: <div className="form-group"> {listClient2}</div>
             }


              <div className="form-group col-md-9">
              <br/>
              <input type="button" onClick={this.handleCloseListOfClient} className="btn btn-default" value={t("zqwTNVFtxt20_x")} /> &nbsp; &nbsp;

              </div> 
            </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }

  ProductsOnInvoice = (event) => {
    const { t } = this.props;
    const taxInfo=this.state.taxInfo;
    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    //const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    }  
    const TAX_TOTAL=TAX1+TAX2; //0.14975;         
    const arrProd=[6,8,9]; 
    let productOptions = [];
    let productList = this.productsList.products.length > 0
    	&& this.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        if(item.type.indexOf('hidden') <0 && item.titre!==''){
          productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
        }
      return (
        null
      );
    }, this);
 
    //const ProductExtraOptionCtrl=this.state.productExtraOptionCtrl;  
    const ProductExtraOptionCtrl2=this.state.productExtraOptionCtrl2;
    const grandTotal=this.state.grandTotal;
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0,
    const orderBruteSubTotal=this.state.orderBruteSubTotal; 
    const orderTVQ=this.state.orderTVQ; //shows at the end
    const orderTPS=this.state.orderTPS;  //shows at the end

    ///////////////////////////////////////////////////////////////////////////////////////
    let productCart=this.state.productCart;
    //const taxInfo=this.state.taxInfo;

    //console.log("OUR-BASKET", productCart);
        
    let subTotal=0;  let totTagTotOptions=0; let totProd=0;
    const listOfCurrentProducts=productCart.length > 0
    	&& productCart.map((itemState, i) => { 
        const isDelete=itemState.isdelete;
        const myItemtxt=""+itemState.id;
        const index=i; 
        //console.log("OUR-BASKET-i-"+i, itemState);
        if(myItemtxt.indexOf("98989") <0 && isDelete===undefined){ 
          
        const item= this.getProductChange(itemState.id); 
        //console.log("OUR-BASKET-PRODINFO", item);
        const optionsEntries = (item.options!=undefined && !arrProd.includes(item.id)) ? Object.entries(item.options): [];
        const cartEntries = Object.entries(item.cart);

        const this00=this;

        let optionsProd=productCart[index].options; 
        let selPage=productCart[index].pages;
        let perPage=productCart[index].perPage;
        if(selPage<=1){  selPage=1; } 

        //totTagTotOptions=((selPage-1)*perPage);  
        totTagTotOptions=productCart[index].totOptions;

        // let tarif = (''+item.tarif).replace(/\W\$/g, ''); 
        // tarif=tarif.replace('$', '');
        const productPrice= itemState.productPrice2===0?itemState.productPrice:itemState.productPrice2;
        //let tarif = productPrice;
        //console.log("weeeeeeeeeeeeeeeeeeooooy ", productPrice, totTagTotOptions, "optionsProd", optionsProd); 
        let tarif =((productPrice*1)+(totTagTotOptions));
        let tarifPrix =productPrice; 
        //let selPage= event.target.value; 
        const productPriceTaxOnly= (((tarif*1)*(TAX_TOTAL))).toFixed(2);
        //const productPriceTotal= (((tarif*1)*0.15)+(tarif*1)).toFixed(2); //(((tarif*1)+(productPriceTaxOnly)));
        const productPriceTotal= ((((tarif*1)*TAX1)+((tarif*1)*TAX2))+(tarif*1)).toFixed(2); 
        const orderTVQ=((tarif*1)*TAX1).toFixed(2);
        const orderTPS=((tarif*1)*TAX2).toFixed(2); 
        //const orderServices=0;
        const orderServices=totTagTotOptions;  
        //const orderSubtotals=((tarif*1)+orderServices).toFixed(2);
        const orderSubtotals=((tarif*1)).toFixed(2);

        // let productCart=this.state.productCart;
         const indexProd=index; //productCart.length;
        const apartir= item.apartir; let apartirUsed=false;
        //const optionsProd=item.options;

        //optionsProd.perPage} data-maximum={optionsProd.maximum
        //optionsProd.push({id: prodOptId, mnt:amountOpt}); 
        //const groupVal=''+indexProd+'-'+item.id+'-'+txtLabel+'-'+amount;
        //const index=arVal[0];  const prodId=arVal[1];  const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
       //console.log("MMMMMMMMMMMMMM", itemState.options, " vs ", optionsProd); 
       let  optionsEntriesQty= optionsEntries.length;

        const productExtraOptionCtrl2=optionsEntries.map(function(elem, index) { 
          const [txtLabel, amount] =elem;
          //const this0=this;
          //const groupVal=''+indexProd+'-'+item.id+'-'+amount;
          const groupVal=''+indexProd+'-'+item.id+'-'+txtLabel+'-'+amount;
          //console.log(`##arraymap There are ${elem} ---- ${index} +++++++++++++ ${txtLabel} -- ${amount} `);
          let titre= '';  
          for (const [txtLabel2, labelValue] of cartEntries) { 
            if(txtLabel===txtLabel2){ 
              titre= labelValue;
              //console.log(`##zazThere are ${txtLabel2} //////VS ${labelValue}  `);
              break;
            } 
          }
          if(titre==='' && apartir===false){
            return (<span key={index} />);
          }if(apartir===true){
            //apartirUsed=true;
            if(apartirUsed===false){
              apartirUsed=true;
              return (<p key={index}>{t("zqwNVFrmEl81_x")}:<br/>
                <input type="number" min="1" step="1" defaultValue={selPage} 
              data-value={groupVal} data-perpage={optionsProd.perPage} data-maximum={optionsProd.maximum} 
              onChange={(eventx) => this00.setPageDocForProduct(eventx)}  /> {t("zqwNVFrmEl82_x")} (n x ${amount})</p>);
            }else{ return (<span key={index} />); }
          }else{
            //console.log(`##INDEEEEEEEEEEEEEEEEEX SET IS ${groupVal} VS ${txtLabel} - ${optionsProd.id} `);
            let isSelected=false; const txtLabelx=txtLabel; const txtLabelzx=optionsProd.id;
            //let indz=-9;
            const indz=this00.indexOfElement(optionsProd, txtLabel, amount);
            //console.log('REACT ] indzindzindzindzindzindz  '+indz);
            if(indz >-1){ 
              isSelected=true; 
            } 

            //return (<p key={index} ><input   type="checkbox" value={groupVal} defaultChecked={isSelected}  onChange={(eventx) => this00.setExtraServiceForProduct(eventx)}  /> {titre} (${amount})</p>);  
            return (<p key={index} ><input   type="checkbox" value={groupVal} defaultChecked={isSelected}  onChange={(eventx) => this00.setExtraServiceForProduct(eventx)}  /> {titre} </p>);
          }
        });
            
        //const rId=(Math.floor(Math.random()*16));
        const rId=itemState.randcolor;

        if(optionsEntriesQty===0){
          //return (null);
          return (
            <div key={myItemtxt} className="marginr md-4">
          <div className="card card-default neocardx3">
            <div className={`card-header neocard${rId}`}>
              <h5 className="m-0">{item.titre}</h5>
            </div>
            </div></div>
          );
        }

        
        //<div className="card card-primary card-outline">  <div key={indexProd} className="marginr md-4">
        const divBlock=(
          <div key={myItemtxt} className="marginr md-4">
          <div className="card card-default neocardx2">
            <div className={`card-header neocard${rId}`}>
              <h5 className="m-0">{item.titre}</h5>
            </div>
            <div className="card-body">
 
              <div className="row">
                <div className="col-md-10">
                  <div className="row" style={{display:"none"}}>
                    <div className="neoinline50"><span>{t("zqwNVFrmEl83_x")}</span></div>
                    <div className="neoinline50l" key={"iz"+indexProd}>    
                <input type="number" step="0.1" min="1" name="neoPrice" defaultValue={tarifPrix}  
                data-value={indexProd+'-'+item.id}    
              onChange={(eventx) => this00.setNewPriceForProduct(eventx)} className="form-control col-md-8x" size="30" style={{display:'inline', width:'100px'}} /> 
                    <span></span> 
                    </div>
                  </div>
                  <div className="row" style={{display:"none"}}> 
                    <div className="neoinline50 text-right"><span>{t("zqwNVFrmEl84_x")}</span></div>
                    <div className="neoinline50l"> <span>{orderServices}</span>$</div> 
                  </div>

{/* 
                  <div className="row" style={{display:"block"}}> 
                    <div className="neoinline50"><span>{t("zqwNVFrmEl57_x")}</span></div>
                    <div className="neoinline50l"> <span>{orderSubtotals}</span>$</div>
                  </div>
                  
                  {TAX1_NAME!=="0"?
                    <div className="row" id="tax1_block" style={{display:"block"}}> 
                    <div className="neoinline50 text-right"><span>{TAX1_NAME!=="0"?TAX1_NAME:''}</span> 
                    (<span>{TAX1lx}</span>%)</div>
                    <div className="neoinline50l"> <span>{orderTVQ}</span>$</div> 
                  </div>:'' }

                   {TAX2_NAME!=="0"?
                    <div className="row" id="tax2_block" style={{display:"block"}}> 
                    <div className="neoinline50"><span>{TAX2_NAME!=="0"?TAX2_NAME:''}</span> (<span>{TAX2lx}</span>%)</div>
                    <div className="neoinline50l"> <span>{orderTPS}</span>$</div>
                  </div>:'' }

                  <div className="row" id="tax2_block" style={{display:"block"}}> 
                    <div className="neoinline50"><span>{t("zqwNVFrmEl85_x")}</span></div>
                    <div className="neoinline50l"> <b><span>{productPriceTaxOnly}</span></b>$</div>
                  </div>
                  <div className="row"><div className="neoinline30"> </div><div className="neoinline50"><hr/></div></div>
                  <div className="row" id="tax2_block" style={{display:"block"}}> 
                    <div className="neoinline50"><span>{t("zqwNVFrmEl61_x")}</span></div>
                    <div className="neoinline50l"> <span>{productPriceTotal}</span>$</div>
                  </div> */}


                </div>
              </div>


              <br/>
              {optionsEntries.length > 0 && cartEntries.length > 0? <>{t("zqwNVFrmEl86_x")}: <hr/></>:''}
              
              {productExtraOptionCtrl2}
              {/* <br/>
              <div className="ovalItemPrice">  
              <span>{productPriceTotal}</span>$  
              </div>  */}
              <p style={{display:"none"}}>
                <input type="checkbox" value={indexProd+'-'+item.id} defaultChecked  
              onChange={(eventx) => this00.setTicketForProduct(eventx)}  /> {t("zqwNVFrmEl87_x")}</p>
             
              {/* <a href="#" className="btn btn-danger" data-prodindex={indexProd} onClick={this.handleRemoveProductItem} >{t("zqwNVFrmEl88_x")}</a> */}
            </div>
          </div>
        </div> 
        ); 
 
         return divBlock;
        }

        return null;

    }, this);

    return (<div className="row">
    {listOfCurrentProducts}
  </div>);

    return (
      <>
      {/* <div className="form-group">
          <label htmlFor="name">{t('zqwNVFrmEl41_x')}</label>
          <Select options={productOptions} onChange={this.handleProductChange2} />            
      </div> */}
      <div className="card card-primary card-outline">
        <div className="card-header">
          <h5 className="m-0">{t("zqwNVFrmEl89_x")}</h5>
        </div>
        <div className="card-body">
          {/* <div className="form-group">
              <label htmlFor="name">{t('zqwname_x')} du Produit</label>
              <Select options={productOptions} onChange={this.handleProductChange} />            
          </div>  */}

          {/* <div className="row"> 
              <div className="form-group"><div className="input-group"> 
              <label htmlFor="province">{t("zqwNVFrmEl90_x")} {t('zqwprovince_x')} *</label> &nbsp;  
                    <select name="province" id="province" value={this.state.province} onChange={this.handleChangeProvince} onBlur={this.handleFocusout}
                      className={this.state.e_province ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwprovince_x')} >
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                      </select> 
                      </div></div>
          </div> */}

 
        <div className="row">
          {listOfCurrentProducts}
        </div>

         
        <div className="card-text"> 
         {/* <h5 className="card-title">Total <b>{grandTotal}</b></h5><br/> */}
         {/* <div class="row" style="display: block;"> */}
         <div className="neoinline40">
          <div className="row" id="tax2_blockqqqqq2">
            <div className="neoinline50"><span>{t("zqwNVFrmEl57_x")}</span></div>
            <div className="neoinline50l"> <span>{orderBruteSubTotal}</span>$</div>
          </div>
           
        {TAX1_NAME!=="0"?
          <div className="row" id="tax1_blockqqqq">
            <div className="neoinline50"><span>{TAX1_NAME!=="0"?TAX1_NAME:''}</span> (<span>{TAX1lx}</span>%)</div>
            <div className="neoinline50l"> <span>{orderTVQ}</span>$</div>
          </div>:'' } 

 
          {TAX2_NAME!=="0"?
            <div className="row" id="tax2_blockqqqqq">
            <div className="neoinline50"><span>{TAX2_NAME!=="0"?TAX2_NAME:''}</span> (<span>{TAX2lx}</span>%)</div>
            <div className="neoinline50l"> <span>{orderTPS}</span>$</div>
          </div>:''} 

 
          <div className="row" id="tax2_blockqqqqq3">
            <div className="neoinline50"><span>{t("zqwNVFrmEl61_x")}</span></div>
            <div className="neoinline50l"> <span><b>{grandTotal}</b></span>$</div>
            <div className="ovalTotalPrice"> &nbsp; <span>{t("zqwNVFrmEl91_x")}</span> <span><b>{grandTotal}</b></span>$ &nbsp; </div>
            
          </div>
 

         </div>
          
        </div>
         {/* <button type="button" className="btn btn-default" onClick={this.handlePreviousStep}>{t("zqwNVFrmEl92_x")}</button> &nbsp; 
        <a href="#" className="btn btn-primary"  onClick={this.handleProductNextStep} >{t("zqwNVFrmEl93_x")}</a>
        <a href="#" className="btn btn-warning float-right"  onClick={this.restartOver}>{t("zqwNVFrmEl94_x")}</a> */}
        </div>
      </div></>
    );
  }

 
}

//const TransNeoVentesForm = withTranslation()(NeoVentesForm); 
const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes });

const mapDispatchToProps = { addOrUpdateNeoVentes, getNeoVentes, getClientSearch,getB2BClientSearch,
   checkClientEligibility, getB2BContractListDetails, getClientAddress, saveB2BClient, 
  getCodePromo, getNeoVentesPromos, getProductInfo, getPartialInvoicePayments, getAllProducts, 
  getChargeDeskStatus, getClientTransactionsFullInfo, sendTicketLead };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoVentesForm));
