import { RECEIVE_NEOSALESPLANNINGWEEKLY, ADD_NEOSALESPLANNINGWEEKLY, REMOVE_NEOSALESPLANNINGWEEKLY, REPLACE_NEOSALESPLANNINGWEEKLY } from '../actions/NeoSalesPlanningWeeklyAction';

const initialState = { NeoSalesPlanningWeekly: [] }
export default function NeoSalesPlanningWeeklyReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NEOSALESPLANNINGWEEKLY:
      return action.NeoSalesPlanningWeekly;
    case ADD_NEOSALESPLANNINGWEEKLY:
      return [action.payload, ...state];
    case REMOVE_NEOSALESPLANNINGWEEKLY:
      return state.filter(NeoSalesPlanningWeekly => NeoSalesPlanningWeekly.id !== action.payload.id);
    case REPLACE_NEOSALESPLANNINGWEEKLY:
      return state.map((NeoSalesPlanningWeekly) => {
        if (NeoSalesPlanningWeekly.id === action.payload.id) {
          return {
            ...NeoSalesPlanningWeekly,
week: action.payload.week,
week_start: action.payload.week_start,
week_end: action.payload.week_end,
expected_quantity: action.payload.expected_quantity,
expected_amount: action.payload.expected_amount,
invoice_quantity: action.payload.invoice_quantity,
invoice_amount: action.payload.invoice_amount,
payment_quantity: action.payload.payment_quantity,
payment_amount: action.payload.payment_amount,
creation_date: action.payload.creation_date,
last_update: action.payload.last_update,
user_creation: action.payload.user_creation,
user_update: action.payload.user_update,
          }
        } else return NeoSalesPlanningWeekly;
      })
    default:
      return state;
  }
}
