import React from 'react';
//import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

 
 
export default  function Toastr(props) {
	const classes = useStyles();
    //const [open, setOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  //const [vertical, setVertical] = React.useState("");
  //const [horizontal, setHorizontal] = React.useState("");
  const vertical='top';
  const horizontal= 'center';
	const duration=6000; 
	let toastType="success";
	//setOpen(true);
	if(props.messagex=="" || props.messagex==undefined){ return (<span/>); }
	//toastType
	if(props.toastType!=="" || props.toastType!==undefined){ toastType=props.toastType; }
	//return ToastrMaster(message, duration, toastType);
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}

		setOpen(false);
	};
	
	//if(message!=="" && message!==null){ setOpen(true); }
	if(props.open===true || props.open!==undefined && (open===false || open===null)){ 
		//setOpen(true);  
		setTimeout(() => {
		  //console.log('waaaaaaaaaaaaaaaaaait to set state and lunch toaster! '+open);
		  setOpen(true);  
		}, 500);
	}
  
  if(props.anchorOrigin!==undefined && props.anchorOrigin!==""){ 
    return (
      <div className={classes.root}> 
        <Snackbar open={open} autoHideDuration={duration} 
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}>
          <Alert onClose={handleClose} severity={toastType}>
            {props.messagex}
          </Alert>
        </Snackbar> 
      </div>
    );  
  } 

  return (
    <div className={classes.root}> 
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toastType}>
          {props.messagex}
        </Alert>
      </Snackbar> 
    </div>
  );  
}

function ToastrMaster(message, duration, toastType) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  setOpen(true);	
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}> 
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toastType}>
          {message}
        </Alert>
      </Snackbar> 
    </div>
  );  
}
 