import { RECEIVE_NEOFOLDERS, ADD_NEOFOLDERS, REMOVE_NEOFOLDERS, REPLACE_NEOFOLDERS } from '../actions/NeoFoldersAction';

const initialState = { NeoFolders: [] }
export default function NeoFoldersReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NEOFOLDERS:
      return action.NeoFolders;
    case ADD_NEOFOLDERS:
      return [action.payload, ...state];
    case REMOVE_NEOFOLDERS:
      return state.filter(NeoFolders => NeoFolders.id !== action.payload.id);
    case REPLACE_NEOFOLDERS:
      return state.map((NeoFolders) => {
        if (NeoFolders.id === action.payload.id) {
          return {
            ...NeoFolders,
id_clients: action.payload.id_clients,
name: action.payload.name,
active: action.payload.active,
created: action.payload.created,
ticket_id: action.payload.ticket_id,
          }
        } else return NeoFolders;
      })
    default:
      return state;
  }
}
