export class CommonArrays  {   
  
  getFieldRecord = (myArr, fieldId) => {
	let fieldBlock=null; 
	let promList = myArr.length > 0
	  && myArr.map((item, i) => {  
	  //let fieldBlock=null;
	  if(item.id===fieldId){
		fieldBlock=item; 
	  }
	   
	return (
	  null
	);
  }, this);
  
  return fieldBlock;
}

getProductRecord = (myArr, fieldId) => {
  let fieldBlock=null; 
  let promList = myArr.length > 0
	  && myArr.map((item, i) => {  
	  //let fieldBlock=null;
	  if(item.product_type_id===fieldId){
		  fieldBlock=item; 
	  }
	  
	  return (
	  null
	  );
  }, this);
  
  return fieldBlock;
}

   
  getTicketProductRecord = (myArr, fieldId) => {
	  let fieldBlock=null; 
	  let promList = myArr.length > 0
		  && myArr.map((item, i) => {  
		  if(item.product_id===fieldId){
			  fieldBlock=item; 
		  }
		  
		  return (
		  null
		  );
	  }, this);
	  
	  return fieldBlock;
  }
  


getFieldTypeId = (fieldType) => {
	const myArr=['text','number','radio','checkbox','select','textarea','date','datetime','time','selectreact','inputmask', 'selectmultiple', 'file','email','selectreactmany','selectreactmanystack','blockfield'];
	let fieldBlock=-3; 
	let promList = myArr.length > 0
	  && myArr.map((item, i) => {  
	  //let fieldBlock=null;
	  if(item===fieldType){
		fieldBlock=i; 
	  }
	   
	return (
	  null
	);
  }, this);
  
  return fieldBlock;
}

getFieldOptionsRecords = (myArr, fieldId) => {
  let fieldBlock=null; 
	////console.log("mmmmQQmmmmm7777QQQQQQQQQQQ222", fieldId);
  for (let keyVal in myArr) {
	  //if (key === 'length' || !widthRange.hasOwnProperty(key)) continue;
	  //var value = widthRange[key];
	  //const objKey=Object.keys(item); 
	  ////console.log("mmmmmmmmm7777QQQQQQQQQQQ222", fieldId,  keyVal, myArr[keyVal]);
	  if(keyVal===fieldId){ //if(myArr[keyVal]===item.field_id){
		  ////console.log("goooooooooooo222", fieldId,  keyVal);
		  //fieldOptionsx=this.getFieldOptionsRecords(fieldOptions, item.field_id);
		  fieldBlock=myArr[fieldId]; 
		  break;
	  }
  }

  return fieldBlock;
}

  getTicketDataRecord = (myArr, fieldId) => {
	  let fieldBlock=null; 
	  let promList = myArr.length > 0
	  && myArr.map((item, i) => {  
		  //let fieldBlock=null;
		  if(item.product_field_id===fieldId){
			  fieldBlock=item; 
		  }
		  
		  return (
		  null
		  );
	  }, this);
  
	  return fieldBlock;
  }

  getFieldRecordx = (myArr, fieldId) => {
	let fieldBlock=null; 
	let promList = myArr.length > 0
	  && myArr.map((item, i) => {  
	  //let fieldBlock=null;
	  let zendesk_id=item.zendesk_id;
	  if(zendesk_id===undefined || zendesk_id===null){
		zendesk_id=""; 
	  }else{ zendesk_id=zendesk_id.replace("=>", ":");  }
	  if(item.id===fieldId || (zendesk_id!==undefined && zendesk_id===fieldId)){
		fieldBlock=item; 
	  }
	   
	return (
	  null
	);
  }, this);
  
  return fieldBlock;
}
  
 
}

//const mapDispatchToProps = { addArticle };                     

//export default connect(null, mapDispatchToProps)(ArticleAdd);