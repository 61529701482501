export const taxesList = [
	{
		"id" : 1,
		"name" : "Alberta",
		"iso" : "AB",
		"tax1" : 0.000,
		"tax2" : 5,
		"tax1x" : 0.000,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "0",
		"tax2_name" : "GST",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 2,
		"name" : "British Columbia",
		"iso" : "BC",
		"tax1" : 7.000,
		"tax2" : 5,
		"tax1x" : 0.07,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "PST",
		"tax2_name" : "GST",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 3,
		"name" : "Manitoba",
		"iso" : "MB",
		"tax1" : 7.000,
		"tax2" : 5,
		"tax1x" : 0.07,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "PST",
		"tax2_name" : "GST",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 4,
		"name" : "New Brunswick",
		"iso" : "NB",
		"tax1" : 10.000,
		"tax2" : 5,
		"tax1x" : 0.1,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "HST",
		"tax2_name" : "0",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 5,
		"name" : "Newfoundland and Labrador",
		"iso" : "NL",
		"tax1" : 10.000,
		"tax2" : 5,
		"tax1x" : 0.1,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "HST",
		"tax2_name" : "0",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 6,
		"name" : "Northwest Territories",
		"iso" : "NT",
		"tax1" : 0.000,
		"tax2" : 5,
		"tax1x" : 0.000,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "0",
		"tax2_name" : "GST",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 7,
		"name" : "Nova Scotia",
		"iso" : "NS",
		"tax1" : 10.000,
		"tax2" : 5,
		"tax1x" : 0.1,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "HST",
		"tax2_name" : "0",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 8,
		"name" : "Nunavut",
		"iso" : "NU",
		"tax1" : 0.000,
		"tax2" : 5,
		"tax1x" : 0.000,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "0",
		"tax2_name" : "GST",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 9,
		"name" : "Ontario",
		"iso" : "ON",
		"tax1" : 8.000,
		"tax2" : 5,
		"tax1x" : 0.08,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "HST",
		"tax2_name" : "0",
		"site" : "https:/on.neolegal.ca"
	},
	{
		"id" : 10,
		"name" : "Prince Edward Island",
		"iso" : "PE",
		"tax1" : 10.000,
		"tax2" : 5,
		"tax1x" : 0.1,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "HST",
		"tax2_name" : "0",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 11,
		"name" : "Quebec",
		"iso" : "QC",
		"tax1" : 9.975,
		"tax2" : 5,
		"tax1x" : 0.09975,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "TVQ",
		"tax2_name" : "TPS",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 12,
		"name" : "Saskatchewan",
		"iso" : "SK",
		"tax1" : 6.000,
		"tax2" : 5,
		"tax1x" : 0.06,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "PST",
		"tax2_name" : "GST",
		"site" : "https:/www.neolegal.ca"
	},
	{
		"id" : 13,
		"name" : "Yukon",
		"iso" : "YT",
		"tax1" : 0.000,
		"tax2" : 5,
		"tax1x" : 0.000,
		"tax2x" : 0.05,
		"created" : "2017-04-12 10:17:22",
		"tax1_name" : "0",
		"tax2_name" : "GST",
		"site" : "https:/www.neolegal.ca"
	}
];


export default function getProvinceTaxInfo(isoprovince) { 
     for(let tax of taxesList){
		 if(tax["iso"]===isoprovince){
			 return tax;
		 }
	 }

	 isoprovince="QC"; //if none return default
	 for(let tax of taxesList){
		if(tax["iso"]===isoprovince){
			return tax;
		}
	}
	 
	return null;
};
