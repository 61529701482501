import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoVentes, addNeoVentesSC, addOrUpdateRefunds, getNeoVentes, getClientSearch, getB2BClientSearch, saveB2BClient, checkClientEligibility, getB2BContractListDetails, getClientAddress, getCodePromo, getNeoVentesPromos, getProductInfo, getPartialInvoicePayments, getAllProducts, getChargeDeskStatus, getClientTransactionsFullInfo } from '../../actions/NeoVentesAction'; 
//import { addOrUpdateNeoProducts, getNeoProducts, getNeoProductsAll, getNeoProductsGoto, deleteNeoProducts } from '../../actions/NeoProductsAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
//import CustomToolbarSelect from "../CustomToolbarSelect";
//import  getProvinceTaxInfo from "../../appTaxes"; 


import Select from 'react-select';
import Autocomplete from '@material-ui/lab/Autocomplete';    
import InputMask from "react-input-mask";
import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormGroup from '@material-ui/core/FormGroup'; 
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Toastr from "../../messages";
import  Loader from "../../loader";
import MuiAlert from '@material-ui/lab/Alert';

import SaveIcon from '@material-ui/icons/Save';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import HelpIcon from '@material-ui/icons/Help';
import EmailIcon from '@material-ui/icons/Email';
//import SaveSharpIcon from '@material-ui/icons/SaveSharp';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 

import ZAFClient from 'zendesk_app_framework_sdk';
import { RotateRightSharp } from '@material-ui/icons';
import StripePayment from '../../StripePayment'; 
import moment from 'moment';
import  getProvinceTaxInfo from "../../appTaxes"; 

/////////////////////////////
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';  
import Grid from '@material-ui/core/Grid'; 
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete'; 
import DownloadIcon from '@material-ui/icons/CloudDownload';
//import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import SpellcheckIcon from '@material-ui/icons/Edit';
//import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails'; 
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import  { dateFormat } from "../../appDateFormat";
import history from '../../history'; 


class NeoRefundPayment extends Component {
  
  constructor(props) {
    super(props);
    this.paymentObj=null;
    this.invoiceObj=null;
    this.stateInit = {
			id:0,
			name:'', e_name: false,
			description:'', e_description: false,
			amount:'0', e_amount: false,
			
			productCart:  [], 
			productId:"", productTitre:'', product:{}, productPrice:'', productPriceTax:'', productPriceTaxOnly:0, productExtraOptions:[], 
			productExtraOptionCtrl:null, productExtraOptionCtrl2:[], showProductOption:false, 
			prodTagPrice:  [], prodTagPriceTax:  [], prodTagPriceTaxOnly:  [], prodTagTotOptions:  [], grandTotal: 0, 
		
			idClient:'0',
			firstname:'', e_firstname: false,
			lastname:'', e_lastname: false,
			cell:'', e_cell: false,
			terms:'', e_terms: false,
			active:'', e_active: false,
			created:'', e_created: false,
			email:'', e_email: false,
			id_users:'0', e_id_users: false,
			language:'fr', e_language: false,
			push:'', e_push: false,
			id_zendesk:'', e_id_zendesk: false,
			sms:1, e_sms: false,
			stripe_id:'', e_stripe_id: false,
		
		
			idAddress:0, 
			address:'', e_address: false,
			unitsuite:'', e_unitsuite: false,
			city:'', e_city: false,
			province:'QC', e_province: false, province2:'', 
			cp:'', e_cp: false,
			country:'Canada', e_country: false,
			company:'', e_company: false, 
			existedClientRO: false, openPossibleZipCode:false, listAddressOnCode: null, listZipCodeAddresses:[], countryTuner:'',  
			listPromotionCodes:[], codepromoOptions:[], openInfoStepsPopup:false, infoStepsPopupno:0,
			infoProduct:{data:[]}, infoProduct_title:"", infoProduct_excerpt:"", partialCanClose:0,
			openTransactionPopup: false, twoStepSale:"",
		
			 
			listOfSuggestion:[],
			valueClientSearch:'', 
			inputValueClientSearch:'',
			selectedIdSuggestion:'',
	  
			listOfSuggestion2:[],
			valueClientSearch2:'', 
			inputValueClientSearch2:'',
			selectedIdSuggestion2:'',
	  
			clientInfo:{ }, 
			clientAddress:{ },
			globalStepWorkflow: 1, // 1 init 2:start with product 3:start with customerInfo 4:payment 5: case close 
			workflowType: 1, //1 begin, 2 with clientInfo, 3 with Prod
	  
			stripePaymentOpen: false, isLoaderOpen:false, 
			toastrMessage: '', toastrType: '', toastrDuration: 6000,
			cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
			orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
		
			stripeToken:'',
			dossier:'', //421
			isRecurrentPayment: 0,  nowPayment: 0, qtyPayment: 1, laterpayment:4, fromDatePayment:'', 
			paymentRound:0, paymentWay: 'full', nowPaymentRO: true, recurPaymentAmount: 0,
			paymentsReceived: [0], totalPaymentReceived: 0, paymentWayRO: false, beforePaymentTotal:0, 
			id_invoice: 0, pMethod:1, status_invoice:'', 
			showProdMulti:0, productsList:{"products": []}, openPListAsOnTheSite: false,
			errorTransacMessage: "", emailDoesNotExist: 0, openPossibleListOfClient: false,
			listClientOnCode:[], possibleListOfClient:[],
			statusChargeDesk: 0, chargeDeskIdStr:"",
			openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}, possibleListOfCDStatusInfo:[],  
			taxInfo:{}, isupsale:false, upsaleTicket:"", isAmerindian:false, no_amerindien:"", 
			listOfTickets:[], goProd:0, 
			newTicket:false, currentTicket:'', disabledBtn:false, 


      refundtype:'', observation:'', clientspecimen:null, 
      refundList:[],  refunds:[], refundValList:[], transactionRefundList:[], transactiontype:"0",  
      currentRefundValue:0, refundedValue:0, transRefBreakdownKeyList:[], 
      currentRefundInvValue:0, refundedInvValue:0, refundedInvId:0, refundedInvPayId:0, 
      refundedInvPayVal:0, refundedInvPayVal2:0, refundedInvValue2:0, 
      retmaxwithtax: 0, retpaymentamount: 0, retrefunded: 0,  amountretention:0, 
      clientTransInfo:{}, errorMessage:"", 
      nontaxableamount:0, creditwithtaxes:"credittaxes",
      montant_tax1:0, montant_tax2:0, provincetax:"", 

        
		};
	  
			this.state = JSON.parse(JSON.stringify(this.stateInit)); // Object.assign(this.stateInit);

      this.taxableAmountRef=React.createRef();

  }

  productsList={"products": []}; // this.state.productsList; // productList();

  componentDidMount() {                                                         
    //this.props.getNeoPayments(this.props.match.params.id);
    /*const idx = this.props.match.params.id;  
    Promise.resolve(this.props.getNeoPayments(idx))
    .then((response)=>{
      //console.log("0000********INI**********"); console.log(response.NeoPaymentsOne);  console.log("0000********FIN**********");
      this.setState(response.NeoPaymentsOne); 
      return response;
    });*/

    // const { t } = this.props;
		// const taxInfo=getProvinceTaxInfo("QC");
		// this.setState({ ["taxInfo"]: taxInfo,"language":"" });  
		this.setState({ ["productCart"]: [],"productId":"",	 });  
    this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
   this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
     this.setState({ ["nowPayment"]:  0 });


   //this.props.getNeoProductsAll();
   //this.productsList=this.props.
   const clientProdTransInfo=this.props.clientProdTransInfo; 
   const client=clientProdTransInfo.client; 
   //const transactions=clientProdTransInfo.transactions;   

   //const Alert=this.Alert;
   //const clientProdTransInfo=
   let stripeId="";
   console.log("woooooooooooooo ", client);
   if(client!==undefined && client!==null){  
     this.setState(client);  
     this.setState({["clientInfo"]: client, ["idClient"]: parseInt(client.id)});  
   }

  //  if(this.props.productsList.products.length > 0){
  //    this.productsList=this.props.productsList;
  //  }
   if(this.props.productsList.products.length > 0){
     this.productsList=this.props.productsList;
   }
   if(this.props.currentTicket!==undefined && this.props.currentTicket!==null && this.props.currentTicket!==""){ 
     this.setState({ ["currentTicket"]: this.props.currentTicket});  
   }

    
   if(this.props.clientAddress!==undefined && this.props.clientAddress!==null){ 
     const taxInfo=getProvinceTaxInfo(this.props.clientAddress.province); //QC
     this.setState({ ["clientAddress"]: this.props.clientAddress, 
     ["province"]: this.props.clientAddress.province, ["taxInfo"]: taxInfo, });  
   }


    const invoices=this.props.invoices;
    const payments=this.props.payments;
    const refunds=this.props.refunds;
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;

    console.log("woooooooooooooo paymentId=", paymentId, "refunds", refunds);

    if(refunds!==undefined && refunds.length > 0){
      this.setState({ ["refunds"]: refunds});  
    }

    const paymentRefundList=[];  const refundValList=[]; 
    let refundedInvValue=0; let refundedInvPayVal=0;
    let refundedInvValue2=0;  let refundedInvPayVal2=0;
    //getXRecordObjects(myArr, fieldName, fieldValue, oneObject) 
    //getXRecordObjects(myArr, fieldName, fieldValue, oneObject)
    if(payments!==undefined && payments.length > 0){ 
      const paymentsx=this.getXRecordObjects(payments, "id_invoices", [invoiceId], false);
      const paymentIds=this.array_column(paymentsx, "id", null);
      const refundsx=this.getXRecordObjects(refunds, "id_payments", paymentIds, false);
      //const zzzzz=this.getXRecordObjects(myArr, fieldName, fieldValue, oneObject);
      for(const paymentx of paymentsx){   //for(const paymentx of payments){  
        console.log("OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
      }  
    }
    
    

    if(payments!==undefined && payments.length > 0){ 
      const refundList=[];
      const paymentsx=this.getXRecordObjects(payments, "id_invoices", [invoiceId], false);
      const paymentIds=this.array_column(paymentsx, "id", null);
      const refundsx=this.getXRecordObjects(refunds, "id_payments", paymentIds, false);
      //const zzzzz=this.getXRecordObjects(myArr, fieldName, fieldValue, oneObject);
      for(const paymentx of paymentsx){   //for(const paymentx of payments){  
        console.log("0000...OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
        let refundedAmount=0;  let refundedAmount2=0;
        if(refunds!==undefined && refunds.length > 0){
          for(const refund of refunds){   
            console.log("0000...OOOOOOOOOOOOOOOOX==REFUUUUUUUND ", refundedAmount, refund);
            if(refund.id_payments===paymentx.id && refund.status==="3"){
              //refundedAmount +=parseFloat(refund.amount);
              refundedAmount2 +=parseFloat(refund.amount);
              refundedAmount +=parseFloat(refund.total);
            } 
            console.log("0000...OOOOOOOOOOOOOOOOX==REFUUUUUUUND .... ", refundedAmount);
          }
        }

        // const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount, refundedAmount:refundedAmount }; 
        // refundList.push(baseElem);  
        // refundValList.push(refundedAmount); 
        if(paymentId===paymentx.id){
          const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount, refundedAmount:refundedAmount, nontaxableamount:0 }; 
          refundList.push(baseElem);  
          refundValList.push(refundedAmount); 

          //currentRefundInvValue:0, refundedInvValue:0, refundedInvId:0, refundedInvPayId:0,
          refundedInvValue=refundedAmount;  
          refundedInvValue2=refundedAmount2;  
          refundedInvPayVal=parseFloat(paymentx.amount); 
          refundedInvPayVal2=parseFloat(paymentx.total);
          this.paymentObj=paymentx;
        } 
      }
       

      const openkey=new Date().getTime(); //new Date.now();
      console.log("0000...wwwwwwwwwwwww REGISTRE REFUND ", openkey, refundedInvValue, refundedInvPayVal, refundValList, refundList);

      this.setState({ ["refundList"]: refundList, ["refundValList"]: refundValList, 
      ["refundedInvValue"]: refundedInvValue, ["refundedInvPayVal"]: refundedInvPayVal, 
      ["refundedInvValue2"]: refundedInvValue2, ["refundedInvPayVal2"]: refundedInvPayVal2, ["open"]:openkey, });  
    }

    console.log("componentDidMount-invoices-invoices", this.props.invoices);
    if(this.props.invoices!==undefined && this.props.invoices.length > 0){
      for(const invoicego of this.props.invoices){   
        //console.log("0000...OOOOOOOOOOOOOOOOX==REFUUUUUUUND ", invoicego);
        if(invoicego.id===""+invoiceId){
          this.invoiceObj=invoicego;
          const taxInfo=getProvinceTaxInfo(""+invoicego.province);
          this.setState({ ["taxInfo"]: taxInfo, });  
          //console.log("weeeeeeeeeeeeeeeeeeee TAXiNFO ", taxInfo);
          console.log("0000...componentDidMount-==FOUND ", invoicego);
          break; 
        } 
      }
    }



  }

  componentDidUpdate(prevProps) {
		/*if (prevProps.openx !== this.props.openx) {
		  //this.updateAndNotify();
		  if(this.props.openx){
			//console.log("WEEEEEEEEEEEE MINIPAYMENT componentWillMount OPEN ", new Date());
			this.setState({ ["productCart"]: [],"productId":"",	 });  
			this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
			this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
			this.setState({ ["nowPayment"]:  0, ["paymentRound"]:  0 });
		  }
		}*/

    const invoices=this.props.invoices;
    const payments=this.props.payments;
    const refunds=this.props.refunds;
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;

    

    if(refunds!==undefined && refunds.length > 0){
      //this.setState({ ["refunds"]: refunds});
      if (prevProps.refunds !== this.props.refunds) {
        this.setState({ ["refunds"]: refunds});
      }  
    }

    const paymentRefundList=[]; const refundValList=[]; 
    let refundedInvValue=0; let refundedInvPayVal=0;
    let refundedInvValue2=0; let refundedInvPayVal2=0;
    //getXRecordObjects(myArr, fieldName, fieldValue, oneObject)
    console.log("...OOOOOOOOOOOOOOOOX==openx-openx-openx NO-EVAL ",  this.props.openx);
    if (prevProps.openx !== this.props.openx) {
      console.log("...OOOOOOOOOOOOOOOOX==openx-openx-openx ",  this.props.openx);
      if(payments!==undefined && payments.length > 0){ 
        const refundList=[];
        const paymentsx=this.getXRecordObjects(payments, "id_invoices", [invoiceId], false);
        const paymentIds=this.array_column(paymentsx, "id", null);
        const refundsx=this.getXRecordObjects(refunds, "id_payments", paymentIds, false);
        //const zzzzz=this.getXRecordObjects(myArr, fieldName, fieldValue, oneObject);
        for(const paymentx of paymentsx){   //for(const paymentx of payments){  
          console.log("...OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
          let refundedAmount=0;   let refundedAmount2=0;    
          if(refunds!==undefined && refunds.length > 0){
            for(const refund of refunds){   
              console.log("...OOOOOOOOOOOOOOOOX==REFUUUUUUUND ", refundedAmount, refund);
              // if(refund.id_payments===paymentx.id){
              //   refundedAmount +=parseFloat(refund.amount);
              // }
              if(refund.id_payments===paymentx.id && refund.status==="3"){
                //refundedAmount +=parseFloat(refund.amount);
                refundedAmount2 +=parseFloat(refund.amount);
                refundedAmount +=parseFloat(refund.total);
              }  
              console.log("...OOOOOOOOOOOOOOOOX==REFUUUUUUUND .... ", refundedAmount);
            }
          }

          //const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount, refundedAmount:refundedAmount }; 
          //refundList.push(baseElem);  
          //refundValList.push(refundedAmount); 
          if(paymentId===paymentx.id){
            const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount, refundedAmount:refundedAmount }; 
            refundList.push(baseElem);  
            refundValList.push(refundedAmount); 

            //currentRefundInvValue:0, refundedInvValue:0, refundedInvId:0, refundedInvPayId:0,
            refundedInvValue=refundedAmount;
            refundedInvValue2=refundedAmount2;    
            refundedInvPayVal=parseFloat(paymentx.amount); 
            refundedInvPayVal2=parseFloat(paymentx.total); 
            this.paymentObj=paymentx;
          } 
        }
        
        
        //currentRefundValue:0, refundedValue:0, 
        /*if(refunds!==undefined && refunds.length > 0){
          for(const refund of refunds){   //for(const paymentx of payments){  
            console.log("...OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
            const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount }; 
            refundList.push(baseElem);  
            refundValList.push(0);  
          }
        }*/

        const openkey=new Date().getTime(); //new Date.now();
        console.log("...wwwwwwwwwwwww REGISTRE REFUND ", openkey, refundedInvValue, refundedInvPayVal, refundValList, refundList);

        this.setState({ ["refundList"]: refundList, ["refundValList"]: refundValList, 
        ["refundedInvValue"]: refundedInvValue, ["refundedInvPayVal"]: refundedInvPayVal, 
        ["refundedInvValue2"]: refundedInvValue2, ["refundedInvPayVal2"]: refundedInvPayVal2, ["open"]:openkey, });  
      } 

      /*if(refunds!==undefined && refunds.length > 0){
        for(const refund of refunds){   //for(const paymentx of payments){  
          console.log("...OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
          const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount }; 
          refundList.push(baseElem);  
          refundValList.push(0);  
        }
      }*/
    }
    
    console.log("componentDidUpdate-invoices-invoices", this.props.invoices);
    if (prevProps.invoices !== this.props.invoices) { 
      if(this.props.invoices!==undefined && this.props.invoices.length > 0){
        for(const invoicego of this.props.invoices){   
          //console.log("0000...OOOOOOOOOOOOOOOOX==REFUUUUUUUND ", invoicego);
          if(invoicego.id===""+invoiceId){
            this.invoiceObj=invoicego;
            const taxInfo=getProvinceTaxInfo(""+invoicego.province);
            this.setState({ ["taxInfo"]: taxInfo, });  
            //console.log("weeeeeeeeeeeeeeeeeeee TAXiNFO ", taxInfo);
            console.log("0000...componentDidUpdate-==FOUND ", invoicego);
            break; 
          } 
        }
      }
    }

	}


  
	  
	setMultiactions = (eventargs) => {
		const closeme=eventargs.closeme;
		// let isChecked=false; 
		// if(event.target.checked){ isChecked=true; }
		// this.setState({ [event.target.name]: isChecked });
		if(closeme!==null && closeme!==undefined && closeme==="close"){
			const obj={};
		} 
	
	};

	
	closeFolderProductPopup  = (anyEvent) => {     
		this.setState({["openProductFolder"]: false, ["disabledBtn"]: false }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success"); 
		const obj={closeme:'close'};
		if(this.state.paymentRound >0){
			obj.refreshafterPayment=1;
		} 
		// const taxInfo=this.state.taxInfo; 
		// const clientAddress=this.state.clientAddress;
		// const province=this.state.province;

		// const taxInfo=JSON.parse(JSON.stringify(this.state.taxInfo));
		// const clientAddress=JSON.parse(JSON.stringify(this.state.clientAddress));
		// const province=JSON.parse(JSON.stringify(this.state.province));
		// const currentTicket=JSON.parse(JSON.stringify(this.state.currentTicket));

		// this.setState(JSON.parse(JSON.stringify(this.stateInit)));
		// this.setState({ ["clientAddress"]: clientAddress, 
		// 	["province"]: province, ["taxInfo"]: taxInfo, ["currentTicket"]: currentTicket});  

		this.props.multiactions(obj);
	}

	handleTransactionChange = (event) => {                                                             
		//event.preventDefault();  
		//const stepTrack=event.target.dataset.prodcontrol;
		const selVal= event.value;  //productId
		this.setState({ ["transactionId"]: selVal });    
	};

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info	  
		//console.log("showToast showToast "); //console.log(arg);
		//alert("hello it is an eventDrop here "+arg.date);
		//Toastr("Hello I am a toast message right here, for testing purpose...");
		//toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		this.setState({ // update a property
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, 5500);
	}

  //handleChangeRefund
  handleChangeRefund = (event) => {
    const eventName=event.target.name;
    const eventVal=event.target.value;
    const index=event.target.dataset.index;
    const paymentId=event.target.dataset.paymentid;
    const max=event.target.dataset.max;
    const refunded=event.target.dataset.refunded;
    const maxrefunded=event.target.dataset.maxrefunded;
    //this.setState({ [event.target.name]: event.target.value }); 
    const paymentx=this.paymentObj;
    const maxPayVal=parseFloat(paymentx.amount); 
    const maxPayValtax=parseFloat(paymentx.total); 
    const refundedInvVal=this.state.refundedInvValue2;
    const refundedInvValtax=this.state.refundedInvValue;
             

    // const amount=this.state.amount;  
    // const nontaxableamount=this.state.nontaxableamount;  
    // //const amountretention=this.state.amountretention;  
    // const observation=this.state.observation;  
    // const refundtypefx=this.state.refundtype; 
    // const totalToRefund=parseFloat(""+amount) + parseFloat(""+nontaxableamount);

    const refundList=this.state.refundList;  
    console.log("eventName, eventVal, index, paymentId, refunded:", eventName, eventVal, index, paymentId, max,refunded, refundList);
    console.log("eventName, eventVal, index, paymentId, refunded: #2", eventVal, index, max,refunded, maxrefunded);

    /*if(parseFloat(eventVal) >parseFloat(max)){
      this.showToast("S'il vous plaît veuillez d'ajouter une valeur qui est moins ou égal à:"+max, "error");
      return false;
    }*/

    const baseElemx=refundList[index];

    /*if(parseFloat(refunded) >=parseFloat(max)){
    //if(baseElemx!==null && baseElemx.refundedAmount!==undefined && parseFloat(baseElemx.refundedAmount) >=parseFloat(max)){  
      this.showToast("Désolé vous ne pouvez pas continuer avec le remboursement parcequ'on a déjà remboursé: "+baseElemx.refundedAmount, "error");
      return false;
    }

    if(eventName==="amount" && (parseFloat(maxrefunded)+parseFloat(eventVal)) >parseFloat(max)){  
        this.showToast("Oups Désolé vous ne pouvez pas continuer parcequ'on a déjà remboursé: "+maxrefunded+" ajuster svp", "error");
        return false;
    }*/

    if(eventName==="amount" && (parseFloat(""+refundedInvVal)+parseFloat(eventVal)) >maxPayVal){  
      this.showToast("Oups Désolé vous ne pouvez pas continuer parcequ'on a déjà remboursé: "+maxrefunded+" ajuster svp", "error");
      return false;
    }

    if(refundList.length >0){
      const baseElem=refundList[index];
      if(eventName==="refundtype"){ baseElem.refundtype=eventVal; }
      else if(eventName==="amount"){ baseElem.amount=eventVal; } 
      else if(eventName==="nontaxableamount"){ baseElem.nontaxableamount=eventVal; } 
      refundList[index]=baseElem;
      this.setState({ ["refundList"]: refundList });   
    }else{
      //const baseElem={payment_id:paymentId, amount:0, refundtype:'', max:max };
      const baseElem={payment_id:paymentId, amount:0, refundtype:'', max:max, nontaxableamount:0 };
      if(eventName==="refundtype"){ baseElem.refundtype=eventVal; }
      else if(eventName==="amount"){ baseElem.amount=eventVal; }
      else if(eventName==="nontaxableamount"){ baseElem.nontaxableamount=eventVal; } 
      refundList.push(baseElem); 
      this.setState({ ["refundList"]: refundList }); 
    }



    
    if(eventName==="refundtype"){ 
      this.setState({ ["transactionRefundList"]: [], ["transactiontype"]: "0" }); 
      this.setState({ ["transRefBreakdownKeyList"]: [] }); 
      //this.setDynamicTotalRefund(transid, refundList, foundObject, transactionRefundList); 
      this.setDynamicTotalRefund("0", refundList, {}, []); 
      //baseElem.refundtype=eventVal; 
    }

    console.log("WATCHEEEEEEEEEEEER", refundList); 
  };
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  // if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		//   this.setState({ ["e_"+event.target.name]: false });
		//   if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
		// 	  this.setState({ ["e_"+event.target.name]: true }); 	
		//   }
	  // } 	
    this.setState({ [event.target.name]: event.target.value });
  };

  handleApplyRefund = (event) => {
    event.preventDefault();
    const { t } = this.props;
    //this.setState({ [event.target.name]: event.target.value });
    const refundList=this.state.refundList; 
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;
    let validRefund=0; 
    const refList=[];
    let refundtype=0;
    const observation=this.state.observation;
    const transacRefundList=this.state.transactionRefundList; 
    let taxableamountx=0;
    let nontaxableamountx=0;

    console.log("ABSURDE ", refundList);
    console.log("ABSURDE weeeeeeeeeeeeeeeee Uuuuuuuh way ", transacRefundList, refundList);

    for(const refund of refundList){ 
      if(refund.refundtype==="total"){ 
        refundtype++;   
      }
      else if(refund.refundtype==="partiel"){
        refundtype++;  
      }
    } 

    if(refundtype  <1){ 
      this.showToast("S'il vous plaît veuillez de choisir un type de remboursement.", "error");
      return false;
    } 

    for(const refund of refundList){
      if(refund.refundtype==="total" && refund.amount!=="" && parseFloat(refund.amount)>0 && refund.nontaxableamount!=="" && parseFloat(refund.nontaxableamount)>=0){ 
        validRefund++; 
        taxableamountx=refund.amount;
        nontaxableamountx=refund.nontaxableamount;
        refList.push(refund);
      }
      else if(refund.refundtype==="partiel" && parseFloat(refund.amount) >0  
      && parseFloat(refund.amount)<=parseFloat(refund.max) && refund.nontaxableamount!=="" && parseFloat(refund.nontaxableamount)>=0){
         validRefund++; 
         taxableamountx=refund.amount;
         nontaxableamountx=refund.nontaxableamount;
         refList.push(refund);
      }
      else if(refund.refundtype==="partiel" && parseFloat(refund.amount) >=0  
      && parseFloat(refund.amount)<=parseFloat(refund.max) && refund.nontaxableamount!=="" && parseFloat(refund.nontaxableamount)>0){
         validRefund++; 
         taxableamountx=refund.amount;
         nontaxableamountx=refund.nontaxableamount;
         refList.push(refund);
      }
    } 
    if(validRefund <1){
      //t("zqwNVFrmEl1_x")
      this.showToast("S'il vous plaît veuillez d'ajouter le montant de paiement à rembourser et le montant non taxable s'il y a lieu.", "error");
      return false;
    }
    
    if(observation===null || observation===""){
      this.showToast("S'il vous plaît veuillez d'expliquer la raison du remboursement.", "error");
      return false;
    }

    const amount=this.state.amount;  
    const nontaxableamount=this.state.nontaxableamount;  
    //const amountretention=this.state.amountretention;    
    const refundtypefx=this.state.refundtype; 
    const totalToRefund=parseFloat(""+amount) + parseFloat(""+nontaxableamount); 
    const totalToRefundx=parseFloat(""+taxableamountx) + parseFloat(""+nontaxableamountx); 

    const paymentx=this.paymentObj;
    const maxPayVal=parseFloat(paymentx.amount); 
    const maxPayValtax=parseFloat(paymentx.total); 
    const refundedInvVal=this.state.refundedInvValue2;
    const refundedInvValtax=this.state.refundedInvValue;
    let mayRefundval=0;
    const zaaaaRest=maxPayVal-parseFloat(""+refundedInvVal);
    if(zaaaaRest >0){  
      mayRefundval=zaaaaRest.toFixed(2);
    }

    if((parseFloat(""+refundedInvVal)+totalToRefundx) >maxPayVal){  
      this.showToast("Oooouuups Désolé vous ne pouvez pas continuer parcequ'on a déjà remboursé: "+refundedInvVal+" ajuster svp à "+mayRefundval, "error");
      return false;
    }

    
    console.log("ABSURDE totalToRefund vs refundList  ", totalToRefund, refundList);
    if(!window.confirm("Confirmez-vous le remboursement pour le montant de "+totalToRefund.toFixed(2)+" $cad?")){
      //this.showToast("Oups Désolé vous devez confirmer le remboursement du client, si tu veux procéder. ", "error");
      return false;
    }
    
    let objToSend=null;
    const clientspecimen=this.state.clientspecimen;
    //if(clientspecimen!==null && clientspecimen.length >0){ 
      //console.log("wewewe l="+clientspecimen.length+" n="+clientspecimen.name+" t="+clientspecimen.type)
    if(clientspecimen!==null && clientspecimen.name!==undefined && clientspecimen.name!==null){
      //const objToSend={invoiceId:invoiceId, observation:observation, refunds:refList};
      //}else{
       objToSend = new FormData();  
        objToSend.append( 
          "file", 
          clientspecimen, 
          clientspecimen.name 
        ); 
        objToSend.append('isMULTIPART', 1); 
        objToSend.append('xfiletype', clientspecimen.type); 
        objToSend.append('invoiceId', invoiceId); 
        objToSend.append('observation', observation); 
        objToSend.append('refunds', JSON.stringify(refList)); 
        objToSend.append('transacRefundList', transacRefundList); 
        
        console.log("weeeeeeeeeeeeeeeee sending with file ");  
    }else{
      objToSend={invoiceId:invoiceId, observation:observation, refunds:refList, transacRefundList:transacRefundList};
      console.log("weeeeeeeeeeeeeeeee nooooooooooo file at all ");
    } 
      
    //console.log("weeeeeeeeeeeeeeeee Uuuuuuuh way ", transacRefundList, objToSend);
    //.showToast("Uuuuuuuh way, you have passed it Tiiiiiiiiiiiil heeeeeeeere.", "success"); return false; 
    
    const this2=this; 
    this2.setState({ ["isLoaderOpen"]: true});  
    Promise.resolve(this.props.addOrUpdateRefunds(objToSend))
    .then((response)=>{
      const clientTransInfo=response.NeoVentes.data;
      //this.setState(response.NdiFieldsOne);
      /*const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
      const isClientId=clientProdTransInfo.client.id;
      const isClientLead=isClientId >0?false:true; 
      //console.log("..HOHOHO response from server .... ");  //console.log(response.NdiFieldsOne.data);
      this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
      this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead }); 	
      //console.log("HOHOHO just assinged to dynamicFormAllData .... "); */	

      console.log("..HOHOHO response from server .... ");  console.log(response);
      
      this.setState({ ["isLoaderOpen"]: false, ["refunds"]: clientTransInfo.refunds, 
      ["disabledBtn"]:true, ["paymentRound"]:1, ["clientTransInfo"]:clientTransInfo,  errorMessage: "", });
      this.showToast(t("zqwNFFtxt11_x"), "success"); 
      // this.saveUpdateTicketData(null);  
      // setTimeout(() => {
      // 	this.showToast(t("zqwNFFtxt10_x"), "warning");  
      // 	this.saveUpdateTicketData(null);  
      // }, 10000);   
      return response;
    }).catch(function(error){ 
      this2.setState({ ["isLoaderOpen"]: false});  
      //alert(error);
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n "+t("zqwNVFrmEl14_x")
      }

      if(errObj.status===500 && errObj.data.error.description!==undefined){
        errMessage ="\n "+errObj.data.error.description;
      }else if(errObj.status<500 && errObj.data!==undefined){
        //errMessage ="\n "+errObj.data.error.description;
      }

      this2.setState({ ["errorMessage"]: errMessage});  
      //this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      //console.log(rawError);
      this2.showToast(errMessage, "error"); 
    });
    

  };

  handleApplyRetention = (event) => {
    event.preventDefault();
    const { t } = this.props;
    //this.setState({ [event.target.name]: event.target.value });
    const refundList=this.state.refundList; 
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;
    let validRefund=0; 
    const refList=[];
    let refundtype=0;
    const observation=this.state.observation;
    const transacRefundList=this.state.transactionRefundList; 

    // data-refcancelled={"yes"} data-refid={item.id} data-refamount={item.amount} 
    const refcancelled=event.target.dataset.refcancelled;
    const refcancelledId=event.target.dataset.refid;
    const refcancelledAmnt=event.target.dataset.amount;
    const retention=event.target.dataset.retention;
    const creditwithtaxes=this.state.creditwithtaxes;



    const baseElem={payment_id:paymentId, amount:0, refundtype:'r', max:1, nontaxableamount:0 };
      // // if(eventName==="refundtype"){ baseElem.refundtype=eventVal; }
      // else if(eventName==="amount"){ baseElem.amount=eventVal; }
      // else if(eventName==="nontaxableamount"){ baseElem.nontaxableamount=eventVal; } 
      // refundList.push(baseElem); 

    refList.push(baseElem);

    console.log("ABSURDE ", refundList);
    console.log("ABSURDE weeeeeeeeeeeeeeeee Uuuuuuuh way ", transacRefundList, refundList);

       
    let objToSend=null;
    const clientspecimen=this.state.clientspecimen;
    //if(clientspecimen!==null && clientspecimen.length >0){ 
      //console.log("wewewe l="+clientspecimen.length+" n="+clientspecimen.name+" t="+clientspecimen.type)
    
    // this.setState({ retmaxwithtax: maxwithtax, retpaymentamount: paymentamount, 
    // retrefunded: refunded,    });
    // amountretention 
    const retmaxwithtax=this.state.retmaxwithtax; 
    const retpaymentamount=this.state.retpaymentamount; 
    const retrefunded=this.state.retrefunded; 
    const amountretention=this.state.amountretention; 

    if(refcancelled===undefined && retention==="credit"){  
    if((parseFloat(""+amountretention)) <1){  
      this.showToast("Oups Désolé le montant de rétention doit être superieur ou égal à 1 ", "error");
      return false;
    }

    if((parseFloat(retrefunded)+parseFloat(amountretention)) >parseFloat(retmaxwithtax)){  
      this.showToast("Oups Désolé vous ne pouvez pas continuer parcequ'on a déjà remboursé: "+retrefunded+" ajuster svp", "error");
      return false;
    }
  }

  if(refcancelled!==undefined && refcancelled==="yes"){  
    if(!window.confirm("Confirmez-vous la cancellation de remboursement interne avec ID #"+refcancelledId)){
      this.showToast("Oups Désolé vous devez confirmer la cancellation de remboursement interne. ", "error");
      return false;
    }
  }

  if(refcancelled===undefined && retention==="retention"){  
    if(!window.confirm("Confirmez-vous la retention du client?")){
      this.showToast("Oups Désolé vous devez confirmer la retention du client, si tu veux procéder. ", "error");
      return false;
    }
  }

  // if(!window.confirm("Confirmez-vous le remboursement pour le montant de "+totalToRefund.toFixed(2)+" $cad?")){
  //   //this.showToast("Oups Désolé vous devez confirmer le remboursement du client, si tu veux procéder. ", "error");
  //   return false;
  // }

    objToSend={invoiceId:invoiceId, observation:observation, 
      refundtypeHold:'r', payment_idHold:paymentId, 
      refunds:refList, transacRefundList:transacRefundList};
    objToSend.amountretention=amountretention; 
    if(refcancelled!==undefined && refcancelled==="yes"){  
      objToSend.amountretention=refcancelledAmnt;
      objToSend.refundcancelled="yes";
      objToSend.refcancelledId=refcancelledId;
      objToSend.observation="Cancelled internal refund #"+refcancelledId;
    }
    if(refcancelled===undefined && retention==="retention"){  
      objToSend.amountretention="0"; 
      objToSend.refundtypeHold='C'; 
    }

    let refundtypex="";  
    if(refundList[0] && refundList[0].refundtype){
      refundtypex=refundList[0].refundtype;  
    }
    if((refundtypex!==null && refundtypex!=="" && refundtypex==="retention")){
      const montant_tax1=this.state.montant_tax1;  
      const montant_tax2=this.state.montant_tax2; 
      const nontaxableamount=this.state.nontaxableamount;   
      objToSend.tax1=""+montant_tax1; 
      objToSend.tax2=""+montant_tax2; 
      objToSend.nontaxableamount=""+nontaxableamount;
      objToSend.creditwithtaxes=""+creditwithtaxes; //credittaxes 
      objToSend.refundtypex=""+refundtypex;
      //const creditwithtaxes=this.state.creditwithtaxes;

        // const tax1= (((amountRet*1)*TAX1)); //.toFixed(2);
        // const tax2= (((amountRet*1)*TAX2));
        // const tax_total= (amountRet*TAX_TOTAL);
        // const totalForItem= (((amountRet*1)+(tax_total*1)));
        // const totalWTax = (((amountRet*1)+tax1+tax2)); 
        
        // this.setState({ montant_tax1: tax1, montant_tax2:tax2 });

        // console.log("WATCHEEEEEEEEEEEER-CONGRATS", taxInfo, amountRet, totalWTax, tax1, tax2); 
    }

    console.log("weeeeeeeeeeeeeeeee nooooooooooo file at all ");
    console.log("weeeeQQQnoooo retmaxwithtax, retpaymentamount, retrefunded, amountretention ", retmaxwithtax, retpaymentamount, retrefunded, amountretention);
      
    console.log("weeeeeeeeeeeeeeeee Uuuuuuuh way ", transacRefundList, objToSend);
    //this.showToast("Uuuuuuuh way, you have passed it Tiiiiiiiiiiiil heeeeeeeere.", "success"); return false; 
    
    const this2=this; 
    this2.setState({ ["isLoaderOpen"]: true});  
    Promise.resolve(this.props.addOrUpdateRefunds(objToSend))
    .then((response)=>{
      const clientTransInfo=response.NeoVentes.data;
      //this.setState(response.NdiFieldsOne);
     
      console.log("..HOHOHO response from server .... ");  console.log(response);
      
      this.setState({ ["isLoaderOpen"]: false, ["refunds"]: clientTransInfo.refunds, 
      ["disabledBtn"]:true, ["paymentRound"]:1, ["clientTransInfo"]:clientTransInfo, errorMessage: "", });
      this.showToast(t("zqwNFFtxt11_x"), "success"); 
      // this.saveUpdateTicketData(null);  
      // setTimeout(() => {
      // 	this.showToast(t("zqwNFFtxt10_x"), "warning");  
      // 	this.saveUpdateTicketData(null);  
      // }, 10000);   
      return response;
    }).catch(function(error){ 
      this2.setState({ ["isLoaderOpen"]: false});  
      //alert(error);
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n "+t("zqwNVFrmEl14_x")
      }
      //this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
      if(errObj.status===500 && errObj.data.error.description!==undefined){
        errMessage ="\n "+errObj.data.error.description;
      }else if(errObj.status<500 && errObj.data!==undefined){
        //errMessage ="\n "+errObj.data.error.description;
      }

      this2.setState({ ["errorMessage"]: errMessage});  
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      //console.log("SHORT ERROR MESSAGE-desc: "+errObj.data.error.description);
      //console.log("SHORT ERROR MESSAGE-statusCode: "+errObj.data.statusCode);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      //console.log(rawError);
      this2.showToast(errMessage, "error"); 
    });
    

  };

  
  handleChangeRefundret = (event) => {
    const eventName=event.target.name;
    const eventVal=event.target.value;
    const index=event.target.dataset.index;
    const paymentId=event.target.dataset.paymentid;
    const max=event.target.dataset.max;
    const refunded=event.target.dataset.refunded;
    const maxrefunded=event.target.dataset.maxrefunded;
    const paymentamount=event.target.dataset.paymentamount;
    const maxwithtax=event.target.dataset.maxwithtax;
    //this.setState({ [event.target.name]: event.target.value }); 
    const refundList=this.state.refundList; 
    const creditwithtaxes=this.state.creditwithtaxes; 
    console.log("eventName, eventVal, index, paymentId, refunded:", eventName, eventVal, index, paymentId, max,refunded, refundList);

    if(parseFloat(eventVal) >parseFloat(maxwithtax)){
      this.showToast("S'il vous plaît veuillez d'ajouter une valeur qui est moins ou égal à:"+max, "error");
      return false;
    }


    //const baseElemx=refundList[index]; handleOptionChange  

    if(parseFloat(refunded) >=parseFloat(max)){
    //if(baseElemx!==null && baseElemx.refundedAmount!==undefined && parseFloat(baseElemx.refundedAmount) >=parseFloat(max)){  
      this.showToast("Désolé vous ne pouvez pas continuer avec le remboursement parcequ'on a déjà remboursé: "+refunded, "error");
      return false;
    }

    if((parseFloat(refunded)+parseFloat(eventVal)) >parseFloat(maxwithtax)){  
        this.showToast("Oups Désolé vous ne pouvez pas continuer parcequ'on a déjà remboursé: "+maxrefunded+" ajuster svp", "error");
        return false;
    }

    // data-index={0} data-paymentid={0}  data-maxwithtax={paymentxtotal} 
    //     data-paymentamount={paymentxamount} 
    //     data-refunded={paymentxrefValue}  data-nontaxableamount={totTaxFree}  
    //           className='form-control' placeholder={""} data-maxrefunded={paymentxrefValue}

    this.setState({ [event.target.name]: event.target.value });
    this.setState({ retmaxwithtax: maxwithtax, retpaymentamount: paymentamount, 
      retrefunded: refunded,    });
      // amountretention 
    const taxInfo=this.state.taxInfo; 
    //const creditwithtaxes=this.state.creditwithtaxes;
    //const refundList=this.state.refundList;
    let refundtype="";  
    if(refundList[0] && refundList[0].refundtype){
      refundtype=refundList[0].refundtype;  
    }
    if((refundtype!==null && refundtype!=="" && refundtype==="retention") && 
      event.target.name==="amountretention" && creditwithtaxes==="credittaxes"){
        const amountRet= parseFloat(event.target.value);
        //const taxInfo= JSON.parse(JSON.stringify(taxInfoX));
        let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
        let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2         
        const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
        const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
        const TAX1l=taxInfo["tax1"]; //tax1 0.9975
        const TAX2l=taxInfo["tax2"]; 
        let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
        let TAX2lx=taxInfo["tax2"]; 
        let TAX12=0; let TAX21=0;
        if(TAX2_NAME ==="0"){             
          TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
          TAX1=TAX1+TAX2;     
          TAX12=TAX1+TAX2;  
          TAX2=0;   
        }  
        if(TAX1_NAME==="0"){                   
          TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
          TAX2=TAX2+TAX1;  
          TAX21=TAX1+TAX2;  
          TAX1=0;                          
        } 

        const TAX_TOTAL=TAX1+TAX2; 

        let tax1= (((amountRet*1)*TAX1)); //.toFixed(2);
        let tax2= (((amountRet*1)*TAX2));
        const tax_total= (amountRet*TAX_TOTAL);
        const totalForItem= (((amountRet*1)+(tax_total*1)));
        const totalWTax = (((amountRet*1)+tax1+tax2)); 

        //tax1=tax1.toFixed(2);  //.toFixed(2);
        //tax2=tax2.toFixed(2); 

        //tax1=parseFloat(""+tax1).toFixed(2);  //.toFixed(2);
        //tax2=parseFloat(""+tax2).toFixed(2); 
        
        this.setState({ montant_tax1: tax1, montant_tax2:tax2 });

        console.log("WATCHEEEEEEEEEEEER-CONGRATS", taxInfo, amountRet, totalWTax, tax1, tax2); 
    } 

    
    console.log("WATCHEEEEEEEEEEEER", refundList); 
  };


  getXRecordObjects = (myArr, fieldName, fieldValues, oneObject) => {
    let fieldBlock=[]; let fieldBlockOne=null; 
    let promList = myArr.length > 0
      && myArr.map((item, i) => {  
      //let fieldBlock=null; 
      //const zx=item[fieldName];      
        if(fieldValues!==null && fieldValues.length > 0 && fieldValues.includes(item[fieldName])){
          if(oneObject){
            fieldBlockOne=item; 
          }else{
            fieldBlock.push(item);
          }
          //fieldBlock=item; 
        }       

      // if(item.product_type_id===fieldValue){
      //   fieldBlock=item; 
      // }
      
      return ( null );
    }, this);
    
      //return fieldBlock;
      if(oneObject){
        return fieldBlockOne; 
      }else{
        return fieldBlock;
      }
  }

  setDynamicTotalRefund = (transid, refundList, foundObject, transactionRefundList) => {
    let eventVal2=0;
    for(const transx of transactionRefundList){
      if(transx.multiple===true){   
        if(transx.id===transid){ 
          if(foundObject && foundObject.refundList && foundObject.refundList.length >0){ 
            for(const transx of foundObject.refundList){ 
              const tmpVal=parseFloat(transx.value);
              if(tmpVal>=0){  
                eventVal2 +=tmpVal; 
              }
            }  
          }
        }else{
          const foundObject2=transx;
          if(foundObject2 && foundObject2.refundList && foundObject2.refundList.length >0){ 
            for(const transx of foundObject2.refundList){ 
              const tmpVal=parseFloat(transx.value);
              if(tmpVal>=0){  
                eventVal2 +=tmpVal; 
              }
            }  
          }
        }
      }else{
        //
        const tmpVal=parseFloat(transx.singleValue);
        if(tmpVal>=0){  
          eventVal2 +=tmpVal; 
        }
      }      
    }

    const index0=0;
    const baseElem=refundList[index0];
    //eventVal2=tmpValx.toFixed(2);
    eventVal2=eventVal2.toFixed(2);
    if(this.taxableAmountRef.current!==null){
    this.taxableAmountRef.current.value=eventVal2;}
    baseElem.amount=eventVal2;
    refundList[index0]=baseElem;
    this.setState({ ["refundList"]: refundList, ["amount"]: eventVal2,  });  
    console.log("###eventName-foundObject###: eeeeeeeeeeeeeeeeeeeror ", refundList);

    
  }


  handleChangeRefundsub = (event) => {
    const eventName=event.target.name;
    const eventVal=event.target.value;
    const index=event.target.dataset.index;
    const paymentIdx=event.target.dataset.paymentid;
    const transid=event.target.dataset.transid;
    const max=event.target.dataset.max;
    const refunded=event.target.dataset.refunded;
    const singlevalue=event.target.dataset.singlevalue;
    //this.setState({ [event.target.name]: event.target.value }); 
    const refundList=this.state.refundList;  
    let transactionRefundList=this.state.transactionRefundList;
    let foundIndex=-3; let foundObject=null; let fqtyO=-1;
    for(const transx of transactionRefundList){
      fqtyO++;
      if(transx.id===transid){  
        foundIndex=fqtyO; 
        foundObject=transx;
      }
    }
    // <input type="number" name="subamount" id="subamount" defaultValue={0} 
    //           min="0" max={maxamount} step="0.1"  
    //           data-index={invRow.key} data-transid={transx.id}  data-max={maxamount} 
    //           data-refunded={invRow.key}  data-nontaxableamount={invRow.key}  
    //                 className='form-control' placeholder={""} 
    //                 />

    if(parseFloat(eventVal)>0){  
      const reeeFunded=parseFloat(refunded);
      const reeeMax=parseFloat(max);
      let maximumToRef=0;
      if(reeeMax>reeeFunded){  
        maximumToRef=reeeMax-reeeFunded;
      }
      if(reeeFunded+parseFloat(eventVal)>reeeMax){
        //this.showToast(t("zqwNFFtxt11_x"), "success"); 
        this.showToast("Erreur, la valeur maximum à introduire est "+maximumToRef.toFixed(2), "error"); 
        return false;
      }
    }

    //singlevalue
    if(singlevalue!==undefined){ 
      let eventVal2=0;
      const tmpValx=parseFloat(eventVal);
      foundObject.singleValue=tmpValx;
      this.setState({ ["transactionRefundList"]: transactionRefundList }); 

      this.setDynamicTotalRefund(transid, refundList, foundObject, transactionRefundList); 

      return false;
    }
















    const transacRefundList=[];
    const mainTransactions=this.props.mainTransactions;
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;
    let mainObject=null;  let mainElement=null;
    if(mainTransactions!==undefined && mainTransactions.length > 0){
      let myIndz=-1;
      for(const transx of mainTransactions){
        //const preachat=JSON.parse(transx.preachat);
        //readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false}
        if(transx.id_invoices===invoiceId && transx.id===transid){ 
          mainObject=transx;   
          myIndz++;
          let transListx=[];
          const preachat=JSON.parse(transx.preachat); //neoprod_breakdown
          if(preachat.neoprod_breakdown.invoice!==undefined && preachat.neoprod_breakdown.invoice.length > 0){
            for(const invRow of preachat.neoprod_breakdown.invoice){
              if(invRow.key===index){   
                mainElement=invRow;
                //transx.value=""+eventVal;
              }
            } 
          }         
          
        } 
      }
    }

    if(foundObject && foundObject.refundList && foundObject.refundList.length >0){
      let fqtyO2=-1;  let foundIt=0;  let subMainElem=null;
      for(const transx of foundObject.refundList){
        fqtyO2++;
        if(transx.key===index){  
          //foundIndex=fqtyO; 
          //foundObject=transx; 
          foundIt=1;
          transx.value=""+eventVal;
          subMainElem=transx;
        }
      }

      if(foundIt <1){ 
        if(mainElement!==null){   
          mainElement.value=""+eventVal;
          foundObject.refundList.push(mainElement);
        } 
      }


      this.setState({ ["transactionRefundList"]: transactionRefundList }); 
    }else{ 
      const locRefundList=[];  const oneList=[];
      if(mainElement!==null){  
        //transx.value=""+eventVal;
        mainElement.value=""+eventVal;
        oneList.push(mainElement);
      }
      /*
      //oneList.push();
      locRefundList.push({id:transid, payment_id:paymentId, refundList:oneList});
      //foundObject.refundList.push();
      //foundObject.refundList=locRefundList;
      //foundObject=locRefundList;
      transactionRefundList=locRefundList;
      */

      foundObject.payment_id=paymentId;
      foundObject.refundList.push(mainElement);
      this.setState({ ["transactionRefundList"]: transactionRefundList }); 
    }

    //transactionRefundList[foundIndex]=foundObject;
    let taxableEventVal=0; let eventVal2=0;
    

    this.setDynamicTotalRefund(transid, refundList, foundObject, transactionRefundList); 





    //getXRecordObjects2 = (myArr, fieldName, fieldValue, oneObject)  
    console.log("eventName, eventVal, index, paymentId, refunded:", eventName, eventVal, index, paymentId, max,refunded, refundList);

    console.log("###eventName-foundObject###:", foundObject, transactionRefundList, refundList);

    return false;


    if(parseFloat(eventVal) >parseFloat(max)){
      this.showToast("S'il vous plaît veuillez d'ajouter une valeur qui est moins ou égal à:"+max, "error");
      return false;
    }

    const baseElemx=refundList[index];

    if(parseFloat(refunded) >=parseFloat(max)){
    //if(baseElemx!==null && baseElemx.refundedAmount!==undefined && parseFloat(baseElemx.refundedAmount) >=parseFloat(max)){  
      this.showToast("Désolé vous ne pouvez pas continuer avec le remboursement parcequ'on a déjà remboursé: "+baseElemx.refundedAmount, "error");
      return false;
    }

    if(refundList.length >0){
      const baseElem=refundList[index];
      if(eventName==="refundtype"){ baseElem.refundtype=eventVal; }
      else if(eventName==="amount"){ baseElem.amount=eventVal; } 
      else if(eventName==="nontaxableamount"){ baseElem.nontaxableamount=eventVal; } 
      refundList[index]=baseElem;
      this.setState({ ["refundList"]: refundList });   
    }else{
      //const baseElem={payment_id:paymentId, amount:0, refundtype:'', max:max };
      const baseElem={payment_id:paymentId, amount:0, refundtype:'', max:max, nontaxableamount:0 };
      if(eventName==="refundtype"){ baseElem.refundtype=eventVal; }
      else if(eventName==="amount"){ baseElem.amount=eventVal; }
      else if(eventName==="nontaxableamount"){ baseElem.nontaxableamount=eventVal; } 
      refundList.push(baseElem); 
      this.setState({ ["refundList"]: refundList }); 
    }

    console.log("WATCHEEEEEEEEEEEER", refundList);
     
  };
      
  handleFocusoutsub = (event) => {
	  // if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		//   this.setState({ ["e_"+event.target.name]: false });
		//   if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
		// 	  this.setState({ ["e_"+event.target.name]: true }); 	
		//   }
	  // } 
    this.handleChangeRefundsub(event);	
  };

  getSubKeyValue = (transactionId, key) => {
	  let value=0;
    const transactionRefundList=this.state.transactionRefundList;
    //locRefundList.push({id:transid,  refundList:oneList});
    for(const transx of transactionRefundList){      
      if(transx.id===transactionId){ 
        //const singleValue=transx.singleValue; 
        if(transx.multiple===true){
        for(const transx2 of transx.refundList){ 
          if(transx2.key===key){  
            value=transx2.value;
            return transx2.value;
            //break;            
          }
        }
       }else{
        const singleValue=transx.singleValue; 
        value=singleValue;
        return singleValue; 
       }
      }
    }

    return value;
  };

  handleChosenTransaction = (event) => {
    const eventName=event.target.name;
    const eventVal=event.target.value;
    const index=event.target.dataset.index;
    const paymentIdx=event.target.dataset.paymentid;
    const transid=event.target.dataset.transid;
    const max=event.target.dataset.max;
    const refunded=event.target.dataset.refunded;
    //this.setState({ [event.target.name]: event.target.value }); 
    const refundList=this.state.refundList;  
    let transactionRefundList=this.state.transactionRefundList;
    let foundIndex=-3; let foundObject=null; let fqtyO=-1;
    const transactiontype=this.state.transactiontype;  
    for(const transx of transactionRefundList){
      fqtyO++;
      if(transx.id===transid){  
        foundIndex=fqtyO; 
        foundObject=transx;
      }
    }

    if(foundIndex>=0){
      foundObject.show="y"; 
      //(foundObject.show)
      this.setState({ ["transactionRefundList"]: transactionRefundList }); 
      console.log("end-WATEEEER-ChosenTransaction OK#7 ", foundIndex, transactiontype);
    }else{
      //validate 0:none 1:old 2:new with breakdown
      //const transacRefundList=[];
      const mainTransactions=this.props.mainTransactions;
      //const invoiceId=this.props.invoiceId;
      //const paymentId=this.props.paymentId;
      let mainObject=null;  let mainElement=null;
      let myIndzqq=-1; let myIndz=-1;
      console.log("777-NOOOOOT-IN-ChosenTransaction #1.0 ", foundIndex, transactiontype, transactionRefundList);
      if(mainTransactions!==undefined && mainTransactions.length > 0){
        //let myIndz=-1;
        console.log("end-WATEEEER-ChosenTransaction #1.1 ", foundIndex, transactiontype, myIndzqq);
        for(const transx of mainTransactions){ 
          if(transx.id===transid){  //transx.id_invoices===invoiceId && transx.id===transid
            mainObject=transx;   
            myIndz++; 
            const preachat=JSON.parse(transx.preachat); //neoprod_breakdown
            console.log("end-WATEEEER-ChosenTransaction #1.2 ", foundIndex, transactiontype, myIndzqq);
            if(preachat.neoprod_breakdown && preachat.neoprod_breakdown.invoice!==undefined && preachat.neoprod_breakdown.invoice.length > 0){
              for(const invRow of preachat.neoprod_breakdown.invoice){
                //if(invRow.key===index){   
                  mainElement=invRow;
                  //transx.value=""+eventVal;
                  myIndzqq++; 
                  console.log("end-WATEEEER-ChosenTransaction #1.3 ", foundIndex, transactiontype, myIndzqq);
                //}
              } 
            } 
            break;      
          }
           
        }
      }

      //transRefBreakdown
      //const transRefBreakdownKeyList=[];
      const transRefBreakdownKeyList=this.state.transRefBreakdownKeyList;
      const transRefBreakdowns=this.props.transRefBreakdown;
      if(transRefBreakdowns &&  transRefBreakdowns.length > 0){
        console.log("strt-WATEEEER-transRefBreakdowns ", transRefBreakdowns);
      for(const transRefx of transRefBreakdowns){
        //fqtyO++;
        if(transRefx.id_transactions===transid){   
          const details=JSON.parse(transRefx.details); //neoprod_breakdown refundList
          for(const detailx of details.refundList){
            if(detailx.key!==""){
              const mixkey=transid+"_"+detailx.key;
              const mixValue=""+detailx.value;
              let objRefList=null; 
              //{mixkey:"", total:} 
              for(const keyRefx of transRefBreakdownKeyList){
                if(keyRefx.mixkey===mixkey){
                  const tot=keyRefx.total + parseFloat(mixValue);
                  keyRefx.total=tot;
                  objRefList=keyRefx;
                  break;
                }
              }
              if(objRefList===null){
                transRefBreakdownKeyList.push({mixkey:mixkey, total:parseFloat(mixValue)});
              }
            }
          }
        }
      }
        this.setState({ ["transRefBreakdownKeyList"]: transRefBreakdownKeyList }); 
        console.log("end-WATEEEER-transRefBreakdownKeyList ", transRefBreakdownKeyList);
      }

      console.log("end-WATEEEER-ChosenTransaction #1 ", foundIndex, transactiontype, myIndzqq);

      if(myIndzqq >=0){
        if(foundIndex <0){
          const locRefundList=[];  const oneList=[]; 
          //locRefundList.push({id:transid, show:"y", refundList:oneList}); 
          //transactionRefundList=locRefundList;
          transactionRefundList.push({id:transid, show:"y", multiple:true, refundList:oneList});
          //foundObject.show="y";  
          this.setState({ ["transactionRefundList"]: transactionRefundList }); 
          console.log("end-WATEEEER-ChosenTransaction #3 ", foundIndex, transactiontype, myIndzqq);
        }
        if(transactiontype!=="2"){
        this.setState({ ["transactiontype"]: "2" }); }
      }
      else if(transactiontype==="2" && myIndzqq <0){
        if(foundIndex <0){
          const locRefundList=[];  const oneList=[];  
          transactionRefundList.push({id:transid, show:"y", multiple:false, singleValue:0.0, refundList:oneList});
          //foundObject.show="y";  
          this.setState({ ["transactionRefundList"]: transactionRefundList }); 
          console.log("end-WATEEEER-ChosenTransaction #3 ", foundIndex, transactiontype, myIndzqq);
        }
        
      }
      else if(transactiontype!=="2" && myIndz >=0){
        this.setState({ ["transactiontype"]: "1" }); 
        console.log("end-WATEEEER-ChosenTransaction #1.7 ", foundIndex, transactiontype, myIndz);
      }
    }


    console.log("end-WATCHEEEEEEEEEEEER-handleChosenTransaction", foundIndex, transactiontype, transactionRefundList);

  };

  handleRemoveTransaction = (event) => { 
    const transid=event.target.dataset.transid;
    const max=event.target.dataset.max;
    const refunded=event.target.dataset.refunded; 
    const refundList=this.state.refundList;  
    let transactionRefundList=this.state.transactionRefundList;
    const transRefBreakdownKeyList=this.state.transRefBreakdownKeyList;
    let foundIndex=-3; let foundObject=null; let fqtyO=-1;
    const transactiontype=this.state.transactiontype;  
    for(const transx of transactionRefundList){
      fqtyO++;
      if(transx.id===transid){  
        foundIndex=fqtyO; 
        foundObject=transx;
      }
    }


    const mixkey=transid+"_"; //+invRow.key;
    let refundedKeyQty=0;
    for (let [i, keyRefx] of transRefBreakdownKeyList.entries()) {
    //for(const keyRefx of transRefBreakdownKeyList){
      if((""+keyRefx.mixkey).startsWith(mixkey)){  
        //keyRefx.mixkey===mixkey
        //refundedKeyAmnt=keyRefx.total;
        refundedKeyQty++;
        keyRefx=null;
        transRefBreakdownKeyList.splice(i, 1);
        //break;
      }
    }

    if(refundedKeyQty>0){
      transactionRefundList.splice(foundIndex, 1);
      this.setState({ ["transRefBreakdownKeyList"]: transRefBreakdownKeyList }); 
      this.setDynamicTotalRefund(transid, refundList, foundObject, transactionRefundList); 
      //this.evaluateTransactionType(event);
      //console.log("end-WATEEEER-transRefBreakdownKeyList OK#7 ", foundIndex, transactiontype);
    }





    if(foundIndex>=0){
      transactionRefundList.splice(foundIndex, 1);
      this.setState({ ["transactionRefundList"]: transactionRefundList }); 
      this.evaluateTransactionType(event);
      console.log("end-WATEEEER-RemoveTransaction OK#7 ", foundIndex, transactiontype);
    }

    console.log("end-WATCHEEEEEEEEEEEER-handleRemoveTransaction", foundIndex, transactiontype, transactionRefundList);

  };

  evaluateTransactionType = (event) => { 
    // const transid=event.target.dataset.transid;
    // const max=event.target.dataset.max;
    // const refunded=event.target.dataset.refunded; 
    // const refundList=this.state.refundList;  
    let transactionRefundList=this.state.transactionRefundList;
    let foundIndex=-3; let foundObject=null; let fqtyO=-1;
    const transactiontype=this.state.transactiontype;  
    for(const transx of transactionRefundList){
      fqtyO++;
      if(transx.id && transx.show){   //if(transx.id===transid){ 
        foundIndex=fqtyO; 
        foundObject=transx;
      }
    }

    if(foundIndex>=0){}else{ 
      // transactionRefundList.splice(foundIndex, 1);
      // this.setState({ ["transactionRefundList"]: transactionRefundList }); 
      this.setState({ ["transactiontype"]: "0" }); 
      console.log("end-WATEEEER-evaluateTransactionType OK#8 ", foundIndex, transactiontype);
    }

    console.log("end-WATCHEEEEEEEEEEEER-evaluateTransactionType", foundIndex, transactiontype, transactionRefundList);

  };

 


  getXRecordObjects2 = (myArr, fieldName, fieldValue, oneObject) => {
    let fieldBlock=[]; let fieldBlockOne=null; 
    let promList = myArr.length > 0
      && myArr.map((item, i) => {  
      //let fieldBlock=null;       
        if(item[fieldName]===fieldValue){
          if(oneObject){
            fieldBlockOne=item; 
          }else{
            fieldBlock.push(item);
          }
          //fieldBlock=item; 
        }       

      // if(item.product_type_id===fieldValue){
      //   fieldBlock=item; 
      // }
      
      return ( null );
    }, this);
    
      //return fieldBlock;
      if(oneObject){
        return fieldBlockOne; 
      }else{
        return fieldBlock;
      }
  }

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  render() {
    const { t } = this.props;
    //const NeoPayments = this.props.NeoPayments;
    const NeoPayments = this.state; 
    const open = this.props.openx; 

    const globalStepWorkflow=this.state.globalStepWorkflow;
    const myToastMessage=this.state.toastrMessage;
    const toastrType=this.state.toastrType;
    const Alert=this.Alert;

    const invoices=this.props.invoices;
    const payments=this.props.payments;
    const refunds=this.props.refunds;
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;
    const PaymentRefundList=this.PaymentRefundList;
    const TransactionDetailedRefundList=this.TransactionDetailedRefundList;
    const disabledBtn=this.state.disabledBtn;
    const refundValList=this.state.refundValList;
    const refundList=this.state.refundList;
    //refundedAmount
    const refundedValue=this.state.refundedInvValue;  
    const paymentValue=this.state.refundedInvPayVal;  
    const paymentValueWTax=this.state.refundedInvPayVal2; 
    const refundedInvValue2=this.state.refundedInvValue2; //sansTaxe 
    let disabledBtn2=false;
    if(refundedInvValue2 >=paymentValue){
      disabledBtn2=true;
    }
    console.log("ZzzzzzEEEEEEEEEEELL refundedValue >=paymentValue, disabledBtn2 ", refundedValue,paymentValue,refundedInvValue2,   disabledBtn2, payments);
    console.log("ZzzzzzEEEEEEEEEEELL refundedValue >=paymentValue, disabledBtn2 ", refundedValue,paymentValue,refundedInvValue2,   disabledBtn2, paymentValueWTax);

    const taxfreeAmounts=this.props.taxfreeAmounts?this.props.taxfreeAmounts:[];
    let totTaxFree=0; let listTaxFree="";
    console.log("WEEEEEEEEEEEEEEEEEEELL taxfreeAmounts ", taxfreeAmounts);
    for(const taxfreeAmount of taxfreeAmounts){
      totTaxFree +=taxfreeAmount; 
      listTaxFree +="$"+taxfreeAmount+" ";
    }

    const amount=this.state.amount;  
    const nontaxableamount=this.state.nontaxableamount;  
    //const amountretention=this.state.amountretention;  
    const observation=this.state.observation;  
    const refundtypefx=this.state.refundtype; 
    const totalToRefund=parseFloat(""+amount) + parseFloat(""+nontaxableamount); 
 

    const lang= t('curlang');
    let refundtype=""; 
    //let transactiontype="0"; //0 none, 1:old, 2: new with breakdown
    const transactiontype=this.state.transactiontype;
    if(refundList[0] && refundList[0].refundtype){
      refundtype=refundList[0].refundtype;  
    }

    if(refundtype!==null && refundtype!=="" && refundtype==="retention"){
      if(refundedValue >=paymentValueWTax){
        disabledBtn2=true;
      }else{
        disabledBtn2=false;
      }
    }

    console.log("invoices-invoices", this.props.invoices);


    const transacRefundList=[];
    const mainTransactions=this.props.mainTransactions;
    const transRefBreakdownKeyList=this.state.transRefBreakdownKeyList;
    const transactionRefundList=this.state.transactionRefundList;
    const amountretention=this.state.amountretention; 
    //const invoiceId=this.props.invoiceId;
    //const paymentId=this.props.paymentId;
    if(mainTransactions!==undefined && mainTransactions.length > 0){
      let myIndz=-1;
      for(const transx of mainTransactions){
        //const preachat=JSON.parse(transx.preachat);
        //readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false}
        if(transx.id_invoices===invoiceId){    
          myIndz++;
          let transListx=[];
          const preachat=JSON.parse(transx.preachat); //neoprod_breakdown
          const objtransShow=this.getXRecordObjects2(transactionRefundList, "id", transx.id, true);
          let doesTransHasMultiple=false;
          if(objtransShow!==null && objtransShow.id!==undefined 
            && objtransShow.show!==undefined && objtransShow.multiple===true){
            doesTransHasMultiple=true;
          }
          
          if(doesTransHasMultiple && preachat.neoprod_breakdown && preachat.neoprod_breakdown.invoice!==undefined && preachat.neoprod_breakdown.invoice.length > 0){
            for(const invRow of preachat.neoprod_breakdown.invoice){
              let labelx=invRow.fr;
              let maxamount0=preachat.amounts.serviceFee;
              if(maxamount0===undefined){ maxamount0=preachat.amounts.subtotal; }
              const maxamount=((maxamount0*invRow.percentage)/100).toFixed(2);
              const mixkey=transx.id+"_"+invRow.key;
              let refundedKeyAmnt=0;
              for(const keyRefx of transRefBreakdownKeyList){
                if(keyRefx.mixkey===mixkey){
                  refundedKeyAmnt=keyRefx.total;
                  break;
                }
              }

              //getSubKeyValue = (transactionId, key)  id="subamount" defaultValue={0}  
              const subkeyval=this.getSubKeyValue(transx.id, invRow.key);
              transListx.push (
                <tr key={"subtr"+invRow.key}>
                  <td>{labelx+" "+invRow.percentage}%</td>
                  <td>
                  <input type="number" name="subamount" id="subamount" 
                   value={subkeyval}  
              min="0" max={maxamount} step="0.1" 
              onChange={this.handleChangeRefundsub} onBlur={this.handleFocusoutsub}  
              data-index={invRow.key} data-transid={transx.id}  data-max={maxamount} 
              data-refunded={refundedKeyAmnt}  data-nontaxableamount={invRow.key}  
                    className='form-control' placeholder={""} 
                    readOnly={(refundedKeyAmnt>=maxamount)?true:false} 
                    />
                  </td>
                  <td>({refundedKeyAmnt>0?<span className='red'>{refundedKeyAmnt}</span>:"0"})/{maxamount}</td>
                </tr>
              ); 
            }
          }else {
            //
            if(objtransShow!==null && objtransShow.id!==undefined){
            const singleValue=objtransShow.singleValue; 
            if(singleValue!==undefined){
              let maxamount0=preachat.amounts.serviceFee;
              if(maxamount0===undefined){ maxamount0=preachat.amounts.subtotal; }
              const maxamount=((maxamount0*1)).toFixed(2);
              const subkeyval=this.getSubKeyValue(transx.id, "0");
              transListx.push (
                <tr key={"subtr2x"+transx.id}>
                  <td>{"Montant"}</td>
                  <td>
                  <input type="number" name="subamount" id="subamount" 
                   value={subkeyval}  
              min="0" max={maxamount} step="0.1"  data-singlevalue={"yes"} 
              onChange={this.handleChangeRefundsub} onBlur={this.handleFocusoutsub}  
              data-index={"-9"} data-transid={transx.id}  data-max={maxamount} 
              data-refunded={refundedValue}  data-nontaxableamount={"0"}  
                    className='form-control' placeholder={""} 
                    //readOnly={(refundedKeyAmnt>=maxamount)?true:false} 
                    />
                  </td>
                  <td>({refundedValue>0?<span className='red'>{refundedValue}</span>:"0"})/{maxamount}</td>
                </tr>
              );
            }
           }
          }

          //if(objtransShow!==null && objtransShow.id!==undefined && objtransShow.show!==undefined){
          //if(doesTransHasMultiple===true){
          transacRefundList.push(<div className="row"  key={"toptr-"+transx.id}>
            <div className="container">
            {(refundtype!==null && refundtype!=="")? 
            <div className="row">
          <div className="form-groupx rowx" key={"tra-"+transx.id}>
            <label htmlFor="refundtype">Transaction #</label> 
            <input type="button" onClick={this.handleChosenTransaction} className="btn btn-success" 
            data-transid={transx.id}  value={transx.id+" >"} disabled={refundtype!=="partiel"?true:false}  />                   
          </div></div>:"" }
          {/* <br/> &nbsp; <br/> &nbsp; <br/> */}
          {(refundtype!==null && refundtype!=="" && transactiontype!=="0" && 
          objtransShow!==null && objtransShow.id!==undefined && objtransShow.show!==undefined)?
          <div className="row">
          <div className="card card-primary card-outline">
          <div className="card-header">
          <h5 className="m-0">Transaction #{transx.id}
          <input type="button" onClick={this.handleRemoveTransaction} className="btn btn-danger floatr" 
            data-transid={transx.id}    value={" X "}  />
          </h5>           
          </div>
          <div className="card-body">
          {/* <h6 className="card-title">Special title treatment</h6> */}
          {/* <h6 className="card-title">{transx.description}</h6> */}
          <Alert severity="success"><h6 className="card-title">{transx.description}</h6></Alert>
          

          <div className="form-groupx">
            {/* <br/> &nbsp; <br/> &nbsp; <br/> */}
            {/* <input type="button" onClick={this.handleRemoveTransaction} className="btn btn-danger" 
            data-transid={transx.id}    value={" X "}  />   */}
               <table className="table table-striped">
                <tbody> 
                  {transListx}
                </tbody>
              </table>              
          </div>
          
          </div></div></div>
          :""
          }
          
          </div></div>);
          //}
        } 
      }
    }
     

    console.log("WEEEEEEEEEEEEEEEEEEELL KEY ", open);
 
    const openk=open;
    const paymentRefundList=[];
    let paymentxamount=0;
    let paymentxtotal=0;
    let paymentxrefValue=0;
    


    if(payments!==undefined && payments.length > 0){
      let myInd=-1;
      for(const paymentx of payments){
        if(paymentx.id_invoices===invoiceId && paymentx.id===paymentId){   //if(paymentx.id_invoices===invoiceId){
          myInd++;
          //const valx=refundedValue; //refundValList[myInd];
          const valx=refundValList[myInd];

          const baseElem=refundList[myInd]; 
          let refundedAmount="";
          if(baseElem!==undefined && baseElem!==null && baseElem.refundedAmount===undefined){
            refundedAmount=""+baseElem.refundedAmount;
          }

          paymentxamount=paymentx.amount;
          paymentxtotal=paymentx.total; 
          //paymentxrefValue=paymentx.refundedAmount;
          paymentxrefValue=refundedValue;

          //data-paymentid={paymentx.id}  data-max={paymentx.amount} 
          //data-refunded={paymentx.refundedAmount}  data-nontaxableamount={totTaxFree} 



          paymentRefundList.push(<div key={"rf-"+paymentx.id_invoices+"-"+paymentx.id+openk}>
          <div className="row"> 
          <div className="form-group" key={"f1-"+paymentx.id_invoices+"-"+paymentx.id}>
            <label htmlFor="id">Payment: #{paymentx.id}</label>  
          </div><br/><br/></div>
          <div className="row"> 
          <div className="form-group" key={"f2-"+paymentx.id_invoices+"-"+paymentx.id}>
            <label htmlFor="refundtype">Type Remboursement:</label> 
            <select name="refundtype"  className={'form-control'} data-index={myInd} 
            data-paymentid={paymentx.id}   data-max={paymentx.amount}  
                defaultValue={this.state.refundtype}  onChange={this.handleChangeRefund} > 
                <option value=""> Select/Choisir</option> 
                <option value="total"> Total </option> 
                <option value="partiel"> Partiel </option> 
                <option value="retention0"> Rétention </option> 
                <option value="retention"> Donner Crédit </option> 
            </select>     
          </div><br/><br/></div>  
          <div className="rowmmm"> 
          {transacRefundList}
          </div>
          {((refundtype!==null && refundtype!=="" && transactiontype!=="0") || refundtype==="total")?
          <> 
          <div className="row">
            <div style={{display:'block', width:'100%'}}><label>{t('zqwamount_x')} non taxable</label></div>
            <div className="form-row" key={"f03-"+paymentx.id_invoices+"-"+paymentx.id}>
              <div className="form-group col-md-6"> 
              <input type="number" name="nontaxableamount" id="nontaxableamount" defaultValue={0} 
              min="0" max={paymentx.amount} step="0.1" 
              onChange={this.handleChangeRefund} onBlur={this.handleFocusout}  
              data-index={myInd} data-paymentid={paymentx.id}  data-max={paymentx.amount} 
              data-refunded={paymentx.refundedAmount}  data-nontaxableamount={totTaxFree} 
              data-maxrefunded={refundedValue}
                    className='form-control' placeholder={"non taxable"} 
                  readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false}  />
              </div>   
              <div className="form-group col-md-6">/{totTaxFree.toFixed(2)} ({listTaxFree})  </div>          
            </div></div>
          <div className="row">
            <div style={{display:'block', width:'100%'}}><label>{t('zqwamount_x')} taxable</label></div>
            <div className="form-row" key={"f3-"+paymentx.id_invoices+"-"+paymentx.id}>
              {/* <label htmlFor="amount">{t('zqwamount_x')}</label> */}
              <div className="form-group col-md-6"> 
              <input type="number" name="amount" id="amount" defaultValue={0} 
              min="1" max={paymentx.amount} step="0.1"  ref={this.taxableAmountRef}
              onChange={this.handleChangeRefund} onBlur={this.handleFocusout}  
              data-index={myInd} data-paymentid={paymentx.id}  data-max={paymentx.amount} 
              data-refunded={paymentx.refundedAmount}  data-nontaxableamount={totTaxFree}  
                    className='form-control' placeholder={""} data-maxrefunded={refundedValue}
                  // readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false} 
                  readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount) || transactiontype==="2")?true:false} 
                  />
                  
              </div>
              <div className="form-group col-md-3">/{paymentx.amount}  </div>
                    Total avec taxes {paymentx.total}<br/>
                    Montant déja remboursé: {refundedValue}$ (avec taxes)
                     {/* {((baseElem===undefined || baseElem===null) && baseElem.refundedAmount===undefined)?'':baseElem.refundedAmount}  */}
            </div></div>
          <hr/>
          </>:"" 
           }
          </div>
        ); 
        } 
      }
    }

    /*let paymentRefundList = payments.length > 0
    	&& payments.map((item, i) => {
      //productOptions.push({ value: item.id, label: item.titre }); 
      if(item.id_invoices!==invoiceId){
        return null;
      } 
      return (
        <div className="form-group" style={{display:'none'}} >
			    <label htmlFor="id">{t('zqwid_x')}: #{NeoPayments.id}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} />  
        </div>
      );
	}, this);*/

  const credit2=this.props.credit;
  const credit3=this.state.clientTransInfo.credit; 
  let credit=null;
  if(credit3!==undefined){
    credit=credit3;
  }else{
    if(credit2!==undefined && credit2!==null && credit2.amount!==undefined){
      credit=credit2;
    }
  }

  const errorMessage=this.state.errorMessage;
  const creditwithtaxes=this.state.creditwithtaxes;
  const montant_tax1=this.state.montant_tax1;
  const montant_tax2=this.state.montant_tax2;
  //const amountretentiongo=this.state.montant_tax1;
  //const montant_tax1=this.state.montant_tax1;
  //amountretention  nontaxableamount  
  const amountretentiongo=parseFloat(""+amountretention) + parseFloat(""+nontaxableamount) + montant_tax1 + montant_tax2; 


  // montant_tax1: tax1, montant_tax2:tax2 

  return (
    <div> 
        <div className="text-center"> 
          <h5>Remboursement de Facture</h5>   
        </div>
      {/* <h2>NeoPayments</h2> {t("zqwPuptxt1_x")} */}
      <hr/>
      {(credit!==null && credit.amount!==undefined)?
        <div className="text-center"> 
          <Alert severity="info">
            <h6 className="card-title">{credit.clientName} ---  Montant crédit: <b>{credit.amount}$cad</b></h6>
          </Alert>
        </div>:""
      }
      {/* <Alert severity="primary"><h6 className="card-title">{transx.description}</h6></Alert> */}
      <div className="form-group">
        <label htmlFor="name">Facture: #{invoiceId}</label>  
      </div>
      <hr/>
      {/* <div className="form-group" style={{display:'none'}} >
        <label htmlFor="id">{t('zqwid_x')}: #{NeoPayments.id}</label>
          <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
            className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} />  
        </div> */}        
      <div className="container">{paymentRefundList}</div>
      {((refundtype!==null && refundtype!=="" && transactiontype!=="0")  || refundtype==="total")?
          <>  
      <div className="form-row">
        <label htmlFor="observation">Observation</label>
        <textarea id="observation" name="observation" rows="4" cols="50" defaultValue={this.state.observation} 
        maxLength={250} 
        onChange={this.handleChange} onBlur={this.handleFocusout} className={'form-control'}></textarea>
        {/* <input type="text" name="observation" id="observation" defaultValue={this.state.observation} onChange={this.handleChange} onBlur={this.handleFocusout}
            className={'form-control'} placeholder={"observation..."} />   */}
      </div>
      <div className="form-row">
        <label>Specimen cheque</label> 
      </div>
      <div className="form-row">
        {/* <label>Specimen cheque</label><br/>&nbsp;<br/> */}
        <input type="file" id="clientspecimen" name="clientspecimen"  onChange={this.onFileChange}></input>
      </div>
      <p> &nbsp; </p>
            
      <div className="form-row">
         <label>Total  <span className="red"><b>{totalToRefund.toFixed(2)}</b></span>
         (plus taxes)</label>  
      </div>

      <div className="btn-group">
        {/* <Link to={{ pathname: `/NeoPayments/${NeoPayments.id}/edit`, state: { NeoPayments: NeoPayments } }} className='btn btn-info'>  
          {t('Edit')}
        </Link> */}

        {/* <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNeoPayments(NeoPayments.id)}>          
          {t('Delete')}
        </button> disabled={disabledBtn}  */}

        <button className="btn btn-danger" type="button"  onClick={this.handleApplyRefund} disabled={disabledBtn2?disabledBtn2:disabledBtn}>          
          Appliquer Remboursement X
        </button>

      {(errorMessage!=="")?
        <><br/>
        <Alert severity="error">{errorMessage}</Alert> </>:""
      }  

        {/* <button className="btn btn-danger" type="button"  onClick={this.handleApplyRetention}>          
          Appliquer Rétention
        </button> */}
        
        {/* <Link to="/NeoPayments" className="btn btn-secondary">{t('Close')}</Link>                                                  */}
      </div>
      </>:
      <>
      {(refundtype!==null && refundtype!=="" && refundtype==="retention")?
      <>
      <div className="row">
        <div className="col-md-6">
          <div className="radio">
            <label>
              <input type="radio" name="creditwithtaxes" value="credittaxes" 
                            checked={creditwithtaxes === 'credittaxes'} 
                            onChange={this.handleOptionChange} />
              Ajouter crédit sans taxes
            </label>
          </div>
        </div>
      <div className="col-md-6">
        <div className="radio">
            <label>
              <input type="radio" name="creditwithtaxes" value="creditallinone" 
                            checked={creditwithtaxes === 'creditallinone'} 
                            onChange={this.handleOptionChange} />
              Ajouter crédit tout inclus (taxes et montant non taxable)
            </label>
        </div>
      </div>

        {/* <div className="form-row">
            <label htmlFor="observation">Observation</label>
            <textarea id="observation" name="observation" rows="4" cols="50" defaultValue={this.state.observation} 
            maxLength={250} 
            onChange={this.handleChange} onBlur={this.handleFocusout} className={'form-control'}></textarea>
          </div> */}
      </div>

      {(creditwithtaxes === 'credittaxes')?
      <>
        <div className="row">
            <div style={{display:'block', width:'100%'}}><label>{t('zqwamount_x')} non taxable</label></div>
            <div className="form-row" key={"badf03-14520002-87022000"}>
              <div className="form-group col-md-6">
              <input type="number" name="nontaxableamount" id="nontaxableamount" value={nontaxableamount}
            //defaultValue={0} 
            min="1" max={paymentxtotal} step="0.1" 
            // ref={this.taxableAmountRef}  
            
            onChange={this.handleChangeRefundret} onBlur={this.handleChangeRefundret}  
            data-index={0} data-paymentid={0}  data-maxwithtax={paymentxtotal} 
            data-paymentamount={paymentxamount} 
            data-refunded={paymentxrefValue}  data-nontaxableamount={totTaxFree}  
                  className='form-control' placeholder={""} data-maxrefunded={paymentxrefValue}
                  //readOnly={true}
                // readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false} 
                //readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount) || transactiontype==="2")?true:false} 
                />

              {/* <input type="number" name="nontaxableamount" id="nontaxableamount" defaultValue={0} 
              min="0" max={paymentx.amount} step="0.1" 
              onChange={this.handleChangeRefund} onBlur={this.handleFocusout}  
              data-index={myInd} data-paymentid={paymentx.id}  data-max={paymentx.amount} 
              data-refunded={paymentx.refundedAmount}  data-nontaxableamount={totTaxFree} 
              data-maxrefunded={refundedValue}
                    className='form-control' placeholder={"non taxable"} 
                  readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false}  /> */}
              </div>   
              <div className="form-group col-md-6">/{totTaxFree.toFixed(2)} ({listTaxFree})  </div>          
            </div></div>
{/*             
          <div className="row">
            <div style={{display:'block', width:'100%'}}><label>{t('zqwamount_x')} taxable</label></div>
            <div className="form-row" key={"f3-"+paymentx.id_invoices+"-"+paymentx.id}> 
              <div className="form-group col-md-6"> 
              <input type="number" name="amount" id="amount" defaultValue={0} 
              min="1" max={paymentx.amount} step="0.1"  ref={this.taxableAmountRef}
              onChange={this.handleChangeRefund} onBlur={this.handleFocusout}  
              data-index={myInd} data-paymentid={paymentx.id}  data-max={paymentx.amount} 
              data-refunded={paymentx.refundedAmount}  data-nontaxableamount={totTaxFree}  
                    className='form-control' placeholder={""} data-maxrefunded={refundedValue}
                  // readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false} 
                  readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount) || transactiontype==="2")?true:false} 
                  />
                  
              </div>
              <div className="form-group col-md-3">/{paymentx.amount}  </div>
                    Total avec taxes {paymentx.total}<br/>
                    Montant déja remboursé: {refundedValue}$ (avec taxes) 
            </div></div> */}

        <div className="row"><br/>&nbsp;<br/>
          <div style={{display:'block', width:'100%'}}><label>{t('zqwamount_x')} taxable
          {/* de Crédit */}
          </label></div>
          <div className="form-row" key={"mrf3-"+88888888000}> 
            <div className="form-group col-md-6"> 
            <input type="number" name="amountretention" id="amountretention" value={amountretention}
            //defaultValue={0} 
            min="1" max={paymentxtotal} step="0.1" 
            // ref={this.taxableAmountRef}  
            
            onChange={this.handleChangeRefundret} onBlur={this.handleChangeRefundret}  
            data-index={0} data-paymentid={0}  data-maxwithtax={paymentxtotal} 
            data-paymentamount={paymentxamount} 
            data-refunded={paymentxrefValue}  data-nontaxableamount={totTaxFree}  
                  className='form-control' placeholder={""} data-maxrefunded={paymentxrefValue}
                  //readOnly={true}
                // readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false} 
                //readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount) || transactiontype==="2")?true:false} 
                /> 
                
            </div>
            <div className="form-group col-md-3">/{paymentxtotal} (avec taxes) </div>

            <div className="form-group col-md-6">
              Taxes: {(montant_tax1+montant_tax2).toFixed(2)}<br/>
              Total avec Taxes: {amountretentiongo.toFixed(2)}<br/>
            </div><br/><br/> 

                  Total avec taxes {paymentxtotal}<br/>
                  Montant déjà remboursé: {paymentxrefValue}$ (avec taxes)
                  {/* {((baseElem===undefined || baseElem===null) && baseElem.refundedAmount===undefined)?'':baseElem.refundedAmount}  */}
          </div>      
        </div>
      </>:
      <div className="row"><br/>&nbsp;<br/>
      <div style={{display:'block', width:'100%'}}><label>{t('zqwamount_x')} de Crédit</label></div>
      <div className="form-row" key={"mrf3-"+88888888}> 
        <div className="form-group col-md-6"> 
        <input type="number" name="amountretention" id="amountretention" value={amountretention}
        //defaultValue={0} 
        min="1" max={paymentxtotal} step="0.1" 
        // ref={this.taxableAmountRef}  
        
        onChange={this.handleChangeRefundret} onBlur={this.handleChangeRefundret}  
        data-index={0} data-paymentid={0}  data-maxwithtax={paymentxtotal} 
        data-paymentamount={paymentxamount} 
        data-refunded={paymentxrefValue}  data-nontaxableamount={totTaxFree}  
              className='form-control' placeholder={""} data-maxrefunded={paymentxrefValue}
            // readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false} 
            //readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount) || transactiontype==="2")?true:false} 
            /> 
            
        </div>        
        <div className="form-group col-md-3">/{paymentxtotal} (avec taxes) </div>
        
        {/* <div className="form-group col-md-6">
          Taxes:{(montant_tax1+montant_tax2).toFixed(2)}<br/>
          Total avec Taxes:{amountretentiongo.toFixed(2)}<br/>
        </div><br/><br/>  */}
              Total avec taxes {paymentxtotal}<br/>
              Montant déjà remboursé: {paymentxrefValue}$ (avec taxes)
               {/* {((baseElem===undefined || baseElem===null) && baseElem.refundedAmount===undefined)?'':baseElem.refundedAmount}  */}
      </div>      
      </div>
      } 

      <div className="row">
      <div className="form-row">
          <label htmlFor="observation">Observation</label>
          <textarea id="observation" name="observation" rows="4" cols="50" defaultValue={this.state.observation} 
          maxLength={250} 
          onChange={this.handleChange} onBlur={this.handleFocusout} className={'form-control'}></textarea>
        </div>
      </div>

      <p><button className="btn btn-danger floatr" type="button"  onClick={this.handleApplyRetention} 
      disabled={disabledBtn2?disabledBtn2:disabledBtn} data-retention="credit">          
          Donner Crédit au Client
        </button><br/>&nbsp;
        {(errorMessage!=="")?
        <><br/>
        <Alert severity="error">{errorMessage}</Alert> </>:""
      }    
        </p></>:""  
      }
      </>
       }

      {(refundtype!==null && refundtype!=="" && refundtype==="retention0")?
      <>
      <p><button className="btn btn-danger floatr" type="button"  onClick={this.handleApplyRetention} 
      // disabled={disabledBtn2?disabledBtn2:disabledBtn} 
      data-retention="retention">          
          Appliquer Rétention
        </button><br/>&nbsp;
        {(errorMessage!=="")?
        <><br/>
        <Alert severity="error">{errorMessage}</Alert> </>:""
      }    
        </p>
      </>:""
      }
       
      <hr/>
      <PaymentRefundList /><br/>&nbsp;<br/>
      <TransactionDetailedRefundList />
      

      <div className="neoforcepopup">
      <div className="form-row neoxtbl">	 
        <div className="form-group col-md-9"> 
          <input type="button" onClick={this.closeFolderProductPopup} className="btn btn-success" value={t('zqwTNVFtxt20_x')}  /> &nbsp; &nbsp; 
        </div> 
      </div> 
      </div> 
  <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
  <Loader  openx={this.state.isLoaderOpen}  />
    </div>
  );


    return (
      <div>
        <Dialog maxWidth={"lg"} open={open} onClose={this.handleCloseTransactionPopup} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-productFolder">
          <DialogContent id="form-dialog-productFolder">
          <div className="text-center"> 
            <h5>Remboursement de Facture/Refund of Invoice</h5>   
          </div>
        {/* <h2>NeoPayments</h2> {t("zqwPuptxt1_x")} */}
        <hr/>
        <div className="form-group">
          <label htmlFor="name">Facture: #{invoiceId}</label>  
        </div>
        <hr/>
        {/* <div className="form-group" style={{display:'none'}} >
			    <label htmlFor="id">{t('zqwid_x')}: #{NeoPayments.id}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} />  
          </div> */}
        <div className="container">{paymentRefundList}</div>
        <div className="form-row">
          <label htmlFor="observation">Observation</label>
          <textarea id="observation" name="observation" rows="4" cols="50" defaultValue={this.state.observation} 
          maxLength={250} 
          onChange={this.handleChange} onBlur={this.handleFocusout} className={'form-control'}></textarea>
          {/* <input type="text" name="observation" id="observation" defaultValue={this.state.observation} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={'form-control'} placeholder={"observation..."} />   */}
        </div>
        <p> &nbsp; </p>
        
        {/* {paymentRefundList} */}
        {/* <p><label>{t('zqwid_x')}</label> &nbsp; &nbsp; #{NeoPayments.id}</p>
        <p><label>{t('zqwid_invoices_x')}</label><br/>{NeoPayments.id_invoices}</p>
        <p><label>{t('zqwamount_x')}</label><br/>{NeoPayments.amount}</p>
        <p><label>{t('zqwtax1_x')}</label><br/>{NeoPayments.tax1}</p>
        <p><label>{t('zqwtax2_x')}</label><br/>{NeoPayments.tax2}</p>
        <p><label>{t('zqwtoken_x')}</label><br/>{NeoPayments.token}</p>
        <p><label>{t('zqwemail_x')}</label><br/>{NeoPayments.email}</p>
        <p><label>{t('zqwresult_x')}</label><br/>{NeoPayments.result}</p>
        <p><label>{t('zqwcode_x')}</label><br/>{NeoPayments.code}</p>
        <p><label>{t('zqwdeclined_code_x')}</label><br/>{NeoPayments.declined_code}</p>
        <p><label>{t('zqwtransact_x')}</label><br/>{NeoPayments.transact}</p>
        <p><label>{t('zqwcreated_x')}</label><br/>{NeoPayments.created}</p>
        <hr/> */}
        <div className="btn-group">
          {/* <Link to={{ pathname: `/NeoPayments/${NeoPayments.id}/edit`, state: { NeoPayments: NeoPayments } }} className='btn btn-info'>  
            {t('Edit')}
          </Link> */}

          {/* <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNeoPayments(NeoPayments.id)}>          
            {t('Delete')}
          </button> disabled={disabledBtn}  */}

          <button className="btn btn-danger" type="button"  onClick={this.handleApplyRefund} disabled={disabledBtn2?disabledBtn2:disabledBtn}>          
            Appliquer Remboursement X2
          </button>
          {/* <Link to="/NeoPayments" className="btn btn-secondary">{t('Close')}</Link>                                                  */}
        </div>
        <hr/>
        <PaymentRefundList />
         
        <div className="form-row neoxtbl">	 
			    <div className="form-group col-md-9"> 
            <input type="button" onClick={this.closeFolderProductPopup} className="btn btn-success" value={t('zqwTNVFtxt20_x')}  /> &nbsp; &nbsp; 
          </div> 
        </div>
              
          </DialogContent>
          
        </Dialog>
		<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
		<Loader  openx={this.state.isLoaderOpen}  />
      </div>
    )
  }

  onFileChange = event => { 
    // Update the state 
    //console.log("weeeeeeeeeeeeeeeee file change "+event.target.files[0].name);
    //this.setState({ ["clientspecimen"]: event.target.files[0] }); 
    if(event.target.files[0]===undefined || event.target.files[0].name===undefined){
      this.setState({ ["clientspecimen"]: null }); 
    }else{
      this.setState({ ["clientspecimen"]: event.target.files[0] }); 
    }
  }; 


  handleOptionChange = event => {  
    //this.setState({ [event.target.name]: event.target.value });
    this.setState({ [event.target.name]: event.target.value,
      amountretention:0, nontaxableamount:0, montant_tax1:0, montant_tax2:0,});

    //const creditwithtaxes=this.state.creditwithtaxes; 
    console.log("handleOptionChange WATCHEEEEEEEEEEEER-CONGRATS", event.target.name, 
    event.target.value, " amountretention:0, nontaxableamount:0, montant_tax1:0, montant_tax2:0" );
  

    // this.setState({
    //   selectedOption: event.target.value
    // });
  };


  array_column =(input, ColumnKey, IndexKey) =>  {  
    //   discuss at: https://locutus.io/php/array_column/
    if (input !== null && (typeof input === 'object' || Array.isArray(input))) {
      const newarray = []
      if (typeof input === 'object') {
        const temparray = []
        for (const key of Object.keys(input)) {
          temparray.push(input[key])
        }
        input = temparray
      }
      if (Array.isArray(input)) {
        for (const key of input.keys()) {
          if (IndexKey && input[key][IndexKey]) {
            if (ColumnKey) {
              newarray[input[key][IndexKey]] = input[key][ColumnKey]
            } else {
              newarray[input[key][IndexKey]] = input[key]
            }
          } else {
            if (ColumnKey) {
              newarray.push(input[key][ColumnKey])
            } else {
              newarray.push(input[key])
            }
          }
        }
      }
      return Object.assign({}, newarray)
    }
  }

  PaymentRefundList = (props) => {
     const refunds=this.state.refunds;
    // const refundsList=this.state.refundList;
    //const refunds=this.state.refundList;
    const paymentId=this.props.paymentId;
    if(refunds.length===0){
      return null;
    }

    let refundlistx = refunds.length > 0
    	&& refunds.map((item, i) => { 
        //console.log(item);
        if(item!==null && paymentId===item.id_payments){
          let refund_type="Remboursement";
          if(item.refund_type==="R"){ refund_type="Crédit"; }
          else if(item.refund_type==="C"){ refund_type="Retenue Client"; }else{
            refund_type +="/"+item.refund_type;
          }

          return(
            <tr key={"r"+item.creation_date}>
              <td>{item.id}</td>
              <td>{item.amount}</td>
              {/* <td>{(item.refund_type==="R"&&item.status==="3")?
              <input type="button" data-refcancelled={"yes"} data-refid={item.id} data-refamount={item.amount} 
              onClick={this.handleApplyRetention} className="btn btn-warning" value={item.refund_type}  />
              :item.refund_type}</td> */}
              <td>{(item.refund_type==="R"&&item.status==="3")?
              <input type="button" data-refcancelled={"yes"} data-refid={item.id} data-refamount={item.amount} 
              onClick={this.handleApplyRetention} className="btn btn-warning" value={refund_type}  />
              :refund_type}</td>
              <td>{item.creation_date}</td>
              <td>{item.taxable}</td>
              <td>{item.nontaxable}</td>
              <td>{item.usertxt}</td>
            </tr>
          ); 
        }
      return (
        null
      );
    }, this);

    console.log("refunds-refunds-refunds", refunds);

    return(<div className="container">
    <h2>Liste de Remboursement</h2>
    {/* <p>The .table-striped class adds zebra-stripes to a table:</p>             */}
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Id</th>
          <th>Sous-total</th>
          <th>Type</th>
          <th>Date</th>
          <th>Montant taxable</th>
          <th>Montant non taxable</th>
          <th>Utilisateur</th>
        </tr>
      </thead>
      <tbody> 
        {refundlistx}
      </tbody>
    </table>
  </div>);

  }

  

  TransactionDetailedRefundList = (props) => {
        const refunds=this.state.refunds;
        const transRefBreakdowns=this.props.transRefBreakdown;
       // const refundsList=this.state.refundList;
       //const refunds=this.state.refundList;
       const paymentId=this.props.paymentId;
       if(transRefBreakdowns.length===0){
         return null;
       }

       let refundlistx = [];
      //const transRefBreakdownKeyList=this.state.transRefBreakdownKeyList;
      //const transRefBreakdowns=this.props.transRefBreakdown;
      if(transRefBreakdowns &&  transRefBreakdowns.length > 0){
        console.log("strt-WATEEEER-transRefBreakdowns ", transRefBreakdowns);
      for(const transRefx of transRefBreakdowns){
        //fqtyO++;
        if(transRefx.id_payments===paymentId){   //if(transRefx.id_transactions===transid){   
          const details=JSON.parse(transRefx.details); //neoprod_breakdown refundList
          for(const detailx of details.refundList){            
            refundlistx.push(
              <tr key={"tdrl"+transRefx.id+detailx.key+""+transRefx.creation_date}>
                 <td>{transRefx.id}</td>
                 <td>{transRefx.id_transactions}</td>
                 <td>{detailx.value}</td>
                 <td>{transRefx.creation_date}</td>
                 <td>{detailx.fr}</td>
                 <td>{detailx.en}</td>
                 <td>{detailx.percentage}</td>
               </tr>
            );
          }
        }
      }

        //this.setState({ ["transRefBreakdownKeyList"]: transRefBreakdownKeyList }); 
        //console.log("end-WATEEEER-transRefBreakdownKeyList ", transRefBreakdownKeyList);
      }   
         
       return(<div className="container">
       <h2>Liste de Détails de Remboursement</h2>
       {/* <p>The .table-striped class adds zebra-stripes to a table:</p>             */}
       <table className="table table-striped">
         <thead>
           <tr>
             <th>Id</th>
             <th>#Trans</th>
             <th>Montant</th>
             <th>Date</th>
             <th>Français</th>
             <th>Anglais</th>
             <th>Pourcentage</th>
           </tr>
         </thead>
         <tbody> 
           {refundlistx}
         </tbody>
       </table>
     </div>);
   
  }


}


const mapStateToProps = (state) => ({ NeoProducts: state.NeoProducts }); 
//const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes }); 
//const mapDispatchToProps = { addOrUpdateNeoProducts, getNeoProducts, getNeoProductsAll, getNeoProductsGoto, deleteNeoProducts };   
const mapDispatchToProps = { addOrUpdateNeoVentes, addNeoVentesSC, addOrUpdateRefunds };           
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoRefundPayment));
