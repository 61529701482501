import React, { Component, useEffect } from 'react';
import { APP_PATHNAME } from './appConfig'; 
import {Router, Route, NavLink, Switch, Redirect, useRouteMatch, useLocation, withRouter } from 'react-router-dom';
import history from './history';
import { encryptText, decryptText } from './appEncryption';

import { BaseHeader } from "./BaseHeader"; 
import { BaseLeftPanel } from "./BaseLeftPanel"; 
import { BaseFooter } from "./BaseFooter"; 

import { BaseHeader2 } from "./BaseHeader2";
import { BaseLeftPanel2 } from "./BaseLeftPanel2";  

import ZAFClient from 'zendesk_app_framework_sdk';

//////////// 
//import { Switch, Route } from 'react-router-dom';
//import {connect} from 'react-redux';
import {connect, useSelector} from 'react-redux';
import {getProfileFetch, logoutUser, getLoggedUser, setLoggedUser} from './redux/actions';
import Signup from './Signup';
import Login from './Login';
import LoginRefresh from './LoginRefresh';

import VwBoard  from './components/vwfacturas/VwBoard';
import { getDailyGoalSale } from './actions/NeoSalesPlanningDailyAction';

import { withTranslation } from 'react-i18next';


import NdiFieldsList from './components/ndifields/NdiFieldsList';
import NdiFieldsInfo from './components/ndifields/NdiFieldsInfo'; 
import NdiFieldsForm from './components/ndifields/NdiFieldsForm'; 
import NdiFieldsFormzendesk from './components/ndifields/NdiFieldsFormzendesk'; 
import NdiFieldsMiniBoardzendesk from './components/ndifields/NdiFieldsMiniBoardzendesk'; 

import NeoB2bClientsList from './components/neob2bclients/NeoB2bClientsList';
import NeoB2bClientsInfo from './components/neob2bclients/NeoB2bClientsInfo'; 
import NeoB2bClientsForm from './components/neob2bclients/NeoB2bClientsForm';
import NeoVentesForm from './components/neob2bclients/NeoVentesForm'; 

import NeoVentesBasicPostActionsta from './components/neoventes/NeoVentesBasicPostActionsta';  

import NeoPaymentsCreditsList from './components/neopaymentscredits/NeoPaymentsCreditsList';
import NeoPaymentsCreditsInfo from './components/neopaymentscredits/NeoPaymentsCreditsInfo'; 
import NeoPaymentsCreditsForm from './components/neopaymentscredits/NeoPaymentsCreditsForm';  

import NeoPaymentsCreditsOperList from './components/neopaymentscreditsoper/NeoPaymentsCreditsOperList';
import NeoPaymentsCreditsOperInfo from './components/neopaymentscreditsoper/NeoPaymentsCreditsOperInfo'; 
import NeoPaymentsCreditsOperForm from './components/neopaymentscreditsoper/NeoPaymentsCreditsOperForm';  

import NeoPaymentsRefundList from './components/neopaymentsrefund/NeoPaymentsRefundList';
import NeoPaymentsRefundInfo from './components/neopaymentsrefund/NeoPaymentsRefundInfo'; 
import NeoPaymentsRefundForm from './components/neopaymentsrefund/NeoPaymentsRefundForm';

 
let zafClient = ZAFClient.init();  let loggedUserProps=null;
class App extends Component { 
  componentDidMount = () => {
	//this.props.getProfileFetch(); 
		//this.props.getDailyGoalSale();
		//console.log("PROPS WATCHER...", this.props.currentUser);


		window.addEventListener("message", (event) => {
			// Do we trust the sender of this message?  
			if (event.origin !== "http://localhost:3000" && event.origin !=="https://sales.neolegal.ca" 
			&& event.origin !=="https://admin.neolegal.ca"){
			   console.log("mmmm from neo reactjs rejected ones...", event.origin, );
			   //console.log(event.data);
			 //console.log("NDIIIIIIII-------mmmm from salesIFrame fall in rejection... ", event.origin );
			 //console.log(event.data);
			  return;
			}
	  
			//<br/>AUTOLOGIN IFRAME<br/>
			  console.log("NDIIIIIIII-------mmmm for zendesk 000007... ", event.origin );
			 console.log("NDIIIIIIII-------mmmm from salesIFrame COME FROM IFRAME COMMUNICATION... ", event.origin );
			 //console.log(event.data);

			 if (event.origin=== "http://localhost:3000" || event.origin==="https://sales.neolegal.ca" 
			|| event.origin==="https://admin.neolegal.ca"){
			  	console.log("NDIIIIIIII-------mmmm from salesIFrame fall in login-Attempt... ", event.origin );
			 	//console.log(event.data);  
			 	//const dataReceived=JSON.parse(event.data);
				const dataReceived=event.data;
				const dalog=localStorage.getItem("uLogTracking2");
				if(dataReceived.uLogTracking2!==undefined && dalog!==undefined 
					&& dataReceived.uLogTracking2!==dalog){
						let qtyDatakey=0;
						for (let [key, value] of Object.entries(dataReceived)) {
							//console.log(`wooooooooooooooooy ${key}: ${value}`);
							localStorage.setItem(key, value);
							qtyDatakey++;
						}
						if(qtyDatakey >0){
							window.parent.postMessage("infoLoggedinReceivedAndApplied", "*");
						}
						console.log("NDIIIIIIII-------mmmm from salesIFrame LOGIN KEYS... ", qtyDatakey);
					}else if(dataReceived.uLogTracking2!==undefined && dalog!==undefined 
						&& dataReceived.uLogTracking2===dalog){							
							console.log("NDIIIIIIII-------mmmm from salesIFrame ALREADY-LOOOOOOOGGEDIN... ");
							console.log("NDIIIIIIII-------mmmm from salesIFrame fall in login-Attempt..., ALREADY LOGGED IN ", event.origin );
							window.parent.postMessage("infoLoggedinReceivedAndApplied", "*");
					}
					else{ 
						//console.log("NDIIIIIIII-------mmmm from salesIFrame fall in login-Attempt..., ALREADY LOGGED IN ", event.origin );
						console.log("NDIIIIIIII-------mmmm from salesIFrame fall in login-Attempt..., UUUUUUUUUNKNOWN ", event.origin );
					}

			}
	  
	  
			  //const dataReceived=JSON.parse(event.data);
			  console.log("from reactjs rceived one...");
			  //console.log("receiving ", event.data);
			//   moment.locale('en'); 
			// //console.log("profile-profile :::::", ticket_id, "backTicketId=", backTicketId, client_id);
					  
			// event.source is popup
			// event.data is "hi there yourself!  the secret response is: rheeeeet!"
		}, false);
	
  }
  componentWillUnmount = () => {
	this.props.getProfileFetch(); 
	//this.props.getDailyGoalSale();
	//console.log("WEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE componentWillUnmount");
  }

  componentDidUpdate(prevProps) {
		/*if (prevProps.openx !== this.props.openx) {
		//this.updateAndNotify();
		if(this.props.openx){
			//console.log("WEEEEEEEEEEEE MINIPAYMENT componentWillMount OPEN ", new Date());
			this.setState({ ["productCart"]: [],"productId":"",	 });  
			this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
			this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
			this.setState({ ["nowPayment"]:  0, ["paymentRound"]:  0 });
		}
		}*/

		if (prevProps.currentUser !== this.props.currentUser) {
			loggedUserProps=this.props.currentUser;
		}

		//console.log("2222PROPS WATCHER...", this.props.currentUser);
	}

 
 
  render() {
	  //console.log(".......///..check authentication "+this.props.LoggedUser);
	  //console.log(".......///..check STOOOOORE "+this.props.store);
	 //const MyAppMain = withTranslation('common')(AppMain); 
	 //const MyAppMain = withTranslation('common')(AppMain); 
	 const MyAppMain = (AppMain); 
	 //const { pathname } = useLocation();
	 //const { router, params, location, routes, pathname } = this.props;
	 //const pathname = this.props.location.pathname; //this.props.location;
	//  const { match, location, history } = this.props;
	//  const mypathname =location.pathname; //this.props.location.path;
	const path =APP_PATHNAME; //document.URL.substr(0,document.URL.lastIndexOf('/'));

	//console.log("WEEEEEEEEEEEEEEEEEEEEE "+mypathname);  //loggedUser  LoggedUser
	//console.log("TEEEEEEEEEESTPROPS", this.props)
	let appInsideZendesk=undefined;
	if((zafClient!==undefined && zafClient!==false)){
		appInsideZendesk="yes";
	}
	 
    return (
	<Router history={history}>
      <div>
        <Switch>
		  {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
		  {/* <Route exact path="/xcvbbb" component={Login} />
          <Route path="/signup" component={Signup}/>
          <Route path="/login" component={Login}/>
		  <PrivateRoute path='/protected'  component={MyAppMain} />
		  <PrivateRoute path='/'  component={MyAppMain} />   */}


		  <Route exact path={path+"xcvbbb"} component={Login} />
          <Route path={path+"signup"} component={Signup}/>
          <Route path={path+"login"} component={Login}/>
		  <Route path={path+"loginrefresh"} component={LoginRefresh}/>
		  <PrivateRoute path={path+"protected"}  component={MyAppMain} {...this.props} appInsideZendesk={appInsideZendesk}  />
		  <PrivateRoute path='/'  component={MyAppMain} {...this.props}  appInsideZendesk={appInsideZendesk}  />    

		  {/*{...this.props} <PrivateRoute path='/protected' render={(routeProps)=> (<MyAppMain {...routeProps} {...this.props} getDailyGoalSale={`getDailyGoalSale`} />)} />
		  <PrivateRoute path='/' render={(props)=> (<MyAppMain {...props} {...this.props} getDailyGoalSale={`getDailyGoalSale`} />)} />  
		   */}

        </Switch>
          {/*this.props.currentUser.username
            ? <button onClick={this.handleClick}>Log Out</button>
            : null*/
          }
		  
      </div>
	  </Router>
    );
  }
}


const PrivateRoute2 = ({ component: Component, ...rest }) => {
	////console.log("check authentication "+getLoggedUser());  LoggedUser
		
	//const user = useSelector(state => state.LoggedUser.currentUser);
	let isLoggedIn =false;
	let isAuthenticated=false;
	//useEffect to avoid the Error: Minified React error #185;
	//useEffect(() => {
   //setTimeout(() => {	  		
   
		  
   return (<Route {...rest} render={(props) => (
	  isAuthenticated === true
		? <Component {...props} />
		: <Redirect to={{
			pathname: APP_PATHNAME+'login',
			state: { from: props.location }
		  }} />
	)} />);	
  
 
	};

const PrivateRoute = ({ component: Component, ...rest }) => {
  ////console.log("check authentication "+getLoggedUser());  LoggedUser
  /*//console.log("loggedUser check   getProtectedThing "+ eval(getProtectedThing()) ); 
  //console.log("loggedUser check   yooooooooooooo "+ getLoggedUser );  getProtectedThing()
  //console.log("..check authentication "+getLoggedUser());*/
  //const state = getState();
  //const user = state.currentUser;
  //console.log("..PRIVATEROUTE check authentication############################# ");
  //const isLoggedIn = useSelector((state: IApplicationState) => state.auth.isLoggedIn);
  //props.currentUser.isAuthenticated === true 

  
  const user = useSelector(state => state.LoggedUser.currentUser);
  let isLoggedIn =false;
  let isAuthenticated=false;
  //useEffect to avoid the Error: Minified React error #185;
  //useEffect(() => {
 //setTimeout(() => {	  		

//   let isLoggedIn =false;
//   let isAuthenticated=false;
  const tokenExist = localStorage.token;
  const rawUserExist = localStorage.user;

  //const user = useSelector(state => state.LoggedUser.currentUser);
  //console.log("..check PrivateRoute "+rawUserExist+"  ===  "+tokenExist+"  ===  "+user);
  //if(user==undefined && user!==null){ 
   //if((tokenExist==undefined && tokenExist!==null) && (rawUserExist==undefined && rawUserExist!==null)){  //if(user==undefined || user!==null){  
   //const user = useSelector(state => state.LoggedUser.currentUser); 
   //const state = store.getState(); 
   //const user = state.currentUser;
   isLoggedIn = user.isAuthenticated;
   const currentUserx = user.currentUser; //user.currentUser;
   let userId = user.id;
   const token = decryptText(localStorage.token);
   const rawUser = decryptText(localStorage.user);
   
   if(isLoggedIn===undefined && userId===undefined && token!==undefined && token!==null){ 
	   const userStr=rawUser.split(":");
	   const rawUserx={id: userStr[1], name: userStr[2], role: userStr[3], isAuthenticated:true}; //rawUser; //JSON.parse(rawUser);
	   let neoState = {
			  currentUser: rawUserx,
			  id: userStr[1], name: userStr[2], role: userStr[3], 
			  isAuthenticated:true
			};
	  //console.log("RAAAAAAAAAAAAAW DATA "+rawUserx.isAuthenticated+" "+rawUserx.id+" "+rawUser);
	  if(rawUserx.id>0){
		  isLoggedIn = true;
		  userId = rawUserx.id;
		  setLoggedUser(neoState);
	  }
   }
  // let isAuthenticated=false;
  //console.log("..====== user  ");
  //console.log(user);
  //console.log("..====== raw user  ");
  //console.log(rawUser);
  //console.log("..end====== user is? "+isLoggedIn+" id= "+userId);
  if(isLoggedIn===true && userId>0 && token!==undefined && token!==null){
	  isAuthenticated=true;
  }
  //console.log("..====== IS user isAuthenticated? "+isAuthenticated);
//}
		
 /*return (<Route {...rest} render={(props) => (
    isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: APP_PATHNAME+'login',
          state: { from: props.location }
        }} />
  )} />);*/
  
  return (<Route {...rest} render={(props) => (
    isAuthenticated === true
      ? <Component {...props} isCompAuthenticated={true} />
      : <Component {...props}  />
  )} />);
   



//}, []); //useEffect

//return null;
//}, 100);	

		
  /*<Route {...rest} render={(props) => (
    getLoggedUser.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/Login',
          state: { from: props.location }
        }} />
  )} />*/
  };
  
function transformJSONStr(str){
	const x=str.split(",");
	let xq={};
	for(let k=0; k<x.length; k++){
		const kv=x[k].split(":");
		xq[kv[0]]=kv[1];
	}
	
	return xq;
}  

function  getProtectedThing() {
  return (dispatch, getState) => {
    // grab current state
    const state = getState();

    // get the JWT token out of it
    // (obviously depends on how your store is structured)
    const user = state.currentUser;
	
	return user;
 
  }
} 

class AppMain extends Component {
	_isMounted = false; //true;

	constructor(props) {
    super(props);
	//const { t } = this.props;
	//this.isLeftMenuOpen = true;
	this.state = {isLeftMenuOpen: false, isLeftMenuOpen2: false}; 
	//let {path, url} = useRouteMatch();
	//{`${path}/zxteams`}
	let path=this.props.location; //this.props.path;
	//console.log("AppMain WEEEEEEEEEEEEEEEEEEEEE ");console.log(path);//console.log("//AppMain WEEEEEEEEEEEEEEEEEEEEE ");

    //this.handleLeftPanelMenu = this.handleLeftPanelMenu.bind(this);
    ////this.handleSubmit = this.handleSubmit.bind(this);
	////this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
	this._isMounted = true; 
	//console.log("AppMain WEEEEEEEEEEEEEEEEEEEEE MOUNT ");
	//this.props.getDailyGoalSale();
	//const mx=getDailyGoalSale();

	/*const locurl=window.location.href; 
	let path=this.props.location; 
	const title=document.title;
	let urlParamZendesk=localStorage.getItem("zendeskzat"); 
	console.log("000MUUUUST weeeeeeeeeeee zeeeeendesk "+urlParamZendesk); 
	if(urlParamZendesk!==undefined){
		urlParamZendesk=urlParamZendesk+"&xqNeO=1";
		console.log("000repush MUUUUST weeeeeeeeeeee zeeeeendesk "+urlParamZendesk);  
		//history.push(`/zendesk/${urlParamZendesk}`);
		const new_URL=locurl+urlParamZendesk;
		window.history.replaceState( {} , title, new_URL ); 
	}*/
	
	// const new_URL=locurl+urlParamZendesk;
	// window.history.replaceState( {} , title, new_URL );

	//const locurl=window.location.href; 
	//console.log("TEEEEEEEEEST URL AAAAAAAAP2  ", zafClient, locurl);  
	   
		/*if(locurl.indexOf("?origin=")> -1 && locurl.indexOf("xqNeO=1")=== -1){
		  const index  = locurl.indexOf('?origin='); 
		  const strOut =locurl.substr(index);
		  const urlParamZendesk=strOut+"&xqNeO=1";
		  const urlParamZendesk2=locurl+"&xqNeO=1";
		  //localStorage.setItem("zendeskzat", strOut);
		  console.log("MUUUUST weeeeeeeeeeee zeeeeendesk "+urlParamZendesk);
		  //history.push(`./${urlParamZendesk}`); 
		  //history.replace(urlParamZendesk2);
		  
		  //history.push(`/zendesk/${urlParamZendesk2}`);
		  history.push(`/zendesk/${urlParamZendesk}`);    
		  history.go(); 
		}*/	

  }

  componentWillUnmount = () => {
	//this.props.getProfileFetch(); 
	//this.props.getDailyGoalSale();
	//console.log("WEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE componentWillUnmount AppMain");
  }
  
  handleLeftPanelMenu(event) {
	event.preventDefault();
	//this.setState(this.initialState);
	let valMenuToggle=true;
	if(this.state.isLeftMenuOpen){
      valMenuToggle=false;
    } else {
      valMenuToggle=true;
    }
	if (this._isMounted) {
		this.setState({
		isLeftMenuOpen: valMenuToggle
		});
		
		//console.log("TEEEEEEEEEEEEEEEEEEEEST TOGGLE ");
		//console.log(" LEFTMENU OPEN "+this.state.isLeftMenuOpen);
	}
	
  }
  
  handleLeftPanelMenu2(event) {
	//event.preventDefault(); 
	let valMenuToggle=true;
	if(this.state.isLeftMenuOpen2){
      valMenuToggle=false;
    } else {
      valMenuToggle=true;
    }
	if (this._isMounted) {
		this.setState({
		isLeftMenuOpen2: valMenuToggle
		});
		
		//console.log("+++temp TEEEEEEEEEEEEEEEEEEEEST TOGGLE "+valMenuToggle);
		//console.log("+++temp LEFTMENU OPEN "+this.state.isLeftMenuOpen2);
	}	
  }
  
  handleLeftPanelMenu2x(event) {
	//event.preventDefault(); 
	let valMenuToggle=false;
	/*if(this.state.isLeftMenuOpen2){
      valMenuToggle=false;
    } else {
      valMenuToggle=true;
    }*/
	if (this._isMounted) {
		this.setState({
		isLeftMenuOpen2: valMenuToggle
		});
		
		//console.log("+++CLOSEtemp TEEEEEEEEEEEEEEEEEEEEST TOGGLE "+valMenuToggle);
		//console.log("+++CLOSEtemp LEFTMENU OPEN "+this.state.isLeftMenuOpen2);
	}	
  }

  getParameterByName = (name) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  
  render() {
	const { t } = this.props;
	//const isCompAuthenticated=this.props.isCompAuthenticated;
	const isCompAuthenticated=this.props.isCompAuthenticated;
	let isNotReallyLoggedin=true; 
	//console.log("isCompAuthenticated isLoooooged  ", isCompAuthenticated);

	if(isCompAuthenticated===undefined){
		//const user = useSelector(state => state.LoggedUser.currentUser);
		//const state = store.getState(); 
		//const stateProps = this.props.LoggedUser; //store;
		const stateProps = loggedUserProps;
		//console.log("UUUUUUUUUUUUUUUUUUUU JUSTProps  ", this.props);
		//console.log("UUUUUUUUUUUUUUUUUUUU stateProps  ", stateProps);
	  if(stateProps!==undefined && stateProps!==null && stateProps.currentUser!==undefined && stateProps.currentUser!==null){
		const user = stateProps.currentUser;
		//let isLoggedIn =false;
		let isAuthenticated=false; 
		const tokenExist = localStorage.token;
		const rawUserExist = localStorage.user; 
		//const state = store.getState(); 
		//const user = state.currentUser;
		let isLoggedIn = user.isAuthenticated;
		const currentUserx = user.currentUser; //user.currentUser;
		let userId = user.id;
		const token = decryptText(localStorage.token);
		const rawUser = decryptText(localStorage.user);
		
		if(isLoggedIn===undefined && userId===undefined && token!==undefined && token!==null){ 
			const userStr=rawUser.split(":");
			const rawUserx={id: userStr[1], name: userStr[2], role: userStr[3], isAuthenticated:true}; //rawUser; //JSON.parse(rawUser);
			let neoState = {
					currentUser: rawUserx,
					id: userStr[1], name: userStr[2], role: userStr[3], 
					isAuthenticated:true
					};
			//console.log("RAAAAAAAAAAAAAW DATA "+rawUserx.isAuthenticated+" "+rawUserx.id+" "+rawUser);
			if(rawUserx.id>0){
				isLoggedIn = true;
				userId = rawUserx.id;
				//setLoggedUser(neoState);
			}else{ userId = "0"; }
			//console.log("UUUUUUUUUUUUUUUUUUUU toouuuuuugh  ");
		} 
		//console.log("..end====== user is? "+isLoggedIn+" id= "+userId);
		//console.log("UUUUUUUUUUUUUUUUUUUU bfooooooooooooore  ", isLoggedIn, userId, token, user);
		if(parseInt(userId)>0){
			//if(isLoggedIn===true && parseInt(userId)>0 && token!==undefined && token!==null){
			isAuthenticated=true;
			isNotReallyLoggedin=false; 
			console.log("UUUUUUUUUUUUUUUUUUUU REEAAAAAAALY LOGGGEEEEED-IN  ");
		}
	  }
	}

	const iframesolotab=this.getParameterByName('iframesolotab');
	//const hideHeaderFooter=this.getParameterByName('hideHeaderFooter');
	const hideHeaderFooter='yes';
	const clientWidth=parseInt(""+document.body.clientWidth);
	console.log("UUUUUUUUUUUUUUUUUUUU appInsideZendesk -SIIIIIIIISE-ZENDESK  ", this.props.appInsideZendesk);
	console.log("UUUUUUUUUUUUUUUUUUUU appInsideZendesk -width - height  ", document.body.clientWidth, document.body.clientHeight);
	console.log("UUUUUUUUUUUUUUUUUUUU appInsideZendesk -zafClient  ", zafClient);
	console.log("UUUUUUUUUKKKKKKKKKKK REEAAAAAAALY LOGGGEEEEED-IN KK=", iframesolotab);
	

	if(isCompAuthenticated===undefined && isNotReallyLoggedin){
		return (
			<div>
				<Login /> 			 
			</div>  
		);
	}

	//(zafClient!==undefined && zafClient!==false)?

	// return (
	// 	<div>
	// 		<Main />
	// 		{/* <NdiFieldsMiniBoardzendesk /> */} 
	// 		{((this.props.appInsideZendesk!==undefined && this.props.appInsideZendesk!==false) || clientWidth>500)?
	// 		"":<NdiFieldsMiniBoardzendesk />
	// 		}			 
	// 	</div>  
	// );
	
	//return (<div>hoooooooooooola mama	</div> );
	//{"Koooolo="+iframesolotab} 

	//{(((this.props.appInsideZendesk!==undefined && this.props.appInsideZendesk!==false) || clientWidth>500) && iframesolotab===null)? 
	return (
		<div>			
			{(((this.props.appInsideZendesk!==undefined && this.props.appInsideZendesk!==false)  || clientWidth>500) && iframesolotab===null)?
			<div className={this.state.isLeftMenuOpen===true ? "hold-transition sidebar-mini" : "sidebar-mini sidebar-collapse"} id="topHomeFirst">
			 <div className="wrapper"> 
			  {hideHeaderFooter!=="yes"? 
			  <BaseHeader2 handleLeftPanelMenuRef={ this.handleLeftPanelMenu2.bind(this) }  />:""
			   }

			   {hideHeaderFooter!=="yes"? 
			  <BaseLeftPanel2 openLeftMenu={ this.state.isLeftMenuOpen2 } handleLeftPanelMenuRefx={ this.handleLeftPanelMenu2x.bind(this) } />:""
			   }
			 
			 <div className="content-wrapper"> 
				<div className="content-header">
				  <div className="container-fluid">
					<div className="row mb-2">
					  {/*<div className="col-sm-6">
						<h1 className="m-0 text-dark">Starter Page</h1>
					  </div> 
					  <div className="col-sm-6">
						<ol className="breadcrumb float-sm-right">
						  <li className="breadcrumb-item"><a href="#">Home</a></li>
						  <li className="breadcrumb-item active">Starter Page</li>
						</ol>
					  </div>*/} 
					</div> 
				  </div> 
				</div> 
		 
		 
				<div className="content">
				  <div className="container-fluid">
					<div className="row">
					 
					 {(this.props.location.pathname==="/" || this.props.location.pathname===APP_PATHNAME)?
					 <>
					 <VwBoard /> 
					  <div className="col-lg-12"> 
						<div className="card"> 
						  <div className="card-body">
						   <div>
								&nbsp; <br/> <h3>WELCOME ON THE DASH BOARDkkk</h3> <br/> &nbsp; <br/>
							</div>
							<Main />
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div>
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div> 
							<div> 
								
								&nbsp; <br/> &nbsp; <br/> 
								{/* {t('welcome')} */}
								&nbsp; <br/>
							</div> 
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div> 
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div> 
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div> 
						   
						  </div> 
						</div>

						
					  </div> 

					  </>
					  
					  :
					  <div className="col-lg-12"> 
						<div className="card">
						  {/*<div className="card-header">
							<h5 className="m-0">Featured w2777</h5>
						  </div> */}
						   
						  <div className="card-body">
							{/*
							<Main /> <NeoVentesForm />
						   */}
						   <Main />

							{/* <NeoVentesForm /> */}
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/> 
							</div> 
						  </div>
						</div> 
					  </div>
					   
					 }
					
					  
					   
					  
					   
					</div> 
				  </div> 
				</div> 
			  </div> 
			 
			  
			  {hideHeaderFooter!=="yes"? 
				<BaseFooter />:""
					}
			</div>
		  </div>
		  :<NdiFieldsMiniBoardzendesk />
			}			 
		</div>  
	);
	
  }
  
  render000() {
	const { t } = this.props; 
	//console.log(" LEFTMENU GOOOOOOOOOO OPEN "+this.state.isLeftMenuOpen2);
	//console.log("AppMain pathname "+this.props.location.pathname+" vs APP_PATHNAME "+APP_PATHNAME);
    return (
			<div className={this.state.isLeftMenuOpen===true ? "hold-transition sidebar-mini" : "sidebar-mini sidebar-collapse"} id="topHomeFirst">
			 <div className="wrapper"> 
			   
			  <BaseHeader2 handleLeftPanelMenuRef={ this.handleLeftPanelMenu2.bind(this) }  />
			   
			  <BaseLeftPanel2 openLeftMenu={ this.state.isLeftMenuOpen2 } handleLeftPanelMenuRefx={ this.handleLeftPanelMenu2x.bind(this) } />
			 
			 
			 <div className="content-wrapper"> 
				<div className="content-header">
				  <div className="container-fluid">
					<div className="row mb-2">
					  {/*<div className="col-sm-6">
						<h1 className="m-0 text-dark">Starter Page</h1>
					  </div> 
					  <div className="col-sm-6">
						<ol className="breadcrumb float-sm-right">
						  <li className="breadcrumb-item"><a href="#">Home</a></li>
						  <li className="breadcrumb-item active">Starter Page</li>
						</ol>
					  </div>*/} 
					</div> 
				  </div> 
				</div> 
		 
		 
				<div className="content">
				  <div className="container-fluid">
					<div className="row">
					 
					 {(this.props.location.pathname==="/" || this.props.location.pathname===APP_PATHNAME)?
					 <>
					 <VwBoard /> 
					  <div className="col-lg-12"> 
						<div className="card"> 
						  <div className="card-body">
						   <div>
								&nbsp; <br/> <h3>WELCOME ON THE DASH BOARDxxx</h3> <br/> &nbsp; <br/>
							</div>
							<Main />
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div>
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div> 
							<div> 
								
								&nbsp; <br/> &nbsp; <br/> 
								{/* {t('welcome')} */}
								&nbsp; <br/>
							</div> 
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div> 
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div> 
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/>
							</div> 
						   
						  </div> 
						</div>

						{/* <div className="card card-primary card-outline">
						  <div className="card-header">
							<h5 className="m-0">Featured qx</h5>
						  </div>
						  <div className="card-body">
							<h6 className="card-title">Special title treatment</h6>

							<p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
							<a href="#" className="btn btn-primary">Go somewhere</a>
						  </div>
						</div> */}
					  </div> 
					  
					  
					  {/* <div className="col-lg-12"> 
						<div className="card">
						  <div className="card-header">
							<h5 className="m-0">Featured w2</h5>
						  </div>
						  <div className="card-body">
							<h6 className="card-title">Special title treatment</h6>

							<p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
							<a href="#" className="btn btn-primary">Go somewhere</a>
						  </div>
						</div> 
					  </div>  */}


					  </>
					  
					  :
					  <div className="col-lg-12"> 
						<div className="card">
						  {/*<div className="card-header">
							<h5 className="m-0">Featured w2777</h5>
						  </div> */}
						  <div className="card-body">
							{/*
							<Main /> <NeoVentesForm />
						   */}
						   <Main />

							{/* <NeoVentesForm /> */}
							<div>
								&nbsp; <br/> &nbsp; <br/> &nbsp; <br/> 
							</div> 
						  </div>
						</div> 
					  </div>
					   
					 }
					
					  
					   
					  
					   
					</div> 
				  </div> 
				</div> 
			  </div> 
			 
			  
		  
				<BaseFooter />
			</div>
		  </div>
		   
    );
  }
}

const Navigation = () => (
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item"><NavLink exact className="nav-link" activeClassName="active" to="./zxteams">Articles</NavLink></li>
      <li className="nav-item"><NavLink exact className="nav-link" activeClassName="active" to="./zxteams/new">Add Article</NavLink></li>
    </ul>
  </nav>
);

/*<Route exact path="/" component={Login} />
          <Route path="/signup" component={Signup}/>
          <Route path="/login" component={Login}/>
		  <PrivateRoute path='/protected' component={AppMain} />*/

//<PrivateRoute exact path='/protected' component={AppMain} />  
		  
const Main = () => {
	let {path, url} = useRouteMatch();
	//const mypathname =document.URL.substr(0,document.URL.lastIndexOf('/'));
	//{`${path}/zxteams`}
	// path=path+"/";
	// path=path.replace("//", "/");
	const pathprev=path;
	path =APP_PATHNAME;
	//console.log("Main Switch Route WEEEEEEEEEEEEEEEEEEEEE "+path);
return ( 
  <Switch>
 
 
 
		
		<Route exact path={path+"ndifields"} component={NdiFieldsList} />
		<Route exact path={path+"fieldszendeskinput"} component={NdiFieldsFormzendesk} />
		<Route exact path={path+"datainputboard"} component={NdiFieldsMiniBoardzendesk} />
		<Route exact path={path+"zendesk/:id/ticket"} component={NdiFieldsMiniBoardzendesk} />
		 
		<Route exact path={path+"ndifields/new"} component={NdiFieldsForm} />
		<Route exact path={path+"ndifields/:id"} component={NdiFieldsInfo} />
		<Route exact path={path+"ndifields/:id/edit"} component={NdiFieldsForm} />

		<Route exact path={path+"/neob2bclients"} component={NeoB2bClientsList} />
		<Route exact path={path+"/neob2bclients/new"} component={NeoB2bClientsForm} />
		<Route exact path={path+"/neob2bclients/:id"} component={NeoB2bClientsInfo} />
		<Route exact path={path+"/neob2bclients/:id/edit"} component={NeoB2bClientsForm} />

		<Route exact path={path+"neoventes/new"} component={NeoVentesForm} />
		<Route exact path={path+"neoventes/:id"} component={NeoVentesForm} />
		<Route exact path={path+"neoventes/:id/edit"} component={NeoVentesForm} />
		<Route exact path={path+"/loginrefresh"} component={LoginRefresh} />
		<Route exact path={path+"ticketactions"} component={NeoVentesBasicPostActionsta} />
		<Route exact path={path+"zendesk/ticketactions"} component={NeoVentesBasicPostActionsta} />
		<Route exact path={path+"zendesk/:id/ticket/ticketactions"} component={NeoVentesBasicPostActionsta} />

		<Route exact path={path+"neopaymentscredits"} component={NeoPaymentsCreditsList} />
		<Route exact path={path+"neopaymentscredits/new"} component={NeoPaymentsCreditsForm} />
		<Route exact path={path+"neopaymentscredits/:id"} component={NeoPaymentsCreditsInfo} />
		<Route exact path={path+"neopaymentscredits/:id/edit"} component={NeoPaymentsCreditsForm} />

		<Route exact path={path+"neopaymentscreditsoper"} component={NeoPaymentsCreditsOperList} />
		<Route exact path={path+"neopaymentscreditsoper/new"} component={NeoPaymentsCreditsOperForm} />
		<Route exact path={path+"neopaymentscreditsoper/:id"} component={NeoPaymentsCreditsOperInfo} />
		<Route exact path={path+"neopaymentscreditsoper/:id/edit"} component={NeoPaymentsCreditsOperForm} />

		<Route exact path={path+"neopaymentsrefund"} component={NeoPaymentsRefundList} />
		<Route exact path={path+"neopaymentsrefund/new"} component={NeoPaymentsRefundForm} />
		<Route exact path={path+"neopaymentsrefund/:id"} component={NeoPaymentsRefundInfo} />
		<Route exact path={path+"neopaymentsrefund/:id/edit"} component={NeoPaymentsRefundForm} />
		
		
		
		
	{/* 
		<Route exact path={path+"zxcompanies"} component={ZxCompaniesList} />
		<Route exact path={path+"zxcompanies/new"} component={ZxCompaniesForm} />
		<Route exact path={path+"zxcompanies/:id"} component={ZxCompaniesInfo} />
		<Route exact path={path+"zxcompanies/:id/edit"} component={ZxCompaniesForm} />
		
		<Route exact path={path+"zonlineusuarios"} component={ZonlineUsuariosList} />
		<Route exact path={path+"zonlineusuarios/new"} component={ZonlineUsuariosForm} />
		<Route exact path={path+"zonlineusuarios/:id"} component={ZonlineUsuariosInfo} />
		<Route exact path={path+"zonlineusuarios/:id/edit"} component={ZonlineUsuariosForm} />
		
		<Route exact path={path+"vwfacturas"} component={VwFacturasList} />
		<Route exact path={path+"vwfacturas/new"} component={VwFacturasForm} />
		<Route exact path={path+"vwfacturas/:id"} component={VwFacturasInfo} />
		<Route exact path={path+"vwfacturas/:id/edit"} component={VwFacturasForm} />

		<Route exact path={path+"neoventes"} component={NeoVentesForm} />
		<Route exact path={path+"neoventes/:id/invoice"} component={NeoVentesForm} /> 
		{/* <Route exact path={path+"neoventes/:id/invoice"} component={NeoVentesFormInvoice} /> /}
		 
		<Route exact path={path+"vwneoventes"} component={NeoVentesList} />
		<Route exact path={path+"vwneoventespending"} component={PendingNeoVentesList} />
		<Route exact path={path+"neoventes/new"} component={NeoVentesForm} />
		<Route exact path={path+"neoventes/:id"} component={NeoVentesInfo} />
		<Route exact path={path+"neoventes/:id/edit"} component={NeoVentesForm} />
		<Route exact path={path+"allstatussales"} component={NeoVentesListx} />
		

		<Route exact path={path+"salesplanningdaily"} component={NeoSalesPlanningDailyList} />
		<Route exact path={path+"salesplanningweekly"} component={NeoSalesPlanningWeeklyList} />

		<Route exact path={path+"salesplanningpeople"} component={NeoSalesPlanningSellerList} />
		
		



		<Route exact path={path+"ndifields"} component={NdiFieldsList} />
		<Route exact path={path+"fieldszendeskinput"} component={NdiFieldsFormzendesk} />
		<Route exact path={path+"datainputboard"} component={NdiFieldsMiniBoardzendesk} />
		 
		<Route exact path={path+"ndifields/new"} component={NdiFieldsForm} />
		<Route exact path={path+"ndifields/:id"} component={NdiFieldsInfo} />
		<Route exact path={path+"ndifields/:id/edit"} component={NdiFieldsForm} />



		<Route exact path={path+"ndifieldoptions"} component={NdiFieldOptionsList} />
		<Route exact path={path+"ndifieldoptions/new"} component={NdiFieldOptionsForm} />
		<Route exact path={path+"ndifieldoptions/:id"} component={NdiFieldOptionsInfo} />
		<Route exact path={path+"ndifieldoptions/:id/edit"} component={NdiFieldOptionsForm} />

		<Route exact path={path+"ndifieldoptionstranslation"} component={NdiFieldOptionsTranslationList} />
		<Route exact path={path+"ndifieldoptionstranslation/new"} component={NdiFieldOptionsTranslationForm} />
		<Route exact path={path+"ndifieldoptionstranslation/:id"} component={NdiFieldOptionsTranslationInfo} />
		<Route exact path={path+"ndifieldoptionstranslation/:id/edit"} component={NdiFieldOptionsTranslationForm} />

		<Route exact path={path+"ndifieldstranslation"} component={NdiFieldsTranslationList} />
		<Route exact path={path+"ndifieldstranslation/new"} component={NdiFieldsTranslationForm} />
		<Route exact path={path+"ndifieldstranslation/:id"} component={NdiFieldsTranslationInfo} />
		<Route exact path={path+"ndifieldstranslation/:id/edit"} component={NdiFieldsTranslationForm} />

		<Route exact path={path+"ndifoldersproducts"} component={NdiFoldersProductsList} />
		<Route exact path={path+"ndifoldersproducts/new"} component={NdiFoldersProductsForm} />
		<Route exact path={path+"ndifoldersproducts/:id"} component={NdiFoldersProductsInfo} />
		<Route exact path={path+"ndifoldersproducts/:id/edit"} component={NdiFoldersProductsForm} />

		<Route exact path={path+"ndiproductfields"} component={NdiProductFieldsList} />
		<Route exact path={path+"ndiproductfields/new"} component={NdiProductFieldsForm} />
		<Route exact path={path+"ndiproductfields/:id"} component={NdiProductFieldsInfo} />
		<Route exact path={path+"ndiproductfields/:id/edit"} component={NdiProductFieldsForm} />

		<Route exact path={path+"ndiproducttypes"} component={NdiProductTypesList} />
		<Route exact path={path+"ndiproducttypes/new"} component={NdiProductTypesForm} />
		<Route exact path={path+"ndiproducttypes/:id"} component={NdiProductTypesInfo} />
		<Route exact path={path+"ndiproducttypes/:id/edit"} component={NdiProductTypesForm} />

		<Route exact path={path+"ndiproducts"} component={NdiProductsList} />
		<Route exact path={path+"ndiproducts/new"} component={NdiProductsForm} />
		<Route exact path={path+"ndiproducts/:id"} component={NdiProductsInfo} />
		<Route exact path={path+"ndiproducts/:id/edit"} component={NdiProductsForm} />

		<Route exact path={path+"ndiproductstranslation"} component={NdiProductsTranslationList} />
		<Route exact path={path+"ndiproductstranslation/new"} component={NdiProductsTranslationForm} />
		<Route exact path={path+"ndiproductstranslation/:id"} component={NdiProductsTranslationInfo} />
		<Route exact path={path+"ndiproductstranslation/:id/edit"} component={NdiProductsTranslationForm} />

		<Route exact path={path+"nditags"} component={NdiTagsList} />
		<Route exact path={path+"nditags/new"} component={NdiTagsForm} />
		<Route exact path={path+"nditags/:id"} component={NdiTagsInfo} />
		<Route exact path={path+"nditags/:id/edit"} component={NdiTagsForm} />

		<Route exact path={path+"nditicketdataleft"} component={NdiTicketDataLeftList} />
		<Route exact path={path+"nditicketdataleft/new"} component={NdiTicketDataLeftForm} />
		<Route exact path={path+"nditicketdataleft/:id"} component={NdiTicketDataLeftInfo} />
		<Route exact path={path+"nditicketdataleft/:id/edit"} component={NdiTicketDataLeftForm} />

 */}



		 
		 
		
	
    </Switch>
  
  
)
};

 

//export default App;
const mapStateToProps = state => ({
  currentUser: state.LoggedUser.currentUser
});

//currentUser: state.reducer.currentUser

const mapDispatchToProps = dispatch => ({
  getProfileFetch: () => dispatch(getProfileFetch()),
  logoutUser: () => dispatch(logoutUser()), 
  getDailyGoalSale: () => dispatch(getDailyGoalSale()),
  loggedUser: getLoggedUser
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));

//const mapStateToProps = (state) => ({ ZxTeams: state.ZxTeams });  
//const mapDispatchToProps = { addOrUpdateZxTeams, getZxTeams, getZxTeamsAll, getZxTeamsGoto, deleteZxTeams };       
//export default connect(mapStateToProps, mapDispatchToProps)(ZxTeamsList);
