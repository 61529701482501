import { RECEIVE_NDIFIELDSTRANSLATION, UPDATE_NDIFIELDSTRANSLATION } from '../actions/NdiFieldsTranslationAction';

export default function singleNdiFieldsTranslationReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDIFIELDSTRANSLATION:
      return action.NdiFieldsTranslation;
    case UPDATE_NDIFIELDSTRANSLATION:
      return {
        id: action.id,
field_id: action.payload.field_id,
lang: action.payload.lang,
text_label: action.payload.text_label,
placeholder: action.payload.placeholder,
field_default_value: action.payload.field_default_value,
field_service: action.payload.field_service,
field_dataset: action.payload.field_dataset,
      }
    default:
      return state;
  }
};