import { RECEIVE_NDIPRODUCTS, ADD_NDIPRODUCTS, REMOVE_NDIPRODUCTS, REPLACE_NDIPRODUCTS } from '../actions/NdiProductsAction';

const initialState = { NdiProducts: [] }
export default function NdiProductsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDIPRODUCTS:
      return action.NdiProducts;
    case ADD_NDIPRODUCTS:
      return [action.payload, ...state];
    case REMOVE_NDIPRODUCTS:
      return state.filter(NdiProducts => NdiProducts.id !== action.payload.id);
    case REPLACE_NDIPRODUCTS:
      return state.map((NdiProducts) => {
        if (NdiProducts.id === action.payload.id) {
          return {
            ...NdiProducts,
name: action.payload.name,
product_type_id: action.payload.product_type_id,
product_block: action.payload.product_block,
published: action.payload.published,
last_update: action.payload.last_update,
user_id: action.payload.user_id,
          }
        } else return NdiProducts;
      })
    default:
      return state;
  }
}
