import { RECEIVE_NDITICKETDATALEFT, ADD_NDITICKETDATALEFT, REMOVE_NDITICKETDATALEFT, REPLACE_NDITICKETDATALEFT } from '../actions/NdiTicketDataLeftAction';

const initialState = { NdiTicketDataLeft: [] }
export default function NdiTicketDataLeftReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDITICKETDATALEFT:
      return action.NdiTicketDataLeft;
    case ADD_NDITICKETDATALEFT:
      return [action.payload, ...state];
    case REMOVE_NDITICKETDATALEFT:
      return state.filter(NdiTicketDataLeft => NdiTicketDataLeft.id !== action.payload.id);
    case REPLACE_NDITICKETDATALEFT:
      return state.map((NdiTicketDataLeft) => {
        if (NdiTicketDataLeft.id === action.payload.id) {
          return {
            ...NdiTicketDataLeft,
product_field_id: action.payload.product_field_id,
case_id: action.payload.case_id,
field_data: action.payload.field_data,
field_data_raw: action.payload.field_data_raw,
is_public: action.payload.is_public,
creation_date: action.payload.creation_date,
user_creation_id: action.payload.user_creation_id,
user_update_id: action.payload.user_update_id,
last_update: action.payload.last_update,
          }
        } else return NdiTicketDataLeft;
      })
    default:
      return state;
  }
}
