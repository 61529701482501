import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOB2BCLIENTS = 'GET_NEOB2BCLIENTS';
export const ADD_NEOB2BCLIENTS = 'ADD_NEOB2BCLIENTS';
export const RECEIVE_NEOB2BCLIENTSX = 'RECEIVE_NEOB2BCLIENTSX';
export const REMOVE_NEOB2BCLIENTS = 'REMOVE_NEOB2BCLIENTS';
export const UPDATE_NEOB2BCLIENTS = 'UPDATE_NEOB2BCLIENTS';
export const REPLACE_NEOB2BCLIENTS = 'REPLACE_NEOB2BCLIENTS';
const apiUrl = API_DOMAIN + '/neob2bclients'; 

axiosDefaults(null);

export const getNeoB2bClientsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_NEOB2BCLIENTS, NeoB2bClients: response.data.neob2bclients.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNeoB2bClients = (NeoB2bClients) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NeoB2bClients.id,
		id_b2b_list: NeoB2bClients.id_b2b_list,
		id_external: NeoB2bClients.id_external,
		name_id_external: NeoB2bClients.name_id_external,
		firstname: NeoB2bClients.firstname,
		lastname: NeoB2bClients.lastname,
		email: NeoB2bClients.email,
		tel: NeoB2bClients.tel,
		start_date: NeoB2bClients.start_date,
		expiry_date: NeoB2bClients.expiry_date,
		address: NeoB2bClients.address,
		city: NeoB2bClients.city,
		province: NeoB2bClients.province,
		postal_code: NeoB2bClients.postal_code,
		raw_data: NeoB2bClients.raw_data,
		date_created: NeoB2bClients.date_created,
		date_updated: NeoB2bClients.date_updated,
		status: NeoB2bClients.status,
		dob: NeoB2bClients.dob,
		id_clients: NeoB2bClients.id_clients,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOB2BCLIENTS, payload: {
			id: data.id,
			id_b2b_list: data.id_b2b_list,
			id_external: data.id_external,
			name_id_external: data.name_id_external,
			firstname: data.firstname,
			lastname: data.lastname,
			email: data.email,
			tel: data.tel,
			start_date: data.start_date,
			expiry_date: data.expiry_date,
			address: data.address,
			city: data.city,
			province: data.province,
			postal_code: data.postal_code,
			raw_data: data.raw_data,
			date_created: data.date_created,
			date_updated: data.date_updated,
			status: data.status,
			dob: data.dob,
			id_clients: data.id_clients,
		}})
      })
      .then(() => {
        history.push("/NeoB2bClients")
      })
      .catch(error => { throw(error) });
  };
};
export const getNeoB2bClients = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.neob2bclients);		  
        return dispatch({type: RECEIVE_NEOB2BCLIENTSX, NeoB2bClientsOne: response.data.neob2bclients});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNeoB2bClientsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NeoB2bClients/${id}/edit`)
  };
};
export const deleteNeoB2bClients = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOB2BCLIENTS, payload: {id}})
      })
      .then(() => {
        history.push("/NeoB2bClients")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNeoB2bClients = (NeoB2bClients) => {
  const id = NeoB2bClients.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoB2bClients.id}`, {
		id_b2b_list: NeoB2bClients.id_b2b_list,
		id_external: NeoB2bClients.id_external,
		name_id_external: NeoB2bClients.name_id_external,
		firstname: NeoB2bClients.firstname,
		lastname: NeoB2bClients.lastname,
		email: NeoB2bClients.email,
		tel: NeoB2bClients.tel,
		start_date: NeoB2bClients.start_date,
		expiry_date: NeoB2bClients.expiry_date,
		address: NeoB2bClients.address,
		city: NeoB2bClients.city,
		province: NeoB2bClients.province,
		postal_code: NeoB2bClients.postal_code,
		raw_data: NeoB2bClients.raw_data,
		date_created: NeoB2bClients.date_created,
		date_updated: NeoB2bClients.date_updated,
		status: NeoB2bClients.status,
		dob: NeoB2bClients.dob,
		id_clients: NeoB2bClients.id_clients,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOB2BCLIENTS, payload: {
			id: data.id,
			id_b2b_list: data.id_b2b_list,
			id_external: data.id_external,
			name_id_external: data.name_id_external,
			firstname: data.firstname,
			lastname: data.lastname,
			email: data.email,
			tel: data.tel,
			start_date: data.start_date,
			expiry_date: data.expiry_date,
			address: data.address,
			city: data.city,
			province: data.province,
			postal_code: data.postal_code,
			raw_data: data.raw_data,
			date_created: data.date_created,
			date_updated: data.date_updated,
			status: data.status,
			dob: data.dob,
			id_clients: data.id_clients,
		}})
        dispatch({type: REPLACE_NEOB2BCLIENTS, payload: {
			id: data.id,
			id_b2b_list: data.id_b2b_list,
			id_external: data.id_external,
			name_id_external: data.name_id_external,
			firstname: data.firstname,
			lastname: data.lastname,
			email: data.email,
			tel: data.tel,
			start_date: data.start_date,
			expiry_date: data.expiry_date,
			address: data.address,
			city: data.city,
			province: data.province,
			postal_code: data.postal_code,
			raw_data: data.raw_data,
			date_created: data.date_created,
			date_updated: data.date_updated,
			status: data.status,
			dob: data.dob,
			id_clients: data.id_clients,
		}})
      })
      .then(() => {
        history.push(`/NeoB2bClients/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNeoB2bClients = (NeoB2bClients, actionMode) => {
	if(actionMode===1){
        return addNeoB2bClients(NeoB2bClients);
      }else {
        return updateNeoB2bClients(NeoB2bClients);
      }
};