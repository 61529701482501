import { RECEIVE_NDITICKETDATALEFT, UPDATE_NDITICKETDATALEFT } from '../actions/NdiTicketDataLeftAction';

export default function singleNdiTicketDataLeftReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDITICKETDATALEFT:
      return action.NdiTicketDataLeft;
    case UPDATE_NDITICKETDATALEFT:
      return {
        id: action.id,
product_field_id: action.payload.product_field_id,
case_id: action.payload.case_id,
field_data: action.payload.field_data,
field_data_raw: action.payload.field_data_raw,
is_public: action.payload.is_public,
creation_date: action.payload.creation_date,
user_creation_id: action.payload.user_creation_id,
user_update_id: action.payload.user_update_id,
last_update: action.payload.last_update,
      }
    default:
      return state;
  }
};