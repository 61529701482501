import { RECEIVE_VWFACTURAS, UPDATE_VWFACTURAS } from '../actions/VwFacturasAction';

export default function singleVwFacturasReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_VWFACTURAS:
      return action.VwFacturas;
    case UPDATE_VWFACTURAS:
      return { 
Facturacion: action.payload.Facturacion,
Factura_Fiscal: action.payload.Factura_Fiscal,
Cliente: action.payload.Cliente,
Vendedor: action.payload.Vendedor,
Fecha: action.payload.Fecha,
Tasa_Imp: action.payload.Tasa_Imp,
Subtotal: action.payload.Subtotal,
Impuesto: action.payload.Impuesto,
Total: action.payload.Total,
Tipo: action.payload.Tipo,
Estado: action.payload.Estado,
Fecha_Sistema: action.payload.Fecha_Sistema,
head: action.payload.head,
id: action.payload.id,
      }
    default:
      return state;
  }
};