import { RECEIVE_NDIPRODUCTSTRANSLATION, ADD_NDIPRODUCTSTRANSLATION, REMOVE_NDIPRODUCTSTRANSLATION, REPLACE_NDIPRODUCTSTRANSLATION } from '../actions/NdiProductsTranslationAction';

const initialState = { NdiProductsTranslation: [] }
export default function NdiProductsTranslationReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDIPRODUCTSTRANSLATION:
      return action.NdiProductsTranslation;
    case ADD_NDIPRODUCTSTRANSLATION:
      return [action.payload, ...state];
    case REMOVE_NDIPRODUCTSTRANSLATION:
      return state.filter(NdiProductsTranslation => NdiProductsTranslation.id !== action.payload.id);
    case REPLACE_NDIPRODUCTSTRANSLATION:
      return state.map((NdiProductsTranslation) => {
        if (NdiProductsTranslation.id === action.payload.id) {
          return {
            ...NdiProductsTranslation,
product_id: action.payload.product_id,
lang: action.payload.lang,
product_block: action.payload.product_block,
published: action.payload.published,
last_update: action.payload.last_update,
user_id: action.payload.user_id,
          }
        } else return NdiProductsTranslation;
      })
    default:
      return state;
  }
}
