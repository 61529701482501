import { RECEIVE_ZXCOMPANIES, ADD_ZXCOMPANIES, REMOVE_ZXCOMPANIES, REPLACE_ZXCOMPANIES } from '../actions/ZxCompaniesAction';

const initialState = { ZxCompanies: [] }
export default function ZxCompaniesReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ZXCOMPANIES:
      return action.ZxCompanies;
    case ADD_ZXCOMPANIES:
      return [action.payload, ...state];
    case REMOVE_ZXCOMPANIES:
      return state.filter(ZxCompanies => ZxCompanies.id !== action.payload.id);
    case REPLACE_ZXCOMPANIES:
      return state.map((ZxCompanies) => {
        if (ZxCompanies.id === action.payload.id) {
          return {
            ...ZxCompanies,
name: action.payload.name,
joining_date: action.payload.joining_date,
address: action.payload.address,
phone: action.payload.phone,
dob: action.payload.dob,
zipcode: action.payload.zipcode,
suite: action.payload.suite,
city: action.payload.city,
state: action.payload.state,
country: action.payload.country,
company_code: action.payload.company_code,
          }
        } else return ZxCompanies;
      })
    default:
      return state;
  }
}
