import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDIPRODUCTFIELDS = 'GET_NDIPRODUCTFIELDS';
export const ADD_NDIPRODUCTFIELDS = 'ADD_NDIPRODUCTFIELDS';
export const RECEIVE_NDIPRODUCTFIELDSX = 'RECEIVE_NDIPRODUCTFIELDSX';
export const REMOVE_NDIPRODUCTFIELDS = 'REMOVE_NDIPRODUCTFIELDS';
export const UPDATE_NDIPRODUCTFIELDS = 'UPDATE_NDIPRODUCTFIELDS';
export const REPLACE_NDIPRODUCTFIELDS = 'REPLACE_NDIPRODUCTFIELDS';
const apiUrl = API_DOMAIN + '/ndiproductfieldsfull'; 

axiosDefaults(null);

export const getNdiProductFieldsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
        dispatch({type: RECEIVE_NDIPRODUCTFIELDS, NdiProductFields: response.data.ndiproductfields.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiProductFields = (NdiProductFields) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiProductFields.id,
		product_type_id: NdiProductFields.product_type_id,
		field_id: NdiProductFields.field_id,
		required: NdiProductFields.required,
		visual_order: NdiProductFields.visual_order,
		enabled: NdiProductFields.enabled,
		creation_date: NdiProductFields.creation_date,
		user_creation_id: NdiProductFields.user_creation_id,
		user_update_id: NdiProductFields.user_update_id,
		last_update: NdiProductFields.last_update,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDIPRODUCTFIELDS, payload: {
			id: data.id,
			product_type_id: data.product_type_id,
			field_id: data.field_id,
			required: data.required,
			visual_order: data.visual_order,
			enabled: data.enabled,
			creation_date: data.creation_date,
			user_creation_id: data.user_creation_id,
			user_update_id: data.user_update_id,
			last_update: data.last_update,
		}})
      })
      .then(() => {
        history.push("/NdiProductFields")
      })
      .catch(error => { throw(error) });
  };
};
export const getNdiProductFields = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}?id=${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.ndiproductfields);		  
        return dispatch({type: RECEIVE_NDIPRODUCTFIELDSX, NdiProductFieldsOne: response.data.ndiproductfields});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiProductFieldsByType = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}?id=${id}&bytype=1`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.data);		  
        return dispatch({type: RECEIVE_NDIPRODUCTFIELDSX, NdiProductFieldsOne: response.data.data});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiProductFieldsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiProductFields/${id}/edit`)
  };
};
export const deleteNdiProductFields = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDIPRODUCTFIELDS, payload: {id}})
      })
      .then(() => {
        history.push("/NdiProductFields")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiProductFields = (NdiProductFields) => {
  const id = NdiProductFields.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiProductFields.id}`, {
		product_type_id: NdiProductFields.product_type_id,
		field_id: NdiProductFields.field_id,
		required: NdiProductFields.required,
		visual_order: NdiProductFields.visual_order,
		enabled: NdiProductFields.enabled,
		creation_date: NdiProductFields.creation_date,
		user_creation_id: NdiProductFields.user_creation_id,
		user_update_id: NdiProductFields.user_update_id,
		last_update: NdiProductFields.last_update,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDIPRODUCTFIELDS, payload: {
			id: data.id,
			product_type_id: data.product_type_id,
			field_id: data.field_id,
			required: data.required,
			visual_order: data.visual_order,
			enabled: data.enabled,
			creation_date: data.creation_date,
			user_creation_id: data.user_creation_id,
			user_update_id: data.user_update_id,
			last_update: data.last_update,
		}})
        dispatch({type: REPLACE_NDIPRODUCTFIELDS, payload: {
			id: data.id,
			product_type_id: data.product_type_id,
			field_id: data.field_id,
			required: data.required,
			visual_order: data.visual_order,
			enabled: data.enabled,
			creation_date: data.creation_date,
			user_creation_id: data.user_creation_id,
			user_update_id: data.user_update_id,
			last_update: data.last_update,
		}})
      })
      .then(() => {
        history.push(`/NdiProductFields/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiProductFields = (NdiProductFields, actionMode) => {
	if(actionMode===1){
        return addNdiProductFields(NdiProductFields);
      }else {
        return updateNdiProductFields(NdiProductFields);
      }
};