import React from 'react';
import { connect, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import BoardIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PaymentIcon from '@material-ui/icons/Payment';
//import BoardIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'; 
import SpeedometerIcon from '@material-ui/icons/Speed';
import ShoppingIcon from '@material-ui/icons/Shop';  //ShoppingBasket
import AssignmentIcon from '@material-ui/icons/Assignment';
import { checkIfUserIsAdmin } from './actions/NeoSalesPlanningDailyAction';
import FolderIcon from '@material-ui/icons/FolderOpen';
import { APP_PATHNAME } from './appConfig'; 


import { useTranslation } from 'react-i18next';

/*import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";*/

import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

//const dispatch=useDispatch();

export function BaseLeftPanel2(props) {
  const { t, i18n } = useTranslation('common');
  const dispatch=useDispatch();

  const path=APP_PATHNAME;

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [itemAdmin, setItemAdmin] = React.useState(0);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
	props.handleLeftPanelMenuRefx(event);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/*<List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      
      
      
      
  <ul className="nav nav-pills nav-sidebar flex-column farxli" data-widget="treeview" role="menu" data-accordion="false">	  
	<li className="nav-item">
		<Link to="./"><i className="nav-icon farx text-info"><DashboardIcon /></i>{t('menudashboard_x')}</Link>
	</li> 
	<li className="nav-item">
		<Link to="./vwdespachosalerts"><i className="nav-icon farx text-warning"><SpeedometerIcon /></i>{t('menuvwdespachosalerts_x')}</Link>
	</li>
	<li className="nav-item">
		<Link to="./vwfacturas"><i className="nav-icon farx text-info"><ShoppingIcon /></i>{t('menuvwfacturas_x')}</Link>
	</li>
	<li className="nav-item">
		<Link to="./vwfacturasnopagadas"><i className="nav-icon farx text-danger"><ShoppingIcon /></i>{t('menuvwfacturasnopagadas_x')}</Link>
	</li>
	<li className="nav-item">
		<Link to="./vwfacturaspagadas"><i className="nav-icon farx text-success"><ShoppingIcon /></i>{t('menuvwfacturaspagadas_x')}</Link>
	</li>
	<li className="nav-item">
		<Link to="./vwpagos"><i className="nav-icon farx text-info"><PaymentIcon /></i>{t('menuvwpagos_x')}</Link>
	</li>

  <li className="nav-item">
		<Link to="./neoventes"><i className="nav-icon farx text-info"><PaymentIcon /></i>Faire une Vente</Link>
	</li>
</ul>    
      
      
      */}

<ul className="nav nav-pills nav-sidebar flex-column farxli" data-widget="treeview" role="menu" data-accordion="false">	  
	<li className="nav-item">
		<Link to={path}><i className="nav-icon farx text-info"><DashboardIcon /></i>{t('menudashboard_x')}</Link>
	</li> 

{/* 
  <li className="nav-item">
		<Link to="/neoventes"><i className="nav-icon farx text-info"><PaymentIcon /></i>Faire une Vente</Link>
	</li>

  <li className="nav-item">
		<Link to="/vwneoventes"><i className="nav-icon farx text-info"><PaymentIcon /></i>Liste de Vente</Link>
	</li>

  <li className="nav-item">
		<Link to="/vwneoventespending"><i className="nav-icon farx text-info"><SpeedometerIcon /></i>Liste de Vente en Attente</Link>
	</li> */}

	<li className="nav-item">
		<Link to={path+"neofolders"}><i className="nav-icon farx text-info"><FolderIcon /></i>Liste de Ticket</Link>
	</li>

	<li className="nav-item">
		<Link to={path+"datainputboard"}><i className="nav-icon farx text-info"><PaymentIcon /></i>Data Input</Link>
	</li>
	{/* <li className="nav-item">
		<Link to="/ndifields/new"><i className="nav-icon farx text-info"><PaymentIcon /></i>neoData Input</Link>
	</li> */}
	<li className="nav-item">
		<Link to={path+"ndifields"}><i className="nav-icon farx text-info"><PaymentIcon /></i>Champs/Fields Input</Link>
	</li>
	
	<li className="nav-item">
		<Link to={path+"ndiproducts"}><i className="nav-icon farx text-info"><PaymentIcon /></i>Les Produits</Link>
	</li>
	{/* <li className="nav-item">
		<Link to="/ndifields"><i className="nav-icon farx text-info"><PaymentIcon /></i>neoXData Input</Link>
	</li> */}
	<li className="nav-item">
		<Link to={path+"ndiproducttypes"}><i className="nav-icon farx text-info"><PaymentIcon /></i>Types de Produits</Link>
	</li>
  
   
  {itemAdmin ==="3"?
  <>
  <li className="nav-item">
		<Link to={path+"allstatussales"}><i className="nav-icon farx text-info"><ShoppingCartIcon /></i>Toutes les Transactions</Link>
	</li>
  <li className="nav-item">
		<Link to={path+"salesplanningweekly"}><i className="nav-icon farx text-info"><AssignmentIcon /></i>Planification de Vente</Link>
  </li>
  <li className="nav-item">
    <Link to={path+"salesplanningpeople"}><i className="nav-icon farx text-info"><ShoppingIcon /></i>Rapport de Vente</Link>
  </li>
  </>:''
 }
   
   
</ul>				  
    </div>
  );

  /*return (
    <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );*/
  
  if(props.openLeftMenu==true){
	 //setState({ ...state, ["left"]: true }); 
	 setTimeout(() => { 
		  setState({ ...state, ["left"]: true }); 
		  props.handleLeftPanelMenuRefx(null);
    }, 1);
    //console.log("meeeeeeeeeeeeeeenu left open...");
    //itemAdmin, setItemAdmin
    /*setTimeout(() => { 
		  // setState({ ...state, ["left"]: true }); 
      // props.handleLeftPanelMenuRefx(null);
      //here validate admin and show menu admin
      const tokadm = localStorage.tokadm; 
      if(tokadm===undefined || tokadm===null){ 
        //return null; /users/checkisadmin
        //checkIfUserIsAdmin
        //localStorage.setItem("tokadm", 0);

        Promise.resolve(dispatch(checkIfUserIsAdmin()))
        .then((response)=>{ 
          const cxz=response.NeoSalesPlanningDailyOne;
          //setSaleGoalDaily(cxz.qtyToday+"/"+cxz.expectation.expected_quantity);
          localStorage.setItem("tokadm", cxz.isadmin);
          setItemAdmin(cxz.isadmin);
          //console.log("weeeeeeeeeee checkIfUserIsAdmin "+cxz.isadmin);
          //console.log(response);  

          return response;
        });
      }else{
        //const isadmin=localStorage.getItem("tokadm");
        setItemAdmin(tokadm);
      }
    }, 1);*/
  }
  
  //console.log("+++++++BaseLeftPanel2 2 2 2 TEMP MENU TOGGLE  "+props.openLeftMenu); //props.openLeftMenu
  
  const anchor='left';
  return (
    <div className="MuiPaper-rootX">
	  <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
		{list(anchor)}
	  </Drawer>
    </div>
  );
}