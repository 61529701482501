import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOPAYMENTSCREDITSOPER = 'GET_NEOPAYMENTSCREDITSOPER';
export const ADD_NEOPAYMENTSCREDITSOPER = 'ADD_NEOPAYMENTSCREDITSOPER';
export const RECEIVE_NEOPAYMENTSCREDITSOPERX = 'RECEIVE_NEOPAYMENTSCREDITSOPERX';
export const REMOVE_NEOPAYMENTSCREDITSOPER = 'REMOVE_NEOPAYMENTSCREDITSOPER';
export const UPDATE_NEOPAYMENTSCREDITSOPER = 'UPDATE_NEOPAYMENTSCREDITSOPER';
export const REPLACE_NEOPAYMENTSCREDITSOPER = 'REPLACE_NEOPAYMENTSCREDITSOPER';
const apiUrl3 = API_DOMAIN + '/neopaymentscreditsoper'; 
const apiUrl = API_DOMAIN + '/creditsoperations'; 
const apiUrl2 = API_DOMAIN + '/b2b/clients'; 
const apiUrlBase=API_DOMAIN;  
const apiUrlMulti = apiUrl + '/multiactioninvoice'; 

axiosDefaults(null);

export const getNeoPaymentsCreditsOperAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
        dispatch({type: RECEIVE_NEOPAYMENTSCREDITSOPER, NeoPaymentsCreditsOper: response.data.data.neopaymentscreditsoper})
      })
      .catch(error => { throw(error); });
  };
};

export const addNeoPaymentsCreditsOper = (NeoPaymentsCreditsOper) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NeoPaymentsCreditsOper.id,
		id_clients: NeoPaymentsCreditsOper.id_clients,
		id_payments: NeoPaymentsCreditsOper.id_payments,
		amount: NeoPaymentsCreditsOper.amount,
		prev_cr_amount: NeoPaymentsCreditsOper.prev_cr_amount,
		f_amount: NeoPaymentsCreditsOper.f_amount,
		user_creation: NeoPaymentsCreditsOper.user_creation,
		user_update: NeoPaymentsCreditsOper.user_update,
		creation_date: NeoPaymentsCreditsOper.creation_date,
		update_date: NeoPaymentsCreditsOper.update_date,
		status: NeoPaymentsCreditsOper.status,
		observation: NeoPaymentsCreditsOper.observation,
		inv_id_payments: NeoPaymentsCreditsOper.inv_id_payments,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOPAYMENTSCREDITSOPER, payload: {
			id: data.id,
			id_clients: data.id_clients,
			id_payments: data.id_payments,
			amount: data.amount,
			prev_cr_amount: data.prev_cr_amount,
			f_amount: data.f_amount,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			observation: data.observation,
			inv_id_payments: data.inv_id_payments,
		}})
      })
      .then(() => {
        history.push("/NeoPaymentsCreditsOper")
      })
      .catch(error => { throw(error) });
  };
};
export const getNeoPaymentsCreditsOper = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.neopaymentscreditsoper);		  
        return dispatch({type: RECEIVE_NEOPAYMENTSCREDITSOPERX, NeoPaymentsCreditsOperOne: response.data.neopaymentscreditsoper});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNeoPaymentsCreditsOperGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NeoPaymentsCreditsOper/${id}/edit`)
  };
};
export const deleteNeoPaymentsCreditsOper = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOPAYMENTSCREDITSOPER, payload: {id}})
      })
      .then(() => {
        history.push("/NeoPaymentsCreditsOper")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNeoPaymentsCreditsOper = (NeoPaymentsCreditsOper) => {
  const id = NeoPaymentsCreditsOper.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoPaymentsCreditsOper.id}`, {
		id_clients: NeoPaymentsCreditsOper.id_clients,
		id_payments: NeoPaymentsCreditsOper.id_payments,
		amount: NeoPaymentsCreditsOper.amount,
		prev_cr_amount: NeoPaymentsCreditsOper.prev_cr_amount,
		f_amount: NeoPaymentsCreditsOper.f_amount,
		user_creation: NeoPaymentsCreditsOper.user_creation,
		user_update: NeoPaymentsCreditsOper.user_update,
		creation_date: NeoPaymentsCreditsOper.creation_date,
		update_date: NeoPaymentsCreditsOper.update_date,
		status: NeoPaymentsCreditsOper.status,
		observation: NeoPaymentsCreditsOper.observation,
		inv_id_payments: NeoPaymentsCreditsOper.inv_id_payments,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOPAYMENTSCREDITSOPER, payload: {
			id: data.id,
			id_clients: data.id_clients,
			id_payments: data.id_payments,
			amount: data.amount,
			prev_cr_amount: data.prev_cr_amount,
			f_amount: data.f_amount,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			observation: data.observation,
			inv_id_payments: data.inv_id_payments,
		}})
        dispatch({type: REPLACE_NEOPAYMENTSCREDITSOPER, payload: {
			id: data.id,
			id_clients: data.id_clients,
			id_payments: data.id_payments,
			amount: data.amount,
			prev_cr_amount: data.prev_cr_amount,
			f_amount: data.f_amount,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			observation: data.observation,
			inv_id_payments: data.inv_id_payments,
		}})
      })
      .then(() => {
        history.push(`/NeoPaymentsCreditsOper/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNeoPaymentsCreditsOper = (NeoPaymentsCreditsOper, actionMode) => {
	if(actionMode===1){
        return addNeoPaymentsCreditsOper(NeoPaymentsCreditsOper);
      }else {
        return updateNeoPaymentsCreditsOper(NeoPaymentsCreditsOper);
      }
};