import { RECEIVE_NDITAGS, UPDATE_NDITAGS } from '../actions/NdiTagsAction';

export default function singleNdiTagsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDITAGS:
      return action.NdiTags;
    case UPDATE_NDITAGS:
      return {
        id: action.id,
name: action.payload.name,
      }
    default:
      return state;
  }
};