import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOPAYMENTSREFUND = 'GET_NEOPAYMENTSREFUND';
export const ADD_NEOPAYMENTSREFUND = 'ADD_NEOPAYMENTSREFUND';
export const RECEIVE_NEOPAYMENTSREFUNDX = 'RECEIVE_NEOPAYMENTSREFUNDX';
export const REMOVE_NEOPAYMENTSREFUND = 'REMOVE_NEOPAYMENTSREFUND';
export const UPDATE_NEOPAYMENTSREFUND = 'UPDATE_NEOPAYMENTSREFUND';
export const REPLACE_NEOPAYMENTSREFUND = 'REPLACE_NEOPAYMENTSREFUND';
const apiUrl = API_DOMAIN + '/neopaymentsrefund'; 

axiosDefaults(null);

export const getNeoPaymentsRefundAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_NEOPAYMENTSREFUND, NeoPaymentsRefund: response.data.neopaymentsrefund.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNeoPaymentsRefund = (NeoPaymentsRefund) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NeoPaymentsRefund.id,
		id_payments: NeoPaymentsRefund.id_payments,
		amount: NeoPaymentsRefund.amount,
		tax1: NeoPaymentsRefund.tax1,
		tax2: NeoPaymentsRefund.tax2,
		tax_total: NeoPaymentsRefund.tax_total,
		total: NeoPaymentsRefund.total,
		user_creation: NeoPaymentsRefund.user_creation,
		user_update: NeoPaymentsRefund.user_update,
		creation_date: NeoPaymentsRefund.creation_date,
		update_date: NeoPaymentsRefund.update_date,
		status: NeoPaymentsRefund.status,
		refund_type: NeoPaymentsRefund.refund_type,
		observation: NeoPaymentsRefund.observation,
		result: NeoPaymentsRefund.result,
		taxable: NeoPaymentsRefund.taxable,
		nontaxable: NeoPaymentsRefund.nontaxable,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOPAYMENTSREFUND, payload: {
			id: data.id,
			id_payments: data.id_payments,
			amount: data.amount,
			tax1: data.tax1,
			tax2: data.tax2,
			tax_total: data.tax_total,
			total: data.total,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			refund_type: data.refund_type,
			observation: data.observation,
			result: data.result,
			taxable: data.taxable,
			nontaxable: data.nontaxable,
		}})
      })
      .then(() => {
        history.push("/NeoPaymentsRefund")
      })
      .catch(error => { throw(error) });
  };
};
export const getNeoPaymentsRefund = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.neopaymentsrefund);		  
        return dispatch({type: RECEIVE_NEOPAYMENTSREFUNDX, NeoPaymentsRefundOne: response.data.neopaymentsrefund});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNeoPaymentsRefundGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NeoPaymentsRefund/${id}/edit`)
  };
};
export const deleteNeoPaymentsRefund = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOPAYMENTSREFUND, payload: {id}})
      })
      .then(() => {
        history.push("/NeoPaymentsRefund")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNeoPaymentsRefund = (NeoPaymentsRefund) => {
  const id = NeoPaymentsRefund.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoPaymentsRefund.id}`, {
		id_payments: NeoPaymentsRefund.id_payments,
		amount: NeoPaymentsRefund.amount,
		tax1: NeoPaymentsRefund.tax1,
		tax2: NeoPaymentsRefund.tax2,
		tax_total: NeoPaymentsRefund.tax_total,
		total: NeoPaymentsRefund.total,
		user_creation: NeoPaymentsRefund.user_creation,
		user_update: NeoPaymentsRefund.user_update,
		creation_date: NeoPaymentsRefund.creation_date,
		update_date: NeoPaymentsRefund.update_date,
		status: NeoPaymentsRefund.status,
		refund_type: NeoPaymentsRefund.refund_type,
		observation: NeoPaymentsRefund.observation,
		result: NeoPaymentsRefund.result,
		taxable: NeoPaymentsRefund.taxable,
		nontaxable: NeoPaymentsRefund.nontaxable,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOPAYMENTSREFUND, payload: {
			id: data.id,
			id_payments: data.id_payments,
			amount: data.amount,
			tax1: data.tax1,
			tax2: data.tax2,
			tax_total: data.tax_total,
			total: data.total,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			refund_type: data.refund_type,
			observation: data.observation,
			result: data.result,
			taxable: data.taxable,
			nontaxable: data.nontaxable,
		}})
        dispatch({type: REPLACE_NEOPAYMENTSREFUND, payload: {
			id: data.id,
			id_payments: data.id_payments,
			amount: data.amount,
			tax1: data.tax1,
			tax2: data.tax2,
			tax_total: data.tax_total,
			total: data.total,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			refund_type: data.refund_type,
			observation: data.observation,
			result: data.result,
			taxable: data.taxable,
			nontaxable: data.nontaxable,
		}})
      })
      .then(() => {
        history.push(`/NeoPaymentsRefund/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNeoPaymentsRefund = (NeoPaymentsRefund, actionMode) => {
	if(actionMode===1){
        return addNeoPaymentsRefund(NeoPaymentsRefund);
      }else {
        return updateNeoPaymentsRefund(NeoPaymentsRefund);
      }
};