import { combineReducers } from 'redux';
 
import LogingReducer from '../redux/reducer';
//import ZxTeams from './ZxTeamsReducer';
//import ZxTeamsOne from './singleZxTeamsReducer';
import ZxCompanies from './ZxCompaniesReducer';
import ZxCompaniesOne from './singleZxCompaniesReducer';
 
import ZonlineUsuarios from './ZonlineUsuariosReducer';
import ZonlineUsuariosOne from './singleZonlineUsuariosReducer'; 
import VwFacturas from './VwFacturasReducer';
import VwFacturasOne from './singleVwFacturasReducer';   

import VwBoardReports from './VwBoardReducer';

import NeoVentes from './NeoVentesReducer';
import NeoVentesOne from './singleNeoVentesReducer';

import NeoSalesPlanningDaily from './NeoSalesPlanningDailyReducer';
import NeoSalesPlanningDailyOne from './singleNeoSalesPlanningDailyReducer';
import NeoSalesPlanningWeekly from './NeoSalesPlanningWeeklyReducer';
import NeoSalesPlanningWeeklyOne from './singleNeoSalesPlanningWeeklyReducer';

import NdiFields from './NdiFieldsReducer';
import NdiFieldsOne from './singleNdiFieldsReducer';

import NdiFieldOptions from './NdiFieldOptionsReducer';
import NdiFieldOptionsOne from './singleNdiFieldOptionsReducer';
import NdiFieldOptionsTranslation from './NdiFieldOptionsTranslationReducer';
import NdiFieldOptionsTranslationOne from './singleNdiFieldOptionsTranslationReducer'; 
import NdiFieldsTranslation from './NdiFieldsTranslationReducer';
import NdiFieldsTranslationOne from './singleNdiFieldsTranslationReducer';
import NdiFoldersProducts from './NdiFoldersProductsReducer';
import NdiFoldersProductsOne from './singleNdiFoldersProductsReducer';
import NdiProductFields from './NdiProductFieldsReducer';
import NdiProductFieldsOne from './singleNdiProductFieldsReducer';
import NdiProductTypes from './NdiProductTypesReducer';
import NdiProductTypesOne from './singleNdiProductTypesReducer';
import NdiProducts from './NdiProductsReducer';
import NdiProductsOne from './singleNdiProductsReducer';
import NdiProductsTranslation from './NdiProductsTranslationReducer';
import NdiProductsTranslationOne from './singleNdiProductsTranslationReducer';
import NdiTags from './NdiTagsReducer';
import NdiTagsOne from './singleNdiTagsReducer';
import NdiTicketDataLeft from './NdiTicketDataLeftReducer';
import NdiTicketDataLeftOne from './singleNdiTicketDataLeftReducer';

import NeoFolders from './NeoFoldersReducer';
//import NeoFoldersOne from './singleNeoFoldersReducer';
import NeoSearch from './NeoSearchReducer';
 

export default combineReducers({  
  LoggedUser: LogingReducer,
  //ZxTeams: ZxTeams,
  //ZxTeamsOne: ZxTeamsOne,
  
  
  ZxCompanies: ZxCompanies,
  ZxCompaniesOne: ZxCompaniesOne,
  
  ZonlineUsuarios: ZonlineUsuarios,
  ZonlineUsuariosOne: ZonlineUsuariosOne,
  
  VwFacturas: VwFacturas,
  VwFacturasOne: VwFacturasOne,
  
  VwBoardReports: VwBoardReports,

  NeoVentes: NeoVentes,
  NeoVentesOne: NeoVentesOne,

  NeoSalesPlanningDaily: NeoSalesPlanningDaily,
  NeoSalesPlanningDailyOne: NeoSalesPlanningDailyOne,
  NeoSalesPlanningWeekly: NeoSalesPlanningWeekly,
  NeoSalesPlanningWeeklyOne: NeoSalesPlanningWeeklyOne,
  
  NdiFields: NdiFields,
  NdiFieldsOne: NdiFieldsOne,
  
  NdiFieldOptions: NdiFieldOptions,
  NdiFieldOptionsOne: NdiFieldOptionsOne,
  NdiFieldOptionsTranslation: NdiFieldOptionsTranslation,
  NdiFieldOptionsTranslationOne: NdiFieldOptionsTranslationOne, 
  NdiFieldsTranslation: NdiFieldsTranslation,
  NdiFieldsTranslationOne: NdiFieldsTranslationOne,
  NdiFoldersProducts: NdiFoldersProducts,
  NdiFoldersProductsOne: NdiFoldersProductsOne,
  NdiProductFields: NdiProductFields,
  NdiProductFieldsOne: NdiProductFieldsOne,
  NdiProductTypes: NdiProductTypes,
  NdiProductTypesOne: NdiProductTypesOne,
  NdiProducts: NdiProducts,
  NdiProductsOne: NdiProductsOne,
  NdiProductsTranslation: NdiProductsTranslation,
  NdiProductsTranslationOne: NdiProductsTranslationOne,
  NdiTags: NdiTags,
  NdiTagsOne: NdiTagsOne,
  NdiTicketDataLeft: NdiTicketDataLeft,
  NdiTicketDataLeftOne: NdiTicketDataLeftOne,

  NeoFolders: NeoFolders,
  NeoSearch: NeoSearch,
  //NeoFoldersOne: NeoFoldersOne,
  
  
});