import { RECEIVE_NDIFIELDOPTIONSTRANSLATION, UPDATE_NDIFIELDOPTIONSTRANSLATION } from '../actions/NdiFieldOptionsTranslationAction';

export default function singleNdiFieldOptionsTranslationReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDIFIELDOPTIONSTRANSLATION:
      return action.NdiFieldOptionsTranslation;
    case UPDATE_NDIFIELDOPTIONSTRANSLATION:
      return {
        id: action.id,
field_option_id: action.payload.field_option_id,
lang: action.payload.lang,
text_label: action.payload.text_label,
      }
    default:
      return state;
  }
};