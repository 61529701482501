import { RECEIVE_NDIFOLDERSPRODUCTS, ADD_NDIFOLDERSPRODUCTS, REMOVE_NDIFOLDERSPRODUCTS, REPLACE_NDIFOLDERSPRODUCTS } from '../actions/NdiFoldersProductsAction';

const initialState = { NdiFoldersProducts: [] }
export default function NdiFoldersProductsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDIFOLDERSPRODUCTS:
      return action.NdiFoldersProducts;
    case ADD_NDIFOLDERSPRODUCTS:
      return [action.payload, ...state];
    case REMOVE_NDIFOLDERSPRODUCTS:
      return state.filter(NdiFoldersProducts => NdiFoldersProducts.id !== action.payload.id);
    case REPLACE_NDIFOLDERSPRODUCTS:
      return state.map((NdiFoldersProducts) => {
        if (NdiFoldersProducts.id === action.payload.id) {
          return {
            ...NdiFoldersProducts,
ticket_id: action.payload.ticket_id,
product_id: action.payload.product_id,
enabled: action.payload.enabled,
creation_date: action.payload.creation_date,
user_creation_id: action.payload.user_creation_id,
user_update_id: action.payload.user_update_id,
last_update: action.payload.last_update,
          }
        } else return NdiFoldersProducts;
      })
    default:
      return state;
  }
}
