import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getNeoPaymentsRefund, deleteNeoPaymentsRefund } from '../../actions/NeoPaymentsRefundAction';

class NeoPaymentsRefundInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		id_payments:'', e_id_payments: false,
		amount:'', e_amount: false,
		tax1:'', e_tax1: false,
		tax2:'', e_tax2: false,
		tax_total:'', e_tax_total: false,
		total:'', e_total: false,
		user_creation:'', e_user_creation: false,
		user_update:'', e_user_update: false,
		creation_date:'', e_creation_date: false,
		update_date:'', e_update_date: false,
		status:'', e_status: false,
		refund_type:'', e_refund_type: false,
		observation:'', e_observation: false,
		result:'', e_result: false,
		taxable:'', e_taxable: false,
		nontaxable:'', e_nontaxable: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getNeoPaymentsRefund(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getNeoPaymentsRefund(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.NeoPaymentsRefundOne);  console.log("0000********FIN**********");
		this.setState(response.NeoPaymentsRefundOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const NeoPaymentsRefund = this.props.NeoPaymentsRefund;
    const NeoPaymentsRefund = this.state; 
    return (
      <div>
        <h2>NeoPaymentsRefund</h2>
        <hr/>
        <p><label>{t('zqwid_x')}</label> &nbsp; &nbsp; #{NeoPaymentsRefund.id}</p>
        <p><label>{t('zqwid_payments_x')}</label><br/>{NeoPaymentsRefund.id_payments}</p>
        <p><label>{t('zqwamount_x')}</label><br/>{NeoPaymentsRefund.amount}</p>
        <p><label>{t('zqwtax1_x')}</label><br/>{NeoPaymentsRefund.tax1}</p>
        <p><label>{t('zqwtax2_x')}</label><br/>{NeoPaymentsRefund.tax2}</p>
        <p><label>{t('zqwtax_total_x')}</label><br/>{NeoPaymentsRefund.tax_total}</p>
        <p><label>{t('zqwtotal_x')}</label><br/>{NeoPaymentsRefund.total}</p>
        <p><label>{t('zqwuser_creation_x')}</label><br/>{NeoPaymentsRefund.user_creation}</p>
        <p><label>{t('zqwuser_update_x')}</label><br/>{NeoPaymentsRefund.user_update}</p>
        <p><label>{t('zqwcreation_date_x')}</label><br/>{NeoPaymentsRefund.creation_date}</p>
        <p><label>{t('zqwupdate_date_x')}</label><br/>{NeoPaymentsRefund.update_date}</p>
        <p><label>{t('zqwstatus_x')}</label><br/>{NeoPaymentsRefund.status}</p>
        <p><label>{t('zqwrefund_type_x')}</label><br/>{NeoPaymentsRefund.refund_type}</p>
        <p><label>{t('zqwobservation_x')}</label><br/>{NeoPaymentsRefund.observation}</p>
        <p><label>{t('zqwresult_x')}</label><br/>{NeoPaymentsRefund.result}</p>
        <p><label>{t('zqwtaxable_x')}</label><br/>{NeoPaymentsRefund.taxable}</p>
        <p><label>{t('zqwnontaxable_x')}</label><br/>{NeoPaymentsRefund.nontaxable}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/NeoPaymentsRefund/${NeoPaymentsRefund.id}/edit`, state: { NeoPaymentsRefund: NeoPaymentsRefund } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNeoPaymentsRefund(NeoPaymentsRefund.id)}>          
            {t('Delete')}
          </button>
          <Link to="/NeoPaymentsRefund" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransNeoPaymentsRefundInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ NeoPaymentsRefund: state.NeoPaymentsRefund });                 

const mapDispatchToProps = { getNeoPaymentsRefund, deleteNeoPaymentsRefund };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoPaymentsRefundInfo));   

