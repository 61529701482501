import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoVentes, addNeoVentesSC, addOrUpdateRefunds, getNeoVentes, getClientSearch, getB2BClientSearch, saveB2BClient, checkClientEligibility, getB2BContractListDetails, getClientAddress, getCodePromo, getNeoVentesPromos, getProductInfo, getPartialInvoicePayments, getAllProducts, getChargeDeskStatus, getClientTransactionsFullInfo } from '../../actions/NeoVentesAction'; 
//import { addOrUpdateNeoProducts, getNeoProducts, getNeoProductsAll, getNeoProductsGoto, deleteNeoProducts } from '../../actions/NeoProductsAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
//import CustomToolbarSelect from "../CustomToolbarSelect";


import Select from 'react-select';
import Autocomplete from '@material-ui/lab/Autocomplete';    
import InputMask from "react-input-mask";
import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormGroup from '@material-ui/core/FormGroup'; 
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Toastr from "../../messages";
import  Loader from "../../loader";
import MuiAlert from '@material-ui/lab/Alert';

import SaveIcon from '@material-ui/icons/Save';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import HelpIcon from '@material-ui/icons/Help';
import EmailIcon from '@material-ui/icons/Email';
//import SaveSharpIcon from '@material-ui/icons/SaveSharp';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 

import ZAFClient from 'zendesk_app_framework_sdk';
import { RotateRightSharp } from '@material-ui/icons';
import StripePayment from '../../StripePayment'; 
import moment from 'moment';
import  getProvinceTaxInfo from "../../appTaxes"; 

/////////////////////////////
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';  
import Grid from '@material-ui/core/Grid'; 
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete'; 
import DownloadIcon from '@material-ui/icons/CloudDownload';
//import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import SpellcheckIcon from '@material-ui/icons/Edit';
//import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails'; 
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import  { dateFormat } from "../../appDateFormat";
import history from '../../history'; 


class NeoRefundPaymentList extends Component {
  
  constructor(props) {
    super(props);
    /*this.state = {
		id:0,
		id_invoices:'', e_id_invoices: false,
		amount:'', e_amount: false,
		tax1:'', e_tax1: false,
		tax2:'', e_tax2: false,
		token:'', e_token: false,
		email:'', e_email: false,
		result:'', e_result: false,
		code:'', e_code: false,
		declined_code:'', e_declined_code: false,
		transact:'', e_transact: false,
		created:'', e_created: false,
    }*/

    this.stateInit = {
			id:0,
			name:'', e_name: false,
			description:'', e_description: false,
			amount:'', e_amount: false,
			
			productCart:  [], 
			productId:"", productTitre:'', product:{}, productPrice:'', productPriceTax:'', productPriceTaxOnly:0, productExtraOptions:[], 
			productExtraOptionCtrl:null, productExtraOptionCtrl2:[], showProductOption:false, 
			prodTagPrice:  [], prodTagPriceTax:  [], prodTagPriceTaxOnly:  [], prodTagTotOptions:  [], grandTotal: 0, 
		
			idClient:'0',
			firstname:'', e_firstname: false,
			lastname:'', e_lastname: false,
			cell:'', e_cell: false,
			terms:'', e_terms: false,
			active:'', e_active: false,
			created:'', e_created: false,
			email:'', e_email: false,
			id_users:'0', e_id_users: false,
			language:'fr', e_language: false,
			push:'', e_push: false,
			id_zendesk:'', e_id_zendesk: false,
			sms:1, e_sms: false,
			stripe_id:'', e_stripe_id: false,
		
		
			idAddress:0, 
			address:'', e_address: false,
			unitsuite:'', e_unitsuite: false,
			city:'', e_city: false,
			province:'QC', e_province: false, province2:'', 
			cp:'', e_cp: false,
			country:'Canada', e_country: false,
			company:'', e_company: false, 
			existedClientRO: false, openPossibleZipCode:false, listAddressOnCode: null, listZipCodeAddresses:[], countryTuner:'',  
			listPromotionCodes:[], codepromoOptions:[], openInfoStepsPopup:false, infoStepsPopupno:0,
			infoProduct:{data:[]}, infoProduct_title:"", infoProduct_excerpt:"", partialCanClose:0,
			openTransactionPopup: false, twoStepSale:"",
		
			 
			listOfSuggestion:[],
			valueClientSearch:'', 
			inputValueClientSearch:'',
			selectedIdSuggestion:'',
	  
			listOfSuggestion2:[],
			valueClientSearch2:'', 
			inputValueClientSearch2:'',
			selectedIdSuggestion2:'',
	  
			clientInfo:{ }, 
			clientAddress:{ },
			globalStepWorkflow: 1, // 1 init 2:start with product 3:start with customerInfo 4:payment 5: case close 
			workflowType: 1, //1 begin, 2 with clientInfo, 3 with Prod
	  
			stripePaymentOpen: false, isLoaderOpen:false, 
			toastrMessage: '', toastrType: '', toastrDuration: 6000,
			cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
			orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
		
			stripeToken:'',
			dossier:'', //421
			isRecurrentPayment: 0,  nowPayment: 0, qtyPayment: 1, laterpayment:4, fromDatePayment:'', 
			paymentRound:0, paymentWay: 'full', nowPaymentRO: true, recurPaymentAmount: 0,
			paymentsReceived: [0], totalPaymentReceived: 0, paymentWayRO: false, beforePaymentTotal:0, 
			id_invoice: 0, pMethod:1, status_invoice:'', 
			showProdMulti:0, productsList:{"products": []}, openPListAsOnTheSite: false,
			errorTransacMessage: "", emailDoesNotExist: 0, openPossibleListOfClient: false,
			listClientOnCode:[], possibleListOfClient:[],
			statusChargeDesk: 0, chargeDeskIdStr:"",
			openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}, possibleListOfCDStatusInfo:[],  
			taxInfo:{}, isupsale:false, upsaleTicket:"", isAmerindian:false, no_amerindien:"", 
			listOfTickets:[], goProd:0, 
			newTicket:false, currentTicket:'', disabledBtn:false, 


      refundtype:'', observation:'', clientspecimen:null, 
      refundList:[],  refunds:[], refundValList:[],    
      currentRefundValue:0, refundedValue:0, 
      currentRefundInvValue:0, refundedInvValue:0, refundedInvId:0, refundedInvPayId:0, refundedInvPayVal:0, 
      clientTransInfo:{}, 
		};
	  
			this.state = JSON.parse(JSON.stringify(this.stateInit)); // Object.assign(this.stateInit);

  }

  productsList={"products": []}; // this.state.productsList; // productList();

  componentDidMount() {                                                         
    //this.props.getNeoPayments(this.props.match.params.id);
    /*const idx = this.props.match.params.id;  
    Promise.resolve(this.props.getNeoPayments(idx))
    .then((response)=>{
      //console.log("0000********INI**********"); console.log(response.NeoPaymentsOne);  console.log("0000********FIN**********");
      this.setState(response.NeoPaymentsOne); 
      return response;
    });*/

    // const { t } = this.props;
		// const taxInfo=getProvinceTaxInfo("QC");
		// this.setState({ ["taxInfo"]: taxInfo,"language":"" });  
		this.setState({ ["productCart"]: [],"productId":"",	 });  
    this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
   this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
     this.setState({ ["nowPayment"]:  0 });


   //this.props.getNeoProductsAll();
   //this.productsList=this.props.
   const clientProdTransInfo=this.props.clientProdTransInfo; 
   const client=clientProdTransInfo.client; 
   //const transactions=clientProdTransInfo.transactions;   

   //const Alert=this.Alert;
   //const clientProdTransInfo=
   let stripeId="";
   console.log("woooooooooooooo ", client);
   if(client!==undefined && client!==null){  
     this.setState(client);  
     this.setState({["clientInfo"]: client, ["idClient"]: parseInt(client.id)});  
   }

   if(this.props.productsList.products.length > 0){
     this.productsList=this.props.productsList;
   }
   if(this.props.productsList.products.length > 0){
     this.productsList=this.props.productsList;
   }
   if(this.props.currentTicket!==undefined && this.props.currentTicket!==null && this.props.currentTicket!==""){ 
     this.setState({ ["currentTicket"]: this.props.currentTicket});  
   }

    
   if(this.props.clientAddress!==undefined && this.props.clientAddress!==null){ 
     const taxInfo=getProvinceTaxInfo(this.props.clientAddress.province); //QC
     this.setState({ ["clientAddress"]: this.props.clientAddress, 
     ["province"]: this.props.clientAddress.province, ["taxInfo"]: taxInfo, });  
   }


    const invoices=this.props.invoices;
    const payments=this.props.payments;
    const refunds=this.props.refunds;
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;

    console.log("woooooooooooooo paymentId=", paymentId, "refunds", refunds);

    if(refunds!==undefined && refunds.length > 0){
      this.setState({ ["refunds"]: refunds});  
    }

    const paymentRefundList=[];  const refundValList=[]; 
    let refundedInvValue=0; let refundedInvPayVal=0;
    //getXRecordObjects(myArr, fieldName, fieldValue, oneObject) 
    //getXRecordObjects(myArr, fieldName, fieldValue, oneObject)
    if(payments!==undefined && payments.length > 0){ 
      const paymentsx=this.getXRecordObjects(payments, "id_invoices", [invoiceId], false);
      const paymentIds=this.array_column(paymentsx, "id", null);
      const refundsx=this.getXRecordObjects(refunds, "id_payments", paymentIds, false);
      //const zzzzz=this.getXRecordObjects(myArr, fieldName, fieldValue, oneObject);
      for(const paymentx of paymentsx){   //for(const paymentx of payments){  
        console.log("OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
      }  
    }
    
    

    if(payments!==undefined && payments.length > 0){ 
      const refundList=[];
      const paymentsx=this.getXRecordObjects(payments, "id_invoices", [invoiceId], false);
      const paymentIds=this.array_column(paymentsx, "id", null);
      const refundsx=this.getXRecordObjects(refunds, "id_payments", paymentIds, false);
      //const zzzzz=this.getXRecordObjects(myArr, fieldName, fieldValue, oneObject);
      for(const paymentx of paymentsx){   //for(const paymentx of payments){  
        console.log("0000...OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
        let refundedAmount=0;
        if(refunds!==undefined && refunds.length > 0){
          for(const refund of refunds){   
            console.log("0000...OOOOOOOOOOOOOOOOX==REFUUUUUUUND ", refundedAmount, refund);
            if(refund.id_payments===paymentx.id){
              refundedAmount +=parseFloat(refund.amount);
            } 
            console.log("0000...OOOOOOOOOOOOOOOOX==REFUUUUUUUND .... ", refundedAmount);
          }
        }

        // const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount, refundedAmount:refundedAmount }; 
        // refundList.push(baseElem);  
        // refundValList.push(refundedAmount); 
        if(paymentId===paymentx.id){
          const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount, refundedAmount:refundedAmount }; 
          refundList.push(baseElem);  
          refundValList.push(refundedAmount); 

          //currentRefundInvValue:0, refundedInvValue:0, refundedInvId:0, refundedInvPayId:0,
          refundedInvValue=refundedAmount;  
          refundedInvPayVal=parseFloat(paymentx.amount); 
        } 
      }
       

      const openkey=new Date().getTime(); //new Date.now();
      console.log("0000...wwwwwwwwwwwww REGISTRE REFUND ", openkey, refundedInvValue, refundedInvPayVal, refundValList, refundList);

      this.setState({ ["refundList"]: refundList, ["refundValList"]: refundValList, ["refundedInvValue"]: refundedInvValue, ["refundedInvPayVal"]: refundedInvPayVal, ["open"]:openkey, });  
    }



  }

  componentDidUpdate(prevProps) {
		/*if (prevProps.openx !== this.props.openx) {
		  //this.updateAndNotify();
		  if(this.props.openx){
			//console.log("WEEEEEEEEEEEE MINIPAYMENT componentWillMount OPEN ", new Date());
			this.setState({ ["productCart"]: [],"productId":"",	 });  
			this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
			this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
			this.setState({ ["nowPayment"]:  0, ["paymentRound"]:  0 });
		  }
		}*/

    const invoices=this.props.invoices;
    const payments=this.props.payments;
    const refunds=this.props.refunds;
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;

    

    if(refunds!==undefined && refunds.length > 0){
      //this.setState({ ["refunds"]: refunds});
      if (prevProps.refunds !== this.props.refunds) {
        this.setState({ ["refunds"]: refunds});
      }  
    }

    const paymentRefundList=[]; const refundValList=[]; 
    let refundedInvValue=0; let refundedInvPayVal=0;
    //getXRecordObjects(myArr, fieldName, fieldValue, oneObject)
    console.log("...OOOOOOOOOOOOOOOOX==openx-openx-openx NO-EVAL ",  this.props.openx);
    if (prevProps.openx !== this.props.openx) {
      console.log("...OOOOOOOOOOOOOOOOX==openx-openx-openx ",  this.props.openx);
      if(payments!==undefined && payments.length > 0){ 
        const refundList=[];
        const paymentsx=this.getXRecordObjects(payments, "id_invoices", [invoiceId], false);
        const paymentIds=this.array_column(paymentsx, "id", null);
        const refundsx=this.getXRecordObjects(refunds, "id_payments", paymentIds, false);
        //const zzzzz=this.getXRecordObjects(myArr, fieldName, fieldValue, oneObject);
        for(const paymentx of paymentsx){   //for(const paymentx of payments){  
          console.log("...OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
          let refundedAmount=0;
          if(refunds!==undefined && refunds.length > 0){
            for(const refund of refunds){   
              console.log("...OOOOOOOOOOOOOOOOX==REFUUUUUUUND ", refundedAmount, refund);
              if(refund.id_payments===paymentx.id){
                refundedAmount +=parseFloat(refund.amount);
              } 
              console.log("...OOOOOOOOOOOOOOOOX==REFUUUUUUUND .... ", refundedAmount);
            }
          }

          //const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount, refundedAmount:refundedAmount }; 
          //refundList.push(baseElem);  
          //refundValList.push(refundedAmount); 
          if(paymentId===paymentx.id){
            const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount, refundedAmount:refundedAmount }; 
            refundList.push(baseElem);  
            refundValList.push(refundedAmount); 

            //currentRefundInvValue:0, refundedInvValue:0, refundedInvId:0, refundedInvPayId:0,
            refundedInvValue=refundedAmount;  
            refundedInvPayVal=parseFloat(paymentx.amount); 
          } 
        }
        
        
        //currentRefundValue:0, refundedValue:0, 
        /*if(refunds!==undefined && refunds.length > 0){
          for(const refund of refunds){   //for(const paymentx of payments){  
            console.log("...OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
            const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount }; 
            refundList.push(baseElem);  
            refundValList.push(0);  
          }
        }*/

        const openkey=new Date().getTime(); //new Date.now();
        console.log("...wwwwwwwwwwwww REGISTRE REFUND ", openkey, refundedInvValue, refundedInvPayVal, refundValList, refundList);

        this.setState({ ["refundList"]: refundList, ["refundValList"]: refundValList, ["refundedInvValue"]: refundedInvValue, ["refundedInvPayVal"]: refundedInvPayVal, ["open"]:openkey, });  
      } 

      /*if(refunds!==undefined && refunds.length > 0){
        for(const refund of refunds){   //for(const paymentx of payments){  
          console.log("...OOOOOOOOOOOOOOOOX ", paymentx, paymentIds, refundsx);
          const baseElem={payment_id:paymentx.id, amount:0, refundtype:'', max:paymentx.amount }; 
          refundList.push(baseElem);  
          refundValList.push(0);  
        }
      }*/
    }   
	}


  
	  
	setMultiactions = (eventargs) => {
		const closeme=eventargs.closeme;
		// let isChecked=false; 
		// if(event.target.checked){ isChecked=true; }
		// this.setState({ [event.target.name]: isChecked });
		if(closeme!==null && closeme!==undefined && closeme==="close"){
			const obj={};
		} 
	
	};

	
	closeFolderProductPopup  = (anyEvent) => {     
		this.setState({["openProductFolder"]: false, ["disabledBtn"]: false }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success"); 
		const obj={closeme:'close'};
		if(this.state.paymentRound >0){
			obj.refreshafterPayment=1;
		} 
		// const taxInfo=this.state.taxInfo; 
		// const clientAddress=this.state.clientAddress;
		// const province=this.state.province;

		// const taxInfo=JSON.parse(JSON.stringify(this.state.taxInfo));
		// const clientAddress=JSON.parse(JSON.stringify(this.state.clientAddress));
		// const province=JSON.parse(JSON.stringify(this.state.province));
		// const currentTicket=JSON.parse(JSON.stringify(this.state.currentTicket));

		// this.setState(JSON.parse(JSON.stringify(this.stateInit)));
		// this.setState({ ["clientAddress"]: clientAddress, 
		// 	["province"]: province, ["taxInfo"]: taxInfo, ["currentTicket"]: currentTicket});  

		this.props.multiactions(obj);
	}

	handleTransactionChange = (event) => {                                                             
		//event.preventDefault();  
		//const stepTrack=event.target.dataset.prodcontrol;
		const selVal= event.value;  //productId
		this.setState({ ["transactionId"]: selVal });    
	};

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info	  
		//console.log("showToast showToast "); //console.log(arg);
		//alert("hello it is an eventDrop here "+arg.date);
		//Toastr("Hello I am a toast message right here, for testing purpose...");
		//toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		this.setState({ // update a property
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, 5500);
	}

  //handleChangeRefund
  handleChangeRefund = (event) => {
    const eventName=event.target.name;
    const eventVal=event.target.value;
    const index=event.target.dataset.index;
    const paymentId=event.target.dataset.paymentid;
    const max=event.target.dataset.max;
    const refunded=event.target.dataset.refunded;
    //this.setState({ [event.target.name]: event.target.value }); 
    const refundList=this.state.refundList;  
    console.log("eventName, eventVal, index, paymentId, refunded:", eventName, eventVal, index, paymentId, max,refunded, refundList);

    if(parseFloat(eventVal) >parseFloat(max)){
      this.showToast("S'il vous plaît veuillez d'ajouter une valeur qui est moins ou égal à:"+max, "error");
      return false;
    }

    const baseElemx=refundList[index];

    if(parseFloat(refunded) >=parseFloat(max)){
    //if(baseElemx!==null && baseElemx.refundedAmount!==undefined && parseFloat(baseElemx.refundedAmount) >=parseFloat(max)){  
      this.showToast("Désolé vous ne pouvez pas continuer avec le remboursement parcequ'on a déjà remboursé: "+baseElemx.refundedAmount, "error");
      return false;
    }

    if(refundList.length >0){
      const baseElem=refundList[index];
      if(eventName==="refundtype"){ baseElem.refundtype=eventVal; }
      else if(eventName==="amount"){ baseElem.amount=eventVal; } 
      refundList[index]=baseElem;
      this.setState({ ["refundList"]: refundList });   
    }else{
      const baseElem={payment_id:paymentId, amount:0, refundtype:'', max:max };
      if(eventName==="refundtype"){ baseElem.refundtype=eventVal; }
      else if(eventName==="amount"){ baseElem.amount=eventVal; }
      refundList.push(baseElem); 
      this.setState({ ["refundList"]: refundList }); 
    }

    console.log("WATCHEEEEEEEEEEEER", refundList); 
  };
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  // if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		//   this.setState({ ["e_"+event.target.name]: false });
		//   if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
		// 	  this.setState({ ["e_"+event.target.name]: true }); 	
		//   }
	  // } 	
  };

  handleApplyRefund = (event) => {
    event.preventDefault();
    const { t } = this.props;
    //this.setState({ [event.target.name]: event.target.value });
    const refundList=this.state.refundList; 
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;
    let validRefund=0; 
    const refList=[];
    let refundtype=0;
    const observation=this.state.observation;

    console.log("ABSURDE ", refundList);

    for(const refund of refundList){
      if(refund.refundtype==="total"){ 
        refundtype++;   
      }
      else if(refund.refundtype==="partiel"){
        refundtype++;  
      }
    } 

    if(refundtype  <1){ 
      this.showToast("S'il vous plaît veuillez de choisir un type de remboursement.", "error");
      return false;
    } 

    for(const refund of refundList){
      if(refund.refundtype==="total"){ 
        validRefund++; 
        refList.push(refund);
      }
      else if(refund.refundtype==="partiel" && parseFloat(refund.amount) >0 
      && parseFloat(refund.amount)<=parseFloat(refund.max)){
         validRefund++; 
         refList.push(refund);
      }
    } 
    if(validRefund <1){
      //t("zqwNVFrmEl1_x")
      this.showToast("S'il vous plaît veuillez d'ajouter le montant de paiement à rembourser.", "error");
      return false;
    }
    
    if(observation===null || observation===""){
      this.showToast("S'il vous plaît veuillez d'expliquer la raison du remboursement.", "error");
      return false;
    }
    
    let objToSend=null;
    const clientspecimen=this.state.clientspecimen;
    //if(clientspecimen!==null && clientspecimen.length >0){ 
      //console.log("wewewe l="+clientspecimen.length+" n="+clientspecimen.name+" t="+clientspecimen.type)
    if(clientspecimen!==null && clientspecimen.name!==undefined && clientspecimen.name!==null){
      //const objToSend={invoiceId:invoiceId, observation:observation, refunds:refList};
      //}else{
       objToSend = new FormData();  
        objToSend.append( 
          "file", 
          clientspecimen, 
          clientspecimen.name 
        ); 
        objToSend.append('isMULTIPART', 1); 
        objToSend.append('xfiletype', clientspecimen.type); 
        objToSend.append('invoiceId', invoiceId); 
        objToSend.append('observation', observation); 
        objToSend.append('refunds', JSON.stringify(refList)); 
        console.log("weeeeeeeeeeeeeeeee sending with file ");  
    }else{
      objToSend={invoiceId:invoiceId, observation:observation, refunds:refList};
      console.log("weeeeeeeeeeeeeeeee nooooooooooo file at all ");
    }

    //this.showToast("Uuuuuuuh way, you have passed it.", "success"); //return false; 
    const this2=this; 
    this2.setState({ ["isLoaderOpen"]: true});  
    Promise.resolve(this.props.addOrUpdateRefunds(objToSend))
    .then((response)=>{
      const clientTransInfo=response.NeoVentes.data;
      //this.setState(response.NdiFieldsOne);
      /*const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
      const isClientId=clientProdTransInfo.client.id;
      const isClientLead=isClientId >0?false:true; 
      //console.log("..HOHOHO response from server .... ");  //console.log(response.NdiFieldsOne.data);
      this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
      this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead }); 	
      //console.log("HOHOHO just assinged to dynamicFormAllData .... "); */	

      console.log("..HOHOHO response from server .... ");  console.log(response);
      
      this.setState({ ["isLoaderOpen"]: false, ["refunds"]: clientTransInfo.refunds, ["disabledBtn"]:true, ["paymentRound"]:1,});
      this.showToast(t("zqwNFFtxt11_x"), "success"); 
      // this.saveUpdateTicketData(null);  
      // setTimeout(() => {
      // 	this.showToast(t("zqwNFFtxt10_x"), "warning");  
      // 	this.saveUpdateTicketData(null);  
      // }, 10000);   
      return response;
    }).catch(function(error){ 
      this2.setState({ ["isLoaderOpen"]: false});  
      //alert(error);
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n "+t("zqwNVFrmEl14_x")
      }
      //this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      //console.log(rawError);
      this2.showToast(errMessage, "error"); 
    });
    

  };

  getXRecordObjects = (myArr, fieldName, fieldValues, oneObject) => {
    let fieldBlock=[]; let fieldBlockOne=null; 
    let promList = myArr.length > 0
      && myArr.map((item, i) => {  
      //let fieldBlock=null; 
      //const zx=item[fieldName];      
        if(fieldValues!==null && fieldValues.length > 0 && fieldValues.includes(item[fieldName])){
          if(oneObject){
            fieldBlockOne=item; 
          }else{
            fieldBlock.push(item);
          }
          //fieldBlock=item; 
        }       

      // if(item.product_type_id===fieldValue){
      //   fieldBlock=item; 
      // }
      
      return ( null );
    }, this);
    
      //return fieldBlock;
      if(oneObject){
        return fieldBlockOne; 
      }else{
        return fieldBlock;
      }
  }
 


  getXRecordObjects000 = (myArr, fieldName, fieldValue, oneObject) => {
    let fieldBlock=[]; let fieldBlockOne=null; 
    let promList = myArr.length > 0
      && myArr.map((item, i) => {  
      //let fieldBlock=null;       
        if(item[fieldName]===fieldValue){
          if(oneObject){
            fieldBlockOne=item; 
          }else{
            fieldBlock.push(item);
          }
          //fieldBlock=item; 
        }       

      // if(item.product_type_id===fieldValue){
      //   fieldBlock=item; 
      // }
      
      return ( null );
    }, this);
    
      //return fieldBlock;
      if(oneObject){
        return fieldBlockOne; 
      }else{
        return fieldBlock;
      }
  }


  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  render() {
    const { t } = this.props;
    //const NeoPayments = this.props.NeoPayments;
    const NeoPayments = this.state; 
    const open = this.props.openx; 

    const globalStepWorkflow=this.state.globalStepWorkflow;
    const myToastMessage=this.state.toastrMessage;
    const toastrType=this.state.toastrType;
    const Alert=this.Alert;

    const invoices=this.props.invoices;
    const payments=this.props.payments;
    const refunds=this.props.refunds;
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;
    const PaymentRefundList=this.PaymentRefundList;
    const TransactionDetailedRefundList=this.TransactionDetailedRefundList;
    const disabledBtn=this.state.disabledBtn;
    const refundValList=this.state.refundValList;
    const refundList=this.state.refundList;
    //refundedAmount
    const refundedValue=this.state.refundedInvValue;
    const paymentValue=this.state.refundedInvPayVal;
    let disabledBtn2=false;
    if(refundedValue >=paymentValue){
      disabledBtn2=true;
    }
     

    console.log("WEEEEEEEEEEEEEEEEEEELL KEY ", open);
 
    const openk=open;
    const paymentRefundList=[];

    const credit2=this.props.credit;
    const credit3=this.state.clientTransInfo.credit; 
    let credit=null;
    if(credit3!==undefined){
      credit=credit3;
    }else{
      if(credit2!==undefined && credit2!==null && credit2.amount!==undefined){
        credit=credit2;
      }
    }





    let totalRemboursement=0; let totalCredit=0;
    const refundsx=this.state.refunds; 
    ///const paymentId=this.props.paymentId;
    for(let item of refundsx){
      if(item!==null && paymentId===item.id_payments){
        let refund_type="Remboursement";
        const valLine=parseFloat(item.total);
        if(item.refund_type==="R"){ 
          refund_type="Credit"; 
          totalCredit +=valLine; 
        }
        else if(item.refund_type==="C"){ refund_type="Retenue Client"; }else{
          refund_type +="/"+item.refund_type;
          totalRemboursement +=valLine; 
        }

      }
    }

    // let refundlistx = refundsx.length > 0
    // 	&& refundsx.map((item, i) => { 
    //     //console.log(item);
    //     if(item!==null && paymentId===item.id_payments){
    //       let refund_type="Remboursement";
    //       if(item.refund_type==="R"){ refund_type="Credit"; }
    //       else if(item.refund_type==="C"){ refund_type="Retenue Client"; }else{
    //         refund_type +="/"+item.refund_type;
    //       }

    //     }
    //   return (
    //     null
    //   );
    // }, this);



    /*
    if(payments!==undefined && payments.length > 0){
      let myInd=-1;
      for(const paymentx of payments){
        if(paymentx.id_invoices===invoiceId){
          myInd++;
          //const valx=refundedValue; //refundValList[myInd];
          const valx=refundValList[myInd];

          const baseElem=refundList[myInd]; 
          let refundedAmount="";
          if(baseElem!==undefined && baseElem!==null && baseElem.refundedAmount===undefined){
            refundedAmount=""+baseElem.refundedAmount;
          }


          paymentRefundList.push(<div key={"rf-"+paymentx.id_invoices+"-"+paymentx.id+openk}>
          <div className="row"> 
          <div className="form-group" key={"f1-"+paymentx.id_invoices+"-"+paymentx.id}>
            <label htmlFor="id">Payment: #{paymentx.id}</label>  
          </div><br/><br/></div>
          <div className="row"> 
          <div className="form-group" key={"f2-"+paymentx.id_invoices+"-"+paymentx.id}>
            <label htmlFor="refundtype">Type Remboursement:</label> 
            <select name="refundtype"  className={'form-control'} data-index={myInd} 
            data-paymentid={paymentx.id}   data-max={paymentx.amount}  
                defaultValue={this.state.refundtype}  onChange={this.handleChangeRefund} > 
                <option value=""> Select/Choisir</option> 
                <option value="total"> Total </option> 
                <option value="partiel"> Partiel </option> 
            </select>     
          </div><br/><br/></div>
          <div className="row">
            <div style={{display:'block', width:'100%'}}><label>{t('zqwamount_x')}</label></div>
            <div className="form-row" key={"f3-"+paymentx.id_invoices+"-"+paymentx.id}>
              {/* <label htmlFor="amount">{t('zqwamount_x')}</label> /}
              <div className="form-group col-md-6"> 
              <input type="number" name="amount" id="amount" defaultValue={0} 
              min="1" max={paymentx.amount} step="0.1" 
              onChange={this.handleChangeRefund} onBlur={this.handleFocusout}  
              data-index={myInd} data-paymentid={paymentx.id}  data-max={paymentx.amount} data-refunded={paymentx.refundedAmount}  
                    className='form-control' placeholder={""} 
                  readOnly={(parseFloat(valx)>=parseFloat(paymentx.amount))?true:false}  />
              </div><div className="form-group col-md-3">/{paymentx.amount}  </div>
                    Total avec taxes {paymentx.total}<br/>
                    Montant déja remborsé: {refundedValue}$ 
                     {/* {((baseElem===undefined || baseElem===null) && baseElem.refundedAmount===undefined)?'':baseElem.refundedAmount}  /}
            </div></div>
          <hr/>
          </div>
        ); 
        } 
      }
    }*/

    /*let paymentRefundList = payments.length > 0
    	&& payments.map((item, i) => {
      //productOptions.push({ value: item.id, label: item.titre }); 
      if(item.id_invoices!==invoiceId){
        return null;
      } 
      return (
        <div className="form-group" style={{display:'none'}} >
			    <label htmlFor="id">{t('zqwid_x')}: #{NeoPayments.id}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} />  
        </div>
      );
	}, this);*/


  return (
    <div> 
        <div className="text-center"> 
          <h5>Liste de Remboursement de Facture: #{invoiceId}</h5>   
        </div>
        {(credit!==null && credit.amount!==undefined)?
        <div className="text-center"> 
          <Alert severity="info">
            <h6 className="card-title">{credit.clientName} ---  Montant crédit: <b>{credit.amount}$cad</b></h6>
          </Alert>
        </div>:""
      }

      <div className="form-row"> 
          &nbsp; 
      </div>
      <div className="form-row"> 
          <h5>Pour le Paiement: #{paymentId}</h5>   
      </div>
      <div className="form-row">
        <div className="md-4">
          <div className="text-center"> 
            <Alert severity="warning">
              <h6 className="card-title">Remboursement: <b>{totalRemboursement.toFixed(2)}$cad</b></h6>
            </Alert>  
          </div>  
        </div>

        <div className="md-4 ml-5">
          <div className="text-center"> 
            <Alert severity="success">
              <h6 className="card-title">Montant crédit: <b>{totalCredit.toFixed(2)}$cad</b></h6>
            </Alert>  
          </div>  
        </div>

        <div className="md-3 ml-5">
          <div className="text-center"> 
            <Alert severity="error">
              <h6 className="card-title">total: <b>{(totalRemboursement+totalCredit).toFixed(2)}$cad</b></h6>
            </Alert>
          </div>
        </div>
        
      </div>


      {/* <h2>NeoPayments</h2> {t("zqwPuptxt1_x")} */}
      {/* <hr/> */}
      {/* <div className="form-group">
        <label htmlFor="name">Facture: #{invoiceId}</label>  
      </div>
      <hr/> */}
      
      {/* <div className="container">{paymentRefundList}</div>
      <div className="form-row">
        <label htmlFor="observation">Observation</label>
        <textarea id="observation" name="observation" rows="4" cols="50" defaultValue={this.state.observation} 
        maxLength={250} 
        onChange={this.handleChange} onBlur={this.handleFocusout} className={'form-control'}></textarea>
         
      </div>
      <div className="form-row">
        <label>Specimen cheque</label> 
      </div>
      <div className="form-row"> 
        <input type="file" id="clientspecimen" name="clientspecimen"  onChange={this.onFileChange}></input>
      </div>
      <p> &nbsp; </p> */}
      
       
      {/* <div className="btn-group"> */}
        {/* <Link to={{ pathname: `/NeoPayments/${NeoPayments.id}/edit`, state: { NeoPayments: NeoPayments } }} className='btn btn-info'>  
          {t('Edit')}
        </Link> */}

        {/* <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNeoPayments(NeoPayments.id)}>          
          {t('Delete')}
        </button> disabled={disabledBtn}  */}

        {/* <button className="btn btn-danger" type="button"  onClick={this.handleApplyRefund} disabled={disabledBtn2?disabledBtn2:disabledBtn}>          
          Appliquer Remboursement
        </button> */}

        {/* <Link to="/NeoPayments" className="btn btn-secondary">{t('Close')}</Link>                                                  */}
      {/* </div>
      <hr/> */}
      <PaymentRefundList /><br/>&nbsp;<br/>
      <TransactionDetailedRefundList />
      
       
      {/* <div className="form-row neoxtbl">	 
        <div className="form-group col-md-9"> 
          <input type="button" onClick={this.closeFolderProductPopup} className="btn btn-success" value={t('zqwTNVFtxt20_x')}  /> &nbsp; &nbsp; 
        </div> 
      </div>  */}
  <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
  <Loader  openx={this.state.isLoaderOpen}  />
    </div>
  );

  }

  onFileChange = event => { 
    // Update the state 
    //console.log("weeeeeeeeeeeeeeeee file change "+event.target.files[0].name);
    //this.setState({ ["clientspecimen"]: event.target.files[0] }); 
    if(event.target.files[0]===undefined || event.target.files[0].name===undefined){
      this.setState({ ["clientspecimen"]: null }); 
    }else{
      this.setState({ ["clientspecimen"]: event.target.files[0] }); 
    }
  }; 


  array_column =(input, ColumnKey, IndexKey) =>  {  
    //   discuss at: https://locutus.io/php/array_column/
    if (input !== null && (typeof input === 'object' || Array.isArray(input))) {
      const newarray = []
      if (typeof input === 'object') {
        const temparray = []
        for (const key of Object.keys(input)) {
          temparray.push(input[key])
        }
        input = temparray
      }
      if (Array.isArray(input)) {
        for (const key of input.keys()) {
          if (IndexKey && input[key][IndexKey]) {
            if (ColumnKey) {
              newarray[input[key][IndexKey]] = input[key][ColumnKey]
            } else {
              newarray[input[key][IndexKey]] = input[key]
            }
          } else {
            if (ColumnKey) {
              newarray.push(input[key][ColumnKey])
            } else {
              newarray.push(input[key])
            }
          }
        }
      }
      return Object.assign({}, newarray)
    }
  }

  PaymentRefundList = (props) => {
     const refunds=this.state.refunds;
    // const refundsList=this.state.refundList;
    //const refunds=this.state.refundList;
    const paymentId=this.props.paymentId;
    if(refunds.length===0){
      return null;
    }

    let refundlistx = refunds.length > 0
    	&& refunds.map((item, i) => { 
        //console.log(item);
        if(item!==null && paymentId===item.id_payments){
          let refund_type="Remboursement";
          if(item.refund_type==="R"){ refund_type="Crédit"; }
          else if(item.refund_type==="C"){ refund_type="Retenue Client"; }else{
            refund_type +="/"+item.refund_type;
          }

          return(
            <tr key={"r"+item.creation_date}>
              <td>{item.id}</td>
              <td>{item.amount}</td>
              <td>{item.tax1}</td>
              <td>{item.tax2}</td>
              <td>{item.total}</td>
              {/* <td>{(item.refund_type==="R")?
              "Rétention" : "Remboursement"}</td> */}
              <td>{refund_type}</td>
              <td>{item.creation_date}</td>
              <td>{item.taxable}</td>
              <td>{item.nontaxable}</td>
              <td>{item.usertxt}</td>
            </tr>
          ); 
        }
      return (
        null
      );
    }, this);

    console.log("refunds-refunds-refunds", refunds);

    return(<div className="container">
    {/* <h2>Liste de remboursement</h2> */}
    {/* <p>The .table-striped class adds zebra-stripes to a table:</p>             */}
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Id</th>
          {/* <th>Montant</th> */}
          <th>Sous-total</th>
          <th>Tax1</th>
          <th>Tax2</th>
          <th>Total</th>
          <th>Type</th>
          <th>Date</th>
          <th>Montant taxable</th>
          <th>Montant non taxable</th>
          <th>Utilisateur</th>
        </tr>
      </thead>
      <tbody> 
        {refundlistx}
      </tbody>
    </table>
  </div>);

  }

  TransactionDetailedRefundList = (props) => {
    const refunds=this.state.refunds;
    const transRefBreakdowns=this.props.transRefBreakdown;
   // const refundsList=this.state.refundList;
   //const refunds=this.state.refundList;
   const paymentId=this.props.paymentId;
   if(transRefBreakdowns.length===0){
     return null;
   }

   let refundlistx = [];
  //const transRefBreakdownKeyList=this.state.transRefBreakdownKeyList;
  //const transRefBreakdowns=this.props.transRefBreakdown;
  if(transRefBreakdowns &&  transRefBreakdowns.length > 0){
    console.log("strt-WATEEEER-transRefBreakdowns ", transRefBreakdowns);
  for(const transRefx of transRefBreakdowns){
    //fqtyO++;
    if(transRefx.id_payments===paymentId){   //if(transRefx.id_transactions===transid){   
      const details=JSON.parse(transRefx.details); //neoprod_breakdown refundList
      for(const detailx of details.refundList){            
        refundlistx.push(
          <tr key={"r"+transRefx.creation_date}>
             <td>{transRefx.id}</td>
             <td>{transRefx.id_transactions}</td>
             <td>{detailx.value}</td>
             <td>{transRefx.creation_date}</td>
             <td>{detailx.fr}</td>
             <td>{detailx.en}</td>
             <td>{detailx.percentage}</td>
           </tr>
        );
      }
    }
  }

    //this.setState({ ["transRefBreakdownKeyList"]: transRefBreakdownKeyList }); 
    //console.log("end-WATEEEER-transRefBreakdownKeyList ", transRefBreakdownKeyList);
  }   
     
   return(<div className="container">
   <h2>Liste de Détails de Remboursement</h2>
   {/* <p>The .table-striped class adds zebra-stripes to a table:</p>             */}
   <table className="table table-striped">
     <thead>
       <tr>
         <th>Id</th>
         <th>#Trans</th>
         <th>Montant</th>
         <th>Date</th>
         <th>Français</th>
         <th>Anglais</th>
         <th>Pourcentage</th>
       </tr>
     </thead>
     <tbody> 
       {refundlistx}
     </tbody>
   </table>
 </div>);

}


}


const mapStateToProps = (state) => ({ NeoProducts: state.NeoProducts }); 
//const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes }); 
//const mapDispatchToProps = { addOrUpdateNeoProducts, getNeoProducts, getNeoProductsAll, getNeoProductsGoto, deleteNeoProducts };   
const mapDispatchToProps = { addOrUpdateNeoVentes, addNeoVentesSC, addOrUpdateRefunds };           
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoRefundPaymentList));
