import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDITICKETDATALEFT = 'GET_NDITICKETDATALEFT';
export const ADD_NDITICKETDATALEFT = 'ADD_NDITICKETDATALEFT';
export const RECEIVE_NDITICKETDATALEFTX = 'RECEIVE_NDITICKETDATALEFTX';
export const REMOVE_NDITICKETDATALEFT = 'REMOVE_NDITICKETDATALEFT';
export const UPDATE_NDITICKETDATALEFT = 'UPDATE_NDITICKETDATALEFT';
export const REPLACE_NDITICKETDATALEFT = 'REPLACE_NDITICKETDATALEFT';
const apiUrl = API_DOMAIN + '/nditicketdataleft'; 

axiosDefaults(null);

export const getNdiTicketDataLeftAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_NDITICKETDATALEFT, NdiTicketDataLeft: response.data.nditicketdataleft.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiTicketDataLeft = (NdiTicketDataLeft) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiTicketDataLeft.id,
		product_field_id: NdiTicketDataLeft.product_field_id,
		case_id: NdiTicketDataLeft.case_id,
		field_data: NdiTicketDataLeft.field_data,
		field_data_raw: NdiTicketDataLeft.field_data_raw,
		is_public: NdiTicketDataLeft.is_public,
		creation_date: NdiTicketDataLeft.creation_date,
		user_creation_id: NdiTicketDataLeft.user_creation_id,
		user_update_id: NdiTicketDataLeft.user_update_id,
		last_update: NdiTicketDataLeft.last_update,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDITICKETDATALEFT, payload: {
			id: data.id,
			product_field_id: data.product_field_id,
			case_id: data.case_id,
			field_data: data.field_data,
			field_data_raw: data.field_data_raw,
			is_public: data.is_public,
			creation_date: data.creation_date,
			user_creation_id: data.user_creation_id,
			user_update_id: data.user_update_id,
			last_update: data.last_update,
		}})
      })
      .then(() => {
        history.push("/NdiTicketDataLeft")
      })
      .catch(error => { throw(error) });
  };
};
export const getNdiTicketDataLeft = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.nditicketdataleft);		  
        return dispatch({type: RECEIVE_NDITICKETDATALEFTX, NdiTicketDataLeftOne: response.data.nditicketdataleft});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiTicketDataLeftGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiTicketDataLeft/${id}/edit`)
  };
};
export const deleteNdiTicketDataLeft = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDITICKETDATALEFT, payload: {id}})
      })
      .then(() => {
        history.push("/NdiTicketDataLeft")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiTicketDataLeft = (NdiTicketDataLeft) => {
  const id = NdiTicketDataLeft.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiTicketDataLeft.id}`, {
		product_field_id: NdiTicketDataLeft.product_field_id,
		case_id: NdiTicketDataLeft.case_id,
		field_data: NdiTicketDataLeft.field_data,
		field_data_raw: NdiTicketDataLeft.field_data_raw,
		is_public: NdiTicketDataLeft.is_public,
		creation_date: NdiTicketDataLeft.creation_date,
		user_creation_id: NdiTicketDataLeft.user_creation_id,
		user_update_id: NdiTicketDataLeft.user_update_id,
		last_update: NdiTicketDataLeft.last_update,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDITICKETDATALEFT, payload: {
			id: data.id,
			product_field_id: data.product_field_id,
			case_id: data.case_id,
			field_data: data.field_data,
			field_data_raw: data.field_data_raw,
			is_public: data.is_public,
			creation_date: data.creation_date,
			user_creation_id: data.user_creation_id,
			user_update_id: data.user_update_id,
			last_update: data.last_update,
		}})
        dispatch({type: REPLACE_NDITICKETDATALEFT, payload: {
			id: data.id,
			product_field_id: data.product_field_id,
			case_id: data.case_id,
			field_data: data.field_data,
			field_data_raw: data.field_data_raw,
			is_public: data.is_public,
			creation_date: data.creation_date,
			user_creation_id: data.user_creation_id,
			user_update_id: data.user_update_id,
			last_update: data.last_update,
		}})
      })
      .then(() => {
        history.push(`/NdiTicketDataLeft/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiTicketDataLeft = (NdiTicketDataLeft, actionMode) => {
	if(actionMode===1){
        return addNdiTicketDataLeft(NdiTicketDataLeft);
      }else {
        return updateNdiTicketDataLeft(NdiTicketDataLeft);
      }
};