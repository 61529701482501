const messagesFr={
"curlang": "fr",    
"welcome_x": "Bienvenue",
"hi_x": "Salut",
"Create_x": "Créer",
"New_x": "Nouveau",
"Edit_x": "Modifier",
"Update_x": "Update",
"Delete_x": "Supprimer",
"Delete": "Supprimer",
"Cancel_x": "Annuler",
"Cancel": "Annuler",
"Close_x": "Fermer", 
"Create": "Créer",

"Yes_x": "Oui",
"No_x": "Non",
"Yes": "Oui",
"No": "Non",

"uname_x": "E-mail",
"upass_x": "Mot de passe",
"tipoUsuario_x": "Type d'utilisateur",
"Seleccionar_x": "Sélectionner",
"Cliente_x": "Client",
"Operador_x": "Opérateur",
"Administrativo_x": "Administratif",
"idioma_x": "Langue",
"Recuerdame_x": "Souviens-toi de moi",
"login_x": "Connexion",
"recoverpass_x": "J'ai oublié mon mot de passe",
"resetpass_x": "Réinitialiser le mot de passe utilisateur",
"qemail_x": "E-mail",
"btnresetpass_x": "Réinitialiser le mot de passe",
"btnCancelar_x": "Annuler",

"id_x": "Id",
"name_x": "Nom",
"notes_x": "Notes",
"errorFoundonform_x": "vous avez des erreurs sur le formulaire.",
"acAddAssetTypes_x": "Ajouter des types d'actifs",
"acEditAssetTypes_x": "Modifier les AssetTypes",

"gridIndexesfound_x": "Désolé, vous devez cocher un seul enregistrement pour pouvoir le modifier.",
"gridnoMatch_x": "Désolé, aucun enregistrement correspondant trouvé",
"gridtoolTiph_x": "Trier",
"gridSortfor_x": "Trier pour",
"gridnext_x": "Page suivante",
"gridprevious_x": "Page précédente",
"gridrowsPerPage_x": "Lignes par page:",
"griddisplayRows_x": "de",
"gridsearch_x": "Rechercher",
"griddownloadCsv_x": "Télécharger CSV",
"gridprint_x": "Imprimer",
"gridviewColumns_x": "Afficher les colonnes",
"gridfilterTable_x": "Table des filtres",
"gridall_x": "Tous",
"gridftitle_x": "FILTRES",
"gridreset_x": "RESET",
"gridvctitle_x": "Afficher les colonnes",
"gridvctitleAria_x": "Afficher / masquer les colonnes du tableau",
"AssetTypes_x": "Assettypes",
"gridNoRecordfound_x": "Aucun enregistrement à afficher",
 

"menuhome_x": "Accueil",
"menudashboard_x": "Tableau",
"menuzonlineusuarios_x": "Profil utilisateur",
"menuvwdespachosalerts_x": "Expéditions",
"menuvwfacturas_x": "Toutes les factures",
"menuvwfacturasnopagadas_x": "Factures impayées",
"menuvwfacturaspagadas_x": "Factures payées",
"menuvwpagos_x": "Paiements effectués",
"menuid_x": "Id",
"ZxCompanies_x": "Liste des sociétés / succursales",
"togoutcnfti_x": "Cher utilisateur",
"logoutconfir_x": "Êtes-vous sûr de vouloir déconnecter le système",
"changeLang_x": "Changer de langue",


"zqwid_clients_x": "Id Clients",
"idzqw_x": "Id",
"namezqw_x": "Nom",
"id_clientszqw_x": "Client",
"activezqw_x": "Actif",
"createdzqw_x": "Crée",
"ticket_idzqw_x": "Ticket",
"gridrowsPerPagezqw_x": "Actif",
"NeoFolderszqw_x": "Neo Folders",










"zqwid_x": "Id",
"zqwkey_x": "Clé",
"zqwall_access_x": "Tous les accès",
"zqwcontroller_x": "Contrôleur",
"zqwdate_created_x": "Date de création",
"zqwdate_modified_x": "Date de modification",
"zqwerrorFoundonform_x01": "vous avez des erreurs sur le formulaire.",
"zqwacAddAccess_x": "Ajouter un accès",
"zqwacEditAccess_x": "Modifier l'accès",
"zqwgridIndexesfound_x": "Désolé, vous ne devez cocher qu'un seul enregistrement pour pouvoir le modifier.",
"zqwgridnoMatch_x": "Désolé, aucun enregistrement correspondant n'a été trouvé",
"zqwgridtoolTiph_x": "Trier",
"zqwgridSortfor_x": "Trier pour",
"zqwgridnext_x": "Page suivante",
"zqwgridprevious_x": "Page précédente",
"zqwgridrowsPerPage_x": "Lignes par page:",
"zqwgriddisplayRows_x": "sur",
"zqwgridsearch_x": "Rechercher",
"zqwgriddownloadCsv_x": "Télécharger CSV",
"zqwgridprint_x": "Imprimer",
"zqwgridviewColumns_x": "Afficher les colonnes",
"zqwgridfilterTable_x": "Filtre de la table",
"zqwgridall_x": "Tout",
"zqwgridftitle_x": "FILTRES",
"zqwgridreset_x": "RESET",
"zqwgridvctitle_x": "Afficher les colonnes",
"zqwgridvctitleAria_x": "Afficher / Masquer les colonnes du tableau",
"zqwAccess_x": "Accès",
"zqwgridNoRecordfound_x": "Aucun enregistrement trouvé à afficher",
"zqwuser_id_x": "ID utilisateur",
"zqwlevel_x": "Niveau",
"zqwignore_limits_x": "Ignorer les limites",
"zqwis_private_key_x": "Est une clé privée",
"zqwip_addresses_x": "Adresses IP",
"zqwacAddKeys_x": "Ajouter des clés",
"zqwacEditKeys_x": "Modifier les clés",
"zqwKeys_x": "Clés",
"zqwuri_x": "Uri",
"zqwmethod_x": "Méthode",
"zqwparams_x": "Paramètres",
"zqwapi_key_x": "Clé Api",
"zqwip_address_x": "Adresse IP",
"zqwtime_x": "Heure",
"zqwrtime_x": "Rtime",
"zqwauthorized_x": "Autorisé",
"zqwresponse_code_x": "Code de réponse",
"zqwacAddLogs_x": "Ajouter des journaux",
"zqwacEditLogs_x": "Modifier les journaux",
"zqwLogs_x": "Journaux", 
"zqwaddress_x": "Adresse",
"zqwcity_x": "Ville",
"zqwprovince_x": "Province",
"zqwcp_x": "Cp",
"zqwcountry_x": "Pays",
"zqwcreated_x": "Créé",
"zqwactive_x": "Actif",
"zqwtel_x": "Téléphone",
"zqwext_x": "Ext",
"zqwcompany_x": "Entreprise",
"zqwname_x": "Nom",
"zqwtype_x": "Type",
"zqwinvoicing_x": "Facturation",
"zqwdelivery_x": "Livraison",
"zqwacAddNeoAddress_x": "Ajouter NeoAddress",
"zqwacEditNeoAddress_x": "Modifier NeoAddress",
"zqwNeoAddress_x": "Neoaddress",
"zqwid_campaign_x": "Campagne d'identification",
"zqwraw_data_x": "Données brutes",
"zqwacAddNeoCampaign_x": "Ajouter NeoCampaign",
"zqwacEditNeoCampaign_x": "Modifier NeoCampaign",
"zqwNeoCampaign_x": "Neocampaign",
"zqwid_folders_x": "Dossiers d'ID",
"zqwfirstname_x": "Prénom",
"zqwlastname_x": "Nom",
"zqwcell_x": "Cellule",
"zqwemail_x": "E-mail",
"zqwns_x": "Ns",
"zqwcase_x": "Cas",
"zqwstatus_x": "Statut",
"zqwclosed_x": "Fermé",
"zqwpending_x": "En attente",
"zqwinvalid_x": "Invalide",
"zqwopened_x": "Ouvert",
"zqwid_transactions_x": "Id transactions",
"zqwlanguage_x": "Langue",
"zqwacAddNeoCases_x": "Ajouter des NeoCases",
"zqwacEditNeoCases_x": "Modifier les NeoCases",
"zqwNeoCases_x": "Neocases",
"zqwterms_x": "Conditions",
"zqwid_users_x": "Identifiant des utilisateurs",
"zqwpush_x": "Pousser",
"zqwid_zendesk_x": "Id Zendesk",
"zqwsms_x": "Sms",
"zqwstripe_id_x": "Identifiant de bande",
"zqwacAddNeoClients_x": "Ajouter des NeoClients",
"zqwacEditNeoClients_x": "Modifier les NeoClients",
"zqwNeoClients_x": "Néoclients",
"zqwdefinition_x": "Définition",
"zqwticket_id_x": "ID du ticket",
"zqwclient_id_x": "ID client",
"zqwdelay_x": "Retard",
"zqwdone_x": "Terminé",
"zqwdate_executed_x": "Date d'exécution",
"zqwresult_x": "Résultat",
"zqwticket_type_x": "Type de ticket",
"zqwdeskapp_x": "Deskapp",
"zqwdesk_id_x": "Id bureau",
"zqwguid_x": "Guid",



"zqwacAddNeoDesk_x": "Ajouter NeoDesk",
"zqwacEditNeoDesk_x": "Modifier NeoDesk",
"zqwNeoDesk_x": "Neodesk",
"zqwid_notes_x": "Notes d'identification",
"zqwfile_location_x": "Emplacement du fichier",
"zqwfile_name_x": "Nom du fichier",
"zqwfile_extension_x": "Extension de fichier",
"zqwfile_html_x": "Fichier Html",
"zqwis_file_x": "Est un fichier",
"zqwmodified_x": "Modifié",
"zqwmetas_x": "Métas",
"zqworg_name_x": "Nom de l'organisation",
"zqwuniqid_x": "Uniqid",
"zqwacAddNeoDocuments_x": "Ajouter des NeoDocuments",
"zqwacEditNeoDocuments_x": "Modifier les NeoDocuments",
"zqwNeoDocuments_x": "Neodocuments",
"zqwacAddNeoDocuments1_x": "Ajouter NeoDocuments1",
"zqwacEditNeoDocuments1_x": "Modifier NeoDocuments1",
"zqwNeoDocuments1_x": "Neodocuments1",
"zqwid_event_x": "Id événement",
"zqwevent_start_x": "Début de l'événement",
"zqwevent_end_x": "Fin de l'événement",
"zqwduration_x": "Durée",
"zqwevent_tz_x": "Event Tz",
"zqwuser_x": "Utilisateur",
"zqwservice_id_x": "ID de service",
"zqwacAddNeoEvents_x": "Ajouter des NeoEvents",
"zqwacEditNeoEvents_x": "Modifier les NeoEvents",
"zqwNeoEvents_x": "Neoevents",
"zqwacAddNeoFolders_x": "Ajouter des NeoFolders",
"zqwacEditNeoFolders_x": "Modifier les NeoFolders",
"zqwNeoFolders_x": "Neofolders",
"zqwpayed_x": "Payé",
"zqwsent_x": "Envoyé",
"zqwoverdue_x": "En retard",
"zqwview_x": "Afficher",
"zqwdeclined_x": "Refusé",
"zqwrefund_x": "Remboursement",
"zqwdue_date_x": "Date de Vente",
"zqwsub_total_x": "Sous-total",
"zqwtax1_x": "Tax1",
"zqwtax2_x": "Taxe2",
"zqwtoken_x": "Jeton",
"zqwcode_x": "Code",
"zqwdeclined_code_x": "Code refusé",
"zqwtransact_x": "Transact",
"zqwtax_total_x": "Total des taxes",
"zqwtotal_x": "Total",
"zqwid_address_x": "Adresse Id",
"zqwacAddNeoInvoices_x": "Ajouter des NeoInvoices",
"zqwacEditNeoInvoices_x": "Modifier les NeoInvoices",
"zqwNeoInvoices_x": "Neoinvoices",
"zqwacAddNeoLawyer_x": "Ajouter NeoLawyer",
"zqwacEditNeoLawyer_x": "Modifier NeoLawyer",
"zqwNeoLawyer_x": "Neolawyer",
"zqwacAddNeoMailHook_x": "Ajouter NeoMailHook",
"zqwacEditNeoMailHook_x": "Modifier NeoMailHook",
"zqwNeoMailHook_x": "Neomailhook",
"zqwid_transaction_x": "Transaction d'ID",
"zqwid_user_x": "ID utilisateur",
"zqwacAddNeoMed_x": "Ajouter NeoMed",
"zqwacEditNeoMed_x": "Modifier NeoMed",
"zqwNeoMed_x": "Neomed",
"zqwnote_x": "Remarque",
"zqwpublic_x": "Public",
"zqwid_lawyer_x": "Id Lawyer",
"zqwread_x": "Lire",
"zqwacAddNeoNotes_x": "Ajouter des NeoNotes",
"zqwacEditNeoNotes_x": "Modifier les NeoNotes",
"zqwNeoNotes_x": "Néonotes",
"zqwid_invoices_x": "Id Invoices",
"zqwamount_x": "Montant",
"zqwacAddNeoPayments_x": "Ajouter des NeoPayments",
"zqwacEditNeoPayments_x": "Modifier NeoPayments",
"zqwNeoPayments_x": "Neopayments",
"zqwtransdata_x": "Transdata",
"zqwsignature_x": "Signature",
"zqwidTrans_x": "Idtrans",
"zqwsource_x": "Source",
"zqwacAddNeoPaymentsHook_x": "Ajouter NeoPaymentsHook",
"zqwacEditNeoPaymentsHook_x": "Modifier NeoPaymentsHook",
"zqwNeoPaymentsHook_x": "Neopaymentshook",
"zqwdescription_x": "Description",
"zqwdata_json_x": "Data Json",
"zqwacAddNeoProducts_x": "Ajouter des NeoProducts",
"zqwacEditNeoProducts_x": "Modifier NeoProducts",
"zqwNeoProducts_x": "Néoproduits",
"zqwiso_x": "Iso",
"zqwtax1_name_x": "Nom Tax1",
"zqwtax2_name_x": "Nom Tax2",
"zqwacAddNeoProvinces_x": "Ajouter des NeoProvinces",
"zqwacEditNeoProvinces_x": "Modifier les NeoProvinces",
"zqwNeoProvinces_x": "Neoprovinces",
"zqwclientemail_x": "Clientemail",
"zqwreferalname_x": "Nom de référence",
"zqwreferalemail_x": "Referalemail",
"zqwadw_id_x": "Adw Id",
"zqwclientvoucher_x": "Clientvoucher",
"zqwreferalvoucher_x": "Referalvoucher",
"zqwclientused_x": "Clientused",
"zqwreferalused_x": "Referalused",
"zqwclientdateused_x": "Clientdateused",
"zqwreferaldateused_x": "Referaldateused",
"zqwclientidtrans_x": "Clientidtrans",
"zqwreferalidtrans_x": "Referalidtrans",
"zqwacAddNeoReferal_x": "Ajouter NeoReferal",
"zqwacEditNeoReferal_x": "Modifier NeoReferal",
"zqwNeoReferal_x": "Neoreferal",
"zqwdate_x": "Date",
"zqwcommission_x": "Commission",
"zqwidstripe_x": "Idstripe",
"zqwapplication_x": "Application",
"zqwcustomer_id_x": "ID client",
"zqwtaxable_x": "Taxable",
"zqworphelin_x": "Orphelin",
"zqwnom_x": "Nom",
"zqwprenom_x": "Prénom",
"zqwidclient_x": "Id Client",
"zqwid_produit_x": "Id Produit",
"zqwproduit_x": "Produit",
"zqwis_business_x": "C'est une affaire",
"zqwclient_created_x": "Client créé",
"zqwacAddNeoRevenus_x": "Ajouter des NeoRevenus",
"zqwacEditNeoRevenus_x": "Modifier les NeoRevenus",
"zqwNeoRevenus_x": "Neorevenus",
"zqwcreated_campaign_x": "Campagne créée",
"zqwcreated_clients_x": "Clients créés",
"zqwacAddNeoSalesCampaign_x": "Ajouter NeoSalesCampaign",
"zqwacEditNeoSalesCampaign_x": "Modifier NeoSalesCampaign",
"zqwNeoSalesCampaign_x": "Neosalescampaign",
"zqwcategory_x": "Catégorie",
"zqwid_product_x": "Id produit",
"zqwcat_id_x": "Cat Id",
"zqwacAddNeoServices_x": "Ajouter des NeoServices",
"zqwacEditNeoServices_x": "Modifier les NeoServices",
"zqwNeoServices_x": "Neoservices",
"zqwacAddNeoSrfaxHook_x": "Ajouter NeoSrfaxHook",
"zqwacEditNeoSrfaxHook_x": "Modifier NeoSrfaxHook",
"zqwNeoSrfaxHook_x": "Neosrfaxhook",
"zqwcurrency_x": "Devise",
"zqwtype_id_x": "Type Id",
"zqwacAddNeoTarifs_x": "Ajouter des NeoTarifs",
"zqwacEditNeoTarifs_x": "Modifier les NeoTarifs",
"zqwNeoTarifs_x": "Neotarifs",




"zqwacAddNeoTarifsUsers_x": "Ajouter des NeoTarifsUsers",
"zqwacEditNeoTarifsUsers_x": "Modifier NeoTarifsUsers",
"zqwNeoTarifsUsers_x": "Neotarifsusers",
"zqwacAddNeoTaskdef_x": "Ajouter NeoTaskdef",
"zqwacEditNeoTaskdef_x": "Modifier NeoTaskdef",
"zqwNeoTaskdef_x": "Neotaskdef",
"zqwacAddNeoTrackingHook_x": "Ajouter NeoTrackingHook",
"zqwacEditNeoTrackingHook_x": "Modifier NeoTrackingHook",
"zqwNeoTrackingHook_x": "Neotrackinghook",
"zqwid_products_x": "Id produits",
"zqwid_vouchers_x": "Bons d'identité",
"zqwpreachat_x": "Preachat",
"zqwacAddNeoTransactions_x": "Ajouter NeoTransactions",
"zqwacEditNeoTransactions_x": "Editer NeoTransactions",
"zqwNeoTransactions_x": "Neotransactions",
"zqwusername_x": "Nom d'utilisateur",
"zqwpassword_x": "Mot de passe",
"zqwadmin_x": "Admin",
"zqwsupervisor_x": "Superviseur",
"zqwlawyer_x": "Avocat",
"zqwagent_x": "Agent",
"zqwlsupervisor_x": "Lsupervisor",
"zqwcustomer_x": "Client",
"zqwmarketing_x": "Marketing",
"zqwsales_x": "Ventes",
"zqwlegalintern_x": "Legalintern",
"zqwelawyer_x": "Elawyer",
"zqwlast_login_x": "Dernière connexion",
"zqwacAddNeoUsers_x": "Ajouter des NeoUsers",
"zqwacEditNeoUsers_x": "Modifier les NeoUsers",
"zqwNeoUsers_x": "Neousers",
"zqwacAddNeoVentes_x": "Contrats Partenariats",
"zqwacEditNeoVentes_x": "Contrats Partenariats",
"zqwNeoVentes_x": "Ventes",
"zqwpercentage_x": "Pourcentage",
"zqwvalid_start_x": "Début valide",
"zqwvalid_end_x": "Fin valide",
"zqwusage_x": "Utilisation",
"zqwacAddNeoVouchers_x": "Ajouter des NeoVouchers",
"zqwacEditNeoVouchers_x": "Modifier les NeoVouchers",
"zqwNeoVouchers_x": "Neovouchers",
"zqwCreation_Date_x": "Date de création",
"zqwDescription_x": "Description",
"zqwAmount_x": "Montant",
"zqwComission_x": "Commission",


"zqwID_x": "Id",
"zqwacAddStripeReport_x": "Ajouter un rapport Stripe",
"zqwacEditStripeReport_x": "Modifier le rapport Stripe",
"zqwStripeReport_x": "Rapport Stripe",



"zqwweek_plan_id_x": "Id du plan hebdomadaire",
"zqwwork_date_x": "Date de travail",
"zqwexpected_quantity_x": "Quantité attendue",
"zqwexpected_amount_x": "Montant attendu",
"zqwinvoice_quantity_x": "Quantité de facture",
"zqwinvoice_amount_x": "Montant de la facture",
"zqwpayment_quantity_x": "Quantité de paiement",
"zqwpayment_amount_x": "Montant du paiement",
"zqwacAddNeoSalesPlanningDaily_x": "Ajouter NeoSalesPlanningDaily",
"zqwacEditNeoSalesPlanningDaily_x": "Modifier NeoSalesPlanningDaily",
"zqwNeoSalesPlanningDaily_x": "Neosalesplanningdaily",
"zqwweek_x": "Semaine",
"zqwweek_start_x": "Début de la semaine",
"zqwweek_end_x": "Week-end",
"zqwacAddNeoSalesPlanningWeekly_x": "Ajouter NeoSalesPlanningWeekly",
"zqwacEditNeoSalesPlanningWeekly_x": "Modifier NeoSalesPlanningWeekly",
"zqwNeoSalesPlanningWeekly_x": "Planifications Hebdomadaires",

"zqwtotal_recurrent_x": "Total récurrent",
"zqwround_recurrent_x": "Cycle récurrent",
"zqwday_recurrent_x": "Récurrent de jour",
"zqwfrom_date_x": "Date de début",
"zqwpayment_type_x": "Type de paiement",
"zqwamount_x": "Montant",
"zqwnotes_x": "Notes",
"zqwlast_update_x": "Dernière mise à jour",
"zqwacAddNeoInvoicesRecurrent_x": "Ajouter NeoInvoicesRecurrent",
"zqwacEditNeoInvoicesRecurrent_x": "Modifier NeoInvoicesRecurrent",
"zqwNeoInvoicesRecurrent_x": "Neoinvoicesrecurrent",
"zqwuser_creation_x": "Création d'utilisateurs",
"zqwuser_update_x": "Mise à jour de l'utilisateur",
"zqwcreation_date_x": "Date de création",
"zqwupdate_date_x": "Date de mise à jour",
"zqwtotalPayment_x": "Total du paiement",


  

 
"zqwid_x": "Id",
"zqwfield_id_x": "Id de champ",
"zqwtext_label_x": "Libellé de texte",
"zqwoption_order_x": "Ordre des options",
"zqwenabled_x": "Activé",
"zqwerrorFoundonform_x": "vous avez des erreurs sur le formulaire.",
"zqwacAddNdiFieldOptions_x": "Ajouter des options Field",
"zqwacEditNdiFieldOptions_x": "Modifier les options de Field",  

"zqwNdiFieldOptions_x": "Ndifieldoptions", 
"zqwfield_option_id_x": "ID d'option de champ",
"zqwlang_x": "Lang",
"zqwacAddNdiFieldOptionsTranslation_x": "Ajouter la traduction des options de champ ",
"zqwacEditNdiFieldOptionsTranslation_x": "Modifier la traduction des options de champ ",
"zqwNdiFieldOptionsTranslation_x": "Traduction des options de champ ",
"zqwname_x": "Nom",
"zqwplaceholder_x": "Espace réservé",
"zqwfield_x": "Champ",
"zqwfield_type_x": "Type de champ",
"zqwfield_default_value_x": "Valeur par défaut du champ",
"zqwmask_x": "Masque",
"zqwfield_service_x": "Service sur site",
"zqwfield_dataset_x": "Jeu de données de champ",
"zqwtag_id_x": "Id de balise",
"zqwacAddNdiFields_x": "Ajouter des champs",
"zqwacEditNdiFields_x": "Editer Champs",
"zqwNdiFields_x": "Les Champs",
"zqwacAddNdiFieldsTranslation_x": "Ajouter la traduction des champs ",
"zqwacEditNdiFieldsTranslation_x": "Modifier la traduction des champs ",
"zqwNdiFieldsTranslation_x": "Traduction des champs ",
"zqwticket_id_x": "ID du ticket",
"zqwproduct_id_x": "ID produit",
"zqwcreation_date_x": "Date de création",
"zqwuser_creation_id_x": "ID de création d'utilisateur",
"zqwuser_update_id_x": "ID de mise à jour de l'utilisateur",
"zqwlast_update_x": "Dernière mise à jour",
"zqwacAddNdiFoldersProducts_x": "Ajouter des produits Folders",
"zqwacEditNdiFoldersProducts_x": "Modifier les produits de dossiers ",
"zqwNdiFoldersProducts_x": "Produits de dossiers ",
"zqwproduct_type_id_x": "ID du type de produit",
"zqwrequired_x": "Obligatoire",
"zqwvisual_order_x": "Ordre visuel",
"zqwacAddNdiProductFields_x": "Ajouter des champs de produit ",
"zqwacEditNdiProductFields_x": "Modifier les champs de produit ",
"zqwNdiProductFields_x": "Champs de produit ",
"zqwmain_category_x": "Catégorie principale",
"zqwparent_x": "Parent",
"zqwacAddNdiProductTypes_x": "Ajouter des types de produits ",
"zqwacEditNdiProductTypes_x": "Modifier les types de produits ",
"zqwNdiProductTypes_x": "Types de Produits",
"zqwproduct_block_x": "Bloc de produit",
"zqwpublished_x": "Publié",
"zqwuser_id_x": "ID utilisateur",
"zqwacAddNdiProducts_x": "Ajouter des produits ",
"zqwacEditNdiProducts_x": "Modifier les produits ",
"zqwNdiProducts_x": "Produits",
"zqwacAddNdiProductsTranslation_x": "Ajouter Produits Traduction",
"zqwacEditNdiProductsTranslation_x": "Modifier Produits Traduction",
"zqwNdiProductsTranslation_x": "Produits Traduction",
"zqwacAddNdiTags_x": "Ajouter des tags ",
"zqwacEditNdiTags_x": "Modifier les balises ",
"zqwNdiTags_x": "Balises ",
"zqwproduct_field_id_x": "ID de champ de produit",
"zqwcase_id_x": "Id de cas",
"zqwfield_data_x": "Données de champ",
"zqwfield_data_raw_x": "Données de champ brutes",
"zqwis_public_x": "Est public",
"zqwacAddNdiTicketDataLeft_x": "Ajouter les données de ticket restantes",
"zqwacEditNdiTicketDataLeft_x": "Modifier les données de ticket à gauche",
"zqwNdiTicketDataLeft_x": "Données de ticket restantes",


 
"zqwid_b2b_list_x": "Liste de Contrats",
"zqwid_external_x": "Id External",
"zqwname_id_external_x": "Name Id External",  
"zqwtel_x": "Téléphone",
"zqwpostal_code_x": "Code Postal",
"zqwdate_updated_x": "Date Updated",
"zqwdob_x": "Dob",
"zqwacAddNeoB2bClients_x": "Add NeoB2bClients",
"zqwacEditNeoB2bClients_x": "Edit NeoB2bClients",
 
"zqwstart_date_x": "Date de début",
"zqwexpiry_date_x": "Date d'Expiration",

"zqwcompany_name_x": "Nom de Compagnie",
"zqwinfos_x": "Infos",
"zqwemail_import_x": "Importer Email",
"zqwacAddNeoB2bContracts_x": "Add NeoB2bContracts",
"zqwacEditNeoB2bContracts_x": "Edit NeoB2bContracts",
"zqwNeoB2bContracts_x": "Neob2bcontracts",
"zqwid_b2b_contracts_x": "Id B2b Contracts", 
"zqwcontract_start_x": "Contract Start",
"zqwcontract_end_x": "Contract End",
"zqwname_id_external_en_x": "Name Id External En",
"zqwquantity_x": "Quantity",
"zqwfrequency_x": "Frequency",
"zqwacAddNeoB2bContractsList_x": "Add NeoB2bContractsList",
"zqwacEditNeoB2bContractsList_x": "Edit NeoB2bContractsList",
"zqwNeoB2bContractsList_x": "Neob2bcontractslist",
"zqwid_product_x": "Id Product",
"zqwquantite_x": "Quantite",
"zqwacAddNeoB2bContractsServices_x": "Add NeoB2bContractsServices",
"zqwacEditNeoB2bContractsServices_x": "Edit NeoB2bContractsServices",
"zqwNeoB2bContractsServices_x": "Neob2bcontractsservices",
"zqwid_b2b_clients_x": "Id B2b Clients",
"zqwid_invoices_x": "Id Invoices",
"zqwacAddNeoB2bInvoices_x": "Add NeoB2bInvoices",
"zqwacEditNeoB2bInvoices_x": "Edit NeoB2bInvoices",
"zqwNeoB2bInvoices_x": "Neob2binvoices",
"zqwCEligibility_x": "Vérifiez Eligibilité",
"zqwTabsummary_x": "Résumé",
"zqwTabinput_x": "Dossier client",
"zqwTabnouveau_x": "Nouveau",
"zqwoccupation_x": "Occupation", 
"zqwTabDocument_x": "Documents",
"zqwTabAjoutProd_x": "Ajout de produit",
"zqwTabhome_x": "Accueil",

"zqwTSumtxt1_x": "Statut dossier",
"zqwTSumtxt2_x": "Statut",
"zqwTSumtxt3_x": "Suivi",  //Statut Next
"zqwTSumtxt3_2_x": "Précisions du suivi",
"zqwTSumtxt4_x": "Services vendus", //"Service séléctionné",
"zqwTSumtxt5_x": "Client Partenaire",
"zqwTSumtxt6_x": "No de police",
"zqwTSumtxt7_x": "Coordonnées client",
"zqwTSumtxt8_x": "Langue du client",
"zqwTSumtxt9_x": "Région du client",
"zqwTSumtxt10_x": "Domaine de droit",
"zqwTSumtxt10b_x": "Prochain RDV client",
"zqwTSumtxt11_x": "Aucune donnée à afficher",
"zqwTSumtxt12_x": "Français",
"zqwTSumtxt13_x": "Anglais",
"zqwTSumtxt14_x": "Nom",
"zqwTSumtxt15_x": "Prénom",
"zqwTSumtxt16_x": "Courriel",
"zqwTSumtxt17_x": "Téléphone",  
"zqwTSumtxt18_x": "Adresse",
"zqwTSumtxt19_x": "Ouvrir grande fenêtre",

"zqwTSCnycompany_x": "Coordonnées de la compagnie",
"zqwTSCnyrepre_x": "Coordonnées du représentant légal",
"zqwTSCnyname_x": "Compagnie",
"zqwTSCnyothername_x": "Autre nom de la compagnie",
"zqwTSCnyneq_x": "NEQ",
"zqwTSCnycity_x": "Ville",
"zqwTSCnycp_x": "Code Postal",
"zqwTSCnyprovince_x": "Province",
"zqwTSCnycountry_x": "Pays",
"zqwTSCnyext_x": "Extension",


"zqwTNVFtxt1_x": "Info du Client",
"zqwTNVFtxt2_x": "Compagnie",
"zqwTNVFtxt3_x": "Please Select Contract", 
"zqwTNVFtxt4_x": "Infos Suplémentaires",
"zqwTNVFtxt5_x": "Produits Éligibles",
"zqwTNVFtxt6_x": "Produits ÉligiblesCe client n'est pas éligible.",
"zqwTNVFtxt7_x": "Services Utilisés",
"zqwTNVFtxt8_x": "Facture",
"zqwTNVFtxt9_x": "Date",
"zqwTNVFtxt10_x": "Description",
"zqwTNVFtxt11_x": "Cliquer sur ‘’Ouvrir dossier’’ pour procéder à l’ouverture du dossier ou sur ‘’Étape suivante’’ pour actualiser les informations du client. ",
"zqwTNVFtxt11b_x": "cliquez sur ce bouton pour finaliser la vente si tu ne vas pas actualiser les données du client. Dans le cas contraire cliquez \"Étape Suivante\".",
"zqwTNVFtxt12_x": "Ouvrir Dossier",
"zqwTNVFtxt13_x": "Étape Suivante", 
"zqwTNVFtxt14_x": "Créer Lead de Vente",
"zqwTNVFtxt15_x": "Ajouter Client à la liste",
"zqwTNVFtxt16_x": "champs obligatoires",
"zqwTNVFtxt17_x": "Adresses du code postal",
"zqwTNVFtxt18_x": "La liste de possible Adresse avec le même code postal",
"zqwTNVFtxt19_x": "Liste d'option d'Addresse à Choisir (facultatif)",
"zqwTNVFtxt20_x": "Fermer",
"zqwTNVFtxt21_x": "Étape Antérieure",
"zqwTNVFtxt22_x": "Étape Suivante",
"zqwTNVFtxt23_x": "Recommencer Complètement",
"zqwTNVFtxt24_x": "Veuillez valider le courriel du client en cliquant sur le champs email et après dehors du champs. ",
"zqwTNVFtxt25_x": "Info du Client",
"zqwTNVFtxt26_x": "Compagnie",
"zqwTNVFtxt27_x": "Selectionner une Langue",
"zqwTNVFtxt28_x": "Francais",
"zqwTNVFtxt29_x": "Anglais",
"zqwTNVFtxt30_x": "J'accepte de recevoir des notifications par SMS concernant mon dossier (recommandé)",
"zqwTNVFtxt31_x": "App/Suite/Unité",
"zqwTNVFtxt32_x": "Google chercher adresse...",
"zqwTNVFtxt33_x": "Recherchez un Client",
"zqwTNVFtxt34_x": "Créer client",
"zqwTNVFtxt34x2_x": "Nouveau Client Mannuellement",
"zqwTNVFtxt35_x": "Nettoyer et Recommencer tout",

"zqwTDCtxt1_x": "Sauvegarder Tout",

"zqwTNVFtxt36_x": "Toutes nos Félicitations",
"zqwTNVFtxt37_x": "La transaction a été effectuée avec succès.",
"zqwTNVFtxt38_x": "vous pouvez fermer c'est tout",
"zqwTNVFtxt39_x": "Finalisation de Vente",
"zqwTNVFtxt40_x": "Résumé de votre commande",
"zqwTNVFtxt41_x": "Client Existant avec Email",
"zqwTNVFtxt42_x": "La liste de possible Client éxistant avec le même email",
"zqwTNVFtxt43_x": "Résumé",
"zqwTNVFtxt44_x": "Résumé",
"zqwTNVFtxt45_x": "Résumé",
"zqwTNVFtxt46_x": "Résumé",
"zqwTNVFtxt47_x": "Résumé",  
"zqwPuptxt1_x":"AJOUTER DES PRODUITS AU TICKET", 
"zqwPuptxt2_x":"Nom du produit", 
"zqwPuptxt3_x":"Ajouter Produit",  
"zqwNFFtxt1_x": "Déplacer un fichier",
"zqwNFFtxt2_x": "Aucune",
"zqwNFFtxt3_x": "Déplacer",
"zqwNFFtxt4_x": "de position",
"zqwNFFtxt5_x": "à position",
"zqwNFFtxt6_x": "Les Produits achetés",
"zqwNFFtxt7_x": "Les Paiements Éffectués",
"zqwNFFtxt8_x": "......",
"zqwNFFtxt9_x":"On a chargé les données avec succès!",  //On a chargé les données avec succès!
"zqwNFFtxt10_x":"On va comparer les données de gauche à droite.",
"zqwNFFtxt11_x":"Finalement, On a chargé les données avec succès!",
"zqwNFFtxt12_x":"On n'a pas de données de zendesk de gauche à actualiser à droite.",
"zqwNFFtxt13_x":"Choisir Fichier",
"zqwNFFtxt14_x":"Désolé, il s'agit d'une Lead, vous ne pouvez pas modifier les informations par défaut du client.",
"zqwNFFtxt15_x":"Désolé, vous devez choisir une position pour la déplacer à une autre position.",
"zqwNFFtxt16_x":"Données sauvegardées", //Félicitations!!! On a finit de sauvegarder ou actualiser avec succès!
"zqwNFFtxt17_x": "Désolé, nous n'enregistrons pas les données vides. Assurez-vous qu'elles sont d'abord remplies.",
"zqwNFFtxt18_x": "Désolé, le format de vos données est incorrect. Assurez-vous qu'il est correctement rempli!",
"zqwNFFtxt19_x":"Client partenaire",
"zqwNFFtxt20_x":"Onwwwwwwwwww",
"zqwNFFtxt21_x":"Onwwwwwwwwww",
"zqwNFFtxtNeo_x": "Créer nouveau ticket",

"zqwNVFrmEl56_x": "Choisir",
"zqwNVFrmEl57_x": "Sous-total",
"zqwNVFrmEl58_x": "Rabais",
"zqwNVFrmEl59_x": "Total avant Paiement",
"zqwNVFrmEl60_x": "Paiements Réalisés",
"zqwNVFrmEl61_x": "Total",
"zqwNVFrmEl62_x": "Organisation du Paiement",
"zqwNVFrmEl63_x": "Façon de Payer",
"zqwNVFrmEl64_x": "Payer Tout le montant",
"zqwNVFrmEl65_x": "Payer une partie (ou multiple carte)",
"zqwNVFrmEl66_x": "Montant à Payer maintenant",
"zqwNVFrmEl67_x": "Paiements récurrents",
"zqwNVFrmEl68_x": "Paiement postérieur chaque ... du mois",
"zqwNVFrmEl69_x": "Dès",
"zqwNVFrmEl70_x": "date example 2000-12-22",
"zqwNVFrmEl71_x": "Félicitations, paiement reçu avec succès!!",
"zqwNVFrmEl72_x": "Paiement",
"zqwNVFrmEl73_x": "Payer avec",
"zqwNVFrmEl74_x": "Paypal par",
"zqwNVFrmEl75_x": "Upsell avec CardOnFile",
"zqwNVFrmEl76_x": "Etape Antérieure",
"zqwNVFrmEl77_x": "C'est une vente interac, assurez-vous que le client a bien payé (fonds déposés) avant de clicker sur finaliser. Ceci va créer le ticket d'achat et l'envoie du courriel au client.",
"zqwNVFrmEl78_x": "FINALISER LA TRANSACTION",
"zqwNVFrmEl79_x": "FERMER SANS FINALISER LA TRANSACTION",
"zqwNVFrmEl80_x": "Recherchez un Client",
"zqwNVFrmEl81_x": "Veuillez indiquer le nombre de pages à relire",
"zqwNVFrmEl82_x": "Pages",
"zqwNVFrmEl83_x": "Prix du Produit",
"zqwNVFrmEl84_x": "Service Aditionnel",
"zqwNVFrmEl85_x": "Totales Taxes",
"zqwNVFrmEl7_x": "Erreur! Vous devez choisir un produit au moins",
"zqwNVFrmEl8_x": "Erreur! Pour continuer avec la transaction, le montant doit être superieur à 0 !",
"zqwNVFrmEl9_x": "Erreur! Pour continuer il faut avoir au moins un produit valide !",

"zqwNVFrmEl13_x": "Félicitations!!! On a finit votre transaction avec succès!",
"zqwNVFrmEl14_x": "Une erreur s'est produite dans les données attendues du serveur. Veuillez réessayer plus tard.",
"zqwNVFrmEl15_x": "Erreur! Pour utiliser Charge Desk, la Façon de Payer doit être 'Payer Tout le Montant' !",
"zqwNVFrmEl16_x": "Désolé, on ne processe pas plus qu'une fois en moins d'une minute. Svp essayez encore dans {{timerest}} secondes ",
"zqwNVFrmEl17_x": "Erreur! Pour utiliser Interac, la Façon de Payer doit être 'Payer Tout le Montant' !",
"zqwNVFrmEl18_x": "Erreur! Désolé ce client a aucune une carte éxistante!",
"zqwNVFrmEl19_x": "Erreur! Pour utiliser carte éxistante, la Façon de Payer doit être 'Payer Tout le Montant' !",
"zqwNVFrmEl20_x": "Vous devez choisir au moins un produit, il n'y a aucun produit dans votre panier.",
"zqwNVFrmEl21_x": "Quelques champs sont obligatoires, veuillez les remplir", 

"zqwMniPEl_x": "Il n'y a pas de (#) numéro ticket",
"zqwNFZendesk1_x": "N'oubliez pas de sauvegarder dans zendesk",
"zqwNVFrmEl22_x": "Erreur! Avant de créer le Lead, il faut mettre un telephone ( voir dans infos supplémentaires/comments).", 

"zqwNDICItxl_x": "Conflits d'Intérêts",
"zqwNDICItx2_x": "Recherche de conflit d'interêts",
"zqwNDICItx3_x": "Recherche",
"zqwNDICItx4_x": "Ignorer",
"zqwNDICItx5_x": "Non appliqué",
"zqwNDICItx6_x": "Aucune information disponible",
"zqwNDICItx7_x": "Aucun conflit",
"zqwNDICItx8_x": "Conflit",
"zqwNDICItx9_x": "Pas de registre de recherche",
"zqwNDICItxl0_x": "Autre",
"zqwNDICItxl1_x": "En attente",
"zqwNFFrmZn013q_x": "Génération de Facture Personalisée", 
"zqwNFFrmZn0132q_x": "Nom de la compagnie", 
"zqwNFFrmZn0133q_x": "Adresse de la compagnie", 
"zqwNFFrmZn0134q_x": "Désolé, nous avons besoin de l'identifiant de la facture pour ouvrir la fenêtre contextuelle et pouvoir télécharger la facture.", 
"zqwNFFrmZn0135q_x": "Désolé, nous avons besoin de l'identifiant de la facture pour pouvoir télécharger la facture.", 
"zqwNFFrmZn0136q_x": "Désolé, vous devez entrer le nom de l'entreprise et son adresse pour continuer.", 

"zqwNFFrmZn01_x": "Facture Judiciaire", 
"zqwNFFrmZn02_x": "Générer",
"zqwNFFrmZn03_x": "Générer et Télécharger",
"zqwNFFrmZn04_x": "Désolé, nous n'ajoutons pas de données vides pour générer la facture judiciaire. Assurez-vous d'abord de vérifier un élément.",
"zqwNFFrmZn05_x": "Désolé, nous avons besoin de l'identifiant du ticket pour pouvoir télécharger la facture judiciaire générée.",
"zqwNFFrmZn06_x": "Désolé, nous avons besoin de l'identifiant de la facture pour pouvoir télécharger la facture judiciaire générée.",
"zqwNFFrmZn07_x": "Désolé, nous avons besoin que la province puisse télécharger la facture judiciaire générée.",
"zqwNFFrmZn08_x": "qxyqxy",
"zqwNVFrmEl86_x": "Les Options de Service Aditionnel",
"zqwNFFrmZn09_x": "Envoi de lien de formulaire",
"zqwNFFrmZn010_x": "Liste de formulaire",
"zqwNFFrmZn010b_x": "Le nom du fichier a envoyer",
"zqwNFFrmZn011_x": "Envoyez formulaire",
"zqwNFFrmZn012_x": "Lien de formulaire envoyé à ",
"zqwNFFrmZn013_x": "Obtenir le lien du formulaire",
"zqwNFFrmZn014_x": "Envoyez par email ou SMS",
"zqwNFFrmZn015_x": "Veuillez choisir un email/téléphone ou inserrez un",
"zqwNFFrmZn016_x": "Choisir ou inserrez email/téléphone",
"zqwNFFrmZn017_x": "Cliquez ici pour me choisir",
"zqwNFFrmZn018_x": "le format de l'e-mail ou le téléphone est incorrect",
"zqwNVFrmEl78d1_x": "FINALISER LA TRANSACTION DE VENTE",
"zqwNVFrmEl78d2_x": "ÉLIMINER LA TRANSACTION DE VENTE",
"zqwNFFrmZn019_x": "Éliminer Vente",
"zqwNFFrmZn020_x": "Finaliser Vente",
"zqwNFFrmZn021_x": "Finaliser",
"zqwNFnoneonotesyet_x": "Pas de Notes encore, cliquez sur le bouton pour charger",
"zqwNFnoneonotesbtn_x": "Charger Notes",
"zqwNeonotesfrmn_x": "Formulaire",
"incorpo": {
    "langue": "Langue",
    "activity": "Activité",
    "actionPrice": "Prix d'action",
    "companyName": "Nom de Compagnie",
    "territoires": "Territoires",
    "Jurisdiction": "Juridiction",
    "accountantType": "Type de comptabilité",
    "capitalActions": "Capital d'Actions",
    "addressCompany_city": "Adresse de Compagnie Ville",
    "dateEndFinancialYear": "Date de clôture de l'exercice financier",
    "city": "Ville",
    "name": "Nom",
    "email": "Courriel",
    "phone": "Téléphone",
    "gender": "Genre",
    "postal": "Code postal",
    "street": "Adresse",
    "country": "Pays",
    "province": "Province",
    "firstname": "Prénom",
    "state": "Province/État",
    "Birthday": "Date de naissance",
    "startDate": "Date d'enregistrement",
    "checkEndDate": "Réviser date de fin",
    "certificatDate": "Date de certificat",
    "statut": "Citoyenneté",
    "position": "Position",
    "founders": "Fondateurs",
    "actionnaires": "Actionnaires",
    "administrators": "Administrateurs et dirigeants",
    "addressCompany": "Adresse de Compagnie",
    "addressCompanyQuebec": "Adresse de Compagnie au Quebec",
    "otherAddress": "Autre Adresse",
    "otherName": "Autre Nom",
    "feild": "...",
    "field": "...",
},
"private":"prive", 
"privatef":"privée",
"soldprods":"Produits vendus",
"zqwMesas015_x": "Recherche de conflit fait",
"zqwMesas016_x": "Pour continuer svp faites la Recherche de conflit",
"zqwNDIFilter_x": "Filtre principal",
"zqwCViewEl14_x": "Vente",
"zqwCTbViewEl1_x": "Ouvrir Onglet",
"zqwCTbViewEl2_x": "Charger",
"zqwCTbViewEl3_x": "Page suivante",
"zqwCTbViewEl4_x": "Rafréchir",
// "zqwCTbViewEl2_x": "Charger/Load",
// "zqwCTbViewEl3_x": "LoadNextPage",
// "zqwCTbViewEl4_x": "Rafreshir/Refresh",
"zqwCViewEl11_x": "On a chargé les données avec succès!",
"zqwCViewEl12_x": "Désolé, tu dois cochez un seul registre pour continuer", 
"zqwNeoPaymentsCredits_x": "Liste de Client avec Crédit",
"zqwobservation_x": "Observation",
"zqwid_payments_x": "#Paiement",
"zqwprev_cr_amount_x": "Montant Antérieur",
"zqwf_amount_x": "Montant",
"zqwinv_id_payments_x": "#Facture",
"zqwNeoPaymentsCreditsOper_x": "La Liste des Opération de Crédits",
"zqwCViewEl13_x": "Attention, si vous ne choisissez pas de courriel ni téléphone le formulaire n'est pas envoyé, Merci de cliquer sur le bouton envoyer",
 



 

  



};

export default messagesFr;