import { RECEIVE_NDIPRODUCTFIELDS, ADD_NDIPRODUCTFIELDS, REMOVE_NDIPRODUCTFIELDS, REPLACE_NDIPRODUCTFIELDS } from '../actions/NdiProductFieldsAction';

const initialState = { NdiProductFields: [] }
export default function NdiProductFieldsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDIPRODUCTFIELDS:
      return action.NdiProductFields;
    case ADD_NDIPRODUCTFIELDS:
      return [action.payload, ...state];
    case REMOVE_NDIPRODUCTFIELDS:
      return state.filter(NdiProductFields => NdiProductFields.id !== action.payload.id);
    case REPLACE_NDIPRODUCTFIELDS:
      return state.map((NdiProductFields) => {
        if (NdiProductFields.id === action.payload.id) {
          return {
            ...NdiProductFields,
product_type_id: action.payload.product_type_id,
field_id: action.payload.field_id,
required: action.payload.required,
visual_order: action.payload.visual_order,
enabled: action.payload.enabled,
creation_date: action.payload.creation_date,
user_creation_id: action.payload.user_creation_id,
user_update_id: action.payload.user_update_id,
last_update: action.payload.last_update,
          }
        } else return NdiProductFields;
      })
    default:
      return state;
  }
}
