import { RECEIVE_NEOSALESPLANNINGDAILY, ADD_NEOSALESPLANNINGDAILY, REMOVE_NEOSALESPLANNINGDAILY, REPLACE_NEOSALESPLANNINGDAILY } from '../actions/NeoSalesPlanningDailyAction';

const initialState = { NeoSalesPlanningDaily: [] }
export default function NeoSalesPlanningDailyReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NEOSALESPLANNINGDAILY:
      return action.NeoSalesPlanningDaily;
    case ADD_NEOSALESPLANNINGDAILY:
      return [action.payload, ...state];
    case REMOVE_NEOSALESPLANNINGDAILY:
      return state.filter(NeoSalesPlanningDaily => NeoSalesPlanningDaily.id !== action.payload.id);
    case REPLACE_NEOSALESPLANNINGDAILY:
      return state.map((NeoSalesPlanningDaily) => {
        if (NeoSalesPlanningDaily.id === action.payload.id) {
          return {
            ...NeoSalesPlanningDaily,
week_plan_id: action.payload.week_plan_id,
work_date: action.payload.work_date,
expected_quantity: action.payload.expected_quantity,
expected_amount: action.payload.expected_amount,
invoice_quantity: action.payload.invoice_quantity,
invoice_amount: action.payload.invoice_amount,
payment_quantity: action.payload.payment_quantity,
payment_amount: action.payload.payment_amount,
creation_date: action.payload.creation_date,
last_update: action.payload.last_update,
user_creation: action.payload.user_creation,
user_update: action.payload.user_update,
          }
        } else return NeoSalesPlanningDaily;
      })
    default:
      return state;
  }
}
