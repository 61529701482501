import { LOGIN_USER, LOGOUT_USER, CHANGE_USERPASS } from './actions';

const initialState = {
  currentUser: { },
  id: 0, name: '', role: '', 
  isAuthenticated:false
}

export default function LogingReducer(state = initialState, action) {
    switch (action.type) {
      case 'LOGIN_USER':
        return {...state, currentUser: action.payload}
      case 'CHANGE_USERPASS':
        return {...state, User: action.payload}
	  default:
        return state;
    }
  }
