import React, {Component} from 'react';
import {connect} from 'react-redux'; 
import {userLoginFetch, getLoggedUserGoto, userPassResetFetch} from './redux/actions';

import axios from 'axios'; 
import { axiosDefaults } from './appConfigaxios'; 
import { withTranslation } from 'react-i18next';

import history from './history';
import Toastr from "./messages"; 
import ZAFClient from 'zendesk_app_framework_sdk';

let zafClient = ZAFClient.init(); 
class LoginRefresh extends Component {
   
	urlParamZendesk="";

	constructor(props) {
		super(props); 
		this.state = {
			username: "",
			rid: "",
			email: "",
			password: "",
			toastrMessage: '', toastrType: '', toastrDuration: 6000,
			isLoginFrm: true,
			isChecked: false
		}

		//urlParamZendesk="";
	}
  
  componentDidMount() { 
	 
	const locurl=window.location.href; 
	zafClient = ZAFClient.init();    
	console.log("55555LOOOOOOOOOGIN zafClient  ", zafClient, locurl); 
	if(locurl.indexOf("?origin=")> -1){
		const index  = locurl.indexOf('?origin='); 
		const strOut =locurl.substr(index);
		this.urlParamZendesk=strOut+"&xqNeO=1";
		//localStorage.setItem("zendeskzat", strOut);
		console.log("---55555555weeeeeeeeeeee zeeeeendesk "+strOut);
		//history.push(`/zendesk/${this.urlParamZendesk}`); 
	}

	//axiosDefaults(null);
	//history.push(`./${this.urlParamZendesk}`);  //history.push(`./neoventes`);  //history.push(`./neoventes`); datainputboard
		//history.push(`./protected`); //history.push(`./protected`);
		//return this.props.getLoggedUserGoto(0);

	if(zafClient!==undefined && zafClient!==false){ 
		const this00=this;
		let stepRunned=0;
			zafClient.invoke('resize', { width: '100%', height: '600px' });
			console.log("555555LOOOOOOOOOGIN yeeeeeeeeeees zafClient is ready to use ");
		 
		
		console.log("55555LOOOOOOOOOGIN  oooooooooooooooh last on Component-ready... "+stepRunned);
	
		console.log("55555inside if zeeeeeeeeeeeeeendesk GET ID CLIENT NEOLEGAL ");
		 
	 
	  }
  }
 
  
 
  
   
  render() { 
	//const { t } = this.props; 	
	const { t, i18n } = this.props; //useTranslation("common");
    //i18n.changeLanguage(value);
		
    return (
		<div>
            it is just a redirect page, that's set.
          </div>
      );
  }
}

//const MyAppMain = withTranslation('common')(LoginRefresh); 

const mapDispatchToProps = dispatch => ({
  userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo)), getLoggedUserGoto, userPassResetFetch: userInfo => dispatch(userPassResetFetch(userInfo))  
});

//userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo)), getLoggedUserGoto, userPassResetFetch 

export default connect(null, mapDispatchToProps)(withTranslation('common')(LoginRefresh));
