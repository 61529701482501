import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_VWFACTURAS = 'GET_VWFACTURAS';
export const ADD_VWFACTURAS = 'ADD_VWFACTURAS';
export const RECEIVE_VWFACTURASX = 'RECEIVE_VWFACTURASX';
export const REMOVE_VWFACTURAS = 'REMOVE_VWFACTURAS';
export const UPDATE_VWFACTURAS = 'UPDATE_VWFACTURAS';
export const REPLACE_VWFACTURAS = 'REPLACE_VWFACTURAS';
export const RECEIVE_VWBOARDS = 'GET_VWBOARDS';
const apiUrl = API_DOMAIN + '/vwfacturas'; 
const apiUrlxBoard = API_DOMAIN + '/vwboard'; 

axiosDefaults(null);

export const getVwFacturasAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}.php`)
      .then(response => {
        dispatch({type: RECEIVE_VWFACTURAS, VwFacturas: response.data.vwfacturas.data})
      })
      .catch(error => { throw(error); });
  };
};


export const getVwBoardAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrlxBoard}`)
      .then(response => {
		const resp=response.data.vwboardreports;
		return dispatch({type: RECEIVE_VWBOARDS, VwBoardReports: null});
		//return dispatch({type: RECEIVE_VWBOARDS, VwBoardReports: response.data.vwboardreports.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addVwFacturas = (VwFacturas) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		Facturacion: VwFacturas.Facturacion,
		Factura_Fiscal: VwFacturas.Factura_Fiscal,
		Cliente: VwFacturas.Cliente,
		Vendedor: VwFacturas.Vendedor,
		Fecha: VwFacturas.Fecha,
		Tasa_Imp: VwFacturas.Tasa_Imp,
		Subtotal: VwFacturas.Subtotal,
		Impuesto: VwFacturas.Impuesto,
		Total: VwFacturas.Total,
		Tipo: VwFacturas.Tipo,
		Estado: VwFacturas.Estado,
		Fecha_Sistema: VwFacturas.Fecha_Sistema,
		head: VwFacturas.head,
		id: VwFacturas.id,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_VWFACTURAS, payload: {
			Facturacion: data.Facturacion,
			Factura_Fiscal: data.Factura_Fiscal,
			Cliente: data.Cliente,
			Vendedor: data.Vendedor,
			Fecha: data.Fecha,
			Tasa_Imp: data.Tasa_Imp,
			Subtotal: data.Subtotal,
			Impuesto: data.Impuesto,
			Total: data.Total,
			Tipo: data.Tipo,
			Estado: data.Estado,
			Fecha_Sistema: data.Fecha_Sistema,
			head: data.head,
			id: data.id,
		}})
      })
      .then(() => {
        history.push("/VwFacturas")
      })
      .catch(error => { throw(error) });
  };
};
export const getVwFacturas = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.vwfacturas);		  
        return dispatch({type: RECEIVE_VWFACTURASX, VwFacturasOne: response.data.vwfacturas});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getVwFacturasGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`./VwFacturas/${id}/edit`)
  };
};
export const deleteVwFacturas = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_VWFACTURAS, payload: {id}})
      })
      .then(() => {
        history.push("/VwFacturas")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateVwFacturas = (VwFacturas) => {
  const id = VwFacturas.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${VwFacturas.id}`, {
		Facturacion: VwFacturas.Facturacion,
		Factura_Fiscal: VwFacturas.Factura_Fiscal,
		Cliente: VwFacturas.Cliente,
		Vendedor: VwFacturas.Vendedor,
		Fecha: VwFacturas.Fecha,
		Tasa_Imp: VwFacturas.Tasa_Imp,
		Subtotal: VwFacturas.Subtotal,
		Impuesto: VwFacturas.Impuesto,
		Total: VwFacturas.Total,
		Tipo: VwFacturas.Tipo,
		Estado: VwFacturas.Estado,
		Fecha_Sistema: VwFacturas.Fecha_Sistema,
		head: VwFacturas.head,
		id: VwFacturas.id,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_VWFACTURAS, payload: {
			Facturacion: data.Facturacion,
			Factura_Fiscal: data.Factura_Fiscal,
			Cliente: data.Cliente,
			Vendedor: data.Vendedor,
			Fecha: data.Fecha,
			Tasa_Imp: data.Tasa_Imp,
			Subtotal: data.Subtotal,
			Impuesto: data.Impuesto,
			Total: data.Total,
			Tipo: data.Tipo,
			Estado: data.Estado,
			Fecha_Sistema: data.Fecha_Sistema,
			head: data.head,
			id: data.id,
		}})
        dispatch({type: REPLACE_VWFACTURAS, payload: {
			Facturacion: data.Facturacion,
			Factura_Fiscal: data.Factura_Fiscal,
			Cliente: data.Cliente,
			Vendedor: data.Vendedor,
			Fecha: data.Fecha,
			Tasa_Imp: data.Tasa_Imp,
			Subtotal: data.Subtotal,
			Impuesto: data.Impuesto,
			Total: data.Total,
			Tipo: data.Tipo,
			Estado: data.Estado,
			Fecha_Sistema: data.Fecha_Sistema,
			head: data.head,
			id: data.id,
		}})
      })
      .then(() => {
        history.push(`./VwFacturas/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateVwFacturas = (VwFacturas, actionMode) => {
	if(actionMode===1){
        return addVwFacturas(VwFacturas);
      }else {
        return updateVwFacturas(VwFacturas);
      }
};