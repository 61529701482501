import { RECEIVE_NDIPRODUCTFIELDS, UPDATE_NDIPRODUCTFIELDS } from '../actions/NdiProductFieldsAction';

export default function singleNdiProductFieldsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDIPRODUCTFIELDS:
      return action.NdiProductFields;
    case UPDATE_NDIPRODUCTFIELDS:
      return {
        id: action.id,
product_type_id: action.payload.product_type_id,
field_id: action.payload.field_id,
required: action.payload.required,
visual_order: action.payload.visual_order,
enabled: action.payload.enabled,
creation_date: action.payload.creation_date,
user_creation_id: action.payload.user_creation_id,
user_update_id: action.payload.user_update_id,
last_update: action.payload.last_update,
      }
    default:
      return state;
  }
};