import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './stylesheets/loader.css';
export default function Loader(props) {
	//LoaderDialog()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => { 
	setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if(props.openx!==true){
	  return (<span />);
  }else{
	  //setOpen(true);
	  setTimeout(() => {
		  setOpen(true);  
	  }, 500);
  }
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" 
        disableBackdropClick disableEscapeKeyDown 
      >
        <DialogTitle id="alert-dialog-title">{"loading please, wait..."}</DialogTitle>
        <DialogContent>
          <div className="loaderPicx">
            <img src="/static/assets/img/sceau-garantie-neo-fr.png" height="100" width="100" className="m-b-10"/>
          </div>       
			    <div className="loader"></div>
          <DialogContentText id="alert-dialog-description"> 
			      we are processing, meanwhile please wait...
          </DialogContentText>
        </DialogContent>
        <DialogActions> 
          {/* <Button onClick={handleClose} color="primary">
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}