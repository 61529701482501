import { RECEIVE_NDIPRODUCTTYPES, UPDATE_NDIPRODUCTTYPES } from '../actions/NdiProductTypesAction';

export default function singleNdiProductTypesReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDIPRODUCTTYPES:
      return action.NdiProductTypes;
    case UPDATE_NDIPRODUCTTYPES:
      return {
        id: action.id,
name: action.payload.name,
main_category: action.payload.main_category,
parent: action.payload.parent,
      }
    default:
      return state;
  }
};