import { RECEIVE_NDIFIELDS, RECEIVE_NDIFIELDS2, UPDATE_NDIFIELDS } from '../actions/NdiFieldsAction';

export default function singleNdiFieldsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDIFIELDS:
      return action.NdiFields;
    case RECEIVE_NDIFIELDS2:
        return action.NdiFields;  
    case UPDATE_NDIFIELDS:
      return {
        id: action.id,
name: action.payload.name,
text_label: action.payload.text_label,
placeholder: action.payload.placeholder,
field: action.payload.field,
field_type: action.payload.field_type,
field_default_value: action.payload.field_default_value,
mask: action.payload.mask,
field_service: action.payload.field_service,
field_dataset: action.payload.field_dataset,
tag_id: action.payload.tag_id,
      }
    default:
      return state;
  }
};