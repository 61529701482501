import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNdiFields, getNdiFields, getProductFields, getTicketSummary, addOrUpdateTicketData, downloadFileTicketData,downloadFileTicketDataPreview, getClientTransactions, addFoldersProducts, downloadFileInvoiceTicketData, downloadFileJudiciaryInvoice, getNeoDocNotes } from '../../actions/NdiFieldsAction'; 
import { addOrUpdateNeoVentes, addNeoVentesSC, addOrUpdateRefunds, getNeoVentes, getClientSearch, getB2BClientSearch, saveB2BClient, checkClientEligibility, getB2BContractListDetails, getClientAddress, getCodePromo, getNeoVentesPromos, getProductInfo, getPartialInvoicePayments, getAllProducts, getChargeDeskStatus, getClientTransactionsFullInfo } from '../../actions/NeoVentesAction'; 
//import { addOrUpdateNeoProducts, getNeoProducts, getNeoProductsAll, getNeoProductsGoto, deleteNeoProducts } from '../../actions/NeoProductsAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
//import CustomToolbarSelect from "../CustomToolbarSelect";
import { axiosDefaultsCred } from '../../appConfigaxios'; 

import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';


import Select from 'react-select';
import Autocomplete from '@material-ui/lab/Autocomplete';    
import InputMask from "react-input-mask";
import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormGroup from '@material-ui/core/FormGroup'; 
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Toastr from "../../messages";
import  Loader from "../../loader";
import MuiAlert from '@material-ui/lab/Alert';

import SaveIcon from '@material-ui/icons/Save';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import HelpIcon from '@material-ui/icons/Help';
import EmailIcon from '@material-ui/icons/Email';
//import SaveSharpIcon from '@material-ui/icons/SaveSharp';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 

import ZAFClient from 'zendesk_app_framework_sdk';
import { RotateRightSharp } from '@material-ui/icons';
import StripePayment from '../../StripePayment'; 
import moment from 'moment';
import  getProvinceTaxInfo from "../../appTaxes"; 

/////////////////////////////
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';  
import Grid from '@material-ui/core/Grid'; 
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete'; 
import DownloadIcon from '@material-ui/icons/CloudDownload';
//import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import SpellcheckIcon from '@material-ui/icons/Edit';
//import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails'; 
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import  { dateFormat } from "../../appDateFormat";
import history from '../../history'; 

const imagList = ["png","jpg","bmp","gif","jpeg","tif","tiff"];

class NeoFilePreviewPDF extends Component {
  
  constructor(props) {
    super(props);
    /*this.state = {
		id:0,
		id_invoices:'', e_id_invoices: false,
		amount:'', e_amount: false,
		tax1:'', e_tax1: false,
		tax2:'', e_tax2: false,
		token:'', e_token: false,
		email:'', e_email: false,
		result:'', e_result: false,
		code:'', e_code: false,
		declined_code:'', e_declined_code: false,
		transact:'', e_transact: false,
		created:'', e_created: false,
    }*/

    this.stateInit = {
			id:0,
			name:'', e_name: false,
			description:'', e_description: false,
			amount:'', e_amount: false,
			
			productCart:  [], 
			productId:"", productTitre:'', product:{}, productPrice:'', productPriceTax:'', productPriceTaxOnly:0, productExtraOptions:[], 
			productExtraOptionCtrl:null, productExtraOptionCtrl2:[], showProductOption:false, 
			prodTagPrice:  [], prodTagPriceTax:  [], prodTagPriceTaxOnly:  [], prodTagTotOptions:  [], grandTotal: 0, 
		
			idClient:'0',
			firstname:'', e_firstname: false,
			lastname:'', e_lastname: false,
			cell:'', e_cell: false,
			terms:'', e_terms: false,
			active:'', e_active: false,
			created:'', e_created: false,
			email:'', e_email: false,
			id_users:'0', e_id_users: false,
			language:'fr', e_language: false,
			push:'', e_push: false,
			id_zendesk:'', e_id_zendesk: false,
			sms:1, e_sms: false,
			stripe_id:'', e_stripe_id: false,
		
		
			idAddress:0, 
			address:'', e_address: false,
			unitsuite:'', e_unitsuite: false,
			city:'', e_city: false,
			province:'QC', e_province: false, province2:'', 
			cp:'', e_cp: false,
			country:'Canada', e_country: false,
			company:'', e_company: false, 
			existedClientRO: false, openPossibleZipCode:false, listAddressOnCode: null, listZipCodeAddresses:[], countryTuner:'',  
			listPromotionCodes:[], codepromoOptions:[], openInfoStepsPopup:false, infoStepsPopupno:0,
			infoProduct:{data:[]}, infoProduct_title:"", infoProduct_excerpt:"", partialCanClose:0,
			openTransactionPopup: false, twoStepSale:"",
		
			 
			listOfSuggestion:[],
			valueClientSearch:'', 
			inputValueClientSearch:'',
			selectedIdSuggestion:'',
	  
			listOfSuggestion2:[],
			valueClientSearch2:'', 
			inputValueClientSearch2:'',
			selectedIdSuggestion2:'',
	  
			clientInfo:{ }, 
			clientAddress:{ },
			globalStepWorkflow: 1, // 1 init 2:start with product 3:start with customerInfo 4:payment 5: case close 
			workflowType: 1, //1 begin, 2 with clientInfo, 3 with Prod
	  
			stripePaymentOpen: false, isLoaderOpen:false, 
			toastrMessage: '', toastrType: '', toastrDuration: 6000,
			cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
			orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
		
			stripeToken:'',
			dossier:'', //421
			isRecurrentPayment: 0,  nowPayment: 0, qtyPayment: 1, laterpayment:4, fromDatePayment:'', 
			paymentRound:0, paymentWay: 'full', nowPaymentRO: true, recurPaymentAmount: 0,
			paymentsReceived: [0], totalPaymentReceived: 0, paymentWayRO: false, beforePaymentTotal:0, 
			id_invoice: 0, pMethod:1, status_invoice:'', 
			showProdMulti:0, productsList:{"products": []}, openPListAsOnTheSite: false,
			errorTransacMessage: "", emailDoesNotExist: 0, openPossibleListOfClient: false,
			listClientOnCode:[], possibleListOfClient:[],
			statusChargeDesk: 0, chargeDeskIdStr:"",
			openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}, possibleListOfCDStatusInfo:[],  
			taxInfo:{}, isupsale:false, upsaleTicket:"", isAmerindian:false, no_amerindien:"", 
			listOfTickets:[], goProd:0, 
			newTicket:false, currentTicket:'', disabledBtn:false, 


      refundtype:'', observation:'', clientspecimen:null, 
      refundList:[],  refunds:[], refundValList:[],    
      currentRefundValue:0, refundedValue:0, 
      currentRefundInvValue:0, refundedInvValue:0, refundedInvId:0, refundedInvPayId:0, refundedInvPayVal:0, 

      dataFileBlob:null, 
		};
	  
			this.state = JSON.parse(JSON.stringify(this.stateInit)); // Object.assign(this.stateInit);

  }

  productsList={"products": []}; // this.state.productsList; // productList();

  componentDidMount() {                                                         
    //this.props.getNeoPayments(this.props.match.params.id);
    /*const idx = this.props.match.params.id;  
    Promise.resolve(this.props.getNeoPayments(idx))
    .then((response)=>{
      //console.log("0000********INI**********"); console.log(response.NeoPaymentsOne);  console.log("0000********FIN**********");
      this.setState(response.NeoPaymentsOne); 
      return response;
    });*/

    // const { t } = this.props;
		// const taxInfo=getProvinceTaxInfo("QC");
		// this.setState({ ["taxInfo"]: taxInfo,"language":"" });  
		this.setState({ ["productCart"]: [],"productId":"",	 });  
    this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
   this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
     this.setState({ ["nowPayment"]:  0 });

     if (this.props.dataXFile!==undefined){
      this.handleDownloadFile(null);
    }  

 
   //const clientProdTransInfo=this.props.clientProdTransInfo; 
   //const client=clientProdTransInfo.client; 
    

   //const Alert=this.Alert;
   //const clientProdTransInfo=
   let stripeId="";
   //console.log("woooooooooooooo ", client);
  //  if(client!==undefined && client!==null){  
  //    this.setState(client);  
  //    this.setState({["clientInfo"]: client, ["idClient"]: parseInt(client.id)});  
  //  }


  }

  componentDidUpdate(prevProps) {
		/*if (prevProps.openx !== this.props.openx) {
		  //this.updateAndNotify();
		  if(this.props.openx){
			//console.log("WEEEEEEEEEEEE MINIPAYMENT componentWillMount OPEN ", new Date());
			this.setState({ ["productCart"]: [],"productId":"",	 });  
			this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
			this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
			this.setState({ ["nowPayment"]:  0, ["paymentRound"]:  0 });
		  }
		}*/

    //handleDownloadFile = (event)
    if (prevProps.dataXFile !== this.props.dataXFile && this.props.dataXFile!==undefined){
      this.handleDownloadFile(null);
    }  

       
	}


  
	  
	setMultiactions = (eventargs) => {
		const closeme=eventargs.closeme;
		// let isChecked=false; 
		// if(event.target.checked){ isChecked=true; }
		// this.setState({ [event.target.name]: isChecked });
		if(closeme!==null && closeme!==undefined && closeme==="close"){
			const obj={};
		} 
	
	};

	
	closeFolderProductPopup  = (anyEvent) => {     
		this.setState({["openProductFolder"]: false, ["disabledBtn"]: false }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success"); 
		const obj={closeme:'close'};
		if(this.state.paymentRound >0){
			obj.refreshafterPayment=1;
		} 
		// const taxInfo=this.state.taxInfo; 
		// const clientAddress=this.state.clientAddress;
		// const province=this.state.province;

		// const taxInfo=JSON.parse(JSON.stringify(this.state.taxInfo));
		// const clientAddress=JSON.parse(JSON.stringify(this.state.clientAddress));
		// const province=JSON.parse(JSON.stringify(this.state.province));
		// const currentTicket=JSON.parse(JSON.stringify(this.state.currentTicket));

		// this.setState(JSON.parse(JSON.stringify(this.stateInit)));
		// this.setState({ ["clientAddress"]: clientAddress, 
		// 	["province"]: province, ["taxInfo"]: taxInfo, ["currentTicket"]: currentTicket});  

		this.props.multiactions(obj);
	}

	handleTransactionChange = (event) => {                                                             
		//event.preventDefault();  
		//const stepTrack=event.target.dataset.prodcontrol;
		const selVal= event.value;  //productId
		this.setState({ ["transactionId"]: selVal });    
	};

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info	  
		//console.log("showToast showToast "); //console.log(arg);
		//alert("hello it is an eventDrop here "+arg.date);
		//Toastr("Hello I am a toast message right here, for testing purpose...");
		//toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		this.setState({ // update a property
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, 5500);
	}

  //handleChangeRefund
  handleChangeRefund = (event) => {
    const eventName=event.target.name;
    const eventVal=event.target.value;
    const index=event.target.dataset.index;
    const paymentId=event.target.dataset.paymentid;
    const max=event.target.dataset.max;
    const refunded=event.target.dataset.refunded;
    //this.setState({ [event.target.name]: event.target.value }); 
    const refundList=this.state.refundList;  
    console.log("eventName, eventVal, index, paymentId, refunded:", eventName, eventVal, index, paymentId, max,refunded, refundList);

    if(parseFloat(eventVal) >parseFloat(max)){
      this.showToast("S'il vous plaît veuillez d'ajouter une valeur qui est moins ou égal à:"+max, "error");
      return false;
    }

    const baseElemx=refundList[index];

    if(parseFloat(refunded) >=parseFloat(max)){
    //if(baseElemx!==null && baseElemx.refundedAmount!==undefined && parseFloat(baseElemx.refundedAmount) >=parseFloat(max)){  
      this.showToast("Désolé vous ne pouvez pas continuer avec le remboursement parcequ'on a déjà remboursé: "+baseElemx.refundedAmount, "error");
      return false;
    }

    if(refundList.length >0){
      const baseElem=refundList[index];
      if(eventName==="refundtype"){ baseElem.refundtype=eventVal; }
      else if(eventName==="amount"){ baseElem.amount=eventVal; } 
      else if(eventName==="nontaxableamount"){ baseElem.nontaxableamount=eventVal; } 
      refundList[index]=baseElem;
      this.setState({ ["refundList"]: refundList });   
    }else{
      //const baseElem={payment_id:paymentId, amount:0, refundtype:'', max:max };
      const baseElem={payment_id:paymentId, amount:0, refundtype:'', max:max, nontaxableamount:0 };
      if(eventName==="refundtype"){ baseElem.refundtype=eventVal; }
      else if(eventName==="amount"){ baseElem.amount=eventVal; }
      else if(eventName==="nontaxableamount"){ baseElem.nontaxableamount=eventVal; } 
      refundList.push(baseElem); 
      this.setState({ ["refundList"]: refundList }); 
    }

    console.log("WATCHEEEEEEEEEEEER", refundList); 
  };
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  // if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		//   this.setState({ ["e_"+event.target.name]: false });
		//   if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
		// 	  this.setState({ ["e_"+event.target.name]: true }); 	
		//   }
	  // } 	
  };

  handleApplyRefund = (event) => {
    event.preventDefault();
    const { t } = this.props;
    //this.setState({ [event.target.name]: event.target.value });
    const refundList=this.state.refundList; 
    const invoiceId=this.props.invoiceId;
    const paymentId=this.props.paymentId;
    let validRefund=0; 
    const refList=[];
    let refundtype=0;
    const observation=this.state.observation;

    console.log("ABSURDE ", refundList);

    for(const refund of refundList){
      if(refund.refundtype==="total"){ 
        refundtype++;   
      }
      else if(refund.refundtype==="partiel"){
        refundtype++;  
      }
    } 

    if(refundtype  <1){ 
      this.showToast("S'il vous plaît veuillez de choisir un type de remboursement.", "error");
      return false;
    } 

    for(const refund of refundList){
      if(refund.refundtype==="total" && refund.amount!=="" && parseFloat(refund.amount)>0 && refund.nontaxableamount!=="" && parseFloat(refund.nontaxableamount)>=0){ 
        validRefund++; 
        refList.push(refund);
      }
      else if(refund.refundtype==="partiel" && parseFloat(refund.amount) >0  
      && parseFloat(refund.amount)<=parseFloat(refund.max) && refund.nontaxableamount!=="" && parseFloat(refund.nontaxableamount)>=0){
         validRefund++; 
         refList.push(refund);
      }
      else if(refund.refundtype==="partiel" && parseFloat(refund.amount) >=0  
      && parseFloat(refund.amount)<=parseFloat(refund.max) && refund.nontaxableamount!=="" && parseFloat(refund.nontaxableamount)>0){
         validRefund++; 
         refList.push(refund);
      }
    } 
    if(validRefund <1){
      //t("zqwNVFrmEl1_x")
      this.showToast("S'il vous plaît veuillez d'ajouter le montant de paiement à rembourser et le montant non taxable s'il y a lieu.", "error");
      return false;
    }
    
    if(observation===null || observation===""){
      this.showToast("S'il vous plaît veuillez d'expliquer la raison du remboursement.", "error");
      return false;
    }
    
    let objToSend=null;
    const clientspecimen=this.state.clientspecimen;
    //if(clientspecimen!==null && clientspecimen.length >0){ 
      //console.log("wewewe l="+clientspecimen.length+" n="+clientspecimen.name+" t="+clientspecimen.type)
    if(clientspecimen!==null && clientspecimen.name!==undefined && clientspecimen.name!==null){
      //const objToSend={invoiceId:invoiceId, observation:observation, refunds:refList};
      //}else{
       objToSend = new FormData();  
        objToSend.append( 
          "file", 
          clientspecimen, 
          clientspecimen.name 
        ); 
        objToSend.append('isMULTIPART', 1); 
        objToSend.append('xfiletype', clientspecimen.type); 
        objToSend.append('invoiceId', invoiceId); 
        objToSend.append('observation', observation); 
        objToSend.append('refunds', JSON.stringify(refList)); 
        console.log("weeeeeeeeeeeeeeeee sending with file ");  
    }else{
      objToSend={invoiceId:invoiceId, observation:observation, refunds:refList};
      console.log("weeeeeeeeeeeeeeeee nooooooooooo file at all ");
    } 
      
    //this.showToast("Uuuuuuuh way, you have passed it Tiiiiiiiiiiiil heeeeeeeere.", "success"); return false; 
    
    const this2=this; 
    this2.setState({ ["isLoaderOpen"]: true});  
    Promise.resolve(this.props.addOrUpdateRefunds(objToSend))
    .then((response)=>{
      const clientTransInfo=response.NeoVentes.data;
      //this.setState(response.NdiFieldsOne);
      /*const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
      const isClientId=clientProdTransInfo.client.id;
      const isClientLead=isClientId >0?false:true; 
      //console.log("..HOHOHO response from server .... ");  //console.log(response.NdiFieldsOne.data);
      this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
      this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead }); 	
      //console.log("HOHOHO just assinged to dynamicFormAllData .... "); */	

      console.log("..HOHOHO response from server .... ");  console.log(response);
      
      this.setState({ ["isLoaderOpen"]: false, ["refunds"]: clientTransInfo.refunds, ["disabledBtn"]:true, ["paymentRound"]:1,});
      this.showToast(t("zqwNFFtxt11_x"), "success"); 
      // this.saveUpdateTicketData(null);  
      // setTimeout(() => {
      // 	this.showToast(t("zqwNFFtxt10_x"), "warning");  
      // 	this.saveUpdateTicketData(null);  
      // }, 10000);   
      return response;
    }).catch(function(error){ 
      this2.setState({ ["isLoaderOpen"]: false});  
      //alert(error);
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n "+t("zqwNVFrmEl14_x")
      }
      //this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      //console.log(rawError);
      this2.showToast(errMessage, "error"); 
    });
    

  };
   
  getXRecordObjects = (myArr, fieldName, fieldValues, oneObject) => {
    let fieldBlock=[]; let fieldBlockOne=null; 
    let promList = myArr.length > 0
      && myArr.map((item, i) => {  
      //let fieldBlock=null; 
      //const zx=item[fieldName];      
        if(fieldValues!==null && fieldValues.length > 0 && fieldValues.includes(item[fieldName])){
          if(oneObject){
            fieldBlockOne=item; 
          }else{
            fieldBlock.push(item);
          }
          //fieldBlock=item; 
        }       

      // if(item.product_type_id===fieldValue){
      //   fieldBlock=item; 
      // }
      
      return ( null );
    }, this);
    
      //return fieldBlock;
      if(oneObject){
        return fieldBlockOne; 
      }else{
        return fieldBlock;
      }
  }
 


  getXRecordObjects000 = (myArr, fieldName, fieldValue, oneObject) => {
    let fieldBlock=[]; let fieldBlockOne=null; 
    let promList = myArr.length > 0
      && myArr.map((item, i) => {  
      //let fieldBlock=null;       
        if(item[fieldName]===fieldValue){
          if(oneObject){
            fieldBlockOne=item; 
          }else{
            fieldBlock.push(item);
          }
          //fieldBlock=item; 
        }       

      // if(item.product_type_id===fieldValue){
      //   fieldBlock=item; 
      // }
      
      return ( null );
    }, this);
    
      //return fieldBlock;
      if(oneObject){
        return fieldBlockOne; 
      }else{
        return fieldBlock;
      }
  }


  handleDownloadFile = (event) => {                                                             
    /*event.preventDefault();
    const { t } = this.props;
    
	if(bucketTxt!==undefined && bucketTxt!==null){
		myNdiTicketDataLeft.bucket=bucketTxt;
	}*/

	//this.showToastError("nooooooooooooooooooooooooooo ooooooooooooooooooh!\n"+pacx); 	return false; 
	 
  if(this.props.dataXFile===undefined || this.props.dataXFile===null){
    return false;
  }

  const myNdiTicketDataLeft = this.props.dataXFile;
  myNdiTicketDataLeft.data64base="2";
  const this2=this;

  const filename=(""+myNdiTicketDataLeft.shortFilename).toLowerCase();
  const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length); 

  console.log("shortFilename-shortFilename 000000000****000000 .... ", myNdiTicketDataLeft.shortFilename);  


	Promise.resolve(this.props.downloadFileTicketDataPreview(myNdiTicketDataLeft))
	.then((response)=>{ 
    //console.log("HOHOHO response typeeeeeeee teeeeest .... ");  
    //console.log(typeof (response.NdiFields));
    //const respData=new Uint8Array(response.NdiFields);
    //const respDatab=new Blob(response.NdiFields);
    //const file = new Blob([(myBlob)], {type: 'application/pdf'});

    //const respData = new Blob([(response.NdiFields)], {type: 'application/pdf'});
    ///const respData = await new Response(response.NdiFields).text();
    const reader = new FileReader();
    reader.addEventListener("loadend", () => {
      // reader.result contains the contents of blob as a typed array
      //console.log("HOHOHO************** reader-reader.... ");  
      //console.log(reader.result);
      
      //var bytes = Object.keys(dataURI).length;
      // var myArr = new Uint8Array(bytes);
      // for(var i = 0; i < bytes; i++){
      //     myArr[i] = dataURI[i].charCodeAt(0);
      // }  
      // return myArr;
      
      //this.setState({ ["dataFileBlob"]: new Uint8Array(reader.result),  });

      if(imagList.includes(extension)){
        this.setState({ ["dataFileBlob"]: reader.result,  });
        //console.log("H00001************** reader-reader IMAAAAAAAAAAAAAGE .... "+extension);  
      }else{
        this.setState({ ["dataFileBlob"]: new Uint8Array(reader.result),  });
        //console.log("H0002************** reader-reader-else.... "+extension);  
      }
    });
    
    //reader.readAsArrayBuffer(response.NdiFields);
    if(imagList.includes(extension)){ 
      reader.readAsDataURL(response.NdiFields);
    }else{
      reader.readAsArrayBuffer(response.NdiFields);
    }

    

    //console.log("HOHOHO response typeeeeeeee teeeeest fileblob-fileblob.... ");  
    //console.log(typeof (respData));


    //const respData=this.convertDataURIToBinary3(response.NdiFields);
    //this.setState({ ["dataFileBlob"]: response,  });
    //this.setState({ ["dataFileBlob"]: respData,  }); //response.NdiFields
     
		//this.setState(response.NdiFieldsOne);
		//const resp=response.NdiFields;
		////consoleXlog("HOHOHO response from server .... ");  //consoleXlog(resp); //NdiFields
		//this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
    
    //console.log("HOHOHO response from server FileTicketDataPreview .... ");  
    ///console.log(response);  
    //console.log(response.NdiFields);  
    ///console.log(respData);  

		return response;
	}).catch(function(error){ 
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
		  errMessage +="\n There was some error from the expected data from the server. Please try again later."
		}
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		//this2.showToastError(errMessage);
		 
		console.log("ERROR WITH TRANSACTION"); 
		//consoleXlog(error);
		console.log("SHORT ERROR MESSAGE: "+errMessage);
		//consoleXlog("RWA RESPONSE"); 
		////consoleXlog(rawError);
		 
		//localStorage.setItem("transerror", JSON.stringify(error));
		//localStorage.setItem("transerrorrawresp", JSON.stringify(rawError));
		//alert(errMessage); //error
	  });

	//this.showToastError("---- laaaaaaaaaaaaast message to show \n"+pacx); 
	return false; 
 
  };


  //var BASE64_MARKER = ';base64,';
  convertDataURIToBinary2 = (dataURIx) => {
    var BASE64_MARKER = ';base64,';
    //function convertDataURIToBinary(dataURI) {
    const dataURI=this.state.dataFileBlob;
    if(dataURI===null) return null;

    var bytes = Object.keys(dataURI).length;
    var myArr = new Uint8Array(bytes)

    for(var i = 0; i < bytes; i++){
        myArr[i] = dataURI[i].charCodeAt(0);
    }
 
    return myArr;
  }

  convertDataURIToBinary3 = (dataURI) => {
    var BASE64_MARKER = ';base64,';
    //function convertDataURIToBinary(dataURI) {
    //const dataURI=this.state.dataFileBlob;
    if(dataURI===null) return null;

    const bytesfile = FileReader.readAsArrayBuffer(dataURI); 

    console.log("HOAHOAHOA7777777 from server oooooooobytes= .... ", bytesfile.result);  


    var bytes = Object.keys(dataURI).length;
    var myArr = new Uint8Array(bytes);
    console.log("HOHOHO7777777 from server bytes= .... "+bytes);  

    for(var i = 0; i < bytes; i++){
        myArr[i] = dataURI[i].charCodeAt(0);
    }
 
    return myArr;
  }


  convertDataURIToBinary = (dataURIx) => {
    var BASE64_MARKER = ';base64,';
    //function convertDataURIToBinary(dataURI) {
    const dataURI=this.state.dataFileBlob; 
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for(let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }



  render() {
    const { t } = this.props;
    //const NeoPayments = this.props.NeoPayments;
    const NeoPayments = this.state; 
    const open = this.props.openx; 

    const goToPrevPage=null;
    const goToNextPage=null;
    const pageNumber=1;
    const numPages=3;
    const PDFApp=this.PDFApp;

    return (
      <div>
        {/* <nav>
          <button onClick={goToPrevPage}>Prev</button>
          <button onClick={goToNextPage}>Next</button>
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </nav>
        DOOOOOOOOOOOOOCUMENT PDF VIEWER <br /><br /><br />
        aaaaaaaaaaaaa <br />aaaaaaaaa <br />aaaaaa */}
  
        {/* <Document
          file="document.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document> */}

      <PDFApp {...this.props.dataXFile} />

 
      </div>
    );
  }

  PDFApp = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };
  
    const goToPrevPage = () =>
      setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  
    const goToNextPage = () =>
      setPageNumber(
        pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
      );

    const goToExactPage = (event) =>{
      let pageNumberx=parseInt(event.target.value);
      if(pageNumberx<1){ pageNumberx=1; }
      // setPageNumber(
      //   pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
      // );
      setPageNumber(
        pageNumberx>= numPages ? numPages : pageNumberx,
      );
    };

      const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
      };

      if(props.fileaddress===undefined || props.fileaddress===null || props.fileaddress===""){
        return null;
      }

      let productOptions = [];
      let pageOptions = [];

      for(let p=1; p<=numPages; p++){
        //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
        pageOptions.push(<option value={p} key={"pg"+p}> {p} </option>);
      }

      


      //const dataFileBlob=this.state.dataFileBlob;

      

      const confd = axiosDefaultsCred(null); 
      let URLtxt="http://127.0.0.1:8055/api/ndi/ticketdata/file/preview?id=0&ticketId=908888&lang=fr-FR"; 
      
      //const obj={"id":1452250,"product_field_id":"161","case_id":"142510","field_data":null,"field_data_raw":"","fieldname":"docpenalList_labelnodel","fieldid":"127","realticket_id":"908888","fileaddress":"dossier_client/tickets/908888/202210051740170c58d73cfa39e588.pdf","shortFilename":"202210051740170c58d73cfa39e588.pdf","bucket":""};
      const obj={"realticket_id":props.realticket_id,"fileaddress":props.fileaddress,"shortFilename":props.shortFilename,"bucket":props.bucket};
      const ojbparams = new URLSearchParams(obj);

      //url: 'http://example.com/sample.pdf', 

      //const credentials={userId:"0", token:"", };

      const objFile00={ url: URLtxt+'&'+ojbparams, 
      httpHeaders: { 'Authorization': confd.token, 'auth-id': confd.userId }, withCredentials: false };

      const objFile={ data: this.state.dataFileBlob };
      //const objFile={ data: this.convertDataURIToBinary2("") };
      //convertDataURIToBinary = (dataURIx)
      //var newurl = window.URL.createObjectURL(this.state.dataFileBlob);
      //document.getElementById("myPDFIFrame").src = newurl;
      
      //const imagList = ["png","jpg","bmp","gif","jpeg","tif","tiff"];
      const filename=(""+props.shortFilename).toLowerCase();
      const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
      let isImage=false; let isPDF=false; 
      if(extension==="pdf"){
        isPDF=true; 
      } 
      else if(imagList.includes(extension)){
        isImage=true;
      }

      if(isImage){
        // // Make a Blob from the bytes
        // var blob = new Blob([bytes], {type: 'image/bmp'});
        // // Use createObjectURL to make a URL for the blob
        // var image = new Image();
        // image.src = URL.createObjectURL(blob);
        // document.body.appendChild(image);

        //URL.createObjectURL(objFile.data)
        // Base64.encode(blob) 
        // imgData64=  Base64.encode(objFile.data); 
        //"data:image/"+extension+";base64,"+    width={"100%"} height={"100%"}

        return (
          <div>
            <img src={objFile.data} alt="customer image" border="0"></img>
          </div>
        );
      }

      if(extension!=="pdf"){
        return (
          <div>
            Error on ne support pas encore des fichiers de {extension} /
            Error we do not support {extension} file yet.
          </div>
        );
      } 
    
  
    return (
      <div>
        <nav>
          <button onClick={goToPrevPage}>Prev</button>
          <button onClick={goToNextPage}>Next</button> 
           &nbsp; 
          <select name="nopage" id="nopage" value={pageNumber} onChange={goToExactPage} placeholder={'page'} >
                <option value="0">Page</option>
                {pageOptions}
          </select> 

          <p>
            Page {pageNumber} of {numPages}
          </p>
        </nav>
  
        {/* <Document
          file="/document.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document> */}

        <Document
          file={objFile}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>

        {/* <iframe sandbox id="myPDFIFrame"
              title="Inline Frame Example"
              width="300"
              height="200"
              src={this.state.dataFileBlob}
              // srcDoc={this.state.dataFileBlob}
              >
        </iframe> */}

        {/* <Document file={objFile} onLoadSuccess={onDocumentLoadSuccess} options={options}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
        </Document> */}
      </div>
    );
  };
 

  onFileChange = event => { 
    // Update the state 
    //console.log("weeeeeeeeeeeeeeeee file change "+event.target.files[0].name);
    //this.setState({ ["clientspecimen"]: event.target.files[0] }); 
    if(event.target.files[0]===undefined || event.target.files[0].name===undefined){
      this.setState({ ["clientspecimen"]: null }); 
    }else{
      this.setState({ ["clientspecimen"]: event.target.files[0] }); 
    }
  }; 


  array_column =(input, ColumnKey, IndexKey) =>  {  
    //   discuss at: https://locutus.io/php/array_column/
    if (input !== null && (typeof input === 'object' || Array.isArray(input))) {
      const newarray = []
      if (typeof input === 'object') {
        const temparray = []
        for (const key of Object.keys(input)) {
          temparray.push(input[key])
        }
        input = temparray
      }
      if (Array.isArray(input)) {
        for (const key of input.keys()) {
          if (IndexKey && input[key][IndexKey]) {
            if (ColumnKey) {
              newarray[input[key][IndexKey]] = input[key][ColumnKey]
            } else {
              newarray[input[key][IndexKey]] = input[key]
            }
          } else {
            if (ColumnKey) {
              newarray.push(input[key][ColumnKey])
            } else {
              newarray.push(input[key])
            }
          }
        }
      }
      return Object.assign({}, newarray)
    }
  }

 

}


const mapStateToProps = (state) => ({ NeoProducts: state.NeoProducts }); 
//const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes }); 
//const mapDispatchToProps = { addOrUpdateNeoProducts, getNeoProducts, getNeoProductsAll, getNeoProductsGoto, deleteNeoProducts };   
const mapDispatchToProps = { addOrUpdateNeoVentes, addNeoVentesSC, addOrUpdateRefunds,
  downloadFileTicketData, downloadFileTicketDataPreview, };           
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoFilePreviewPDF));
