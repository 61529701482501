import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDIFOLDERSPRODUCTS = 'GET_NDIFOLDERSPRODUCTS';
export const ADD_NDIFOLDERSPRODUCTS = 'ADD_NDIFOLDERSPRODUCTS';
export const RECEIVE_NDIFOLDERSPRODUCTSX = 'RECEIVE_NDIFOLDERSPRODUCTSX';
export const REMOVE_NDIFOLDERSPRODUCTS = 'REMOVE_NDIFOLDERSPRODUCTS';
export const UPDATE_NDIFOLDERSPRODUCTS = 'UPDATE_NDIFOLDERSPRODUCTS';
export const REPLACE_NDIFOLDERSPRODUCTS = 'REPLACE_NDIFOLDERSPRODUCTS';
const apiUrl = API_DOMAIN + '/ndifoldersproducts'; 

axiosDefaults(null);

export const getNdiFoldersProductsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_NDIFOLDERSPRODUCTS, NdiFoldersProducts: response.data.ndifoldersproducts.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiFoldersProducts = (NdiFoldersProducts) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiFoldersProducts.id,
		ticket_id: NdiFoldersProducts.ticket_id,
		product_id: NdiFoldersProducts.product_id,
		enabled: NdiFoldersProducts.enabled,
		creation_date: NdiFoldersProducts.creation_date,
		user_creation_id: NdiFoldersProducts.user_creation_id,
		user_update_id: NdiFoldersProducts.user_update_id,
		last_update: NdiFoldersProducts.last_update,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDIFOLDERSPRODUCTS, payload: {
			id: data.id,
			ticket_id: data.ticket_id,
			product_id: data.product_id,
			enabled: data.enabled,
			creation_date: data.creation_date,
			user_creation_id: data.user_creation_id,
			user_update_id: data.user_update_id,
			last_update: data.last_update,
		}})
      })
      .then(() => {
        history.push("/NdiFoldersProducts")
      })
      .catch(error => { throw(error) });
  };
};
export const getNdiFoldersProducts = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.ndifoldersproducts);		  
        return dispatch({type: RECEIVE_NDIFOLDERSPRODUCTSX, NdiFoldersProductsOne: response.data.ndifoldersproducts});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiFoldersProductsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiFoldersProducts/${id}/edit`)
  };
};
export const deleteNdiFoldersProducts = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDIFOLDERSPRODUCTS, payload: {id}})
      })
      .then(() => {
        history.push("/NdiFoldersProducts")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiFoldersProducts = (NdiFoldersProducts) => {
  const id = NdiFoldersProducts.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiFoldersProducts.id}`, {
		ticket_id: NdiFoldersProducts.ticket_id,
		product_id: NdiFoldersProducts.product_id,
		enabled: NdiFoldersProducts.enabled,
		creation_date: NdiFoldersProducts.creation_date,
		user_creation_id: NdiFoldersProducts.user_creation_id,
		user_update_id: NdiFoldersProducts.user_update_id,
		last_update: NdiFoldersProducts.last_update,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDIFOLDERSPRODUCTS, payload: {
			id: data.id,
			ticket_id: data.ticket_id,
			product_id: data.product_id,
			enabled: data.enabled,
			creation_date: data.creation_date,
			user_creation_id: data.user_creation_id,
			user_update_id: data.user_update_id,
			last_update: data.last_update,
		}})
        dispatch({type: REPLACE_NDIFOLDERSPRODUCTS, payload: {
			id: data.id,
			ticket_id: data.ticket_id,
			product_id: data.product_id,
			enabled: data.enabled,
			creation_date: data.creation_date,
			user_creation_id: data.user_creation_id,
			user_update_id: data.user_update_id,
			last_update: data.last_update,
		}})
      })
      .then(() => {
        history.push(`/NdiFoldersProducts/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiFoldersProducts = (NdiFoldersProducts, actionMode) => {
	if(actionMode===1){
        return addNdiFoldersProducts(NdiFoldersProducts);
      }else {
        return updateNdiFoldersProducts(NdiFoldersProducts);
      }
};