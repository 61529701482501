import { RECEIVE_NDIFOLDERSPRODUCTS, UPDATE_NDIFOLDERSPRODUCTS } from '../actions/NdiFoldersProductsAction';

export default function singleNdiFoldersProductsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDIFOLDERSPRODUCTS:
      return action.NdiFoldersProducts;
    case UPDATE_NDIFOLDERSPRODUCTS:
      return {
        id: action.id,
ticket_id: action.payload.ticket_id,
product_id: action.payload.product_id,
enabled: action.payload.enabled,
creation_date: action.payload.creation_date,
user_creation_id: action.payload.user_creation_id,
user_update_id: action.payload.user_update_id,
last_update: action.payload.last_update,
      }
    default:
      return state;
  }
};