import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateNeoB2bClients, getNeoB2bClients } from '../../actions/NeoB2bClientsAction'; 
import { Validations } from '../../utils/validations'; 

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['id_b2b_list']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['id_external']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['name_id_external']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['firstname']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['lastname']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['email']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['tel']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['start_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['expiry_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['address']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['city']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['province']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['postal_code']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['raw_data']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['date_created']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['date_updated']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['status']={check:false, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION['dob']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['id_clients']={check:false, chkType:'int', message:'incorrect value'};

class NeoB2bClientsForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		id_b2b_list:'', e_id_b2b_list: false,
		id_external:'', e_id_external: false,
		name_id_external:'', e_name_id_external: false,
		firstname:'', e_firstname: false,
		lastname:'', e_lastname: false,
		email:'', e_email: false,
		tel:'', e_tel: false,
		start_date:'', e_start_date: false,
		expiry_date:'', e_expiry_date: false,
		address:'', e_address: false,
		city:'', e_city: false,
		province:'', e_province: false,
		postal_code:'', e_postal_code: false,
		raw_data:'', e_raw_data: false,
		date_created:'', e_date_created: false,
		date_updated:'', e_date_updated: false,
		status:'', e_status: false,
		dob:'', e_dob: false,
		id_clients:'', e_id_clients: false,
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.NeoB2bClients.id);
		if(this.props.NeoB2bClients.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getNeoB2bClients(idx);
			Promise.resolve(this.props.getNeoB2bClients(idx))
			.then((response)=>{
				this.setState(response.NeoB2bClientsOne);
				return response;
			});
		}	
	}	
  }

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const id_b2b_list = this.state.id_b2b_list;
    const id_external = this.state.id_external;
    const name_id_external = this.state.name_id_external;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const email = this.state.email;
    const tel = this.state.tel;
    const start_date = this.state.start_date;
    const expiry_date = this.state.expiry_date;
    const address = this.state.address;
    const city = this.state.city;
    const province = this.state.province;
    const postal_code = this.state.postal_code;
    const raw_data = this.state.raw_data;
    const date_created = this.state.date_created;
    const date_updated = this.state.date_updated;
    const status = this.state.status;
    const dob = this.state.dob;
    const id_clients = this.state.id_clients;
    const NeoB2bClients = {id: id, id_b2b_list: id_b2b_list, id_external: id_external, name_id_external: name_id_external, firstname: firstname, lastname: lastname, email: email, tel: tel, start_date: start_date, expiry_date: expiry_date, address: address, city: city, province: province, postal_code: postal_code, raw_data: raw_data, date_created: date_created, date_updated: date_updated, status: status, dob: dob, id_clients: id_clients, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoB2bClients[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateNeoB2bClients(NeoB2bClients, 1); 
		//this.props.addNeoB2bClients(NeoB2bClients); 
	}else{ 
		this.props.addOrUpdateNeoB2bClients(NeoB2bClients, 2);
		//this.props.updateNeoB2bClients(NeoB2bClients);
		
	}
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/NeoB2bClients`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/NeoB2bClients/${idx}`);
		//this.props.history.push(`/NeoB2bClients/${this.props.NeoB2bClients.id}`);
	}
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h3>{this.actionMode==='new'? t('zqwacAddNeoB2bClients_x'): t('zqwacEditNeoB2bClients_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		  
		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="id_b2b_list">{t('zqwid_b2b_list_x')}</label>
            <input type="text" name="id_b2b_list" id="id_b2b_list" defaultValue={this.state.id_b2b_list} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id_b2b_list ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_b2b_list_x')} /> 
			{this.state.e_id_b2b_list && (<span className="text-danger">{FIELD_VALIDATION['id_b2b_list'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="id_external">{t('zqwid_external_x')}</label>
            <input type="text" name="id_external" id="id_external" defaultValue={this.state.id_external} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id_external ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_external_x')} /> 
			{this.state.e_id_external && (<span className="text-danger">{FIELD_VALIDATION['id_external'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="name_id_external">{t('zqwname_id_external_x')}</label>
            <input type="text" name="name_id_external" id="name_id_external" defaultValue={this.state.name_id_external} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_name_id_external ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwname_id_external_x')} /> 
			{this.state.e_name_id_external && (<span className="text-danger">{FIELD_VALIDATION['name_id_external'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="firstname">{t('zqwfirstname_x')}</label>
            <input type="text" name="firstname" id="firstname" defaultValue={this.state.firstname} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_firstname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwfirstname_x')} /> 
			{this.state.e_firstname && (<span className="text-danger">{FIELD_VALIDATION['firstname'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="lastname">{t('zqwlastname_x')}</label>
            <input type="text" name="lastname" id="lastname" defaultValue={this.state.lastname} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_lastname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlastname_x')} /> 
			{this.state.e_lastname && (<span className="text-danger">{FIELD_VALIDATION['lastname'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="email">{t('zqwemail_x')}</label>
            <input type="text" name="email" id="email" defaultValue={this.state.email} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_email ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwemail_x')} /> 
			{this.state.e_email && (<span className="text-danger">{FIELD_VALIDATION['email'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="tel">{t('zqwtel_x')}</label>
            <input type="text" name="tel" id="tel" defaultValue={this.state.tel} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_tel ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtel_x')} /> 
			{this.state.e_tel && (<span className="text-danger">{FIELD_VALIDATION['tel'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="start_date">{t('zqwstart_date_x')}</label>
            <input type="text" name="start_date" id="start_date" defaultValue={this.state.start_date} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_start_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstart_date_x')} /> 
			{this.state.e_start_date && (<span className="text-danger">{FIELD_VALIDATION['start_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="expiry_date">{t('zqwexpiry_date_x')}</label>
            <input type="text" name="expiry_date" id="expiry_date" defaultValue={this.state.expiry_date} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_expiry_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwexpiry_date_x')} /> 
			{this.state.e_expiry_date && (<span className="text-danger">{FIELD_VALIDATION['expiry_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="address">{t('zqwaddress_x')}</label>
            <input type="text" name="address" id="address" defaultValue={this.state.address} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_address ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwaddress_x')} /> 
			{this.state.e_address && (<span className="text-danger">{FIELD_VALIDATION['address'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="city">{t('zqwcity_x')}</label>
            <input type="text" name="city" id="city" defaultValue={this.state.city} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_city ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcity_x')} /> 
			{this.state.e_city && (<span className="text-danger">{FIELD_VALIDATION['city'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="province">{t('zqwprovince_x')}</label>
            <input type="text" name="province" id="province" defaultValue={this.state.province} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_province ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwprovince_x')} /> 
			{this.state.e_province && (<span className="text-danger">{FIELD_VALIDATION['province'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="postal_code">{t('zqwpostal_code_x')}</label>
            <input type="text" name="postal_code" id="postal_code" defaultValue={this.state.postal_code} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_postal_code ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwpostal_code_x')} /> 
			{this.state.e_postal_code && (<span className="text-danger">{FIELD_VALIDATION['postal_code'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="raw_data">{t('zqwraw_data_x')}</label>
            <input type="text" name="raw_data" id="raw_data" defaultValue={this.state.raw_data} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_raw_data ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwraw_data_x')} /> 
			{this.state.e_raw_data && (<span className="text-danger">{FIELD_VALIDATION['raw_data'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="date_created">{t('zqwdate_created_x')}</label>
            <input type="text" name="date_created" id="date_created" defaultValue={this.state.date_created} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_date_created ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwdate_created_x')} /> 
			{this.state.e_date_created && (<span className="text-danger">{FIELD_VALIDATION['date_created'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="date_updated">{t('zqwdate_updated_x')}</label>
            <input type="text" name="date_updated" id="date_updated" defaultValue={this.state.date_updated} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_date_updated ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwdate_updated_x')} /> 
			{this.state.e_date_updated && (<span className="text-danger">{FIELD_VALIDATION['date_updated'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="status">{t('zqwstatus_x')}</label>
            <select name="status" id="status" defaultValue={this.state.status} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_status ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstatus_x')}> 
                        <option value="">Please Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
			{this.state.e_status && (<span className="text-danger">{FIELD_VALIDATION['status'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="dob">{t('zqwdob_x')}</label>
            <input type="text" name="dob" id="dob" defaultValue={this.state.dob} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_dob ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwdob_x')} /> 
			{this.state.e_dob && (<span className="text-danger">{FIELD_VALIDATION['dob'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="id_clients">{t('zqwid_clients_x')}</label>
            <input type="text" name="id_clients" id="id_clients" defaultValue={this.state.id_clients} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id_clients ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_clients_x')} /> 
			{this.state.e_id_clients && (<span className="text-danger">{FIELD_VALIDATION['id_clients'].message}</span>)} 
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			<button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>
          </div>
        </form>
      </div>
    );
  }
}

//const TransNeoB2bClientsForm = withTranslation()(NeoB2bClientsForm); 
const mapStateToProps = (state) => ({ NeoB2bClients: state.NeoB2bClients });

const mapDispatchToProps = { addOrUpdateNeoB2bClients, getNeoB2bClients };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoB2bClientsForm));
