import { RECEIVE_NDIFIELDOPTIONS, ADD_NDIFIELDOPTIONS, REMOVE_NDIFIELDOPTIONS, REPLACE_NDIFIELDOPTIONS } from '../actions/NdiFieldOptionsAction';

const initialState = { NdiFieldOptions: [] }
export default function NdiFieldOptionsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDIFIELDOPTIONS:
      return action.NdiFieldOptions;
    case ADD_NDIFIELDOPTIONS:
      return [action.payload, ...state];
    case REMOVE_NDIFIELDOPTIONS:
      return state.filter(NdiFieldOptions => NdiFieldOptions.id !== action.payload.id);
    case REPLACE_NDIFIELDOPTIONS:
      return state.map((NdiFieldOptions) => {
        if (NdiFieldOptions.id === action.payload.id) {
          return {
            ...NdiFieldOptions,
field_id: action.payload.field_id,
text_label: action.payload.text_label,
option_order: action.payload.option_order,
enabled: action.payload.enabled,
          }
        } else return NdiFieldOptions;
      })
    default:
      return state;
  }
}
