import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDIFIELDOPTIONSTRANSLATION = 'GET_NDIFIELDOPTIONSTRANSLATION';
export const ADD_NDIFIELDOPTIONSTRANSLATION = 'ADD_NDIFIELDOPTIONSTRANSLATION';
export const RECEIVE_NDIFIELDOPTIONSTRANSLATIONX = 'RECEIVE_NDIFIELDOPTIONSTRANSLATIONX';
export const REMOVE_NDIFIELDOPTIONSTRANSLATION = 'REMOVE_NDIFIELDOPTIONSTRANSLATION';
export const UPDATE_NDIFIELDOPTIONSTRANSLATION = 'UPDATE_NDIFIELDOPTIONSTRANSLATION';
export const REPLACE_NDIFIELDOPTIONSTRANSLATION = 'REPLACE_NDIFIELDOPTIONSTRANSLATION';
const apiUrl = API_DOMAIN + '/ndifieldoptionstranslation'; 

axiosDefaults(null);

export const getNdiFieldOptionsTranslationAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_NDIFIELDOPTIONSTRANSLATION, NdiFieldOptionsTranslation: response.data.ndifieldoptionstranslation.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiFieldOptionsTranslation = (NdiFieldOptionsTranslation) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiFieldOptionsTranslation.id,
		field_option_id: NdiFieldOptionsTranslation.field_option_id,
		lang: NdiFieldOptionsTranslation.lang,
		text_label: NdiFieldOptionsTranslation.text_label,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDIFIELDOPTIONSTRANSLATION, payload: {
			id: data.id,
			field_option_id: data.field_option_id,
			lang: data.lang,
			text_label: data.text_label,
		}})
      })
      .then(() => {
        history.push("/NdiFieldOptionsTranslation")
      })
      .catch(error => { throw(error) });
  };
};
export const getNdiFieldOptionsTranslation = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.ndifieldoptionstranslation);		  
        return dispatch({type: RECEIVE_NDIFIELDOPTIONSTRANSLATIONX, NdiFieldOptionsTranslationOne: response.data.ndifieldoptionstranslation});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiFieldOptionsTranslationGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiFieldOptionsTranslation/${id}/edit`)
  };
};
export const deleteNdiFieldOptionsTranslation = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDIFIELDOPTIONSTRANSLATION, payload: {id}})
      })
      .then(() => {
        history.push("/NdiFieldOptionsTranslation")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiFieldOptionsTranslation = (NdiFieldOptionsTranslation) => {
  const id = NdiFieldOptionsTranslation.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiFieldOptionsTranslation.id}`, {
		field_option_id: NdiFieldOptionsTranslation.field_option_id,
		lang: NdiFieldOptionsTranslation.lang,
		text_label: NdiFieldOptionsTranslation.text_label,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDIFIELDOPTIONSTRANSLATION, payload: {
			id: data.id,
			field_option_id: data.field_option_id,
			lang: data.lang,
			text_label: data.text_label,
		}})
        dispatch({type: REPLACE_NDIFIELDOPTIONSTRANSLATION, payload: {
			id: data.id,
			field_option_id: data.field_option_id,
			lang: data.lang,
			text_label: data.text_label,
		}})
      })
      .then(() => {
        history.push(`/NdiFieldOptionsTranslation/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiFieldOptionsTranslation = (NdiFieldOptionsTranslation, actionMode) => {
	if(actionMode===1){
        return addNdiFieldOptionsTranslation(NdiFieldOptionsTranslation);
      }else {
        return updateNdiFieldOptionsTranslation(NdiFieldOptionsTranslation);
      }
};