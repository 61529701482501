import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDIPRODUCTTYPES = 'GET_NDIPRODUCTTYPES';
export const ADD_NDIPRODUCTTYPES = 'ADD_NDIPRODUCTTYPES';
export const RECEIVE_NDIPRODUCTTYPESX = 'RECEIVE_NDIPRODUCTTYPESX';
export const REMOVE_NDIPRODUCTTYPES = 'REMOVE_NDIPRODUCTTYPES';
export const UPDATE_NDIPRODUCTTYPES = 'UPDATE_NDIPRODUCTTYPES';
export const REPLACE_NDIPRODUCTTYPES = 'REPLACE_NDIPRODUCTTYPES';
const apiUrl = API_DOMAIN + '/ndiproductstypes'; 

axiosDefaults(null);

export const getNdiProductTypesAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
        dispatch({type: RECEIVE_NDIPRODUCTTYPES, NdiProductTypes: response.data.data.ndiproducttypes})
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiProductTypes = (NdiProductTypes) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, NdiProductTypes)
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDIPRODUCTTYPES, payload: {
			id: data.id,
			name: data.name,
			main_category: data.main_category,
			parent: data.parent,
		}})
      })
      .then(() => {
        history.push("/NdiProductTypes")
      })
      .catch(error => { throw(error) });
  };
};

export const addOrUpdateProductTypesAndChildren = (NdiProductTypes) => { 
	 
	return (dispatch) => {
	  return axios.post(`${apiUrl}`, NdiProductTypes)
		.then(response => {
		  let data = response.data.data; //response.data.data;
		    
		  console.log('0000000children RESPONSE PAAAAAAAAAAAAAYMENT  ', response);  
		  console.log(data);   
		  //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
		  //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
		  //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });
		  return dispatch({type: ADD_NDIPRODUCTTYPES, NdiProductTypes: data});
		})
		/*.then(response => {
		  //history.push("/NeoVentes")
		  return response;
		})*/
		//.catch(error => { throw(error) });
		.catch(err => {
		  if (err.response) {
			// client received an error response (5xx, 4xx)
			console.log("error falls here #1"); console.log(err.response);
			console.log(err);
			throw(err.response);
		  } else if (err.request) {
			// client never received a response, or request never left
			console.log("error falls here #2");
			const errMessage="client never received a response, or request never left";
			throw(errMessage);
		  } else {
			// anything else
			console.log("error falls here #3"); console.log(err);   
			throw(err); 
		  }
	  });
	};
  };

export const addNdiProductTypes000 = (NdiProductTypes) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiProductTypes.id,
		name: NdiProductTypes.name,
		main_category: NdiProductTypes.main_category,
		parent: NdiProductTypes.parent,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDIPRODUCTTYPES, payload: {
			id: data.id,
			name: data.name,
			main_category: data.main_category,
			parent: data.parent,
		}})
      })
      .then(() => {
        history.push("/NdiProductTypes")
      })
      .catch(error => { throw(error) });
  };
};

export const getNdiProductTypes = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}?id=${id}`)
      .then(response => {
		  console.log("response from server .... ", response);  console.log(response.data.data);		  
        return dispatch({type: RECEIVE_NDIPRODUCTTYPESX, NdiProductTypesOne: response.data.data});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiProductTypesGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiProductTypes/${id}/edit`)
  };
};
export const deleteNdiProductTypes = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDIPRODUCTTYPES, payload: {id}})
      })
      .then(() => {
        history.push("/NdiProductTypes")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiProductTypes = (NdiProductTypes) => {
  const id = NdiProductTypes.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiProductTypes.id}`, {
		name: NdiProductTypes.name,
		main_category: NdiProductTypes.main_category,
		parent: NdiProductTypes.parent,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDIPRODUCTTYPES, payload: {
			id: data.id,
			name: data.name,
			main_category: data.main_category,
			parent: data.parent,
		}})
        dispatch({type: REPLACE_NDIPRODUCTTYPES, payload: {
			id: data.id,
			name: data.name,
			main_category: data.main_category,
			parent: data.parent,
		}})
      })
      .then(() => {
        history.push(`/NdiProductTypes/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiProductTypes = (NdiProductTypes, actionMode) => {
	if(actionMode===1){
        return addNdiProductTypes(NdiProductTypes);
      }else {
        return updateNdiProductTypes(NdiProductTypes);
      }
};