import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoPaymentsRefund, getNeoPaymentsRefund, getNeoPaymentsRefundAll, getNeoPaymentsRefundGoto, deleteNeoPaymentsRefund } from '../../actions/NeoPaymentsRefundAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import Toastr from "../../messages";
import  Loader from "../../loader"; 

class NeoPaymentsRefundList extends Component {
	constructor(props) {
		super(props); 
		this.state={
			isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		}; 
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() { 
		//this.props.getNeoPaymentsRefundAll();
		const { t } = this.props;
		//this.props.getNeoPaymentsRefundAll();
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.getNeoPaymentsRefundAll())
        .then((response)=>{ 
		  this.setState({ ["isLoaderOpen"]: false});
		  this.showToast(t("zqwCViewEl11_x"), "success");  
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          window.alert(error);
        }); 
	}
	
	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, toastrDuration);
	}
	

	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoPaymentsRefund;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoPaymentsRefund(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.NeoPaymentsRefund;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      //gridIndexes.push(row.index); 
      //idIndex=row.index; 
	  gridIndexes.push(row.dataIndex); //global  
	  idIndex=row.dataIndex; 
	return null;
    });
 
    if(gridIndexes.length>1){
      window.alert(t('zqwgridIndexesfound_x'));
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getNeoPaymentsRefundGoto(obj.id); 
	//history.push(`/neopaymentsrefund/${obj.id}/edit`); 
  }
  

  render() { 
    const { t } = this.props;
	const columns = [
	 {
	  name: "id", label: t('zqwid_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "id_payments", label: t('zqwid_payments_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "amount", label: t('zqwamount_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "tax1", label: t('zqwtax1_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "tax2", label: t('zqwtax2_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "tax_total", label: t('zqwtax_total_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "total", label: t('zqwtotal_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "user_creation", label: t('zqwuser_creation_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "user_update", label: t('zqwuser_update_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "creation_date", label: t('zqwcreation_date_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "update_date", label: t('zqwupdate_date_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "status", label: t('zqwstatus_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "refund_type", label: t('zqwrefund_type_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "observation", label: t('zqwobservation_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "result", label: t('zqwresult_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "taxable", label: t('zqwtaxable_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "nontaxable", label: t('zqwnontaxable_x'),
	  options: { filter: true, sort: true,}
	 },
	];
const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),
	};  
	
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	  
	  
    if(this.props?.NeoPaymentsRefund?.length) {                                
      return (
        <div>
          <h4>{t('zqwNeoPaymentsRefund_x')}</h4>		  
		  <MUIDataTable
			  title={""}
			  data={this.props.NeoPaymentsRefund}
			  columns={columns}
			  options={options}
			/>
			<Loader  openx={this.state.isLoaderOpen}  />
			<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
        </div>
      )    
    } else {
      return (<div>{t('zqwgridNoRecordfound_x')} <Loader  openx={this.state.isLoaderOpen}  /></div>)
    }
  }
}


const mapStateToProps = (state) => ({ NeoPaymentsRefund: state.NeoPaymentsRefund });  
const mapDispatchToProps = { addOrUpdateNeoPaymentsRefund, getNeoPaymentsRefund, getNeoPaymentsRefundAll, getNeoPaymentsRefundGoto, deleteNeoPaymentsRefund };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoPaymentsRefundList));
