import { RECEIVE_NDIPRODUCTTYPES, ADD_NDIPRODUCTTYPES, REMOVE_NDIPRODUCTTYPES, REPLACE_NDIPRODUCTTYPES } from '../actions/NdiProductTypesAction';

const initialState = { NdiProductTypes: [] }
export default function NdiProductTypesReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDIPRODUCTTYPES:
      return action.NdiProductTypes;
    case ADD_NDIPRODUCTTYPES:
      return [action.payload, ...state];
    case REMOVE_NDIPRODUCTTYPES:
      return state.filter(NdiProductTypes => NdiProductTypes.id !== action.payload.id);
    case REPLACE_NDIPRODUCTTYPES:
      return state.map((NdiProductTypes) => {
        if (NdiProductTypes.id === action.payload.id) {
          return {
            ...NdiProductTypes,
name: action.payload.name,
main_category: action.payload.main_category,
parent: action.payload.parent,
          }
        } else return NdiProductTypes;
      })
    default:
      return state;
  }
}
