import { RECEIVE_ZXCOMPANIES, UPDATE_ZXCOMPANIES } from '../actions/ZxCompaniesAction';

export default function singleZxCompaniesReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_ZXCOMPANIES:
      return action.ZxCompanies;
    case UPDATE_ZXCOMPANIES:
      return {
        id: action.id,
name: action.payload.name,
joining_date: action.payload.joining_date,
address: action.payload.address,
phone: action.payload.phone,
dob: action.payload.dob,
zipcode: action.payload.zipcode,
suite: action.payload.suite,
city: action.payload.city,
state: action.payload.state,
country: action.payload.country,
company_code: action.payload.company_code,
      }
    default:
      return state;
  }
};