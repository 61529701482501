import React from 'react';
//import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';

import {
	ElementsConsumer, CardElement,
	 	useMemo,

	//useStripe,
	//useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement
  } from "@stripe/react-stripe-js";

import CardSection from './CardSection';

import useResponsiveFontSize from "./useResponsiveFontSize";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};




class CheckoutForm extends React.Component {
	constructor(props) {
    super(props);
    this.state ={
      buttonEnabled:true, 
    };

      //this.stateInit = { buttonEnabled:true,  id:0, };

      //this.state = JSON.parse(JSON.stringify(this.stateInit)); // Object.assign(this.stateInit);
    }
   prevent = false;
   //buttonRef=React.createRef();

	handleSubmit000 = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const clientsecret=elements.getElement(CardCvcElement);

    const result = await stripe.confirmCardPayment(clientsecret, { //const result = await stripe.confirmCardPayment('{CLIENT_SECRET}', {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Jenny Rosen',
        },
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.

        console.log("SUCCESS "+result.paymentIntent.status+" stripeToken=");
        console.log(result);
        console.log("stripeToken "+result.paymentIntent.stripeToken+" ");
        console.log("//SUCCESS stripeToken=");


      }
    }
  };

  handleClick = (event) => {
    event.persist();
    event.preventDefault();  
    //const isEnabled=this.state.buttonEnabled; 
    this.setState({ ["buttonEnabled"]: false,}); 
     
    //console.log("ON SIMPLE  CLICK DETECTED a. "+this.prevent);
    setTimeout(() => {
      //console.log("ON SIMPLE  CLICK DETECTED b. "+this.prevent);
      if(this.prevent=== true){ 
        event.stopPropagation();
        this.prevent=false;
        //this.handleSubmit(event);   
        return false;
      }else{
        this.setState({ ["buttonEnabled"]: false,}); 
        this.handleSubmit(event);  
      }
    }, 1500); //Windows default double-click time is 500 ms

    /*if(this.prevent=== true){ 
      event.stopPropagation();
      //this.prevent=false;
      //this.handleSubmit(event); 
      return false;
    }else{
      this.handleSubmit(event); 
    }
    //this.handleSubmit(event); */
  };

  handleDblClick = (event) => {
    event.persist();
    event.preventDefault();
    this.prevent = true;
    event.stopPropagation();
    //console.log("EEEEEEEEROR DOUBLE CLICK DETECTED"); 
    return false;
  };

  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const {stripe, elements} = this.props;


    // console.log("//FAAAAAAAAAAKE SUCCESS stripeToken=");
    // const resultxx={ token: {id: "RAAAAAAAAAAA77777777777", text: "go for it RAAAAAAAAAAA77777777777"} }; 
    // window.alert("FAAAAAAAAAAKE SUCCESS stripeToken= "+resultxx.token.id+" ");
    // console.log("//FAAAAAAAAAAKE propooooooops stripeToken=");
    // console.log(this.props);
    // this.props.stripestatus(resultxx);
    // return false;




    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      this.setState({ ["buttonEnabled"]: true,}); 
      console.log(result.error.message);
      window.alert("Erreur  "+result.error.message);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      //stripeTokenHandler(result.token);
      
      // console.log("OLD STYLE SUCCESS ");
      // console.log(result.token);
      // console.log("stripeToken= "+result.token.id+" ");
      // console.log("//SUCCESS stripeToken=");
      // window.alert("SUCCESS stripeToken= "+result.token.id+" ");
      this.setState({ ["buttonEnabled"]: true,}); 
      this.props.stripestatus(result);
       
    }
  };

  // stripeTokenHandler(token) {
  //   const paymentData = {token: token.id};
  
  //   // Use fetch to send the token ID and any other payment data to your server.
  //   // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  //   const response = await fetch('/charge', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(paymentData),
  //   });
  
  //   // Return and display the result of the charge.
  //   return response.json();
  // }

  render() {
  const options={ };
  const isEnabled=this.state.buttonEnabled;
    return (
      <form onSubmit={this.handleSubmit}>
       <CardSection />







		{/* <label>
        Card number
        <CardNumberElement
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      </label>
      <label>
        Expiration date
        <CardExpiryElement
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      </label>
      <label>
        CVC
        <CardCvcElement
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      </label> */}

 

















        {/* <button disabled={!this.props.stripe}>Confirm order</button> */}
        {/* <div className="form-group">  
          <button disabled={(!this.props.stripe || !isEnabled)?true:false} className="btn btn-primary"
           onDoubleClick={this.handleDblClick} onClick={this.handleClick}>Confirm order</button>
        </div> */}

        <div className="form-group">  
          <button disabled={(!this.props.stripe || !isEnabled)?true:false} className="btn btn-primary"
           onDoubleClick={this.handleDblClick} onClick={this.handleClick}>Confirm order</button>
        </div>
      </form>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm  stripe={stripe} elements={elements} stripestatus={props.stripestatus}  />
      )}
    </ElementsConsumer>
  );
}