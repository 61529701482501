import { RECEIVE_NDITAGS, ADD_NDITAGS, REMOVE_NDITAGS, REPLACE_NDITAGS } from '../actions/NdiTagsAction';

const initialState = { NdiTags: [] }
export default function NdiTagsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDITAGS:
      return action.NdiTags;
    case ADD_NDITAGS:
      return [action.payload, ...state];
    case REMOVE_NDITAGS:
      return state.filter(NdiTags => NdiTags.id !== action.payload.id);
    case REPLACE_NDITAGS:
      return state.map((NdiTags) => {
        if (NdiTags.id === action.payload.id) {
          return {
            ...NdiTags,
name: action.payload.name,
          }
        } else return NdiTags;
      })
    default:
      return state;
  }
}
