import React from 'react';
//import ChangeLanguage '../changeLanguage';

import ChangeLanguage from './changeLanguage'; 

//class BaseLeftPanel extends React.Component {
export class BaseHeader extends React.Component {
  constructor(props) {
    super(props); 
	//this.state = {isLeftMenuOpen: true};
	this.state = {isBellOpen: false, isCommentOpen: false};
	
    /*this.initialState = {
      id: '',
      product_name: '',
      price: '',
      sku: ''
    }

    if(props.product){
      this.state = props.product
    } else {
      this.state = this.initialState;
    }*/

    //this.handleLeftPanelMenu = this.handleLeftPanelMenu.bind(this);
	this.handleBell = this.handleBell.bind(this);
	this.handleComment = this.handleComment.bind(this);
    ////this.handleSubmit = this.handleSubmit.bind(this);
	////this.handleCancel = this.handleCancel.bind(this);
	this.handleClickOut = this.handleClickOut.bind(this);
  }
  
  /*handleLeftPanelMenu(event) {
	event.preventDefault();
	//this.setState(this.initialState);
	let valMenuToggle=true;
	if(this.state.isLeftMenuOpen){
      valMenuToggle=false;
    } else {
      valMenuToggle=true;
    }
	
	this.setState({
      isLeftMenuOpen: valMenuToggle
    });
	
	console.log("HEADER TEEEEEEEEEEEEEEEEEEEEST TOGGLE ");
	console.log(" "+this.state.isLeftMenuOpen);
	
  }*/
  
  handleBell(event) {
	event.preventDefault();
	event.stopPropagation();	
	let valToggle=true;
	if(this.state.isBellOpen){
      valToggle=false;
    } else {
      valToggle=true;
    }
	
	this.setState({
      isBellOpen: valToggle
    });
	
	console.log("handleBell TEEEEEEEEEEEEEEEEEEEEST TOGGLE ");
	console.log(" "+this.state.isBellOpen);
	
  }
  
  handleComment(event) {
	event.preventDefault(); 
	event.stopPropagation();
	let valToggle=true;
	if(this.state.isCommentOpen){
      valToggle=false;
    } else {
      valToggle=true;
    }
	
	this.setState({
      isCommentOpen: valToggle
    });
	
	console.log("handleComment TEEEEEEEEEEEEEEEEEEEEST TOGGLE ");
	console.log(" "+this.state.isCommentOpen);
	 
  }
  
  handleClickOut(event) {
	event.preventDefault();  	
	this.setState({
      isBellOpen: false, isCommentOpen: false
    }); 
	
	console.log("handleClickOut TEEEEEEEEEEEEEEEEEEEEST TOGGLE "); 
	
  }

render() {
	//<div className="hold-transition sidebar-mini">
//function App() {
  return ( 
	<nav className="main-header navbar navbar-expand navbar-white navbar-light" onClick={this.handleClickOut}>
		<ul className="navbar-nav">
		  <li className="nav-item">
			<a className="nav-link" data-widget="pushmenu" href="#" onClick={this.props.handleLeftPanelMenuRef}><i className="fas fa-bars"></i></a>
		  </li>
		  <li className="nav-item d-none d-sm-inline-block">
			<a href="index3.html" className="nav-link">Home</a>
		  </li>
		  <li className="nav-item d-none d-sm-inline-block">
			<a href="#" className="nav-link">Contact</a>
		  </li>
		</ul>
 
		<form className="form-inline ml-3">
		  <div className="input-group input-group-sm">
			<input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
			<div className="input-group-append">
			  <button className="btn btn-navbar" type="submit">
				<i className="fas fa-search"></i>
			  </button>
			</div>
		  </div>
		</form>
 
		<ul className="navbar-nav ml-auto"> 
		  <li className={this.state.isCommentOpen===true ? "nav-item dropdown show" : "nav-item dropdown"}>
			<a className="nav-link" data-toggle="dropdown" href="#"  onClick={this.handleComment} aria-expanded={this.state.isCommentOpen===true ? "true" : "false"}>
			  <i className="far fa-comments"></i>
			  <span className="badge badge-danger navbar-badge">3</span>
			</a>
			<div className={this.state.isCommentOpen===true ? "dropdown-menu dropdown-menu-lg dropdown-menu-right show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right"}>
			  <a href="#" className="dropdown-item">
				 
				<div className="media">
				  <img src={ require("./adminltelibs/dist/img/user1-128x128.jpg") } alt="User Avatar" className="img-size-50 mr-3 img-circle" />
				  <div className="media-body">
					<h3 className="dropdown-item-title">
					  Brad Diesel
					  <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
					</h3>
					<p className="text-sm">Call me whenever you can...</p>
					<p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
				  </div>
				</div>
				 
			  </a>
			  <div className="dropdown-divider"></div>
			  <a href="#" className="dropdown-item"> 
				<div className="media">
				  <img src={ require("./adminltelibs/dist/img/user8-128x128.jpg") } alt="User Avatar" className="img-size-50 img-circle mr-3" />
				  <div className="media-body">
					<h3 className="dropdown-item-title"> 
					  John Pierce
					  <span className="float-right text-sm text-muted"><i className="fas fa-star"></i></span>
					</h3>
					<p className="text-sm">I got your message bro</p>
					<p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
				  </div>
				</div>
				 
			  </a>
			  <div className="dropdown-divider"></div>
			  <a href="#" className="dropdown-item">
				 
				<div className="media">
				  <img src={ require("./adminltelibs/dist/img/user3-128x128.jpg") } alt="User Avatar" className="img-size-50 img-circle mr-3" />
				  <div className="media-body">
					<h3 className="dropdown-item-title">
					  Nora Silvester
					  <span className="float-right text-sm text-warning"><i className="fas fa-star"></i></span>
					</h3>
					<p className="text-sm">The subject goes here</p>
					<p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
				  </div>
				</div>
				 
			  </a>
			  <div className="dropdown-divider"></div>
			  <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
			</div>
		  </li>
		   
		  <li className={this.state.isBellOpen===true ? "nav-item dropdown show" : "nav-item dropdown"}>
			<a className="nav-link" data-toggle="dropdown" href="#"  onClick={this.handleBell} aria-expanded={this.state.isBellOpen===true ? "true" : "false"}>
			  <i className="far fa-bell"></i>
			  <span className="badge badge-warning navbar-badge">15</span>
			</a>
			<div className={this.state.isBellOpen===true ? "dropdown-menu dropdown-menu-lg dropdown-menu-right show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right"}>
			  <span className="dropdown-header">15 Notifications</span>
			  <div className="dropdown-divider"></div>
			  <a href="#" className="dropdown-item">
				<i className="fas fa-envelope mr-2"></i> 4 new messages
				<span className="float-right text-muted text-sm">3 mins</span>
			  </a>
			  <div className="dropdown-divider"></div>
			  <a href="#" className="dropdown-item">
				<i className="fas fa-users mr-2"></i> 8 friend requests
				<span className="float-right text-muted text-sm">12 hours</span>
			  </a>
			  <div className="dropdown-divider"></div>
			  <a href="#" className="dropdown-item">
				<i className="fas fa-file mr-2"></i> 3 new reports
				<span className="float-right text-muted text-sm">2 days</span>
			  </a>
			  <div className="dropdown-divider"></div>
			  <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
			</div>
		  </li>
		  <li className="nav-item">
			<ChangeLanguage />
		  </li>
		  <li className="nav-item">
			<a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#"><i
				className="fas fa-th-large"></i></a>
		  </li>
		   
		</ul>
	  </nav>   
  );
}
}

//export default BaseHeader;
//export BaseHeader;
