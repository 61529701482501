import { RECEIVE_NDIFIELDOPTIONS, UPDATE_NDIFIELDOPTIONS } from '../actions/NdiFieldOptionsAction';

export default function singleNdiFieldOptionsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDIFIELDOPTIONS:
      return action.NdiFieldOptions;
    case UPDATE_NDIFIELDOPTIONS:
      return {
        id: action.id,
field_id: action.payload.field_id,
text_label: action.payload.text_label,
option_order: action.payload.option_order,
enabled: action.payload.enabled,
      }
    default:
      return state;
  }
};