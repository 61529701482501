import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDITAGS = 'GET_NDITAGS';
export const ADD_NDITAGS = 'ADD_NDITAGS';
export const RECEIVE_NDITAGSX = 'RECEIVE_NDITAGSX';
export const REMOVE_NDITAGS = 'REMOVE_NDITAGS';
export const UPDATE_NDITAGS = 'UPDATE_NDITAGS';
export const REPLACE_NDITAGS = 'REPLACE_NDITAGS';
const apiUrl = API_DOMAIN + '/nditags'; 

axiosDefaults(null);

export const getNdiTagsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.data);	//response.data.nditags	 
        return dispatch({type: RECEIVE_NDITAGS, NdiTags: response.data.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiTags = (NdiTags) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiTags.id,
		name: NdiTags.name,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDITAGS, payload: {
			id: data.id,
			name: data.name,
		}})
      })
      .then(() => {
        history.push("/NdiTags")
      })
      .catch(error => { throw(error) });
  };
};
export const getNdiTags = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}?${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.nditags);		  
        return dispatch({type: RECEIVE_NDITAGSX, NdiTagsOne: response.data.nditags});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiTagsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiTags/${id}/edit`)
  };
};
export const deleteNdiTags = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDITAGS, payload: {id}})
      })
      .then(() => {
        history.push("/NdiTags")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiTags = (NdiTags) => {
  const id = NdiTags.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiTags.id}`, {
		name: NdiTags.name,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDITAGS, payload: {
			id: data.id,
			name: data.name,
		}})
        dispatch({type: REPLACE_NDITAGS, payload: {
			id: data.id,
			name: data.name,
		}})
      })
      .then(() => {
        history.push(`/NdiTags/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiTags = (NdiTags, actionMode) => {
	if(actionMode===1){
        return addNdiTags(NdiTags);
      }else {
        return updateNdiTags(NdiTags);
      }
};