import { RECEIVE_NEOSEARCH, ADD_NEOSEARCH, REMOVE_NEOSEARCH, REPLACE_NEOSEARCH, UPDATE_NEOSEARCH } from '../actions/NeoSearchAction';
 

const initialState = { NeoSearch: [] }
export default function NeoSearchReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NEOSEARCH:
      return action.NeoSearch;
    case ADD_NEOSEARCH:
      //return [action.payload, ...state];
      return action.NeoSearch;
    case REMOVE_NEOSEARCH:
      return state.filter(NeoSearch => NeoSearch.id !== action.payload.id);
    case REPLACE_NEOSEARCH:
      return state.map((NeoSearch) => {
        if (NeoSearch.id === action.payload.id) {
          return {
            ...NeoSearch,
            search: action.payload.search,
            filter: action.payload.filter,
            result: action.payload.result,
            actions: action.payload.actions,
            ticket_id: action.payload.ticket_id,
            client_id: action.payload.client_id,
            search_type: action.payload.search_type,
            date_created: action.payload.date_created,
            user_creation: action.payload.user_creation,
            user_update: action.payload.user_update,
            update_date: action.payload.update_date,
            status: action.payload.status,
          }
        } else return NeoSearch;
      })
	case UPDATE_NEOSEARCH:
      return {
        id: action.id,
        search: action.payload.search,
        filter: action.payload.filter,
        result: action.payload.result,
        actions: action.payload.actions,
        ticket_id: action.payload.ticket_id,
        client_id: action.payload.client_id,
        search_type: action.payload.search_type,
        date_created: action.payload.date_created,
        user_creation: action.payload.user_creation,
        user_update: action.payload.user_update,
        update_date: action.payload.update_date,
        status: action.payload.status,
      }  
    default:
      return state;
  }
}
