import { RECEIVE_NEOVENTES, UPDATE_NEOVENTES, RECEIVE_NEOCLIENTSX, RECEIVE_NEOADDRESSX, RECEIVE_NEOCODEPROMOX } from '../actions/NeoVentesAction';

export default function singleNeoVentesReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NEOVENTES:
      return action.NeoVentes;
    case RECEIVE_NEOCLIENTSX:
        return action.NeoClientsOne;  
    case RECEIVE_NEOADDRESSX:
        return action.NeoAddressOne; 
    case RECEIVE_NEOCODEPROMOX:
        return action.NeoCodePromoOne;         
    case UPDATE_NEOVENTES:
      return {
        id: action.id,
        name: action.payload.name,
        description: action.payload.description,
        amount: action.payload.amount,
      }
    default:
      return state;
  }
};  