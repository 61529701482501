import { RECEIVE_NDIFIELDS, ADD_NDIFIELDS, REMOVE_NDIFIELDS, REPLACE_NDIFIELDS } from '../actions/NdiFieldsAction';

const initialState = { NdiFields: [] }
export default function NdiFieldsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDIFIELDS:
      return action.NdiFields;
    case ADD_NDIFIELDS:
      return action.NdiFields; //[action.payload, ...state];
    case REMOVE_NDIFIELDS:
      return state.filter(NdiFields => NdiFields.id !== action.payload.id);
    case REPLACE_NDIFIELDS:
      return state.map((NdiFields) => {
        if (NdiFields.id === action.payload.id) {
          return {
            ...NdiFields,
name: action.payload.name,
text_label: action.payload.text_label,
placeholder: action.payload.placeholder,
field: action.payload.field,
field_type: action.payload.field_type,
field_default_value: action.payload.field_default_value,
mask: action.payload.mask,
field_service: action.payload.field_service,
field_dataset: action.payload.field_dataset,
tag_id: action.payload.tag_id,
          }
        } else return NdiFields;
      })
    default:
      return state;
  }
}
