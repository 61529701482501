import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDIFIELDSTRANSLATION = 'GET_NDIFIELDSTRANSLATION';
export const ADD_NDIFIELDSTRANSLATION = 'ADD_NDIFIELDSTRANSLATION';
export const RECEIVE_NDIFIELDSTRANSLATIONX = 'RECEIVE_NDIFIELDSTRANSLATIONX';
export const REMOVE_NDIFIELDSTRANSLATION = 'REMOVE_NDIFIELDSTRANSLATION';
export const UPDATE_NDIFIELDSTRANSLATION = 'UPDATE_NDIFIELDSTRANSLATION';
export const REPLACE_NDIFIELDSTRANSLATION = 'REPLACE_NDIFIELDSTRANSLATION';
const apiUrl = API_DOMAIN + '/ndifieldstranslation'; 

axiosDefaults(null);

export const getNdiFieldsTranslationAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_NDIFIELDSTRANSLATION, NdiFieldsTranslation: response.data.ndifieldstranslation.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiFieldsTranslation = (NdiFieldsTranslation) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiFieldsTranslation.id,
		field_id: NdiFieldsTranslation.field_id,
		lang: NdiFieldsTranslation.lang,
		text_label: NdiFieldsTranslation.text_label,
		placeholder: NdiFieldsTranslation.placeholder,
		field_default_value: NdiFieldsTranslation.field_default_value,
		field_service: NdiFieldsTranslation.field_service,
		field_dataset: NdiFieldsTranslation.field_dataset,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDIFIELDSTRANSLATION, payload: {
			id: data.id,
			field_id: data.field_id,
			lang: data.lang,
			text_label: data.text_label,
			placeholder: data.placeholder,
			field_default_value: data.field_default_value,
			field_service: data.field_service,
			field_dataset: data.field_dataset,
		}})
      })
      .then(() => {
        history.push("/NdiFieldsTranslation")
      })
      .catch(error => { throw(error) });
  };
};
export const getNdiFieldsTranslation = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.ndifieldstranslation);		  
        return dispatch({type: RECEIVE_NDIFIELDSTRANSLATIONX, NdiFieldsTranslationOne: response.data.ndifieldstranslation});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiFieldsTranslationGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiFieldsTranslation/${id}/edit`)
  };
};
export const deleteNdiFieldsTranslation = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDIFIELDSTRANSLATION, payload: {id}})
      })
      .then(() => {
        history.push("/NdiFieldsTranslation")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiFieldsTranslation = (NdiFieldsTranslation) => {
  const id = NdiFieldsTranslation.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiFieldsTranslation.id}`, {
		field_id: NdiFieldsTranslation.field_id,
		lang: NdiFieldsTranslation.lang,
		text_label: NdiFieldsTranslation.text_label,
		placeholder: NdiFieldsTranslation.placeholder,
		field_default_value: NdiFieldsTranslation.field_default_value,
		field_service: NdiFieldsTranslation.field_service,
		field_dataset: NdiFieldsTranslation.field_dataset,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDIFIELDSTRANSLATION, payload: {
			id: data.id,
			field_id: data.field_id,
			lang: data.lang,
			text_label: data.text_label,
			placeholder: data.placeholder,
			field_default_value: data.field_default_value,
			field_service: data.field_service,
			field_dataset: data.field_dataset,
		}})
        dispatch({type: REPLACE_NDIFIELDSTRANSLATION, payload: {
			id: data.id,
			field_id: data.field_id,
			lang: data.lang,
			text_label: data.text_label,
			placeholder: data.placeholder,
			field_default_value: data.field_default_value,
			field_service: data.field_service,
			field_dataset: data.field_dataset,
		}})
      })
      .then(() => {
        history.push(`/NdiFieldsTranslation/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiFieldsTranslation = (NdiFieldsTranslation, actionMode) => {
	if(actionMode===1){
        return addNdiFieldsTranslation(NdiFieldsTranslation);
      }else {
        return updateNdiFieldsTranslation(NdiFieldsTranslation);
      }
};