import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getNeoPaymentsCreditsOper, deleteNeoPaymentsCreditsOper } from '../../actions/NeoPaymentsCreditsOperAction';

class NeoPaymentsCreditsOperInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		id_clients:'', e_id_clients: false,
		id_payments:'', e_id_payments: false,
		amount:'', e_amount: false,
		prev_cr_amount:'', e_prev_cr_amount: false,
		f_amount:'', e_f_amount: false,
		user_creation:'', e_user_creation: false,
		user_update:'', e_user_update: false,
		creation_date:'', e_creation_date: false,
		update_date:'', e_update_date: false,
		status:'', e_status: false,
		observation:'', e_observation: false,
		inv_id_payments:'', e_inv_id_payments: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getNeoPaymentsCreditsOper(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getNeoPaymentsCreditsOper(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.NeoPaymentsCreditsOperOne);  console.log("0000********FIN**********");
		this.setState(response.NeoPaymentsCreditsOperOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const NeoPaymentsCreditsOper = this.props.NeoPaymentsCreditsOper;
    const NeoPaymentsCreditsOper = this.state; 
    return (
      <div>
        <h2>NeoPaymentsCreditsOper</h2>
        <hr/>
        <p><label>{t('zqwid_x')}</label> &nbsp; &nbsp; #{NeoPaymentsCreditsOper.id}</p>
        <p><label>{t('zqwid_clients_x')}</label><br/>{NeoPaymentsCreditsOper.id_clients}</p>
        <p><label>{t('zqwid_payments_x')}</label><br/>{NeoPaymentsCreditsOper.id_payments}</p>
        <p><label>{t('zqwamount_x')}</label><br/>{NeoPaymentsCreditsOper.amount}</p>
        <p><label>{t('zqwprev_cr_amount_x')}</label><br/>{NeoPaymentsCreditsOper.prev_cr_amount}</p>
        <p><label>{t('zqwf_amount_x')}</label><br/>{NeoPaymentsCreditsOper.f_amount}</p>
        <p><label>{t('zqwuser_creation_x')}</label><br/>{NeoPaymentsCreditsOper.user_creation}</p>
        <p><label>{t('zqwuser_update_x')}</label><br/>{NeoPaymentsCreditsOper.user_update}</p>
        <p><label>{t('zqwcreation_date_x')}</label><br/>{NeoPaymentsCreditsOper.creation_date}</p>
        <p><label>{t('zqwupdate_date_x')}</label><br/>{NeoPaymentsCreditsOper.update_date}</p>
        <p><label>{t('zqwstatus_x')}</label><br/>{NeoPaymentsCreditsOper.status}</p>
        <p><label>{t('zqwobservation_x')}</label><br/>{NeoPaymentsCreditsOper.observation}</p>
        <p><label>{t('zqwinv_id_payments_x')}</label><br/>{NeoPaymentsCreditsOper.inv_id_payments}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/NeoPaymentsCreditsOper/${NeoPaymentsCreditsOper.id}/edit`, state: { NeoPaymentsCreditsOper: NeoPaymentsCreditsOper } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNeoPaymentsCreditsOper(NeoPaymentsCreditsOper.id)}>          
            {t('Delete')}
          </button>
          <Link to="/NeoPaymentsCreditsOper" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransNeoPaymentsCreditsOperInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ NeoPaymentsCreditsOper: state.NeoPaymentsCreditsOper });                 

const mapDispatchToProps = { getNeoPaymentsCreditsOper, deleteNeoPaymentsCreditsOper };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoPaymentsCreditsOperInfo));   

