import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateNeoPaymentsRefund, getNeoPaymentsRefund } from '../../actions/NeoPaymentsRefundAction'; 
import { Validations } from '../../utils/validations'; 

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['id_payments']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['amount']={check:false, chkType:'BigDecimal', message:'incorrect value'};
FIELD_VALIDATION['tax1']={check:false, chkType:'BigDecimal', message:'incorrect value'};
FIELD_VALIDATION['tax2']={check:false, chkType:'BigDecimal', message:'incorrect value'};
FIELD_VALIDATION['tax_total']={check:false, chkType:'BigDecimal', message:'incorrect value'};
FIELD_VALIDATION['total']={check:false, chkType:'BigDecimal', message:'incorrect value'};
FIELD_VALIDATION['user_creation']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['user_update']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['creation_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['update_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['status']={check:true, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION['refund_type']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['observation']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['result']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['taxable']={check:false, chkType:'BigDecimal', message:'incorrect value'};
FIELD_VALIDATION['nontaxable']={check:false, chkType:'BigDecimal', message:'incorrect value'};

class NeoPaymentsRefundForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		id_payments:'', e_id_payments: false,
		amount:'', e_amount: false,
		tax1:'', e_tax1: false,
		tax2:'', e_tax2: false,
		tax_total:'', e_tax_total: false,
		total:'', e_total: false,
		user_creation:'', e_user_creation: false,
		user_update:'', e_user_update: false,
		creation_date:'', e_creation_date: false,
		update_date:'', e_update_date: false,
		status:'', e_status: false,
		refund_type:'', e_refund_type: false,
		observation:'', e_observation: false,
		result:'', e_result: false,
		taxable:'', e_taxable: false,
		nontaxable:'', e_nontaxable: false,
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.NeoPaymentsRefund.id);
		if(this.props.NeoPaymentsRefund.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getNeoPaymentsRefund(idx);
			Promise.resolve(this.props.getNeoPaymentsRefund(idx))
			.then((response)=>{
				this.setState(response.NeoPaymentsRefundOne);
				return response;
			});
		}	
	}	
  }

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const id_payments = this.state.id_payments;
    const amount = this.state.amount;
    const tax1 = this.state.tax1;
    const tax2 = this.state.tax2;
    const tax_total = this.state.tax_total;
    const total = this.state.total;
    const user_creation = this.state.user_creation;
    const user_update = this.state.user_update;
    const creation_date = this.state.creation_date;
    const update_date = this.state.update_date;
    const status = this.state.status;
    const refund_type = this.state.refund_type;
    const observation = this.state.observation;
    const result = this.state.result;
    const taxable = this.state.taxable;
    const nontaxable = this.state.nontaxable;
    const NeoPaymentsRefund = {id: id, id_payments: id_payments, amount: amount, tax1: tax1, tax2: tax2, tax_total: tax_total, total: total, user_creation: user_creation, user_update: user_update, creation_date: creation_date, update_date: update_date, status: status, refund_type: refund_type, observation: observation, result: result, taxable: taxable, nontaxable: nontaxable, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoPaymentsRefund[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateNeoPaymentsRefund(NeoPaymentsRefund, 1); 
		//this.props.addNeoPaymentsRefund(NeoPaymentsRefund); 
	}else{ 
		this.props.addOrUpdateNeoPaymentsRefund(NeoPaymentsRefund, 2);
		//this.props.updateNeoPaymentsRefund(NeoPaymentsRefund);
		
	}
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/NeoPaymentsRefund`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/NeoPaymentsRefund/${idx}`);
		//this.props.history.push(`/NeoPaymentsRefund/${this.props.NeoPaymentsRefund.id}`);
	}
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h3>{this.actionMode==='new'? t('zqwacAddNeoPaymentsRefund_x'): t('zqwacEditNeoPaymentsRefund_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		  
		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="id_payments">{t('zqwid_payments_x')}</label>
            <input type="text" name="id_payments" id="id_payments" defaultValue={this.state.id_payments} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id_payments ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_payments_x')} /> 
			{this.state.e_id_payments && (<span className="text-danger">{FIELD_VALIDATION['id_payments'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="amount">{t('zqwamount_x')}</label>
            <input type="text" name="amount" id="amount" defaultValue={this.state.amount} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_amount ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwamount_x')} /> 
			{this.state.e_amount && (<span className="text-danger">{FIELD_VALIDATION['amount'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="tax1">{t('zqwtax1_x')}</label>
            <input type="text" name="tax1" id="tax1" defaultValue={this.state.tax1} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_tax1 ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtax1_x')} /> 
			{this.state.e_tax1 && (<span className="text-danger">{FIELD_VALIDATION['tax1'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="tax2">{t('zqwtax2_x')}</label>
            <input type="text" name="tax2" id="tax2" defaultValue={this.state.tax2} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_tax2 ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtax2_x')} /> 
			{this.state.e_tax2 && (<span className="text-danger">{FIELD_VALIDATION['tax2'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="tax_total">{t('zqwtax_total_x')}</label>
            <input type="text" name="tax_total" id="tax_total" defaultValue={this.state.tax_total} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_tax_total ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtax_total_x')} /> 
			{this.state.e_tax_total && (<span className="text-danger">{FIELD_VALIDATION['tax_total'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="total">{t('zqwtotal_x')}</label>
            <input type="text" name="total" id="total" defaultValue={this.state.total} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_total ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtotal_x')} /> 
			{this.state.e_total && (<span className="text-danger">{FIELD_VALIDATION['total'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="user_creation">{t('zqwuser_creation_x')}</label>
            <input type="text" name="user_creation" id="user_creation" defaultValue={this.state.user_creation} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_user_creation ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwuser_creation_x')} /> 
			{this.state.e_user_creation && (<span className="text-danger">{FIELD_VALIDATION['user_creation'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="user_update">{t('zqwuser_update_x')}</label>
            <input type="text" name="user_update" id="user_update" defaultValue={this.state.user_update} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_user_update ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwuser_update_x')} /> 
			{this.state.e_user_update && (<span className="text-danger">{FIELD_VALIDATION['user_update'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="creation_date">{t('zqwcreation_date_x')}</label>
            <input type="text" name="creation_date" id="creation_date" defaultValue={this.state.creation_date} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_creation_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcreation_date_x')} /> 
			{this.state.e_creation_date && (<span className="text-danger">{FIELD_VALIDATION['creation_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="update_date">{t('zqwupdate_date_x')}</label>
            <input type="text" name="update_date" id="update_date" defaultValue={this.state.update_date} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_update_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwupdate_date_x')} /> 
			{this.state.e_update_date && (<span className="text-danger">{FIELD_VALIDATION['update_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="status">{t('zqwstatus_x')}</label>
            <select name="status" id="status" defaultValue={this.state.status} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_status ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstatus_x')}> 
                        <option value="">Please Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
			{this.state.e_status && (<span className="text-danger">{FIELD_VALIDATION['status'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="refund_type">{t('zqwrefund_type_x')}</label>
            <select name="refund_type" id="refund_type" defaultValue={this.state.refund_type} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_refund_type ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwrefund_type_x')}> 
                        <option value="T">{t('zqwT_x')}</option>
                        <option value="P">{t('zqwP_x')}</option>
                    </select>
			{this.state.e_refund_type && (<span className="text-danger">{FIELD_VALIDATION['refund_type'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="observation">{t('zqwobservation_x')}</label>
            <input type="text" name="observation" id="observation" defaultValue={this.state.observation} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_observation ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwobservation_x')} /> 
			{this.state.e_observation && (<span className="text-danger">{FIELD_VALIDATION['observation'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="result">{t('zqwresult_x')}</label>
            <input type="text" name="result" id="result" defaultValue={this.state.result} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_result ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwresult_x')} /> 
			{this.state.e_result && (<span className="text-danger">{FIELD_VALIDATION['result'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="taxable">{t('zqwtaxable_x')}</label>
            <input type="text" name="taxable" id="taxable" defaultValue={this.state.taxable} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_taxable ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtaxable_x')} /> 
			{this.state.e_taxable && (<span className="text-danger">{FIELD_VALIDATION['taxable'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="nontaxable">{t('zqwnontaxable_x')}</label>
            <input type="text" name="nontaxable" id="nontaxable" defaultValue={this.state.nontaxable} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_nontaxable ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwnontaxable_x')} /> 
			{this.state.e_nontaxable && (<span className="text-danger">{FIELD_VALIDATION['nontaxable'].message}</span>)} 
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			<button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>
          </div>
        </form>
      </div>
    );
  }
}

//const TransNeoPaymentsRefundForm = withTranslation()(NeoPaymentsRefundForm); 
const mapStateToProps = (state) => ({ NeoPaymentsRefund: state.NeoPaymentsRefund });

const mapDispatchToProps = { addOrUpdateNeoPaymentsRefund, getNeoPaymentsRefund };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoPaymentsRefundForm));
