import { RECEIVE_VWFACTURAS, ADD_VWFACTURAS, REMOVE_VWFACTURAS, REPLACE_VWFACTURAS } from '../actions/VwFacturasAction';

const initialState = { VwFacturas: [] }
export default function VwFacturasReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_VWFACTURAS:
      return action.VwFacturas;
    case ADD_VWFACTURAS:
      return [action.payload, ...state];
    case REMOVE_VWFACTURAS:
      return state.filter(VwFacturas => VwFacturas.id !== action.payload.id);
    case REPLACE_VWFACTURAS:
      return state.map((VwFacturas) => {
        if (VwFacturas.id === action.payload.id) {
          return {
            ...VwFacturas,
Facturacion: action.payload.Facturacion,
Factura_Fiscal: action.payload.Factura_Fiscal,
Cliente: action.payload.Cliente,
Vendedor: action.payload.Vendedor,
Fecha: action.payload.Fecha,
Tasa_Imp: action.payload.Tasa_Imp,
Subtotal: action.payload.Subtotal,
Impuesto: action.payload.Impuesto,
Total: action.payload.Total,
Tipo: action.payload.Tipo,
Estado: action.payload.Estado,
Fecha_Sistema: action.payload.Fecha_Sistema,
head: action.payload.head,
id: action.payload.id,
          }
        } else return VwFacturas;
      })
    default:
      return state;
  }
}
