import { RECEIVE_NEOVENTES, ADD_NEOVENTES, REMOVE_NEOVENTES, REPLACE_NEOVENTES } from '../actions/NeoVentesAction';

const initialState = { NeoVentes: [] }
export default function NeoVentesReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NEOVENTES:
      return action.NeoVentes;
    case ADD_NEOVENTES:
      return action.NeoVentes; //return [action.payload, ...state];
    case REMOVE_NEOVENTES:
      return state.filter(NeoVentes => NeoVentes.id !== action.payload.id);
    case REPLACE_NEOVENTES:
      return state.map((NeoVentes) => {
        if (NeoVentes.id === action.payload.id) {
          return {
            ...NeoVentes,
name: action.payload.name,
description: action.payload.description,
amount: action.payload.amount,
          }
        } else return NeoVentes;
      })
    default:
      return state;
  }
}
