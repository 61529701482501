import React from 'react';
import {connect} from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
//import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import Toastr from "./messages";
import { userLogoutProfile } from './redux/actions';
import history from './history';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function UserLogout(props) {
//export default function ChangePassword(props) {	
  const [open, setOpen] = React.useState(false);
  
  const [passwordold, setPasswordold] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  
  const [toastrMessage, setToastrMessage] = React.useState('');
  const [toastrType, setPToastrType] = React.useState('success');
  
  const passwordoldRef = React.createRef();
  const passwordRef = React.createRef();
  const passwordRef2 = React.createRef();
  let anyErrorMessage= '';
  
   

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const { t, i18n } = useTranslation('common');
  
  const handleChange = event => {
    /*this.setState({
      [event.target.name]: event.target.value
    });*/
	
	//console.log('PAAAAAAAAAAAASSSSSWORD '+event.target.name+' : '+event.target.value);
	setHandleValue(event);
	 
  }
  
  const handleFocusout = event => {
    /*this.setState({
      [event.target.name]: event.target.value
    });*/
	
	//(event.target.name=="username"){
	//his.setState({ ["email"]: event.target.value });
	//
  }
  
  const setHandleValue = (event) => {
	//const [passwordold, setPasswordold] = React.useState('');
	//const [password, setPassword] = React.useState('');
	//const [password2, setPassword2] = React.useState('');
  
    switch(event.target.name) {
	  case "passwordold":
		setPasswordold(event.target.value);
		break;
	  case "password":
		setPassword(event.target.value);
		break;
	  case "password2":
		setPassword2(event.target.value);
		break;
	  default:
		// do nothing
	}
  };
  
  const handleSubmit = event => {
    event.preventDefault()
    //console.log("...RID.... ..check id "+rid);
	//window.alert("...RID.... ..check id ="+rid+"  length="+rid.length); 
	const tokenRid = localStorage.rid;
	//const username=this.state.username;
	 
	let randid=''; //this.state.rid;
	const format=/(^[\.\w]{5,25})$/;
	const format2=/(^[\w]{8,25})$/;
	 
	
	//const tokenRid = localStorage.rid;
	if(tokenRid===undefined || tokenRid===null){
		//localStorage.setItem("rid", randid);
		showToast("Veuillez vous authentifier pour changer votre mot de passe", "error");	 
		return false;
	} 
	
	//showToast("uuuuuuuuuuuuups, that set for now...", "error");		
	//userLogoutProfile = (passFieldsObj)  
	anyErrorMessage= '';
	//const user={ "passwordold":userpassold, "password":userpass2, "password2":userpass2  }
	const user={ "fakeparam":1 }
	//Promise.resolve(this.props.userLoginFetch(this.state))
	//this.props.userLoginFetch(user);  Promise.resolve(this.props.userLogoutProfile(user))

	showToast("Vous avez été déconnecté du système avec succès!", "success");
	localStorage.removeItem("user"); 
	localStorage.removeItem("token"); 
	localStorage.removeItem("rid"); 
	showToast("Vous avez été déconnecté du système avec succès!", "success");
	handleClose(); 
	history.push(`./Login`); 



	/*Promise.resolve(props.userLogoutProfile(user, showExternalToastError))
	.then((response)=>{
		//console.log("...RESPONSE IN PROM....///..check authentication "+response); 
		if(anyErrorMessage==''){
			showToast("Vous avez été déconnecté du système avec succès!", "success");
			setTimeout(() => {
				localStorage.removeItem("user"); 
				localStorage.removeItem("token"); 
				localStorage.removeItem("rid"); 
				handleClose();
				history.push(`./Login`); 
			}, 5500);
		}else{
			showToast(anyErrorMessage, "error");
			setTimeout(() => {
				localStorage.removeItem("user"); 
				localStorage.removeItem("token"); 
				localStorage.removeItem("rid"); 
				handleClose();
				history.push(`./Login`); 
			}, 5500);
		}	
		//return response;
	});*/
	
  };
  
  const showToast = (arg, toastType) => {
	//toastType: success or error or warning or info	  
	console.log("showToast showToast "); console.log(arg); 
	setToastrMessage(arg);  setPToastrType(toastType); 
  
	setTimeout(() => { 
		//this.setState({ toastrMessage: "",  toastrType: 'success' });
		setToastrMessage("");  setPToastrType('success'); 	
	}, 5500);
  };
  
  let showExternalToastError = (arg) => {
	//toastType: success or error or warning or info	  
	console.log("showToast ExternalToastError "); console.log(arg); 
	//showToast(arg, "error");
	anyErrorMessage= ''+arg;
	//setToastrMessage(arg);  setPToastrType(toastType);  
  };
  
  
  /*<Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button>*/

  return (
    <div> 
	  {/*<a href="#" className="btn btn-warning"  onClick={handleClickOpen}><b>Cambiar Contraseña</b></a>
	  <MenuItem onClick={logoutSystem}>Logout</MenuItem>*/}
	  <a href="#" className="link" onClick={handleClickOpen}><b>Sortir</b></a>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         <span className="fas fa-user"></span> On sort... 
        </DialogTitle>
        <DialogContent dividers>
			<form onSubmit={handleSubmit}>
			<Typography gutterBottom>
				{t('togoutcnfti_x')},
			  </Typography>
			<Typography gutterBottom>
				{t('logoutconfir_x')}? 
			  </Typography>	
						
			  <div className="form-group">
				<button type="submit" className="btn btn-danger"> &nbsp; &nbsp; {t('Yes_x')}  &nbsp; &nbsp; </button> &nbsp; &nbsp;  
				<button type="button" className="btn btn-success" onClick={handleClose}> &nbsp; &nbsp; {t('No_x')} &nbsp; &nbsp; </button>  
			  </div>
			</form>
		
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('Close_x')}
          </Button>
        </DialogActions>
      </Dialog> 
	  <Toastr messagex={toastrMessage} open={true} toastType={toastrType} /> 
    </div>
  );
}

const mapDispatch = {
    userLogoutProfile
};

export default connect(null, mapDispatch)(UserLogout);
