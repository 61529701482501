import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { addOrUpdateNdiFields, getNdiFields } from '../../actions/NdiFieldsAction'; 
import { Validations } from '../../utils/validations';
import { axiosDefaultsCred, axiosDefaults } from '../../appConfigaxios'; 
import Toastr from "../../messages";
import  Loader from "../../loader";
import MuiAlert from '@material-ui/lab/Alert';

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['name']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['text_label']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['placeholder']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_type']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_default_value']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['mask']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_service']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_dataset']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['tag_id']={check:false, chkType:'int', message:'incorrect value'};

class NdiNeoDocSendFileOptions extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		name:'', e_name: false,
		text_label:'', e_text_label: false,
		placeholder:'', e_placeholder: false,
		field:'', e_field: false,
		field_type:'', e_field_type: false,
		field_default_value:'', e_field_default_value: false,
		mask:'', e_mask: false,
		field_service:'', e_field_service: false,
		field_dataset:'', e_field_dataset: false,
		tag_id:'', e_tag_id: false,
		baseFileList:[], 
		messageServer:'', isLoaderOpen: false, 
		toastrMessage: '', toastrType: '', toastrDuration: 6000,
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };

  
  handleCheckBoxClick = (event) => {
    //this.setState({ [event.target.name]: event.target.value });
	const labelnodel=event.target.value;
	//console.log("handleCheckBoxClick ", labelnodel, event.target.checked);
    let checkboxList = this.state.baseFileList;
    checkboxList.forEach(chkItem=>{
		//console.log('OOOOOOOOOOOOedit  test  ', chkItem.labelnodel, labelnodel);
      if(""+chkItem.labelnodel === ""+labelnodel){
        chkItem.isChecked = event.target.checked;
		//console.log('OOOOOOOOOOOOedit  test passed  ', chkItem.labelnodel, labelnodel);
      }
    });
    this.setState({
      checkboxList: checkboxList
    });
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.NdiFields.id);
		if(this.props.NdiFields.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getNdiFields(idx);
			Promise.resolve(this.props.getNdiFields(idx))
			.then((response)=>{
				this.setState(response.NdiFieldsOne);
				return response;
			});
		}	
	}

	/*Promise.resolve(this.props.getNdiFields(4))
	.then((response)=>{
		this.setState(response.NdiFieldsOne);
		return response;
	});*/	

	console.log("*******WEEEEEEEEEEEE dehoooors gooooooooooooo ", this.props);



	const { t } = this.props;  
	//const tmpeventDataset=this.state.tmpeventDataset;
	const { tmpeventDataset } = this.props;
	const fieldname=tmpeventDataset.fieldname;
	const index=tmpeventDataset.index; 
	const tickeId=parseInt(tmpeventDataset.id);
	const caseid=tmpeventDataset.caseid;
	const productfieldid=tmpeventDataset.productfieldid;
	const fieldid=tmpeventDataset.fieldid;
	const required=tmpeventDataset.required;
	const fileshere=tmpeventDataset.fileshere; 
	const evalregexp=tmpeventDataset.evalregexp;
	const editlabelText=tmpeventDataset.editlabeltext;
	const actionGo=tmpeventDataset.delete;
	const deleteFileName=tmpeventDataset.deletefile;
	const filelabel=tmpeventDataset.filelabel; 
	const fileLabelDescription=this.state.fileLabelDescription; 
	const valueDescription2=this.state.fileLabelDescription2; 

	//fieldBlock={fieldBlock} ticketDataLeftRec={ticketDataLeftRec}
	const { fieldBlock, ticketDataLeftRec } = this.props;
	 
	//fileLabelDescription  editlabeltext
	let titlePoupup="No titile/Sans Titre";
	let btnText=""; let textLabelField=""; let textLabelField2="";
	
	const compoundCommand="{\"frfollowup\":\""+fileLabelDescription+"\", \"enfollowup\":\""+valueDescription2+"\" }";

	//console.log("fieldBlock <=> ticketDataLeftRec", tmpeventDataset, fieldBlock, ticketDataLeftRec);

	let baseFileList=[];
	// if(ticketDataLeftRec!==undefined && ticketDataLeftRec!==null && ticketDataLeftRec.field_data_raw.length>0){
	// 	baseFileList=JSON.parse(ticketDataLeftRec.field_data_raw); 
	// 	//JSON.parse(JSON.stringify(ticketDataLeftRec2)); 
	// }
	// else if(fieldBlock!==undefined && fieldBlock!==null && fieldBlock.field_dataset.length>0){
	// 	baseFileList=JSON.parse(fieldBlock.field_dataset);  
	// }

	console.log("*******WEEEEEEEEEEEE dehoooors ", this.props);
	console.log("*******WEEEEEEEEEEEE dehoooors ----- ", this.props.caseid);

	
	//if (this.props.caseid!== undefined) {  
	if (this.props.fieldBlock!==undefined || this.props.ticketDataLeftRec!==undefined) {
		//this.updateAndNotify(); 
		//console.log("WEEEEEEEEEEEE MINIPAYMENT componentWillMount OPEN ", new Date());
		let baseFileListx=[];
		if(ticketDataLeftRec!==undefined && ticketDataLeftRec!==null && ticketDataLeftRec.field_data_raw.length>0){
			baseFileListx=JSON.parse(ticketDataLeftRec.field_data_raw); 
			//JSON.parse(JSON.stringify(ticketDataLeftRec2)); 
			console.log("*******WEEEEEEEEEEEE inside  gooooooooooooo: ticketDataLeftRec ", baseFileListx);
		}
		else if(fieldBlock!==undefined && fieldBlock!==null && fieldBlock.field_dataset.length>0){
			baseFileListx=JSON.parse(fieldBlock.field_dataset);  
			console.log("*******WEEEEEEEEEEEE inside  gooooooooooooo: fieldBlock ");
		}
		 
		let fieldid="0"; let multi=false;
		if(fieldBlock!==undefined && fieldBlock!==null && fieldBlock.id!==undefined){
			fieldid=fieldBlock.id;
			if(fieldBlock.mask!==undefined && fieldBlock.mask==="multiple"){
				multi=true;  
			}  
		}
		for(let item of baseFileListx){
			let listfiles=[];
			if(item.listfiles!==undefined && item.listfiles.length>0){
				listfiles=item.listfiles;
			} 
			if(item.value===""  && listfiles.length<1){
				item.doc={
					"labelnodel": ""+item.labelnodel,
					"fieldid": fieldid,
					"multi": multi,
					"label": {
						"fr": item.label,
						"en": item.label
					}
				};
				item.isChecked=false;
				baseFileList.push(item);
			}
		}

		this.setState({ ["baseFileList"]:  baseFileList, });
	}

  }

  componentDidUpdate(prevProps) {
	/*if (prevProps.openx !== this.props.openx) {
	  //this.updateAndNotify();
	  if(this.props.openx){
		//console.log("WEEEEEEEEEEEE MINIPAYMENT componentWillMount OPEN ", new Date());
		this.setState({ ["productCart"]: [],"productId":"",	 });  
		this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
		this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
		this.setState({ ["nowPayment"]:  0, ["paymentRound"]:  0 });
	  }
	}*/

	const { t } = this.props;  
	//const tmpeventDataset=this.state.tmpeventDataset;
	const { tmpeventDataset } = this.props;
	const fieldname=tmpeventDataset.fieldname;
	const index=tmpeventDataset.index; 
	const tickeId=parseInt(tmpeventDataset.id);
	const caseid=tmpeventDataset.caseid;
	const productfieldid=tmpeventDataset.productfieldid;
	const fieldid=tmpeventDataset.fieldid;
	const required=tmpeventDataset.required;
	const fileshere=tmpeventDataset.fileshere; 
	const evalregexp=tmpeventDataset.evalregexp;
	const editlabelText=tmpeventDataset.editlabeltext;
	const actionGo=tmpeventDataset.delete;
	const deleteFileName=tmpeventDataset.deletefile;
	const filelabel=tmpeventDataset.filelabel; 
	const fileLabelDescription=this.state.fileLabelDescription; 
	const valueDescription2=this.state.fileLabelDescription2; 

	//fieldBlock={fieldBlock} ticketDataLeftRec={ticketDataLeftRec}
	const { fieldBlock, ticketDataLeftRec } = this.props;
	 
	//fileLabelDescription  editlabeltext
	let titlePoupup="No titile/Sans Titre";
	let btnText=""; let textLabelField=""; let textLabelField2="";
	
	const compoundCommand="{\"frfollowup\":\""+fileLabelDescription+"\", \"enfollowup\":\""+valueDescription2+"\" }";

	//console.log("fieldBlock <=> ticketDataLeftRec", tmpeventDataset, fieldBlock, ticketDataLeftRec);

	let baseFileList=[];
	// if(ticketDataLeftRec!==undefined && ticketDataLeftRec!==null && ticketDataLeftRec.field_data_raw.length>0){
	// 	baseFileList=JSON.parse(ticketDataLeftRec.field_data_raw); 
	// 	//JSON.parse(JSON.stringify(ticketDataLeftRec2)); 
	// }
	// else if(fieldBlock!==undefined && fieldBlock!==null && fieldBlock.field_dataset.length>0){
	// 	baseFileList=JSON.parse(fieldBlock.field_dataset);  
	// }

	console.log("*******WEEEEEEEEEEEE dehoooors ", this.props);
	console.log("*******WEEEEEEEEEEEE dehoooors ----- ", prevProps.caseid, this.props.caseid);

	if (prevProps.caseid !== this.props.caseid && this.props.caseid!== undefined) {  //prevProps.caseid !== this.props.caseid
		//this.updateAndNotify();
		// id={tickeId} caseid={caseid} index={index} 
		// 	productfieldid={productfieldid} fieldid={fieldid} 
		// 	required={required} delete={actionGo} filelabel={fileLabelDescription} 
		// 	evalregexp={""} deletefile={deleteFileName} 
		// 	tmpeventDataset={tmpeventDataset} 
		// 	fieldBlock={fieldBlock} ticketDataLeftRec={ticketDataLeftRec}
		console.log("*******WEEEEEEEEEEEE neeeeeeeeeeeeeeuf OPEN ", new Date());
		 
	}

	//if (this.props.caseid!== undefined) {  
	if (prevProps.fieldBlock !== this.props.fieldBlock || prevProps.ticketDataLeftRec !== this.props.ticketDataLeftRec) {
		//this.updateAndNotify(); 
		//console.log("WEEEEEEEEEEEE MINIPAYMENT componentWillMount OPEN ", new Date());
		let baseFileListx=[];
		if(ticketDataLeftRec!==undefined && ticketDataLeftRec!==null && ticketDataLeftRec.field_data_raw.length>0){
			baseFileListx=JSON.parse(ticketDataLeftRec.field_data_raw); 
			//JSON.parse(JSON.stringify(ticketDataLeftRec2)); 
		}
		else if(fieldBlock!==undefined && fieldBlock!==null && fieldBlock.field_dataset.length>0){
			baseFileListx=JSON.parse(fieldBlock.field_dataset);  
		}
		 
		let fieldid="0"; let multi=false;
		if(fieldBlock!==undefined && fieldBlock!==null && fieldBlock.id!==undefined){
			fieldid=fieldBlock.id;
			if(fieldBlock.mask!==undefined && fieldBlock.mask==="multiple"){
				multi=true;  
			}  
		}

		for(let item of baseFileListx){
			let listfiles=[];
			if(item.listfiles!==undefined && item.listfiles.length>0){
				listfiles=item.listfiles;
			} 
			if(item.value===""  && listfiles.length<1){
				item.doc={
					"labelnodel": ""+item.labelnodel,
					"fieldid": fieldid,
					"multi": multi,
					"label": {
						"fr": item.label,
						"en": item.label
					}
				};
				item.isChecked=false;
				baseFileList.push(item);
			}
		}
		
		this.setState({ ["baseFileList"]:  baseFileList, });
	}
		
  }


  


  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const text_label = this.state.text_label;
    const placeholder = this.state.placeholder;
    const field = this.state.field;
    const field_type = this.state.field_type;
    const field_default_value = this.state.field_default_value;
    const mask = this.state.mask;
    const field_service = this.state.field_service;
    const field_dataset = this.state.field_dataset;
    const tag_id = this.state.tag_id;
    const NdiFields = {id: id, name: name, text_label: text_label, placeholder: placeholder, field: field, field_type: field_type, field_default_value: field_default_value, mask: mask, field_service: field_service, field_dataset: field_dataset, tag_id: tag_id, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NdiFields[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateNdiFields(NdiFields, 1); 
		//this.props.addNdiFields(NdiFields); 
	}else{ 
		this.props.addOrUpdateNdiFields(NdiFields, 2);
		//this.props.updateNdiFields(NdiFields);
		
	}
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/NdiFields`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/NdiFields/${idx}`);
		//this.props.history.push(`/NdiFields/${this.props.NdiFields.id}`);
	}
  }

  handleSubmitDocEmail = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const text_label = this.state.text_label;
    const placeholder = this.state.placeholder;
    const field = this.state.field;
    const field_type = this.state.field_type;
    const field_default_value = this.state.field_default_value;
    const mask = this.state.mask;
    const field_service = this.state.field_service;
    const field_dataset = this.state.field_dataset;
    const tag_id = this.state.tag_id;
    const NdiFields = {id: id, name: name, text_label: text_label, placeholder: placeholder, field: field, field_type: field_type, field_default_value: field_default_value, mask: mask, field_service: field_service, field_dataset: field_dataset, tag_id: tag_id, }
    
	let errorFound=0; let docFound=0;
	
	const docList=[];
	let checkboxList = this.state.baseFileList;
    checkboxList.forEach(chkItem=>{
		//console.log('OOOOOOOOOOOOedit  test  ', chkItem.labelnodel, labelnodel);
		if(chkItem.isChecked === true){ 
			//console.log('OOOOOOOOOOOOedit  test passed  ', chkItem.labelnodel, labelnodel);
			docList.push(chkItem.doc);
			docFound++;
		}
		//   if(""+chkItem.labelnodel === ""+labelnodel){
		//     chkItem.isChecked = event.target.checked;
		// 	//console.log('OOOOOOOOOOOOedit  test passed  ', chkItem.labelnodel, labelnodel);
		//   }
    });

	const domainsdd=[
		{ "label": "civil", "value": "445" },
		{  "label": "vicescaches", "value": "442" },
		{ "label": "famille",  "value": "446" },
		{  "label": "penal", "value": "447" },
		{ "label": "notariat", "value": "448" },
		{ "label": "corporatif", "value": "449" },
		{ "label": "criminel", "value": "450" }
	];
	const domProp=this.props.domainid;
	//const ticket=this.props.ticket;
	const ticket="599761";
	let targetTxt="nodomain-yet";  //NEOLEGAL_penal-pnc

	for(let dom of domainsdd){
		if(dom.value===domProp){
			targetTxt=dom.label+"-"+dom.value;
		}
	}

	const confd = axiosDefaultsCred(null);
	//confd.token, 'auth-id': confd.userId     
	//let targetTxt="nodomain-yet";  //NEOLEGAL_penal-pnc
	 
	const dataObj={
		"ticket":ticket,
		"target":"NEOLEGAL_"+targetTxt,
		"AUTH_ID": confd.userId,
		"data":{
			"documents": docList
		  }
	  };   

	//if(errorFound > 0){ window.alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(docFound <1){ window.alert(`${t('zqwerrorFoundonform_x')}`); return false; }
	
	console.log('you haaaaaaaaaaaaaaave passeeeeeed the  test so faaaaaaaaaaar  ', docFound, dataObj);  
	this.neodocData(dataObj, confd.userId, confd.token);    
  };

  neodocData = (dataObj, userId, token) => {
	const this2=this;
	this.setState({ ["isLoaderOpen"]: true}); 
	//return false;  
	fetch(`https://apidoc.neodesk.app/api/v2/desk/sendFormToClient`, {   
		method: "POST", 		
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Authorization': "Basic YWRtaW46NmQyMWI0ODMzMzY4ZDYxYzMxMjc1MDkyODg2YTRkOWFkZDhmNzgwMWFiZDc4OTJkMDE3YjNmMTlhMzdiMzBjOA==",
			'x-jwt-token':token.replace("bearer ", ""),

			//'x-jwt-token':token,
			// 'Authorization': `${token}`,
			//'Authorization': `Bearer ${token}`,
			//'AUTH_ID': `${userId}`
		},
		body: JSON.stringify(dataObj)
	  })
		.then(function(response) {
		  if (!response.ok) {
			//throw new Error(`HTTP error! status: ${response.status}`);
			const error=`HTTP error! status: ${response.status}`;
			//return Promise.Reject(error);
			return Promise.reject(new Error(error));
		  }
		  return response.json();
		})
		.then(datax => { 
			const data = datax.result;  
			//localStorage.setItem("token", encryptText(data.token));
			//console.log("###******* SEEEEND DOOOOOOC SUCCESS  ");
			/// console.log("###******* PROFILE TEST  ");
			//console.log(data);  //state.LoggedUser.currentUser

			if(data.success===true || data.success==="true"){
				this.setState({ ["messageServer"]:  data.message, });
			}

			this.setState({ ["isLoaderOpen"]: false});  			   
		   
		})
		.catch(error => {
			this2.setState({ ["isLoaderOpen"]: false});  
			console.log("EEEEEEEEEEEEEEEEEEEERROOOOOOOR SEEEEND DOOOOOOC  on admin-neolegal ");
		  	console.error(error);
		});

  }

  
  render(){  
    const open = this.state.openFileLabelUpdate; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;  
	//const tmpeventDataset=this.state.tmpeventDataset;
	const { tmpeventDataset } = this.props;
	const fieldname=tmpeventDataset.fieldname;
	const index=tmpeventDataset.index; 
	const tickeId=parseInt(tmpeventDataset.id);
	const caseid=tmpeventDataset.caseid;
	const productfieldid=tmpeventDataset.productfieldid;
	const fieldid=tmpeventDataset.fieldid;
	const required=tmpeventDataset.required;
	const fileshere=tmpeventDataset.fileshere; 
	const evalregexp=tmpeventDataset.evalregexp;
	const editlabelText=tmpeventDataset.editlabeltext;
	const actionGo=tmpeventDataset.delete;
	const deleteFileName=tmpeventDataset.deletefile;
	const filelabel=tmpeventDataset.filelabel; 
	const fileLabelDescription=this.state.fileLabelDescription; 
	const valueDescription2=this.state.fileLabelDescription2; 



	//const open = this.props.openx;
    const globalStepWorkflow=this.state.globalStepWorkflow;
    const myToastMessage=this.state.toastrMessage;
    const toastrType=this.state.toastrType;
    const Alert=this.Alert;

	//fieldBlock={fieldBlock} ticketDataLeftRec={ticketDataLeftRec}
	const { fieldBlock, ticketDataLeftRec } = this.props;
	const messageServer=this.state.messageServer;
	 
  //fileLabelDescription  editlabeltext
  let titlePoupup="No titile/Sans Titre";
  let btnText=""; let textLabelField=""; let textLabelField2="";
  if(actionGo==="editlabel"){
	titlePoupup="Actualisation du Label du Fichier";
	btnText='Actualiser/Update';
	textLabelField="Noveau label du fichier";
  }else  if(actionGo==="sendEmailForDoc"){
	titlePoupup="Envoi de Courriel";
	btnText='Envoyer Email';
	textLabelField="Label du fichier";
  }else  if(actionGo==="reqMoreDoc"){
	titlePoupup="Ajouter Fichier additionnel";
	btnText='Ajouter Fichier';
	textLabelField="Label du fichier";
  }
  else  if(actionGo==="addFollowup"){
	titlePoupup="Ajouter un nouveau type de suivi";
	btnText='Ajouter Type de suivi';
	textLabelField="Nom du suivi (Français)";
	textLabelField2="Nom du suivi (Anglais)";
  }

  const compoundCommand="{\"frfollowup\":\""+fileLabelDescription+"\", \"enfollowup\":\""+valueDescription2+"\" }";

   console.log("fieldBlock <=> ticketDataLeftRec", tmpeventDataset, fieldBlock, ticketDataLeftRec);

//    let baseFileList=[];
//    if(ticketDataLeftRec!==undefined && ticketDataLeftRec!==null && ticketDataLeftRec.field_data_raw.length>0){
// 	baseFileList=JSON.parse(ticketDataLeftRec.field_data_raw); 
// 	//JSON.parse(JSON.stringify(ticketDataLeftRec2)); 
//    }
//    else if(fieldBlock!==undefined && fieldBlock!==null && fieldBlock.field_dataset.length>0){
// 	baseFileList=JSON.parse(fieldBlock.field_dataset);  
//    }

   let baseFileList=this.state.baseFileList;

   console.log("fieldBlock <=> ticketDataLeftRec: baseFileList --- ", baseFileList);

   let refundlistx = baseFileList.length > 0
    	&& baseFileList.map((item, i) => { 
        //console.log(item);
        if(item!==null && item.labelnodel!==undefined){
          return(
            <tr key={"r"+item.labelnodel}>
              <td>
			  <input type="checkbox" name="idfiletosend" id={"idf"+item.labelnodel}  value={item.labelnodel} 
			  defaultChecked={item.isChecked} data-index={i} 
			  //onChange={this.handleChange} onBlur={this.handleFocusout}
			  onClick={this.handleCheckBoxClick}
              className={'form-control'}  /> 
				</td>
              <td>{item.label}</td>
            </tr>
          ); 
        }
      return (
        null
      );
    }, this);

    return (
      <div>        
          <div className="text-center"> 
            <h5>{titlePoupup}</h5>  
          </div>

		  {/* <div className="form-group">
			  <label htmlFor="name">Weeeeeeeeeeeeeeeeeeeee teeeest d'un fichier</label>	 
		  </div> */}

		  <div className="text-centerx"> 
		  <table className="table table-striped">
			<thead>
				<tr>
				<th> &nbsp; </th> 
				<th>Nom de fichier</th>
				</tr>
			</thead>
			<tbody> 
				{refundlistx}
			</tbody>
		  </table>
		  </div>

		  {/* <div className="form-group">
			  <label htmlFor="name">Vous êtes en train d'actualiser label d'un fichier</label>	 
		  </div> */}

		
			<div className="text-center"> 
				<p> </p>   
			</div>
			 
			 {messageServer===""?
			<div className="form-group">
				 
				<input type="button" 
				data-id={tickeId} data-caseid={caseid} data-index={index} 
				data-productfieldid={productfieldid} data-fieldid={fieldid} data-fieldname={fieldname}
				data-required={required} data-delete={actionGo} data-filelabel={compoundCommand} 
				data-evalregexp={""} data-deletefile={deleteFileName} 
				//onClick={this.handleSubmitTicketData} 
				onClick={this.handleSubmitDocEmail} 
				className="btn btn-primary float-right" value={btnText} /> &nbsp; &nbsp; 
            </div>:
			<div className="form-group">
				<div className="text-center sucMessage"> 
				<label htmlFor="nameooooo">Félicitations, fichiers envoyés au client/Congratulations files sent to the client!</label><br/>
				<br/>
				</div>
				{messageServer} 	 
			</div>
			}

          {/* <div className="form-row neoxtbl">
			<div className="form-group col-md-9">
            <br/>
            <input type="button" onClick={this.closePopup4} className="btn btn-success" value={'Fermer/Close'} /> &nbsp; &nbsp; 
            </div> 
          </div>               */}

		<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
  		<Loader  openx={this.state.isLoaderOpen}  />
          
      </div>
    );
  }

}

//const TransNdiFieldsForm = withTranslation()(NdiFieldsForm); 
const mapStateToProps = (state) => ({ NdiFields: state.NdiFields });

const mapDispatchToProps = { addOrUpdateNdiFields, getNdiFields };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NdiNeoDocSendFileOptions));
