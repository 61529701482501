import { RECEIVE_NDIFIELDOPTIONSTRANSLATION, ADD_NDIFIELDOPTIONSTRANSLATION, REMOVE_NDIFIELDOPTIONSTRANSLATION, REPLACE_NDIFIELDOPTIONSTRANSLATION } from '../actions/NdiFieldOptionsTranslationAction';

const initialState = { NdiFieldOptionsTranslation: [] }
export default function NdiFieldOptionsTranslationReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDIFIELDOPTIONSTRANSLATION:
      return action.NdiFieldOptionsTranslation;
    case ADD_NDIFIELDOPTIONSTRANSLATION:
      return [action.payload, ...state];
    case REMOVE_NDIFIELDOPTIONSTRANSLATION:
      return state.filter(NdiFieldOptionsTranslation => NdiFieldOptionsTranslation.id !== action.payload.id);
    case REPLACE_NDIFIELDOPTIONSTRANSLATION:
      return state.map((NdiFieldOptionsTranslation) => {
        if (NdiFieldOptionsTranslation.id === action.payload.id) {
          return {
            ...NdiFieldOptionsTranslation,
field_option_id: action.payload.field_option_id,
lang: action.payload.lang,
text_label: action.payload.text_label,
          }
        } else return NdiFieldOptionsTranslation;
      })
    default:
      return state;
  }
}
