import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDIPRODUCTS = 'GET_NDIPRODUCTS';
export const ADD_NDIPRODUCTS = 'ADD_NDIPRODUCTS';
export const RECEIVE_NDIPRODUCTSX = 'RECEIVE_NDIPRODUCTSX';
export const REMOVE_NDIPRODUCTS = 'REMOVE_NDIPRODUCTS';
export const UPDATE_NDIPRODUCTS = 'UPDATE_NDIPRODUCTS';
export const REPLACE_NDIPRODUCTS = 'REPLACE_NDIPRODUCTS';
const apiUrl = API_DOMAIN + '/ndiproducts'; 

axiosDefaults(null);

export const getNdiProductsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data);		 
        return dispatch({type: RECEIVE_NDIPRODUCTS, NdiProducts: response.data.data.ndiproducts});
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiProducts = (NdiProducts) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiProducts.id,
		name: NdiProducts.name,
		product_type_id: NdiProducts.product_type_id,
		product_block: NdiProducts.product_block,
		published: NdiProducts.published,
		last_update: NdiProducts.last_update,
		user_id: NdiProducts.user_id,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDIPRODUCTS, payload: {
			id: data.id,
			name: data.name,
			product_type_id: data.product_type_id,
			product_block: data.product_block,
			published: data.published,
			last_update: data.last_update,
			user_id: data.user_id,
		}})
      })
      .then(() => {
        history.push("/NdiProducts")
      })
      .catch(error => { throw(error) });
  };
};
export const getNdiProducts = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.ndiproducts);		  
        return dispatch({type: RECEIVE_NDIPRODUCTSX, NdiProductsOne: response.data.ndiproducts});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiProductsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiProducts/${id}/edit`)
  };
};
export const deleteNdiProducts = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDIPRODUCTS, payload: {id}})
      })
      .then(() => {
        history.push("/NdiProducts")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiProducts = (NdiProducts) => {
  const id = NdiProducts.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiProducts.id}`, {
		name: NdiProducts.name,
		product_type_id: NdiProducts.product_type_id,
		product_block: NdiProducts.product_block,
		published: NdiProducts.published,
		last_update: NdiProducts.last_update,
		user_id: NdiProducts.user_id,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDIPRODUCTS, payload: {
			id: data.id,
			name: data.name,
			product_type_id: data.product_type_id,
			product_block: data.product_block,
			published: data.published,
			last_update: data.last_update,
			user_id: data.user_id,
		}})
        dispatch({type: REPLACE_NDIPRODUCTS, payload: {
			id: data.id,
			name: data.name,
			product_type_id: data.product_type_id,
			product_block: data.product_block,
			published: data.published,
			last_update: data.last_update,
			user_id: data.user_id,
		}})
      })
      .then(() => {
        history.push(`/NdiProducts/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiProducts = (NdiProducts, actionMode) => {
	if(actionMode===1){
        return addNdiProducts(NdiProducts);
      }else {
        return updateNdiProducts(NdiProducts);
      }
};