import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoB2bClients, getNeoB2bClients, getNeoB2bClientsAll, getNeoB2bClientsGoto, deleteNeoB2bClients } from '../../actions/NeoB2bClientsAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";

class NeoB2bClientsList extends Component {
	constructor(props) {
		super(props); 
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() { 
		this.props.getNeoB2bClientsAll();
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoB2bClients;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoB2bClients(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.NeoB2bClients;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getNeoB2bClientsGoto(obj.id); 
	//history.push(`/neob2bclients/${obj.id}/edit`); 
  }
  

  render() { 
    const { t } = this.props;
	const columns = [
	 {
	  name: "id", label: t('zqwid_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "id_b2b_list", label: t('zqwid_b2b_list_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "id_external", label: t('zqwid_external_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "name_id_external", label: t('zqwname_id_external_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "firstname", label: t('zqwfirstname_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "lastname", label: t('zqwlastname_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "email", label: t('zqwemail_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "tel", label: t('zqwtel_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "start_date", label: t('zqwstart_date_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "expiry_date", label: t('zqwexpiry_date_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "address", label: t('zqwaddress_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "city", label: t('zqwcity_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "province", label: t('zqwprovince_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "postal_code", label: t('zqwpostal_code_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "raw_data", label: t('zqwraw_data_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "date_created", label: t('zqwdate_created_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "date_updated", label: t('zqwdate_updated_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "status", label: t('zqwstatus_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "dob", label: t('zqwdob_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "id_clients", label: t('zqwid_clients_x'),
	  options: { filter: true, sort: true,}
	 },
	];
const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),
	};  
	  
    if(this.props.NeoB2bClients.length) {                                
      return (
        <div>
          <h4>{t('zqwNeoB2bClients_x')}</h4>		  
		  <MUIDataTable
			  title={""}
			  data={this.props.NeoB2bClients}
			  columns={columns}
			  options={options}
			/>
        </div>
      )    
    } else {
      return (<div>{t('zqwgridNoRecordfound_x')}</div>)
    }  }
}


const mapStateToProps = (state) => ({ NeoB2bClients: state.NeoB2bClients });  
const mapDispatchToProps = { addOrUpdateNeoB2bClients, getNeoB2bClients, getNeoB2bClientsAll, getNeoB2bClientsGoto, deleteNeoB2bClients };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoB2bClientsList));
