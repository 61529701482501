import React, { useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';

import FaceIcon from '@material-ui/icons/Face';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

import UserLogout  from "./UserLogout";

import ChangeLanguage from './changeLanguage'; 
import { Link } from "react-router-dom";

import { connect, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getDailyGoalSale } from './actions/NeoSalesPlanningDailyAction';
import Fade from '@material-ui/core/Fade';
import history from './history';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

//export class BaseHeader2 extends React.Component {
export function BaseHeader2(props) {
  let _isMounted = true; //false;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorLeftMenu, setAnchorLeftMenu] = React.useState(false);
  const [userLogoutOpen, setUserLogoutOpen] = React.useState(false);
  const [saleGoalDaily, setSaleGoalDaily] = React.useState("...");
  const [itemSale2, setItemSale2] = React.useState("...");
  const [itemSale3, setItemSale3] = React.useState("...");
  const [itemSale4, setItemSale4] = React.useState("...");
  const [itemSale5, setItemSale5] = React.useState("...");
  const [itemSale6, setItemSale6] = React.useState("...");
  const [itemSale7, setItemSale7] = React.useState("...");

  const [saleGoalWeekly, setSaleGoalWeekly] = React.useState("...");
  const [itemSale20, setItemSale20] = React.useState("...");
  const [itemSale30, setItemSale30] = React.useState("...");
  const [itemSale40, setItemSale40] = React.useState("...");
  const [itemSale50, setItemSale50] = React.useState("...");
  const [itemSale60, setItemSale60] = React.useState("...");
  const [itemSale70, setItemSale70] = React.useState("...");
   

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLeftMenuOpen = Boolean(anchorLeftMenu);
  //const isUserLogoutOpen= Boolean(anchorLeftMenu);

  const dispatch=useDispatch();

  const [anchorElSale, setAnchorElSale] = React.useState(null);
  const openSale = Boolean(anchorElSale);

  const handleClickSale = (event) => {
    setAnchorElSale(event.currentTarget);
  };

  const handleCloseSale = () => {
    setAnchorElSale(null);
  };

  useEffect(() => {
    _isMounted = true;
    //console.log("useEffect Header2 POOOOPS "); ////console.log(props);
    //const czx= dispatch(getDailyGoalSale()); //dispatch(getDailyGoalSale());
    ////console.log(czx); 
    ////console.log("useEffect Header2 POOOOPS ");
    
    /*handleSaleGoal(null);

    const interval= setInterval(() => {
      handleSaleGoal(null);
    }, 300000);

    return () => clearInterval(interval);*/

    forceLogoutSystem(null);

    const interval= setInterval(() => {
      //handleSaleGoal(null);
      forceLogoutSystem(null);
    }, 300000);

    return () => clearInterval(interval);
    
  }, []);

  const handleSaleGoal = (event) => {
    Promise.resolve(dispatch(getDailyGoalSale()))
    .then((response)=>{ 
      const cxz=response.NeoSalesPlanningDailyOne;
      //setSaleGoalDaily(cxz.qtyToday+"/"+cxz.expectation.expected_quantity);
      setSaleGoalDaily(cxz.expectation.expected_quantity+" Totale vente d'aujourd'hui "+ 
      cxz.qtyToday +"  ("+cxz.qtyToday+"/"+cxz.expectation.expected_quantity+")");
      setItemSale2(cxz.dat2); setItemSale3(cxz.expectation.expected_quantity);  
      setItemSale4(cxz.expectation.expected_amount);
      setItemSale5(cxz.qtyToday); setItemSale6(cxz.amntToday);  
      setItemSale7(((cxz.qtyToday*100)/(cxz.expectation.expected_quantity*1)).toFixed(2)+"%");  
      ////console.log("useEffect Header2 "); //console.log(response.NeoSalesPlanningDailyOne);

      setSaleGoalWeekly(cxz.weekexpectation.expected_quantity+" Totale vente hebdomadaire "+ 
      cxz.qtyWeek +"  ("+cxz.qtyWeek+"/"+cxz.weekexpectation.expected_quantity+")");
      setItemSale20(cxz.dat2); setItemSale30(cxz.weekexpectation.expected_quantity);  
      setItemSale40(cxz.weekexpectation.expected_amount);
      setItemSale50(cxz.qtyWeek); setItemSale60(cxz.amntWeek);  
      setItemSale70(((cxz.qtyWeek*100)/(cxz.weekexpectation.expected_quantity*1)).toFixed(2)+"%");  

      return response;
    })
  };

  const forceLogoutSystem = (event) => {
    const dd=new Date();
		const ddIso=new Date(dd.getFullYear(), dd.getMonth(), dd.getDate());
		const ddStr=""+dd.getFullYear()+""+dd.getMonth()+""+dd.getDate(); 
		//localStorage.setItem("uLogTracking", ddIso.toISOString());
    //localStorage.setItem("uLogTracking2", ddStr);
    const uLogTracking2=localStorage.getItem("uLogTracking2");
    if(uLogTracking2!==undefined){
      if(uLogTracking2!==ddStr){
        localStorage.removeItem("user"); 
        localStorage.removeItem("token"); 
        localStorage.removeItem("rid"); 
        localStorage.removeItem("profile");  
        history.push(`./Login`); 
      }
    }else{
      localStorage.removeItem("user"); 
        localStorage.removeItem("token"); 
        localStorage.removeItem("rid"); 
        localStorage.removeItem("profile");  
        history.push(`./Login`); 
    }
    

  }; 

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  
  const handleExternalMenuOpen = (event) => {
    //setMobileMoreAnchorEl(event.currentTarget);
    if(_isMounted){
      //console.log("+++++++ TEMP MENU TOGGLE ");
      props.handleLeftPanelMenuRef(event);
    }
  };
  
  const logoutSystem = (event) => {
    //window.confirm();
	/*if(window.confirm('Are you sure you want to log out the system?')){
		///zonlineusuarios
	}*/
	setUserLogoutOpen(true);
  };
  
  /*
  <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
	  <MenuItem><Link to="./zonlineroles">Profile</Link></MenuItem>
	  <MenuItem><Link to="./zxcompanies">Compañías</Link></MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
	  <MenuItem onClick={logoutSystem}>Logout</MenuItem>
	  <MenuItem><UserLogout openx={userLogoutOpen} /></MenuItem>
  */

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
    <MenuItem><Link to="./zonlineroles">Profile</Link></MenuItem> 
	  <MenuItem><UserLogout openx={userLogoutOpen} /></MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}

	  {/* <MenuItem>
		<IconButton aria-label="change the language" color="inherit">
		  <ChangeLanguage /> Change Language
		</IconButton> 
      </MenuItem> */}

	  {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}

      <MenuItem><Link to="./zonlineroles">Profile</Link></MenuItem> 
	    <MenuItem><UserLogout openx={userLogoutOpen} /></MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
			onClick={handleExternalMenuOpen}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>

           
          
          
          {/*<Typography className={classes.title} variant="h6" noWrap>
            Material-UI
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>*/}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}

			{/* <IconButton aria-label="change the language" color="inherit">
              <ChangeLanguage />
            </IconButton>  */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      
	  {/*<UserLogout openx={userLogoutOpen} />*/}
    </div>
  );

  
}

const mapDispatchToProps = dispatch => ({ 
  getDailyGoalSale: () => dispatch(getDailyGoalSale()) 
});
const mapStateToProps = (state) => ({ NeoSalesPlanningDaily: state.NeoSalesPlanningDaily });  //NeoVentes: state.NeoVentes
//const mapDispatchToProps = {  getDailyGoalSale: dispatch(getDailyGoalSale) };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(BaseHeader2));
