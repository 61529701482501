import  { encrypt , decrypt } from 'react-crypt-gsm';

export const encryptText = function (text) { 
    // Global axios defaults
	
	//console.log("encrypt AUTHORIZATION ");  
	if(text===null || text===undefined){ 
		return text; //return ''; 
	}
	   const encryptedStr = encrypt(text);
	   const text2 = JSON.stringify(encryptedStr);
	return text2; //return encrypt(text);
};


export const decryptText = function (text) { 
    // Global axios defaults
	
	//console.log("decrypt AUTHORIZATION "); 
	if(text===null || text===undefined){ 
		return text; //return ''; 
	}
 
	 const text3 = JSON.parse(text); 
	 const content=text3.content;
	 const tag=new Uint8Array(text3.tag.data);
	 const textedConst={content: content, tag: tag};   
	 const decryptedStr2 = decrypt(textedConst);  


	//const decryptedStr = decrypt(text); 
	return decryptedStr2; //return decrypt(text); 

};

export const testEncryptionText = function (text) { 
    // Global axios defaults
	
	//console.log("decrypt AUTHORIZATION "); 
	if(text===null || text===undefined){ 
		return text; //return ''; 
	}

	//const myJSON = JSON.stringify(obj);
	//const text = JSON.stringify(text);

	//console.log("CLEAR TEXT "+text); 
	const encryptedStr = encrypt(text);
	//console.log("ENCRYPTED TEXT "+encryptedStr); 
	//console.log(encryptedStr); 
	const decryptedStr = decrypt(encryptedStr);  //content tag 
	//console.log("DECRYPTED TEXT "+decryptedStr); 
	//console.log(decryptedStr);
	//console.log("################### CLEAR TEXT "); 
	 const text2 = JSON.stringify(encryptedStr);
	//console.log("ENCRYPTED TEXT2 "+text2); 
	 const text3 = JSON.parse(text2);
	 //console.log("JSON TEXT3 "+text3); 
	 //console.log(text3);
	 const content=text3.content;
	 const tag=new Uint8Array(text3.tag.data);
	 const textedConst={content: content, tag: tag};
	 //console.log("JSON TEXT4 "+content+"  tag="+tag);
	 //console.log(textedConst); 
	 const decryptedStr2 = decrypt(textedConst); 
	 //console.log("DECRYPTED TEXT2 "+decryptedStr2); 
	 //console.log(decryptedStr2);


	return text; 

};


