import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getNeoB2bClients, deleteNeoB2bClients } from '../../actions/NeoB2bClientsAction';

class NeoB2bClientsInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		id_b2b_list:'', e_id_b2b_list: false,
		id_external:'', e_id_external: false,
		name_id_external:'', e_name_id_external: false,
		firstname:'', e_firstname: false,
		lastname:'', e_lastname: false,
		email:'', e_email: false,
		tel:'', e_tel: false,
		start_date:'', e_start_date: false,
		expiry_date:'', e_expiry_date: false,
		address:'', e_address: false,
		city:'', e_city: false,
		province:'', e_province: false,
		postal_code:'', e_postal_code: false,
		raw_data:'', e_raw_data: false,
		date_created:'', e_date_created: false,
		date_updated:'', e_date_updated: false,
		status:'', e_status: false,
		dob:'', e_dob: false,
		id_clients:'', e_id_clients: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getNeoB2bClients(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getNeoB2bClients(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.NeoB2bClientsOne);  console.log("0000********FIN**********");
		this.setState(response.NeoB2bClientsOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const NeoB2bClients = this.props.NeoB2bClients;
    const NeoB2bClients = this.state; 
    return (
      <div>
        <h2>NeoB2bClients</h2>
        <hr/>
        <p><label>{t('zqwid_x')}</label> &nbsp; &nbsp; #{NeoB2bClients.id}</p>
        <p><label>{t('zqwid_b2b_list_x')}</label><br/>{NeoB2bClients.id_b2b_list}</p>
        <p><label>{t('zqwid_external_x')}</label><br/>{NeoB2bClients.id_external}</p>
        <p><label>{t('zqwname_id_external_x')}</label><br/>{NeoB2bClients.name_id_external}</p>
        <p><label>{t('zqwfirstname_x')}</label><br/>{NeoB2bClients.firstname}</p>
        <p><label>{t('zqwlastname_x')}</label><br/>{NeoB2bClients.lastname}</p>
        <p><label>{t('zqwemail_x')}</label><br/>{NeoB2bClients.email}</p>
        <p><label>{t('zqwtel_x')}</label><br/>{NeoB2bClients.tel}</p>
        <p><label>{t('zqwstart_date_x')}</label><br/>{NeoB2bClients.start_date}</p>
        <p><label>{t('zqwexpiry_date_x')}</label><br/>{NeoB2bClients.expiry_date}</p>
        <p><label>{t('zqwaddress_x')}</label><br/>{NeoB2bClients.address}</p>
        <p><label>{t('zqwcity_x')}</label><br/>{NeoB2bClients.city}</p>
        <p><label>{t('zqwprovince_x')}</label><br/>{NeoB2bClients.province}</p>
        <p><label>{t('zqwpostal_code_x')}</label><br/>{NeoB2bClients.postal_code}</p>
        <p><label>{t('zqwraw_data_x')}</label><br/>{NeoB2bClients.raw_data}</p>
        <p><label>{t('zqwdate_created_x')}</label><br/>{NeoB2bClients.date_created}</p>
        <p><label>{t('zqwdate_updated_x')}</label><br/>{NeoB2bClients.date_updated}</p>
        <p><label>{t('zqwstatus_x')}</label><br/>{NeoB2bClients.status}</p>
        <p><label>{t('zqwdob_x')}</label><br/>{NeoB2bClients.dob}</p>
        <p><label>{t('zqwid_clients_x')}</label><br/>{NeoB2bClients.id_clients}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/NeoB2bClients/${NeoB2bClients.id}/edit`, state: { NeoB2bClients: NeoB2bClients } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNeoB2bClients(NeoB2bClients.id)}>          
            {t('Delete')}
          </button>
          <Link to="/NeoB2bClients" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransNeoB2bClientsInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ NeoB2bClients: state.NeoB2bClients });                 

const mapDispatchToProps = { getNeoB2bClients, deleteNeoB2bClients };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoB2bClientsInfo));   

