import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateNdiFields, getNdiFields } from '../../actions/NdiFieldsAction'; 
import { getNdiTagsAll } from '../../actions/NdiTagsAction'; 
import { Validations } from '../../utils/validations'; 
import { APP_PATHNAME } from '../../appConfig'; 
 
import Toastr from "../../messages";
import  Loader from "../../loader";

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['name']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['text_label']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['placeholder']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_type']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_default_value']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['mask']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_service']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_dataset']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['tag_id']={check:false, chkType:'int', message:'incorrect value'};

class NdiFieldsForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		id:0,
		name:'', e_name: false,
		text_label:'', e_text_label: false,
		placeholder:'', e_placeholder: false,
		field:'', e_field: false,
		field_type:'', e_field_type: false,
		field_default_value:'', e_field_default_value: false,
		mask:'', e_mask: false,
		field_service:'', e_field_service: false,
		field_dataset:'', e_field_dataset: false,
		tag_id:'', e_tag_id: false,
		fieldx:'-', showMaskBlock:false, listTagIds:[], 
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeField = (event) => {
	this.setState({ [event.target.name]: event.target.value });
	const val=(""+event.target.value).split("-");
	this.setState({ ["field"]: val[0], ["showMaskBlock"]: false });
	this.setState({ ["field_type"]: val[1] });
	if(event.target.value==="inputMask-inputmask"){
		this.setState({ ["showMaskBlock"]: true});
	}
	 
  };

  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.NdiFields.id);
		if(this.props.NdiFields.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getNdiFields(idx);
			Promise.resolve(this.props.getNdiFields(idx))
			.then((response)=>{
				const ndiFields=response.NdiFieldsOne;
				this.setState(response.NdiFieldsOne);
				const fxval=ndiFields.field+"-"+ndiFields.field_type;
				this.setState({ ["fieldx"]: fxval});
				console.log("weeeeeeeeeeeeeeeeeeee", fxval); 
				if(fxval==="inputMask-inputmask"){
					this.setState({ ["showMaskBlock"]: true});
				}
				
				return response;
			});
		}	
	}

	// Promise.resolve(this.props.getNdiFields(4))
	// .then((response)=>{
	// 	this.setState(response.NdiFieldsOne);
	// 	return response;
	// });	

	Promise.resolve(this.props.getNdiTagsAll())
	.then((response)=>{
		//this.setState(response.NdiProductTypesOne);
		const ndiTags=response.NdiTags.nditags;
		//console.log("resp props.getNdiTagsAll ", ndiTags); 
		this.setState({ ["listTagIds"]: ndiTags });
		 

		return response;
	});

  }

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		toastrType: toastType
		});
		setTimeout(() => { 
		this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		}); 
		}, toastrDuration);
	}

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const text_label = this.state.text_label;
    const placeholder = this.state.placeholder;
    const field = this.state.field;
    const field_type = this.state.field_type;
    const field_default_value = this.state.field_default_value;
    const mask = this.state.mask;
    const field_service = this.state.field_service;
    const field_dataset = this.state.field_dataset;
    const tag_id = this.state.tag_id;
    const NdiFields = {id: id, name: name, text_label: text_label, placeholder: placeholder, field: field, field_type: field_type, field_default_value: field_default_value, mask: mask, field_service: field_service, field_dataset: field_dataset, tag_id: tag_id, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NdiFields[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		//this.props.addOrUpdateNdiFields(NdiFields, 1); 
		//this.props.addNdiFields(NdiFields);
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.addOrUpdateNdiFields(NdiFields, 1))
		.then((response)=>{ 
			const ndiTags=response; //.NdiTags.nditags;
			console.log("submit form resp props.getNdiTagsAll ", ndiTags); 
			//this.setState({ ["listTagIds"]: ndiTags });
			this.setState({ ["isLoaderOpen"]: false});
		 	this.showToast("On a sauvegardé les données avec succès!", "success");  

			return response;
		}).catch(function(error){ 
			this2.setState({ ["isLoaderOpen"]: false});  
			alert(error);
		});  
	}else{ 
		//this.props.addOrUpdateNdiFields(NdiFields, 2);
		//this.props.updateNdiFields(NdiFields);
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.addOrUpdateNdiFields(NdiFields, 1))
		.then((response)=>{ 
			const ndiTags=response; //.NdiTags.nditags;
			console.log("submit form resp props.getNdiTagsAll ", ndiTags); 
			//this.setState({ ["listTagIds"]: ndiTags });
			this.setState({ ["isLoaderOpen"]: false});
		 	this.showToast("On a actualisé les données avec succès!", "success");  

			return response;
		}).catch(function(error){ 
			this2.setState({ ["isLoaderOpen"]: false});  
			alert(error);
		});  
		
	}
  };

  handleCancel = () => { 
	  const path =APP_PATHNAME;
	this.props.history.push(`${path}NdiFields`);
	// if(this.actionMode==='new'){ 
	// 	this.props.history.push(`./NdiFields`);
	// }else{ 
	// 	const idx = this.props.match.params.id; 
	// 	this.props.history.push(`./NdiFields/${idx}`);
	// 	//this.props.history.push(`/NdiFields/${this.props.NdiFields.id}`);
	// }
  }

  //https://developer.zendesk.com/apps/docs/core-api/client_api 
  //https://developer.zendesk.com/apps/docs/developer-guide/manifest


  render() {
	const { t } = this.props;
	const listTagIds=this.state.listTagIds;
	// const listProductTypes =  listProductTypesx.filter(function(prodType) {  
	// 	return prodType.main_category === "1"; 
	// });  
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	const tag_id=this.state.tag_id;
	
	const listeOptions = listTagIds.map((item, i) => {  
		const tgId=""+item.id;
		return (
		  <option key={"op"+(i+1)} value={tgId}>{item.name}</option>
		);
	  }, this);

    return (
      <div>
        <h3>{this.actionMode==='new'? t('zqwacAddNdiFields_x'): t('zqwacEditNdiFields_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		  
		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="name">{t('zqwname_x')}</label>
            <input type="text" name="name" id="name" defaultValue={this.state.name} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_name ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwname_x')} /> 
			{this.state.e_name && (<span className="text-danger">{FIELD_VALIDATION['name'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="text_label">{t('zqwtext_label_x')}</label>
            <input type="text" name="text_label" id="text_label" defaultValue={this.state.text_label} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_text_label ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtext_label_x')} /> 
			{this.state.e_text_label && (<span className="text-danger">{FIELD_VALIDATION['text_label'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="placeholder">{t('zqwplaceholder_x')}</label>
            <input type="text" name="placeholder" id="placeholder" defaultValue={this.state.placeholder} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_placeholder ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwplaceholder_x')} /> 
			{this.state.e_placeholder && (<span className="text-danger">{FIELD_VALIDATION['placeholder'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="field">Liste de Type de Champs</label>
            <select name="fieldx" id="fieldx" key={this.state.fieldx} defaultValue={this.state.fieldx} onChange={this.handleChangeField} onBlur={this.handleFocusout}
              className={'form-control'} placeholder={t('zqwfield_x')}> 
                        <option value="">Please Select </option>
						<option value="-">Please Select field</option>
                        <option value="input-text">Input Text</option>
						<option value="input-number">Input Number</option>
						<option value="input-radio">Input Radio</option>
						<option value="input-checkbox">Input Checkbox</option>
						<option value="input-date">Input Date</option>
						<option value="input-time">Input Time</option>
						<option value="input-datetime">Input DateTime</option>
                        <option value="select-select">Select</option>
						<option value="select-selectmultiple">Select Multiple</option>
						{/* <option value="select-single">Select</option>
						<option value="select-selectmultiple">Select</option> */}
						<option value="inputMask-inputmask">Input Mask</option>
						<option value="textarea-textarea">textarea</option>
						<option value="select-selectreact">Select-react</option>
						<option value="input-file">Input Fichier/file</option>
						<option value="input-email">Input Email</option>
						<option value="select-selectreactmany">Select-react Multiple</option>
						<option value="select-selectreactmanystack">Select-react Multiple&Stack</option>
						 
						{/* <option value="select2">No</option>
						<option value="select3">No</option>
						<option value="select4">No</option> */}
                    </select>  
			{this.state.e_field && (<span className="text-danger">{FIELD_VALIDATION['field'].message}</span>)} 
          </div>

		  {/* <div className="form-group">
			<label htmlFor="field">{t('zqwfield_x')}</label>
            <input type="text" name="field" id="field" defaultValue={this.state.field} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_field ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwfield_x')} />  
			{this.state.e_field && (<span className="text-danger">{FIELD_VALIDATION['field'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="field_type">{t('zqwfield_type_x')}</label>
            <input type="text" name="field_type" id="field_type" defaultValue={this.state.field_type} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_field_type ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwfield_type_x')} /> 
			{this.state.e_field_type && (<span className="text-danger">{FIELD_VALIDATION['field_type'].message}</span>)} 
          </div> */}

		  <div className="form-group">
			<label htmlFor="field_default_value">{t('zqwfield_default_value_x')}</label>
            <input type="text" name="field_default_value" id="field_default_value" defaultValue={this.state.field_default_value} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_field_default_value ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwfield_default_value_x')} /> 
			{this.state.e_field_default_value && (<span className="text-danger">{FIELD_VALIDATION['field_default_value'].message}</span>)} 
          </div>

		{this.state.showMaskBlock===true?
		  <div className="form-group">
			<label htmlFor="mask">{t('zqwmask_x')}</label>
            <input type="text" name="mask" id="mask" defaultValue={this.state.mask} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_mask ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwmask_x')} /> 
			{this.state.e_mask && (<span className="text-danger">{FIELD_VALIDATION['mask'].message}</span>)} 
          </div>:''

		}

		  {/* <div className="form-group">
			<label htmlFor="field_service">{t('zqwfield_service_x')}</label>
            <input type="text" name="field_service" id="field_service" defaultValue={this.state.field_service} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_field_service ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwfield_service_x')} /> 
			{this.state.e_field_service && (<span className="text-danger">{FIELD_VALIDATION['field_service'].message}</span>)} 
          </div> */}

		  <div className="form-group">
			<label htmlFor="field_dataset">{t('zqwfield_dataset_x')}</label>
            {/* <input type="text" name="field_dataset" id="field_dataset" defaultValue={this.state.field_dataset} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_field_dataset ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwfield_dataset_x')} /> */}
			 <textarea name="field_dataset" id="field_dataset" rows="4" cols="50" 
			 defaultValue={this.state.field_dataset} onChange={this.handleChange} 
			 onBlur={this.handleFocusout} 
			 className={this.state.e_field_dataset ? 'form-control fieldErr' : 'form-control'} 
			 placeholder={t('zqwfield_dataset_x')}></textarea>  
			{this.state.e_field_dataset && (<span className="text-danger">{FIELD_VALIDATION['field_dataset'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="tag_id">{t('zqwtag_id_x')}</label>
            {/* <input type="text" name="tag_id" id="tag_id" defaultValue={this.state.tag_id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_tag_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtag_id_x')} /> */}
			<select name="tag_id" id="tag_id"  key={this.state.tag_id} defaultValue={this.state.tag_id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_tag_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwtag_id_x')}> 
                        <option value="">Please Select</option>
                        {listeOptions}
                    </select>   
			{this.state.e_tag_id && (<span className="text-danger">{FIELD_VALIDATION['tag_id'].message}</span>)} 
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			<button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>
          </div>
        </form>
		<Loader  openx={this.state.isLoaderOpen}  />
		<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
      </div>
    );
  }
}

//const TransNdiFieldsForm = withTranslation()(NdiFieldsForm); 
const mapStateToProps = (state) => ({ NdiFields: state.NdiFields });

const mapDispatchToProps = { addOrUpdateNdiFields, getNdiFields, getNdiTagsAll };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NdiFieldsForm));
