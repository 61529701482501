import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoVentes, getNeoVentes, getNeoVentesAll,getNeoVentesAllXStaus, getClientSearch, 
	getNeoVentesAllXStausx2, getNeoVentesGoto, deleteNeoVentes, getOrSetMultiActionStatus, 
	getClientAndAddress, getClientPaymentsFullInfo, getGenAndCheckTicket } from '../../actions/NeoVentesAction'; 
import { withTranslation } from 'react-i18next';
//import { addOrUpdateNeoVentes, getNeoVentes, getClientSearch, getB2BClientSearch, getClientAddress, getCodePromo, getNeoVentesPromos, getProductInfo, getPartialInvoicePayments, getAllProducts, getChargeDeskStatus, getClientTransactionsFullInfo, getClientAndAddress, getClientAndAddressByLead } from '../../actions/NeoVentesAction'; 


import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Close from "@material-ui/icons/Close";
import ZAFClient from 'zendesk_app_framework_sdk';
import SearchIcon from '@material-ui/icons/Search';


import Autocomplete from '@material-ui/lab/Autocomplete'; 
import TextField from '@material-ui/core/TextField'; 
import MUIDataTable from "mui-datatables";
import CustomToolbarSelectvente from "../CustomToolbarSelectventeconfpta";
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import Toastr from "../../messages";
import  Loader from "../../loader";
//import NdiInterestConflict from './NdiInterestConflict'; 
import { getSearchConflict, setSearchConflict, getNeoSearchByTicket } from '../../actions/NeoSearchAction'; 
//import NdiFieldsMiniBoardzendesk from '../../srcndi/components/ndifields/NdiFieldsMiniBoardzendesk';
//import NeoB2bleadProspectsForm  from "../neob2bleadprospects/NeoB2bleadProspectsForm"; 
//import SalesFlowForm from './SalesFlowForm'; 
//import NeoVentesForm from './NeoVentesForm';
import { encryptText, decryptText } from '../../appEncryption';
//import NdiFieldsFormzendesk from './components/ndifields/NdiFieldsFormzendesk'; 
import NdiFieldsMiniBoardzendesk from '../ndifields/NdiFieldsMiniBoardzendesk'; 

const NeoB2bleadProspectsForm={};
const NdiInterestConflict={};
const NeoVentesForm={};
const SalesFlowForm={};

class NeoVentesBasicPostActionsta extends Component {
	actiongo="";
	ticketListobj=[];
	tabCounter=1; // 1 7    
	constructor(props) {
		super(props); 
		this.state={isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
		statusChargeDesk: 0, chargeDeskIdStr:"", invoiceId:0, info_otheresp:"", 
		openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}, possibleListOfCDStatusInfo:[], 
		openNeoSearch:false, openNeoSearchlist:false, neoSearchList:[], 
		client_id:'', ticket_id:'',
		neoemail:'',  currentView:1,  
		openSaleflow:false, listOfTicketsPayments:{},



		productCart:  [], 
		idClient:'0',
		firstname:'', e_firstname: false,
		lastname:'', e_lastname: false,
		cell:'', e_cell: false,
		terms:'', e_terms: false,
		active:'', e_active: false,
		created:'', e_created: false,
		email:'', e_email: false,
		id_users:'0', e_id_users: false, 
		language:'', e_language: false,  
		push:'', e_push: false,
		id_zendesk:'', e_id_zendesk: false,
		sms:"1", e_sms: false,
		stripe_id:'', e_stripe_id: false,
		occupation:'', e_occupation: false, 
	
		idAddress:0, 
		address:'', e_address: false,
		unitsuite:'', e_unitsuite: false,
		city:'', e_city: false,
		province:'QC', e_province: false, province2:'', 
		cp:'', e_cp: false,
		country:'Canada', e_country: false,
		company:'', e_company: false, 
		openB2bleadProspect:false,
		indexRowChecked:'', ticketlistall:"", transaction:"", 
		openCheckingTicket:false, upsellof:"", 
		errorTransacMessage:"", ticketListRec:{}, 
		openNeoSaleFormPopup:false, 
		NeoVentes:{data:[]},  fieldName:"", fieldVal:"",
		fieldName0:"", fieldVal0:"",

		zendchangeCounter:0, activeTab:0, 
		tabList : [
			//{key:5, id:5, ticket:"1015173"},{key:6, id:6, ticket:"908888"}
		], 
		
		
		listOfSuggestion:[],
		valueClientSearch:'', 
		inputValueClientSearch:'',
		selectedIdSuggestion:'',
		clientInfo:{ }, 
		clientAddress:{ },
		globalStepWorkflow: 1, // 1 init 2:start with product 3:start with customerInfo 4:payment 5: case close 
		workflowType: 1, //1 begin, 2 with clientInfo, 3 with Prod  
		fieldTicketOpen:"", 

	};
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() { 
		const { t } = this.props;
		//this.props.getNeoVentesAll();
		//this.props.getNeoVentesAllXStaus("payed"); this.props.getNeoVentesAllXStaus("payed,paid-b2b"))
		
		/*this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		//const statusXObj={statusList:"payed,paid-b2b", conflict:1};
		const statusXObj={statusList:"payed,paid-b2b", conflict:2};
		Promise.resolve(this.props.getNeoVentesAllXStausx2(statusXObj))
        .then((response)=>{ 
			console.log("POSTACTION ", response);
		  this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: response.NeoVentes});
		  this.showToast(t("zqwCViewEl11_x"), "success");  
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          window.alert(error);
        });*/
	}

	//loadNextPage  const objList  = this.state.NeoVentes.data;
	loadNextPage = (event) => {
		const { t } = this.props;
		//this.setState({ [event.target.name]: event.target.value });
		const objBig  = this.state.NeoVentes;
		if(objBig!==null && objBig.currentPage!==undefined){}else{ return false; }
		const currentPage=parseInt(""+objBig.currentPage);
		const limit=objBig.limit;
		const maxpage=objBig.maxpage;
		const offset=objBig.offset;
		const records=objBig.records;
		const total=objBig.total;
		const fieldName=this.state.fieldName;
		const fieldVal=this.state.fieldVal;
		//limit: 5 maxpage: 5442 offset: 0 records: 5 total: "27208" 
		if(objBig!==null && objBig.isThereNextPage){
			if(objBig.isThereNextPage==="yes"){}else{
				this.showToast("Il n'a plus de page/ there is no more page.", "error");  
				return false;
			}
		}

		if((currentPage+1)>maxpage){ return false; }

		const page=(currentPage+1);
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		//const statusXObj={statusList:"payed,paid-b2b", conflict:1};
		const statusXObj={statusList:"payed,paid-b2b", conflict:2, page:page
		, fieldName:fieldName, fieldVal:fieldVal};
		Promise.resolve(this.props.getNeoVentesAllXStausx2(statusXObj))
        .then((response)=>{ 
			console.log("222-POSTACTION ", response);
			const dataAll= this.state.NeoVentes;
			let data={};// dataAll.data; //this.state.NeoVentes.data;
			//data.concat(response.NeoVentes.data);
			dataAll.data.push(...response.NeoVentes.data);
			//console.log("222-POSTACTION data ", data, response.NeoVentes.data);
			//dataAll.data=data;
			if(response.NeoVentes.data.length>0){
				dataAll.isThereNextPage="yes";
			}else{
				dataAll.isThereNextPage="no";
			}
			dataAll.currentPage=parseInt(response.NeoVentes.currentPage);
		  //this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: response.NeoVentes});
		  this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: dataAll});
		  this.showToast(t("zqwCViewEl11_x"), "success");  
		  //console.log("222-POSTACTION dataAll ", dataAll);
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          window.alert(error);
        });


	};

	loadNextPageRedata = (event) => {
		const { t } = this.props;
		//this.setState({ [event.target.name]: event.target.value });
		//const objBig  = this.state.NeoVentes;
		 const fieldName=this.state.fieldName;
		 const fieldVal=this.state.fieldVal;
		// //limit: 5 maxpage: 5442 offset: 0 records: 5 total: "27208" 
		// if((currentPage+1)>maxpage){ return false; }
		// const page=(currentPage+1);
		if(fieldName===null || fieldName==="" || fieldVal===null || fieldVal===""){
			this.showToast("Erreur Champs et Valeur/ Error Field and value", "error");  
			return false;
		}

		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		//const statusXObj={statusList:"payed,paid-b2b", conflict:1};
		const statusXObj={statusList:"payed,paid-b2b", conflict:2, page:1
		, fieldName:fieldName, fieldVal:fieldVal};
		Promise.resolve(this.props.getNeoVentesAllXStausx2(statusXObj))
        .then((response)=>{ 
			//console.log("222-POSTACTION ", response);
		  this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: response.NeoVentes});
		  //this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: dataAll});
		  this.showToast(t("zqwCViewEl11_x"), "success");  
		  //console.log("222-POSTACTION dataAll ", dataAll);
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          window.alert(error);
        });


	};

	loadNextPageReset = (event) => {
		const { t } = this.props;
		//this.setState({ [event.target.name]: event.target.value });
		//const objBig  = this.state.NeoVentes;
		 const fieldName=this.state.fieldName;
		 const fieldVal=this.state.fieldVal;
		// //limit: 5 maxpage: 5442 offset: 0 records: 5 total: "27208" 
		// if((currentPage+1)>maxpage){ return false; }
		// const page=(currentPage+1);

		//this.setState({ ["isLoaderOpen"]: true, ["fieldName"]: "", ["fieldVal"]: "",});
		this.setState({ ["isLoaderOpen"]: true, ["fieldName"]: "", ["fieldVal"]: "",["fieldName0"]: "", ["fieldVal0"]: "",});
		

		//this.setState({ ["fieldName"]: "", ["fieldVal"]: "",});  
		const this2=this; 
		//const statusXObj={statusList:"payed,paid-b2b", conflict:1};
		const statusXObj={statusList:"payed,paid-b2b", conflict:2, page:1
		, fieldName:"", fieldVal:""};
		Promise.resolve(this.props.getNeoVentesAllXStausx2(statusXObj))
        .then((response)=>{ 
			//console.log("222-POSTACTION ", response);
		  this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: response.NeoVentes});
		  //this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: dataAll});
		  this.showToast(t("zqwCViewEl11_x"), "success");  
		  //console.log("222-POSTACTION dataAll ", dataAll);
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          window.alert(error);
        });


	};

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		if(event.target.name==="fieldName0"){
			this.setState({ ["fieldName"]: event.target.value });
		}
		else if(event.target.name==="fieldVal0"){
			this.setState({ ["fieldVal"]: event.target.value });
		}

	  };
	  
	handleFocusout = (event) => {
		//   if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		// 	  this.setState({ ["e_"+event.target.name]: false });
		// 	  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
		// 		  this.setState({ ["e_"+event.target.name]: true }); 	
		// 	  }
		//   } 	
	};

	setTicketlistChanged = (event) => {
		//const trans=event.currentTarget.dataset.transaction;
		const trans=event.target[event.target.selectedIndex].getAttribute('data-transaction');
		this.setState({ [event.target.name]: event.target.value, ["transaction"]: ""+trans });
		console.log("hello tr ", trans);
	};

	//name="ticketlistall" id="ticketlistall" onChange={this.setTicketlistChanged}

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, toastrDuration);
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.state.NeoVentes.data;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoVentes(gridIds[0]);

	}

  updateXRecordsMain(selectedRows, indexName){  
    const { t } = this.props;
	const objList  = this.state.NeoVentes.data;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      //gridIndexes.push(row.index); //current in filter
	  gridIndexes.push(row.dataIndex); //global 
      //idIndex=row.index; 
	  idIndex=row.dataIndex; 
	return null;
    });

	console.log("Teeeeeeeeeeeeeeeeeeeeeeest "+idIndex+"   indexName="+indexName, selectedRows);  
 
    if(gridIndexes.length>1){
      alert(t("zqwCViewEl12_x")); //alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
	let obj=objList[idIndex]; 
	const invoiceId=obj.id;
	//this.props.getNeoVentesGoto(obj.id); 
	console.log("oooooooooooooooooooooooh "+obj.id+"   indexName="+indexName); 
	console.log(obj);  //return false; 
	//history.push(`./neoventes/${obj.id}/edit`);
	this.actiongo=indexName;
	const client_id=obj.id_clients;
	let ticket_id=obj.ticket_id;  
	const objAction={invoiceId:obj.id,  actiongo: indexName, };
	const email=obj.email;
	//indexRowChecked:'', ticketlistall:"", transaction:"",
	const indexRowCheckedx=this.state.indexRowChecked;  
	const ticketlistallx=this.state.ticketlistall;  
	const transactionx=this.state.transaction; 
	if(indexRowCheckedx!==null && indexRowCheckedx!==""){
		//ticket_id=ticketlistallx;  
		if(ticketlistallx!==null && ticketlistallx!==""){
			ticket_id=ticketlistallx;  
		} 
	} 
	 
	//this.setState({ ["isLoaderOpen"]: true, ["client_id"]: client_id,["ticket_id"]: ticket_id,});  
	const this2=this;  
	if(indexName==="mkconflict"){ 
		console.log("ooooooooooopen search conflict") ;
		this.setState({["client_id"]: client_id,["ticket_id"]: ticket_id,  ["openNeoSearch"]: true, ["currentView"]:1,}); 

		return false;  
	}else if(indexName==="ndi"){ 
		console.log("ooooooooooopen NDI neooooooooolegal") ;
		this.setState({["client_id"]: client_id, ["ticket_id"]: ticket_id,  
		["openNeoSearch"]: false, ["currentView"]:2, ["neoemail"]: email,}); 

		return false;  
	}else if(indexName==="rendezvous"){ 
		console.log("ooooooooooopen rendez-vous neooooooooolegal") ;
 			   
      	const serachObj={clientId:client_id, ticketId:ticket_id};

		// this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""});  
		Promise.resolve(this.props.getClientPaymentsFullInfo(serachObj))
		.then((response)=>{ 
			console.log('getClientTransactionList  '); console.log(response); 
			this.setState({ ["listOfTicketsPayments"]: response.NeoVentesOne, }); 
			//this.setState({ ["isLoaderOpen"]: false}); 
			// this.setState({ ["listOfTicketsPayments"]: response.NeoVentesOne, ["isLoaderOpen"]: false });  
			return response;
		}).catch(function(error){ 
			const errObj=error;
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
			errMessage +="\n There was some error from the expected data from the server. Please try again later."
			}
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			
			console.log("ERROR WITH TRANSACTION"); 
			console.log(error);
			console.log("SHORT ERROR MESSAGE: "+errMessage);
			console.log("RWA RESPONSE");  
		});

			// this.getClientFullAddress(id_clients);
			Promise.resolve(this.props.getClientAndAddress(client_id))
			.then((response)=>{  
				const obj=response.NeoAddressOne; 
				// console.log("getClientAndAddress");
				// console.log(obj); 
				//this.setState({ ["clientInfo"]: newValue}); 
				const newValue=obj.client; 
				const address=obj.address;  
				const clientInfo={  
				  id_clients:newValue.id, 
				  idClient:newValue.id, 
				  firstname:newValue.firstname, 
				  lastname:newValue.lastname, 
				  cell:newValue.cell, 
				  terms:newValue.terms, 
				  active:newValue.active,  
				  email:newValue.email, 
				  id_users:parseInt(newValue.id_users), 
				  language:newValue.language, 
				  push:newValue.push, 
				  id_zendesk:newValue.id_zendesk, 
				  sms:newValue.sms,
				  stripe_id:newValue.stripe_id,  
				  occupation:newValue.occupation, 
		
				};
				const clientAddress={
				  idAddress:address.id, 
				  address:address.address, 
				  city:address.city, 
				  province:address.province, 
				  cp:address.cp, 
				  country:address.country, 
				  company:address.company,  
				}; 
				this.setState(clientInfo ); //xaza  this.setState({ clientInfo });
				this.setState( clientAddress ); //this.setState({ clientAddress });  		  
	
			  return response;
			}); 

	
		   

		this.setState({["client_id"]: client_id, ["ticket_id"]: ticket_id,  
		["openNeoSearch"]: false, ["currentView"]:3, ["neoemail"]: email,}); 

		return false;  
	}else if(indexName==="saleflow"){ 
		console.log("ooooooooooopen NeoSalesFlowForm neooooooooolegal") ;
		// this.setState({["client_id"]: client_id, ["ticket_id"]: ticket_id,  
		// ["openNeoSearch"]: false, ["currentView"]:3, ["neoemail"]: email,}); 
		this.setState({["client_id"]: client_id,["ticket_id"]: ticket_id,  
		["currentView"]:1, ["invoiceId"]:invoiceId, ["openSaleflow"]: true, }); 

		return false;  
	}  
	else if(indexName==="lead2Action"){ 
		console.log("ooooooooooopen lead2Action neooooooooolegal") ;
		// this.setState({["client_id"]: client_id, ["ticket_id"]: ticket_id,  
		// ["openNeoSearch"]: false, ["currentView"]:3, ["neoemail"]: email,}); 
		this.setState({["client_id"]: client_id,["ticket_id"]: ticket_id,  
		["currentView"]:1, ["invoiceId"]:invoiceId, ["openB2bleadProspect"]: true, }); 

		return false;  
	}  

	


	

	this.setState({ ["isLoaderOpen"]: true,});  
	///search/searchconflicts
	//Promise.resolve(this.props.getOrSetMultiActionStatus(objAction))
	Promise.resolve(this.props.getNeoSearchByTicket(ticket_id))
    .then((response)=>{
		if(indexName==="conflict"){
			const infox=response.NeoSearch; 
			this.setState({ ["isLoaderOpen"]: false, ["openNeoSearchlist"]: true, ["neoSearchList"]: infox, }); 
		}else{
			const info=response.NeoVentesOne; //JSON.parse(response); // response.NeoVentesOne; 			
			//console.log("reeeeeeeeeeeeeeeeeeeeeeeesp info"); console.log(info);  
			//console.log("reeeeeeeeeeeeeeeeeeeeeeeesp info"); console.log(info.otheresp);  
			this.setState({ ["isLoaderOpen"]: false, ["chargeDeskIdStr"]: info.messageAction, 
			["openPossibleCDStatusInfo"]: true, ["invoiceId"]: invoiceId,
			["info_otheresp"]: info.otheresp, }); 
		}
		 this.showToast(t("zqwCViewEl11_x"), "success"); //chargeDeskIdStr 
      return response;
    }).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		alert(error);
	});
  }

  checkMissingTickets = (event) => {
	//this.setState({ ["openNeoSearch"]: false, ["currentView"]:1, }); 
	//this.ticketListobj=obj;
	const indexRowChecked=this.state.indexRowChecked;  
	const ticketlistallx=this.state.ticketlistall;
	//const saleId= this.ticketListobj["id"]; 
	const saleId= this.state.ticketListRec["id"]; 
	const this00=this; 
	//const client_id=this.ticketListobj["id_clients"]; //id_clients
	const client_id=this.state.ticketListRec["id_clients"]; 
	const confIndex=this.state.confIndex;
	console.log("**************ticketList-checkMissingTickets****", saleId, this.ticketListobj);
	// const transactionx=this.state.transaction; 
	// if(indexRowChecked!==null && indexRowChecked!==""){
	// 	//ticket_id=ticketlistallx;  
	// 	if(ticketlistallx!==null && ticketlistallx!==""){
	// 		//ticket_id=ticketlistallx;  
	// 	} 
	// } 
	
	if(confIndex===indexRowChecked){
		this.setState({ ["openCheckingTicket"]:true, }); 
		return false;
	}
	if(parseInt(saleId)>0){}else{ 
		this.showToast("Cochez une facture", "error");  //t("zqwCViewEl11_x")
		return false;
	}

	this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]:""});  
	/*
	///search/searchconflicts
	//Promise.resolve(this.props.getOrSetMultiActionStatus(objAction))
	Promise.resolve(this.props.getNeoSearchByTicket(saleId))
    .then((response)=>{
		if(indexName==="conflict"){
			const infox=response.NeoSearch; 
			this.setState({ ["isLoaderOpen"]: false, ["openNeoSearchlist"]: true, ["neoSearchList"]: infox, }); 
		}else{
			const info=response.NeoVentesOne; //JSON.parse(response); // response.NeoVentesOne; 			
			//console.log("reeeeeeeeeeeeeeeeeeeeeeeesp info"); console.log(info);  
			//console.log("reeeeeeeeeeeeeeeeeeeeeeeesp info"); console.log(info.otheresp);  
			this.setState({ ["isLoaderOpen"]: false, ["chargeDeskIdStr"]: info.messageAction, 
			["openPossibleCDStatusInfo"]: true, ["invoiceId"]: invoiceId,
			["info_otheresp"]: info.otheresp, }); 
		}
		 this.showToast(t("zqwCViewEl11_x"), "success"); //chargeDeskIdStr 
      return response;
    }).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		alert(error);
	});*/

	const serachObj={clientId:client_id, ticketId:0, saleId:saleId};

		// this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""});  
		Promise.resolve(this.props.getClientPaymentsFullInfo(serachObj))
		.then((response)=>{ 
			console.log('*******getClientTransactionList  '); console.log(response); 
			this.setState({ ["listOfTicketsPayments"]: response.NeoVentesOne, ["confIndex"]: indexRowChecked, }); 
			this.setState({ ["isLoaderOpen"]: false, ["openCheckingTicket"]:true, }); 
			// this.setState({ ["listOfTicketsPayments"]: response.NeoVentesOne, ["isLoaderOpen"]: false });  
			
			// //listOfTicketsPayments  transactions by id_invoices
			// const transx=response.gohistory.transactions;
			// this.setState({ ["isLoaderOpen"]: false}); 
			
			return response;
		}).catch(function(error){ 
			const errObj=error;
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
			errMessage +="\n There was some error from the expected data from the server. Please try again later."
			}
			this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			
			console.log("ERROR WITH TRANSACTION"); 
			console.log(error);
			console.log("SHORT ERROR MESSAGE: "+errMessage);
			console.log("RWA RESPONSE");  
		});

	//return false;  
  }

  
  getGenAndCheckTicket = (event) => {
	//this.setState({ ["openNeoSearch"]: false, ["currentView"]:1, }); 
	//this.ticketListobj=obj;
	const indexRowChecked=this.state.indexRowChecked;  
	const ticketlistallx=this.state.ticketlistall;
	//const saleId= this.ticketListobj["id"];
	const saleId= this.state.ticketListRec["id"];  
	const this00=this; 
	//const client_id=this.ticketListobj["id_clients"]; //id_clients
	const confIndex=this.state.confIndex;
	const transId=event.currentTarget.dataset.transaction; 
	const isupsell=event.currentTarget.dataset.isupsell; 
	const upsellof=this.state.upsellof;
	console.log("**************ticketList-getGenAndCheckTicket****", saleId, transId, this.ticketListobj);
	// const transactionx=this.state.transaction; 
	// if(indexRowChecked!==null && indexRowChecked!==""){
	// 	//ticket_id=ticketlistallx;  
	// 	if(ticketlistallx!==null && ticketlistallx!==""){
	// 		//ticket_id=ticketlistallx;  
	// 	} 
	// } 
	
	// if(confIndex===indexRowChecked){
	// 	this.setState({ ["openCheckingTicket"]:true, }); 
	// 	return false;
	// }
	this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: "",});  
	
	const serachObj={transactionId:transId, ticketId:0, invoiceId:saleId, 
		genTicket:"yes", upsellof:upsellof};


	// $genTicket=$data["genTicket"] ?? "";
  //       $invoiceId=$data["invoiceId"] ?? 0;
  //       $transactionId=$data["transactionId"] ?? 0;


		// this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""});  
		Promise.resolve(this.props.getGenAndCheckTicket(serachObj))
		.then((response)=>{ 
			console.log('*******getGenAndCheckTicket  '); console.log(response); 
			//this.setState({ ["listOfTicketsPayments"]: response.NeoVentesOne, ["confIndex"]: indexRowChecked, }); 
			this.setState({ ["ticketListRec"]: response.NeoVentesOne.saleticketList, 
			["isLoaderOpen"]: false, }); //["openCheckingTicket"]:true,
			// this.setState({ ["listOfTicketsPayments"]: response.NeoVentesOne, ["isLoaderOpen"]: false });  
			
			// //listOfTicketsPayments  transactions by id_invoices
			// const transx=response.gohistory.transactions;
			// this.setState({ ["isLoaderOpen"]: false}); 
			
			return response;
		}).catch(function(error){ 
			const errObj=error;
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
			errMessage +="\n There was some error from the expected data from the server. Please try again later."
			}
			this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			
			console.log("ERROR WITH TRANSACTION"); 
			console.log(error);
			console.log("SHORT ERROR MESSAGE: "+errMessage);
			console.log("RWA RESPONSE"); 
				  
		});

	//return false;  
  }


  
  openTicketSale = (event) => {
	//this.setState({ ["openNeoSearch"]: false, ["currentView"]:1, }); 
	const ticketID=this.state.ticketlistall;
	//const saleId= this.ticketListobj["id"]; 
	const saleId= this.state.ticketListRec["id"]; 
	if(parseInt(ticketID)>0 && parseInt(saleId)>0){}else{ 
		this.showToast("Cochez et choisir un Ticket & facture", "error");  //t("zqwCViewEl11_x")
		return false;
	}

	//this.props.history.push(`/neoventes/${saleId}/${ticketID}/onSaleClose`);
	this.setState({ ["openNeoSaleFormPopup"]: true, }); 
	//return false;  
  }

  setToReturn = () => {
	this.setState({ ["openNeoSearch"]: false, ["currentView"]:1, }); 

	return false;  
  }

  

  render() { 
    const { t } = this.props;
	const NeoSearchResultHistory=this.NeoSearchResultHistory;
	const NeoSearchActions=this.NeoSearchActions; 
	const NeoSalesFlowForm=this.NeoSalesFlowForm;
	const NeoB2bleadProspectsFormx=this.NeoB2bleadProspectsFormx;
	const NeoSalesCheckingTicketsForm=this.NeoSalesCheckingTicketsForm;
	const NeoSaleFormPopup=this.NeoSaleFormPopup; 

	//const backTicketId=433332; neoemail backTicketId
    //const neoemail="sidbenac_zx23@gmail.com";
	const backTicketId=this.state.ticket_id; //backTicketId;
    const neoemail=this.state.neoemail; 
	const currentView=this.state.currentView; 
	const indexRowChecked=this.state.indexRowChecked;
	 
	const columns002 = [
		{
		 name: "id", label: t('zqwid_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "fullName", label: t('zqwid_clients_x'),
		 options: { filter: true, sort: true,}
		},
		{
			name: "status", label: t('zqwstatus_x'),
			options: { filter: true, sort: true,}
		},
		{
		 name: "due_date", label: t('zqwdue_date_x'),
		 options: { filter: true, sort: true,}
		},  
		{
			name: "vendor", label: t('zqwCViewEl14_x'),
			options: { filter: true, sort: true,}
		},
		{
		 name: "total", label: t('zqwtotal_x'),
		 options: { filter: true, sort: true,}
		}, 
		{
		 name: "province", label: t('zqwprovince_x'),
		 options: { filter: true, sort: true,}
		},
		{
			name: "ticket_id", label: "Ticket",
			options: { filter: true, sort: true,}
		},
		{
			name: "searchconflict", label: t('zqwNDICItx2x3_x'), 
			options: { filter: true, sort: true,}
		},   
	   ];

	const columns = [
		// {
		//  name: "id", label: t('zqwid_x'),
		//  options: { filter: true, sort: true,}
		// },
		// {
		// 	name: "ticket_id", label: "Ticket",
		// 	options: { filter: true, sort: true,}
		// },
		{
			name: "ticket_id",
			options: {
				filter: true, sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					//console.log("customBodyRender ", value);				
				/*	if (value !== null && value !== ""){
						//console.log("tableMeta", tableMeta); // rowData  
						const rowIndex=tableMeta.rowIndex; // tableData   
						let dcode = this.state.pendingList[rowIndex].declined_code;
						//let dcode = tableMeta.tableData[rowIndex].declined_code;
	
						if (value === "flexiti" && dcode!==null && dcode!=="")
							return (
								<span>{value+'/'+dcode}</span>
							);
						else
							return (
								<span>{value}</span>
							);
					  
					}else{
						return (
							<span>{value}</span>
						);
					}*/

					//{t("zqwCTbViewEl1_x")}  

					return (
						<button type="button" className="btn btn-primary" 
						data-tickettab={value} data-emailtab={""} 
						onClick={this.handleOpenTicketTab}>#{value}</button>
					);
	
				}
			//   customBodyRender: (value, tableMeta, updateValue) => (
			// 	<div>{value.join(",")}</div>
			//   )
			}
		},
		{
		 name: "fullName", label: t('zqwid_clients_x'),
		 options: { filter: true, sort: true,}
		},
		{
			name: "status", label: t('zqwstatus_x'),
			options: { filter: true, sort: true,}
		},
		{
		 name: "due_date", label: t('zqwdue_date_x'),
		 options: { filter: true, sort: true,}
		},
		{
			name: "proddescription", label: t('zqwproduit_x'),
			options: { filter: true, sort: true,}
		}, 
		{
			name: "vendor", label: t('zqwCViewEl14_x'),
			options: { filter: true, sort: true,}
		},
		{
		 name: "total", label: t('zqwtotal_x'),
		 options: { filter: true, sort: true,}
		}, 
		{
		 name: "province", label: t('zqwprovince_x'),
		 options: { filter: true, sort: true,}
		},
		{
			name: "idInvoice", label: t('zqwTNVFtxt8_x'), 
			options: { filter: true, sort: true,}
		},
		// {
		// 	name: "ticket_id", label: "Ticket",
		// 	options: { filter: true, sort: true,}
		// },
		{
			name: "id", label: t('zqwid_x'),
			options: { filter: true, sort: true,}
		},
		// {
		// 	name: "searchconflict", label: t('zqwNDICItx2x3_x'), 
		// 	options: { filter: true, sort: true,}
		// },   
	   ];

	//    <button type="button" className="btn btn-warning" 
	// 		  data-tickettab={ticketlistallx} data-emailtab={ticketlistallx} 
	// 		  onClick={this.handleOpenTicketTab}>{t("zqwCTbViewEl1_x")} #{ticketlistallx}</button>

const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      //downloadCsv: t('zqwgriddownloadCsv_x'),
      //print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  download:false, print: false,  
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		console.log("onRowsSelect");
		//console.log(currentRowsSelected, allRowsSelected);  {  "index": 5, "dataIndex": 10 }
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log("Selected", allRowsSelected);
		if(allRowsSelected.length==1){
			this.setState({ ["indexRowChecked"]: ""+allRowsSelected[0]["dataIndex"]});

			const objListSales  = this.state.NeoVentes.data;
			let obj=objListSales[allRowsSelected[0]["dataIndex"]]; 
			this.ticketListobj=obj;
			this.setState({ ["ticketListRec"]:obj, ["ticketlistall"]:""});  
			// if(obj.ticketList.length<=1 && parseInt(obj.ticket_id)>0){
			// 	this.setState({ ["ticketlistall"]:obj.ticket_id});
			// }

			if(parseInt(obj.ticket_id)>0){
				this.setState({ ["ticketlistall"]:obj.ticket_id});
			}
	
			console.log("Selected dt=", allRowsSelected[0]["dataIndex"], obj);  
		}else{
			//this.setState({ ["indexRowChecked"]: "", });  
			this.setState({ ["indexRowChecked"]: "", ["ticketListRec"]:{}, ["ticketlistall"]:""});  
		}
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelectvente selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} 
		isEdit={true} isDelete={true} t={this.props.t} />
      ),
	}; 

	//indexRowChecked-------
	let ticketList=[];  //let ticketListobj=[];  objListSales.ticketList
	//const objListSales  = this.props.NeoVentes;
	const objListSales =this.state.ticketListRec;
	/*if(indexRowChecked!==""){
		//let obj=objListSales[indexRowChecked]; 
		let obj=objListSales; 
		//this.ticketListobj=obj;
		console.log("**************ticketList****", obj);
		if(obj!==undefined && obj!==null && obj.ticketList!==undefined && obj.ticketList.length>0){
			//ticketList.push();
			//this.ticketListobj=obj;
			//"ta": 1009626, "description": "(tr#40909) Contest a Ticket of 1 to 3 Points"
			let ky=0;
			for(let ticketobj of obj.ticketList){
				ticketList.push(<option key={"or"+ky} data-transaction={""+ticketobj.tr} 
				value={""+ticketobj.ta}>{"TA "+ticketobj.ta+" "+ticketobj.description}</option>);
				ky++;
			}

			// let productList = productListx.length > 0
			// 	&& productListx.map((item, i) => {
			// 		//productOptions.push({ value: item.id, label: item.titre }); 
			// 		//console.log(item);
			// 		if(item!==null){
			// 		productsList.push(item); 
			// 		}
			// 	return (
			// 		null
			// 	);
			// 	}, this);
		}

		if(obj!==undefined && obj!==null && obj.ticketList!==undefined && obj.ticketList.length<1){
			if(obj.upsale_ticket===null && obj.ticket_id===null){}
		}
	}*/

	const Alert=this.Alert;
	//const indexRowCheckedx=this.state.indexRowChecked;  
	 const ticketlistallx=this.state.ticketlistall;  
	// const transactionx=this.state.transaction; 
	// if(indexRowChecked!==null && indexRowChecked!==""){
	// 	//ticket_id=ticketlistallx;  
	// 	if(ticketlistallx!==null && ticketlistallx!==""){
	// 		//ticket_id=ticketlistallx;  
	// 	} 
	// } 
	//////////////////////////////////////////////////////////////////////////////
	
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	const CheckOrSetActionsOnInvoice=this.CheckOrSetActionsOnInvoice;

	//indexRowChecked:'', ticketlistall:"", transaction:"",
	//console.log("state.NeoVentes", this.state.NeoVentes);
	//fieldName:"", fieldVal:"" 
	//invId invTicket invCliId invEmail invPhone    

	const value=this.state.activeTab;
	const TabPanel = this.TabPanel;
	const handleChange=this.handleChangetab;
	const AutocompleteClient=this.AutocompleteClient;

	console.log("###################### ta=", ticketlistallx, " indexData=", indexRowChecked);

	return (
		<div>
			<div className="row">  
			<div className="neox50"></div>    		  
    	<div className="neox50">
		{/* <div className="form-group">
          <div className="md-6"  key={"dv91000aaaa3"}>             
			 cax
          </div> 
          <div className="md-6 floatr" >  
			<input name="fieldTicketOpen" id="fieldTicketOpen" type="number" className="form-control floatrx"
			onChange={this.handleChange} onKeyDown={this.handleTicketTabLaunch0}
			value={this.state.fieldTicketOpen} placeholder="# ticket" />
			<button type="button" className="btn btn-primary floatrx" onClick={this.handleTicketTabLaunch}>
				<SearchIcon /> 
				</button> 
          </div>       
        </div> */}

		
		<div className="form-group" > 
                <div className="input-group">
				<input name="fieldTicketOpen" id="fieldTicketOpen" type="number" className="form-control floatrx"
			onChange={this.handleChange} onKeyDown={this.handleTicketTabLaunch0}
			value={this.state.fieldTicketOpen} placeholder="# ticket" />
			<button type="button" className="btn btn-primary floatrx" onClick={this.handleTicketTabLaunch}>
				<SearchIcon /> 
				</button> 
		</div></div>
		</div></div>

			<AppBar position="static" color="default">
        {/* {fulltabs===true?  */}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {/* <Tab label={t("zqwTabsummary_x")} {...this.a11yProps(0)} /> */}
		  <Tab label={"...Client"} {...this.a11yProps(0)} />
          {/* <Tab label={t("zqwTabinput_x")}  {...this.a11yProps(1)} /> 
          <Tab label={t("zqwTabnouveau_x")}  {...this.a11yProps(2)} /> 
          <Tab label={t("zqwTabAjoutProd_x")}  {...this.a11yProps(3)} /> 
          <Tab label={t("zqwTabDocument_x")}  {...this.a11yProps(4)} /> */}

		  {
			this.state.tabList.map((tab)=>(
						<Tab
							key={tab.key.toString()}
							value={tab.id}
							label={"TA "+tab.ticket}
							icon={
								<Close 
									id={tab.id}
									onClick={
										this.deleteTab
									}
								/>
							}
							// className="mytab"
							{...this.a11yProps(tab.id)}
						/>
					))
			}

          {/* <Tab label={whyNeolegal}  {...this.a11yProps(1)} />
          <Tab label="Other 3" {...this.a11yProps(2)} />
          <Tab label="Other 4" {...this.a11yProps(3)} />  */}
        </Tabs>

		{/* :
        <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label={t("zqwTabsummary_x")} {...this.a11yProps(0)} />
        <Tab label={t("zqwTabinput_x")}  {...this.a11yProps(1)} /> 
        * <Tab label={t("zqwTabnouveau_x")}  {...this.a11yProps(2)} /> 
        <Tab label={t("zqwTabAjoutProd_x")}  {...this.a11yProps(3)} /> 
        <Tab label={t("zqwTabDocument_x")}  {...this.a11yProps(4)} />  *
         
      </Tabs> */}
        {/* } */}
      </AppBar>


	  <TabPanel value={value} index={0}  className="iamneombz2 neospxSum">
		{/* <p className = "font-16"><strong>OTHER TAB #1: </strong> </p> */}
		<div className="row">      		  
    	<div className="neox50">
		<div className="form-group">
          <div className="md-6"  key={"dv91000000"}>
             <AutocompleteClient key={91000} propstep={1} /> 
          </div> 
          <div className="md-4">
            {/* <AutocompleteClientB2B key={91000} propstep={1} />  */}
			{/* aaaaaa bcx */}
			&nbsp; 
          </div> 
          {/* <div className="md-3">
            <button type="button" className="btn btn-primary" onClick={this.resetToNewCustomer}>{t("zqwNVFrmEl42_x")}</button> 
          </div>  */}
 
        </div>
		</div></div>
		<div   key={"dv91000002"}>			
			{/* <h4>{t('zqwCViewEl55_x')}</h4> */}
			{/* <span>conflits, processus après vente,</span>  &nbsp;  */}

			{/* <select name="ticketlistall" id="ticketlistall" onChange={this.setTicketlistChanged}>
				<option value="">Choisir/Select Ticket</option>
				{ticketList}
			</select>  &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.checkMissingTickets}>CheckMissingTicket</button>  &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.openTicketSale}>OpenTicketSale</button>  &nbsp;  */}
			
			{/* <button type="button" className="btn btn-primary" onClick={this.loadNextPage}>LoadNextPage</button>   &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.loadNextPageReset}>Rafreshir/Refresh</button>   
			 */}

			<div style={{marginTop:'5px',marginBottom:'5px', padding:'5px',backgroundColor:'orange'}}>
				<span>{t('zqwNDIFilter_x')}</span>  &nbsp; 
				{/* <button type="button" className="btn btn-primary" onClick={this.loadNextPage}>LoadNextPage</button>   */}
				<select name="fieldName0" id="fieldName0" onChange={this.handleChange} value={this.state.fieldName0}>
					<option   key={"op10"} value="">Choisir/Select Champs/Field</option>
					<option key={"op11"} value="invId">#Facture/Invoice#</option>
					<option key={"op12"} value="invCliId">#Client/Client#</option>
					<option key={"op13"} value="invTicket">#Ticket/Ticket#</option>
					<option key={"op14"} value="invEmail">Email</option>
					<option key={"op15"} value="invPhone">#Telephone/Phone#</option>
				</select>   &nbsp; 
				<input name="fieldVal0" id="fieldVal0" onChange={this.handleChange} value={this.state.fieldVal0} />
				<input type='hidden' name="fieldName" id="fieldName" value={this.state.fieldName} />
				<input type='hidden' name="fieldVal" id="fieldVal" value={this.state.fieldVal} />
				&nbsp; 
				<button type="button" className="btn btn-primary" onClick={this.loadNextPageRedata}>{t('zqwCTbViewEl2_x')}</button>  
				&nbsp; 
				<button type="button" className="btn btn-primary" onClick={this.loadNextPage}>{t('zqwCTbViewEl3_x')}</button>   &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.loadNextPageReset}>{t('zqwCTbViewEl4_x')}</button>   
			
			</div>
			{(ticketlistallx!==null && ticketlistallx!=="" && indexRowChecked!==null && indexRowChecked!=="")?
			<><br/>
              <Alert severity="success">Ticket: #{ticketlistallx} &nbsp;
			  {/* <span style={{right:'20px', color:'#FFFFFF'}}>
			  <a href={"https://crm2.neolegal.ca/zendesk/"+ticketlistallx+"/ticket"}
			   className="btn btn-warning" 
			   target="_blank">{t("zqwCViewEl59_x")} #{ticketlistallx}</a>
			  </span> */}
			  <button type="button" className="btn btn-warning" 
			  data-tickettab={ticketlistallx} data-emailtab={ticketlistallx} 
			  onClick={this.handleOpenTicketTab}>{t("zqwCTbViewEl1_x")} #{ticketlistallx}</button>  
			  </Alert>
			</>:""
			}		  
			<MUIDataTable
				title={""}
				data={this.state.NeoVentes.data}
				columns={columns}
				options={options}
				/>
				{/* <Loader  openx={this.state.isLoaderOpen}  />
				<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />  */}

			</div>

      </TabPanel>


       {/* <TabPanel value={value} index={1}> 
         
          <p className = "font-16"><strong>OTHER TAB #2: </strong> </p>

          {/ <NdiFieldsFormzendesk clientAddress={clientAddress}  /> /}
           
      </TabPanel>
      <TabPanel value={value} index={2}>
         <p className = "font-16"><strong>OTHER TAB #3: </strong> </p>
          
         {/ <NeoVentesFormB2b currentTab={"tab2"} />  /}

      </TabPanel>
      <TabPanel value={value} index={3}>          
	  	<p className = "font-16"><strong>OTHER TAB #4: </strong> </p>

         <p className = "font-16"><strong>OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO </strong> </p>
         <p className = "font-16"><strong>OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO </strong> </p>
         <p className = "font-16"><strong>OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO </strong> </p> 

         {/ <NdiFieldsFormzendesk currentTab={"tab3"} clientAddress={clientAddress} />      /}
		 
      </TabPanel> 
      <TabPanel value={value} index={4}>          
		<p className = "font-16"><strong>OTHER TAB #5: </strong> </p>
		 
         {/ 77<NdiFieldsFormzendesk currentTab={"tab4"} clientAddress={clientAddress} /> /}
		 
      </TabPanel> */}


	  {
			this.state.tabList.map((tab)=>(
						// <Tab
						// 	key={tab.key.toString()}
						// 	value={tab.id}
						// 	label={"TA "+tab.ticket}
						// 	icon={
						// 		<Close 
						// 			id={tab.id}
						// 			onClick={
						// 				this.deleteTab
						// 			}
						// 		/>
						// 	}
						// 	// className="mytab"
						// 	{...this.a11yProps(tab.id)}
						// />
						<TabPanel value={value} index={tab.id} key={"ntb"+tab.ticket.toString()}>          
							{/* <p className = "font-16"><strong>XTAB #{tab.id}: </strong> </p>
							<p className = "font-16"><strong>TICKET #{tab.ticket}: </strong> </p> */}
							{/* <NdiFieldsFormzendesk currentTab={"tab4"} clientAddress={clientAddress} /> */}
							
							<NdiFieldsMiniBoardzendesk currentTabTicket={tab.ticket} currentTabEmail={""} /> 
						</TabPanel>
					))
			}

			<Loader  openx={this.state.isLoaderOpen}  />
			<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
		</div>
		);    

	
	if(this.state.NeoVentes.data.length) {                                
		return (
			<div>
			{/* <h4>{t('zqwmenuel2x2_x')}</h4> */}
			<h4>{t('zqwCViewEl55_x')}</h4>
			<span>conflits, processus après vente,</span>  &nbsp; 
			{/* <select name="ticketlistall" id="ticketlistall" onChange={this.setTicketlistChanged}>
				<option value="">Choisir/Select Ticket</option>
				{ticketList}
			</select>  &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.checkMissingTickets}>CheckMissingTicket</button>  &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.openTicketSale}>OpenTicketSale</button>  &nbsp;  */}
			<button type="button" className="btn btn-primary" onClick={this.loadNextPage}>LoadNextPage</button>   &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.loadNextPageReset}>Rafreshir/Refresh</button>   
			
			<div style={{marginTop:'5px',marginBottom:'5px', padding:'5px',backgroundColor:'orange'}}>
				<span>{t('zqwNDIFilter_x')}</span>  &nbsp; 
				{/* <button type="button" className="btn btn-primary" onClick={this.loadNextPage}>LoadNextPage</button>   */}
				<select name="fieldName" id="fieldName" onChange={this.handleChange} value={this.state.fieldName}>
					<option value="">Choisir/Select Champs/Field</option>
					<option value="invId">#Facture/Invoice</option>
					<option value="invCliId">#Client/Clint#</option>
					<option value="invTicket">#Ticket/Ticket#</option>
					<option value="invEmail">Email</option>
					<option value="invPhone">#Telephone/Phone#</option>
				</select>   &nbsp; 
				<input name="fieldVal" id="fieldVal" onChange={this.handleChange} value={this.state.fieldVal} />
				&nbsp; 
				<button type="button" className="btn btn-primary" onClick={this.loadNextPageRedata}>Charger/Load</button>  
			</div>
			{(ticketlistallx!==null && ticketlistallx!=="" && indexRowChecked!==null && indexRowChecked!=="")?
			<><br/>
              <Alert severity="success">Ticket: #{ticketlistallx} &nbsp;
			  <span style={{right:'20px', color:'#FFFFFF'}}>
			  <a href={"https://crm2.neolegal.ca/zendesk/"+ticketlistallx+"/ticket"}
			   className="btn btn-warning" 
			   target="_blank">{t("zqwCViewEl59_x")} #{ticketlistallx}</a>
			  </span>
			  </Alert>
			</>:""
			}		  
			<MUIDataTable
				title={""}
				data={this.state.NeoVentes.data}
				columns={columns}
				options={options}
				/>
				<Loader  openx={this.state.isLoaderOpen}  />
				<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
{/* 

				<CheckOrSetActionsOnInvoice  openx={this.state.isLoaderOpen}  />

				
				<NeoSearchResultHistory   />
				<NeoSearchActions   />
				<NeoSalesFlowForm   />
				<NeoB2bleadProspectsFormx   />
				<NeoSalesCheckingTicketsForm />
				<NeoSaleFormPopup /> */}
				
				 
				
				
			</div>
		)    
		} else {
		return (<div>{t('zqwgridNoRecordfound_x')} <Loader  openx={this.state.isLoaderOpen}  /></div>)
		}


	/*
	if(currentView===1) { 
		if(this.state.NeoVentes.data.length) {                                
		return (
			<div> 
			<h4>{t('zqwCViewEl55_x')}</h4>
			<span>conflits, processus après vente,</span>  &nbsp; 
			<select name="ticketlistall" id="ticketlistall" onChange={this.setTicketlistChanged}>
				<option value="">Choisir/Select Ticket</option>
				{ticketList}
			</select>  &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.checkMissingTickets}>CheckMissingTicket</button>  &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.openTicketSale}>OpenTicketSale</button>  &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.loadNextPage}>LoadNextPage</button>   &nbsp; 
			<button type="button" className="btn btn-primary" onClick={this.loadNextPageReset}>Rafreshir/Refresh</button>   
			
			<div style={{marginTop:'5px',marginBottom:'5px', padding:'5px',backgroundColor:'orange'}}>
				<span>Filtrer serveur/Server filtering</span>  &nbsp; 
				<select name="fieldName" id="fieldName" onChange={this.handleChange} value={this.state.fieldName}>
					<option value="">Choisir/Select Champs/Field</option>
					<option value="invId">#Facture/Invoice</option>
					<option value="invCliId">#Client/Clint#</option>
					<option value="invTicket">#Ticket/Ticket#</option>
					<option value="invEmail">Email</option>
					<option value="invPhone">#Telephone/Phone#</option>
				</select>   &nbsp; 
				<input name="fieldVal" id="fieldVal" onChange={this.handleChange} value={this.state.fieldVal} />
				&nbsp; 
				<button type="button" className="btn btn-primary" onClick={this.loadNextPageRedata}>Charger/Load</button>  
			</div>
			{(ticketlistallx!==null && ticketlistallx!=="" && indexRowChecked!==null && indexRowChecked!=="")?
			<><br/>
              <Alert severity="success">Ticket: #{ticketlistallx} &nbsp;
			  <span style={{right:'20px', color:'#FFFFFF'}}>
			  <a href={"https://crm2.neolegal.ca/zendesk/"+ticketlistallx+"/ticket"}
			   className="btn btn-warning" 
			   target="_blank">{t("zqwCViewEl59_x")} #{ticketlistallx}</a>
			  </span>
			  </Alert>
			</>:""
			}		  
			<MUIDataTable
				title={""}
				data={this.state.NeoVentes.data}
				columns={columns}
				options={options}
				/>
				<Loader  openx={this.state.isLoaderOpen}  />
				<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
				<CheckOrSetActionsOnInvoice  openx={this.state.isLoaderOpen}  />

				
				<NeoSearchResultHistory   />
				<NeoSearchActions   />
				<NeoSalesFlowForm   />
				<NeoB2bleadProspectsFormx   />
				<NeoSalesCheckingTicketsForm />
				<NeoSaleFormPopup />
				
				 
				
				
			</div>
		)    
		} else {
		return (<div>{t('zqwgridNoRecordfound_x')} <Loader  openx={this.state.isLoaderOpen}  /></div>)
		}
	}
	else if(currentView===2){
		return (<div> 
			<h4>{t('zqwCViewEl55_x')}</h4>
			<button type="button" className="btn btn-primary" onClick={this.setToReturn}>{t("zqwCViewEl56_x")}</button>
			
			<NdiFieldsMiniBoardzendesk backTicketId={backTicketId} neoemail={neoemail} /> 
			</div>)
	}else if(currentView===3){
		
		const listTiPays=this.state.listOfTicketsPayments;
		let transactions=[];
		//const transactions=listTiPays.transactions;
		if(listTiPays!==null && listTiPays.transactions!==null && listTiPays.transactions!==undefined){
			transactions=listTiPays.transactions;
		}


		const firstname = this.state.firstname;
		const lastname = this.state.lastname;
		const cell = this.state.cell;
		const email = this.state.email;
		const gender = ""; //this.state.gender;
		const language = this.state.language;
		const dob = ""; //this.state.dob;
		const source = ""; //this.state.source; 
		const current_insurances = ""; 
		const id_invoices = this.state.id_invoice;   
		const address = this.state.address;
		const unitsuite = this.state.unitsuite;
		const city = this.state.city;
		const province=this.state.province;
		const cp = this.state.cp;
		const country = this.state.country;
	
		const ticket_id= this.state.ticket_id;  
		const client_id=this.state.idClient; // client_id;   


		
		const backTicketId=this.state.ticket_id; //433332;
    const invoiceId=this.state.id_invoice; //4750;
    const neoemail=this.state.email; //"sidbenac_zx23@gmail.com";  //client_email
    const client_email=this.state.email; //"sidbenac@gmail.com";
    const neoClientId=this.state.id_zendesk; //413958800713;      
    // 15;
    // import { encryptText, decryptText } from './appEncryption';
    let profile=null; let id_zendeskProf="";
    if(localStorage.getItem("profile")!==undefined  && localStorage.getItem("profile")!==null){
      // profile=(decryptText(localStorage.getItem("profile"))).split(':');
      profile=(decryptText(localStorage.getItem("profile")));
      const rProfile=JSON.parse(profile);
      if(rProfile.id_zendesk!==undefined && rProfile.id_zendesk!==null){
        id_zendeskProf=rProfile.id_zendesk;
      } 
    }

    //const productCart=this.state.productCart;  
    let subjectOne=""; 

    // productCart.length > 0
    // 	&& productCart.map((item, i) => { 
    //     if(item.productTitre!=="" && subjectOne===""){   
    //       subjectOne=""+item.productTitre; 
    //     }
    // }, this); 

	transactions.length > 0
    	&& transactions.map((item, i) => { 
        if(item.description!=="" && subjectOne===""){   
          subjectOne=""+item.description; 
        }
    }, this); 

    // console.log("profile-profile :::::", profile, id_zendeskProf);
    // console.log("booking?ticket_id="+backTicketId+"&client_id="+neoClientId+"&id_zendesk="+id_zendeskAgent+"&client_email="+client_email+"&client_name="+client_name+"&subject="+subject+"&client_details="+details+"" ); 


    const id_zendeskAgent=id_zendeskProf; //26141155848;
    const client_name=firstname+" "+lastname; //"John 007 Doe"; 
    const subject=subjectOne; //"Envoyez une mise en demeure avec un avocat"; 
    //const details="Jon Doe \n416 Av De La Pommeraie, Terrebonne(QC) J6Y 1V6 Canada"; 
    const details=client_name+" \n"+address+", "+city+"("+province+") "+cp+" "+country;
		
		console.log("....booking?ticket_id="+backTicketId+"&client_id="+neoClientId+"&id_zendesk="+id_zendeskAgent+"&client_email="+client_email+"&client_name="+client_name+"&subject="+subject+"&client_details="+details+"" ); 


		return (<div> 
			<h4>{t('zqwNDICItbook2_x')}</h4>
			<button type="button" className="btn btn-primary" onClick={this.setToReturn}>{t("zqwCViewEl56_x")}</button>
			

			<div className="form-group"> 
				<iframe src={"https://admin.neolegal.ca/booking?ticket_id="+backTicketId+"&client_id="+neoClientId+"&id_zendesk=26141155848&client_email="+neoemail+"&client_name="+client_name+"&subject="+subject+"&client_details="+details+""} 
          			style={{width: "100%", minHeight: "700px", border:"0"}}></iframe>
		  </div>      

			</div>)
	}*/

	
	// <NdiFieldsMiniBoardzendesk backTicketId={backTicketId} neoemail={neoemail} /> 
  }
	

	CheckOrSetActionsOnInvoice = (event) => {  
		const open = this.state.openPossibleCDStatusInfo; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common");
		const listObj=this.state.possibleListOfCDStatusInfo;
		const info_otheresp=this.state.info_otheresp; 
		let maxWidth="sm"; //"sm";
		let isfullScreen=false;
		if(info_otheresp!==null && info_otheresp!=="" && this.actiongo==="viewemailinvoice"){
			maxWidth="lg";
			isfullScreen=true;
			//sessionStorage.setItem("tmpcontent", info_otheresp);
		}
	  
		return (
		  <div>
			<Dialog maxWidth={maxWidth} open={open} onClose={this.handleCloseListOfCDStatusInfo} disableBackdropClick disableEscapeKeyDown fullScreen={isfullScreen} aria-labelledby="form-dialog-confirmclient4">
			  <DialogContent id="form-dialog-confirmclient4">
				
			  <div className="text-center"><h2>{t("zqwCViewEl16_x")}</h2></div> 
				<p>{t("zqwCViewEl17_x")} # {this.state.invoiceId} </p> 
				<p> &nbsp; </p> 

				{/* <p>{this.state.chargeDeskIdStr} </p>  */}
				{(info_otheresp!==null && info_otheresp!==""  && this.actiongo==="viewemailinvoice")?
					<div className="form-group col-11">
						{/* {this.state.info_otheresp}  */}
						{/* <iframe src="./viewemailinvoice" name="iframe_emac" title="Email Content"></iframe> */}
						<iframe srcDoc={info_otheresp} sandbox="allow-same-origin" allowFullScreen={true} 
						style={{minHeight:'400px', minWidth:'700px', border:'none'}}  
						 name="iframe_emac" title="Email Content"></iframe>
						 <input type="button" onClick={this.handleCloseListOfCDStatusInfo} 
						 className="btn btn-success float-right" value={t("zqwNVFrmbtn_x")} />
					</div>
					:<p>{this.state.chargeDeskIdStr} </p> 
				}

				{/* <p> &nbsp; </p> */}
				<div className="form-row neoxtbl">
	
				  <div className="form-group col-md-9">
				  <br/>
				  <input type="button" onClick={this.handleCloseListOfCDStatusInfo} className="btn btn-default" value={t("zqwNVFrmbtn_x")} /> &nbsp; &nbsp;
				   {/* {((paymentRound >0 && parseFloat(this.state.nowPayment)<=0 && this.state.pMethod===1) || (this.state.partialCanClose>0 && this.state.status_invoice!=="payed"))? */}
			  {/* <button type="button" className="btn btn-danger" onClick={this.handleFinalizeCD}>FINALISER LA TRANSACTION</button>  */}
				
			  {/* } */}
				  </div> 
				</div>
				  
			  </DialogContent>
			  
			</Dialog>
		  </div>
		);
	  }

	  handleCloseListOfCDStatusInfo  = (anyx) => {   
		this.setState({["openPossibleCDStatusInfo"]: false }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success");   
	  }

	   //neoSearchData 
  handleCloseNeoSearch  = (anyEvent) => {  
    console.log("OLD STYLE SUCCESS handleCloseNeoSearch ");  
    this.setState({["openNeoSearch"]: false, ["openNeoSearchlist"]: false, 
	["openSaleflow"]: false, ["openB2bleadProspect"]: false, 
	["openCheckingTicket"]:false, ["openNeoSaleFormPopup"]: false, }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);

  }

  handleOpenNeoSearch  = (anyEvent) => {     
    this.setState({["openNeoSearch"]: true }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);
    console.log("OLD STYLE SUCCESS handleOpenNeoSearch ");  

  }

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


	  NeoSearchResultHistory = (props) => {
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common"); 
		const myLang=t("curlang");
		const openNeoSearch=this.state.openNeoSearchlist;
		if(!openNeoSearch){
		  return (null);
		}
	
		//const neoSearchData=this.state.neoSearchData;     
		const neoSearch=this.state.neoSearchList; 
		if(neoSearch!==undefined && neoSearch!==null && neoSearch.length >0){}else{
		  return (null);
		}
	
		console.log("neoSearchneoSearchneoSearchneoSearch ", neoSearch);
	
	
		//return (<NdiInterestConflict neoSearchData={neoSearch}  />);
		return ( 
			<div>
			  <Dialog maxWidth={"sm"} open={openNeoSearch} onClose={this.handleCloseNeoSearch} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
				<DialogContent id="form-dialog-confirm"> 
				  <NdiInterestConflict neoSearchData={neoSearch}  />
	  
					<div className="form-group col-md-9">
					<br/>
					<input type="button" onClick={this.handleCloseNeoSearch} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
					 
					</div>                               
				</DialogContent>
				
			  </Dialog>
			</div> 
		);
	
		// if(myLang==="fr"){
		//   return this.xHelpInfoAsOnTheSiteFr(event);
		// }else{
		//   return this.xHelpInfoAsOnTheSiteEn(event);
		// }
	  }

	  NeoSearchActions = (props) => {
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common"); 
		const myLang=t("curlang");
		const openNeoSearch=this.state.openNeoSearch;
		if(!openNeoSearch){
		  return (null);
		}

		const ticket_id=this.state.ticket_id;
		const client_id=this.state.client_id;
		if(ticket_id!==null && ticket_id!=="" && client_id!==null && client_id!==""){}else{
			console.log(" nothing to show ", ticket_id, client_id);
			return (null);
		}
	
		//const neoSearchData=this.state.neoSearchData;     
		//const neoSearch=this.state.neoSearchList; 
		// if(neoSearch!==undefined && neoSearch!==null && neoSearch.length >0){}else{
		//   return (null);
		// }
	
		//console.log("neoSearchneoSearchneoSearchneoSearch ", neoSearch);
	
	
		//return (<NdiInterestConflict neoSearchData={neoSearch}  />);
		return ( 
			<div>
			  <Dialog maxWidth={"sm"} open={openNeoSearch} onClose={this.handleCloseNeoSearch} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
				<DialogContent id="form-dialog-confirm"> 
				  <NdiInterestConflict ticket_id={""+ticket_id} client_id={""+client_id}   />
	  
					<div className="form-group col-md-9">
					<br/>
					<input type="button" onClick={this.handleCloseNeoSearch} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
					 
					</div>                               
				</DialogContent>
				
			  </Dialog>
			</div> 
		);
	
		// if(myLang==="fr"){
		//   return this.xHelpInfoAsOnTheSiteFr(event);
		// }else{
		//   return this.xHelpInfoAsOnTheSiteEn(event);
		// }
	  }

	  
	  NeoSalesFlowForm = (props) => {
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common"); 
		const myLang=t("curlang");
		const open=this.state.openSaleflow;
		if(!open){
		  return (null);
		}
	
		const client_id=this.state.client_id;
		const ticket_id=this.state.ticket_id;
		const invoiceId=this.state.invoiceId; 

		//objListSales.ticketList 
		const objListSales =this.state.ticketListRec;
		let ticketList=[];
		if(objListSales!==undefined && objListSales!==null && objListSales.ticketList!==undefined){
			ticketList=objListSales.ticketList;
		}

	
		console.log("SalesFlowForm-SalesFlowForm ", invoiceId, client_id, ticket_id);
	
	
		//return (<NdiInterestConflict neoSearchData={neoSearch}  />);
		//maxWidth: lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
		return ( 
			<div>
			  <Dialog maxWidth={"lg"} open={open} onClose={this.handleCloseNeoSearch} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
				<DialogContent id="form-dialog-confirm"> 
				  <SalesFlowForm client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId} ticketList={ticketList} />
	  
					<div className="form-group col-md-9">
					<br/>
					<input type="button" onClick={this.handleCloseNeoSearch} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
					 
					</div>                               
				</DialogContent>
				
			  </Dialog>
			</div> 
		);
	 
	  }


	  NeoB2bleadProspectsFormx = (props) => {
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common"); 
		const myLang=t("curlang");
		const open=this.state.openB2bleadProspect;
		if(!open){
		  return (null);
		}
	
		const client_id=this.state.client_id;
		const ticket_id=this.state.ticket_id;
		const invoiceId=this.state.invoiceId; 
		const frminput={rndidpros:undefined };
	
		console.log("NeoB2bleadProspectsFormx ", invoiceId, client_id, ticket_id);
	
	
		//return (<NdiInterestConflict neoSearchData={neoSearch}  />);
		//maxWidth: lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
		return ( 
			<div>
			  <Dialog maxWidth={"lg"} open={open} onClose={this.handleCloseNeoSearch} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
				<DialogContent id="form-dialog-confProspect"> 
				<NeoB2bleadProspectsForm  frminput={frminput}  frminputClose={null} 
				frminputGetDataFrmSrv={777} 
		  client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId} />    

				{/* <NeoB2bleadProspectsForm frminput={neoB2bleadProspects} 
          frminputClose={this.frminputCloseFromChild} paStateFuncAction={refFuncAction} 
		  client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId} />     */}

				  {/* <SalesFlowForm client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId} /> */}
	  
					<div className="form-group col-md-9">
					<br/>
					<input type="button" onClick={this.handleCloseNeoSearch} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
					 
					</div>                               
				</DialogContent>
				
			  </Dialog>
			</div> 
		);
	 
	  }

	  //listOfTicketsPayments  transactions by id_invoices
	  //const transx=response.gohistory.transactions;
	  //this.setState({ ["isLoaderOpen"]: false}); 

	  NeoSalesCheckingTicketsForm = (props) => {
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common"); 
		const myLang=t("curlang");
		const open=this.state.openCheckingTicket;
		if(!open){
		  return (null);
		}

		const listOfTicketsPayments=this.state.listOfTicketsPayments;
	
		const client_id=this.state.client_id;
		const ticket_id=this.state.ticket_id;
		const invoiceId=this.state.invoiceId; 
		const errorMessage=this.state.errorTransacMessage;
		const Alert=this.Alert;
	
		console.log("NeoSalesCheckingTicketsForm ", invoiceId, client_id, ticket_id);

		let ky=0;  let transactionsTr=[]; let ticketListTrans=[];
		const transactions=this.state.listOfTicketsPayments.gohistory.transactions;
		const objListSales =this.state.ticketListRec;
		console.log("NeoSalesCheckingTicketsForm2 ", listOfTicketsPayments);
		console.log("NeoSalesCheckingTicketsForm3 ", transactions);

		for(let trans of transactions){
			// transactionsTr.push(<option key={"or"+ky} data-transaction={""+ticketobj.tr} 
			// value={""+ticketobj.ta}>{"TA "+ticketobj.ta+" "+ticketobj.description}</option>);
			console.log("NeoSalesCheckingTicketsForm sub4 ", trans.id_invoices, this.ticketListobj.id);
			if(trans.id_invoices!==this.ticketListobj.id){
				continue;
			}
			let ticketRef="";

			const idTr=parseInt(trans.id_products);
			if(idTr<9999){  
				for(let tlinf of objListSales.ticketList){
					if(trans.id===tlinf.tr){
						ticketRef=" (TA "+tlinf.ta+")";
						break;
					}
				}
				ticketListTrans.push(<option key={"tptor"+ky} value={""+trans.id}>{" "+trans.id+ticketRef} </option>); 
			}
			//this.getGenAndCheckTicket
			transactionsTr.push(<tr key={"trtr"+ky}><td>{trans.id}</td>
			<td>{trans.description}</td><td>{(idTr>9999)? 
			<button type="button" className="btn btn-primary" data-transaction={trans.id} onClick={this.getGenAndCheckTicket}>upsell</button>:
			<button type="button" className="btn btn-primary" data-transaction={trans.id} onClick={this.getGenAndCheckTicket}>GenTicket</button>
			// "upsell":"GenTicket"
			}
			</td>
			</tr>);
			ky++;
		}

			// let productList = productListx.length > 0
			// 	&& productListx.map((item, i) => {
			// 		//productOptions.push({ value: item.id, label: item.titre }); 
			// 		//console.log(item);
			// 		if(item!==null){
			// 		productsList.push(item); 
			// 		}
			// 	return (
			// 		null
			// 	);
			// 	}, this);
	
	
		//return (<NdiInterestConflict neoSearchData={neoSearch}  />);
		//maxWidth: lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
		return ( 
			<div>
			  <Dialog maxWidth={"lg"} open={open} onClose={this.handleCloseNeoSearch} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
				<DialogContent id="form-dialog-confirm"> 
				  {/* <SalesFlowForm client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId} /> */}
					{/* hooooooooooooooooooooooooooolala <br/>
					hooooooooooooooooooooooooooolala <br/>
					hooooooooooooooooooooooooooolala <br/>
					hooooooooooooooooooooooooooolala <br/> */}
					
					<h4>Facture/Invoice: #{this.ticketListobj.id}</h4>
					Upsell de 
					<select name="upsellof" id="upsellof" onChange={this.handleChange}>
						<option value="">Choisir/Select Upsell Ticket de/of</option>
						{ticketListTrans}
					</select>

			{(errorMessage!==null && errorMessage!=="")?
			<><br/>
              <Alert severity="error">{errorMessage}</Alert>
			</>:""
			}	 
			<table className="table table-striped">
				<thead>
					<tr>
					<th>Id</th>
					<th>Description</th>
					<th>Action</th>
					</tr>
				</thead>
				<tbody> 
					{transactionsTr}
				</tbody>
			</table>

					<div className="form-group col-md-9">
					<br/>
					<input type="button" onClick={this.handleCloseNeoSearch} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
					 
					</div>                               
				</DialogContent>
				
			  </Dialog>
			</div> 
		);
	 
	  }

	  NeoSaleFormPopup = (props) => {
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common"); 
		const myLang=t("curlang");
		const open=this.state.openNeoSaleFormPopup;
		if(!open){
		  return (null);
		}
	
		const client_id=this.state.client_id;
		const ticket_id=this.state.ticket_id;
		//const invoiceId=this.state.invoiceId; 
		const frminput={rndidpros:undefined };

		const ticketID=this.state.ticketlistall;
		const invoiceId= this.state.ticketListRec["id"]; 

		//objListSales.ticketList 
		const objListSales =this.state.ticketListRec;
		let ticketList=[];
		if(objListSales!==undefined && objListSales!==null && objListSales.ticketList!==undefined){
			ticketList=objListSales.ticketList;
		}
 				
		// this.setState({ ["openNeoSaleFormPopup"]: true, }); 
	
		console.log("NeoSaleFormPopup ", invoiceId, client_id, ticket_id);
	
	
		//return (<NdiInterestConflict neoSearchData={neoSearch}  />);
		//maxWidth: lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
		return ( 
			<div>
			  <Dialog fullWidth={"lg"} maxWidth={"lg"} open={open} onClose={this.handleCloseNeoSearch} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-NeoSaleFormPopup">
				<DialogContent id="form-dialog-NeoSaleFormPopup"> 
				{/* <NeoB2bleadProspectsForm  frminput={frminput}  frminputClose={null} 
				frminputGetDataFrmSrv={777} 
		  client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId} />     */}
		  <h4>Ticket #{ticketID} &nbsp; &nbsp; &nbsp; &nbsp; Fac/Inv #{invoiceId}</h4>
		  <input type="button" onClick={this.handleCloseNeoSearch} 
						 className="btn btn-success float-right"  value={t("zqwNVFrmbtn_x")} />

			<NeoVentesForm  frmGoXonSaleClosePopup={777} 
		  client_idPopup={client_id} ticket_idPopup={ticketID} invoiceIdPopup={invoiceId} 
		  ticketList={ticketList} />    

				{/* <NeoB2bleadProspectsForm frminput={neoB2bleadProspects} 
          frminputClose={this.frminputCloseFromChild} paStateFuncAction={refFuncAction} 
		  client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId} />     */}

				  {/* <SalesFlowForm client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId} /> */}
	  
					<div className="form-group col-md-9">
					<br/>
					<input type="button" onClick={this.handleCloseNeoSearch} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
					 
					</div>                               
				</DialogContent>
				
			  </Dialog>
			</div> 
		);
	 
	  }


	  handleChangetab = (event, newValue) => {
		console.log("WOOOOOOOOOOOOOOOOOOOOY TAB CHANGED ", newValue);
		this.activeTab=newValue; 
		this.setState({["activeTab"]:newValue,});
		//setValue(newValue);
		if(newValue===0){
		  //this.componentReloadData(); 
		}
	  };

	  TabPanel = (props) => {  
		//return (<div className="text-center mt-20 mt-sm-20">Bonjour</div>);
		const { children, value, index, ...other } = props;
	  
		return (
		  <div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		  >
			{value === index && (
			  <Box p={3}> 
				<div>{children}</div>
			  </Box>
			)}
		  </div>
		);
	  }
	 
	
	  a11yProps= (index) => {  
		return {
		  id: `scrollable-auto-tab-${index}`,
		  'aria-controls': `scrollable-auto-tabpanel-${index}`,
		};
	  }


	  handleOpenTicketTab = (event) => {
		// console.log("WOOOOOOOOOOOOOOOOOOOOY TAB CHANGED ", newValue);
		// this.activeTab=newValue; 
		// this.setState({["activeTab"]:newValue,});
		// //setValue(newValue);
		// if(newValue===0){
		//   //this.componentReloadData(); 
		// }
		const tabList=this.state.tabList;
		const ticket=parseInt(event.currentTarget.dataset.tickettab);
		let foundTabQty=0;
		console.log("WOOOOOOOOOOOOOOOOOOOOY go open TAB CHANGED ", ticket);
		for(let tabTk of tabList){
			if(tabTk.ticket===""+ticket){
				foundTabQty++;
			}
		}
		if(foundTabQty<1){
			this.addTab(""+ticket);
		}else{
			this.showToast("Ticket déjà ouvert dans un onglet/Ticket already openned on a tab", "error"); 
			//this.showToast(t("zqwCViewEl11_x"), "success");   
		}
	  };

	  addTab = (ticket) => {
		this.tabCounter++;
		const id=this.tabCounter; //this.tabCounter+1;
		const tabList=this.state.tabList;
		tabList.push({
			key:id,
			id:id,
			ticket:ticket,
		});
		//this.setState({ ["tabList"]: tabList, }); 
		this.setState({ ["tabList"]: tabList, ["activeTab"]: id,}); 
		const fieldTicketOpen=this.state.fieldTicketOpen;
		if(fieldTicketOpen!==""){ this.setState({ ["fieldTicketOpen"]: "", }); }

        /*this.setState((state,props)=>{
            let tabList = cloneDeep(state.tabList);
            let id = tabList[tabList.length-1].id+1;
            tabList.push({
                key:id,
                id:id,
            });

            return {
                tabList,
            }
        })*/
    }

    deleteTab = (e) => {
        // prevent MaterialUI from switching tabs
        e.stopPropagation();

        // Cases:
        // Case 1: Single tab.
        // Case 2: Tab on which it's pressed to delete.
        // Case 3: Tab on which it's pressed but it's the first tab
        // Case 4: Rest all cases.
        // Also cleanup data pertaining to tab.

        // Case 1:
        // if(this.state.tabList.length === 1){
        //     return; // If you want all tabs to be deleted, then don't check for this case.
        // }

        // Case 2,3,4:
        let tabID = parseInt(e.target.id);
        let tabIDIndex = -1; //0
		const activeTab=this.state.activeTab;
		let goTab=activeTab;

		let tabList = this.state.tabList.filter((value,index)=>{
			console.log("tabID-index", tabID, index);
            if(value.id === tabID){
                tabIDIndex = index;
				if(activeTab === tabID){
					goTab=0;
				}
            }
            return value.id !== tabID;
        });

		console.log("tabID-tabIDIndex-tabList", tabID, tabIDIndex, tabList);
		this.setState({
			tabList:tabList,
			activeTab: goTab, 
		});
        
		/*
        let tabList = this.state.tabList.filter((value,index)=>{
            if(value.id === tabID){
                tabIDIndex = index;
            }
            return value.id !== tabID;
        });
        
        this.setState((state,props)=>{
            let curValue = parseInt(state.value);
            if(curValue === tabID){
                // Case 3:
                if(tabIDIndex === 0){
                    curValue = state.tabList[tabIDIndex+1].id
                }
                // Case 2:
                else{
                    curValue = state.tabList[tabIDIndex-1].id
                }
            }
            return {
                value:curValue
            }
        },()=>{
            this.setState({
                tabList:tabList
            })
        });*/
    }

	handleTicketTabLaunch0 = (event) => {
		if (event.key === 'Enter' || event.keyCode === 13) {
			// Do something
			this.handleTicketTabLaunch(event)
		}
	 };
	handleTicketTabLaunch = (event) => { 
		const tabList=this.state.tabList;
		const fieldTicketOpen=this.state.fieldTicketOpen;
		const ticket=parseInt(fieldTicketOpen);
		let foundTabQty=0;
		console.log("handleTicketTabLaunch-WOOOOOOOOOOOOOOOOOOOOY go open TAB CHANGED ", ticket);
		for(let tabTk of tabList){
			if(tabTk.ticket===""+ticket){
				foundTabQty++;
			}
		}
		if(foundTabQty<1 && ticket>0){
			this.addTab(""+ticket);
		}else{
			this.showToast("Ticket déjà ouvert dans un onglet/Ticket already openned on a tab", "error"); 
			//this.showToast(t("zqwCViewEl11_x"), "success");   
		}
	};










	
	AutocompleteClient = (props) => {    
		//AutocompleteClient (props){
		  const { t } = this.props;
		const listofSuggestion=this.state.listOfSuggestion;
		const valueClientSearch=this.state.valueClientSearch;
		const inputValueClientSearch=this.state.inputValueClientSearch;
		//const selectedIdSuggestion=this.state.selectedIdSuggestion; 
		const propstep=props.propstep;
		const this2=this;
	
		return (
		  <Autocomplete
	
			value={valueClientSearch} 
			inputValue={inputValueClientSearch}
			onInputChange={(event, newInputValue) => {
			  //setInputValue(newInputValue);
			  this.setState({ ["inputValueClientSearch"]: newInputValue }); 
			  //console.log('onInputChange search for autocomplete ='+newInputValue);
			  if(newInputValue.length >2){ 
				  //goto the server 
				  //this.getClientSearchSuggestion(newInputValue); 
					setTimeout(() => {
					  this.getClientSearchSuggestion(newInputValue); 
					}, 0); 
			  }
			}}
	
			onChange={(event, newValue) => { 
	
			  if (typeof newValue === 'string') {
				// timeout to avoid instant validation of the dialog's form.
				setTimeout(() => {
				  this.setState({ ["valueClientSearch"]: newValue });  
				});
			  } else if (newValue && newValue.inputValue) {
				 
				this.setState({ ["valueClientSearch"]: newValue.inputValue });  
			  } else {
				//setValue(newValue);
				this.setState({ ["valueClientSearch"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)}); 
				 
				this.setState({ ["clientInfo"]: newValue}); 
				const clientInfo={  
				  id_clients:newValue.id, 
				  idClient:newValue.id, 
				  firstname:newValue.firstname, 
				  lastname:newValue.lastname, 
				  cell:newValue.cell, 
				  terms:newValue.terms, 
				  active:newValue.active,  
				  email:newValue.email, 
				  id_users:parseInt(newValue.id_users), 
				  language:newValue.language, 
				  push:newValue.push, 
				  id_zendesk:newValue.id_zendesk, 
				  sms:newValue.sms,
				  stripe_id:newValue.stripe_id,  
				  occupation:newValue.occupation, 
		
				}; 

				this.setState({ fieldName: "invCliId", fieldVal: newValue.id});
				//this.loadNextPageRedata(null);
				setTimeout(() => {
					this.loadNextPageRedata(null);
				  }, 10); 


				/*
				this.setState(clientInfo ); //xaza  this.setState({ clientInfo });
				if(propstep==1){ this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]:3 }); }
				//else{ this.setState({ ["globalStepWorkflow"]: 2 }); }
				let existedTF=true;
				if((newValue.id_zendesk===null || newValue.id_zendesk==="" || newValue.id_zendesk==="0") 
				&& (newValue.stripe_id===null || newValue.stripe_id==="")){
				  existedTF=false;
				}
	
				//this.setState({ ["existedClientRO"]: true, ["emailDoesNotExist"]: 1});
				this.setState({ ["existedClientRO"]: existedTF, ["emailDoesNotExist"]: 1});
				 
				this.clientFullInfoValidate();
				 
	
				//get client Address
				const clientId=newValue.id;
				setTimeout(() => {
				  this.getClientFullAddress(clientId); 
				}, 0); 
				*/

			  }
	
			  //this.setState({ ["valueClientSearch"]: newValue }); 
			  //console.log('onChange search for autocomplete '+newValue);
			}}
	
			// filterOptions={(options, params) => {
			//   const filtered = filter(options, params);
	
			//   if (params.inputValue !== '') {
			//     filtered.push({
			//       inputValue: params.inputValue,
			//       title: `Add "${params.inputValue}"`,
			//     });
			//   }
	
			//   return filtered;
			// }}
	
			freeSolo
			id="free-solo-2-demo"
			disableClearable
			// options={listofSuggestion.map((option) => option.firstname+' '+option.lastname+' '+option.email)}
			options={listofSuggestion}
	
	
			  getOptionLabel={(option) => {
				// e.g value selected with enter, right from the input
				//return option.title;
				//this.setState({ ["selectedIdSuggestion"]: option.id}); 
				//console.log(';;;;; sel id is '+option.id); 
	
				if (typeof option === 'string') {
				  return option;
				}
				if (option.inputValue) {
				  return option.inputValue;
				}
				//return option.title;
				//this.setState({ ["selectedIdSuggestion"]: option.id}); 
			  //console.log('sel id is '+option.id); 
			  return (option.firstname+' '+option.lastname+' '+option.email);
				
			  }}
	
			  renderOption={(option) => (option.firstname+' '+option.lastname+' '+option.email)}
	
			renderInput={(params) => ( 
			  <TextField
				{...params}
				label={t("zqwNVFrmEl80_x")} 
				margin="normal"
				variant="outlined"
				InputProps={{ ...params.InputProps, type: 'search' }}
			  />
			)}
		  />
		);
	  }
	
	getClientSearchSuggestion = (search) =>{   
        const valueClientSearch=this.state.valueClientSearch;
        const inputValueClientSearch=this.state.inputValueClientSearch;
        if(valueClientSearch===inputValueClientSearch){ 
          //console.log('WE DONOT GO TO SERVER FOR  '+inputValueClientSearch);  
          return false; 
        }
        const serachObj={search:search, searchEmail:""};
        Promise.resolve(this.props.getClientSearch(serachObj))
        .then((response)=>{  
          this.setState({ ["listOfSuggestion"]: response.NeoClientsOne }); 
          return response;
        });
        
  }


	  
	
}


const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes });  
const mapDispatchToProps = { addOrUpdateNeoVentes, getNeoVentes, getNeoVentesAll,getNeoVentesAllXStaus, getNeoVentesAllXStausx2, getNeoVentesGoto, deleteNeoVentes, getOrSetMultiActionStatus,
	getClientSearch, 
	getSearchConflict, setSearchConflict, getNeoSearchByTicket, getClientAndAddress, getClientPaymentsFullInfo, getGenAndCheckTicket };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoVentesBasicPostActionsta));
