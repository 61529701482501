import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOFOLDERS = 'GET_NEOFOLDERS';
export const ADD_NEOFOLDERS = 'ADD_NEOFOLDERS';
export const RECEIVE_NEOFOLDERSX = 'RECEIVE_NEOFOLDERSX';
export const REMOVE_NEOFOLDERS = 'REMOVE_NEOFOLDERS';
export const UPDATE_NEOFOLDERS = 'UPDATE_NEOFOLDERS';
export const REPLACE_NEOFOLDERS = 'REPLACE_NEOFOLDERS';
const apiUrl = API_DOMAIN + '/neofolders'; 

axiosDefaults(null);

export const getNeoFoldersAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
        //console.log("getNeoFoldersAll"); console.log(response.data.data); 
        return dispatch({type: RECEIVE_NEOFOLDERS, NeoFolders: response.data.data.neofolders});
      })
      .catch(error => { throw(error); });
  };

  //response.data.neofolders.data
};

export const addNeoFolders = (NeoFolders) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NeoFolders.id,
		id_clients: NeoFolders.id_clients,
		name: NeoFolders.name,
		active: NeoFolders.active,
		created: NeoFolders.created,
		ticket_id: NeoFolders.ticket_id,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOFOLDERS, payload: {
			id: data.id,
			id_clients: data.id_clients,
			name: data.name,
			active: data.active,
			created: data.created,
			ticket_id: data.ticket_id,
		}})
      })
      .then(() => {
        history.push("/NeoFolders")
      })
      .catch(error => { throw(error) });
  };
};
export const getNeoFolders = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.neofolders);		  
        return dispatch({type: RECEIVE_NEOFOLDERSX, NeoFoldersOne: response.data.neofolders});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNeoFoldersGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NeoFolders/${id}/edit`)
  };
};
export const deleteNeoFolders = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOFOLDERS, payload: {id}})
      })
      .then(() => {
        history.push("/NeoFolders")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNeoFolders = (NeoFolders) => {
  const id = NeoFolders.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoFolders.id}`, {
		id_clients: NeoFolders.id_clients,
		name: NeoFolders.name,
		active: NeoFolders.active,
		created: NeoFolders.created,
		ticket_id: NeoFolders.ticket_id,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOFOLDERS, payload: {
			id: data.id,
			id_clients: data.id_clients,
			name: data.name,
			active: data.active,
			created: data.created,
			ticket_id: data.ticket_id,
		}})
        dispatch({type: REPLACE_NEOFOLDERS, payload: {
			id: data.id,
			id_clients: data.id_clients,
			name: data.name,
			active: data.active,
			created: data.created,
			ticket_id: data.ticket_id,
		}})
      })
      .then(() => {
        history.push(`/NeoFolders/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNeoFolders = (NeoFolders, actionMode) => {
	if(actionMode===1){
        return addNeoFolders(NeoFolders);
      }else {
        return updateNeoFolders(NeoFolders);
      }
};