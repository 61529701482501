import { RECEIVE_ZONLINEUSUARIOS, ADD_ZONLINEUSUARIOS, REMOVE_ZONLINEUSUARIOS, REPLACE_ZONLINEUSUARIOS } from '../actions/ZonlineUsuariosAction';

const initialState = { ZonlineUsuarios: [] }
export default function ZonlineUsuariosReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ZONLINEUSUARIOS:
      return action.ZonlineUsuarios;
    case ADD_ZONLINEUSUARIOS:
      return [action.payload, ...state];
    case REMOVE_ZONLINEUSUARIOS:
      return state.filter(ZonlineUsuarios => ZonlineUsuarios.id_usuario !== action.payload.id_usuario);
    case REPLACE_ZONLINEUSUARIOS:
      return state.map((ZonlineUsuarios) => {
        if (ZonlineUsuarios.id_usuario === action.payload.id_usuario) {
          return {
            ...ZonlineUsuarios,
fecha: action.payload.fecha,
nombre: action.payload.nombre,
apellido: action.payload.apellido,
correo: action.payload.correo,
usuario: action.payload.usuario,
contrasena: action.payload.contrasena,
id_rol_usuario: action.payload.id_rol_usuario,
habilitado: action.payload.habilitado,
logintoken: action.payload.logintoken,
logintime: action.payload.logintime,
zona: action.payload.zona,
funcion: action.payload.funcion,
sucursales: action.payload.sucursales,
          }
        } else return ZonlineUsuarios;
      })
    default:
      return state;
  }
}
