import { RECEIVE_VWBOARDS  } from '../actions/VwFacturasAction';

const initialState = { VwBoardReports: [] }
export default function VwBoardReportsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_VWBOARDS:
      return action.VwBoardReports;
    //case ADD_VwBoardReports:
    //  return [action.payload, ...state];
    //case REMOVE_VwBoardReports:
    //  return state.filter(VwBoardReports => VwBoardReports.id !== action.payload.id);
    default:
      return state;
  }
}
