import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import { Provider } from 'react-redux';


import 'bootstrap/dist/css/bootstrap.min.css';  
import './adminltelibs/plugins/fontawesome-free/css/all.min.css';
import './adminltelibs/dist/css/adminlte.min.css';


//import 'bootstrap/dist/css/bootstrap.css';
import './stylesheets/index.css';


import App from './Appzendesk';
import rootReducer from './reducers';
/////import { getArticles } from './actions/articleAction';
 

// import i18n (needs to be bundled ;)) 
import './i18n'; 
import { withTranslation, I18nextProvider } from 'react-i18next';


const store = createStore(rootReducer, applyMiddleware(thunk, logger));  

/////store.dispatch(getArticles());
//store.dispatch(getZxTeamsAll());
const MyAppTranlated = withTranslation('common')(App); 


ReactDOM.render(
  <Provider store={store}>  
	<Suspense fallback="loading">
      <I18nextProvider><MyAppTranlated /></I18nextProvider>
    </Suspense>
  </Provider>, 
  document.getElementById('root')
);
