import React from 'react';
 
export class BaseFooter extends React.Component {

render() {
	 
  return (  
	  <footer className="main-footer"> 
		<div className="float-right d-none d-sm-inline">
		  
		</div> 
		<strong>Copyright &copy; <a href="#">Neolegal</a> </strong> 
	  </footer>
  );
}
}

//export default BaseFooter;
//export BaseFooter;
