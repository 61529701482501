import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVwBoardAll } from '../../actions/VwFacturasAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import BarChartIcon from '@material-ui/icons/BarChart';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoneyIcon from '@material-ui/icons/Money';
import PaymentIcon from '@material-ui/icons/Payment';

//ShoppingBasket  Payment  Money  BarChart  CalendarToday  Navigation  Reorder  Timelapse  CreditCard                                      

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

class VwBoard extends Component {
	constructor(props) {
		super(props); 
		//this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		//this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		this.state = {
			boardReports:{}
		}
		this.FacturaList=this.FacturaList.bind(this);		
	}
  
	componentDidMount() { 
		//this.props.getVwBoardAll();
		////this.props.VwBoardReports
		Promise.resolve(this.props.getVwBoardAll())
		.then((response)=>{
			//this.setState(response.VwFacturasOne);
			//console.log("START TEST"); console.log(response.VwBoardReports); console.log("END TEST");
			this.setState({boardReports: response.VwBoardReports });
			//return response;
		});
	}
	
 
  FacturaList() {
	const classes = useStyles();
	if(this.state.boardReports==null){ return null; }
	const list = this.state.boardReports.facven;
	if(list==null || list==undefined){ return null; }
	 
	const Mysublist=list.map((item, index) => {  
		  let picOp=""; let cssOp="none";
		  if(index %2==0){ picOp="2"; }
		  if(index >0){ cssOp="block"; }
		  return(<>
		  <Divider variant="inset" key={'x'+index} component="li" style={{display:cssOp}} />
		  <ListItem key={index} alignItems="flex-start">
			<ListItemAvatar>
			  <Avatar alt={index+1} src={require(`../../img/fac${picOp}.png`)} />
			</ListItemAvatar>
			<ListItemText
			  primary={item.Factura_Fiscal}
			  secondary={
				<React.Fragment>
				  <Typography
					component="span"
					variant="body2"
					className={classes.inline}
					color="textPrimary"
				  >
					${item.Total}
				  </Typography>
				  {" / "+item.Fecha }
				</React.Fragment>
			  }
			/>
		  </ListItem></>);
		   
	});
	
	return (
		<List className={classes.root}> 
			{Mysublist}
		</List>);
	 

  }
  

  render() { 
    const { t } = this.props;
  
	
	let gridHeaderLabel2="...";
	let montoFacturasVencidasTxt="0";
	let totalFacturasNoPagadas="0";
	let montoFacturasNoPagadas="0";
	let totalFacturasVencidas="0";
	let montoFacturasVencidas="0";
	let totalFacturasNoVencidas="0";
	let montoFacturasNoVencidas="0";
	let MFacVencidasTxtCmp=(<span />);
	let mntTxt="";  let mntTxtTitle="";
	let listFacturasVencidas=[];
	 
 

	if(this.props.VwBoardReports){
		const vwBoard=this.props.VwBoardReports;
		gridHeaderLabel2=vwBoard.HeaderLabel2;
		montoFacturasVencidasTxt=vwBoard.VencidasTxt;
		totalFacturasNoPagadas=vwBoard.tNoPagadas;
		montoFacturasNoPagadas=vwBoard.mNoPagadas;
		totalFacturasVencidas=vwBoard.tVencidas;
		montoFacturasVencidas=vwBoard.mVencidas;
		totalFacturasNoVencidas=vwBoard.tNoVencidas;
		montoFacturasNoVencidas=vwBoard.mNoVencidas;
		listFacturasVencidas=vwBoard.facven;
		if(vwBoard.mVencidas >0){
			mntTxt="B./ "+vwBoard.mVencidas;    mntTxtTitle="Tu empresa debe";
		   MFacVencidasTxtCmp=(<div className="panel panel-danger" style={{marginBottom:'-19', color:'#FF0000'}}> 
		   <div className="panel-heading">Tu empresa debe</div> <div className="panel-body" style={{color:'#FF0000'}}>
		<big><b>B./ {vwBoard.mNoVencidas}</b></big> </div> </div>);
		}
		
	}
	
 
	
	return (
        <div> 
		<div className="row" id="topParent">
			<div className="col-md-12">
				<div className="card card-primary card-outline">
					<div className="card-body">
						 <h5 className="card-title"><span id="trendyTitle2"><b>{gridHeaderLabel2}</b></span></h5> 
						 <div style={{minHeight:'20'}}><br/>
							 {/*<MFacVencidasTxtCmp />*/}
  <div className="panel panel-danger" style={{marginBottom:'-19', color:'#FF0000'}}> 
		   <div className="panel-heading">{mntTxtTitle}</div> <div className="panel-body" style={{color:'#FF0000'}}>
		<big><b>{mntTxt}</b></big> </div> </div>
		
						 </div>
						 <div style={{height:'20'}}> &nbsp; </div>
					</div>
				</div>
			</div>
		</div>

		<div className="row">				
			<div className="col-md-4">
				<div className="card m-t-0">
					<div className="row">
						<div className="col-md-6">
							<div className="peity_bar_bad left text-center m-t-10"><span><span style={{display: 'none'}}><span style={{display: 'none'}}><span style={{display: 'none'}}><span style={{display: 'none'}}>3,5,6,16,8,10,6</span><canvas width="50" height="24"></canvas></span>
								<canvas width="50" height="24"></canvas>
								</span><canvas width="50" height="24"></canvas></span><canvas width="50" height="24"></canvas></span>
								<h6><i className="red"><BarChartIcon /></i>... <i className="fa fa-shopping-cart blue"></i></h6></div>
						</div>
						<div className="col-md-6 border-left text-center p-t-10">
							<h3 className="mb-0 font-weight-bold" id="0000comp1">{totalFacturasNoPagadas}</h3>
							<span className="text-muted">Total Paiement En Attente</span>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-4">
				<div className="card m-t-0">
					<div className="row">
						<div className="col-md-6">
							<div className="peity_bar_good left text-center m-t-10"><span><span style={{display: 'none'}}>12,6,9,23,14,10,13</span><canvas width="50" height="24"></canvas></span>
								<h6><i className="purple"><CreditCardIcon /></i>...<i className="blue"><MoneyIcon /></i></h6>
							</div>
						</div>
						<div className="col-md-6 border-left text-center p-t-10">
							<h3 className="mb-0 font-weight-bold" id="0000comp1b">{montoFacturasNoPagadas}</h3>
							<span className="text-muted">Montant de Paiement En Attente</span>
						</div>
					</div>
				</div>
			</div>
			
			<div className="col-md-4">
				<div className="card m-t-0">
					<div className="row">
						<div className="col-md-6">
							<div className="peity_line_good left text-center m-t-10"><span><span style={{display: 'none'}}><span style={{display: 'none'}}><span style={{display: 'none'}}><span style={{display: 'none'}}>12,6,9,23,14,10,17</span><canvas width="50" height="24"></canvas></span>
								<canvas width="50" height="24"></canvas>
								</span><canvas width="50" height="24"></canvas></span><canvas width="50" height="24"></canvas></span>
								<h6><i className="red"><BarChartIcon /></i>... <i className="fa fa-shopping-cart red"></i></h6>
							</div>
						</div>
						<div className="col-md-6 border-left text-center p-t-10">
							<h3 className="mb-0 " id="0000comp2">{totalFacturasVencidas}</h3>
							<span className="text-muted">Total Paiement Fini</span>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-4">
				<div className="card m-t-0">
					<div className="row">
						<div className="col-md-6">
							<div className="peity_bar_good left text-center m-t-10"><span><span style={{display: 'none'}}>12,6,9,23,14,10,13</span><canvas width="50" height="24"></canvas></span>
								<h6><i className="orange"><CreditCardIcon /></i>...<i className="red"><MoneyIcon /></i>
								</h6>
							</div>
						</div>
						<div className="col-md-6 border-left text-center p-t-10">
							<h3 className="mb-0 font-weight-bold" id="0000comp3">{montoFacturasVencidas}</h3>
							<span className="text-muted">Montant de Paiements Finis</span>
						</div>
					</div>
				</div>
			</div>
			
			
			<div className="col-md-4">
				<div className="card m-t-0">
					<div className="row">
						<div className="col-md-6">
							<div className="peity_line_good left text-center m-t-10"><span><span style={{display: 'none'}}><span style={{display: 'none'}}><span style={{display: 'none'}}><span style={{display: 'none'}}>12,6,9,23,14,10,17</span><canvas width="50" height="24"></canvas></span>
								<canvas width="50" height="24"></canvas>
								</span><canvas width="50" height="24"></canvas></span><canvas width="50" height="24"></canvas></span>
								<h6><i className="red"><BarChartIcon /></i>... <i className="fa fa-shopping-cart green"></i></h6>
							</div>
						</div>
						<div className="col-md-6 border-left text-center p-t-10">
							<h3 className="mb-0 " id="0000comp231">{totalFacturasNoVencidas}</h3>
							<span className="text-muted">Total Paiement Décliné</span>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-4">
				<div className="card m-t-0">
					<div className="row">
						<div className="col-md-6">
							<div className="peity_bar_good left text-center m-t-10"><span><span style={{display: 'none'}}>12,6,9,23,14,10,13</span><canvas width="50" height="24"></canvas></span>
								<h6><i className="blue"><CreditCardIcon /></i>...<i className="green"><MoneyIcon /></i></h6>
							</div>
						</div>
						<div className="col-md-6 border-left text-center p-t-10">
							<h3 className="mb-0 font-weight-bold" id="0000comp231b">{montoFacturasNoVencidas}</h3>
							<span className="text-muted">Montant de Paiements Déclinés</span>
						</div>
					</div>
				</div>
			</div>
	</div> 
	<h4>Liste d'activités</h4>
	{/* <this.FacturaList />  */}
 	
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
        </div>
      );    

	//this.props.VwBoardReports.length	
	
	}
}


const mapStateToProps = (state) => ({ VwBoardReports: state.VwBoardReports });  
const mapDispatchToProps = { getVwBoardAll };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(VwBoard));
