import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getDocLinkType, sendDocLink, getDocLink, getDownloadFileLink } from '../../actions/NdiFieldsAction'; 
import { Validations } from '../../utils/validations'; 
import Toastr from "../../messages";
import  Loader from "../../loader";

import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import EmailIcon from '@mui/icons-material/Email';
// import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import Avatar from '@material-ui/core/Avatar';  
import IconButton from '@material-ui/core/IconButton';
//import Select from 'react-select';
import Select, { StylesConfig } from 'react-select';
//import "../../stylesheets/reactselect.css";




const filter = createFilterOptions();


let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['name']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['text_label']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['placeholder']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_type']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_default_value']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['mask']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_service']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_dataset']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['tag_id']={check:false, chkType:'int', message:'incorrect value'};

class PublicLinkForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  form_name='';
  target='';
  listFormOptions={};
  
  constructor(props) {
    super(props);
    this.state = {
		isLoaderOpen:false, 
		toastrMessage: '', toastrType: '', toastrDuration: 6000,
		id:0,
		name:'', e_name: false,
		text_label:'', e_text_label: false,
		placeholder:'', e_placeholder: false,
		field:'', e_field: false,
		field_type:'', e_field_type: false,
		field_default_value:'', e_field_default_value: false,
		mask:'', e_mask: false,
		field_service:'', e_field_service: false,
		field_dataset:'', e_field_dataset: false,
		tag_id:'', e_tag_id: false,   
		docList: [], hideSendButton:true, hideButtonlink:false, 
		message:"", formurl:"", action:"", 
		mediaToSendToList: [], mediatosend:"", selOptionTxt:"", 
		valueClientSearch:"", inputValueClientSearch:"", objectClientSearch:null,
		keyreload:"000",  name2:{}, 
		listEmails:[], 
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }


  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeDocList = (event) => {
    this.form_name='';
  	this.target='';
	// const form_name =event.target.dataset.formname; 
	// const target =event.target.dataset.target; 
	const form_name =event.target[event.target.selectedIndex].dataset.formname; 
	const target =event.target[event.target.selectedIndex].dataset.target; 
	const ticket=this.props.currentTicket;
	console.log('handleChangeDocList ', event.target.value, form_name, target, ticket);
	this.setState({ [event.target.name]: event.target.value });
	if(form_name!==undefined && target!==undefined){
		this.form_name=form_name;
		this.target=''+target;
	}

	this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: true, ["message"]: '', ["formurl"]:"", ["hideButtonlink"]:false,});

	
  };

  handleChangeXTraEmails = (event) => {
	const listEmails=this.state.listEmails; 
	//const index =event.value;
    this.setState({ [event.target.name]: event.target.value });

	//consoleXlog("zzz handleChange2", event.target.name, event.target.value, "OOOObj#######", event.target,  event);
	let index=-3;
	//const index=event.target.dataset.index; //event.currentTarget.dataset.index;
	if(event.target.dataset!==undefined && event.target.dataset.index!==undefined){ 
		index=event.target.dataset.index; 
	}
	
	const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	const aaaaEmail=""+event.target.value;
	// if(aaaaEmail.match(mailformat))
	// {
	// 	//document.form1.text1.focus(); 
	// }

	if(index>=0){
		//if(listEmails.length-1))
		if(listEmails[index]!==undefined){
			listEmails[index]=""+event.target.value;
		}else{
			listEmails.push(""+event.target.value);
		}
		this.setState({ ["listEmails"]: listEmails });
	}

  };

  handleChangeXTraEmailsrem = (event) => {
	const listEmails=this.state.listEmails; 
	//const index =event.value;
    //this.setState({ [event.target.name]: event.target.value });

	//consoleXlog("zzz handleChange2", event.target.name, event.target.value, "OOOObj#######", event.target,  event);
	let index=-3;
	//const index=event.target.dataset.index; //event.currentTarget.dataset.index;
	if(event.target.dataset!==undefined && event.target.dataset.index!==undefined){ 
		index=event.target.dataset.index; 
	} 

	if(index>=0){ 
		listEmails.splice(index, 1);

		this.setState({ ["listEmails"]: listEmails });
	}

  };


  handleChangeXTraEmailsadd = (event) => {
	const listEmails=this.state.listEmails; 
	//const index =event.value;
    //this.setState({ [event.target.name]: event.target.value });
	//consoleXlog("zzz handleChange2", event.target.name, event.target.value, "OOOObj#######", event.target,  event);
	
	listEmails.push("");
	this.setState({ ["listEmails"]: listEmails });

  };


  handleChangeDocList2 = (event) => {
    this.form_name='';
  	this.target='';
	const backVal =event.value;

	this.setState({ ["form_name"]: "", });

	if(backVal!==undefined && backVal!==null && backVal!==""){
		//const locObj=JSON.parse(backVal);
		let locObjItem={}; let locObj={};
		//const listFormOptions=this.listFormOptions;
		for(let item of this.listFormOptions){ 
			if(item.value===backVal){
				locObjItem=item;
				break;
			}
		}
		if(locObjItem!==null && locObjItem.value!==undefined){
			//locObj=locObjItem.valuex;
			locObj=JSON.parse(locObjItem.valuex);
		}
			 
		console.log('handleChangeDocList2 ', backVal, locObjItem, "000-0", this.listFormOptions);

		//const locObj={value:item.id, formname:item.form_name, target:item.target};
		//productOptions.push({ value: item.id, label: item.titre });
		//productOptions.push({ value: JSON.stringify(locObj), label: item.titre });
		const form_name =locObj.formname; 
		const target =locObj.target; 
		const value =locObj.value; 
		const ticket=this.props.currentTicket;

		console.log('handleChangeDocList2 ', backVal, form_name, target, ticket);
		//listFormOptions.push({ value: item.id, label: title, valuex: JSON.stringify(locObj) });
		//.setState({ ["name"]: value, ["name2"]: backVal  });
		this.setState({ ["name"]: value, ["name2"]: locObj  });
		//this.setState({ ["name"]: "", ["name2"]: {}  });
		if(form_name!==undefined && target!==undefined){
			this.form_name=form_name;
			this.target=''+target;
			this.setState({ ["form_name"]: ""+form_name, });
		}

		//zqwNFFrmZn010_x 

		this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: true, ["message"]: '', ["formurl"]:"", ["hideButtonlink"]:false,});
		//this.setState({ ["name"]: "", ["name2"]: {}  });
		//this.setState({ ["name"]: value, ["name2"]: locObj  });

		// setTimeout(() => {
		// 	this.setState({ ["name"]: value, ["name2"]: locObj  });
		// 	console.log('handleChangeDocList2 state 2nd time ', backVal, value, "000-0");

		// }, 500); 

	} 
	
  };

   
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	const { t } = this.props;  
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.NdiFields.id);
		if(this.props.NdiFields.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getNdiFields(idx);
			Promise.resolve(this.props.getNdiFields(idx))
			.then((response)=>{
				this.setState(response.NdiFieldsOne);
				return response;
			});
		}	
	}

	// getDocLinkType, sendDocLink

	// Promise.resolve(this.props.getNdiFields(4))
	// 		.then((response)=>{
	// 			this.setState(response.NdiFieldsOne);
	// 			return response;
	// 		});	

	const formOrFile=this.props.formOrFile;
	const publicFormOrFile=this.props.publicFormOrFile;
	if(formOrFile===2){
		//this.form_name="gofile";
		//.target="gofile"; 
		//console.log("componentDidMount fichierName=", publicFormOrFile.fileaddress, publicFormOrFile);
	}

	const docList=this.state.docList;
	if(docList.length=== 0 && formOrFile!==2){
		// NdiFieldsOne
		const this2=this; 
		this.setState({ ["isLoaderOpen"]: true});  
		Promise.resolve(this.props.getDocLinkType())
		.then((response)=>{
		const listForms=response.NdiFieldsOne;
		//this.setState(response.NdiFieldsOne);
		this.setState({ ["isLoaderOpen"]: false, ["docList"]: listForms, });  
		console.log("..HOHOHO response from server .... ");  console.log(response);
		
		//   this.setState({ ["isLoaderOpen"]: false, ["refunds"]: clientTransInfo.refunds, ["disabledBtn"]:true, ["paymentRound"]:1,});
		//   this.showToast(t("zqwNFFtxt11_x"), "success"); 
		// this.saveUpdateTicketData(null);  
		// setTimeout(() => {
		// 	this.showToast(t("zqwNFFtxt10_x"), "warning");  
		// 	this.saveUpdateTicketData(null);  
		// }, 10000);   
		return response;
		}).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		//alert(error);
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
			errMessage +="\n "+t("zqwNVFrmEl14_x")
		}
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		
		console.log("ERROR WITH TRANSACTION"); 
		console.log(error);
		console.log("SHORT ERROR MESSAGE: "+errMessage);
		console.log("RWA RESPONSE"); 
		//console.log(rawError);
		this2.showToast(errMessage, "error"); 
		});
	}

	const dynamicFormAllData=this.props.dynamicFormAllData;
	console.log("dynamicFormAllData ", dynamicFormAllData);
	// optionsData  ---  product_field_id 
	//client 75 76 names; 77 phone; 78 email
	// PA    82 83 names; 84 phone; 85 email
	//clientAd 160--- 1262 1261 names; 1263 phone; 1264 email
	// PA-Ad   159--- 1252 1251 names; 1253 phone; 1254 email
	const mediaToSendToList=[];
	//const docList=this.state.docList;
	let clientFName="", clientLName="", clientPhone="", clientEmail="";
	let paFName="", paLName="", paPhone="", paEmail="";
	let mList = dynamicFormAllData.optionsData.length > 0
          && dynamicFormAllData.optionsData.map((item, i) => { 
            //   const firstname=item.firstname;
			let product_field_id=item.product_field_id;  
			if(product_field_id==="75"){
				clientFName=""+item.field_data;
			}else if(product_field_id==="76"){
				clientLName=""+item.field_data;
			}else if(product_field_id==="77"){
				clientPhone=""+item.field_data;
			}else if(product_field_id==="78"){
				clientEmail=""+item.field_data;
			}else if(product_field_id==="82"){
				paFName=""+item.field_data;
			}else if(product_field_id==="83"){
				paLName=""+item.field_data;
			}else if(product_field_id==="84"){
				paPhone=""+item.field_data;
			}else if(product_field_id==="85"){
				paEmail=""+item.field_data;
			}

			return null;
 
          }, this);

		  if(clientPhone!==""){
			mediaToSendToList.push({role:"client",fname:clientFName, lname:clientLName, media:"SMS", endmedia:clientPhone});
		  }
		  if(clientEmail!==""){
			mediaToSendToList.push({role:"client",fname:clientFName, lname:clientLName, media:"email", endmedia:clientEmail});
		  }

		  /*if(paPhone!==""){
			mediaToSendToList.push({role:"PA/OP",fname:paFName, lname:paLName, media:"SMS", endmedia:paPhone});
		  }
		  if(paEmail!==""){
			mediaToSendToList.push({role:"PA/OP",fname:paFName, lname:paLName, media:"email", endmedia:paEmail});
		  }*/

		//   mediaToSendToList.push({role:"client",fname:clientFName, lname:clientLName, media:"SMS", phone:clientPhone, email:clientEmail});


	let mList2 = dynamicFormAllData.optionsData.length > 0
          && dynamicFormAllData.optionsData.map((mainItem, i) => { 
            //   const firstname=item.firstname;  
			//paFName=""+item.field_data, paLName=""+item.field_data, paPhone=""+item.field_data, paEmail=""+item.field_data;
			if(mainItem.product_field_id==="159"){
				//clientFName=""+item.field_data;
				//const paAd=JSON.parse(item.field_data_raw);
				//clientAd 160--- 1262 1261 names; 1263 phone; 1264 email
				// PA-Ad   159--- 1252 1251 names; 1253 phone; 1254 email
				/*if(mainItem.field_data_raw!==null && mainItem.field_data_raw!==""){
					const paAds=JSON.parse(mainItem.field_data_raw);
					for(let paAd of paAds){
						let paxFName="", paxLName="", paxPhone="", paxEmail="";
						for(let item of paAd){
							let product_field_id=item.labelnodel;  
							if(product_field_id===1252){
								paxFName=""+item.value;
							}else if(product_field_id===1251){
								paxLName=""+item.value;
							}else if(product_field_id===1253){
								paxPhone=""+item.value;
							}else if(product_field_id===1254){
								paxEmail=""+item.value;
							}
						}
						if(paxPhone!==""){
							mediaToSendToList.push({role:"PA/OP+",fname:paxFName, lname:paxLName, media:"SMS", endmedia:paxPhone});
						}
						if(paxEmail!==""){
							mediaToSendToList.push({role:"PA/OP+",fname:paxFName, lname:paxLName, media:"email", endmedia:paxEmail});
						}
					} 
				}*/
			}else if(mainItem.product_field_id==="160"){
				//clientLName=""+item.field_data;
				//const cliAd=JSON.parse(item.field_data_raw);
				//clientAd 160--- 1262 1261 names; 1263 phone; 1264 email
				// PA-Ad   159--- 1252 1251 names; 1253 phone; 1254 email
				if(mainItem.field_data_raw!==null && mainItem.field_data_raw!==""){
					const cliAds=JSON.parse(mainItem.field_data_raw);
					for(let cliAd of cliAds){
						let clientxFName="", clientxLName="", clientxPhone="", clientxEmail="";
						for(let item of cliAd){
							let product_field_id=item.labelnodel;  
							if(product_field_id===1262){
								clientxFName=""+item.value;
							}else if(product_field_id===1261){
								clientxLName=""+item.value;
							}else if(product_field_id===1263){
								clientxPhone=""+item.value;
							}else if(product_field_id===1264){
								clientxEmail=""+item.value;
							}
						}
						if(clientxPhone!==""){
							mediaToSendToList.push({role:"client+",fname:clientxFName, lname:clientxLName, media:"SMS", endmedia:clientxPhone});
						}
						if(clientxEmail!==""){
							mediaToSendToList.push({role:"client+",fname:clientxFName, lname:clientxLName, media:"email", endmedia:clientxEmail});
						}
					}
				}
			}
			return null;  
          }, this);

		  if(mediaToSendToList.length >0){
			this.setState({ ["mediaToSendToList"]: mediaToSendToList, });  
		  }

  }

  showToast = (arg, toastType) => {
		//toastType: success or error or warning or info	  
		//console.log("showToast showToast "); //console.log(arg);
		//alert("hello it is an eventDrop here "+arg.date);
		//Toastr("Hello I am a toast message right here, for testing purpose...");
		//toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		this.setState({ // update a property
			toastrMessage: arg, 
		toastrType: toastType
		});
		setTimeout(() => { 
		this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		}); 
		}, 5500);
	}

	//   getDocLinkType, sendDocLink

	handleSubmitDoc = (event) => {                                                             
		event.preventDefault();
		const { t } = this.props;
		const id = this.state.id;
		const name = this.state.name;
		
		// const form_name =event.target.dataset.formname; 
		// const target =event.target.dataset.target; 
		const form_name= this.form_name; 
		const target   = this.target;
		const ticket=this.props.currentTicket;
		const action=this.state.action;
		const formurl=this.state.formurl;

		let selOptionTxt=""; let passValid=0;
		let mediatosend=this.state.mediatosend;
		const valueClientSearch=this.state.valueClientSearch;
		const inputValueClientSearch=this.state.inputValueClientSearch; 
		const objectClientSearch=this.state.objectClientSearch;
		let emailphone="";
		console.log("weeeeeeee objectClientSearch-reeeeeeeeeender ", objectClientSearch); 
		console.log("weeeeeeee valueClientSearch-inputValueClientSearch vcs=", valueClientSearch, "ivc=", inputValueClientSearch); 
		
		if(objectClientSearch!==null){
			// options.push({mediatype:item.media, role:item.role, value:item.endmedia, name:item.fname+" "+item.lname, label:item.role+" "+item.fname+" "+item.lname+" "+item.media+":"+item.endmedia});
			// {item.role+" "+item.fname+" "+item.lname+" "+item.media+":"+item.endmedia}
			selOptionTxt=objectClientSearch.label;
			emailphone=""+objectClientSearch.value;
			mediatosend=objectClientSearch.mediatype;
		}
		if(mediatosend!==""){
			if(mediatosend==="email"){ 
				//emailCls=" fxmac "; smsCls="";
				if (objectClientSearch===null && !this.validateEmail(inputValueClientSearch)){
					window.alert(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x")); 
					this.showToast(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x"), "error"); 
					return false;
				}
				passValid=1;	
			}else{
				//emailCls=""; smsCls=" fxmac ";  
				if (objectClientSearch===null && !this.validatePhone(inputValueClientSearch)){
					window.alert(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x")); 
					this.showToast(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x"), "error"); 
					return false;
				}
				passValid=2;	
			}
		}else{
			// window.alert(t('zqwerrorFoundonform_x')+" click email/SMS "); 
			// this.showToast(t('zqwerrorFoundonform_x')+" click email/SMS ", "error"); 
			// return false;
		}
		if(objectClientSearch===null && inputValueClientSearch!==null && inputValueClientSearch!==""){ 
			emailphone=""+inputValueClientSearch;
		}
		if(emailphone===null || emailphone===""){ 
			window.alert(t('zqwNFFrmZn015_x')); 
			this.showToast(t('zqwNFFrmZn015_x'), "error"); 
			return false;
		}

		if (this.validateEmail(emailphone)){
			mediatosend="email";
			passValid=1;
		}

		if (this.validatePhone(emailphone)){
			mediatosend="SMS";
			passValid=2;
		}

		// if (!this.validateEmail(emailphone)  && !this.validatePhone(emailphone)){
		if (passValid <1){
			window.alert(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x")); 
			this.showToast(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x"), "error"); 
			return false; 
		}

		// if (!this.validateEmail(emailphone)){
		// 	window.alert(t('zqwerrorFoundonform_x')+" email incorrect "); 
		// 	this.showToast(t('zqwerrorFoundonform_x')+" email incorrect ", "error"); 
		// 	return false;
		// }	 
		// //emailCls=""; smsCls=" fxmac ";  
		// if (objectClientSearch===null && !this.validatePhone(emailphone)){
		// 	window.alert(t('zqwerrorFoundonform_x')+" téléphone/phone incorrect "); 
		// 	this.showToast(t('zqwerrorFoundonform_x')+" téléphone/phone incorrect ", "error"); 
		// 	return false;
		// }	

		//action:"sendLinkToEmail"

		console.log(" mediatosend=", mediatosend, " txtval=", emailphone); 
		//return false;   


		if(form_name===undefined || form_name===null || form_name==="" ||
			 target===undefined || target===null|| target===""){
				window.alert(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x")); 
				this.showToast(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x"), "error"); 
			return false;
		} 

		if(ticket===undefined || ticket===null){
			window.alert(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x")); 
			this.showToast(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x"), "error"); 
			return false;
		}
		
		const listEmails=this.state.listEmails; 		
		const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; 
		let validEmails=0; let invalidEmails=0; 
		const goListEmails=[]; 		
		if(listEmails.length >0)
		{
			for(const emailval of listEmails){
				const aaaaEmail=""+emailval;
				if(aaaaEmail.match(mailformat))
				{
					validEmails++;
					goListEmails.push(aaaaEmail); 
				}else if(aaaaEmail.length >0)
				{ invalidEmails++; }
			}

			if(validEmails===0 || invalidEmails >0){
				this.showToast("Il semble que la liste de courriel est vide ou il y a un courriel qui est incorrect. ", "error"); 
				window.alert("Il semble que la liste de courriel est vide ou il y a un courriel qui est incorrect. "); 
				//this.showToast(t('zqwerrorFoundonform_x')+" "+t("zqwNFFrmZn018_x"), "error"); 
				return false; 
			}
		}

		console.log('handleSubmitDoc ', ticket, form_name, target);
		//const docLink={form_name:form_name, target:target, ticketid:ticket};
		//const docLink={form_name:form_name, target:target, ticketid:ticket, formurl:formurl, action:"sendLinkToEmail", emailphone:emailphone};
		const docLink={form_name:form_name, target:target, ticketid:ticket, formurl:formurl, action:mediatosend, emailphone:emailphone, };
		const this2=this; 
		docLink.sendfilelink=0;
		const formOrFile=this.props.formOrFile;
		const publicFormOrFile=this.props.publicFormOrFile; 
		if(formOrFile===2){
			docLink.sendfilelink=formOrFile;
		}
		if(validEmails>0){
			docLink.otherEmails=goListEmails;
		}
		
		console.log('handleSubmitDoc-otherEmails ', listEmails, goListEmails);
		//return false; 

		this.setState({ ["isLoaderOpen"]: true});  
		Promise.resolve(this.props.sendDocLink(docLink))
		.then((response)=>{
			const listForms=response.NdiFieldsOne;
			//this.setState(response.NdiFieldsOne);
			let hideSendButton=false;
			let message=""; 
			if(listForms.puburl!==undefined && listForms.puburl.url!==undefined && listForms.puburl.url!==""){
				hideSendButton=true;
				message="<b>"+t("zqwNFFrmZn012_x")+"</b><br/>"+emailphone;
				//message="<b>"+t("zqwNFFrmZn012_x")+"</b><br/>"+listForms.client.firstname+" "+listForms.client.lastname+" <br/>Email: "+listForms.client.email; 
				/// this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: true,["isLoaderOpen"]: false, });
			}
			// this.setState({ ["isLoaderOpen"]: false,  });
			this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: hideSendButton,["message"]: message, });
			
			this.setState({ ["listEmails"]: []});  

			console.log("..HOHOHO response sendDocLink .... ");  console.log(response);
			this.showToast("Felicitations/Congratulations!", "success");    
			return response;
		}).catch(function(error){ 
			this2.setState({ ["isLoaderOpen"]: false});  
			//alert(error);
			const errObj=error;
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
				errMessage +="\n "+t("zqwNVFrmEl14_x")
			}
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage}); 
			console.log("ERROR WITH TRANSACTION"); 
			console.log(error);
			console.log("SHORT ERROR MESSAGE: "+errMessage);
			console.log("RWA RESPONSE"); 
			//console.log(rawError);
			this2.showToast(errMessage, "error"); 
		});
	 
	  };

	  handleSubmitGetLink = (event) => {                                                             
		event.preventDefault();
		const { t } = this.props;
		const id = this.state.id;
		const name = this.state.name;
		
		// const form_name =event.target.dataset.formname; 
		// const target =event.target.dataset.target; 
		const form_name= this.form_name; 
		const target   = this.target;
		const ticket=this.props.currentTicket;
		console.log('handleSubmitGetLink ', ticket, form_name, target);
		if(form_name===undefined || form_name===null || form_name==="" ||
			 target===undefined || target===null|| target===""){
			alert(t('zqwerrorFoundonform_x')); 
			this.showToast(t('zqwerrorFoundonform_x'), "error"); 
			return false;
		} 

		if(ticket===undefined || ticket===null){
			alert(t('zqwerrorFoundonform_x')+" #ticket"); 
			this.showToast(t('zqwerrorFoundonform_x'), "error"); 
			return false;
		} 
		
		let form_oldurl="";  let form_nameid="";
		const docList=this.state.docList;
		if(docList.length > 0){
			for(let item of docList){
				if(item.form_name!==undefined && item.form_name!==null && item.form_name===form_name){ 
					if(item.url!==undefined && item.url!==null || item.url!==""){
						form_oldurl=""+item.url;
					}
					form_nameid=""+item.id;
				}
			}
		}

		// let docListformat = docList.length > 0
        //   && docList.map((item, i) => { 
		// 	if(item.form_name!==undefined && item.form_name!==null && item.form_name===form_name){ 
		// 		if(item.url!==undefined && item.url!==null || item.url!==""){
		// 			form_oldurl="".item.url;
		// 		}
		// 	}

		// 	return null;
  
        //   }, this);

		//getDownloadFileLink  
		const formOrFile=this.props.formOrFile;
		const publicFormOrFile=this.props.publicFormOrFile;
		let docxLink=this.props.getDocLink;
		if(formOrFile===2){
			docxLink=this.props.getDownloadFileLink;
		}

		console.log('handleSubmitDoc ', ticket, form_name, target);
		const docLink={form_name:form_name, target:target, ticketid:ticket, form_oldurl};
		if(formOrFile===2){
			docLink.url=publicFormOrFile.fileaddress;
		}
		docLink.id=""+form_nameid;
		
		const this2=this; 
		this.setState({ ["isLoaderOpen"]: true});  
		//Promise.resolve(this.props.getDocLink(docLink))
		//Promise.resolve(docxLink(docLink))
		Promise.resolve(docxLink(docLink))
		.then((response)=>{
			//console.log("..TOPNOTCH-HOHOHO response sendDocLink .... ");  console.log(response);
			if(formOrFile===2){
				const listForms=response.NdiFieldsOne;
				//this.setState(response.NdiFieldsOne);
				let hideSendButton=true;
				let message=""; 
				listForms.puburl={url:listForms.url};
				if(listForms.puburl!==undefined && listForms.puburl.url!==undefined && listForms.puburl.url!==""){
					hideSendButton=false;
					//message="<b>"+t("zqwNFFrmZn012_x")+"</b><br/>"+listForms.client.firstname+" "+listForms.client.lastname+" <br/>Email: "+listForms.client.email; 
					///// this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: true,["isLoaderOpen"]: false, });
				}

				//console.log("koooooooool=====", listForms.puburl.url);    
				// this.setState({ ["isLoaderOpen"]: false,  });
				this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: hideSendButton, ["message"]: message, ["formurl"]:listForms.puburl.url, ["hideButtonlink"]:true,});
				

				console.log("..HOHOHO00000 response sendDocLink .... ");  console.log(response);
				this.showToast("Felicitations/Congratulations!", "success");   
			}else{
				const listForms=response.NdiFieldsOne;
				//this.setState(response.NdiFieldsOne);
				let hideSendButton=true;
				let message=""; 
				if(listForms.puburl!==undefined && listForms.puburl.url!==undefined && listForms.puburl.url!==""){
					hideSendButton=false;
					//message="<b>"+t("zqwNFFrmZn012_x")+"</b><br/>"+listForms.client.firstname+" "+listForms.client.lastname+" <br/>Email: "+listForms.client.email; 
					///// this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: true,["isLoaderOpen"]: false, });
				}

				//console.log("koooooooool=====", listForms.puburl.url);    
				// this.setState({ ["isLoaderOpen"]: false,  });
				this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: hideSendButton, ["message"]: message, ["formurl"]:listForms.puburl.url, ["hideButtonlink"]:true,});

				console.log("..HOHOHO response sendDocLink .... ");  console.log(response);
				this.showToast("Felicitations/Congratulations!", "success");    
			}
			// const listForms=response.NdiFieldsOne;
			// //this.setState(response.NdiFieldsOne);
			// let hideSendButton=true;
			// let message=""; 
			// if(listForms.puburl!==undefined && listForms.puburl.url!==undefined && listForms.puburl.url!==""){
			// 	hideSendButton=false;
			// 	//message="<b>"+t("zqwNFFrmZn012_x")+"</b><br/>"+listForms.client.firstname+" "+listForms.client.lastname+" <br/>Email: "+listForms.client.email; 
			// 	///// this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: true,["isLoaderOpen"]: false, });
			// }

			// //console.log("koooooooool=====", listForms.puburl.url);    
			// // this.setState({ ["isLoaderOpen"]: false,  });
			// this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: hideSendButton, ["message"]: message, ["formurl"]:listForms.puburl.url, ["hideButtonlink"]:true,});

			// console.log("..HOHOHO response sendDocLink .... ");  console.log(response);
			// this.showToast("Felicitations/Congratulations!", "success");    
			return response;
		}).catch(function(error){ 
			this2.setState({ ["isLoaderOpen"]: false});  
			//alert(error);
			const errObj=error;
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
				errMessage +="\n "+t("zqwNVFrmEl14_x")
			}
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage}); 
			console.log("ERROR WITH TRANSACTION"); 
			console.log(error);
			console.log("SHORT ERROR MESSAGE: "+errMessage);
			console.log("RWA RESPONSE"); 
			//console.log(rawError);
			this2.showToast(errMessage, "error"); 
		});
	 
	  };
	  
	  validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	  }

	  validatePhone = (txt) => {
		//const re = /^\d{10}$/;
		//const re = /^[0-9]{3} - [0-9]{3} - [0-9]{4}$/; 
		const re =/^([\(]*\+?[0-9]{1,3}[\)]*)?([\s]*)(\(?\+?)([0-9]{3})(\)?)([\s]*[\-]?)([0-9]{3})([\-]?)([0-9]{4})([\s]*)$/;
             
		//const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(txt);
	  }

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const text_label = this.state.text_label;
    const placeholder = this.state.placeholder;
    const field = this.state.field;
    const field_type = this.state.field_type;
    const field_default_value = this.state.field_default_value;
    const mask = this.state.mask;
    const field_service = this.state.field_service;
    const field_dataset = this.state.field_dataset;
    const tag_id = this.state.tag_id;
    const NdiFields = {id: id, name: name, text_label: text_label, placeholder: placeholder, field: field, field_type: field_type, field_default_value: field_default_value, mask: mask, field_service: field_service, field_dataset: field_dataset, tag_id: tag_id, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NdiFields[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateNdiFields(NdiFields, 1); 
		//this.props.addNdiFields(NdiFields); 
	}else{ 
		this.props.addOrUpdateNdiFields(NdiFields, 2);
		//this.props.updateNdiFields(NdiFields);
		
	}
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/NdiFields`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/NdiFields/${idx}`);
		//this.props.history.push(`/NdiFields/${this.props.NdiFields.id}`);
	}
  }
   
  handleSetMediaOption = (event) => { 
	 const sendoption=event.currentTarget.dataset.sendoption;
	 console.log("weeeeeeeeeeeeeelllllll ", sendoption);
	 this.setState({ ["mediatosend"]: sendoption }); 	
  }

  render() {
    const { t } = this.props;
	const open = this.props.openx;  
    const myToastMessage=this.state.toastrMessage;
    const toastrType=this.state.toastrType;
    // const Alert=this.Alert;
	const mediatosend=this.state.mediatosend;
	let listFormOptions = [];
	const form_name= this.form_name; 
	const listEmails=this.state.listEmails;

	const docList=this.state.docList;
	let docListformat = docList.length > 0
          && docList.map((item, i) => { 
            //   const firstname=item.firstname;  
			let title="";
			if(item.title===undefined || item.title===null || item.title===""){
				title=item.form_name;
			}else{ title=item.title; }

			const locObj={value:item.id, formname:item.form_name, target:item.target};
			//listFormOptions.push({ value: JSON.stringify(locObj), label: title });
			listFormOptions.push({ value: item.id, label: title, valuex: JSON.stringify(locObj) });
			this.listFormOptions=listFormOptions;
			console.log("MONITORING docList ", item.id);

			return (<option value={item.id} key={"dc"+i} data-formname={item.form_name} 
			data-target={item.target}>{title}</option>);
			  
			return (<option value={item.id} key={"dc"+i} data-formname={item.form_name} 
			data-target={item.target}>{item.form_name}</option>);  
          }, this);

	////////////////////////////////////////////////////////////
	//let productOptions = [];
    /*const productsList=this.state.productsList.products;
 
    let productList = productsList.length > 0
    	&& productsList.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        //console.log(item);
        if(item!==null){  // && item.id!==undefined
          const isUpsell=item.upsell;
          if(isUpsell!==null && isUpsell!==undefined){
            productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
          }else{
            //if(!item.type.includes('hidden') && item.titre!==''){  
              //console.log("LOOOOOOKING FOR ", item);
            if(item.type.indexOf('hidden') <0 && item.titre!==''){  
              productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
              //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
            }
          }
        }
      return (
        null
      );
    }, this);*/
	/////////////////////////////////////////////////////////////

	const hideButton=this.state.hideSendButton;
	const messageSrv=this.state.message;
	const formurl=this.state.formurl;
	const hideButtonlink=this.state.hideButtonlink;
	//console.log("hellooooooooooooooooo ", formurl);
	//   mediaToSendToList.push({role:"client+",fname:clientxFName, lname:clientxLName, media:"email", endmedia:clientxEmail});
	const mediaToSendToList=this.state.mediaToSendToList;
	const options = [];
	let mediaList = mediaToSendToList.length > 0
          && mediaToSendToList.map((item, i) => { 
            //   const firstname=item.firstname;  
			//options.push({mediatype:item.media, role:item.role, value:item.endmedia, name:item.fname+" "+item.lname, label:item.role+" "+item.fname+" "+item.lname+" "+item.media+":"+item.endmedia});
			if(mediatosend!==""){
				if(mediatosend===item.media){ 
					options.push({mediatype:item.media, role:item.role, value:item.endmedia, name:item.fname+" "+item.lname, label:item.role+" "+item.fname+" "+item.lname+" "+item.media+":"+item.endmedia});
					return (<option value={item.endmedia} key={"meddc"+i} data-mediatype={item.media} 
					data-role={item.role} data-name={item.fname+" "+item.lname}>
					{item.role+" "+item.fname+" "+item.lname+" "+item.media+":"+item.endmedia}
					</option>);  
				}else{
					return null;	
				}
			}

			options.push({mediatype:item.media, role:item.role, value:item.endmedia, name:item.fname+" "+item.lname, label:item.role+" "+item.fname+" "+item.lname+" "+item.media+":"+item.endmedia});
			return (<option value={item.endmedia} key={"meddc"+i} data-mediatype={item.media} 
			data-role={item.role} data-name={item.fname+" "+item.lname}>
			{item.role+" "+item.fname+" "+item.lname+" "+item.media+":"+item.endmedia}
			</option>);  
          }, this);

	//const options = ['One', 'Two', 'Three', 'Four'];
	let emailCls="", smsCls="";	
	//let selOptionTxt=this.state.selOptionTxt;
	let selOptionTxt="";
	//const mediatosend=this.state.mediatosend;
	const valueClientSearch=this.state.valueClientSearch;
    const inputValueClientSearch=this.state.inputValueClientSearch; 
	const objectClientSearch=this.state.objectClientSearch;
	//console.log("objectClientSearch-reeeeeeeeeender ", objectClientSearch); 
	//console.log("valueClientSearch-inputValueClientSearch vcs=", valueClientSearch, "ivc=", inputValueClientSearch); 
	if(objectClientSearch!==null){
		// options.push({mediatype:item.media, role:item.role, value:item.endmedia, name:item.fname+" "+item.lname, label:item.role+" "+item.fname+" "+item.lname+" "+item.media+":"+item.endmedia});
		// return (<option value={item.endmedia} key={"meddc"+i} data-mediatype={item.media} 
		// data-role={item.role} data-name={item.fname+" "+item.lname}>
		// {item.role+" "+item.fname+" "+item.lname+" "+item.media+":"+item.endmedia}
		selOptionTxt=objectClientSearch.label;

	}
	if(mediatosend!==""){
		if(mediatosend==="email"){ 
			emailCls=" fxmac "; smsCls="";	
		}else{
			emailCls=""; smsCls=" fxmac ";	
		}
	}

	const keyreload=this.state.keyreload;
	//const formKey=this.state.name+""+keyreload;
	const formKey=""+keyreload;
	const form_nameZx=this.state.form_name; 

	const formOrFile=this.props.formOrFile;
	const publicFormOrFile=this.props.publicFormOrFile;
	const name2=this.state.name2;
	const name=this.state.name;
	//defaultValue={colourOptions[2]}
	let nameValSel=null;
	//listFormOptions.push({ value: item.id, label: title, valuex: JSON.stringify(locObj) });
	console.log("MONITORING ", formKey, name2, name);

	if(formOrFile===2){
		this.form_name="gofile";
		this.target="gofile"; 
		console.log("fichierName=", publicFormOrFile.fileaddress, publicFormOrFile);
	}

	// <div key={formKey}>

    return (
      <div key={formKey}>
	     
        {/* <h3>{t('zqwacEditNdiFields_x')}</h3> */}
        {/* <form onSubmit={this.handleSubmit}> */}
		<form>
          	{formOrFile===1?	  
			<div className="form-group">
				<label htmlFor="name">{t('zqwNFFrmZn010_x')}</label>
				{/* <select name="name" id="name" defaultValue={this.state.name} onChange={this.handleChangeDocList} onBlur={this.handleFocusout}
				className={this.state.e_name ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwname_x')}>
					<option value="">Choisir/Select</option>
					{docListformat}
				</select> */}
				<Select defaultValue={name2} options={listFormOptions}  
				onChange={this.handleChangeDocList2}  />
				{/* <input type="text" name="name" id="name" defaultValue={this.state.name} onChange={this.handleChange} onBlur={this.handleFocusout}
				className={this.state.e_name ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwname_x')} />  */}
				{this.state.e_name && (<span className="text-danger">{FIELD_VALIDATION['name'].message}</span>)} 
			</div>:""}
			{formOrFile===2?
			<div className="form-group">
				<label htmlFor="name">
					{t('zqwNFFrmZn010b_x')} 
				</label>
				<br/>
				{publicFormOrFile.labelname} 
			</div>	  
			:""}

			<div className="form-group">
				<textarea id="formurl" name="formurl" className={'form-control'} rows="4" cols="50" defaultValue={formurl}></textarea>	 
			</div> 
			{hideButton===false?
			<> 
			 
			{/* <div className="form-group">
			<span className={"fxm "+emailCls} title={t("zqwNFFrmZn017_x")}><EmailIcon htmlColor="#0A3CA4" onClick={this.handleSetMediaOption} data-sendoption="email" /> </span> 
			<span className={"fxm "+smsCls} title={t("zqwNFFrmZn017_x")}><SmsIcon htmlColor="#0A3CA4" onClick={this.handleSetMediaOption} data-sendoption="SMS" /> </span> 		
			</div>  */}
			 
			<div className="form-group">
				<label htmlFor="mediatosend">{t('zqwNFFrmZn014_x')}</label>
				{/* <select name="mediatosend" id="mediatosend" defaultValue={this.state.mediatosend} onChange={this.handleChangeDocList} onBlur={this.handleFocusout}
				className={'form-control'}>
					<option value="">Choisir/Select</option>
					{mediaList}
				</select> 
				{this.state.e_name && (<span className="text-danger">{FIELD_VALIDATION['name'].message}</span>)} 
					 */}
			</div>

			<div className="form-group">
				<div className="row col-12">
			<Autocomplete
					// filterOptions={(options, params) => {
					// 	const filtered = filter(options, params);
					// 	// Suggest the creation of a new value
					// 	console.log("filterOptions ", params.inputValue, params);
					// 	if (params.inputValue !== '') {
					// 		filtered.push(`Add "${params.inputValue}"`);
					// 		console.log("GOOOOO filterOptions ", params.inputValue, params);
					// 	}
					// 	return filtered;
					// }}

					value={valueClientSearch} 
        			inputValue={inputValueClientSearch}

					onInputChange={(event, newInputValue) => {
						//setInputValue(newInputValue);
						//console.log('onInputChange event TYPE ', event);
						//console.log('onInputChange subevent TYPE ', event.nativeEvent);
						//this.setState({ ["inputValueClientSearch"]: newInputValue }); 
						if(event.nativeEvent.bubbles===true){ //"PointerEvent.type"
							this.setState({ ["inputValueClientSearch"]: newInputValue }); 
						}
						//console.log('onInputChange search for autocomplete ='+newInputValue);
						if(newInputValue.length >2){ 
							//goto the server 
							//this.getClientSearchSuggestion(newInputValue); 
							//this.setState({ ["inputValueClientSearch"]: newInputValue }); 
							  setTimeout(() => {
								//this.getClientSearchSuggestion(newInputValue); 
								console.log("neoVaaaaaaaaaaal ", newInputValue);
							  }, 0); 
						}
					  }}
			  
					  onChange={(event, newValue) => { 
			  
						if (typeof newValue === 'string') {
						  // timeout to avoid instant validation of the dialog's form.
						  setTimeout(() => {
							this.setState({ ["valueClientSearch"]: newValue, ["valueClientSearch"]: newValue });  
						  });
						  this.setState({ ["valueClientSearch"]: newValue, ["objectClientSearch"]: newValue }); 
						} else if (newValue && newValue.inputValue) {
						   
						  this.setState({ ["valueClientSearch"]: newValue.inputValue, ["objectClientSearch"]: newValue });  
						} else {
						  //setValue(newValue);
						  //this.setState({ ["valueClientSearch"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)}); 
						  this.setState({ ["valueClientSearch"]: '',  ["objectClientSearch"]: newValue}); 
						  //this.setState({ ["clientInfo"]: newValue}); 
						  
						  //this.setState(clientInfo ); //xaza  this.setState({ clientInfo });
						  //if(propstep==1){ this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]:3 }); }
						  //else{ this.setState({ ["globalStepWorkflow"]: 2 }); }
			   
						   
			  
						  //get client Address
						//   const clientId=newValue.id;
						//   setTimeout(() => {
						// 	this.getClientFullAddress(clientId); 
						//   }, 0); 
						}
			  
						
						//this.setState({ ["valueClientSearch"]: newValue }); 
						//console.log('letUS onChange search for autocomplete ', newValue);
					  }}

					selectOnFocus
					clearOnBlur
					handleHomeEndKeys
					options={options}
					//renderOption={(option) => option.value}
					renderOption={(option) => option.label}
					//getOptionLabel={(option) => option.label}
					getOptionLabel={(option) => {
						// e.g value selected with enter, right from the input
						//return option.title;
						//this.setState({ ["selectedIdSuggestion"]: option.id}); 
						//console.log(';;;;; sel id is '+option.id); 
			
						if (typeof option === 'string') {
						  return option;
						}
						if (option.inputValue) {
						  return option.inputValue;
						}
						//return option.title;
						//this.setState({ ["selectedIdSuggestion"]: option.id}); 
					  //console.log('zigZAG-sel id is '+option.label); 
					  return option.label;
					  return (option.firstname+' '+option.lastname+' '+option.email);
						
					  }}
			
					  //renderOption={(option) => (option.firstname+' '+option.lastname+' '+option.email)}
					style={{ minWidth: 400 }}
					freeSolo
					renderInput={(params) => (
					<TextField {...params} label={t("zqwNFFrmZn016_x")} 
						variant="outlined" />
					)}

					// renderInput={(params) => ( 
					// 	<TextField
					// 	  {...params}
					// 	  label={t("zqwNFFrmZn016_x")} 
					// 	  margin="normal"
					// 	  variant="outlined"
					// 	  InputProps={{ ...params.InputProps, type: 'search' }}
					// 	/>
					//   )}
				/></div>
				<span style={{fontWeight:"bolder", color:"blue"}}>{selOptionTxt}</span> 
			</div> 

			{/* form_name==="deeee"? */}
			{/* form_name   zqwNFFrmZn010_x     <AttachMoneyIcon /> */}
			{form_nameZx.indexOf("testament-")>-1?
			<div>		
			  <div className="form-group">
				<label htmlFor="emailextras">Autres Emails (Testament)  				  
				<IconButton edge="end" aria-label="Add more/ajouter plus"  
					onClick={this.handleChangeXTraEmailsadd}>
					+ 
				</IconButton>
				</label> 
			  </div>		 
				{listEmails!==null && listEmails.map((option, ind) => ( 
					<div className="row">
			  		<div className="md-10"> 
						<input type="email" name="emails" id="emails" defaultValue={option}
							data-index={ind} 
							onChange={this.handleChangeXTraEmails} 
							// onBlur={this.handleFocusout}  
              				className={'form-control'} 
							placeholder={'Email'} /> &nbsp; 
					</div>
					<div className="md-2"> 
						<button type="button" className="btn btn-danger" data-index={ind} 
						onClick={this.handleChangeXTraEmailsrem}>x</button> &nbsp;
					</div>
					</div> 
		  		))}	 
				
			</div>:""
			}
			</>:"" 
			} 

			<div className="form-group">
				<label htmlFor="name"> &nbsp; </label>	 
			</div> 
			 
			{hideButton===false?
			<div className="form-group">
				<button type="button" className="btn btn-primary" onClick={this.handleSubmitDoc}>{t('zqwNFFrmZn011_x')}</button> &nbsp; 
				{/* <button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button> */}
			</div>:"" }
			{hideButtonlink===false?
			<div className="form-group">
				<button type="button" className="btn btn-primary" onClick={this.handleSubmitGetLink}>{t('zqwNFFrmZn013_x')}</button> &nbsp;  
			</div>:"" }

			{messageSrv!==""?
				<div className="form-group">
				{/* <label>{ messageSrv }</label> */}
				{/* { messageSrv } */}
				<div dangerouslySetInnerHTML={{__html: messageSrv}}></div>
			</div>:"" }

			{/* <div className="form-group">
				<label htmlFor="emailextras">Testament Emails  				  
				<IconButton edge="end" aria-label="Add more/ajouter plus"  
					onClick={this.handleChangeXTraEmailsadd}>
					+ 
				</IconButton>
				</label> 
			</div>		 
				{listEmails!==null && listEmails.map((option, ind) => ( 
					// <ListItem key={"filx"+ind} className="lstfilesep">
					// <div className="md-9"> option.labelnodel!==undefined && (option.value
					// 		<input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
            		//   className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
					<div className="row">
			  		<div className="md-10"> 
						<input type="email" name="emails" id="emails" defaultValue={option}
							data-index={ind} 
							onChange={this.handleChangeXTraEmails} 
							// onBlur={this.handleFocusout}  
              				className={'form-control'} 
							placeholder={'Email'} /> &nbsp; 
					</div>
					<div className="md-2"> 
						<button type="button" className="btn btn-danger" data-index={ind} 
						onClick={this.handleChangeXTraEmailsrem}>x</button> &nbsp;
					</div>
					</div> 
		  		))}	  */}
			{/* </div> */}
			{formOrFile===1?	  
			<div className="form-group red">
				<label htmlFor="name">{t('zqwCViewEl13_x')}</label>
			</div>:""}
        </form>
		<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
  		<Loader  openx={this.state.isLoaderOpen}  />
      </div>
    );
  }
}

//const TransNdiFieldsForm = withTranslation()(NdiFieldsForm); 
const mapStateToProps = (state) => ({ NdiFields: state.NdiFields });

const mapDispatchToProps = { getDocLinkType, sendDocLink, getDocLink, getDownloadFileLink };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(PublicLinkForm));
