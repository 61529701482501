import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_ZXCOMPANIES = 'GET_ZXCOMPANIES';
export const ADD_ZXCOMPANIES = 'ADD_ZXCOMPANIES';
export const RECEIVE_ZXCOMPANIESX = 'RECEIVE_ZXCOMPANIESX';
export const REMOVE_ZXCOMPANIES = 'REMOVE_ZXCOMPANIES';
export const UPDATE_ZXCOMPANIES = 'UPDATE_ZXCOMPANIES';
export const REPLACE_ZXCOMPANIES = 'REPLACE_ZXCOMPANIES';
const apiUrl = API_DOMAIN + '/zxcompanies'; 

axiosDefaults(null);

export const getZxCompaniesAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}.php`)
      .then(response => {
        dispatch({type: RECEIVE_ZXCOMPANIES, ZxCompanies: response.data.zxcompanies.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addZxCompanies = (ZxCompanies) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: ZxCompanies.id,
		name: ZxCompanies.name,
		joining_date: ZxCompanies.joining_date,
		address: ZxCompanies.address,
		phone: ZxCompanies.phone,
		dob: ZxCompanies.dob,
		zipcode: ZxCompanies.zipcode,
		suite: ZxCompanies.suite,
		city: ZxCompanies.city,
		state: ZxCompanies.state,
		country: ZxCompanies.country,
		company_code: ZxCompanies.company_code,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_ZXCOMPANIES, payload: {
			id: data.id,
			name: data.name,
			joining_date: data.joining_date,
			address: data.address,
			phone: data.phone,
			dob: data.dob,
			zipcode: data.zipcode,
			suite: data.suite,
			city: data.city,
			state: data.state,
			country: data.country,
			company_code: data.company_code,
		}})
      })
      .then(() => {
        history.push("/ZxCompanies")
      })
      .catch(error => { throw(error) });
  };
};
export const getZxCompanies = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.zxcompanies);		  
        return dispatch({type: RECEIVE_ZXCOMPANIESX, ZxCompaniesOne: response.data.zxcompanies});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getZxCompaniesGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`./ZxCompanies/${id}/edit`)
  };
};
export const deleteZxCompanies = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_ZXCOMPANIES, payload: {id}})
      })
      .then(() => {
        history.push("/ZxCompanies")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateZxCompanies = (ZxCompanies) => {
  const id = ZxCompanies.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${ZxCompanies.id}`, {
		name: ZxCompanies.name,
		joining_date: ZxCompanies.joining_date,
		address: ZxCompanies.address,
		phone: ZxCompanies.phone,
		dob: ZxCompanies.dob,
		zipcode: ZxCompanies.zipcode,
		suite: ZxCompanies.suite,
		city: ZxCompanies.city,
		state: ZxCompanies.state,
		country: ZxCompanies.country,
		company_code: ZxCompanies.company_code,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_ZXCOMPANIES, payload: {
			id: data.id,
			name: data.name,
			joining_date: data.joining_date,
			address: data.address,
			phone: data.phone,
			dob: data.dob,
			zipcode: data.zipcode,
			suite: data.suite,
			city: data.city,
			state: data.state,
			country: data.country,
			company_code: data.company_code,
		}})
        dispatch({type: REPLACE_ZXCOMPANIES, payload: {
			id: data.id,
			name: data.name,
			joining_date: data.joining_date,
			address: data.address,
			phone: data.phone,
			dob: data.dob,
			zipcode: data.zipcode,
			suite: data.suite,
			city: data.city,
			state: data.state,
			country: data.country,
			company_code: data.company_code,
		}})
      })
      .then(() => {
        history.push(`./ZxCompanies/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateZxCompanies = (ZxCompanies, actionMode) => {
	if(actionMode===1){
        return addZxCompanies(ZxCompanies);
      }else {
        return updateZxCompanies(ZxCompanies);
      }
};