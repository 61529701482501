import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getNdiFields, deleteNdiFields } from '../../actions/NdiFieldsAction';

class NdiFieldsInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		name:'', e_name: false,
		text_label:'', e_text_label: false,
		placeholder:'', e_placeholder: false,
		field:'', e_field: false,
		field_type:'', e_field_type: false,
		field_default_value:'', e_field_default_value: false,
		mask:'', e_mask: false,
		field_service:'', e_field_service: false,
		field_dataset:'', e_field_dataset: false,
		tag_id:'', e_tag_id: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getNdiFields(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getNdiFields(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.NdiFieldsOne);  console.log("0000********FIN**********");
		this.setState(response.NdiFieldsOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const NdiFields = this.props.NdiFields;
    const NdiFields = this.state; 
    return (
      <div>
        <h2>NdiFields</h2>
        <hr/>
        <p><label>{t('zqwid_x')}</label> &nbsp; &nbsp; #{NdiFields.id}</p>
        <p><label>{t('zqwname_x')}</label><br/>{NdiFields.name}</p>
        <p><label>{t('zqwtext_label_x')}</label><br/>{NdiFields.text_label}</p>
        <p><label>{t('zqwplaceholder_x')}</label><br/>{NdiFields.placeholder}</p>
        <p><label>{t('zqwfield_x')}</label><br/>{NdiFields.field}</p>
        <p><label>{t('zqwfield_type_x')}</label><br/>{NdiFields.field_type}</p>
        <p><label>{t('zqwfield_default_value_x')}</label><br/>{NdiFields.field_default_value}</p>
        <p><label>{t('zqwmask_x')}</label><br/>{NdiFields.mask}</p>
        <p><label>{t('zqwfield_service_x')}</label><br/>{NdiFields.field_service}</p>
        <p><label>{t('zqwfield_dataset_x')}</label><br/>{NdiFields.field_dataset}</p>
        <p><label>{t('zqwtag_id_x')}</label><br/>{NdiFields.tag_id}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/NdiFields/${NdiFields.id}/edit`, state: { NdiFields: NdiFields } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNdiFields(NdiFields.id)}>          
            {t('Delete')}
          </button>
          <Link to="/NdiFields" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransNdiFieldsInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ NdiFields: state.NdiFields });                 

const mapDispatchToProps = { getNdiFields, deleteNdiFields };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NdiFieldsInfo));   

