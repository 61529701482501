import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateNeoPaymentsCredits, getNeoPaymentsCredits } from '../../actions/NeoPaymentsCreditsAction'; 
import { Validations } from '../../utils/validations'; 

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['id_clients']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['amount']={check:false, chkType:'BigDecimal', message:'incorrect value'};
FIELD_VALIDATION['user_creation']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['user_update']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['creation_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['update_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['status']={check:true, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION['observation']={check:false, chkType:'String', message:'incorrect value'};

class NeoPaymentsCreditsForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		id_clients:'', e_id_clients: false,
		amount:'', e_amount: false,
		user_creation:'', e_user_creation: false,
		user_update:'', e_user_update: false,
		creation_date:'', e_creation_date: false,
		update_date:'', e_update_date: false,
		status:'', e_status: false,
		observation:'', e_observation: false,
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.NeoPaymentsCredits.id);
		if(this.props.NeoPaymentsCredits.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getNeoPaymentsCredits(idx);
			Promise.resolve(this.props.getNeoPaymentsCredits(idx))
			.then((response)=>{
				this.setState(response.NeoPaymentsCreditsOne);
				return response;
			});
		}	
	}	
  }

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const id_clients = this.state.id_clients;
    const amount = this.state.amount;
    const user_creation = this.state.user_creation;
    const user_update = this.state.user_update;
    const creation_date = this.state.creation_date;
    const update_date = this.state.update_date;
    const status = this.state.status;
    const observation = this.state.observation;
    const NeoPaymentsCredits = {id: id, id_clients: id_clients, amount: amount, user_creation: user_creation, user_update: user_update, creation_date: creation_date, update_date: update_date, status: status, observation: observation, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoPaymentsCredits[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateNeoPaymentsCredits(NeoPaymentsCredits, 1); 
		//this.props.addNeoPaymentsCredits(NeoPaymentsCredits); 
	}else{ 
		this.props.addOrUpdateNeoPaymentsCredits(NeoPaymentsCredits, 2);
		//this.props.updateNeoPaymentsCredits(NeoPaymentsCredits);
		
	}
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/NeoPaymentsCredits`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/NeoPaymentsCredits/${idx}`);
		//this.props.history.push(`/NeoPaymentsCredits/${this.props.NeoPaymentsCredits.id}`);
	}
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h3>{this.actionMode==='new'? t('zqwacAddNeoPaymentsCredits_x'): t('zqwacEditNeoPaymentsCredits_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		  
		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="id_clients">{t('zqwid_clients_x')}</label>
            <input type="text" name="id_clients" id="id_clients" defaultValue={this.state.id_clients} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id_clients ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_clients_x')} /> 
			{this.state.e_id_clients && (<span className="text-danger">{FIELD_VALIDATION['id_clients'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="amount">{t('zqwamount_x')}</label>
            <input type="text" name="amount" id="amount" defaultValue={this.state.amount} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_amount ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwamount_x')} /> 
			{this.state.e_amount && (<span className="text-danger">{FIELD_VALIDATION['amount'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="user_creation">{t('zqwuser_creation_x')}</label>
            <input type="text" name="user_creation" id="user_creation" defaultValue={this.state.user_creation} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_user_creation ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwuser_creation_x')} /> 
			{this.state.e_user_creation && (<span className="text-danger">{FIELD_VALIDATION['user_creation'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="user_update">{t('zqwuser_update_x')}</label>
            <input type="text" name="user_update" id="user_update" defaultValue={this.state.user_update} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_user_update ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwuser_update_x')} /> 
			{this.state.e_user_update && (<span className="text-danger">{FIELD_VALIDATION['user_update'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="creation_date">{t('zqwcreation_date_x')}</label>
            <input type="text" name="creation_date" id="creation_date" defaultValue={this.state.creation_date} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_creation_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcreation_date_x')} /> 
			{this.state.e_creation_date && (<span className="text-danger">{FIELD_VALIDATION['creation_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="update_date">{t('zqwupdate_date_x')}</label>
            <input type="text" name="update_date" id="update_date" defaultValue={this.state.update_date} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_update_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwupdate_date_x')} /> 
			{this.state.e_update_date && (<span className="text-danger">{FIELD_VALIDATION['update_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="status">{t('zqwstatus_x')}</label>
            <select name="status" id="status" defaultValue={this.state.status} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_status ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstatus_x')}> 
                        <option value="">Please Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
			{this.state.e_status && (<span className="text-danger">{FIELD_VALIDATION['status'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="observation">{t('zqwobservation_x')}</label>
            <input type="text" name="observation" id="observation" defaultValue={this.state.observation} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_observation ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwobservation_x')} /> 
			{this.state.e_observation && (<span className="text-danger">{FIELD_VALIDATION['observation'].message}</span>)} 
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			<button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>
          </div>
        </form>
      </div>
    );
  }
}

//const TransNeoPaymentsCreditsForm = withTranslation()(NeoPaymentsCreditsForm); 
const mapStateToProps = (state) => ({ NeoPaymentsCredits: state.NeoPaymentsCredits });

const mapDispatchToProps = { addOrUpdateNeoPaymentsCredits, getNeoPaymentsCredits };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoPaymentsCreditsForm));
