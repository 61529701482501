import { RECEIVE_NDIPRODUCTS, UPDATE_NDIPRODUCTS } from '../actions/NdiProductsAction';

export default function singleNdiProductsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDIPRODUCTS:
      return action.NdiProducts;
    case UPDATE_NDIPRODUCTS:
      return {
        id: action.id,
name: action.payload.name,
product_type_id: action.payload.product_type_id,
product_block: action.payload.product_block,
published: action.payload.published,
last_update: action.payload.last_update,
user_id: action.payload.user_id,
      }
    default:
      return state;
  }
};