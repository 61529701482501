import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import BlockIcon from "@material-ui/icons/Block";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditeIcon from "@material-ui/icons/Edit";
import { withTranslation } from 'react-i18next';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
  updateIcon: {
    color: "#1976d2",
  },
  deleteIcon: {
    color: "#FF0000",
  },
};

//selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows}
class CustomToolbarSelectventeconfpta extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   error: null,
    //   products: [],
    //   response: {}
    // }
 
    this.isEdit=this.props.isEdit;
    this.isDelete=this.props.isDelete;
  }

  handleClickInverseSelection = () => {
    const nextSelectedRows = this.props.displayData.reduce((nextSelectedRows, _, index) => {
      if (!this.props.selectedRows.data.find(selectedRow => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    this.props.setSelectedRows(nextSelectedRows);
  };

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  handleClickBlockSelected = () => {
    console.log(`block users with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
  };
  
   handleDeleteClick = () => {
    /*console.log("Delete click! ", this.props.selectedRows); // a user can do something with these selectedRow values
    console.log(`deleteeeee block users with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
    //this.props.selectedRows.data.map(row => row.dataIndex);
    this.props.selectedRows.data.map(row => { console.log("TestDelete click! ",  row.dataIndex); console.log(row); });
    console.log("TEEEEEEEEEEEEEEEEEEEEEEST OTRO! ");
    this.props.selectedRows.data.map(row => { console.log("...TestDelete click! ",  row.index); console.log(row);  });*/
    this.props.deleteXRecords(this.props.selectedRows); 
  };

  handleDeleteClick2 = () => {
    /*var grid=this.props.displayData;
    console.log("000000 Delete click! ", this.props.selectedRows); // a user can do something with these selectedRow values
    console.log(`00000 deleteeeee block users with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
    console.log("00000 KOOOOOOOOOOOOOOOOOOOL! ");*/
    this.props.deleteXRecords(this.props.selectedRows); 
  };

  handleUpdateClick = (event) => { 
    const index=event.currentTarget.dataset.index; //event.currentTarget.dataset.index; event.target 
    //console.log("UPDATE or other action KOOOOOOOOOOOOOOOOOOOL! "+index); 
    this.props.updateXRecords(this.props.selectedRows, index); 
  };
  
  

  render() {
    const { classes } = this.props;

    this.isEdit=this.props.isEdit;
    this.isDelete=this.props.isDelete;
    //const t= this.props.t;
    const { t } = this.props;

    const update2=(<Tooltip title={"icon Update Record 1"}>
    <IconButton className={classes.iconButton} onClick={this.handleUpdateClick}>
        <EditeIcon className={classes.updateIcon} />
      </IconButton>
    </Tooltip>);
    const update3=(<span/>); 
    const updateHTML=this.isEdit? update2 : update3; 

    const delete2=(<Tooltip title={"icon Delete 2"}>
      <IconButton className={classes.iconButton} onClick={this.handleDeleteClick2}>
        <DeleteIcon className={classes.deleteIcon} />
      </IconButton>
    </Tooltip>);
    const delete3=(<span/>); 
    const deleteHTML=this.isDelete? delete2 : delete3; 


    return (
      <div className={classes.iconContainer}>
        </div>
    );

    return (
      <div className={classes.iconContainer}>
        <Tooltip title={t("zqwCViewEl49_x")}>
          <IconButton className={classes.iconButton} onClick={this.handleClickDeselectAll}>
            <IndeterminateCheckBoxIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("zqwCViewEl50_x")}>
          <IconButton className={classes.iconButton} onClick={this.handleClickInverseSelection}>
            <CompareArrowsIcon className={[classes.icon, classes.inverseIcon].join(" ")} />
          </IconButton>
        </Tooltip>

        <Tooltip title={t("zqwNDICItx2_x")}>
          <IconButton className={classes.iconButton} data-index={"mkconflict"} onClick={this.handleUpdateClick}>
            {t("zqwNDICItx3_x")}
          </IconButton>
        </Tooltip>
        <Tooltip title={t("viewstat_x")}>
          <IconButton className={classes.iconButton} data-index={"conflict"} onClick={this.handleUpdateClick}>
            {t("view_x")}
          </IconButton>
        </Tooltip>
        <Tooltip title={"Input de données Neolegal/Neolegal Data Input"}>
          <IconButton className={classes.iconButton} data-index={"ndi"} onClick={this.handleUpdateClick}>
          {t("zqwNDIabr_x")}
          </IconButton>
        </Tooltip>
        <Tooltip title={"Rendez-vous Neolegal/Neolegal Appointment(rendez-vous)"}>
          <IconButton className={classes.iconButton} data-index={"rendezvous"} onClick={this.handleUpdateClick}>
            {"Cal"}
          </IconButton>
        </Tooltip>         
        <Tooltip title={"Lead2Action"}>
          <IconButton className={classes.iconButton} data-index={"lead2Action"} onClick={this.handleUpdateClick}>
            {"Lead2Action"}
          </IconButton>
        </Tooltip> 

        {/* <Tooltip title={"Flux de vente/sale flow"}>
          <IconButton className={classes.iconButton} data-index={"saleflow"} onClick={this.handleUpdateClick}>
            {"flow"}
          </IconButton>
        </Tooltip>
          
        <Tooltip title={t("zqwCViewEl44b_x")}>
          <IconButton className={classes.iconButton} data-index={"viewemailinvoice"} onClick={this.handleUpdateClick}> 
           <ChromeReaderModeIcon className={classes.icon} />
          </IconButton>
        </Tooltip> */}
        
        {/* <Tooltip title={"Assignez Vente à Quelqu'un"}>
          <IconButton className={classes.iconButton} data-index={"assign"} onClick={this.handleUpdateClick}> 
          Assignez
          </IconButton>
        </Tooltip> */}

        {/* <Tooltip title={"Block selected"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickBlockSelected}>
            <BlockIcon className={classes.icon} />
          </IconButton>
        </Tooltip> */}


     
      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelectventeconfpta" })(CustomToolbarSelectventeconfpta);