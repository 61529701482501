import React from 'react';
//import logo from './logo.svg';
//import './App.css';
/*import { About } from "./About"; 
import { Topics } from "./Topics"; 
import { Products } from "./Products"; 
//import { App } from "./App"; 
import { AppPage2 } from "./AppPage2";*/
 
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

//class App extends React.Component {
export class BaseLeftPanel extends React.Component {
  constructor(props) {
    super(props);
	////this.isLeftMenuOpen = true;
	//this.state = {isLeftMenuOpen: true};
	    

    //this.handleLeftPanelMenu = this.handleLeftPanelMenu.bind(this);
    ////this.handleSubmit = this.handleSubmit.bind(this);
	////this.handleCancel = this.handleCancel.bind(this);
  }
  
  /*handleLeftPanelMenu(event) {
	event.preventDefault();
	//this.setState(this.initialState);
	let valMenuToggle=true;
	if(this.state.isLeftMenuOpen){
      valMenuToggle=false;
    } else {
      valMenuToggle=true;
    }
	
	this.setState({
      isLeftMenuOpen: valMenuToggle
    });
	
	console.log("TEEEEEEEEEEEEEEEEEEEEST TOGGLE ");
	console.log(" "+this.state.isLeftMenuOpen);
	
  }*/

render() {
	//<div className="hold-transition sidebar-mini">
//function App() {
  return (
	
	  <aside className="main-sidebar sidebar-dark-primary elevation-4">
		 
		<a href="index3.html" className="brand-link">
		  <img src={ require("./adminltelibs/dist/img/AdminLTELogo.png") } alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: .8}} />
		  <span className="brand-text font-weight-light">AdminLTE 3</span>
		</a>

		 
		<div className="sidebar"> 
		  <div className="user-panel mt-3 pb-3 mb-3 d-flex">
			<div className="image">
			  <img src={ require("./adminltelibs/dist/img/user2-160x160.jpg") } className="img-circle elevation-2" alt="User Image" />
			</div>
			<div className="info">
			  <a href="#" className="d-block">Alexander Pierce</a>
			</div>
		  </div>
 
		  <nav className="mt-2">
			<ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
			   
			  <li className="nav-item has-treeview menu-open">
				<a href="#" className="nav-link active">
				  <i className="nav-icon fas fa-tachometer-alt"></i>
				  <p>
					Starter Pages
					<i className="right fas fa-angle-left"></i>
				  </p>
				</a>
				<ul className="nav nav-treeview">
				  <li className="nav-item">
					<a href="#" className="nav-link active">
					  <i className="far fa-circle nav-icon"></i>
					  <p>Active Page</p>
					</a>
				  </li>
				  <li className="nav-item">
					<a href="#" className="nav-link">
					  <i className="far fa-circle nav-icon"></i>
					  <p>Inactive Page</p>
					</a>
				  </li>
				</ul>
			  </li>
			  <li className="nav-item">
				<a href="#" className="nav-link">
				  <i className="nav-icon fas fa-th"></i>
				  <p>
					Simple Link
					<span className="right badge badge-danger">New</span>
				  </p>
				</a>
			  </li>
			  
			  
			  
			  
			  
			  
			  
			  <li className="nav-header">MULTI LEVEL EXAMPLE</li>
			  <li className="nav-item">
				<a href="#" className="nav-link">
				  <i className="fas fa-circle nav-icon"></i>
				  <p>Level 1</p>
				</a>
			  </li>
			  <li className="nav-item has-treeview">
				<a href="#" className="nav-link">
				  <i className="nav-icon fas fa-circle"></i>
				  <p>
					Level 1
					<i className="right fas fa-angle-left"></i>
				  </p>
				</a>
				<ul className="nav nav-treeview">
				  <li className="nav-item">
					<a href="#" className="nav-link">
					  <i className="far fa-circle nav-icon"></i>
					  <p>Level 2</p>
					</a>
				  </li>
				  <li className="nav-item has-treeview">
					<a href="#" className="nav-link">
					  <i className="far fa-circle nav-icon"></i>
					  <p>
						Level 2
						<i className="right fas fa-angle-left"></i>
					  </p>
					</a>
					<ul className="nav nav-treeview">
					  <li className="nav-item">
						<a href="#" className="nav-link">
						  <i className="far fa-dot-circle nav-icon"></i>
						  <p>Level 3</p>
						</a>
					  </li>
					  <li className="nav-item">
						<a href="#" className="nav-link">
						  <i className="far fa-dot-circle nav-icon"></i>
						  <p>Level 3</p>
						</a>
					  </li>
					  <li className="nav-item">
						<a href="#" className="nav-link">
						  <i className="far fa-dot-circle nav-icon"></i>
						  <p>Level 3</p>
						</a>
					  </li>
					</ul>
				  </li>
				  <li className="nav-item">
					<a href="#" className="nav-link">
					  <i className="far fa-circle nav-icon"></i>
					  <p>Level 2</p>
					</a>
				  </li>
				</ul>
			  </li>
			  <li className="nav-item">
				<a href="#" className="nav-link">
				  <i className="fas fa-circle nav-icon"></i>
				  <p>Level 1</p>
				</a>
			  </li>
			  
			  
			  <li className="nav-header">LABELS</li>
			  <li className="nav-item">
				<a href="#" className="nav-link">
				  <i className="nav-icon far fa-circle text-danger"></i>
				  <p className="text">Important</p>
				</a>
			  </li>
			  <li className="nav-item">
				<a href="#" className="nav-link">
				  <i className="nav-icon far fa-circle text-warning"></i>
				  <p>Warning</p>
				</a>
			  </li>
			  <li className="nav-item">
				<a href="#" className="nav-link">
				  <i className="nav-icon far fa-circle text-info"></i>
				  <p>Informational</p>
				</a>
			  </li>
			  
			  
			  <li className="nav-header">REAL PAGE</li>
			  <li className="nav-item">
				<Link to="./"><i className="nav-icon far fa-circle text-danger"></i>Home</Link>
			  </li>
			  <li className="nav-item">
				<Link to="./about"><i className="nav-icon far fa-circle text-warning"></i>About</Link>
			  </li>
			  <li className="nav-item">
				<Link to="./topics"><i className="nav-icon far fa-circle text-info"></i>Topics</Link>
			  </li>
			  <li className="nav-item">
				<Link to="./products"><i className="nav-icon far fa-circle text-danger"></i>Products</Link>
			  </li>
			  <li className="nav-item">
				<Link to="./page2"><i className="nav-icon far fa-circle text-warning"></i>Page 2</Link>
			  </li>
			  <li className="nav-item">
				<Link to="./productsmui"><i className="nav-icon far fa-circle text-danger"></i>Products g MUI</Link>
			  </li>
			   
			  
			  
{/*<li className="nav-item">
	<Link to="./zxcompanies"><i className="nav-icon far fa-circle text-danger"></i>zxcompanies</Link>
</li>
<li className="nav-item">
	<Link to="./zxcompanysettings"><i className="nav-icon far fa-circle text-danger"></i>zxcompanysettings</Link>
</li>
<li className="nav-item">
	<Link to="./zxcostsegments"><i className="nav-icon far fa-circle text-danger"></i>zxcostsegments</Link>
</li>
<li className="nav-item">
	<Link to="./zxemployeecontacts"><i className="nav-icon far fa-circle text-danger"></i>zxemployeecontacts</Link>
</li>
<li className="nav-item">
	<Link to="./zxemployeesettings"><i className="nav-icon far fa-circle text-danger"></i>zxemployeesettings</Link>
</li>
<li className="nav-item">
	<Link to="./zxemployees"><i className="nav-icon far fa-circle text-danger"></i>zxemployees</Link>
</li>
<li className="nav-item">
	<Link to="./zxholidays"><i className="nav-icon far fa-circle text-danger"></i>zxholidays</Link>
</li>
<li className="nav-item">
	<Link to="./zxphonetypes"><i className="nav-icon far fa-circle text-danger"></i>zxphonetypes</Link>
</li>
<li className="nav-item">
	<Link to="./zxphones"><i className="nav-icon far fa-circle text-danger"></i>zxphones</Link>
</li>
<li className="nav-item">
	<Link to="./zxpunches"><i className="nav-icon far fa-circle text-danger"></i>zxpunches</Link>
</li>
<li className="nav-item">
	<Link to="./zxrelationtypes"><i className="nav-icon far fa-circle text-danger"></i>zxrelationtypes</Link>
</li>
<li className="nav-item">
	<Link to="./zxteams"><i className="nav-icon far fa-circle text-danger"></i>zxteams</Link>
</li>
<li className="nav-item">
	<Link to="./zxtimebanks"><i className="nav-icon far fa-circle text-danger"></i>zxtimebanks</Link>
</li>
<li className="nav-item">
	<Link to="./zxtimeoffs"><i className="nav-icon far fa-circle text-danger"></i>zxtimeoffs</Link>
</li>
<li className="nav-item">
	<Link to="./zxtimesheets"><i className="nav-icon far fa-circle text-danger"></i>zxtimesheets</Link>
</li>
<li className="nav-item">
	<Link to="./zxworkinoutcontrols"><i className="nav-icon far fa-circle text-danger"></i>zxworkinoutcontrols</Link>
</li>
<li className="nav-item">
	<Link to="./zxworkschedules"><i className="nav-icon far fa-circle text-danger"></i>zxworkschedules</Link>
</li>
<li className="nav-item">
	<Link to="./zxworkschedulesspecial"><i className="nav-icon far fa-circle text-danger"></i>zxworkschedulesspecial</Link>
</li>
<li className="nav-item">
	<Link to="./zxworktimes"><i className="nav-icon far fa-circle text-danger"></i>zxworktimes</Link>
</li>*/}
 			  
			  
			  
			  
<li className="nav-item">
	<Link to="./zonlinedespachosprog"><i className="nav-icon far fa-circle text-danger"></i>zonlinedespachosprog</Link>
</li>
<li className="nav-item">
	<Link to="./zonlineinteracciones"><i className="nav-icon far fa-circle text-danger"></i>zonlineinteracciones</Link>
</li>
<li className="nav-item">
	<Link to="./zonlineinteraccionespoll"><i className="nav-icon far fa-circle text-danger"></i>zonlineinteraccionespoll</Link>
</li>
<li className="nav-item">
	<Link to="./zonlinelogacessosusuarios"><i className="nav-icon far fa-circle text-danger"></i>zonlinelogacessosusuarios</Link>
</li>
<li className="nav-item">
	<Link to="./zonlineloginfracasos"><i className="nav-icon far fa-circle text-danger"></i>zonlineloginfracasos</Link>
</li>
<li className="nav-item">
	<Link to="./zonlineroles"><i className="nav-icon far fa-circle text-danger"></i>zonlineroles</Link>
</li>
<li className="nav-item">
	<Link to="./zonlineusuarios"><i className="nav-icon far fa-circle text-danger"></i>zonlineusuarios</Link>
</li>
<li className="nav-item">
	<Link to="./vwdespachosalerts"><i className="nav-icon far fa-circle text-danger"></i>vwdespachosalerts</Link>
</li>
<li className="nav-item">
	<Link to="./vwfacturas"><i className="nav-icon far fa-circle text-danger"></i>vwfacturas</Link>
</li>
<li className="nav-item">
	<Link to="./vwfacturasnopagadas"><i className="nav-icon far fa-circle text-danger"></i>vwfacturasnopagadas</Link>
</li>
<li className="nav-item">
	<Link to="./vwfacturaspagadas"><i className="nav-icon far fa-circle text-danger"></i>vwfacturaspagadas</Link>
</li>
<li className="nav-item">
	<Link to="./vwpagos"><i className="nav-icon far fa-circle text-danger"></i>vwpagos</Link>
</li>			  
			  
			  
			  
			</ul>
		  </nav>
		  
		</div>

			
	  </aside>
	  
	  

  );
}
}

//export default BaseLeftPanel;
//export BaseLeftPanel;
