import { RECEIVE_NEOSALESPLANNINGWEEKLY, UPDATE_NEOSALESPLANNINGWEEKLY } from '../actions/NeoSalesPlanningWeeklyAction';

export default function singleNeoSalesPlanningWeeklyReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NEOSALESPLANNINGWEEKLY:
      return action.NeoSalesPlanningWeekly;
    case UPDATE_NEOSALESPLANNINGWEEKLY:
      return {
        id: action.id,
week: action.payload.week,
week_start: action.payload.week_start,
week_end: action.payload.week_end,
expected_quantity: action.payload.expected_quantity,
expected_amount: action.payload.expected_amount,
invoice_quantity: action.payload.invoice_quantity,
invoice_amount: action.payload.invoice_amount,
payment_quantity: action.payload.payment_quantity,
payment_amount: action.payload.payment_amount,
creation_date: action.payload.creation_date,
last_update: action.payload.last_update,
user_creation: action.payload.user_creation,
user_update: action.payload.user_update,
      }
    default:
      return state;
  }
};