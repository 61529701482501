import { RECEIVE_NDIFIELDSTRANSLATION, ADD_NDIFIELDSTRANSLATION, REMOVE_NDIFIELDSTRANSLATION, REPLACE_NDIFIELDSTRANSLATION } from '../actions/NdiFieldsTranslationAction';

const initialState = { NdiFieldsTranslation: [] }
export default function NdiFieldsTranslationReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NDIFIELDSTRANSLATION:
      return action.NdiFieldsTranslation;
    case ADD_NDIFIELDSTRANSLATION:
      return [action.payload, ...state];
    case REMOVE_NDIFIELDSTRANSLATION:
      return state.filter(NdiFieldsTranslation => NdiFieldsTranslation.id !== action.payload.id);
    case REPLACE_NDIFIELDSTRANSLATION:
      return state.map((NdiFieldsTranslation) => {
        if (NdiFieldsTranslation.id === action.payload.id) {
          return {
            ...NdiFieldsTranslation,
field_id: action.payload.field_id,
lang: action.payload.lang,
text_label: action.payload.text_label,
placeholder: action.payload.placeholder,
field_default_value: action.payload.field_default_value,
field_service: action.payload.field_service,
field_dataset: action.payload.field_dataset,
          }
        } else return NdiFieldsTranslation;
      })
    default:
      return state;
  }
}
