const messagesEn={
"welcome_x": "Bienvenido",
"hi_x": "Hola",
"Create_x": "Crear",
"New_x": "Nuevo",
"Edit_x": "Editar",
"Update_x": "Actualizar",
"Delete_x": "Eliminar",
"Cancel_x": "Cancelar",
"Close_x": "Cerrar",
"Create_x": "Crear",
   
"Yes_x": "Si",
"No_x": "No",
"Yes": "Si",
"No": "No",


"uname_x": "Email",
"upass_x": "Contraseña",
"tipoUsuario_x": "Tipo Usuario",
"Seleccionar_x": "Seleccionar",
"Cliente_x": "Cliente",
"Operador_x": "Operador",
"Administrativo_x": "Administrativo",
"idioma_x": "Idioma",
"Recuerdame_x": "Recuerdame",
"login_x": "Entrar",
"recoverpass_x": "Olvido mi contraseña",
"resetpass_x": "Resetear Contraseña de Usuario",
"qemail_x": "Correo/Email",
"btnresetpass_x": "Resetear Contraseña",
"btnCancelar_x": "Cancelar",
 


"cliente_x": "Cliente",
"nombre_x": "Nombre",
"Ruc_Cedula_x": "Ruc Cedula",
"dv_x": "Dv",
"Encargado_CxC_x": "Encargado CxC",
"telefono_x": "Telefono",
"direccion_x": "Direccion",
"habilitado_x": "Habilitado",
"Si_x": "Si", 
"Email_x": "Email",
"Retencion_Impuesto_x": "Retencion Impuesto",
"fecha_registro_x": "Fecha Registro",
"errorFoundonform_x": "Tienes algunos errores en el formulario.",
"acAddClientes_x": "Agregar Clientes",
"acEditClientes_x": "Editar Clientes",
"gridIndexesfound_x": "Lo sentimos, tienes que poner el gancho en un solo registro para poder editarlo.",
"gridnoMatch_x": "Lo sentimos, no encontramos ningun registro para desplagar",


"gridtoolTiph_x": "Ordenar",
"gridSortfor_x": "Ordenar por",
"gridnext_x": "Página siguiente",
"gridprevious_x": "Página anterior",
"gridrowsPerPage_x": "Filas por página:",
"griddisplayRows_x": "de",
"gridsearch_x": "Buscar",
"griddownloadCsv_x": "Descargar CSV",
"gridprint_x": "Imprimir",
"gridviewColumns_x": "Ver columnas",
"gridfilterTable_x": "Tabla de filtros",
"gridall_x": "Todos",
"gridftitle_x": "FILTROS",
"gridreset_x": "RESETEAR",
"gridvctitle_x": "Mostrar columnas",
"gridvctitleAria_x": "Mostrar / Ocultar columnas de tabla",
"Clientes_x": "Clientes",
"gridNoRecordfound_x": "No se encontró ningún registro para mostrar",



"menuhome_x": "Inicio",
"menudashboard_x": "Tablero",
"menuzonlineusuarios_x": "Perfil de usuario",
"menuvwdespachosalerts_x": "Despachos",
"menuvwfacturas_x": "Todas las Facturas",
"menuvwfacturasnopagadas_x": "Facturas no Pagadas",
"menuvwfacturaspagadas_x": "Facturas Pagadas",
"menuvwpagos_x": "Pagos Realizados",
"menuid_x": "Id",
"ZxCompanies_x": "Lista de Empresas/Sucursales",
"togoutcnfti_x": "Estimado usuario",
"logoutconfir_x": "Usted esta seguro(a) de que quieres salir del sistema",
"changeLang_x": "Cambiar Idioma",










"zqwid_x": "Id",
"zqwkey_x": "Key",
"zqwall_access_x": "All Access",
"zqwcontroller_x": "Controller",
"zqwdate_created_x": "Date Created",
"zqwdate_modified_x": "Date Modified",
"zqwerrorFoundonform_x": "you have some errors on the form.",
"zqwacAddAccess_x": "Add Access",
"zqwacEditAccess_x": "Edit Access",
"zqwgridIndexesfound_x": "Sorry, you have to check only one record to be able to edit it.",
"zqwgridnoMatch_x": "Sorry, no matching records found",
"zqwgridtoolTiph_x": "Sort",
"zqwgridSortfor_x": "Sort for",
"zqwgridnext_x": "Next Page",
"zqwgridprevious_x": "Previous Page",
"zqwgridrowsPerPage_x": "Rows per page:",
"zqwgriddisplayRows_x": "of",
"zqwgridsearch_x": "Search",
"zqwgriddownloadCsv_x": "Download CSV",
"zqwgridprint_x": "Print",
"zqwgridviewColumns_x": "View Columns",
"zqwgridfilterTable_x": "Filter Table",
"zqwgridall_x": "All",
"zqwgridftitle_x": "FILTERS",
"zqwgridreset_x": "RESET",
"zqwgridvctitle_x": "Show Columns",
"zqwgridvctitleAria_x": "Show/Hide Table Columns",
"zqwAccess_x": "Access",
"zqwgridNoRecordfound_x": "No record found to display",
"zqwuser_id_x": "User Id",
"zqwlevel_x": "Level",
"zqwignore_limits_x": "Ignore Limits",
"zqwis_private_key_x": "Is Private Key",
"zqwip_addresses_x": "Ip Addresses",
"zqwacAddKeys_x": "Add Keys",
"zqwacEditKeys_x": "Edit Keys",
"zqwKeys_x": "Keys",
"zqwuri_x": "Uri",
"zqwmethod_x": "Method",
"zqwparams_x": "Params",
"zqwapi_key_x": "Api Key",
"zqwip_address_x": "Ip Address",
"zqwtime_x": "Time",
"zqwrtime_x": "Rtime",
"zqwauthorized_x": "Authorized",
"zqwresponse_code_x": "Response Code",
"zqwacAddLogs_x": "Add Logs",
"zqwacEditLogs_x": "Edit Logs",
"zqwLogs_x": "Logs",
"zqwid_clients_x": "Id Clients",
"zqwaddress_x": "Address",
"zqwcity_x": "City",
"zqwprovince_x": "Province",
"zqwcp_x": "Cp",
"zqwcountry_x": "Country",
"zqwcreated_x": "Created",
"zqwactive_x": "Active",
"zqwtel_x": "Tel",
"zqwext_x": "Ext",
"zqwcompany_x": "Company",
"zqwname_x": "Name",
"zqwtype_x": "Type",
"zqwinvoicing_x": "Invoicing",
"zqwdelivery_x": "Delivery",
"zqwacAddNeoAddress_x": "Add NeoAddress",
"zqwacEditNeoAddress_x": "Edit NeoAddress",
"zqwNeoAddress_x": "Neoaddress",
"zqwid_campaign_x": "Id Campaign",
"zqwraw_data_x": "Raw Data",
"zqwacAddNeoCampaign_x": "Add NeoCampaign",
"zqwacEditNeoCampaign_x": "Edit NeoCampaign",
"zqwNeoCampaign_x": "Neocampaign",
"zqwid_folders_x": "Id Folders",
"zqwfirstname_x": "Firstname",
"zqwlastname_x": "Lastname",
"zqwcell_x": "Cell",
"zqwemail_x": "Email",
"zqwns_x": "Ns",
"zqwcase_x": "Case",
"zqwstatus_x": "Status",
"zqwclosed_x": "Closed",
"zqwpending_x": "Pending",
"zqwinvalid_x": "Invalid",
"zqwopened_x": "Opened",
"zqwid_transactions_x": "Id Transactions",
"zqwlanguage_x": "Language",
"zqwacAddNeoCases_x": "Add NeoCases",
"zqwacEditNeoCases_x": "Edit NeoCases",
"zqwNeoCases_x": "Neocases",
"zqwterms_x": "Terms",
"zqwid_users_x": "Id Users",
"zqwpush_x": "Push",
"zqwid_zendesk_x": "Id Zendesk",
"zqwsms_x": "Sms",
"zqwstripe_id_x": "Stripe Id",
"zqwacAddNeoClients_x": "Add NeoClients",
"zqwacEditNeoClients_x": "Edit NeoClients",
"zqwNeoClients_x": "Neoclients",
"zqwdefinition_x": "Definition",
"zqwticket_id_x": "Ticket Id",
"zqwclient_id_x": "Client Id",
"zqwdelay_x": "Delay",
"zqwdone_x": "Done",
"zqwdate_executed_x": "Date Executed",
"zqwresult_x": "Result",
"zqwticket_type_x": "Ticket Type",
"zqwdeskapp_x": "Deskapp",
"zqwdesk_id_x": "Desk Id",
"zqwguid_x": "Guid",
"zqwacAddNeoDesk_x": "Add NeoDesk",
"zqwacEditNeoDesk_x": "Edit NeoDesk",
"zqwNeoDesk_x": "Neodesk",
"zqwid_notes_x": "Id Notes",
"zqwfile_location_x": "File Location",
"zqwfile_name_x": "File Name",
"zqwfile_extension_x": "File Extension",
"zqwfile_html_x": "File Html",
"zqwis_file_x": "Is File",
"zqwmodified_x": "Modified",
"zqwmetas_x": "Metas",
"zqworg_name_x": "Org Name",
"zqwuniqid_x": "Uniqid",
"zqwacAddNeoDocuments_x": "Add NeoDocuments",
"zqwacEditNeoDocuments_x": "Edit NeoDocuments",
"zqwNeoDocuments_x": "Neodocuments",
"zqwacAddNeoDocuments1_x": "Add NeoDocuments1",
"zqwacEditNeoDocuments1_x": "Edit NeoDocuments1",
"zqwNeoDocuments1_x": "Neodocuments1",
"zqwid_event_x": "Id Event",
"zqwevent_start_x": "Event Start",
"zqwevent_end_x": "Event End",
"zqwduration_x": "Duration",
"zqwevent_tz_x": "Event Tz",
"zqwuser_x": "User",
"zqwservice_id_x": "Service Id",
"zqwacAddNeoEvents_x": "Add NeoEvents",
"zqwacEditNeoEvents_x": "Edit NeoEvents",
"zqwNeoEvents_x": "Neoevents",
"zqwacAddNeoFolders_x": "Add NeoFolders",
"zqwacEditNeoFolders_x": "Edit NeoFolders",
"zqwNeoFolders_x": "Neofolders",
"zqwpayed_x": "Payed",
"zqwsent_x": "Sent",
"zqwoverdue_x": "Overdue",
"zqwview_x": "View",
"zqwdeclined_x": "Declined",
"zqwrefund_x": "Refund",
"zqwdue_date_x": "Due Date",
"zqwsub_total_x": "Sub Total",
"zqwtax1_x": "Tax1",
"zqwtax2_x": "Tax2",
"zqwtoken_x": "Token",
"zqwcode_x": "Code",
"zqwdeclined_code_x": "Declined Code",
"zqwtransact_x": "Transact",
"zqwtax_total_x": "Tax Total",
"zqwtotal_x": "Total",
"zqwid_address_x": "Id Address",
"zqwacAddNeoInvoices_x": "Add NeoInvoices",
"zqwacEditNeoInvoices_x": "Edit NeoInvoices",
"zqwNeoInvoices_x": "Neoinvoices",
"zqwacAddNeoLawyer_x": "Add NeoLawyer",
"zqwacEditNeoLawyer_x": "Edit NeoLawyer",
"zqwNeoLawyer_x": "Neolawyer",
"zqwacAddNeoMailHook_x": "Add NeoMailHook",
"zqwacEditNeoMailHook_x": "Edit NeoMailHook",
"zqwNeoMailHook_x": "Neomailhook",
"zqwid_transaction_x": "Id Transaction",
"zqwid_user_x": "Id User",
"zqwacAddNeoMed_x": "Add NeoMed",
"zqwacEditNeoMed_x": "Edit NeoMed",
"zqwNeoMed_x": "Neomed",
"zqwnote_x": "Note",
"zqwpublic_x": "Public",
"zqwid_lawyer_x": "Id Lawyer",
"zqwread_x": "Read",
"zqwacAddNeoNotes_x": "Add NeoNotes",
"zqwacEditNeoNotes_x": "Edit NeoNotes",
"zqwNeoNotes_x": "Neonotes",
"zqwid_invoices_x": "Id Invoices",
"zqwamount_x": "Amount",
"zqwacAddNeoPayments_x": "Add NeoPayments",
"zqwacEditNeoPayments_x": "Edit NeoPayments",
"zqwNeoPayments_x": "Neopayments",
"zqwtransdata_x": "Transdata",
"zqwsignature_x": "Signature",
"zqwidTrans_x": "Idtrans",
"zqwsource_x": "Source",
"zqwacAddNeoPaymentsHook_x": "Add NeoPaymentsHook",
"zqwacEditNeoPaymentsHook_x": "Edit NeoPaymentsHook",
"zqwNeoPaymentsHook_x": "Neopaymentshook",
"zqwdescription_x": "Description",
"zqwdata_json_x": "Data Json",
"zqwacAddNeoProducts_x": "Add NeoProducts",
"zqwacEditNeoProducts_x": "Edit NeoProducts",
"zqwNeoProducts_x": "Neoproducts",
"zqwiso_x": "Iso",
"zqwtax1_name_x": "Tax1 Name",
"zqwtax2_name_x": "Tax2 Name",
"zqwacAddNeoProvinces_x": "Add NeoProvinces",
"zqwacEditNeoProvinces_x": "Edit NeoProvinces",
"zqwNeoProvinces_x": "Neoprovinces",
"zqwclientemail_x": "Clientemail",
"zqwreferalname_x": "Referalname",
"zqwreferalemail_x": "Referalemail",
"zqwadw_id_x": "Adw Id",
"zqwclientvoucher_x": "Clientvoucher",
"zqwreferalvoucher_x": "Referalvoucher",
"zqwclientused_x": "Clientused",
"zqwreferalused_x": "Referalused",
"zqwclientdateused_x": "Clientdateused",
"zqwreferaldateused_x": "Referaldateused",
"zqwclientidtrans_x": "Clientidtrans",
"zqwreferalidtrans_x": "Referalidtrans",
"zqwacAddNeoReferal_x": "Add NeoReferal",
"zqwacEditNeoReferal_x": "Edit NeoReferal",
"zqwNeoReferal_x": "Neoreferal",
"zqwdate_x": "Date",
"zqwcommission_x": "Commission",
"zqwidstripe_x": "Idstripe",
"zqwapplication_x": "Application",
"zqwcustomer_id_x": "Customer Id",
"zqwtaxable_x": "Taxable",
"zqworphelin_x": "Orphelin",
"zqwnom_x": "Nom",
"zqwprenom_x": "Prenom",
"zqwidclient_x": "Idclient",
"zqwid_produit_x": "Id Produit",
"zqwproduit_x": "Produit",
"zqwis_business_x": "Is Business",
"zqwclient_created_x": "Client Created",
"zqwacAddNeoRevenus_x": "Add NeoRevenus",
"zqwacEditNeoRevenus_x": "Edit NeoRevenus",
"zqwNeoRevenus_x": "Neorevenus",
"zqwcreated_campaign_x": "Created Campaign",
"zqwcreated_clients_x": "Created Clients",
"zqwacAddNeoSalesCampaign_x": "Add NeoSalesCampaign",
"zqwacEditNeoSalesCampaign_x": "Edit NeoSalesCampaign",
"zqwNeoSalesCampaign_x": "Neosalescampaign",
"zqwcategory_x": "Category",
"zqwid_product_x": "Id Product",
"zqwcat_id_x": "Cat Id",
"zqwacAddNeoServices_x": "Add NeoServices",
"zqwacEditNeoServices_x": "Edit NeoServices",
"zqwNeoServices_x": "Neoservices",
"zqwacAddNeoSrfaxHook_x": "Add NeoSrfaxHook",
"zqwacEditNeoSrfaxHook_x": "Edit NeoSrfaxHook",
"zqwNeoSrfaxHook_x": "Neosrfaxhook",
"zqwcurrency_x": "Currency",
"zqwtype_id_x": "Type Id",
"zqwacAddNeoTarifs_x": "Add NeoTarifs",
"zqwacEditNeoTarifs_x": "Edit NeoTarifs",
"zqwNeoTarifs_x": "Neotarifs",
"zqwacAddNeoTarifsUsers_x": "Add NeoTarifsUsers",
"zqwacEditNeoTarifsUsers_x": "Edit NeoTarifsUsers",
"zqwNeoTarifsUsers_x": "Neotarifsusers",
"zqwacAddNeoTaskdef_x": "Add NeoTaskdef",
"zqwacEditNeoTaskdef_x": "Edit NeoTaskdef",
"zqwNeoTaskdef_x": "Neotaskdef",
"zqwacAddNeoTrackingHook_x": "Add NeoTrackingHook",
"zqwacEditNeoTrackingHook_x": "Edit NeoTrackingHook",
"zqwNeoTrackingHook_x": "Neotrackinghook",
"zqwid_products_x": "Id Products",
"zqwid_vouchers_x": "Id Vouchers",
"zqwpreachat_x": "Preachat",
"zqwacAddNeoTransactions_x": "Add NeoTransactions",
"zqwacEditNeoTransactions_x": "Edit NeoTransactions",
"zqwNeoTransactions_x": "Neotransactions",
"zqwusername_x": "Username",
"zqwpassword_x": "Password",
"zqwadmin_x": "Admin",
"zqwsupervisor_x": "Supervisor",
"zqwlawyer_x": "Lawyer",
"zqwagent_x": "Agent",
"zqwlsupervisor_x": "Lsupervisor",
"zqwcustomer_x": "Customer",
"zqwmarketing_x": "Marketing",
"zqwsales_x": "Sales",
"zqwlegalintern_x": "Legalintern",
"zqwelawyer_x": "Elawyer",
"zqwlast_login_x": "Last Login",
"zqwacAddNeoUsers_x": "Add NeoUsers",
"zqwacEditNeoUsers_x": "Edit NeoUsers",
"zqwNeoUsers_x": "Neousers",
"zqwacAddNeoVentes_x": "Add NeoVentes",
"zqwacEditNeoVentes_x": "Edit NeoVentes",
"zqwNeoVentes_x": "Neoventes",
"zqwpercentage_x": "Percentage",
"zqwvalid_start_x": "Valid Start",
"zqwvalid_end_x": "Valid End",
"zqwusage_x": "Usage",
"zqwacAddNeoVouchers_x": "Add NeoVouchers",
"zqwacEditNeoVouchers_x": "Edit NeoVouchers",
"zqwNeoVouchers_x": "Neovouchers",
"zqwCreation_Date_x": "Creation Date",
"zqwDescription_x": "Description",
"zqwAmount_x": "Amount",
"zqwComission_x": "Comission",
"zqwID_x": "Id",
"zqwacAddStripeReport_x": "Add StripeReport",
"zqwacEditStripeReport_x": "Edit StripeReport",
"zqwStripeReport_x": "Stripereport",


 
}

export default messagesEn;

	
