import { RECEIVE_NEOSALESPLANNINGDAILY, UPDATE_NEOSALESPLANNINGDAILY } from '../actions/NeoSalesPlanningDailyAction';

export default function singleNeoSalesPlanningDailyReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NEOSALESPLANNINGDAILY:
      return action.NeoSalesPlanningDaily;
    case UPDATE_NEOSALESPLANNINGDAILY:
      return {
        id: action.id,
week_plan_id: action.payload.week_plan_id,
work_date: action.payload.work_date,
expected_quantity: action.payload.expected_quantity,
expected_amount: action.payload.expected_amount,
invoice_quantity: action.payload.invoice_quantity,
invoice_amount: action.payload.invoice_amount,
payment_quantity: action.payload.payment_quantity,
payment_amount: action.payload.payment_amount,
creation_date: action.payload.creation_date,
last_update: action.payload.last_update,
user_creation: action.payload.user_creation,
user_update: action.payload.user_update,
      }
    default:
      return state;
  }
};