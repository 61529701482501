import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 
import productInfoMultiple from '../appProductInfoMulti'; 

export const RECEIVE_NEOVENTES = 'GET_NEOVENTES';
export const ADD_NEOVENTES = 'ADD_NEOVENTES';
export const RECEIVE_NEOVENTESX = 'RECEIVE_NEOVENTESX';
export const REMOVE_NEOVENTES = 'REMOVE_NEOVENTES';
export const UPDATE_NEOVENTES = 'UPDATE_NEOVENTES';
export const REPLACE_NEOVENTES = 'REPLACE_NEOVENTES';
export const RECEIVE_NEOCLIENTSX = 'RECEIVE_NEOCLIENTSX';
export const RECEIVE_NEOADDRESSX = 'RECEIVE_NEOADDRESSX';
export const RECEIVE_NEOCODEPROMOX = 'RECEIVE_NEOCODEPROMOX';
const apiUrl = API_DOMAIN + '/neoventes'; 
//const apiUrlProduct = API_DOMAIN + '/products'; 
const apiUrlProduct = API_DOMAIN + '/productlist';
const apiUrlPeoplesales = API_DOMAIN + '/salesplanning/peoplesales'; 
const apiUrl2 = API_DOMAIN + '/b2b/clients'; 
 
const apiUrlBase=API_DOMAIN;  
const apiUrlMulti = apiUrl + '/multiactioninvoice'; 
 



axiosDefaults(null);

export const getNeoVentesAll = () => {
  //const user=(localStorage.getItem("user")).split(':');
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {   
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.data});
      })
      .catch(error => { throw(error); });
  };
};

export const getNeoVentesAllPending = () => { 
  const status="sent,partial";
  return (dispatch) => { 
    return axios.get(`${apiUrl}?status=${status}`)
      .then(response => {   
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.data});
      })
      .catch(error => { throw(error); });
  };
};

export const getNeoVentesAllXStaus = (statusList) => { 
  let status="payed";
  if(statusList!==""){ status=statusList; }
  return (dispatch) => { 
    return axios.get(`${apiUrl}?status=${status}`)
      .then(response => {   
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.data});
      })
      .catch(error => { throw(error); });
  };
};

// /chargedesk/checkstatus
export const getChargeDeskStatus = (chargeId) => { 
  //const status="sent,partial";
  return (dispatch) => { 
    return axios.get(`${API_DOMAIN}/chargedesk/checkstatus?chargeId=${chargeId}`)
      .then(response => {   
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.chargeInfo});
      })
      .catch(error => { throw(error); });
  };
};

export const addNeoVentes = (NeoVentes) => { 
  const auth_id=38;
  //return axios.post(`${apiUrl}/sales?auth_id=${auth_id}`, NeoVentes)
  return (dispatch) => {
    return axios.post(`${apiUrl}/salesb2b`, NeoVentes)
      .then(response => {
        let data = response.data.data;
        const mydataAr= [] ;
        mydataAr.push({
          id: data.id,
          transaction: data.transaction,
          // name: data.name,
          // description: data.description,
          // amount: data.amount,
        });

        //{ ZxCompanies: [] }

        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT  ');  
        //console.log(response);   
        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
        //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
        //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });
        return dispatch({type: ADD_NEOVENTES, NeoVentes: data})
      })
      .then(response => {
        //history.push("/NeoVentes")
        return response;
      })
      //.catch(error => { throw(error) });
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1"); console.log(err.response);
          console.log(err);
          throw(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          throw(errMessage);
        } else {
          // anything else
          console.log("error falls here #3"); console.log(err);   
          throw(err); 
        }
    });
  };
};

export const addNeoVentesSC = (NeoVentes) => { 
  const auth_id=38;
  //return axios.post(`${apiUrl}/sales?auth_id=${auth_id}`, NeoVentes)
  return (dispatch) => {
    return axios.post(`${apiUrl}/salessc`, NeoVentes)
      .then(response => {
        let data = response.data.data;
        const mydataAr= [] ;
        mydataAr.push({
          id: data.id,
          transaction: data.transaction,
          // name: data.name,
          // description: data.description,
          // amount: data.amount,
        });

        //{ ZxCompanies: [] }

        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT  ');  
        //console.log(response);   
        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
        //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
        //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });
        return dispatch({type: ADD_NEOVENTES, NeoVentes: data})
      })
      .then(response => {
        //history.push("/NeoVentes")
        return response;
      })
      //.catch(error => { throw(error) });
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1"); console.log(err.response);
          console.log(err);
          throw(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          throw(errMessage);
        } else {
          // anything else
          console.log("error falls here #3"); console.log(err);   
          throw(err); 
        }
    });
  };
};

export const addOrUpdateRefunds = (NeoObjects) => {  
  //return axios.post(`${apiUrl}/sales?auth_id=${auth_id}`, NeoObjects)
  if(NeoObjects.isMULTIPART!==undefined){
    const myAxios={isMULTIPART:1}
   axiosDefaults(myAxios); 
  }
  return (dispatch) => {
    return axios.post(`${apiUrlBase}/refunds/refunds`, NeoObjects)
      .then(response => {
        //let data = response.data.data;    
        let data = response.data;    
        //{ ZxCompanies: [] }  
        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT  ');  
        //console.log(response);   
        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
        //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
        //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });
        return dispatch({type: ADD_NEOVENTES, NeoVentes: data})
      })
      .then(response => {
        //history.push("/NeoVentes")
        return response;
      })
      //.catch(error => { throw(error) });
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1"); console.log(err.response);
          console.log(err);
          throw(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          throw(errMessage);
        } else {
          // anything else
          console.log("error falls here #3"); console.log(err);   
          throw(err); 
        }
    });
  };
};


export const getNeoVentes = (id) => {
	 //console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  //console.log("response from server .... ");  //console.log(response.data.neoventes);		  
        return dispatch({type: RECEIVE_NEOVENTESX, NeoVentesOne: response.data.neoventes});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};

export const getNeoVentesByPeople = (obj) => {
  //const user=(localStorage.getItem("user")).split(':');
  const date=obj.date; const date2=obj.date2; const groupByDayWeekMonth=obj.dayWeekMonth;
  return (dispatch) => {
    return axios.get(`${apiUrlPeoplesales}?date=${date}&date2=${date2}&grpby=${groupByDayWeekMonth}`)
      .then(response => {   
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data});
      })
      .catch(error => { throw(error); });
  };
};


export const getClientTransactionsFullInfo = (obj) => {
  //const id=obj.clientId;  
  const id=isNaN(obj.clientId)? 0:obj.clientId;
  const id2=obj.ticketId;
	return (dispatch) => {
	  return axios.get(`${apiUrlBase}/neofoldersfullinfo?clientId=${id}&ticketId=${id2}`)
		.then(response => {
			console.log("getClientTransactions", response); //response.data.data.ndifields
      //return dispatch({type: RECEIVE_NDIFIELDS, NdiFields: response.data.data});
      return dispatch({type: RECEIVE_NEOVENTESX, NeoVentesOne: response.data.data});
		})
		.catch(error => { throw(error); });
	};
};

 
 
export const getClientPaymentsFullInfo = (obj) => {
  //const id=obj.clientId;
  const id=isNaN(obj.clientId)? 0:obj.clientId;
  const id2=obj.ticketId;
  let neoemail="";
  if(obj.neoemail!==undefined && obj.neoemail!==null){ neoemail=""+obj.neoemail; }
	return (dispatch) => {
	  return axios.get(`${apiUrlBase}/neofolderspaymentlist?clientId=${id}&ticketId=${id2}&neoemail=${neoemail}`)
		.then(response => {
			console.log("getClientTransactions", response); //response.data.data.ndifields
      //return dispatch({type: RECEIVE_NDIFIELDS, NdiFields: response.data.data});
      return dispatch({type: RECEIVE_NEOVENTESX, NeoVentesOne: response.data.data});
		})
		.catch(error => { throw(error); });
	};
};


export const getClientSearch = (serachObj) => {
  //console.log("getClientSearch BEFORE response from server .... "+search); 
  //return axios.get(`${apiUrl}/${id}`)
  const auth_id=38;
  //return axios.get(`${apiUrl}/searchclients?auth_id=${auth_id}&search=${search}`) 
  //return axios.get(`${apiUrl}/searchclients?search=${search}`)
  const search=serachObj.search;  const searchEmail=serachObj.searchEmail;
  let searchURL=""; const clientId=serachObj.clientId!==undefined?serachObj.clientId:0;  
  if(searchEmail===""){
    searchURL=`${apiUrl}/searchclients?search=${search}&clientId=${clientId}`;
  }else{ searchURL=`${apiUrl}/searchclients?search=${search}&searchEmail=${searchEmail}&clientId=${clientId}`; }
 return (dispatch) => {
   return axios.get(searchURL)
     .then(response => {
     //console.log("response from server .... ");  //console.log(response.data.data);		  ////console.log(response.data.neoclients);		  
       if(response.data.data.length==0){
        return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: []});
       }
      //return dispatch({type: RECEIVE_NEOVENTESX, NeoVentesOne: response.data.neoventes});
       return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data.neoclients});
     })
     .catch(error => { 
       throw(error); 
     });
 };
};


export const getB2BClientSearch = (serachObj) => {
  //console.log("getClientSearch BEFORE response from server .... "+search); 
  //return axios.get(`${apiUrl}/${id}`)
  const auth_id=38;
  //return axios.get(`${apiUrl}/searchclients?auth_id=${auth_id}&search=${search}`) 
  //return axios.get(`${apiUrl}/searchclients?search=${search}`)
  const search=serachObj.search;  const searchEmail=serachObj.searchEmail;
  let searchURL="";
  if(searchEmail===""){
    searchURL=`${apiUrl2}/search?search=${search}`;
  }else{ searchURL=`${apiUrl2}/search?search=${search}&searchEmail=${searchEmail}`; }
 return (dispatch) => {
   return axios.get(searchURL)
     .then(response => {
     //console.log("response from server .... ");  //console.log(response.data.data);		  ////console.log(response.data.neoclients);		  
       if(response.data.data.length==0){
        return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: []});
       }
      //return dispatch({type: RECEIVE_NEOVENTESX, NeoVentesOne: response.data.neoventes});
       return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data.neoclients});
     })
     .catch(error => { 
       throw(error); 
     });
 };
};

export const checkClientEligibility = (serachObj) => {
  //console.log("getClientSearch BEFORE response from server .... "+search); 
  //return axios.get(`${apiUrl}/${id}`)
  //return axios.get(`${apiUrl}/searchclients?auth_id=${auth_id}&search=${search}`) 
  //return axios.get(`${apiUrl}/searchclients?search=${search}`)
  
  //const search=serachObj.search;  const searchEmail=serachObj.searchEmail;
  const search=serachObj.id;  const searchEmail=serachObj.searchEmail;
  let searchURL="";
  // if(searchEmail===""){
  //   searchURL=`${apiUrl2}/search?search=${search}`;
  // }else{ searchURL=`${apiUrl2}/search?search=${search}&searchEmail=${searchEmail}`; }
  searchURL=`${apiUrl2}/eligibility?id=${search}`;
 return (dispatch) => {
   return axios.get(searchURL)
     .then(response => {
        //console.log("eligibility response from server .... ");  console.log(response.data.data);		  ////console.log(response.data.neoclients);		  
       if(response.data.data.length==0){
        return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: []});
       }
      //return dispatch({type: RECEIVE_NEOVENTESX, NeoVentesOne: response.data.neoventes});
       return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data});
     })
     .catch(error => { 
       throw(error); 
     });
 };
};

///b2b/clients/contractslist
export const getB2BContractListDetails = (serachObj) => {
  //console.log("getClientSearch BEFORE response from server .... "+search); 
  //return axios.get(`${apiUrl}/${id}`)
  const auth_id=38;
  //return axios.get(`${apiUrl}/searchclients?auth_id=${auth_id}&search=${search}`) 
  //return axios.get(`${apiUrl}/searchclients?search=${search}`)
  const search=serachObj.search;  const searchEmail=serachObj.searchEmail;
  let searchURL="";
  // if(searchEmail===""){
  //   searchURL=`${apiUrl2}/contractslist?search=${search}`;
  // }else{ searchURL=`${apiUrl2}/contractslist?search=${search}&searchEmail=${searchEmail}`; }

  searchURL=`${apiUrl2}/contractslist?search=${search}`;
 return (dispatch) => {
   return axios.get(searchURL)
     .then(response => {
     //console.log("response from server .... ");  //console.log(response.data.data);		  ////console.log(response.data.neoclients);		  
       if(response.data.data.length==0){
        return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: []});
       }
      //return dispatch({type: RECEIVE_NEOVENTESX, NeoVentesOne: response.data.neoventes});
       return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data.contractslist});
     })
     .catch(error => { 
       throw(error); 
     });
 };
};

 

export const saveB2BClient = (NeoVentes) => { 
  const auth_id=38;
  //return axios.post(`${apiUrl}/sales?auth_id=${auth_id}`, NeoVentes)
  return (dispatch) => {
    return axios.post(`${apiUrl2}`, NeoVentes)
      .then(response => {
        let data = response.data.data;
        //let data = response.data.data.neoclients;
        
        console.log('0000000 RESPONSE saveB2BClient  ');  
        console.log(response);   
        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
        //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
        //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } }); 

        //return dispatch({type: ADD_NEOVENTES, NeoVentes: data});
        return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: data});
      })
      .then(response => {
        //history.push("/NeoVentes")
        return response;
      })
      //.catch(error => { throw(error) });
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1"); console.log(err.response);
          console.log(err);
          console.log("SIMPLE ERROR", err.response.data.data.errorMessage);
          if(err.response.data.data.errorMessage!==undefined){
            const blokcrr={data:err.response.data.data.errorMessage, statusText:"Error", status:"ok with error"}
            //throw(err.response.data.data.errorMessage);
            throw(blokcrr);
          }else{
            throw(err.response);
          }
          //throw(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          throw(errMessage);
        } else {
          // anything else
          console.log("error falls here #3"); console.log(err);   
          throw(err); 
        }
    });
  };
};


 
export const getClientAddress = (clientId) => {
  //console.log("getClientAddress BEFORE response from server .... "+clientId); 
  //return axios.get(`${apiUrl}/${id}`)
  const auth_id=38;
 return (dispatch) => {
   return axios.get(`${apiUrl}/clientaddress?clientId=${clientId}`)
     .then(response => {
        //console.log("response from server .... ");  //console.log(response.data.data);		  ////console.log(response.data.neoclients);	 
       //return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data.neoclients});
       return dispatch({type: RECEIVE_NEOADDRESSX, NeoAddressOne: response.data.data});  //response.data.data.neoaddress
     })
     .catch(error => { 
       throw(error); 
     });
 };
};

export const getClientAndAddress = (clientId) => {
  //console.log("getClientAddress BEFORE response from server .... "+clientId); 
  //return axios.get(`${apiUrl}/${id}`)
  const auth_id=38;
 return (dispatch) => {
   return axios.get(`${apiUrlBase}/clientinfo?clientId=${clientId}`)
     .then(response => {
        //console.log("response from server .... ");  //console.log(response.data.data);		  ////console.log(response.data.neoclients);	 
       //return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data.neoclients});
       return dispatch({type: RECEIVE_NEOADDRESSX, NeoAddressOne: response.data.data});  //response.data.data.neoaddress
     })
     .catch(error => { 
       throw(error); 
     });
 };
};

export const getCodePromo = (code) => {
  //console.log("getCodePromo BEFORE response from server .... "+code); 
  //return axios.get(`${apiUrl}/${id}`)
  const auth_id=38;
 return (dispatch) => {
   return axios.get(`${apiUrl}/voucher?search=${code}`)
     .then(response => {
     //console.log("response from server .... ");  //console.log(response.data.data.neovoucher);		  ////console.log(response.data.neoclients);	 
       //return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data.neoclients});
       return dispatch({type: RECEIVE_NEOCODEPROMOX, NeoCodePromoOne: response.data.data.neovoucher});  //response.data.data.neoaddress
     })
     .catch(error => { 
       throw(error); 
     });
 };
};

 
export const getNeoVentesPromos = () => {
  //console.log("getNeoVentesPromos BEFORE response from server .... "); 
  //return axios.get(`${apiUrl}/${id}`)
  const auth_id=38;
 return (dispatch) => {
   return axios.get(`${apiUrl}/vouchers`)
     .then(response => {
     //console.log("response from server .... ");  //console.log(response.data.data.neovouchers);		  ////console.log(response.data.neoclients);	 
       //return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data.neoclients});
       return dispatch({type: RECEIVE_NEOCODEPROMOX, NeoCodePromoOne: response.data.data.neovouchers});  //response.data.data.neoaddress
     })
     .catch(error => { 
       throw(error); 
     });
 };
};

 
export const getProductInfo = (id, lang) => {
  //console.log("getProductInfo BEFORE response from server .... "+id); 
  //return axios.get(`${apiUrl}/${id}`) 
  const arrProd=[6,8,9]; 
  
  if(arrProd.includes(parseInt(id))){ 
    const response=productInfoMultiple(lang, id);
    //console.log("IIIIINNNNNN getProductInfo BEFORE response from server .... "+id); 
    return (dispatch) => {
      //console.log("PREEEEEEEEEEEEXX response from server .... ");  console.log(response.data);
      return dispatch({type: RECEIVE_NEOCODEPROMOX, NeoCodePromoOne: response});
    };
  }else{ 
    //console.log("OOOOOOOOOOOOUUUUUUUT getProductInfo BEFORE response from server .... "+id); 
    //arrProd.includes(parseInt(id))? productInfoMultiple(lang, id):  
 return (dispatch) => {
   return  axios.get(`${apiUrl}/xtraproductinfo?productId=${id}&lang=${lang}`)
     .then(response => {
     //console.log("response from server .... ");  console.log(response);		  ////console.log(response.data.neoclients);	 
       //return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data.neoclients});
       return dispatch({type: RECEIVE_NEOCODEPROMOX, NeoCodePromoOne: response.data});  //response.data.data.neoaddress
     })
     .catch(error => { 
       throw(error); 
     });
 };
 }
 
};

export const getAllProducts0 = () => {
  //const user=(localStorage.getItem("user")).split(':');
  return (dispatch) => {
    return axios.get(`${apiUrlProduct}`)
      .then(response => { 
        //console.log("response getAllProducts from server .... ");  console.log(response);	  
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data});
      })
      .catch(error => { throw(error); });
  };
};

export const getAllProducts = (objProd) => {
  //const user=(localStorage.getItem("user")).split(':');
  const objProdx={lang:"fr", upsell:"yes"};
  if(objProd===undefined || objProd===null){
    objProd=objProdx;
  }
  const lang=objProd.lang; const upsell=objProd.upsell;
  return (dispatch) => {
    return axios.get(`${apiUrlProduct}?upsell=${upsell}&lang=${lang}`)
      .then(response => { 
        //console.log("response getAllProducts from server .... ");  console.log(response);	  
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data});
      })
      .catch(error => { throw(error); });
  };
};


export const getPartialInvoicePayments = (id) => {
  //console.log("getPartialInvoicePayments BEFORE response from server .... "+id); 
  //return axios.get(`${apiUrl}/${id}`) 
 return (dispatch) => {
   return axios.get(`${apiUrl}/invoicepayments?invoiceId=${id}`)
     .then(response => {
     //console.log("getPartialInvoicePayments response from server .... ");  //console.log(response.data);		  ////console.log(response.data.neoclients);	 
       //return dispatch({type: RECEIVE_NEOCLIENTSX, NeoClientsOne: response.data.data.neoclients});
       return dispatch({type: RECEIVE_NEOCODEPROMOX, NeoCodePromoOne: response.data});  //response.data.data.neoaddress
     })
     .catch(error => { 
       throw(error); 
     });
 };
};
 

export const getNeoVentesGoto = (id) => {
	 //console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`./NeoVentes/${id}/edit`)
  };
};
export const deleteNeoVentes = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOVENTES, payload: {id}})
      })
      .then(() => {
        history.push("/NeoVentes")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNeoVentes = (NeoVentes) => {
  const id = NeoVentes.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoVentes.id}`, {
		name: NeoVentes.name,
		description: NeoVentes.description,
		amount: NeoVentes.amount,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOVENTES, payload: {
			id: data.id,
			name: data.name,
			description: data.description,
			amount: data.amount,
		}})
        dispatch({type: REPLACE_NEOVENTES, payload: {
			id: data.id,
			name: data.name,
			description: data.description,
			amount: data.amount,
		}})
      })
      .then(() => {
        history.push(`./NeoVentes/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNeoVentes = (NeoVentes, actionMode) => {
	if(actionMode===1){
        return addNeoVentes(NeoVentes);
      }else {
        return updateNeoVentes(NeoVentes);
      }
};

export const getGenAndCheckTicket = (obj) => {
  //const id=obj.clientId;
  const id=isNaN(obj.invoiceId)? 0:obj.invoiceId;
  const id2=obj.transactionId;
  const ups=obj.upsellof;
  const stat="yes";
  // $genTicket=$data["genTicket"] ?? "";
  //       $invoiceId=$data["invoiceId"] ?? 0;
  //       $transactionId=$data["transactionId"] ?? 0;

  //axios.get(`${apiUrlBase}/neofolderspaymentlist?clientId=${id}&ticketId=${id2}`)
	return (dispatch) => {
	  return axios.get(`${apiUrl}?genTicket=${stat}&invoiceId=${id}&transactionId=${id2}&upsellof=${ups}`)
		.then(response => {
			console.log("getClientTransactions", response); //response.data.data.ndifields
      //return dispatch({type: RECEIVE_NDIFIELDS, NdiFields: response.data.data});
      return dispatch({type: RECEIVE_NEOVENTESX, NeoVentesOne: response.data.data});
		})
		.catch(err => { 
      //throw(error); 
      if (err.response) {
        // client received an error response (5xx, 4xx)
        console.log("error falls here #1"); console.log(err.response);
        console.log(err);
        throw(err.response);
      } else if (err.request) {
        // client never received a response, or request never left
        console.log("error falls here #2");
        const errMessage="client never received a response, or request never left";
        throw(errMessage);
      } else {
        // anything else
        console.log("error falls here #3"); console.log(err);   
        throw(err); 
      }
    
    });
	};

  // let status="payed";
  // if(statusList!==""){ status=statusList; }
  // return (dispatch) => { 
  //   return axios.get(`${apiUrl}?status=${status}`)
  //     .then(response => {   
  //       return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.data});
  //     })
  //     .catch(error => { throw(error); });
  // };

};


export const getNeoVentesAllXStaus2 = (statusList, invoiceGroup) => { 
  let status="payed";
  if(statusList!==""){ status=statusList; }
  return (dispatch) => { 
    return axios.get(`${apiUrl}?status=${status}&upsell=${invoiceGroup}`)
      .then(response => {   
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.data});
      })
      .catch(error => { throw(error); });
  };
};

export const getNeoVentesAllXStausx = (statusXObj) => { 
  let status="payed";
  const statusList=statusXObj.statusList;
  if(statusList!==""){ status=statusList; }
  let restParam="";
  if(statusXObj.invoiceGroup!==undefined){
    restParam +="&upsell="+statusXObj.invoiceGroup;
  }
  if(statusXObj.conflict!==undefined){
    restParam +="&searchConflict="+statusXObj.conflict;
  }
  return (dispatch) => { 
    return axios.get(`${apiUrl}?status=${status}${restParam}`)
      .then(response => { 
        //console.log("getNeoVentesAllXStausx-POSTACTION ", response);  
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.data});
      })
      .catch(error => { throw(error); });
  };
};

export const getNeoVentesAllXStausx2 = (statusXObj) => { 
  let status="payed";
  const statusList=statusXObj.statusList;
  if(statusList!==""){ status=statusList; }
  let restParam="";
  if(statusXObj.invoiceGroup!==undefined){
    restParam +="&upsell="+statusXObj.invoiceGroup;
  }
  if(statusXObj.conflict!==undefined){
    restParam +="&searchConflict="+statusXObj.conflict;
  }

  if(statusXObj.page!==undefined){
    restParam +="&page="+statusXObj.page;
  }
  if(statusXObj.fieldName!==undefined){
    restParam +="&fieldName="+statusXObj.fieldName;
  }
  if(statusXObj.fieldVal!==undefined){
    restParam +="&fieldVal="+statusXObj.fieldVal;
  }
  if(statusXObj.specialSWA!==undefined){
    restParam +="&specialSWA="+statusXObj.specialSWA;
  }
  if(statusXObj.mySpecialSWA!==undefined){
    restParam +="&mySpecialSWA="+statusXObj.mySpecialSWA;
  }

  restParam +="&oneToManyTransByInv=3";
  
  

  return (dispatch) => { 
    return axios.get(`${apiUrl}?status=${status}${restParam}`)
      .then(response => { 
        //console.log("getNeoVentesAllXStausx-POSTACTION ", response);  
        //return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.data});
        return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data});
      })
      .catch(error => { throw(error); });
  };
};

export const getOrSetMultiActionStatus = (objAction) => { 
  //const status="sent,partial";  invoiceId  actiongo 
  const actiongo=objAction.actiongo;   
  const invoiceId=objAction.invoiceId;   
  return (dispatch) => { 
    return axios.get(`${apiUrlMulti}?invoiceId=${invoiceId}&actiongo=${actiongo}`)
      .then(response => {  
        console.log("GOreeeeeeeeeeeeeeeeeeeeeeeesp info"); console.log(response);   
        return dispatch({type: RECEIVE_NEOVENTESX, NeoVentesOne: response.data.data});
      })
      //.catch(error => { throw(error); });
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1"); console.log(err.response);
          console.log(err);
          throw(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          throw(errMessage);
        } else {
          // anything else
          console.log("error falls here #3"); console.log(err);   
          throw(err); 
        }
    });
  };
};