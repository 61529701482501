import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDIFIELDOPTIONS = 'GET_NDIFIELDOPTIONS';
export const ADD_NDIFIELDOPTIONS = 'ADD_NDIFIELDOPTIONS';
export const RECEIVE_NDIFIELDOPTIONSX = 'RECEIVE_NDIFIELDOPTIONSX';
export const REMOVE_NDIFIELDOPTIONS = 'REMOVE_NDIFIELDOPTIONS';
export const UPDATE_NDIFIELDOPTIONS = 'UPDATE_NDIFIELDOPTIONS';
export const REPLACE_NDIFIELDOPTIONS = 'REPLACE_NDIFIELDOPTIONS';
const apiUrl = API_DOMAIN + '/ndifieldoptions'; 

axiosDefaults(null);

export const getNdiFieldOptionsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_NDIFIELDOPTIONS, NdiFieldOptions: response.data.ndifieldoptions.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiFieldOptions = (NdiFieldOptions) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiFieldOptions.id,
		field_id: NdiFieldOptions.field_id,
		text_label: NdiFieldOptions.text_label,
		option_order: NdiFieldOptions.option_order,
		enabled: NdiFieldOptions.enabled,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDIFIELDOPTIONS, payload: {
			id: data.id,
			field_id: data.field_id,
			text_label: data.text_label,
			option_order: data.option_order,
			enabled: data.enabled,
		}})
      })
      .then(() => {
        history.push("/NdiFieldOptions")
      })
      .catch(error => { throw(error) });
  };
};
export const getNdiFieldOptions = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.ndifieldoptions);		  
        return dispatch({type: RECEIVE_NDIFIELDOPTIONSX, NdiFieldOptionsOne: response.data.ndifieldoptions});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiFieldOptionsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiFieldOptions/${id}/edit`)
  };
};
export const deleteNdiFieldOptions = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDIFIELDOPTIONS, payload: {id}})
      })
      .then(() => {
        history.push("/NdiFieldOptions")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiFieldOptions = (NdiFieldOptions) => {
  const id = NdiFieldOptions.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiFieldOptions.id}`, {
		field_id: NdiFieldOptions.field_id,
		text_label: NdiFieldOptions.text_label,
		option_order: NdiFieldOptions.option_order,
		enabled: NdiFieldOptions.enabled,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDIFIELDOPTIONS, payload: {
			id: data.id,
			field_id: data.field_id,
			text_label: data.text_label,
			option_order: data.option_order,
			enabled: data.enabled,
		}})
        dispatch({type: REPLACE_NDIFIELDOPTIONS, payload: {
			id: data.id,
			field_id: data.field_id,
			text_label: data.text_label,
			option_order: data.option_order,
			enabled: data.enabled,
		}})
      })
      .then(() => {
        history.push(`/NdiFieldOptions/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiFieldOptions = (NdiFieldOptions, actionMode) => {
	if(actionMode===1){
        return addNdiFieldOptions(NdiFieldOptions);
      }else {
        return updateNdiFieldOptions(NdiFieldOptions);
      }
};