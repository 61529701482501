import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOSEARCH = 'GET_NEOSEARCH';
export const ADD_NEOSEARCH = 'ADD_NEOSEARCH';
export const RECEIVE_NEOSEARCHX = 'RECEIVE_NEOSEARCHX';
export const REMOVE_NEOSEARCH = 'REMOVE_NEOSEARCH';
export const UPDATE_NEOSEARCH = 'UPDATE_NEOSEARCH';
export const REPLACE_NEOSEARCH = 'REPLACE_NEOSEARCH';
const apiUrl = API_DOMAIN + '/neosearch'; 
const apiUrlBase = API_DOMAIN; 

axiosDefaults(null);

export const getNeoSearchAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_NEOSEARCH, NeoSearch: response.data.neosearch.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNeoSearch = (NeoSearch) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NeoSearch.id,
		search: NeoSearch.search,
		filter: NeoSearch.filter,
		result: NeoSearch.result,
		actions: NeoSearch.actions,
		ticket_id: NeoSearch.ticket_id,
		client_id: NeoSearch.client_id,
		search_type: NeoSearch.search_type,
		date_created: NeoSearch.date_created,
		user_creation: NeoSearch.user_creation,
		user_update: NeoSearch.user_update,
		update_date: NeoSearch.update_date,
		status: NeoSearch.status,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOSEARCH, payload: {
			id: data.id,
			search: data.search,
			filter: data.filter,
			result: data.result,
			actions: data.actions,
			ticket_id: data.ticket_id,
			client_id: data.client_id,
			search_type: data.search_type,
			date_created: data.date_created,
			user_creation: data.user_creation,
			user_update: data.user_update,
			update_date: data.update_date,
			status: data.status,
		}})
      })
      .then(() => {
        history.push("/NeoSearch")
      })
      .catch(error => { throw(error) });
  };
};
export const getNeoSearch = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.neosearch);		  
        return dispatch({type: RECEIVE_NEOSEARCHX, NeoSearchOne: response.data.neosearch});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNeoSearchGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NeoSearch/${id}/edit`)
  };
};

export const getNeoSearchByTicket = (ticketId) => {
	return (dispatch) => {
	  return axios.get(`${apiUrlBase}/search/searchconflicts?ticketId=${ticketId}`)
		.then(response => {
			console.log("BEFORE getNeoSearchByTicket .... ", response); 
		  return dispatch({type: RECEIVE_NEOSEARCH, NeoSearch: response.data.data.neosearch})
		})
		.catch(error => { throw(error); });
	};
  };


 
export const getSearchConflict000 = (obj) => {
	//const objToSend={id:obj.id, ticketId:obj.ticketId};
	const id=obj.id; const ticketId=obj.ticketId; 
	let lang=""; let zendsk=""; let search=""; let byfield=""; 
	// if(obj.lang!==undefined){ lang=obj.lang; }else{
	// 	lang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
	// 	//lang="fr";  if(myLang.indexOf("fr")< 0){ lang="fr"; }else{ lang="en"; }
	// }
	// if(obj.zendeskapi!==undefined){ zendsk="&zendeskapi="+obj.zendeskapi; }
	if(obj.search!==undefined){ search=obj.search; }
	if(obj.search!==undefined){ search=obj.search; }
	 
	 console.log("BEFORE response from server .... "+id, lang); 
	 //return axios.get(`${apiUrl}/${id}`)
	 //return axios.get(`${apiUrlPField}?id=${id}&ticketId=${ticketId}&lang=${lang}${zendsk}`)

  return (dispatch) => {
    return axios.get(`${apiUrlBase}/clients/searchconflicts?search=${search}&byfield=${byfield}`)
      .then(response => {
		  console.log("response getSearchConflict from server .... ");  console.log(response.data);		  
        //return dispatch({type: RECEIVE_NDIFIELDSX, NdiFieldsOne: response.data});
		return dispatch({type: RECEIVE_NEOSEARCHX, NeoSearch: response.data});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};

export const setSearchConflict = (obj) => {  
	//axios.get(`${apiUrlBase}/clients/searchconflicts?search=${search}&byfield=${byfield}`)
   return (dispatch) => {
	 return axios.post(`${apiUrlBase}/search/searchconflicts`, obj)
	   .then(response => {
		 let data = response.data; //response.data.data;
		   
		 console.log('0000000 RESPONSE setSearchConflict  ');  
		 console.log(data);   
		 //console.log('0000000 RESPONSE setSearchConflict ---.. '+data.id);  
		 //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
		 //dispatch({type: ADD_NEOSEARCH, payload: { ...mydataAr } });
		 //return dispatch({type: ADD_NDIFIELDS, NdiFields: data}); NeoSearchOne
		 return dispatch({type: ADD_NEOSEARCH, NeoSearch: data}); 
	   })
	   /*.then(response => {
		 //history.push("/NeoVentes")
		 return response;
	   })*/
	   //.catch(error => { throw(error) });
	   .catch(err => {
		 if (err.response) {
		   // client received an error response (5xx, 4xx)
		   console.log("error falls here #1"); console.log(err.response);
		   console.log(err);
		   throw(err.response);
		 } else if (err.request) {
		   // client never received a response, or request never left
		   console.log("error falls here #2");
		   const errMessage="client never received a response, or request never left";
		   throw(errMessage);
		 } else {
		   // anything else
		   console.log("error falls here #3"); console.log(err);   
		   throw(err); 
		 }
	 });
   };
 };

 
 export const getSearchConflict = (obj) => {
	//const objToSend={id:obj.id, ticketId:obj.ticketId};
	const id=obj.id; const ticketId=obj.ticketId; 
	let lang=""; let zendsk=""; let search=""; let byfield=""; 
	// if(obj.lang!==undefined){ lang=obj.lang; }else{
	// 	lang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
	// 	//lang="fr";  if(myLang.indexOf("fr")< 0){ lang="fr"; }else{ lang="en"; }
	// }
	// if(obj.zendeskapi!==undefined){ zendsk="&zendeskapi="+obj.zendeskapi; }
	if(obj.search!==undefined){ search=obj.search; }
	if(obj.search!==undefined){ search=obj.search; } 
	const curLang=localStorage.getItem("i18nextLng");
	 
	 console.log("BEFORE response from server .... "+id, lang); 
	 //return axios.get(`${apiUrl}/${id}`)
	 //return axios.get(`${apiUrlPField}?id=${id}&ticketId=${ticketId}&lang=${lang}${zendsk}`)

  return (dispatch) => {
    return axios.get(`${apiUrlBase}/clients/searchconflicts?search=${search}&byfield=${byfield}`, {timeout: 60000})
      .then(response => {
		  console.log("response getSearchConflict from server .... ");  console.log(response.data);	  	  
        //return dispatch({type: RECEIVE_NDIFIELDSX, NdiFieldsOne: response.data});
		return dispatch({type: RECEIVE_NEOSEARCHX, NeoSearch: response.data});
      })
      .catch(err => { 
		console.log("erroooooooooooor ");  
		console.log(err);  
        //throw(error);
		if (err.response) {
			// client received an error response (5xx, 4xx)
			console.log("error falls here #1"); console.log(err.response);
			console.log(err);
			throw(err.response);
		} else if (err.request) {
			// client never received a response, or request never left
			console.log("error falls here #2");
			let errMessage="client never received a response, or request never left";
			if (err.code!==undefined && err.code === 'ECONNABORTED'){
				//errMessage="Désolé! La réponse est expirée après 60 secondes. ";
				errMessage="Désolé! Le temps d'attente pour la réponse est expiré. Essayez encore svp. ";
				if(curLang==="en"){
					//errMessage="Sorry! Response is timeout after 60 seconds. ";
					errMessage="Sorry! The waiting time for the response has expired. Try again, please. ";
				}
			}

			throw(errMessage);
		} else {
			// anything else
			console.log("error falls here #3"); console.log(err);   
			throw(err); 
		} 
      });
  };
};





export const deleteNeoSearch = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOSEARCH, payload: {id}})
      })
      .then(() => {
        history.push("/NeoSearch")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNeoSearch = (NeoSearch) => {
  const id = NeoSearch.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoSearch.id}`, {
		search: NeoSearch.search,
		filter: NeoSearch.filter,
		result: NeoSearch.result,
		actions: NeoSearch.actions,
		ticket_id: NeoSearch.ticket_id,
		client_id: NeoSearch.client_id,
		search_type: NeoSearch.search_type,
		date_created: NeoSearch.date_created,
		user_creation: NeoSearch.user_creation,
		user_update: NeoSearch.user_update,
		update_date: NeoSearch.update_date,
		status: NeoSearch.status,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOSEARCH, payload: {
			id: data.id,
			search: data.search,
			filter: data.filter,
			result: data.result,
			actions: data.actions,
			ticket_id: data.ticket_id,
			client_id: data.client_id,
			search_type: data.search_type,
			date_created: data.date_created,
			user_creation: data.user_creation,
			user_update: data.user_update,
			update_date: data.update_date,
			status: data.status,
		}})
        dispatch({type: REPLACE_NEOSEARCH, payload: {
			id: data.id,
			search: data.search,
			filter: data.filter,
			result: data.result,
			actions: data.actions,
			ticket_id: data.ticket_id,
			client_id: data.client_id,
			search_type: data.search_type,
			date_created: data.date_created,
			user_creation: data.user_creation,
			user_update: data.user_update,
			update_date: data.update_date,
			status: data.status,
		}})
      })
      .then(() => {
        history.push(`/NeoSearch/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNeoSearch = (NeoSearch, actionMode) => {
	if(actionMode===1){
        return addNeoSearch(NeoSearch);
      }else {
        return updateNeoSearch(NeoSearch);
      }
};