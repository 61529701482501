import { RECEIVE_ZONLINEUSUARIOS, UPDATE_ZONLINEUSUARIOS } from '../actions/ZonlineUsuariosAction';

export default function singleZonlineUsuariosReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_ZONLINEUSUARIOS:
      return action.ZonlineUsuarios;
    case UPDATE_ZONLINEUSUARIOS:
      return {
        id_usuario: action.id_usuario,
fecha: action.payload.fecha,
nombre: action.payload.nombre,
apellido: action.payload.apellido,
correo: action.payload.correo,
usuario: action.payload.usuario,
contrasena: action.payload.contrasena,
id_rol_usuario: action.payload.id_rol_usuario,
habilitado: action.payload.habilitado,
logintoken: action.payload.logintoken,
logintime: action.payload.logintime,
zona: action.payload.zona,
funcion: action.payload.funcion,
sucursales: action.payload.sucursales,
      }
    default:
      return state;
  }
};