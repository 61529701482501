import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import LanguageIcon from '@material-ui/icons/Language';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { withTranslation, useTranslation  } from 'react-i18next';

const emails2 = [{'id':'en','ln':'English'}, {'id':'fr','ln':'Français'}, 
{'id':'fr-FR','ln':'Français France'}, {'id':'fr-CA','ln':'Français Canada'}, 
{'id':'en-US','ln':'English USA'}, {'id':'en-CA','ln':'English Canada'},
{'id':'es','ln':'Español'}]; //const emails = ['English', 'Français', 'Español'];  
//const emails = [{'id':'en','ln':'English'}, {'id':'es','ln':'Español'}]; //const emails = ['English', 'Français', 'Español'];
const emails = [{'id':'en','ln':'English'}, {'id':'fr','ln':'Français'}];  
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const { t, i18n } = useTranslation("common");

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    const selectedValue2=localStorage.i18nextLng;
    //console.log("outWEEEEEEEEEEEEEEEEEEEEE CHANGING LANGUAGE "+value, selectedValue2)
    if(selectedValue2!=value){
      console.log("WEEEEEEEEEEEEEEEEEEEEE CHANGING LANGUAGE "+value);
      i18n.changeLanguage(value);
      window.location.reload();
    }
	  onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t('changeLang_x')}</DialogTitle>
      <List>
        {emails.map((email) => (
          <ListItem button onClick={() => handleListItemClick(email.id)} key={email.id}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LanguageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email.ln} />
          </ListItem>
        ))}
 
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function ChangeLanguage() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1].id);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
	{/*<Typography variant="subtitle1">Selected: {selectedValue}</Typography>
      <br />
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open simple dialog
</Button>*/}

	  {/* <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#">
	  <i className="fas fa-cogs" onClick={handleClickOpen}></i></a> */}
    <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#"
    onClick={handleClickOpen}><LanguageIcon />
	  Changer Langue</a>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}