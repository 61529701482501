import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";
import { genLang } from "../../composables/genLang";

export default function CopyButton({ text, roundedInput }) {
  const [copied, setCopied] = useState(false);

  const handleCopyText = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  return (
    text && (
      <Tooltip
        placement="left"
        title={
          copied
            ? genLang({ en: "Copied", fr: "Copié" })
            : genLang({ en: "Copy", fr: "Copie" })
        }
      >
        <IconButton
          size="small"
          style={{
            position: "absolute",
            right: "20px",
            top: roundedInput ? "40px" : "20px",
          }}
          onClick={handleCopyText}
        >
          {copied ? (
            <CheckIcon style={{ fontSize: "1.1rem" }} />
          ) : (
            <FileCopyIcon style={{ fontSize: "1.1rem" }} />
          )}
        </IconButton>
      </Tooltip>
    )
  );
}
