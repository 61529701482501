



export default  function  productInfoMultiple(lang, idProd) { 
    //productsList={
	let arrProdInfo=[];  let arrDescription=[]; let arrDescriptionOpt=[]; let arrPourquoi=[]; 
	let title=""; let excerpt="";
	 idProd=parseInt(idProd);
	if(lang==="en"){
		if(idProd===6){
			title="Poursuivre à la Cour des petites créances - Forfait de base"; 
			excerpt="";
		}else if(idProd===8){
			title="Poursuivre à la Cour des petites créances - Coaching"; 
			excerpt="";
		}else if(idProd===9){
			title="Poursuivre à la Cour des petites créances - Forfait Complet"; 
			excerpt="";
		}
		// arrDescription.push("");
		// arrDescription.push("");
		// arrDescription.push("");
		// arrDescription.push("");
		// arrDescription.push("");
		// arrDescription.push("");
		// arrDescription.push("");
		// arrDescription.push("");

		arrDescription.push ( "Training and preparation for the transition to court (2 calls included)");
		arrDescription.push ("Strategic advice from a lawyer to maximize your chances of winning");
		arrDescription.push ("Follow-up call following your visit to the Court");
		arrDescription.push ("Drafting of the legal request (2 calls included)");
		arrDescription.push ("Consolidation of your documents in your virtual legal portal");
		arrDescription.push ("Opening of your file at the Small Claims Court");
		arrDescription.push ("Court Fee Payment Assistance (Court Fees Not Included)");
		arrDescription.push ("Preparation of the list of documents and supporting documents");

		arrPourquoi= [
			// {
			// 	"field": { "type": "text" },
			// 	"value": "Our lawyers can help you with:"
			// },
			{
				"field": { "type": "text" },
				"value": "Preparing your file as well as possible "
			},
			{
				"field": { "type": "text" },
				"value": "Defining the best legal strategy "
			},
			{
				"field": { "type": "text" },
				"value": "Opening the file for you at the Small Claims Court"
			}, 
			{
				"field": { "type": "text" },
				"value": "Training you to represent yourself for the big day "
			},
			{
				"field": { "type": "text" },
				"value": "Preparing yourself for different possible situations "
			}
		];
	}else{
		if(idProd===6){
			title="Poursuivre à la Cour des petites créances - Forfait de base"; 
			excerpt="";
		}else if(idProd===8){
			title="Poursuivre à la Cour des petites créances - Coaching"; 
			excerpt="";
		}else if(idProd===9){
			title="Poursuivre à la Cour des petites créances - Forfait Complet"; 
			excerpt="";
		}

		arrDescription.push("Entrainement et préparation au passage en cour (2 appels inclus)");
		arrDescription.push("Conseils stratégiques d'un avocat afin de maximiser vos chances de victoire");
		arrDescription.push("Appel de suivi suite à votre passage à la Cour");
		arrDescription.push("Rédaction de la demande en justice (2 appels inclus)");
		arrDescription.push("Regroupement de vos documents dans votre portail juridique virtuel");
		arrDescription.push("Ouverture de votre dossier à la Cour des Petites Créances");
		arrDescription.push("Aide au paiement des frais de Cour (Frais de Cour non inclus)");
		arrDescription.push("Préparation de la liste des pièces et des pièces justificatives");

		arrPourquoi= [
			// {
			// 	"field": {
			// 		"type": "text"
			// 	},
			// 	"value": "NOS AVOCATS PEUVENT VOUS AIDER À:"
			// },
			{
				"field": { "type": "text" },
				"value": "Préparer au mieux votre dossier  "
			},
			{
				"field": { "type": "text" },
				"value": "Définir la meilleure stratégie juridique "
			},
			{
				"field": { "type": "text" },
				"value": "Ouvrir le dossier pour vous à la Cour des Petites Créances"
			},
			{
				"field": { "type": "text" },
				"value": "Vous former à vous représenter pour le jour J "
			},
			{
				"field": { "type": "text" },
				"value": "Vous préparer aux différentes situations possibles  "
			}
		];
	} 

	//{"field": { "type": "text"},"value":  ""},
	if(idProd===6){
		//arrDescriptionOpt.push(arrDescription[0]);
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[1]});
		//arrDescriptionOpt.push(arrDescription[2]);
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[3]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[4]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[5]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[6]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[7]});
	}else if(idProd===8){
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[0]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[1]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[2]}); 
	}else if(idProd===9){
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[0]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[1]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[2]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[3]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[4]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[5]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[6]});
		arrDescriptionOpt.push({"field": { "type": "text"},"value": arrDescription[7]});
	}

	// for(let i=0; i<arrDescriptionOpt.length; i++){

	// }

	const contentmx=[
		{
			"published": true,
			"title": title,
			"image": "",
			"excerpt": excerpt,
			"content": "test",
			"tags": [
				"...", 
				"...."
			],
			"Description": arrDescriptionOpt,
			"Description_fr": [
				{
					"field": {
						"type": "text"
					},
					"value": "..."
				},
				{
					"field": {
						"type": "text"
					},
					"value": "..."
				}
			],
			"Description_en": null,
			"title_slug": "poursuivre-aux-petites-creances",
			"_mby": "5b84a2b6643230e6b900019b",
			"_by": "5b84a2b6643230e6b900019b",
			"_modified": 1547843360,
			"_created": 1535419698,
			"_id": "5b84a532643230fc99000396",
			"id_produit": "4",
			"Pourquoi": arrPourquoi,
			"Signature": true,
			"title_en": "",
			"excerpt_en": "",
			"tags_en": null,
			"Pourquoi_en": [
				{
					"field": {
						"type": "text"
					},
					"value": "why"
				}
			],
			"title_en_slug": "",
			"FAQ": [ ],
			"FAQ_en": [
				{
					"field": {
						"type": "text",
						"label": "Question"
					},
					"value": "english question"
				},
				{
					"field": {
						"type": "html",
						"label": "Réponse"
					},
					"value": "english answer"
				}
			],
			"Domaine_Couverts": null,
			"Remarques": "",
			"metasdescription": "Envoyer une lettre de mise en demeure rédigée par un avocat Neolegal. Le service est offert partout au Québec et ne nécessite aucun déplacement. Qu'attendez-vous?",
			"metasdescription_en": null,
			"TitrePage": "Envoyer une mise en demeure avec un avocat",
			"TitrePage_en": null,
			"Remarques_en": null,
			"Sceau": "Le sceau et la signature de Neolegal démontrent que la lettre de mise en demeure a été rédigée et envoyée par un avocat. ",
			"Sceau_en": null,
			"Type": "Regular",
			"_pid": null,
			"_o": 0,
			"BaliseH1": "Envoyer une mise en demeure avec un avocat"
		}
	];






 return {
    "statusCode": 200,
    "data": [
        {
            "Type": {
                "name": "Type",
                "type": "select",
                "localize": false,
                "options": {
                    "cls": "",
                    "default": "Regular",
                    "options": "Regular, Business"
                }
            },
            "title": {
                "name": "title",
                "type": "text",
                "localize": true,
                "options": {
                    "slug": true
                }
            },
            "TitrePage": {
                "name": "TitrePage",
                "type": "text",
                "localize": true,
                "options": []
            },
            "BaliseH1": {
                "name": "BaliseH1",
                "type": "text",
                "localize": false,
                "options": []
            },
            "id_produit": {
                "name": "id_produit",
                "type": "text",
                "localize": false,
                "options": []
            },
            "published": {
                "name": "published",
                "type": "boolean",
                "localize": false,
                "options": {
                    "default": false,
                    "label": false
                }
            },
            "excerpt": {
                "name": "excerpt",
                "type": "markdown",
                "localize": true,
                "options": []
            },
            "tags": {
                "name": "tags",
                "type": "tags",
                "localize": true,
                "options": []
            },
            "metasdescription": {
                "name": "metasdescription",
                "type": "text",
                "localize": true,
                "options": []
            },
            "Description": {
                "name": "Description",
                "type": "repeater",
                "localize": true,
                "options": []
            },
            "Domaine_Couverts": {
                "name": "Domaine_Couverts",
                "type": "repeater",
                "localize": false,
                "options": []
            },
            "Pourquoi": {
                "name": "Pourquoi",
                "type": "repeater",
                "localize": true,
                "options": []
            },
            "Signature": {
                "name": "Signature",
                "type": "boolean",
                "localize": false,
                "options": []
            },
            "FAQ": {
                "name": "FAQ",
                "type": "repeater",
                "localize": true,
                "options": {
                    "fields": [
                        {
                            "type": "text",
                            "label": "Question"
                        },
                        {
                            "type": "html",
                            "label": "Reponse"
                        }
                    ]
                }
            },
            "Remarques": {
                "name": "Remarques",
                "type": "text",
                "localize": true,
                "options": []
            },
            "Sceau": {
                "name": "Sceau",
                "type": "text",
                "localize": true,
                "options": []
            },
            "preachat_1": {
                "name": "preachat_1",
                "type": "text",
                "localize": false,
                "options": []
            },
            "preachat_2": {
                "name": "preachat_2",
                "type": "text",
                "localize": false,
                "options": []
            }
        },
        contentmx,
        1
    ]
};   

};
