import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOPAYMENTSCREDITS = 'GET_NEOPAYMENTSCREDITS';
export const ADD_NEOPAYMENTSCREDITS = 'ADD_NEOPAYMENTSCREDITS';
export const RECEIVE_NEOPAYMENTSCREDITSX = 'RECEIVE_NEOPAYMENTSCREDITSX';
export const REMOVE_NEOPAYMENTSCREDITS = 'REMOVE_NEOPAYMENTSCREDITS';
export const UPDATE_NEOPAYMENTSCREDITS = 'UPDATE_NEOPAYMENTSCREDITS';
export const REPLACE_NEOPAYMENTSCREDITS = 'REPLACE_NEOPAYMENTSCREDITS';
const apiUrl = API_DOMAIN + '/neopaymentscredits'; 
const apiUrl2 = API_DOMAIN + '/b2b/clients'; 
const apiUrlBase=API_DOMAIN;  
const apiUrlMulti = apiUrl + '/multiactioninvoice'; 

axiosDefaults(null);

export const getNeoPaymentsCreditsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
        console.log("BEFORE response from server .... ", response); 
        return dispatch({type: RECEIVE_NEOPAYMENTSCREDITS, NeoPaymentsCredits: response.data.data.neopaymentscredits})
      })
      .catch(error => { throw(error); });
  };
};

export const addNeoPaymentsCredits = (NeoPaymentsCredits) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NeoPaymentsCredits.id,
		id_clients: NeoPaymentsCredits.id_clients,
		amount: NeoPaymentsCredits.amount,
		user_creation: NeoPaymentsCredits.user_creation,
		user_update: NeoPaymentsCredits.user_update,
		creation_date: NeoPaymentsCredits.creation_date,
		update_date: NeoPaymentsCredits.update_date,
		status: NeoPaymentsCredits.status,
		observation: NeoPaymentsCredits.observation,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOPAYMENTSCREDITS, payload: {
			id: data.id,
			id_clients: data.id_clients,
			amount: data.amount,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			observation: data.observation,
		}})
      })
      .then(() => {
        history.push("/NeoPaymentsCredits")
      })
      .catch(error => { throw(error) });
  };
};
export const getNeoPaymentsCredits = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.neopaymentscredits);		  
        return dispatch({type: RECEIVE_NEOPAYMENTSCREDITSX, NeoPaymentsCreditsOne: response.data.neopaymentscredits});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNeoPaymentsCreditsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NeoPaymentsCredits/${id}/edit`)
  };
};
export const deleteNeoPaymentsCredits = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOPAYMENTSCREDITS, payload: {id}})
      })
      .then(() => {
        history.push("/NeoPaymentsCredits")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNeoPaymentsCredits = (NeoPaymentsCredits) => {
  const id = NeoPaymentsCredits.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoPaymentsCredits.id}`, {
		id_clients: NeoPaymentsCredits.id_clients,
		amount: NeoPaymentsCredits.amount,
		user_creation: NeoPaymentsCredits.user_creation,
		user_update: NeoPaymentsCredits.user_update,
		creation_date: NeoPaymentsCredits.creation_date,
		update_date: NeoPaymentsCredits.update_date,
		status: NeoPaymentsCredits.status,
		observation: NeoPaymentsCredits.observation,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOPAYMENTSCREDITS, payload: {
			id: data.id,
			id_clients: data.id_clients,
			amount: data.amount,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			observation: data.observation,
		}})
        dispatch({type: REPLACE_NEOPAYMENTSCREDITS, payload: {
			id: data.id,
			id_clients: data.id_clients,
			amount: data.amount,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			observation: data.observation,
		}})
      })
      .then(() => {
        history.push(`/NeoPaymentsCredits/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNeoPaymentsCredits = (NeoPaymentsCredits, actionMode) => {
	if(actionMode===1){
        return addNeoPaymentsCredits(NeoPaymentsCredits);
      }else {
        return updateNeoPaymentsCredits(NeoPaymentsCredits);
      }
};