import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getNeoPaymentsCredits, deleteNeoPaymentsCredits } from '../../actions/NeoPaymentsCreditsAction';

class NeoPaymentsCreditsInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		id_clients:'', e_id_clients: false,
		amount:'', e_amount: false,
		user_creation:'', e_user_creation: false,
		user_update:'', e_user_update: false,
		creation_date:'', e_creation_date: false,
		update_date:'', e_update_date: false,
		status:'', e_status: false,
		observation:'', e_observation: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getNeoPaymentsCredits(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getNeoPaymentsCredits(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.NeoPaymentsCreditsOne);  console.log("0000********FIN**********");
		this.setState(response.NeoPaymentsCreditsOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const NeoPaymentsCredits = this.props.NeoPaymentsCredits;
    const NeoPaymentsCredits = this.state; 
    return (
      <div>
        <h2>NeoPaymentsCredits</h2>
        <hr/>
        <p><label>{t('zqwid_x')}</label> &nbsp; &nbsp; #{NeoPaymentsCredits.id}</p>
        <p><label>{t('zqwid_clients_x')}</label><br/>{NeoPaymentsCredits.id_clients}</p>
        <p><label>{t('zqwamount_x')}</label><br/>{NeoPaymentsCredits.amount}</p>
        <p><label>{t('zqwuser_creation_x')}</label><br/>{NeoPaymentsCredits.user_creation}</p>
        <p><label>{t('zqwuser_update_x')}</label><br/>{NeoPaymentsCredits.user_update}</p>
        <p><label>{t('zqwcreation_date_x')}</label><br/>{NeoPaymentsCredits.creation_date}</p>
        <p><label>{t('zqwupdate_date_x')}</label><br/>{NeoPaymentsCredits.update_date}</p>
        <p><label>{t('zqwstatus_x')}</label><br/>{NeoPaymentsCredits.status}</p>
        <p><label>{t('zqwobservation_x')}</label><br/>{NeoPaymentsCredits.observation}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/NeoPaymentsCredits/${NeoPaymentsCredits.id}/edit`, state: { NeoPaymentsCredits: NeoPaymentsCredits } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNeoPaymentsCredits(NeoPaymentsCredits.id)}>          
            {t('Delete')}
          </button>
          <Link to="/NeoPaymentsCredits" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransNeoPaymentsCreditsInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ NeoPaymentsCredits: state.NeoPaymentsCredits });                 

const mapDispatchToProps = { getNeoPaymentsCredits, deleteNeoPaymentsCredits };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoPaymentsCreditsInfo));   

