import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NDIPRODUCTSTRANSLATION = 'GET_NDIPRODUCTSTRANSLATION';
export const ADD_NDIPRODUCTSTRANSLATION = 'ADD_NDIPRODUCTSTRANSLATION';
export const RECEIVE_NDIPRODUCTSTRANSLATIONX = 'RECEIVE_NDIPRODUCTSTRANSLATIONX';
export const REMOVE_NDIPRODUCTSTRANSLATION = 'REMOVE_NDIPRODUCTSTRANSLATION';
export const UPDATE_NDIPRODUCTSTRANSLATION = 'UPDATE_NDIPRODUCTSTRANSLATION';
export const REPLACE_NDIPRODUCTSTRANSLATION = 'REPLACE_NDIPRODUCTSTRANSLATION';
const apiUrl = API_DOMAIN + '/ndiproductstranslation'; 

axiosDefaults(null);

export const getNdiProductsTranslationAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_NDIPRODUCTSTRANSLATION, NdiProductsTranslation: response.data.ndiproductstranslation.data})
      })
      .catch(error => { throw(error); });
  };
};

export const addNdiProductsTranslation = (NdiProductsTranslation) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NdiProductsTranslation.id,
		product_id: NdiProductsTranslation.product_id,
		lang: NdiProductsTranslation.lang,
		product_block: NdiProductsTranslation.product_block,
		published: NdiProductsTranslation.published,
		last_update: NdiProductsTranslation.last_update,
		user_id: NdiProductsTranslation.user_id,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NDIPRODUCTSTRANSLATION, payload: {
			id: data.id,
			product_id: data.product_id,
			lang: data.lang,
			product_block: data.product_block,
			published: data.published,
			last_update: data.last_update,
			user_id: data.user_id,
		}})
      })
      .then(() => {
        history.push("/NdiProductsTranslation")
      })
      .catch(error => { throw(error) });
  };
};
export const getNdiProductsTranslation = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.ndiproductstranslation);		  
        return dispatch({type: RECEIVE_NDIPRODUCTSTRANSLATIONX, NdiProductsTranslationOne: response.data.ndiproductstranslation});
      })
      .catch(error => { 
        throw(error); 
      });
  };
};
export const getNdiProductsTranslationGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiProductsTranslation/${id}/edit`)
  };
};
export const deleteNdiProductsTranslation = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDIPRODUCTSTRANSLATION, payload: {id}})
      })
      .then(() => {
        history.push("/NdiProductsTranslation")
      })
      .catch(error => {
        throw(error);
      });
  };
};
export const updateNdiProductsTranslation = (NdiProductsTranslation) => {
  const id = NdiProductsTranslation.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiProductsTranslation.id}`, {
		product_id: NdiProductsTranslation.product_id,
		lang: NdiProductsTranslation.lang,
		product_block: NdiProductsTranslation.product_block,
		published: NdiProductsTranslation.published,
		last_update: NdiProductsTranslation.last_update,
		user_id: NdiProductsTranslation.user_id,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDIPRODUCTSTRANSLATION, payload: {
			id: data.id,
			product_id: data.product_id,
			lang: data.lang,
			product_block: data.product_block,
			published: data.published,
			last_update: data.last_update,
			user_id: data.user_id,
		}})
        dispatch({type: REPLACE_NDIPRODUCTSTRANSLATION, payload: {
			id: data.id,
			product_id: data.product_id,
			lang: data.lang,
			product_block: data.product_block,
			published: data.published,
			last_update: data.last_update,
			user_id: data.user_id,
		}})
      })
      .then(() => {
        history.push(`/NdiProductsTranslation/${id}`)
      })
      .catch(error => { throw(error) });
  };
};
export const addOrUpdateNdiProductsTranslation = (NdiProductsTranslation, actionMode) => {
	if(actionMode===1){
        return addNdiProductsTranslation(NdiProductsTranslation);
      }else {
        return updateNdiProductsTranslation(NdiProductsTranslation);
      }
};