import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

class GoogleAddressSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };

    this.street=''; 
    this.suite=""; 
    this.city=""; 
    this.statex=""; 
    this.country=""; 
    this.zipCode=""; 
    this.formatedAddress='';
    this.address=""; 
    this.statex2=""; 

    this.postalcode=""; 
    this.postalcodeList=[]; 
    //this.onChangeByAutoComplete=this.onChangeByAutoComplete.bind(this);
  } 

  //it is currently not supported for the Geocoding or Places API to show a list of addresses by zip code
  //https://stackoverflow.com/questions/56864920/google-geocode-places-api-search-zip-code-get-addresses-connected-to-it-poss
 

  handleChange = address => {
    this.setState({ address });
    //this.setState({ ["address"]: address });
    const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
    const letter = /(?!.*[DFIOQU])[A-Z]/i;
    const digit = /[0-9]/;
    const mask = [firstLetter, digit, letter, " ", digit, letter, digit];

    const patternPostal = /((?!.*[DFIOQU])[A-VXY])([0-9])((?!.*[DFIOQU])[A-Z])([ ]?)([0-9])((?!.*[DFIOQU])[A-Z])([0-9])/i;
     
    if(patternPostal.test(address)){
      this.postalcode=address; 
      console.log("CHANGE ADDRESS "); console.log(address); console.log("//CHANGE ADDRESS ");
    }
  }; 

  handleSelect = address => {
    //.then(results => getLatLng(results[0]))
    this.address=address;
     
    geocodeByAddress(address) 
      //.then(results => { this.onChangeByAutoComplete(results[0]); return getLatLng(results[0])})
      //.then(results => { this.onChangeByAutoComplete(results[0]); return results[0];})
      //.then(results => { this.onChangeByAutoComplete(results); return results[0];})
      .then(results => { 
        //var lat = r['results'][0]['geometry']['location']['lat'];
        //var lng = r['results'][0]['geometry']['location']['lng'];

        //this.onChangeByAutoComplete(results);
        if (this.postalcode !== "") { 
            const placeid=results[0].place_id;
            //const placeid=results[0].placeId;
            console.log("@@@@@@@ place id "+placeid);
            //return results[0];
            //return geocodeByPlaceId(placeid);
            
          console.log("CHANGE ADDRESS POSTAL "+this.postalcode); 
            const latlng = getLatLng(results[0]).then(resultLatLng => {  
              console.log("###@@@@@@@ place lat lng "); console.log(resultLatLng);
              return this.geocodeDetails2(resultLatLng);
              //return resultLatLng;
              //const rxz= this.geocodeDetails2(resultLatLng);
              //return this.onChangeByAutoComplete(results);
            }).then(resultsLnglat => {
               this.onChangeByAutoComplete(results);
            });
            console.log("@@@@@@@ place lat lng "); console.log(latlng);
            //return this.geocodeDetails(placeid);
        }else{ 
           this.onChangeByAutoComplete(results);
        }
      }).then(resultDetails => { 
        //to treat details
        console.log("@@@@@@@ resultDetails "); console.log(resultDetails); console.log("//@@@@@@@ resultDetails ");
        return resultDetails;
      })
      //.then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));

      //this.geocodeDetails()

      //console.log("#I#ADDRESS## AD="+address+" IDP=");
  };

  geocodeByZipCode = address => {
    const geocoder = new window.google.maps.Geocoder();
    const OK = window.google.maps.GeocoderStatus.OK;
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status !== OK) {
          reject(status);
        }
        resolve(results);
      });
    });
  };

  // geocodeDetails = placeId => {
  //   const request = {
  //     placeId: placeId,
  //     fields: ['name', 'address_component', 'adr_address', 'formatted_address', 'place_id', 
  //     'plus_code', 'vicinity', 'rating', 
  //     "postal_code", 'geocode', 'geometry']
  //   };
    
  //   // const geocoder = new window.google.maps.places.PlacesService(); //new google.maps.places.PlacesService(map);
  //   // geocoder.getDetails(request, callback);
    
  //   callback(place, status) {
  //     if (status == google.maps.places.PlacesServiceStatus.OK) {
  //       createMarker(place);
  //     }
  //   };
    
  //   const geocoder = new window.google.maps.Geocoder();
  //   const OK = window.google.maps.GeocoderStatus.OK;
  
  //   return new Promise((resolve, reject) => {
  //     geocoder.geocode({ address }, (results, status) => {
  //       if (status !== OK) {
  //         reject(status);
  //       }
  //       resolve(results);
  //     });
  //   });
  // };


  geocodeDetails = placeId => {
    //const geocoder = new window.google.maps.Geocoder();
    //const OK = window.google.maps.GeocoderStatus.OK;
    const geocoder = new window.google.maps.places.PlacesService(null);
    const OK = window.google.maps.places.PlacesServiceStatus.OK;
    const request = {
      placeId: placeId,
      fields: ['ALL']
      //  fields: ['name', 'address_component', 'adr_address', 'formatted_address', 'place_id', 
      //  'plus_code', 'vicinity', 'rating', 
      //  "postal_code", 'geocode', 'geometry']
    };
  
    return new Promise((resolve, reject) => {
      //geocoder.geocode({ placeId }, (results, status) => {
      geocoder.getDetails(request, (results, status) => {
        if (status !== OK) {
          reject(status);
        }
        resolve(results);
        console.log("weeeeeeeeeeeeeeeeeeeeeeeeeee///////// geocodeDetails IIIIIIDDDDDD "); console.log(results);
      });
    });
  };

  geocodeDetails2 = location => {
    const geocoder = new window.google.maps.Geocoder();
    const OK = window.google.maps.GeocoderStatus.OK;
    const request = {
      location: location,
      // fields: ['name', 'address_component', 'adr_address', 'formatted_address', 'place_id', 
      // 'plus_code', 'vicinity', 'rating', 
      // "postal_code", 'geocode', 'geometry']
    };
  
    return new Promise((resolve, reject) => {
      //geocoder.geocode({ placeId }, (results, status) => {
      geocoder.geocode(request, (results, status) => {
        if (status !== OK) {
          reject(status);
        }
        if (this.postalcode !== "") {
          let productList = results.length > 0
          && results.map((item, i) => { 
              const addcomp=item.address_components;
              const addform=item.formatted_address;
              const patternPostal = /((?!.*[DFIOQU])[A-VXY])([0-9])((?!.*[DFIOQU])[A-Z])([ ]?)([0-9])((?!.*[DFIOQU])[A-Z])([0-9])/i;
     
              if(patternPostal.test(addcomp[(addcomp.length-1)].long_name)){
                const addx=this.addressFormatter(item);
                this.postalcodeList.push({ address: addform, obj: addcomp, addFormatted: addx }); 
                console.log("PASO CHANGE ADDRESS "); console.log(addform); console.log("//PASO CHANGE ADDRESS ");
              }
               
            return (
              null
            );
          }, this);
        }
        
        resolve(results);
        console.log("weeeeeeeeeeeeeeeeeeeeeeeeeee/////////geocodeDetails2 geocodeDetails"); console.log(results); console.log("///////// geocodeDetails2");
      });
    });
  };

  onChangeByAutoComplete(newAddressObj){
    let newCountry = '';
    let administrativeAreaLevel1Buffer = '';
    let administrativeAreaLevel2Buffer = '';
    let administrativeAreaLevel1Buffer2 = '';
    let administrativeAreaLevel2Buffer2 = '';

    console.log('GOOOOOOGLE ADDRESS******');
    console.log(newAddressObj);  console.log('GOOOOOOGLE ADDRESS******');
    const newAddress=newAddressObj[0];
    const addressComponents = newAddress['address_components'];

    //console.log(" place_id="+newAddress.place_id);  console.log("formatted_address="+newAddress.formatted_address);
    //formatted_address: "6552 Boul St-Laurent, Montréal, QC H2S 3C6, Canada" place_id
    //console.log(" addressComponents");
    //console.log(addressComponents);
    this.formatedAddress=newAddress.formatted_address;
     
    //this.resetFormExceptStreet();
    if (addressComponents && addressComponents.length>0) {
      //console.log(" ..step 3 ");
      addressComponents.forEach((ac, index) => {
        const type = ac.types;
        const val = ac.long_name;
        const val2 = ac.short_name;
        //console.log(" ..step 4."+index);
        if (type.includes('street_number')) {
          this.street=val;
          if(val.indexOf('-') >-1){
            const addr=val.split("-");
            this.suite=addr[0].trim();
            this.street=addr[1].trim();
            //address=addr[1].trim();
          }
        } else if (type.includes('route')) {
          const streetNbTest = /^\d+\s?/;
          if (streetNbTest.test(this.street)) {
            this.street=(this.street + ' ' + val);
          } else {
            this.street=val;
          }
        } else if (type.includes('locality')) {
          this.city=val;
        } else if (type.includes('postal_code')) {
          this.zipCode=val;
        } else if (type.includes('administrative_area_level_1')) {
          administrativeAreaLevel1Buffer = val; // cannot use this info without knowing country first
          administrativeAreaLevel1Buffer2 = val2;
        } else if (type.includes('administrative_area_level_2')) {
          administrativeAreaLevel2Buffer = val; // cannot use this info without knowing country first
          administrativeAreaLevel2Buffer2 = val2; 
        } else if (type.includes('country')) {
          newCountry = val;
          this.country= val;
        }
 
      });

      if (newCountry =="France") { //if (countryId === 75) { // exception: for France use administrative_area_level_2
        this.statex=administrativeAreaLevel2Buffer!=''? administrativeAreaLevel2Buffer : administrativeAreaLevel1Buffer;
        //console.log("weeeeeeeeeeeeeeeeeee france "+administrativeAreaLevel2Buffer+" vs "+administrativeAreaLevel1Buffer);
        this.statex2=administrativeAreaLevel2Buffer2!=''? administrativeAreaLevel2Buffer2 : administrativeAreaLevel1Buffer2;
      } else {
        // use administrative_area_level_1 now if it was provided earlier
        this.statex=administrativeAreaLevel1Buffer;
        this.statex2=administrativeAreaLevel1Buffer2;
      }

      // console.log("IADDRESS => C="+this.country+" S="+this.statex+" V="+this.city+" R="+this.street+" U="+this.suite+" Z="+
      // this.zipCode+" A="+this.address+" F="+this.formatedAddress);

      this.sendData(this.getDataObject());
       
    } else {
      console.log('uuuuuuups No Google Place address found');
    }
  
  }

  addressFormatter(newAddress){
    let newCountry = '';
    let administrativeAreaLevel1Buffer = '';
    let administrativeAreaLevel2Buffer = '';
    let administrativeAreaLevel1Buffer2 = '';
    let administrativeAreaLevel2Buffer2 = '';

    let street=''; 
    let suite=""; 
    let city=""; 
    let statex=""; 
    let country=""; 
    let zipCode=""; 
    let formatedAddress='';
    let address=""; 
    let statex2=""; 

    console.log('FORMAT GOOOOOOGLE ADDRESS******');
    console.log(newAddress);  console.log('FORMAT GOOOOOOGLE ADDRESS******');
    //const newAddress=newAddressObj[0];
    const addressComponents = newAddress['address_components'];
 
    formatedAddress=newAddress.formatted_address;
     
    //this.resetFormExceptStreet();
    if (addressComponents && addressComponents.length>0) {
      //console.log(" ..step 3 ");
      addressComponents.forEach((ac, index) => {
        const type = ac.types;
        const val = ac.long_name;
        const val2 = ac.short_name;
        //console.log(" ..step 4."+index);
        if (type.includes('street_number')) {
          street=val; address=val;
          if(val.indexOf('-') >-1){
            const addr=val.split("-");
            suite=addr[0].trim();
            street=addr[1].trim();
            address=addr[1].trim();
          }
        } else if (type.includes('route')) {
          const streetNbTest = /^\d+\s?/;
          if (streetNbTest.test(street)) {
            street=(street + ' ' + val);
            address=(street );
            // street=(val + ' ' + street);
            // address=(val + ' ' + street);
          } else {
            street=val;  address=val; 
          }
        } else if (type.includes('locality')) {
          city=val;
        } else if (type.includes('postal_code')) {
          zipCode=val;
        } else if (type.includes('administrative_area_level_1')) {
          administrativeAreaLevel1Buffer = val; // cannot use this info without knowing country first
          administrativeAreaLevel1Buffer2 = val2; 
        } else if (type.includes('administrative_area_level_2')) {
          administrativeAreaLevel2Buffer = val; // cannot use this info without knowing country first
          administrativeAreaLevel2Buffer2 = val2;
        } else if (type.includes('country')) {
          newCountry = val;
          country= val;
        }
 
      });

      if (newCountry =="France") { //if (countryId === 75) { // exception: for France use administrative_area_level_2
        statex=administrativeAreaLevel2Buffer!=''? administrativeAreaLevel2Buffer : administrativeAreaLevel1Buffer;
        //console.log("weeeeeeeeeeeeeeeeeee france "+administrativeAreaLevel2Buffer+" vs "+administrativeAreaLevel1Buffer);
        statex2=administrativeAreaLevel2Buffer2!=''? administrativeAreaLevel2Buffer2 : administrativeAreaLevel1Buffer2;
      } else {
        // use administrative_area_level_1 now if it was provided earlier
        statex=administrativeAreaLevel1Buffer;
        statex2=administrativeAreaLevel1Buffer2;
      }

      // console.log("IADDRESS => C="+this.country+" S="+this.statex+" V="+this.city+" R="+this.street+" U="+this.suite+" Z="+
      // this.zipCode+" A="+this.address+" F="+this.formatedAddress);

      //this.sendData(this.getDataObject());
      let myObj={ 
        address: address,
        street:street, 
        suite:suite,
        city:city,
        "state":statex,
        "state2":statex2,
        country:country,
        zipCode:zipCode,
        formatedAddress:formatedAddress 
    };
       return myObj; 
    } else {
      console.log('uuuuuuups No Google Place address found');
      return null;
    }
  
  }

  sendData = (data) => {
    this.props.parentCallback(data);
  };

  getDataObject(){
    let myObj={ 
        address: this.address,
        street:this.street, 
        suite:this.suite,
        city:this.city,
        "state":this.statex,
        "state2":this.statex2,
        country:this.country,
        zipCode:this.zipCode,
        formatedAddress:this.formatedAddress,
        whenzipCode:this.postalcodeList 
    };

    this.setState({ ["address"]: this.street });
     
    return myObj;
  }

  getKeyId() { 
		let length=Math.floor(Math.random() * 500); 
		return length;
  }

  render() {
    const myplaceholder=this.props.placeholder;  //placeholder: 'Search Places ...',
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: myplaceholder,
                className: 'location-search-input form-control',
              })}
            />
            <div className="autocomplete-dropdown-container"> 
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  //console.log("SUGGESTION #"+index);  console.log(suggestion);  console.log("SUGGESTION");
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    const mIndx=this.getKeyId()+''+index;
                    //<span>{suggestion.description}----{mIndx}</span>
                  return (
                    <div key={mIndx}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })} 
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
} 


export default GoogleAddressSearch;