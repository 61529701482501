import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userLoginFetch, getLoggedUserGoto, userPassResetFetch} from './redux/actions';

import axios from 'axios'; 
import { axiosDefaults } from './appConfigaxios'; 

import history from './history';
import Toastr from "./messages";
import { withTranslation } from 'react-i18next';
import { encryptText, decryptText, testEncryptionText } from './appEncryption';
import ZAFClient from 'zendesk_app_framework_sdk';

let zafClient = ZAFClient.init(); 
class Login extends Component {
  /*constructor(props) {
		super(props); 
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}*/

	urlParamZendesk="";

	constructor(props) {
		super(props); 
		this.state = {
			username: "",
			rid: ""+this.randomStringId(),
			email: "",
			password: "",
			toastrMessage: '', toastrType: '', toastrDuration: 6000,
			isLoginFrm: true,
			isChecked: false
		}

		//urlParamZendesk="";
	}
  
  componentDidMount() { 
	const remember = localStorage.getItem("rememberme"); //localStorage.rememberme;
	////console.log("...WEEEEEEEEH....///..componentDidMount  "+remember);
	////console.log(testEncryptionText('hello world here')); 
	if(remember==="1"){
		const uname = decryptText(localStorage.uname); 
		const upass = decryptText(localStorage.upass);
		const lang = localStorage.getItem("i18nextLng");
		this.props.i18n.changeLanguage(lang);
		this.setState({ ["isChecked"]: true });
		if(uname!=undefined || uname!=null){
			this.setState({ ["email"]: uname});
			this.setState({ ["username"]: uname});
		}
		if(upass!=undefined || upass!=null){
			this.setState({ ["password"]: upass}); 
		}
	} else {
		this.props.i18n.changeLanguage("fr");
	}

	const locurl=window.location.href; 
	zafClient = ZAFClient.init();    
	console.log("LOOOOOOOOOGIN zafClient  ", zafClient, locurl); 
	if(locurl.indexOf("?origin=")> -1){
		const index  = locurl.indexOf('?origin='); 
		const strOut =locurl.substr(index);
		this.urlParamZendesk=strOut+"&xqNeO=1";
		localStorage.setItem("zendeskzat", strOut);
		//console.log("weeeeeeeeeeee zeeeeendesk "+strOut);
	}

	if(zafClient!==undefined && zafClient!==false){ 
		const this00=this;
		let stepRunned=0;
			zafClient.invoke('resize', { width: '100%', height: '600px' });
			console.log("LOOOOOOOOOGIN yeeeeeeeeeees zafClient is ready to use ");
		 
		
		console.log("LOOOOOOOOOGIN  oooooooooooooooh last on Component-ready... "+stepRunned);
	
		console.log("inside if zeeeeeeeeeeeeeendesk GET ID CLIENT NEOLEGAL ");
		 
	 
	  }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
	
	if(event.target.name=="username"){
		this.setState({ ["email"]: event.target.value });
	}
  }
  
  setResetPassForm = event => {
    this.setState({ ["isLoginFrm"]: false });
  }
  
  setResetLoginForm = event => {
    this.setState({ ["isLoginFrm"]: true });
  }
  
  setLanguage = event => {
	  const lng=event.target.value; 
	if(lng !=""){
		const { t, i18n } = this.props; //useTranslation("common");
		i18n.changeLanguage(lng);
	}
  }
  
  setRememberme = event => {
    //this.setState({ ["isLoginFrm"]: true });
	let isChecked=false;
	if(event.target.checked==true){
		isChecked=true;
		localStorage.setItem("rememberme", 1);
	}else{
		const remember = localStorage.rememberme;
		if(remember=="1"){
			localStorage.removeItem("rememberme");
		}
	}
	this.setState({ ["isChecked"]: isChecked });
	//console.log("remember me is "+isChecked);
  }

  handleSubmit = event => {
    event.preventDefault(); 
    //this.props.userLoginFetch(this.state);
	//const myToastMessage=this.state.toastrMessage;
	//const rid=this.randomStringId();
	////console.log("...RID.... ..check id "+rid);
	//window.alert("...RID.... ..check id ="+rid+"  length="+rid.length); 
	const tokenRid = localStorage.rid;
	const username=this.state.username;
	const userpass=this.state.password;
	let randid=this.state.rid;
	const format=/(^[\.\w]{5,25})$/;
	const format2=/(^[\w]{8,25})$/;
	
	//console.log("...RID.... ..check id "+randid);
	 
	if((''+username).length < 5 || (''+username).length > 100){ //if(!(''+username).match(format)){ 
		//window.alert("Por favor, el nombre de usuario debe tener minimo 5 caracteres letras o alfanumerico"); 
		this.showToast("S'il vous plaît, le nom d'utilisateur doit avoir au moins 5 caractères lettres ou alphanumériques", "error");
		//$('#user_rx').focus();
		return false;
	}
	if((''+userpass).length < 8 || (''+userpass).length > 25){  
		//window.alert("Por favor, la clave debe tener minimo 8 caracteres y maximo 25");
		this.showToast("S'il vous plaît, le mot de passe doit avoir un minimum de 8 caractères et un maximum de 25", "error");		
		//$('#pass_rx').focus();
		return false;
	}
	//const tokenRid = localStorage.rid;
	if(tokenRid===undefined || tokenRid===null){
		localStorage.setItem("rid", randid);
	}else{  randid=tokenRid;  }  
	  
	//const user={ "email":username, "password":userpass, "rid":randid }
	const user={ "email":username, "password":userpass }
	//Promise.resolve(this.props.userLoginFetch(this.state))
	//this.props.userLoginFetch(user);
	Promise.resolve(this.props.userLoginFetch(user))
	.then((response)=>{
		//console.log("...RESPONSE IN PROM....///..check authentication "+response);
		//this.setState(response.ZxTeams);
		console.log("...RESPONSE SUCCESSFUL AUTH IN PROM....///..check authentication ", response);
		const remember = localStorage.rememberme;
		if(remember=="1"){
			localStorage.setItem("uname", encryptText(username));
			localStorage.setItem("upass", encryptText(userpass));
		}
		const dd=new Date();
		const ddIso=new Date(dd.getFullYear(), dd.getMonth(), dd.getDate());
		const ddStr=""+dd.getFullYear()+""+dd.getMonth()+""+dd.getDate(); 
		localStorage.setItem("uLogTracking", ddIso.toISOString());
		localStorage.setItem("uLogTracking2", ddStr);
		axiosDefaults(null);
		let urlBase=this.urlParamZendesk;
		const patt = /zendesk\/[0-9]+\/Login/;
		const patt2 = /zendesk\/[0-9]+\/ticket/;
		console.log("heloooooooooooooooooo URL ", urlBase);
		if(urlBase===null || urlBase===""){
			//urlBase=window.location.href; 
			if(window.location.href.indexOf("/zendesk/") >=0){
				urlBase="ticket";
				console.log("waaaaaaas empty heloooooooooooooooooo URL ", urlBase);
			}
		}
		//http://localhost:3001/zendesk/433332/Login
		if(patt.test(urlBase)){
			urlBase=urlBase.replace("/Login", "/ticket");
		}else if(patt2.test(urlBase)){

		}
		console.log("#2 heloooooooooooooooooo URL ", urlBase);
		// Check if not in iframe
		if (window.self === window.top) history.push(`./${urlBase}`);
		  
	});
  }
  
  handleSubmitResetpass = event => {
    event.preventDefault()
    //this.props.userLoginFetch(this.state);
	//const myToastMessage=this.state.toastrMessage;
	//const rid=this.randomStringId();
	////console.log("...RID.... ..check id "+rid);
	//window.alert("...RID.... ..check id ="+rid+"  length="+rid.length); 
	const tokenRid = localStorage.rid;
	const username=this.state.username;
	//const userpass=this.state.password;
	let userpass="";
	let randid=this.state.rid;
	const format=/(^[\.\w]{5,25})$/;
	const format2=/(^[\w]{8,25})$/;
	
	//console.log("...RESETRID.... ..check id "+randid);
	 
	if((''+username).length < 5 || (''+username).length > 100){  
		//window.alert("Por favor, el nombre de usuario debe tener minimo 5 caracteres letras o alfanumerico"); 
		this.showToast("Por favor, el nombre de usuario debe tener minimo 5 caracteres letras o alfanumerico", "error");
		//$('#user_rx').focus();
		return false;
	}
	 
	//const tokenRid = localStorage.rid;
	if(tokenRid===undefined || tokenRid===null){
		//localStorage.setItem("rid", randid);
	}else{  randid=tokenRid;  }

	for(let m=1;m<8; m++){
		userpass +=""+randid;
	}	
	  
	const user={ "email":username, "password":userpass, "rid":randid }
	//Promise.resolve(this.props.userLoginFetch(this.state))
	//this.props.userLoginFetch(user);
	Promise.resolve(this.props.userPassResetFetch(user))
	.then((response)=>{
		this.showToast("Felicidades, si el correo que puso existe, recibiras un correo para resetear tu clave. Revise tu correo en unos minutos.", "success");
		this.setResetLoginForm(event);
	});
  }
  
  
  handleAClick = event => {
    event.preventDefault();
    //this.props.userLoginFetch(this.state);
	//console.log("...0000000000....///..check authentication "+this.props.LoggedUser);
  }
  
  randomStringId() {
		let result = '';
		let length=Math.floor(Math.random() * (75 - 50) + 50);
		let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_-';
		for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
  }
  
  showToast = (arg, toastType) => {
	//toastType: success or error or warning or info	  
	//console.log("showToast showToast "); //console.log(arg);
	//alert("hello it is an eventDrop here "+arg.date);
	//Toastr("Hello I am a toast message right here, for testing purpose...");
	//toastrMessage: '', toastrType: '', toastrDuration: 6000, 
	this.setState({ // update a property
      toastrMessage: arg, 
	  toastrType: toastType
    });
	setTimeout(() => { 
	  this.setState({ 
		  toastrMessage: "", 
		  toastrType: 'success'
		}); 
	}, 5500);
  } 

  
  render() {
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	const isLoginFrm=this.state.isLoginFrm;
	const isChecked=this.state.isChecked;
	const defaultLang = localStorage?.getItem("i18nextLng") ?? "fr"
	//const { t } = this.props; 
	
	const { t, i18n } = this.props; //useTranslation("common");
    //i18n.changeLanguage(value);
		
    return (
      <div className="hold-transition login-page login-box">
  <div className="login-logox">
  {/*<a href="#"><img src={require("./img/logo-text.png")} width="152" height="28" /></a>   <a href="#"><img src={require("./img/logo-icon-3.png")} width="111" height="64" /></a> */} 
	<p><a href="#"><img src={require("./img/logo-icon-3.png")} width="226" height="64" /></a></p>
  </div> 
  <div className="card">
	{isLoginFrm==true?	
    <div className="card-body login-card-body"> 	
      <form  onSubmit={this.handleSubmit}>
        <div className="input-group mb-3">
          <input type="text" className="form-control" placeholder={t("uname_x")} 
		  name='username' 
          value={this.state.username}
          onChange={this.handleChange} />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input type="password" className="form-control" placeholder={t("upass_x")} 
		  name='password' 
          value={this.state.password}
          onChange={this.handleChange} />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock"></span>
            </div>
          </div>
        </div>
		
		
		{/*<div className="input-group mb-3"> 
		  <select name="tipoUsuario" id="tipoUsuario" defaultValue="cliente" className="form-control" placeholder={t("tipoUsuario_x")} > 
				<option value="">{t("Seleccionar_x")}</option> 
				<option value="cliente">Cliente</option>
				<option value="operador">Operador</option> 
				<option value="admin">Administrativo</option> 
			</select>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user"></span>
            </div>
          </div>
        </div>*/}


		<div className="input-group mb-3"> 
		  <select name="language" id="language" className="form-control" placeholder={t("idioma_x")}  onChange={this.setLanguage}  > 
				<option value="">{t("Seleccionar_x")}</option> 
				<option selected={defaultLang === "fr"} value="fr">Français</option>
				{/* <option value="es">Español</option> */}
				<option selected={defaultLang === "en"} value="en">English</option> 
			</select>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-language"></span>
            </div>
          </div>
        </div>
		
        <div className="row">
          {/* <div className="col-8"> */}
            <div className="icheck-primary">
              <input type="checkbox" name="remember"  id="remember" checked={isChecked} onChange={this.setRememberme} onClick={this.setRememberme} />
              <label htmlFor="remember">
                &nbsp; {t("Recuerdame_x")}
              </label>
            </div>
          {/* </div>   */}
        </div>
		
		<div className="row">
          <div className="col-4">
            
          </div> 
          <div className="col-8">
            <button type="submit" className="btn btn-primary btn-block">{t("login_x")}</button>
          </div> 
        </div>
      </form>
	  <p className="mb-0">
         &nbsp; 
      </p>  
	  <p className="mb-1">
	  	&nbsp;   
        {/* <a href="#" onClick={this.setResetPassForm}>{t("recoverpass_x")}</a> */}
      </p>
	   
    </div>
	:
	<div className="card-body login-card-body"> 
		<p className="mb-1">
			<b>{t("resetpass_x")}</b>
		</p> 
      <form  onSubmit={this.handleSubmitResetpass}>
        <div className="input-group mb-3">
          <input type="email" className="form-control" placeholder={t("qemail_x")} 
		  name='username' 
          value={this.state.username}
          onChange={this.handleChange} />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope"></span>
            </div>
          </div>
        </div>
		<div className="row">
          <div className="col-4">
            
          </div> 
          <div className="col-12"> 
			<p className="mb-8">
				<button type="submit" className="btn btn-primary">{t("btnresetpass_x")}</button>  
			</p>
			<p className="mb-1">
				<button type="button" className="btn btn-warning" onClick={this.setResetLoginForm} >{t("btnCancelar_x")}</button>
			</p>
          </div> 
        </div>
	  </form>	
	</div>	
	}
  </div>
  <Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
</div>
    )
  }
}

//const MyAppMain = withTranslation('common')(Login); 

const mapDispatchToProps = dispatch => ({
  userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo)), getLoggedUserGoto, userPassResetFetch: userInfo => dispatch(userPassResetFetch(userInfo))  
});

//userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo)), getLoggedUserGoto, userPassResetFetch 

export default connect(null, mapDispatchToProps)(withTranslation('common')(Login));
