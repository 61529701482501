import { RECEIVE_NDIPRODUCTSTRANSLATION, UPDATE_NDIPRODUCTSTRANSLATION } from '../actions/NdiProductsTranslationAction';

export default function singleNdiProductsTranslationReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NDIPRODUCTSTRANSLATION:
      return action.NdiProductsTranslation;
    case UPDATE_NDIPRODUCTSTRANSLATION:
      return {
        id: action.id,
product_id: action.payload.product_id,
lang: action.payload.lang,
product_block: action.payload.product_block,
published: action.payload.published,
last_update: action.payload.last_update,
user_id: action.payload.user_id,
      }
    default:
      return state;
  }
};